import PropTypes from "prop-types";
import React from "react";

const GeneratorDetailsForm = ({ billingAddress, serviceAddress, name, changeRequestHandler }) => {
  const service = {
    "Generator Name": name || "N/A",
    street: serviceAddress?.street || "N/A",
    suite: serviceAddress?.suite || "N/A",
    city: serviceAddress?.city || "N/A",
    state: serviceAddress?.state || "N/A",
    zip: serviceAddress?.zipCode || "N/A",
    email: serviceAddress?.email || "N/A",
    phone: serviceAddress?.phone || "N/A",
  };

  const billing = {
    "Generator Name": name || "N/A",
    street: billingAddress?.street || "N/A",
    suite: billingAddress?.suite || "N/A",
    city: billingAddress?.city || "N/A",
    state: billingAddress?.state || "N/A",
    zip: billingAddress?.zipCode || "N/A",
    email: billingAddress?.email || "N/A",
    phone: billingAddress?.phone || "N/A",
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6 px-3">
        <h2 className="text-2xl font-bold">Generator Details</h2>
        <a className="text-thatblue hover:underline text-sm cursor-pointer" onClick={changeRequestHandler}>
          Change Request
        </a>
      </div>

      <div className="grid grid-cols-2 gap-6 bg-white rounded-cardRadii shadow-md p-8">
        <div>
          <h3 className="text-gray-900 font-medium capitalize mb-2">Billing Information</h3>
          <hr className="text-gray-400" />
          <div className="py-3">
            {Object.entries(billing).map(([key, value]) => (
              <div key={key} className="mb-4 w-full flex items-center gap-3">
                <p className="text-gray-700 font-medium w-1/2 capitalize">{key}</p>
                <p className="text-gray-600 w-1/2">{value}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-gray-900 font-medium capitalize mb-2">Service Information</h3>
          <hr className="text-gray-400" />
          <div className="py-3">
            {Object.entries(service).map(([key, value]) => (
              <div key={key} className="mb-4 w-full flex items-center gap-3">
                <p className="text-gray-700 font-medium w-1/2 capitalize">{key}</p>
                <p className="text-gray-700 w-1/2">{value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

GeneratorDetailsForm.propTypes = {
  billingAddress: PropTypes.shape({
    city: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    suite: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  name: PropTypes.string,
  serviceAddress: PropTypes.shape({
    city: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    suite: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  changeRequestHandler: PropTypes.func.isRequired,
};

// Set default props to prevent undefined errors
GeneratorDetailsForm.defaultProps = {
  billingAddress: {},
  serviceAddress: {},
  name: "Unknown Generator",
};

export default GeneratorDetailsForm;
