import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { doc, getDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../config/firebase";
import NoOfStops from "../generator-management/components/generators/components/detail-forms/forms/NoOfStops";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatUtcDateString } from "../../../utils/helpers";

const FullCalender = ({ onTwoEventsSelected, routes, selectedRouteIds }) => {
	const calendarRef = useRef(null);
	const [expandedEventId, setExpandedEventId] = useState(null);
	const [slideDirection, setSlideDirection] = useState("right");
	const [prevView, setPrevView] = useState("dayGridMonth");
	const [selectedEvents, setSelectedEvents] = useState([]);
	const [events, setEvents] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		console.log({ selectedRouteIds, startDate, endDate });
		if (!selectedRouteIds?.length || !startDate || !endDate) return;
		getAllEvents(selectedRouteIds, startDate, endDate);
	}, [selectedRouteIds, startDate, endDate]);

	const getAllEvents = async (selectedRouteId, startDate, endDate) => {
		setIsLoading(true);
		try {
			const startLocal = new Date(startDate);
			startLocal.setHours(0, 0, 0, 0);

			const endLocal = new Date(endDate);
			endLocal.setHours(23, 59, 59, 999);

			let tempIds = [];

			console.log({ startDate, endDate });

			// Rest of your existing code...
			while (startLocal <= endLocal) {
				let todaySt =
					startLocal.getFullYear() +
					"-" +
					(startLocal.getMonth() + 1 < 10 ? `0${startLocal.getMonth() + 1}` : startLocal.getMonth() + 1) +
					"-" +
					(startLocal.getDate() < 10 ? `0${startLocal.getDate()}` : startLocal.getDate());

				tempIds.push(`${todaySt}T00:00:00.000Z`);
				startLocal.setDate(startLocal.getDate() + 1);
			}

			tempIds = [...new Set(tempIds)];

			console.log({ tempIds });
			console.log({ selectedRouteIds });

			const tempEvents = [];
			await Promise.all(
				tempIds.flatMap((id, index) =>
					selectedRouteIds.map(async (routeId) => {
						const foundRoute = routes.find((route) => route.id === routeId);

						if (!foundRoute) {
							console.log("returning after no route found");
							return;
						}

						try {
							const dailyRouteRes = await getDoc(doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, id));
							console.log({ dailyRouteRes, id });

							if (dailyRouteRes.exists()) {
								const routeData = dailyRouteRes.data();
								tempEvents.push({
									id: `${routeId}-${id}`,
									title: `${routeData?.services?.length || 0} Stops`,
									color: foundRoute.color,
									label: foundRoute.routeLabel,
									routeId: routeId,
									dailyRouteId: id,
									start: parseDateLocal(id?.split("T")?.[0]),
									startStr: id?.split("T")?.[0],
									end: parseDateLocal(id?.split("T")?.[0]),
								});
							} else {
								console.log("Not found for ", id);
								tempEvents.push({});
							}
						} catch (error) {
							console.error(`Error fetching route ${routeId} for date ${id}:`, error);
						}
					})
				)
			);
			setEvents(tempEvents);
			console.log({ tempEvents });
			if (!tempEvents.length) {
				toast.info("No service found");
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const style = document.createElement("style");
		style.textContent = styleString;
		document.head.appendChild(style);
		return () => document.head.removeChild(style);
	}, []);

	const handleEventClick = (clickInfo) => {
		clickInfo.jsEvent.preventDefault();
		clickInfo.el.classList.remove("fc-event-selected");

		if (clickInfo.jsEvent.ctrlKey) {
			setSelectedEvents((prev) => {
				let newSelection;

				if (prev.includes(clickInfo.event.id)) {
					newSelection = prev.filter((id) => id !== clickInfo.event.id);
				} else if (prev.length < 2) {
					newSelection = [...prev, clickInfo.event.id];
				} else {
					newSelection = [prev[1], clickInfo.event.id];
				}

				const selectedEventObjects = events.filter((event) => newSelection.includes(event.id));
				onTwoEventsSelected?.(selectedEventObjects);

				return newSelection;
			});
		} else {
			setSelectedEvents([]);
			onTwoEventsSelected([]);
			document.querySelectorAll(".fc-event-selected").forEach((el) => {
				el.classList.remove("fc-event-selected");
			});
			setExpandedEventId((prev) => (prev === clickInfo.event.id ? null : clickInfo.event.id));
		}
	};

	const renderEventContent = (eventInfo) => {
		return eventInfo.event.extendedProps?.routeId ? (
			<div className="flex items-center gap-2">
				<div
					className="min-w-6 min-h-6 rounded-full"
					style={{ backgroundColor: eventInfo.event.backgroundColor || eventInfo.event.extendedProps.color }}
				></div>
				<p className="text-black flex-grow text-sm truncate">
					<NoOfStops
						routeId={eventInfo.event.extendedProps.routeId}
						serviceDate={eventInfo.event.extendedProps.dailyRouteId}
					/>
				</p>
			</div>
		) : null;
	};

	const handleViewChange = (viewType) => {
		const viewOrder = ["timeGridDay", "dayGridWeek", "dayGridMonth", "dayGridYear"];
		const prevIndex = viewOrder.indexOf(prevView);
		const newIndex = viewOrder.indexOf(viewType);

		setSlideDirection(prevIndex < newIndex ? "right" : "left");
		setPrevView(viewType);
	};

	const dateClickHandler = (dateStr) => {
		let event = events.find((event) => event.startStr === dateStr);

		if (!event) {
			toast.info("No service is scheduled for this date");
			return;
		}
		navigate(`/admin/operations/route-optimization?date=${dateStr + "T00:00:00.000Z"}`);
	};

	return (
		<div className="bg-white calendar-container relative min-h-[500px]">
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-50">
					<div className="animate-spin rounded-full h-12 w-12 border-4 border-t-transparent border-blue-500"></div>
				</div>
			)}
			<div
				className={`view-transition ${slideDirection === "right" ? "slide-enter-active" : "slide-left-enter-active"}`}
			>
				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					initialView="dayGridMonth"
					headerToolbar={{
						left: "prev next today",
						center: "title",
						right: "dayGridMonth dayGridWeek",
					}}
					buttonText={{
						prev: "Prev",
						next: "Next",
						today: "Today",
						month: "Month",
						week: "Week",
						day: "Day",
						year: "Year",
					}}
					// validRange={validRange}
					events={events}
					eventMaxStack={2}
					eventClick={handleEventClick}
					eventContent={renderEventContent}
					viewDidMount={(view) => {
						console.log({ view });
						handleViewChange(view.view.type);
					}}
					initialDate={new Date()}
					datesSet={(dateInfo) => {
						const newStart = new Date(dateInfo.start);
						const newEnd = new Date(dateInfo.end);

						let todayDate = new Date();

						if (todayDate.getMonth() >= newStart.getMonth() && todayDate.getFullYear() === newStart.getFullYear()) {
							setStartDate(todayDate);
						} else {
							setStartDate((prev) => {
								const prevUTC = prev ? new Date(prev) : null;
								let resp = prevUTC?.getMonth() === newStart.getTime() ? prev : newStart;
								return resp;
							});
						}

						console.log({ newStart, startDate, newEnd, todayDate });

						setEndDate((prev) => {
							const prevUTC = prev ? new Date(prev) : null;
							let resp = prevUTC?.getTime() === newEnd.getTime() ? prev : newEnd;
							console.log({ resp, prevUTC, newEnd });
							return resp;
						});
					}}
					dateClick={(info) => {
						let calendarDate = info.dateStr;
						const today = new Date();
						let todaySt =
							today.getFullYear() +
							"-" +
							(today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1) +
							"-" +
							(today.getDate() < 10 ? `0${today.getDate()}` : today.getDate());

						if (new Date(todaySt) > new Date(calendarDate)) {
							return;
						}
						console.log(
							"Sending for route optimization",
							calendarDate,
							todaySt,
							new Date(todaySt),
							new Date(calendarDate)
						);
						dateClickHandler(calendarDate);
					}}
					timeZone="local"
				/>
			</div>
		</div>
	);
};

export default FullCalender;

function parseDateLocal(dateStr) {
	const [year, month, day] = dateStr.split("-").map(Number);
	return new Date(year, month - 1, day);
}

const styleString = `
  /* Base Button Styling */
  .fc .fc-button-primary {
    background-color: rgb(255, 255, 255) !important;
    border: 1.5px solid #8A8A8F !important;
    color: black !important;
    text-transform: capitalize !important;
    padding: 8px 40px !important;
    font-weight: 500 !important;
    border-radius: 9999px !important;
    transition: all 0.2s ease !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .fc-daygrid-day-number:hover {
  background-color: rgba(0, 101, 211, 0.3) !important;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer !important;
}

  /* Active/Selected Button */
  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):active {
    background-color: #0065d3 !important;
    color: white !important;
    border-color: #0065d3 !important;
  }

  /* Hover State */
  .fc .fc-button-primary:hover {
    background-color: #0065d3 !important;
    color: white !important;
    border-color: #0065d3 !important;
  }

  /* Disabled State */
  .fc .fc-button:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
    background-color: white !important;
    color: black !important;
  }

  /* Today Button */
  .fc .fc-today-button {
    background-color: #0065d3 !important;
    color: white !important;
    border-color: #0065d3 !important;
  }

  /* Toolbar Layout */
  .fc .fc-toolbar {
    gap: 1rem;
    row-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }

  .fc-toolbar-chunk:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    order: 1;
  }

  .fc-toolbar-chunk:nth-child(2) {
    order: 2;
    text-align: center;
    flex-grow: 1;
    min-width: 200px;
  }

  .fc-toolbar-chunk:nth-child(3) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    order: 3;
    justify-content: flex-end;
  }

  /* Calendar Grid */
  .fc .fc-daygrid-day-frame {
    min-height: 120px !important;
    height: 100% !important;
    position: relative;
    overflow: visible !important;
  }

  .fc .fc-daygrid-day-events {
    margin: 0 !important;
    position: relative !important;
    overflow: visible !important;
  }

  /* Event Cards */
  .event-card {
    display: flex;
    align-items: center;
    min-height: 40px !important;
    width: 100% !important;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    transition: box-shadow 0.2s ease;
  }

  .event-card.selected {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #000;
  }

  .event-card:not(.selected):not(.expanded) {
    box-shadow: none !important;
  }

  /* Event Content */
  .event-content-wrapper {
    min-height: 32px !important;
    display: flex !important;
    align-items: center !important;
    padding: 4px 8px;
    position: relative;
    z-index: 2;
  }

  /* Expanded Event Details */
  .expanded-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: inherit;
    border-radius: 0px 0px 4px 4px;
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    z-index: 999;
  }

  .event-card.expanded .expanded-content {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    z-index: 1001;
  }

  /* Time and Description Styling */
  .time-info {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin-bottom: 8px;
  }

  .description-section {
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 6px;
  }

  /* Miscellaneous */
  .fc-view-harness {
    overflow: visible !important;
    position: relative;
    z-index: 1;
  }

  .fc-scrollgrid-sync-inner {
    position: relative;
    overflow: visible !important;
  }

  .fc-daygrid-event-harness {
    background: transparent !important;
    position: relative !important;
  }

  .fc-daygrid-event-harness + .fc-daygrid-event-harness {
    margin-top: 2px !important;
  }

 .fc-daygrid-dot-event:hover {
  background-color: inherit !important;
  opacity: 1 !important;
}
`;
