import PropTypes from "prop-types";
import {
	AzureMapDataSourceProvider,
	AzureMapHtmlMarker,
	AzureMapLayerProvider,
	AzureMapsContext,
} from "react-azure-maps";
import { data } from "azure-maps-control";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FcBiohazard } from "react-icons/fc";
import RoundedPin from "../../pages/admin/assets/components/routes-optimization/components/RoundedPin";
import { randomizeCoordinates } from "../../utils/helpers";
const CustomHtmMarker = ({
	coordinates = { lat: 0, lng: 0 },
	randomCoordinates = { lat: 0, lng: 0 },
	draggable = false,
	pin,
	id = Math.random().toString(),
}) => {
	const [isPopShown, setIsPopShown] = useState(false);
	const { isMapReady, mapRef, removeMapRef } = useContext(AzureMapsContext);
	const [currentRandomPosition, setCurrentRandomPosition] = useState(
		new data.Position(randomCoordinates.lng, randomCoordinates.lat)
	);
	const [currentActualCoordinates, setCurrentActualCoordinates] = useState(
		new data.Position(coordinates.lng, coordinates.lat)
	);

	const currentCollection = useMemo(() => {
		return new data.LineString([
			[currentActualCoordinates[0], currentActualCoordinates[1]],
			[currentRandomPosition[0], currentRandomPosition[1]],
		]);
	}, [currentActualCoordinates, currentRandomPosition]);

	useEffect(() => {
		setCurrentRandomPosition(new data.Position(randomCoordinates.lng, randomCoordinates.lat));
		setCurrentActualCoordinates(new data.Position(coordinates.lng, coordinates.lat));
	}, [coordinates, randomCoordinates]);
	const dragHandler = (e) => {
		setCurrentRandomPosition(new data.Position(e.target.options.position[0], e.target.options.position[1]));
	};
	const eventToMarker = [{ eventName: "drag", callback: dragHandler }];

	return (
		<>
			<AzureMapHtmlMarker
				id={id}
				events={eventToMarker}
				options={{
					position: currentRandomPosition,
					draggable: draggable,
				}}
				markerContent={pin}
				isPopupVisible={isPopShown}
			/>
			<AzureMapDataSourceProvider collection={currentCollection}>
				<AzureMapLayerProvider
					type="LineLayer"
					options={{
						strokeColor: "black",
						strokeWidth: 2,
						strokeOpacity: 0.5,
						blur: 0,
						lineCap: "round",
						translate: [0, 0],
					}}
				/>
			</AzureMapDataSourceProvider>
		</>
	);
};

CustomHtmMarker.propTypes = {
	coordinates: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
};

export default CustomHtmMarker;
