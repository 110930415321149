import { usTimeZones } from "../../../../../../../../../utils/constants";

const GeneratorOfficeHours = ({ workingHours = {} }) => {
    const timeZone = usTimeZones.find((item) => item.value == workingHours?.timeZone)?.label;
    
    const { timeZone: _, ...workingHoursWithoutTimeZone } = workingHours;

    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const defaultOfficeHours = dayOrder.reduce((acc, day) => {
        acc[day] = {
            open: "--",
            lunchStart: "--",
            lunchEnd: "--",
            close: "--",
            closed: false
        };
        return acc;
    }, {});

    const officeHours = { ...defaultOfficeHours, ...workingHoursWithoutTimeZone };

    const sortedDays = Object.entries(officeHours).sort(
        (a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0])
    );

    return (
        <div className="py-6">
            <h2 className="text-2xl font-bold mb-6 ml-3">Generator Office Hours</h2>

            <div className="bg-white rounded-cardRadii shadow-md p-8">
                <p className="mb-4 font-semibold">Time Zone: {timeZone}</p>

                <table className="w-full">
                    <thead className="p-2">
                        <tr>
                            <th className="text-left pb-4 font-bold">Date</th>
                            <th className="text-center pb-4 font-bold">Open</th>
                            <th className="text-center pb-4 font-bold">Lunch Start</th>
                            <th className="text-center pb-4 font-bold">Lunch End</th>
                            <th className="text-center pb-4 font-bold">Closes</th>
                            <th className="text-center pb-4 font-bold">Closed</th>
                        </tr>
                    </thead>
                    <tbody className="p-2">
                        {sortedDays.map(([day, details], index) => (
                            <tr key={index} className={details.closed ? "bg-white" : ""}>
                                <td className="py-3 font-semibold">{day}</td>
                                <td className="py-3 text-center">{details?.open || "--"}</td>
                                <td className="py-3 text-center">{details?.lunchStart || "--"}</td>
                                <td className="py-3 text-center">{details?.lunchEnd || "--"}</td>
                                <td className="py-3 text-center">{details?.close || "--"}</td>
                                <td className="py-3 text-center">
                                    {details?.closed && (
                                        <div className="flex justify-center">
                                            <svg
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                className="w-5 h-5 text-green-500"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GeneratorOfficeHours;