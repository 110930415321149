export const stateOptions = [
	{ value: "AL", label: "AL" },
	{ value: "AK", label: "AK" },
	{ value: "AZ", label: "AZ" },
	{ value: "AR", label: "AR" },
	{ value: "CA", label: "CA" },
	{ value: "CO", label: "CO" },
	{ value: "CT", label: "CT" },
	{ value: "DE", label: "DE" },
	{ value: "FL", label: "FL" },
	{ value: "GA", label: "GA" },
	{ value: "HI", label: "HI" },
	{ value: "ID", label: "ID" },
	{ value: "IL", label: "IL" },
	{ value: "IN", label: "IN" },
	{ value: "IA", label: "IA" },
	{ value: "KS", label: "KS" },
	{ value: "KY", label: "KY" },
	{ value: "LA", label: "LA" },
	{ value: "ME", label: "ME" },
	{ value: "MD", label: "MD" },
	{ value: "MA", label: "MA" },
	{ value: "MI", label: "MI" },
	{ value: "MN", label: "MN" },
	{ value: "MS", label: "MS" },
	{ value: "MO", label: "MO" },
	{ value: "MT", label: "MT" },
	{ value: "NE", label: "NE" },
	{ value: "NV", label: "NV" },
	{ value: "NH", label: "NH" },
	{ value: "NJ", label: "NJ" },
	{ value: "NM", label: "NM" },
	{ value: "NY", label: "NY" },
	{ value: "NC", label: "NC" },
	{ value: "ND", label: "ND" },
	{ value: "OH", label: "OH" },
	{ value: "OK", label: "OK" },
	{ value: "OR", label: "OR" },
	{ value: "PA", label: "PA" },
	{ value: "RI", label: "RI" },
	{ value: "SC", label: "SC" },
	{ value: "SD", label: "SD" },
	{ value: "TN", label: "TN" },
	{ value: "TX", label: "TX" },
	{ value: "UT", label: "UT" },
	{ value: "VT", label: "VT" },
	{ value: "VA", label: "VA" },
	{ value: "WA", label: "WA" },
	{ value: "WV", label: "WV" },
	{ value: "WI", label: "WI" },
	{ value: "WY", label: "WY" },
]

const vendorOptions = [
	{ value: "Supply Vendor", label: "Supply Vendor" },
	{ value: "Service Vendor", label: "Service Vendor" },
];

const VendorStatus = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../config/firebase";
import { createNewVendor, GetVendorData, EditVendorDetails } from "../../../../../utils/firebaseOperations";
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding";
import Input from "../../../../../components/UI/Input";
import MultiSelectRounded from "../../../../../components/UI/dropdowns/MultiSelectRounded";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import Button from "../../../../../components/UI/Button";
import Textarea from "../../../../../components/UI/Textarea";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import UploadFiles from "../../../../transporters/UploadFiles";
import FileUpload from "../../../../../components/UI/FileUpload";
import UrlInput from "../../../../../components/UI/UrlInput";

const AddNewVendor = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { geocodeAddress } = useGeocoding();
	const [vendorData, setVendorData] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const lastSavedDataRef = useRef({});
	const formLoadedRef = useRef(false);
	const isModified = useRef(false);
	const [fileChange, setFileChange] = useState(false);

	const defaultValues = {
		name: "",
		apartment: "",
		state: "",
		phoneNumber: "",
		email: "",
		vendorType: [],
		vendorStatus: "",
		street: "",
		vendorWebsite: "",
		city: "",
		zipCode: "",
		notes: "",
		altphone: "",
		uploadFile: null,
		vendorCoordinates: null,
	};

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		getValues,
		setValue,
		trigger,
	} = useForm({ defaultValues });

	// Function to check if the form data has actually changed
	const hasChanged = useCallback((currentValues) => {
		const lastSaved = lastSavedDataRef.current;
		
		// Skip file comparison as it can cause issues
		const { uploadFile: currentFile, ...currentRest } = currentValues;
		const { uploadFile: lastFile, ...lastRest } = lastSaved;
		
		// If file changed, consider it a change
		if (fileChange) return true;
		
		// Stringify for comparison
		const currentStr = JSON.stringify(currentRest);
		const lastStr = JSON.stringify(lastRest);
		
		return currentStr !== lastStr;
	}, [fileChange]);

	// Function to prepare data for saving to Firebase
	const prepareDataForSave = async (data) => {
		try {
			const address = {
				street: data.street,
				city: data.city,
				state: data.state,
				zip: data.zipCode,
			};

			let coordinates = data.vendorCoordinates;
			// Only geocode if address fields have changed
			if (!coordinates && (data.street || data.city || data.state || data.zipCode)) {
				coordinates = await geocodeAddress(address);
			}
			
			const vendorDataToSave = {
				...data,
				vendorCoordinates: coordinates || null,
			};

			let { uploadFile, ...toSave } = vendorDataToSave;

			// Handle file upload if there's a new file
			if (data.uploadFile && data.uploadFile instanceof File) {
				const token = Math.random().toString(36).substring(2);
				const storageRef = ref(storage, `vendors/files/${data.uploadFile.name}`);
				const snapshot = await uploadBytes(storageRef, data.uploadFile);
				const downloadURL = await getDownloadURL(snapshot.ref);

				toSave.file = {
					name: data.uploadFile.name,
					link: downloadURL,
					mime: data.uploadFile?.type,
					path: storageRef.fullPath,
					ref: "",
					token: token,
				};
			} else if (vendorData?.file && !fileChange) {
				// Keep existing file data if file hasn't changed
				toSave.file = vendorData.file;
			}

			return toSave;
		} catch (error) {
			console.error("Error preparing data:", error);
			throw error;
		}
	};

	const handleAutoSave = useCallback(async () => {
		if (!id || !isModified.current) return;
		
		try {
			const currentValues = getValues();
			
			if (!hasChanged(currentValues)) {
				return;
			}
			
			// Validate required fields before saving
			const isValid = await trigger();
			if (!isValid) {
				console.log("Validation failed during autosave");
				return;
			}
			
			setIsSaving(true);
			const dataToSave = await prepareDataForSave(currentValues);
			await EditVendorDetails(dataToSave, id);
			
			lastSavedDataRef.current = {...currentValues};
			isModified.current = false;
			setFileChange(false);
			
			console.log("Autosaved vendor details");
			setIsSaving(false);
		} catch (error) {
			console.error("Autosave failed:", error);
			setIsSaving(false);
		}
	}, [id, getValues, hasChanged, trigger]);

	// Handle form submission (manual save)
	const onSubmit = async (data) => {
		console.log("Submiting data:- ", data);
		
		const loadingToastId = toast.loading("Saving vendor data...");
		
		try {
			const toSave = await prepareDataForSave(data);
			
			console.log("VendorData to save,", toSave);
	
			if (id) {
				await EditVendorDetails(toSave, id);
				
				toast.update(loadingToastId, {
					render: "Vendor updated successfully!",
					type: "success",
					isLoading: false,
					autoClose: 3000,
					closeButton: true
				});
				
				lastSavedDataRef.current = {...data};
				isModified.current = false;
				setFileChange(false);
			} else {
				await createNewVendor(toSave);
				
				toast.update(loadingToastId, {
					render: "Vendor added successfully!",
					type: "success",
					isLoading: false,
					autoClose: 3000,
					closeButton: true
				});
				
				reset(defaultValues);
			}
			
			
		} catch (error) {
			console.error("Error saving vendor:", error);
			
			toast.update(loadingToastId, {
				render: error.message || "Failed to save vendor. Please try again.",
				type: "error",
				isLoading: false,
				autoClose: 3000,
				closeButton: true
			});
		}
	};

	useEffect(() => {
		const fetchVendor = async () => {
			if (id) {
				try {
					const data = await GetVendorData(id);
					if (data) {
						setVendorData(data);
						reset(data);
						lastSavedDataRef.current = {...data};
						formLoadedRef.current = true;
					}
				} catch (error) {
					toast.error("Failed to fetch vendor data");
				}
			} else {
				formLoadedRef.current = true;
			}
		};

		fetchVendor();
	}, [id, reset]);

	const handleInputChange = useCallback(() => {
		if (!formLoadedRef.current) return;
		
		if (!id) return; 
		
		isModified.current = true;
	}, [id]);

	const handleFieldBlur = useCallback(() => {
		if (!id) return; 
		if (isModified.current) {
			handleAutoSave();
		}
	}, [id, handleAutoSave]);

	const handleFileChange = (file) => {
		setValue('uploadFile', file);
		setFileChange(true);
		isModified.current = true;
	};

	const createChangeHandler = (field, originalOnChange) => (e) => {
		originalOnChange(e);
		handleInputChange();
	};

	const createBlurHandler = (field) => () => {
		handleFieldBlur();
	};

	return (
		<div className="p-6">
			<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<ToastContainer />
				<div className="flex justify-between my-4 mx-6">
					<h6 className="font-medium text-lg">Vendor Profile</h6>
					{isSaving && id && <span className="text-sm text-gray-500">Saving...</span>}
				</div>
				<div className="border-b mb-2 border-[#CCCCCC]" />
				<div className="mx-6">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex gap-8">
							<div className="w-1/2">
								<Controller
									name="name"
									control={control}
									rules={{ required: "Vendor Name is required" }}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<Input
												value={value}
												onChange={createChangeHandler("name", onChange)}
												onBlur={createBlurHandler("name")}
												label="Vendor Name*"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="street"
									control={control}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<Input
												value={value}
												onChange={createChangeHandler("street", onChange)}
												onBlur={createBlurHandler("street")}
												label="Street"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.street && <p className="text-red-500 text-sm mt-1">{errors.street?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="city"
									control={control}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<Input
												value={value}
												onChange={createChangeHandler("city", onChange)}
												onBlur={createBlurHandler("city")}
												label="City"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.city && <p className="text-red-500 text-sm mt-1">{errors.city?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="state"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Dropdown
												value={value}
												onChange={(val) => {
													onChange(val);
													handleInputChange();
													handleFieldBlur();
												}}
												options={stateOptions}
												label="State"
												className="text-sm"
											/>
											{errors.state && <p className="text-red-500 text-sm mt-1">{errors.state?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="zipCode"
									control={control}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<Input
												value={value}
												onChange={createChangeHandler("zipCode", onChange)}
												onBlur={createBlurHandler("zipCode")}
												label="Zip"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.zipCode && <p className="text-red-500 text-sm mt-1">{errors.zipCode?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="vendorWebsite"
									control={control}
									rules={{
										pattern: {
											value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
											message: "Please enter a valid URL",
										},
									}}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<UrlInput
												value={value}
												onChange={createChangeHandler("vendorWebsite", onChange)}
												onBlur={createBlurHandler("vendorWebsite")}
												label="Vendor Website"
												className="w-full px-2 py-1 text-sm"
												placeholder="https://example.com"
											/>
											{errors.vendorWebsite && (
												<p className="text-red-500 text-sm mt-1">{errors.vendorWebsite?.message}</p>
											)}
										</div>
									)}
								/>
							</div>

							<div className="w-1/2">
								<Controller
									name="email"
									control={control}
									rules={{
										pattern: {
											value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
											message: "Please enter a valid email address",
										},
									}}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<Input
												value={value}
												onChange={createChangeHandler("email", onChange)}
												onBlur={createBlurHandler("email")}
												label="Email"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.email && <p className="text-red-500 text-sm mt-1">{errors.email?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="phoneNumber"
									control={control}
									render={({ field }) => (
										<div>
											<PhoneInput
												id="phoneNumber"
												label="Phone Number"
												value={field.value}
												onChange={(val) => {
													field.onChange(val);
													handleInputChange();
												}}
												onBlur={() => handleFieldBlur()}
											/>
											{errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="vendorType"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<MultiSelectRounded
												value={value}
												onChange={(val) => {
													onChange(val);
													handleInputChange();
													handleFieldBlur();
												}}
												options={vendorOptions}
												label="Vendor Type"
												className="text-sm"
											/>
											{errors.vendorType && <p className="text-red-500 text-sm mt-1">{errors.vendorType?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="vendorStatus"
									control={control}
									render={({ field: { value, onChange } }) => (
										<div>
											<Dropdown
												value={value}
												onChange={(val) => {
													onChange(val);
													handleInputChange();
													handleFieldBlur();
												}}
												options={VendorStatus}
												label="Vendor Status"
												className="text-sm"
											/>
											{errors.vendorStatus && (
												<p className="text-red-500 text-sm mt-1">{errors.vendorStatus?.message}</p>
											)}
										</div>
									)}
								/>

								<Controller
									name="notes"
									control={control}
									render={({ field: { value, onChange, onBlur } }) => (
										<div>
											<Textarea
												value={value}
												onChange={createChangeHandler("notes", onChange)}
												onBlur={createBlurHandler("notes")}
												label="Notes"
												className="w-full px-2 py-1 text-sm"
											/>
											{errors.notes && <p className="text-red-500 text-sm mt-1">{errors.notes?.message}</p>}
										</div>
									)}
								/>

								<Controller
									name="uploadFile"
									control={control}
									render={({ field }) => (
										<FileUpload
											control={control}
											errors={errors}
											existingFile={vendorData?.file}
											name="uploadFile"
											onChange={(file) => {
												handleFileChange(file);
												handleFieldBlur();
											}}
										/>
									)}
								/>
							</div>
						</div>

						<div className="flex justify-end gap-8 py-10">
							<Button
								onClick={() => navigate("/admin/assets/manage-vendor")}
								btnStyle="form_nav_secondary"
								text="View Vendor List"
							/>
							<Button type="submit" btnStyle="form_nav_primary" text={"Save"} />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewVendor;
