import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../config/firebase";
import {
	arrayUnion,
	collection,
	deleteField,
	doc,
	getDoc,
	getDocs,
	query,
	Timestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { toast } from "react-toastify";

const useConnectionManager = () => {
	const [user, loading] = useAuthState(auth);

	// Helper function to get user data for notifications
	const getUserNotificationData = (userData) => ({
		companyName: userData.companyName || "",
		profileImage: userData.profileImage || "",
		email: userData.email || "",
		serviceArea: userData.serviceArea || "",
		shortDesc: userData.shortDesc || "",
		category: userData.category || [],
		localAreas: userData.localAreas || [],
		uid: userData.id,
	});

	// Send Connection Request
	const sendConnectionRequest = async (targetUserId) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", user.uid);
			const targetUserRef = doc(db, "octoMarketUsers", targetUserId);

			// Get both user documents
			const [currentUserDoc, targetUserDoc] = await Promise.all([getDoc(currentUserRef), getDoc(targetUserRef)]);

			const currentUserData = currentUserDoc.data();
			const targetUserData = targetUserDoc.data();

			// Check if connection already exists
			const existingConnection = currentUserData.connections?.[targetUserId];
			if (existingConnection) {
				throw new Error("Connection already exists or pending");
			}

			const timestamp = Timestamp.now();

			// Create notification data with user information
			const senderNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_sent",
				targetUserId,
				timestamp,
				read: false,
				targetUserData: getUserNotificationData(targetUserData),
			};

			const receiverNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_received",
				fromUserId: user.uid,
				timestamp,
				read: false,
				senderData: getUserNotificationData(currentUserData),
			};

			// Connection data
			const connectionData = {
				status: "pending",
				requestedAt: timestamp,
				type: "sent",
				userData: getUserNotificationData(targetUserData),
			};

			const targetConnectionData = {
				...connectionData,
				receivedAt: timestamp,
				type: "received",
				userData: getUserNotificationData(currentUserData),
			};

			// Update both octoMarketUsers
			await Promise.all([
				updateDoc(currentUserRef, {
					[`connections.${targetUserId}`]: connectionData,
					notifications: arrayUnion(senderNotification),
				}),
				updateDoc(targetUserRef, {
					[`connections.${user.uid}`]: targetConnectionData,
					notifications: arrayUnion(receiverNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error sending connection request:", error);
			throw error;
		}
	};

	// Accept Connection Request
	const acceptConnectionRequest = async (requestingUserId) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", user.uid);
			const requestingUserRef = doc(db, "octoMarketUsers", requestingUserId);

			const [currentUserDoc, requestingUserDoc] = await Promise.all([
				getDoc(currentUserRef),
				getDoc(requestingUserRef),
			]);

			const currentUserData = currentUserDoc.data();
			const requestingUserData = requestingUserDoc.data();

			const timestamp = Timestamp.now();

			// Create notification data
			const acceptorNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_accepted",
				targetUserId: requestingUserId,
				timestamp,
				read: false,
				targetUserData: getUserNotificationData(requestingUserData),
			};

			const requesterNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_accepted",
				fromUserId: user.uid,
				timestamp,
				read: false,
				acceptorData: getUserNotificationData(currentUserData),
			};

			const acceptedConnectionData = {
				status: "accepted",
				type: "received",
				requestedAt: currentUserData.connections[requestingUserId].requestedAt,
				acceptedAt: timestamp,
				userData: getUserNotificationData(requestingUserData),
			};

			const requesterAcceptedData = {
				...acceptedConnectionData,
				type: "sent",
				userData: getUserNotificationData(currentUserData),
			};

			await Promise.all([
				updateDoc(currentUserRef, {
					[`connections.${requestingUserId}`]: acceptedConnectionData,
					notifications: arrayUnion(acceptorNotification),
				}),
				updateDoc(requestingUserRef, {
					[`connections.${user.uid}`]: requesterAcceptedData,
					notifications: arrayUnion(requesterNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error accepting connection request:", error);
			throw error;
		}
	};

	// Decline Connection Request
	const declineConnectionRequest = async (requestingUserId) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", user.uid);
			const requestingUserRef = doc(db, "octoMarketUsers", requestingUserId);

			const [currentUserDoc, requestingUserDoc] = await Promise.all([
				getDoc(currentUserRef),
				getDoc(requestingUserRef),
			]);

			const currentUserData = currentUserDoc.data();
			const requestingUserData = requestingUserDoc.data();

			const timestamp = Timestamp.now();

			// Create notification data
			const declineNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_declined",
				targetUserId: requestingUserId,
				timestamp,
				read: false,
				targetUserData: getUserNotificationData(requestingUserData),
			};

			const requesterNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_declined_by_user",
				fromUserId: user.uid,
				timestamp,
				read: false,
				declinedData: getUserNotificationData(currentUserData),
			};

			await Promise.all([
				updateDoc(currentUserRef, {
					[`connections.${requestingUserId}`]: deleteField(),
					notifications: arrayUnion(declineNotification),
				}),
				updateDoc(requestingUserRef, {
					[`connections.${user.uid}`]: deleteField(),
					notifications: arrayUnion(requesterNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error declining connection request:", error);
			throw error;
		}
	};

	// Cancel Sent Connection Request
	const cancelConnectionRequest = async (targetUserId) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", user.uid);
			const targetUserRef = doc(db, "octoMarketUsers", targetUserId);

			const [currentUserDoc, targetUserDoc] = await Promise.all([getDoc(currentUserRef), getDoc(targetUserRef)]);

			const currentUserData = currentUserDoc.data();
			const targetUserData = targetUserDoc.data();

			const timestamp = Timestamp.now();

			// Create notification data
			const cancelNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_canceled",
				targetUserId,
				timestamp,
				read: false,
				targetUserData: getUserNotificationData(targetUserData),
			};

			const receiverNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "connection_request_canceled_by_sender",
				fromUserId: user.uid,
				timestamp,
				read: false,
				senderData: getUserNotificationData(currentUserData),
			};

			await Promise.all([
				updateDoc(currentUserRef, {
					[`connections.${targetUserId}`]: deleteField(),
					notifications: arrayUnion(cancelNotification),
				}),
				updateDoc(targetUserRef, {
					[`connections.${user.uid}`]: deleteField(),
					notifications: arrayUnion(receiverNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error canceling connection request:", error);
			throw error;
		}
	};

	// Get Notifications with User Data
	const getNotifications = async () => {
		if (!user) throw new Error("No authenticated user");

		try {
			const userDoc = await getDoc(doc(db, "octoMarketUsers", user.uid));
			const notifications = userDoc.data().notifications || [];

			// Sort notifications by timestamp (newest first)
			return notifications.sort((a, b) => b.timestamp - a.timestamp);
		} catch (error) {
			console.error("Error fetching notifications:", error);
			throw error;
		}
	};

	// Get Received Connection Requests
	const getReceivedConnectionRequests = async () => {
		if (!user) throw new Error("No authenticated user");

		try {
			const userDoc = await getDoc(doc(db, "octoMarketUsers", user.uid));
			const connections = userDoc.data().connections || {};

			return Object.entries(connections)
				.filter(([_, data]) => data.status === "pending" && data.type === "received")
				.map(([userId, data]) => ({
					userId,
					...data,
				}));
		} catch (error) {
			console.error("Error fetching received requests:", error);
			throw error;
		}
	};

	// Get Sent Connection Requests
	const getSentConnectionRequests = async () => {
		if (!user) throw new Error("No authenticated user");

		try {
			const userDoc = await getDoc(doc(db, "octoMarketUsers", user.uid));
			const connections = userDoc.data().connections || {};

			return Object.entries(connections)
				.filter(([_, data]) => data.status === "pending" && data.type === "sent")
				.map(([userId, data]) => ({
					userId,
					...data,
				}));
		} catch (error) {
			console.error("Error fetching sent requests:", error);
			throw error;
		}
	};

	// Get Accepted Connections
	const getConnections = async () => {
		if (!user) throw new Error("No authenticated user");

		try {
			const userDoc = await getDoc(doc(db, "octoMarketUsers", user.uid));
			const connections = userDoc.data().connections || {};

			// Filter for accepted connections
			const acceptedConnections = Object.entries(connections)
				.filter(([_, data]) => data.status === "accepted")
				.map(([userId, data]) => ({
					userId,
					...data,
				}));

			console.log("Accepted connections", acceptedConnections);

			return acceptedConnections;
		} catch (error) {
			console.error("Error fetching connections:", error);
			throw error;
		}
	};

	// Send Approval Request
	const sendApprovalRequest = async (data) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", user.uid);
			const targetAdminRef = await getAdminRef();

			if (!targetAdminRef) {
				toast.error("Something went wrong, please try after sometimes");
			}

			// Get both user documents
			const [currentUserDoc, targetUserDoc] = await Promise.all([getDoc(currentUserRef), getDoc(targetAdminRef)]);

			const currentUserData = { id: currentUserDoc.id, ...currentUserDoc.data() };
			const targetAdminData = targetUserDoc.data();

			const timestamp = Timestamp.now();

			// Create notification data with user information
			const senderNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "approval_request_sent",
				targetUserId: targetAdminRef.id,
				timestamp,
				read: false,
				targetUserData: {
					name: "Admin",
				},
			};

			const receiverNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "approval_request_received",
				fromUserId: user.uid,
				timestamp,
				read: false,
				senderData: getUserNotificationData(currentUserData),
			};

			// Update both octoMarketUsers
			await Promise.all([
				updateDoc(currentUserRef, {
					notifications: arrayUnion(senderNotification),
				}),
				updateDoc(targetAdminRef, {
					notifications: arrayUnion(receiverNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error sending connection request:", error);
			throw error;
		}
	};

	// Accept Approval Request
	const approveUserRequest = async (userId) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", userId);
			const targetAdminRef = doc(db, "admins", user?.uid);

			// Get both user documents
			const [currentUserDoc, targetUserDoc] = await Promise.all([getDoc(currentUserRef), getDoc(targetAdminRef)]);

			const currentUserData = { id: currentUserDoc.id, ...currentUserDoc.data() };
			const targetAdminData = targetUserDoc.data();

			const timestamp = Timestamp.now();

			// Create notification data with user information
			const senderNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "request_approved_by_admin",
				targetUserId: targetAdminRef.id,
				timestamp,
				read: false,
				targetUserData: {
					name: "Admin",
				},
			};

			const receiverNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "request_approved_for_user",
				fromUserId: user.uid,
				timestamp,
				read: false,
				senderData: getUserNotificationData(currentUserData),
			};

			// Update both octoMarketUsers
			await Promise.all([
				updateDoc(currentUserRef, {
					approved: true,
					rejected: false,
					notifications: arrayUnion(senderNotification),
				}),
				updateDoc(targetAdminRef, {
					notifications: arrayUnion(receiverNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error sending connection request:", error);
			throw error;
		}
	};

	// Reject Approval Request
	const rejectUserRequest = async (userId) => {
		if (!user) throw new Error("No authenticated user");

		try {
			const currentUserRef = doc(db, "octoMarketUsers", userId);
			const targetAdminRef = doc(db, "admins", user?.uid);

			// Get both user documents
			const [currentUserDoc, targetUserDoc] = await Promise.all([getDoc(currentUserRef), getDoc(targetAdminRef)]);

			const currentUserData = { id: currentUserDoc.id, ...currentUserDoc.data() };
			const targetAdminData = targetUserDoc.data();

			const timestamp = Timestamp.now();

			// Create notification data with user information
			const senderNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "request_rejected_by_admin",
				targetUserId: targetAdminRef.id,
				timestamp,
				read: false,
				targetUserData: {
					name: "Admin",
				},
			};

			const receiverNotification = {
				id: Math.floor(Math.random() * 1000000000),
				type: "request_rejected_for_user",
				fromUserId: user.uid,
				timestamp,
				read: false,
				senderData: getUserNotificationData(currentUserData),
			};

			// Update both octoMarketUsers
			await Promise.all([
				updateDoc(currentUserRef, {
					rejected: true,
					approved: false,
					notifications: arrayUnion(senderNotification),
				}),
				updateDoc(targetAdminRef, {
					notifications: arrayUnion(receiverNotification),
				}),
			]);

			return true;
		} catch (error) {
			console.error("Error sending connection request:", error);
			throw error;
		}
	};

	return {
		sendConnectionRequest,
		acceptConnectionRequest,
		declineConnectionRequest,
		cancelConnectionRequest,
		getNotifications,
		getReceivedConnectionRequests,
		getSentConnectionRequests,
		getConnections,
		sendApprovalRequest,
		approveUserRequest,
		rejectUserRequest,
	};
};

export default useConnectionManager;

const getAdminRef = async () => {
	const adminsRef = collection(db, "admins");
	const q = query(adminsRef, where("role", "==", "octoMarketAdmin"));
	const querySnapshot = await getDocs(q);

	if (!querySnapshot.empty) {
		const firstDoc = querySnapshot.docs[0];
		const adminDocRef = doc(db, "admins", firstDoc.id);
		return adminDocRef;
	} else {
		return false;
	}
};
