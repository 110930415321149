import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import Input from "./UI/Input";
import Dropdown from "./UI/dropdowns/Dropdown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "./UI/loaders/Loader";
import { PostDriverDetails, GetNewDriverId, getAllTransporters } from "../utils/firebaseOperations";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, COLLECTIONS, db } from "../config/firebase";
import { showErrorToastMessage, showLoadingToastMessage, showSuccessToastMessage } from "../utils/helpers";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	runTransaction,
	serverTimestamp,
	setDoc,
	where,
} from "firebase/firestore";
import { LOCATION_TYPE_HOME_BASE } from "../utils/constants";
import MultiSelectRounded from "./UI/dropdowns/MultiSelectRounded";
import useTUserContext from "../context/TransporterUserContext";

const driverStatuses = [
	{ label: "Active", value: "active" },
	{ label: "Inactive", value: "inactive" },
];

const driverLocations = [
	{ label: "Location 1", value: "location1" },
	{ label: "Location 2", value: "location2" },
	{ label: "Location 3", value: "location3" },
];

const MiniDriverRegistrationForm = ({ onClose, activeSubmenu }) => {
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
		setError,
	} = useForm({
		defaultValues: {
			firstName: "",
			middleInitial: "",
			lastName: "",
			OctoDriverId: "",
			email: "",
			driverLocation: "",
			driverStatus: "active",
			portalAccess: {
				editCustomerInfo: false,
				adjustRoute: false,
				postponeStop: false,
				editPickups: false,
				weighPickups: false,
				adjustPricing: false,
				callCustomer: true,
				emailCustomer: true,
				billCustomersAndCollectPayments: false,
			},
		},
	});

	const [inProgress, setInProgress] = useState(false);
	const navigate = useNavigate();
	const [DriverOctoDriverId, setDriverOctoDriverId] = useState("");
	const { user, loading } = useTUserContext();
	const [locationOptions, setLocationOptions] = useState([]);

	useEffect(() => {
		if (!user) return;
		gerAllLocationOptions();
	}, [user]);

	useEffect(() => {
		const unsubscribe = onSnapshot(doc(db, COLLECTIONS.counters, "default"), (snapshot) => {
			let count = 0;
			if (snapshot.exists()) {
				count = snapshot.data().driverCount;
			}
			const BASE = 100000;
			return setDriverOctoDriverId(`${BASE + count + 1}`);
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const gerAllLocationOptions = async () => {
		let tempLocationOptions = [];
		console.log("user", user?.uid);
		const transporterRes = await getDoc(doc(db, COLLECTIONS.transporters, user?.uid));
		if (
			transporterRes.exists() &&
			transporterRes.data()?.transporterServiceCoordinates?.lat &&
			transporterRes.data()?.transporterServiceCoordinates?.lng
		) {
			tempLocationOptions.push({
				label: transporterRes.data()?.companyDisplayName,
				value: LOCATION_TYPE_HOME_BASE,
			});
		}
		const satelliteLocationsRes = await getDocs(
			query(
				collection(db, COLLECTIONS.satelliteLocation),
				where("transporterId", "==", user?.uid),
				where("satelliteStatus", "==", "Active")
			)
		);
		tempLocationOptions.push(
			...satelliteLocationsRes.docs.map((doc) => {
				const data = doc.data();
				return { label: data.name, value: doc.id };
			})
		);
		console.log({ tempLocationOptions });
		setValue(
			"driverLocation",
			tempLocationOptions.map((option) => option.value)
		);
		setLocationOptions(tempLocationOptions);
	};
	const onSubmitForm = async (data) => {
		data.transporterId = user?.uid;

		if (activeSubmenu === "driver") {
			showLoadingToastMessage("Saving driver details...");
			setInProgress(true);
			try {
				if (!(await checkDriverEmailAvailability(data.email))) {
					showErrorToastMessage("Email already exists");
					setInProgress(false);
					return;
				}
				const driverId = await runTransaction(db, async (transaction) => {
					let count = 0;
					const countRes = await transaction.get(doc(db, COLLECTIONS.counters, "default"));
					if (countRes.exists() && countRes?.data()?.driverCount) {
						count = countRes?.data()?.driverCount ?? 0;
					}
					const BASE = 100000;
					const driverId = `${BASE + count + 1}`;
					console.log({ driverId });
					const fullData = { ...data, OctoDriverId: driverId, createdAt: serverTimestamp() };
					transaction.set(doc(db, COLLECTIONS.drivers, driverId), fullData, { merge: true });
					transaction.set(
						doc(db, COLLECTIONS.counters, "default"),
						{
							driverCount: count + 1,
						},
						{ merge: true }
					);
					return driverId;
				});
				onClose();
				navigate(`/admin/assets/drivers/${driverId}/edit`);
				showSuccessToastMessage("Driver details saved successfully!");
			} catch (error) {
				console.error("Error saving driver details:", error);
				showErrorToastMessage("Failed to save driver details. Please try again.");
			} finally {
				setInProgress(false);
			}
		}
	};

	const checkDriverEmailAvailability = async (email) => {
		const docRes = await getDocs(query(collection(db, "drivers"), where("email", "==", email)));
		if (docRes.docs.length > 0) {
			setError("email", { type: "manual", message: "Email already exists" }, { shouldFocus: true });
			return false;
		}
		return true;
	};

	return (
		<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
			<div className="bg-white z-20 rounded-cardRadii max-w-md w-full min-h-fit">
				<form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col justify-between min-h-80 p-6">
					<div className="flex justify-between pb-2">
						<div className="flex items-center gap-4">
							<h6 className="font-bold text-xl text-black">Add New Driver</h6>
						</div>
						<button
							type="button"
							disabled={inProgress}
							className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${
								inProgress ? "" : "hover:bg-gray-200"
							}`}
							onClick={onClose}
						>
							<RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
						</button>
					</div>

					<div className="overflow-auto max-h-[60vh] px-2">
						<Controller
							name="firstName"
							control={control}
							rules={{ required: "First name is required" }}
							render={({ field: { onChange, value } }) => (
								<Input label="First Name" value={value} onChange={onChange} style="flex-col" isRequired />
							)}
						/>
						{errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}

						<Controller
							name="middleInitial"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									label="Middle Initial"
									value={value.toUpperCase()}
									onChange={(e) => onChange(e.target.value.slice(0, 1).toUpperCase())}
									style="flex-col"
								/>
							)}
						/>

						<Controller
							name="lastName"
							control={control}
							rules={{ required: "Last name is required" }}
							render={({ field: { onChange, value } }) => (
								<Input label="Last Name" value={value} onChange={onChange} style="flex-col" isRequired />
							)}
						/>
						{errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
						<Controller
							name="OctoDriverId"
							control={control}
							render={({ field: { value } }) => (
								<Input label="OCTO ID #" value={DriverOctoDriverId} style="flex-col" />
								// readOnly={true}
							)}
						/>

						<Controller
							name="email"
							control={control}
							rules={{
								required: "Email is required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Invalid email address",
								},
							}}
							render={({ field: { onChange, value } }) => (
								<Input label="Email" value={value} onChange={onChange} style="flex-col" isRequired />
							)}
						/>
						{errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}

						<Controller
							name="driverLocation"
							control={control}
							render={({ field: { onChange, value } }) => (
								<MultiSelectRounded
									styles="flex flex-col w-full gap-1"
									value={value}
									onChange={onChange}
									options={locationOptions}
									label="Driver Location Assignment"
									id="driverLocation"
								/>
							)}
						/>

						<Controller
							name="driverStatus"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Dropdown
									label="Driver Status"
									options={driverStatuses}
									value={value}
									onChange={onChange}
									styles="flex-col min-w-full"
								/>
							)}
						/>
					</div>

					<button
						type="submit"
						className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${
							inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"
						} w-full rounded-full text-white`}
					>
						{inProgress ? <Loader height="h-auto" /> : `Add New Driver`}
					</button>
				</form>
			</div>
		</div>
	);
};

export default MiniDriverRegistrationForm;
