import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import GeneratorRoutes from "./forms/GeneratorRoutes";
import Loader from "../../../../../../../components/UI/loaders/Loader";
import GeneratorProfile from "./forms/GeneratorProfile";
import GeneratorContacts from "./forms/GeneratorContacts";
import ServiceNotes from "./forms/ServiceNotes";
import NotFound from "../../../../../../../components/NotFound";
import { getGeneratorById } from "../../../../../../../utils/firebaseOperations";
import { useBreadcrumb } from "../../../../../../../context/BreadcrumbContext";
import { useDispatch } from "react-redux";
import GeneratorDashboardAdmin from "./GeneratorDashboardAdmin";
import ServiceCalender from "../../../../../../generator-portal/dashboard/components/ServiceCalender/ServiceCalender";
import useTUserContext from "../../../../../../../context/TransporterUserContext";

const DASHBOARD = "generator-dashboard";
const PROFILE = "generator-profile";
const CONTACTS = "generator-contacts";
const NOTES = "service-notes";
const ROUTES = "routes";
const GEN_HOME_ROUTE = "/admin/generators";
const SERVICE_CALENDAR = "service-calendar";

const GeneratorDetails = () => {
	const { id: genId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [generatorData, setGeneratorData] = useState({});
	const [error, setError] = useState("");
	const formName = location.pathname.split("/").pop();
	const { handleSetBreadcrumb, handleStepCompletion } = useBreadcrumb();
	const { user, loading } = useTUserContext();

	useEffect(() => {
		if (![DASHBOARD, PROFILE, CONTACTS, NOTES, ROUTES, SERVICE_CALENDAR].includes(formName)) {
			navigate(`${GEN_HOME_ROUTE}/${genId}/${DASHBOARD}`);
		}
	}, [location.pathname]);

	useEffect(() => {
		//if (formName === SERVICE_CALENDAR) return;
		if (!generatorData || (genId != generatorData.id && genId != generatorData.parentAccount)) {
			const fetchDataOfGenerator = async () => {
				const data = await getGeneratorById(genId);
				console.log({ genTransId: data, transporterId: user.uid });

				if (!data) setError("Generator not found");
				else if (data.transporterId !== user.uid) {
					if (data.subContractor?.id !== user.uid) {
						setError("Generator not found");
						return;
					}
					setData(data);
				} else {
					setData(data);
				}
				setIsLoading(false);
			};

			if (genId && user) {
				fetchDataOfGenerator();
			}
		}
	}, [genId, navigate, user, generatorData]);

	const setData = (data) => {
		setGeneratorData(data);
		handleSetBreadcrumb("Generator Dashboard", data?.octoNumber?.dashboardCompleted);
		handleStepCompletion("Generator Profile", data?.onboardingStatus?.profileCompleted);
		handleStepCompletion("Generator Contacts", data?.onboardingStatus?.contactsCompleted);
		handleStepCompletion("Service Notes", data?.onboardingStatus?.serviceNotesCompleted);
		handleStepCompletion("Routes", data?.onboardingStatus?.routesCompleted);
		handleStepCompletion("Service Calendar", data?.onboardingStatus?.profileCompleted);
	};

	const handleClick = (nextForm) => {
		navigate(`${GEN_HOME_ROUTE}/${genId}/${nextForm}`);
	};

	const formComponents = useMemo(
		() => ({
			[DASHBOARD]: (
				<GeneratorDashboardAdmin
					onNextClick={() => handleClick(DASHBOARD)}
					generatorData={generatorData}
					setGeneratorData={setGeneratorData}
				/>
			),
			[PROFILE]: (
				<GeneratorProfile
					onNextClick={() => handleClick(CONTACTS)}
					onClickBack={() => navigate()}
					generatorData={generatorData}
					setGeneratorData={setGeneratorData}
				/>
			),
			[CONTACTS]: (
				<GeneratorContacts
					onNextClick={() => handleClick(ROUTES)}
					onClickBack={() => handleClick(PROFILE)}
					generatorData={generatorData}
					setGeneratorData={setGeneratorData}
				/>
			),
			[ROUTES]: (
				<GeneratorRoutes
					onNextClick={() => console.log("Final submit")}
					onClickBack={() => handleClick(NOTES)}
					genId={generatorData?.id}
					generatorData={generatorData}
					setGeneratorData={setGeneratorData}
				/>
			),
			[SERVICE_CALENDAR]: <ServiceCalender generatorData={generatorData} />,
		}),
		[generatorData, handleClick, navigate]
	);

	if (isLoading || loading) return <Loader />;
	if (error) return <NotFound text="Generator not found" height="h-[calc(100%-80px)]" />;

	return <div className="overflow-y-auto">{formComponents[formName]}</div>;
};

export default GeneratorDetails;
