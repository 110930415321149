import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MultiselectDropdown from "../../components/UI/dropdowns/MultiselectDropdown";
import SearchBox from "../../components/UI/searchBox/Search";
import useTUserContext from "../../context/TransporterUserContext";
import { collection, doc, getDocs, onSnapshot, serverTimestamp, writeBatch } from "firebase/firestore";
import { COLLECTIONS, db } from "../../config/firebase";
import { EMAIL_TEMPLATE_TYPES } from "../../utils/constants";

const EmailList = () => {
	const [selectedStatuses, setSelectedStatuses] = useState(["all", "Active", "Inactive"]);
	const navigate = useNavigate();
	const [templates, setTemplates] = useState([]);
	const { user } = useTUserContext();
	useEffect(() => {
		if (!user) return;
		let unsubscribe = onSnapshot(collection(db, COLLECTIONS.emailTemplates, user.uid, "templates"), (snap) => {
			if (snap.docs.length > 0) {
				let data = snap.docs.map((doc) => {
					return { id: doc.id, ...doc.data() };
				});
				setTemplates(data);
			} else {
				createDefaultTemplates(user.uid);
			}
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);
	const createDefaultTemplates = async (uid) => {
		const allTemplatesRes = await getDocs(collection(db, COLLECTIONS.defaultEmailTemplates));
		if (!allTemplatesRes.docs.length) return;
		const allTemplates = allTemplatesRes.docs.map((doc) => doc.data());
		const templates = allTemplates.map((template) => {
			return { ...template, createdBy: "System", createdAt: serverTimestamp(), updatedAt: serverTimestamp() };
		});
		const batch = writeBatch(db);
		templates.forEach(async (template) => {
			batch.set(doc(db, COLLECTIONS.emailTemplates, uid, "templates", template.id), template);
		});
		await batch.commit();
	};
	// Handler for row click
	const handleRowClick = (id) => {
		navigate(`email/edit/${id}`);
	};

	const renderTableRows = (data) => {
		return data.map((item) => (
			<tr
				key={item.id}
				className="border-b border-gray-300 text-sm hover:bg-gray-50 cursor-pointer"
				onClick={() => handleRowClick(item.id)}
			>
				<td className="py-4 px-3">
					<span className="text-blue-500">{item.name}</span>
				</td>
				<td className="py-4 px-3 text-gray-600">{item?.createdBy ?? "--"}</td>
				<td className="py-4 px-3 text-gray-600">{item?.triggerType ?? "--"}</td>
				<td className="py-4 px-3 text-gray-600">{item?.replyRate ?? "--"}</td>
				<td className="py-4 px-3 text-gray-600">{item?.clickThroughRate ?? "--"}</td>
			</tr>
		));
	};

	return (
		<div className="max-w-full p-6 bg-gray-100">
			{/* Header */}
			<div className="">
				<div className="mb-2">
					<h1 className="text-xl font-bold">Email List</h1>
				</div>
				<div className="flex ">
					<div className="flex gap-3 ml-auto">
						<div className="ml-8">
							<SearchBox />
						</div>
						<div>
							<MultiselectDropdown
								buttonText="Selection"
								options={[
									{ label: "All", value: "All" },
									{ label: "Billing", value: "Billing" },
									{ label: "Service", value: "Service" },
								]}
								selectedOptions={selectedStatuses}
							/>
						</div>
						<div>
							<MultiselectDropdown
								buttonText="Trigger Type"
								options={[
									{ label: "All", value: "All" },
									{ label: "Automated", value: "Automated" },
									{ label: "Manual", value: "Manual" },
								]}
								selectedOptions={selectedStatuses}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* Billing Section */}
			<div className="my-8 bg-white rounded-xl">
				<div className="inline-block mx-4 py-2 font-medium">Service</div>
				<div className="mt-3 overflow-x-auto">
					<table className="min-w-full">
						<thead>
							<tr className="bg-blue-50 text-sm">
								<th className="py-3 px-3 text-left font-medium text-gray-700">Name</th>
								<th className="py-3 px-3 text-left font-medium text-gray-700">Created By</th>
								<th className="py-3 px-3 text-left font-medium text-gray-700">Trigger Type</th>
								<th className="py-3 px-3 text-left font-medium text-gray-700">Reply Rate</th>
								<th className="py-3 px-3 text-left font-medium text-gray-700">Click-through Rate</th>
							</tr>
						</thead>
						<tbody>{renderTableRows(templates.filter((el) => el.type === EMAIL_TEMPLATE_TYPES.SERVICE))}</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default EmailList;
