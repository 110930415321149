import ServiceCard from "./ServiceCard";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { createRef, useEffect, useMemo, useState } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ITEM_TYPE_MAP, itemsMap, LOCATION_TYPE, LOCATION_TYPE_HOME_BASE } from "../../../../../../utils/constants";
import { formatDuration, formatUtcDateString } from "../../../../../../utils/helpers";
import { MdDragIndicator } from "react-icons/md";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import {
	convertISODateStringToFormattedDate,
	formattedDate,
	generateUTCDate,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../utils/helpers";
import { doc, onSnapshot, serverTimestamp, Timestamp, updateDoc, writeBatch } from "firebase/firestore";
import { COLLECTIONS, db, getRouteSheetUrl } from "../../../../../../config/firebase";
import { useDroppable } from "@dnd-kit/core";

const RouteColumn = ({
	id,
	waypoints,
	routeId,
	dailyRouteId,
	routeLabel,
	locations,
	defaultStartLocation,
	defaultEndLocation,
	treatmentFacilities,
	vendorMarkers,
	satelliteLocations,
	transporterData,
	setRouteColumns,
	color,
	isRouteReversed,
	totalTimeInSeconds,
	totalDistanceInMeters,
	assignedDriverName,
	assignedServiceVehicleName,
	assignedServiceVehicleId,
	drivers,
	assignedDriverId,
	routes,
	setCurrentRouteSplitId,
	routeUpdatingIds = [],
	removeServiceById = () => {},
	itemsOptions,
	itemsMap,
	checkForChanges,
	setRouteUpdatingIds,
}) => {
	const [toolsOpen, setToolsOpen] = useState(false);
	const [statsOpen, setStatsOpen] = useState(false);
	const [noOfSplit, setNoOfSplit] = useState(1);
	const [allExpanded, setAllExpanded] = useState(false);
	const [selectedStartLocation, setSelectedStartLocation] = useState(defaultStartLocation.id);
	const [selectedEndLocation, setSelectedEndLocation] = useState(defaultEndLocation.id);
	const [assignedDriver, setAssignedDriver] = useState(assignedDriverId);
	const [routeSplits, setRouteSplits] = useState([]);
	const [isRouteSplitOpen, setIsRouteSplitOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [currentDailyRouteData, setCurrentDailyRouteData] = useState(null);
	const [lengthInMeters, setLengthInMeters] = useState(0);
	const [travelTimeInSeconds, setTravelTimeInSeconds] = useState(0);

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id,
		data: { type: ITEM_TYPE_MAP.ROUTES },
	});
	const { droppableNodeRef } = useDroppable({
		id: id + "droppable",
		data: {
			accepts: [ITEM_TYPE_MAP.SERVICE],
		},
	});

	const optimizeRouteHandler = async () => {
		if (!dailyRouteId) {
			showErrorToastMessage("No daily route id found.");
			return;
		}
		if (!routeId) {
			showErrorToastMessage("No route id found.");
			return;
		}
		if (typeof defaultEndLocation?.lat === "undefined" || typeof defaultEndLocation?.lng === "undefined") {
			showErrorToastMessage("End location not correct.");
			return;
		}
		if (typeof defaultStartLocation?.lat === "undefined" || typeof defaultStartLocation?.lng === "undefined") {
			showErrorToastMessage("Start location not correct.");
			return;
		}
		if (!waypoints?.length) {
			showErrorToastMessage("No service found on the route.");
			return;
		}
		try {
			showLoadingToastMessage("Optimizing Route");
			const res = await fetch(
				`https://us-central1-routes-123.cloudfunctions.net/routeOptimizationHandler?dailyRouteId=${dailyRouteId}&routeId=${routeId}`,
				{
					body: JSON.stringify({ waypoints, defaultEndLocation, defaultStartLocation }),
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (res?.status) {
				const { finalWaypoints, optimizedOrder } = await res.json();

				console.log(finalWaypoints);
				console.log(optimizedOrder);

				if (finalWaypoints && finalWaypoints?.length) {
					console.log("updating route");
					setRouteColumns((prev) => {
						return prev.map((el) => {
							if (el.id === id) {
								el.waypoints = [...finalWaypoints];
							}
							return el;
						});
					});
				}
			}
			checkForChanges();
			showSuccessToastMessage("Route Optimization Successful.");
		} catch (error) {
			console.log(error);
			showInternalServerErrorToastMessage();
		}
	};
	const updateServiceIsLockedHandler = (isLocked, serviceId) => {
		setRouteColumns((prev) => {
			return prev.map((route) => {
				if (route.id === id) {
					return {
						...route,
						waypoints: route.waypoints.map((wp) => {
							if (wp.serviceId === serviceId) {
								return { ...wp, isLocked: isLocked };
							}
							return wp;
						}),
					};
				}
				return route;
			});
		});
	};

	const routeReverseHandler = (isReversed) => {
		console.log({ isReversed });
		setRouteColumns((prev) => {
			return prev.map((route) => {
				if (route.id === id) {
					const lockedWaypoints = route.waypoints.filter((wp) => wp.isLocked);
					const lockedIndices = {};
					route.waypoints.forEach((wp, idx) => {
						if (wp.isLocked) {
							lockedIndices[wp.serviceId] = idx;
						}
					});
					const unlockedWaypoints = route.waypoints.filter((wp) => !wp.isLocked).reverse();
					const newWaypoints = Array(route.waypoints.length);
					lockedWaypoints.forEach((wp) => {
						newWaypoints[lockedIndices[wp.serviceId]] = wp;
					});
					let unlockedIndex = 0;
					for (let i = 0; i < newWaypoints.length; i++) {
						if (!newWaypoints[i]) {
							newWaypoints[i] = unlockedWaypoints[unlockedIndex++];
						}
					}
					return {
						...route,
						waypoints: newWaypoints,
						isRouteReversed: isReversed,
					};
				}
				return route;
			});
		});
		setRouteUpdatingIds((prev) => Array.from(new Set([...prev, id])));
	};

	const printRouteSheetHandler = async () => {
		const tempExpectedContainersSet = new Set();

		const tempExpectedContainers = [];
		waypoints.forEach((waypoint) => {
			waypoint.scheduleData?.expectedItemOrService.forEach((el) => {
				tempExpectedContainersSet.add(el.item);
			});
		});
		const tempExpectedContainersArray = Array.from(tempExpectedContainersSet);
		tempExpectedContainersArray.forEach((container) => {
			let quantity = 0;
			waypoints.forEach((waypoint) => {
				waypoint.scheduleData?.expectedItemOrService.forEach((el) => {
					if (el.item === container) {
						quantity += el.quantity;
					}
				});
			});
			tempExpectedContainers.push({ containerName: itemsMap[container], quantity });
		});
		const dataToSend = {
			data: {
				routeId,
				dailyRouteId,
				routeDate: formatUtcDateString(dailyRouteId),
				expectedContainers: tempExpectedContainers,
				routeLabel,
				transporterData,
				waypoints: waypoints.map((waypoint) => {
					return {
						...waypoint,
						scheduleData: {
							...waypoint.scheduleData,
							serviceFrequency: {
								...waypoint.scheduleData.serviceFrequency,
								type:
									waypoint.scheduleData.serviceFrequency.type === "MTWM"
										? "MTW"
										: waypoint.scheduleData.serviceFrequency.type,
							},
							expectedItemOrService: waypoint.scheduleData?.expectedItemOrService.map((el) => {
								return {
									...el,
									item: itemsMap[el.item],
								};
							}),
						},
					};
				}),
			},
			stringTemplate: routeSheetTemplateString,
		};
		document.getElementById("generating_route_sheet" + id).showModal();
		try {
			const res = await fetch(getRouteSheetUrl(), {
				body: JSON.stringify(dataToSend),
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (res.status !== 200) {
				showErrorToastMessage("Error generating pdf");
				return;
			}
			const pdfLink = await res.json();
			window.open(pdfLink.finalUrl, "_blank");
		} catch (error) {
			console.log(error);
			showInternalServerErrorToastMessage();
		} finally {
			document.getElementById("generating_route_sheet" + id).close();
		}
	};

	const renderExpectedItemsQuantity = useMemo(() => {
		return waypoints.reduce((acc, curr) => {
			if (curr.scheduleData?.expectedItemOrService?.length) {
				return (
					acc + curr.scheduleData.expectedItemOrService.reduce((itemAcc, itemCurr) => itemAcc + itemCurr.quantity, 0)
				);
			}
			return acc;
		}, 0);
	}, [waypoints]);
	const renderIsUpdating = (routeUpdatingIds, id) => {
		if (routeUpdatingIds.includes(id)) {
			return true;
		}
		return false;
	};

	const renderEndLocation = (type) => {
		if (type === LOCATION_TYPE.TSTF) {
			return "TF/TS";
		}
		return type;
	};
	return (
		<div
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Transform.toString(transform),
			}}
			className={`border ${
				renderIsUpdating(routeUpdatingIds, id) ? "border-red-500 shadow-md shadow-red-500 " : "border-gray-300"
			} p-1 rounded-lg min-w-56 max-w-56 text-xs bg-white ${isDragging ? "border-primary-500" : ""}`}
		>
			<div className={`flex flex-col gap-2 ${isDragging ? "opacity-0" : "opacity-100"} h-full`}>
				<div className="flex w-full item-center justify-center">
					<div className="text-sm px-1 text-center">
						<p className="font-semibold">{routeLabel}</p>
						<p className="font-semibold">{convertISODateStringToFormattedDate(dailyRouteId)}</p>
					</div>
				</div>
				<div className="border-2 rounded-md">
					<button
						className="transition-all duration-500 w-full flex items-center p-1 justify-between bg-gray-200"
						onClick={() => {
							setToolsOpen((prev) => !prev);
						}}
					>
						<p>Routing Tools</p>
						{!toolsOpen && <GoChevronDown />}
						{toolsOpen && <GoChevronUp />}
					</button>
					{toolsOpen && (
						<div className="p-1.5 flex flex-col gap-2">
							<div className="flex gap-1 items-center justify-between">
								<button
									onClick={() => {
										document.getElementById(`start_location_${id}`).showModal();
									}}
									className="text-[12px] border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Start Location
								</button>

								<button
									className="text-[12px] border border-red-500 text-red-500 hover:bg-red-500 hover:text-white transition-all duration-200 rounded-full px-2 whitespace-nowrap"
									onClick={() => {
										document.getElementById(`end_location_${id}`).showModal();
									}}
								>
									End Location
								</button>
								<dialog id={`start_location_${id}`} className="modal">
									<div className="modal-box h-80">
										<form method="dialog">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`start_location_${id}`).close();
												}}
											>
												✕
											</button>
											<h3 className="font-bold text-lg">Update Start Location</h3>

											<div className="overflow-visible z-10 flex flex-col py-5">
												<Dropdown
													styles="flex-col min-w-full text-sm"
													isRequired={true}
													onChange={(value) => {
														setSelectedStartLocation(value);
													}}
													placeholder="Start Location"
													label={"Select Start Location"}
													value={selectedStartLocation}
													options={locations}
												/>
											</div>
											<div className="h-20">
												<p>
													Note: Changing the start location from here only updates the start location of the selected
													date.
												</p>
											</div>
											<div className="flex justify-between">
												<button
													className="btn btn-primary btn-sm"
													disabled={!selectedStartLocation || selectedStartLocation === defaultStartLocation.id}
													onClick={async () => {
														let dataToUpdate = {};
														if (selectedStartLocation === LOCATION_TYPE_HOME_BASE) {
															dataToUpdate.defaultStartLocation = {
																id: LOCATION_TYPE_HOME_BASE,
																lat: transporterData?.transporterServiceCoordinates?.lat,
																lng: transporterData?.transporterServiceCoordinates?.lng,
																name: transporterData?.companyDisplayName ?? "",
																type: LOCATION_TYPE.TRANSPORTER,
															};
														}
														console.log({ selectedStartLocation });
														console.log({ satelliteLocations });
														const foundSatellite = satelliteLocations.find((el) => el.id === selectedStartLocation);
														console.log(foundSatellite);
														if (foundSatellite) {
															dataToUpdate.defaultStartLocation = {
																id: foundSatellite.id,
																lat: foundSatellite.satelliteServiceCoordinates.lat,
																lng: foundSatellite.satelliteServiceCoordinates.lng,
																name: foundSatellite.name,
																type: LOCATION_TYPE.SATELLITE,
															};
														}
														const foundVendor = vendorMarkers.find((el) => el.id === selectedStartLocation);
														console.log(foundVendor);
														if (foundVendor) {
															dataToUpdate.defaultStartLocation = {
																id: foundVendor.id,
																lat: foundVendor.vendorCoordinates.lat,
																lng: foundVendor.vendorCoordinates.lng,
																name: foundVendor.name,
																type: LOCATION_TYPE.VENDOR,
															};
														}
														const foundTreatment = treatmentFacilities.find((el) => el.id === selectedStartLocation);
														if (foundTreatment) {
															dataToUpdate.defaultStartLocation = {
																id: foundTreatment.id,
																lat: foundTreatment.treatmentCoordinates.lat,
																lng: foundTreatment.treatmentCoordinates.lng,
																name: foundTreatment.name,
																type: LOCATION_TYPE.TSTF,
															};
														}
														if (dataToUpdate.defaultStartLocation) {
															try {
																await updateDoc(
																	doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																	dataToUpdate
																);
																setRouteColumns((prev) => {
																	return prev.map((el) => {
																		if (el.id === id) {
																			el.defaultStartLocation = dataToUpdate.defaultStartLocation;
																		}
																		return el;
																	});
																});
																showSuccessToastMessage("Start Location Updated Successfully.");
															} catch (error) {
																console.log(error);
																showInternalServerErrorToastMessage();
															}
														}
													}}
												>
													OK
												</button>
												<button
													className="btn btn-error btn-sm"
													onClick={() => {
														console.log("Cancelling");
														document.getElementById(`start_location_${id}`).close();
													}}
												>
													Cancel
												</button>
											</div>
										</form>
									</div>
								</dialog>
								<dialog id={`end_location_${id}`} className="modal">
									<div className="modal-box h-80">
										<form method="dialog">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`end_location_${id}`).close();
												}}
											>
												✕
											</button>
											<h3 className="font-bold text-lg">Update End Location</h3>

											<div className="overflow-visible z-10 flex flex-col py-5">
												<Dropdown
													styles="flex-col min-w-full text-sm"
													isRequired={true}
													onChange={(value) => {
														setSelectedEndLocation(value);
													}}
													placeholder="End Location"
													label={"Select End Location"}
													value={selectedEndLocation}
													options={locations}
												/>
											</div>
											<div className="h-20">
												<p>
													Note: Changing the end location from here only updates the end location of the selected date.
												</p>
											</div>
											<div className="flex justify-between">
												<button
													className="btn btn-primary btn-sm"
													disabled={!selectedEndLocation || selectedEndLocation === defaultEndLocation.id}
													onClick={async () => {
														let dataToUpdate = {};
														if (selectedEndLocation === LOCATION_TYPE_HOME_BASE) {
															dataToUpdate.defaultEndLocation = {
																id: LOCATION_TYPE_HOME_BASE,
																lat: transporterData?.transporterServiceCoordinates?.lat,
																lng: transporterData?.transporterServiceCoordinates?.lng,
																name: transporterData?.companyDisplayName ?? "",
																type: LOCATION_TYPE.TRANSPORTER,
															};
														}
														const foundSatellite = satelliteLocations.find((el) => el.id === selectedEndLocation);
														if (foundSatellite) {
															dataToUpdate.defaultEndLocation = {
																id: foundSatellite.id,
																lat: foundSatellite.satelliteServiceCoordinates.lat,
																lng: foundSatellite.satelliteServiceCoordinates.lng,
																name: foundSatellite.name,
																type: LOCATION_TYPE.SATELLITE,
															};
														}
														const foundVendor = vendorMarkers.find((el) => el.id === selectedEndLocation);
														console.log(foundVendor);
														if (foundVendor) {
															dataToUpdate.defaultEndLocation = {
																id: foundVendor.id,
																lat: foundVendor.vendorCoordinates.lat,
																lng: foundVendor.vendorCoordinates.lng,
																name: foundVendor.name,
																type: LOCATION_TYPE.VENDOR,
															};
														}
														const foundTreatment = treatmentFacilities.find((el) => el.id === selectedEndLocation);
														if (foundTreatment) {
															dataToUpdate.defaultEndLocation = {
																id: foundTreatment.id,
																lat: foundTreatment.treatmentCoordinates.lat,
																lng: foundTreatment.treatmentCoordinates.lng,
																name: foundTreatment.name,
																type: LOCATION_TYPE.TSTF,
															};
														}
														if (dataToUpdate.defaultEndLocation) {
															try {
																await updateDoc(
																	doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																	dataToUpdate
																);
																setRouteColumns((prev) => {
																	return prev.map((el) => {
																		if (el.id === id) {
																			el.defaultEndLocation = dataToUpdate.defaultEndLocation;
																		}
																		return el;
																	});
																});
																showSuccessToastMessage("End Location Updated Successfully.");
															} catch (error) {
																console.log(error);
																showInternalServerErrorToastMessage();
															}
														}
													}}
												>
													OK
												</button>
												<button
													className="btn btn-error btn-sm"
													onClick={() => {
														console.log("Cancelling");
														document.getElementById(`end_location_${id}`).close();
													}}
												>
													Cancel
												</button>
											</div>
										</form>
									</div>
								</dialog>
							</div>
							<div className="flex justify-between items-center">
								<p className="text-sm cursor-default w-full">Route Direction</p>
								<label htmlFor={`direction_${id}`} className="flex items-center gap-2 justify-between cursor-pointer ">
									<div className="relative transition-all duration-500">
										<input
											id={`direction_${id}`}
											type="checkbox"
											className="toggle toggle-primary toggle-md"
											checked={!isRouteReversed}
											onChange={(e) => {
												routeReverseHandler(!e.target.checked);
											}}
										/>
										{
											<p
												className={`absolute top-1 text-[10px] text-primary-500 left-1 z-10 ${
													!isRouteReversed ? "opacity-100" : "opacity-0"
												}`}
											>
												FWD
											</p>
										}
										{
											<p
												className={`absolute top-1 text-[10px] text-gray-500 right-1 z-10 ${
													isRouteReversed ? "opacity-100" : "opacity-0"
												}`}
											>
												REV
											</p>
										}
									</div>
								</label>
							</div>
							<div className="flex flex-col gap-1">
								<button
									onClick={optimizeRouteHandler}
									className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Optimize Route
								</button>
								<button
									onClick={() => {
										setCurrentRouteSplitId(id);
										document.getElementById(`split_route`).showModal();
									}}
									disabled={routeUpdatingIds.length > 0}
									className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									Split Route
								</button>

								{/* <button
									className="p-1 text-xs border bg-primary-500 disabled:bg-gray-300 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
									disabled={!renderIsUpdating(routeUpdatingIds, id)}
									onClick={() => {
										document.getElementById(`save_route_order`).showModal();
									}}
								>
									Save Route Order
								</button> */}
								<div>
									<button
										className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-12 whitespace-nowrap"
										onClick={printRouteSheetHandler}
									>
										Print Route Sheet
									</button>
								</div>
								<button
									onClick={() => {
										if (assignedDriverId?.length > 0 || assignedDriverName?.length > 0) {
											document.getElementById(`unassign_driver_${id}`).showModal();
										} else {
											document.getElementById(`assign_driver_${id}`).showModal();
										}
									}}
									className="p-1 text-xs border bg-primary-500 text-white hover:bg-primary-500/60 transition-all duration-200 rounded-full px-2 whitespace-nowrap"
								>
									{assignedDriverId?.length > 0 || assignedDriverName?.length > 0
										? "Un-assign Driver"
										: "Assign Driver"}
									{console.log({ assignedDriverId, assignedDriverName })}
								</button>
								<dialog id={`assign_driver_${id}`} className="modal">
									<div className="modal-box h-80">
										<form method="dialog" className="h-full">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`assign_driver_${id}`).close();
												}}
											>
												✕
											</button>
											<div className="flex flex-col h-full">
												<h3 className="font-bold text-lg">Assign Driver To Route</h3>

												<div className="overflow-visible z-10 flex flex-col py-5">
													<Dropdown
														styles="flex-col min-w-full text-sm"
														isRequired={true}
														onChange={(value) => {
															setAssignedDriver(value);
														}}
														placeholder="Driver"
														label={"Select Driver"}
														value={assignedDriver}
														options={drivers.map((el) => ({ label: el.firstName + " " + el.lastName, value: el.id }))}
													/>
												</div>

												<div className="flex justify-between mt-auto">
													<button
														className="btn btn-error btn-sm"
														onClick={() => {
															console.log("Cancelling");
															document.getElementById(`assign_driver_${id}`).close();
														}}
													>
														Cancel
													</button>
													<button
														className="btn btn-primary btn-sm"
														onClick={async () => {
															if (assignedDriver) {
																try {
																	await updateDoc(
																		doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																		{
																			assignedDriverId: assignedDriver,
																			assignedDriverName:
																				drivers.find((el) => el.id === assignedDriver).firstName +
																				" " +
																				drivers.find((el) => el.id === assignedDriver).lastName,
																			isAssignedByAdmin: true,
																			updatedAt: serverTimestamp(),
																		}
																	);
																	setRouteColumns((prev) => {
																		return prev.map((el) => {
																			if (el.id === id) {
																				el.assignedDriverId = assignedDriver;
																				el.assignedDriverName =
																					drivers.find((el) => el.id === assignedDriver).firstName +
																					" " +
																					drivers.find((el) => el.id === assignedDriver).lastName;
																				el.isAssignedByAdmin = true;
																			}
																			return el;
																		});
																	});
																	showSuccessToastMessage("Driver Assigned Successfully.");
																} catch (error) {
																	console.log(error);
																	showInternalServerErrorToastMessage();
																}
															}
														}}
													>
														OK
													</button>
												</div>
											</div>
										</form>
									</div>
								</dialog>
								<dialog id={`unassign_driver_${id}`} className="modal">
									<div className="modal-box h-fit">
										<form method="dialog" className="h-full">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`unassign_driver_${id}`).close();
												}}
											>
												✕
											</button>
											<div className="flex flex-col h-full">
												<h3 className="font-bold text-lg">Un-assign Driver From Route</h3>

												<div className="overflow-visible z-10 flex flex-col py-5">
													<p>Are you sure you want to un-assign the driver from the route?</p>
												</div>

												<div className="flex justify-between mt-auto">
													<button
														className="btn btn-error btn-sm"
														onClick={() => {
															console.log("Cancelling");
															document.getElementById(`unassign_driver_${id}`).close();
														}}
													>
														Cancel
													</button>
													<button
														className="btn btn-primary btn-sm"
														onClick={async () => {
															try {
																console.log({ assignedDriverId });

																const batch = writeBatch(db);
																batch.update(
																	doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteId),
																	{
																		assignedDriverId: "",
																		assignedDriverName: "",
																		assignedServiceVehicleId: "",
																		assignedServiceVehicleName: "",
																		isAssignedByAdmin: true,
																		updatedAt: serverTimestamp(),
																	}
																);
																if (assignedServiceVehicleId && assignedServiceVehicleId?.length > 0) {
																	batch.update(doc(db, COLLECTIONS.serviceVehicles, assignedServiceVehicleId), {
																		assignedDriverId: "",
																		assignedDriverName: ``,
																		assignedRouteId: "",
																		assignedRouteName: "",
																		assignedRouteDailyRouteId: "",
																		updatedAt: serverTimestamp(),
																	});
																}
																if (assignedDriverId) {
																	batch.update(doc(db, COLLECTIONS.drivers, assignedDriverId.toString()), {
																		currentlyAssignedDailyRouteId: "",
																		currentlyAssignedRouteId: "",
																		currentlyAssignedRouteName: "",
																		updatedAt: serverTimestamp(),
																	});
																}
																await batch.commit();
																setRouteColumns((prev) => {
																	return prev.map((el) => {
																		if (el.id === id) {
																			el.assignedDriverId = "";
																			el.assignedDriverName = "";
																			el.assignedServiceVehicleId = "";
																			el.assignedServiceVehicleName = "";
																			el.isAssignedByAdmin = false;
																		}
																		return el;
																	});
																});
																showSuccessToastMessage("Driver Un-assigned Successfully.");
															} catch (error) {
																console.log(error);
																showInternalServerErrorToastMessage();
															}
														}}
													>
														OK
													</button>
												</div>
											</div>
										</form>
									</div>
								</dialog>
							</div>
						</div>
					)}
				</div>
				<div className="border-2 rounded-md">
					<button
						className="transition-all duration-500 w-full flex items-center p-1 justify-between bg-gray-200"
						onClick={() => {
							setStatsOpen((prev) => !prev);
						}}
					>
						<p>Stats For Nerds</p>
						{!statsOpen && <GoChevronDown />}
						{statsOpen && <GoChevronUp />}
					</button>
					<div className={`p-1 ${statsOpen ? "flex" : "hidden"} flex-col gap-2`}>
						<p>
							<span className="font-semibold">Services: </span>
							<span>{waypoints?.length ?? 0}</span>
						</p>
						<p>
							<span className="font-semibold">Time: </span>
							<span id={`totalTimeInSeconds_${id}`}>0 hours</span>
						</p>
						<p>
							<span className="font-semibold">Distance: </span>
							<span id={`totalDistanceInMeters_${id}`}>0 Mile(s)</span>
						</p>
						<p>
							<span className="font-semibold">Service Vehicle: </span>
							<span>{assignedServiceVehicleName}</span>
						</p>
						<p>
							<span className="font-semibold">Driver: </span>
							{assignedDriverName}
						</p>
						<p>
							<span className="font-semibold">Expected Containers: </span>
							{renderExpectedItemsQuantity}
						</p>
					</div>
				</div>
				<button
					onClick={() => {
						setAllExpanded((prev) => !prev);
					}}
				>
					{allExpanded ? "Collapse" : "Expand"} All Services
				</button>
				<div className="flex flex-col h-full ">
					{
						<SortableContext items={waypoints.map((waypoint) => waypoint.serviceId)}>
							{
								<div className="flex flex-col">
									{waypoints.map((waypoint, index) => (
										<ServiceCard
											columnId={id}
											index={index}
											dailyRouteId={dailyRouteId}
											key={waypoint.serviceId}
											id={waypoint.serviceId}
											text={waypoint.type + waypoint.id}
											generatorData={waypoint.generatorData}
											serviceData={waypoint.serviceData}
											isLocked={waypoint.isLocked}
											scheduleData={waypoint.scheduleData}
											allExpanded={allExpanded}
											updateServiceIsLockedHandler={updateServiceIsLockedHandler}
											routeColor={color ?? "#FFA500"}
											nextWaypoint={waypoints[index + 1] ?? null}
											lat={waypoint.lat}
											lng={waypoint.lng}
											defaultEndLocation={defaultEndLocation}
											removeServiceById={removeServiceById}
											itemsMap={itemsMap}
											itemsOptions={itemsOptions}
										/>
									))}
									<div className="mt-auto px-2 text-left bg-logoutBtn rounded-md shadow-md text-xxs">
										<div className="font-semibold text-center">End Location</div>
										<div className="font-semibold">Name:</div>
										<div>{defaultEndLocation?.name ?? ""}</div>
										<div className="font-semibold">Type:</div>
										<div className="capitalize">
											{defaultEndLocation?.type ? renderEndLocation(defaultEndLocation?.type) : ""}
										</div>
									</div>
								</div>
							}
						</SortableContext>
					}
				</div>
			</div>
			<dialog id={`generating_route_sheet${id}`} className="modal">
				<div className="modal-box">
					<form method="dialog">
						<h3 className="font-bold text-lg">Please Wait while we generate the route sheet</h3>

						<div className="h-20">
							<p>Note: This process may take upto 1 min. Thank you for your patience.</p>
						</div>
					</form>
				</div>
			</dialog>
		</div>
	);
};
export default RouteColumn;

const routeSheetTemplateString = `
<!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<title>Medical Waste Route Sheet</title>

		<!-- Updated to newer version -->
	</head>
	<body>
		<div>
			<style>
				@page {
					size: letter;
					margin: 0.25in 0.25in 0.25in 0.25in;
				}

				body {
					font-family: Arial, sans-serif;
					font-size: 8px;
					margin: 0;
					padding: 10mm;
					box-sizing: border-box;
				}
				.route-sheet-container {
					box-sizing: border-box;
				}
				.route-title {
					text-align: center;
					margin-bottom: 10px;
					font-size: 18px;
					font-weight: bold;
				}

				.route-info {
					text-align: center;
					margin-bottom: 20px;
					font-size: 14px;
					font-weight: bold;
				}

				.section-details {
					display: flex;
					flex-direction: column;
					gap: 2px;
				}

				.border-divider {
					border-top: 2px solid black;
					margin-top: 5px;
					margin-bottom: 2px;
				}

				.border-divider-gray {
					border-color: #ccc;
				}

				.margin-top-small {
					margin-top: 5px;
				}

				.margin-top-medium {
					margin-top: 20px;
				}

				.margin-top-large {
					margin-top: 30px;
				}

				.margin-top-extra-large {
					margin-top: 30px;
				}

				.gap-large {
					gap: 30px;
				}

				.font-bold {
					font-weight: bold;
				}

				table {
					width: 100%;
					border-collapse: collapse;
				}

				th,
				td {
					border: 1px solid black;
					padding: 4px;
					text-align: center;
					width: 50px;
				}

				.certification-section {
					display: flex;
					justify-content: space-between;
					gap: 30px;
					margin-top: 30px;
				}
				.generator-section {
					page-break-inside: avoid;
				}
				.generator-container {
					display: flex;
					flex-direction: column;
				}

				.section-details1 {
					margin-right: 20px;
				}
				.note {
					margin-bottom: 10px;
				}
				.grid {
					display: grid;
				}
				.grid-cols-2 {
					grid-template-columns: repeat(2, minmax(0, 1fr));
				}
				.max-h-9 {
					max-height: 2.25rem /* 36px */;
				}
				.flex {
					display: flex;
				}
				.flex-col {
					flex-direction: column;
				}
				.hidden {
					display: none;
				}
				.gap-2px {
					gap: 2px;
				}
				.ml-auto {
					margin-left: auto;
				}
				.p-1 {
					padding: 0.25rem;
				}
				.title {
					font-size: 15px;
				}
				.text-lg {
					font-size: 12px;
				}
				.text-sm {
					font-size: 10px;
					line-height: normal;
				}
				.text-xs {
					font-size: 8px;
				}
				.mx-auto {
					margin-left: auto;
					margin-right: auto;
				}
				.text-end {
					text-align: end;
					padding-right: 4px;
				}
				.text-center {
					text-align: center;
				}

				.font-italic {
					font-style: italic;
				}
				.flex {
					display: flex;
				}
				.flex-col {
					flex-direction: column;
				}
				.p-5 {
					padding: 1rem;
				}
				.pb-2 {
					padding-bottom: 0.5rem;
				}
				.relative {
					position: relative;
				}
				.right-0 {
					right: 0;
				}
				.bottom-0 {
					bottom: 0;
				}
				.pt-6 {
					padding-top: 12px;
				}
				.absolute {
					position: absolute;
				}
				.border {
					border: 1px solid #000;
				}
				.border-2 {
					border-width: 2px;
				}
				.border-black {
					border-color: #000;
				}
				.text-center {
					text-align: center;
				}
				.font-bold {
					font-weight: 700;
				}
				.bg-gray {
					background-color: #efefef;
				}
				.border-b {
					border-bottom-width: 1px;
					border-top-width: 0px;
					border-left-width: 0px;
					border-right-width: 0px;
				}

				.justify-between {
					justify-content: space-between;
				}
				.mb-1 {
					margin-bottom: 0.25rem;
				}
				.font-semibold {
					font-weight: 600;
				}
				.border-t {
					border-top-width: 1px;
					border-bottom-width: 0px;
					border-left-width: 0px;
					border-right-width: 0px;
				}
				.gap-1 {
					gap: 0.25rem;
				}
				.gap-2 {
					gap: 0.5rem;
				}
				.pt-2 {
					padding-top: 0.25rem;
				}
				.hidden {
					display: none;
				}
				.w-full {
					width: 100%;
				}
				.w-half {
					width: 50%;
				}
				.table-h {
					border: 1px solid black;
					height: 146.8px;
				}
				.w-60 {
					width: 60%;
				}
				.w-40 {
					width: 40%;
				}
				.p-2 {
					padding: 2px;
				}
				.px-2 {
					padding-left: 2px;
					padding-right: 2px;
				}
				p {
					margin: 0;
				}
				.hr {
					margin: 0;
					border: none;
					border-top: 1px dotted gray; /* Add a dotted top border */
				}
				h1 {
					margin: 0;
				}
				h2 {
					margin: 0;
				}
				.border-b {
					border-bottom-width: 1px;
				}
				.custom-border {
					border-collapse: collapse;
				}
				.border-r {
					border-right-width: 0px;
					border-top-width: 0px;
					border-bottom-width: 0px;
					border-left-width: 0px;
				}
				.text-right {
					text-align: right;
				}
				.text-left {
					text-align: left;
				}
				.w-33 {
					width: 33.333333%;
				}
				.w-50 {
					width: 50%;
				}
				.w-90 {
					width: 90%;
				}
				.-top-1 {
					top: -0.25rem /* -4px */;
				}
				.h-8 {
					height: 2rem /* 32px */;
				}
				.mr-auto {
					margin-right: auto;
				}
				.ml-auto {
					margin-left: auto;
				}
				.text-right {
					text-align: right;
				}
				.justify-between {
					justify-content: space-between;
				}
				.gap-x-2 {
					column-gap: 0.5rem /* 8px */;
				}
				.whitespace-nowrap {
    				white-space: nowrap;
				}
			</style>
			<div class="route-sheet-container">
				<div class="route-title">ROUTE SHEET</div>
				<div class="route-info">UN3291, Regulated Medical Waste, n.o.s., 6.2, PGII</div>

				<div class="flex justify-between">
					<div class="flex flex-col gap-2px w-50">
						<span>{{transporterData.companyName}}</span>
						<span>{{routeDate}}</span>
						<span>{{routeLabel}}</span>
						<span>{{transporterData.street}}</span>
						<span>{{transporterData.generalPhone}}</span>
					</div>

					<div class="flex flex-col gap-2px" style="maxWidth: 40%;width:fit">
						<div class="font-semibold">Minimum Containers Needed to Complete Route</div>
						{{#each expectedContainers}}
						<div class="flex gap-x-2 w-full">
							<span class="w-90 whitespace-nowrap">{{containerName}}</span>
							<span>{{quantity}}</span>
						</div>
						{{/each}}
					</div>
				</div>

				<div class="generator-container">
					<div class="hidden">{{#each waypoints}}</div>
					<div class="generator-section">
						<div class="border-divider"></div>
						<div class="flex relative">
							<div class="flex flex-col gap-2px w-33">
								<div>{{this.generatorData.octoNumber}}</div>
								<div>{{this.generatorData.generatorName}}</div>
								<div>
									{{this.generatorData.serviceAddress.street}}, {{this.generatorData.serviceAddress.city}},
									{{this.generatorData.serviceAddress.state}} {{this.generatorData.serviceAddress.zipCode}}
								</div>
								<div>{{this.generatorData.phone}}</div>
								<div>Service Hours: {{this.scheduleData.serviceDuration}} minutes</div>
							</div>

							<div class="flex flex-col gap-2px w-33">
								<div><span class="font-bold">DEL:</span> {{this.generatorData.deliveryNote}}</div>
								<div><span class="font-bold">PRK:</span> {{this.generatorData.parkingNote}}</div>
								<div><span class="font-bold">LOC:</span> {{this.generatorData.locationOfWaste}}</div>
								<div><span class="font-bold">Note:</span> {{this.generatorData.serviceInstructions}}</div>
								<div><span class="font-bold">Service Freq:</span> {{this.scheduleData.serviceFrequency.type}}</div>
								<div>
									<span class="font-bold">Expected Container:</span> 
								</div>
								{{#each this.scheduleData.expectedItemOrService}}
								<div class="">
									{{this.item}} ({{this.quantity}})
								</div>
								{{/each}}
							</div>

							<div class="right-table -mt-2 absolute right-0 -top-1">
								<table>
									<thead>
										<tr>
											<th>Waste Type</th>
											<th>Size</th>
											<th>Qty</th>
											<th>Weight</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Sharps/Bio</td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td>Pharmaceutical</td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td>Pathological</td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td>Chemotherapy</td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="h-8"></div>
						<div class="flex gap-2">
							<div>
								<div>
									<span class="font-bold">Generator's Certification:</span> I certify that the information provided is
									accurate and that the materials have been properly classified, described, packaged, labeled, and are
									in suitable condition for transportation in compliance with the applicable U.S. Department of
									Transportation regulations.
								</div>
								<div class="flex flex-col gap-2px">
									<div class="margin-top-extra-large">
										Representative Signature: _______________________________________
									</div>
									<div>Generator Representative (Print):</div>
									<div class="note">Notes:</div>
								</div>
							</div>
							<div>
								<div>
									<span class="font-bold">Transporter's Certification:</span> I certify that the information provided is
									true and correct, and that this load contains only untreated medical waste. I understand that any
									falsification of this manifest may result in the loss of my transporter's registration and/or the
									right to use State-authorized facilities.
								</div>
								<div class="flex flex-col gap-2px">
									<div class="margin-top-extra-large">
										Representative Signature: ________________________________________
									</div>
									<div>Transporter Representative (Print):</div>
								</div>
							</div>
						</div>
					</div>
					<div class="hidden">{{/each}}</div>
				</div>
			</div>
		</div>
	</body>
</html>
`;
