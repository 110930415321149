import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Input from "../UI/Input";
import Dropdown from "../UI/dropdowns/Dropdown";
import Loader from "../UI/loaders/Loader";
import { DEFAULT_SERVICE_VEHICLE_VALUES, LOCATION_TYPE_HOME_BASE } from "../../utils/constants";
import { getAllVehicleSatelliteLocationSnapshot } from "../../utils/firebaseOperations";
import { showErrorToastMessage, showLoadingToastMessage, showSuccessToastMessage } from "../../utils/helpers";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	runTransaction,
	serverTimestamp,
	where,
} from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../../config/firebase";
import { statusOptions, vehicleOptions } from "../../utils/vehicleConstants";
import { set } from "date-fns";
import useTUserContext from "../../context/TransporterUserContext";

const MiniServiceVehicleRegModal = ({ onClose = () => {} }) => {
	const {
		control,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: DEFAULT_SERVICE_VEHICLE_VALUES,
	});
	const [vehicleLocationOptions, setVehicleLocationOptions] = useState([{ label: "Loading", value: "loading" }]);
	const { user, loading } = useTUserContext();
	const [inProgress, setInProgress] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (!user) return;
		getAllLocationOptions();
	}, [user]);
	const getAllLocationOptions = async () => {
		let tempLocationOptions = [];
		console.log("user", user?.uid);
		const transporterRes = await getDoc(doc(db, COLLECTIONS.transporters, user?.uid));
		if (
			transporterRes.exists() &&
			transporterRes.data()?.transporterServiceCoordinates?.lat &&
			transporterRes.data()?.transporterServiceCoordinates?.lng
		) {
			tempLocationOptions.push({
				label: transporterRes.data()?.companyDisplayName ?? "",
				value: LOCATION_TYPE_HOME_BASE,
			});
		}
		const satelliteLocationsRes = await getDocs(
			query(
				collection(db, COLLECTIONS.satelliteLocation),
				where("transporterId", "==", user?.uid),
				where("satelliteStatus", "==", "Active")
			)
		);
		tempLocationOptions.push(
			...satelliteLocationsRes.docs.map((doc) => {
				const data = doc.data();
				return { label: data.name, value: doc.id };
			})
		);

		setVehicleLocationOptions(tempLocationOptions);
	};
	const onSubmitForm = async (data) => {
		console.log({ data });
		data.transporterId = user?.uid;
		showLoadingToastMessage("Creating New Service Vehicle");
		try {
			setInProgress(true);
			const serviceVehicleDocRef = await addDoc(collection(db, COLLECTIONS.serviceVehicles), {
				createdAt: serverTimestamp(),
				...data,
			});
			await updateServiceVehicleId(serviceVehicleDocRef.id);
			showSuccessToastMessage("Service Vehicle Created Successfully!");
			reset(DEFAULT_SERVICE_VEHICLE_VALUES);
			navigate(`/admin/assets/service-vehicles/${serviceVehicleDocRef.id}/edit`);
			onClose();
		} catch (error) {
			console.log(error);
			showErrorToastMessage("Internal Server Error.");
		} finally {
			setInProgress(false);
		}
	};
	const updateServiceVehicleId = (id) => {
		return runTransaction(db, async (transaction) => {
			let count = 0;
			const countRes = await transaction.get(doc(db, COLLECTIONS.counters, "default"));
			if (countRes.exists() && countRes?.data()?.serviceVehicleCount) {
				count = countRes?.data()?.serviceVehicleCount;
			}
			const serviceVehicleRes = await transaction.get(doc(db, COLLECTIONS.serviceVehicles, id));
			console.log({ serviceVehicle: serviceVehicleRes.data() });

			count++;

			if (serviceVehicleRes.exists() && !serviceVehicleRes?.data()?.serviceVehicleId) {
				transaction.set(
					doc(db, COLLECTIONS.serviceVehicles, id),
					{
						serviceVehicleId: `SV${count.toString().padStart(7, "0")}`,
					},
					{ merge: true }
				);
				transaction.set(
					doc(db, COLLECTIONS.counters, "default"),
					{
						serviceVehicleCount: count,
					},
					{ merge: true }
				);
			}
		});
	};
	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<div className="z-10 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
				<div className="flex flex-col justify-between p-6 overflow-visible bg-white z-auto rounded-cardRadii max-w-md w-full min-h-fit">
					<div className="flex justify-between pb-2">
						<div className="flex items-center gap-4">
							<h6 className="font-bold text-xl text-black">Add New Service Vehicle</h6>
						</div>
						<button
							type="button"
							disabled={inProgress}
							className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${
								inProgress ? "" : "hover:bg-gray-200"
							}`}
							onClick={onClose}
						>
							<RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
						</button>
					</div>
					<div className="max-h-[60vh] px-2">
						<Controller
							name="name"
							control={control}
							rules={{ required: "Nick name is required." }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Input
										value={value}
										onChange={onChange}
										label="Nickname"
										isRequired
										className="w-full px-2 py-1 text-sm"
										style="flex-col gap-1"
										margin="mt-1"
									/>
									{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
								</div>
							)}
						/>
						<Controller
							name="locationAssignment"
							control={control}
							rules={{ required: "Vehicle Location Assignment is required." }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										value={value}
										onChange={onChange}
										options={vehicleLocationOptions}
										label="Vehicle Location Assignment *"
										className="text-sm"
										styles="flex-col gap-1"
										margin="1"
									/>
									{errors.locationAssignment && (
										<p className="text-red-500 text-sm mt-1">{errors.locationAssignment?.message}</p>
									)}
								</div>
							)}
						/>
						<Controller
							name="vehicleType"
							control={control}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										value={value}
										onChange={onChange}
										options={vehicleOptions}
										label="Vehicle Type"
										className="text-sm"
										styles="flex-col gap-1"
										margin="1"
									/>
									{errors.vehicleType && <p className="text-red-500 text-sm mt-1">{errors.vehicleType?.message}</p>}
								</div>
							)}
						/>
						<Controller
							name="status"
							control={control}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										value={value}
										onChange={onChange}
										options={statusOptions}
										label="Status"
										className="text-sm"
										styles="flex-col gap-1"
										margin="1"
									/>
									{errors.status && <p className="text-red-500 text-sm mt-1">{errors.status?.message}</p>}
								</div>
							)}
						/>
						<button
							type="submit"
							className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${
								inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"
							} w-full rounded-full text-white`}
						>
							{inProgress ? <Loader height="h-auto" /> : `Add New Service Vehicle`}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

MiniServiceVehicleRegModal.propTypes = {
	onClose: PropTypes.func,
};

export default MiniServiceVehicleRegModal;
