import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { MdClear, MdContentCopy, MdContentPaste } from "react-icons/md";
import { toast, Zoom } from "react-toastify";
import Checkbox from "../../../../../../../../../components/UI/Checkbox";
import Dropdown from "../../../../../../../../../components/UI/dropdowns/Dropdown";
import { usTimeZones } from "../../../../../../../../../utils/constants";
import SearchableDropdown from "../../../../../../../../../components/UI/dropdowns/SearchableDropdown";

const GeneratorWorkingHours = ({ control, setValue, getValues, watch, errors }) => {
	const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const [copiedValues, setCopiedValues] = useState(null);

	const timeDefaults = {
		open: "8:00 AM",
		lunchStart: "11:30 AM",
		lunchEnd: "12:30 PM",
		close: "5:00 PM",
	};

	const generateTimeOptions = (startTime) => {
		const times = [];
		for (let hour = 0; hour < 24; hour++) {
			for (let minute = 0; minute < 60; minute += 30) {
				const ampm = hour < 12 ? "AM" : "PM";
				const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
				const timeLabel = `${hour12 < 10 ? "" : ""}${hour12}:${minute === 0 ? "00" : minute} ${ampm}`;
				times.push({ label: timeLabel, value: timeLabel });
			}
		}

		if (startTime) {
			const startIndex = times.findIndex((time) => time.value === startTime);
			if (startIndex !== -1) {
				const beforeStartTimes = times.slice(0, startIndex);
				const afterStartTimes = times.slice(startIndex);
				return [...afterStartTimes, ...beforeStartTimes];
			}
		}
		return times;
	};

	const handleClosedChange = (day, isClosed) => {
		if (isClosed) {
			setValue(`workingHours.${day}.open`, "");
			setValue(`workingHours.${day}.lunchStart`, "");
			setValue(`workingHours.${day}.lunchEnd`, "");
			setValue(`workingHours.${day}.close`, "");
			setValue(`workingHours.${day}.required`, false);
		}
	};

	const handleCopy = (day) => {
		const values = getValues(`workingHours.${day}`);
		setCopiedValues(values);
		toast.success(`Copied ${day}'s working hours`, {
			autoClose: 1000,
			hideProgressBar: true,
			transition: Zoom,
		});
	};

	const handlePaste = (day) => {
		const isClosed = watch(`workingHours.${day}.closed`);
		if (isClosed) {
			toast.error(`Cannot paste to ${day} as it is closed`, {
				autoClose: 1000,
				hideProgressBar: true,
				transition: Zoom,
			});
			return;
		}
		if (copiedValues) {
			Object.keys(copiedValues).forEach((key) => {
				if (key !== "closed" && key !== "required") {
					setValue(`workingHours.${day}.${key}`, copiedValues[key]);
				}
			});
			toast.success(`Pasted working hours to ${day}`, {
				autoClose: 1000,
				hideProgressBar: true,
				transition: Zoom,
			});
		} else {
			toast.warning("No working hours copied yet", {
				autoClose: 1000,
				hideProgressBar: true,
				transition: Zoom,
			});
		}
	};

	const handleClear = (day, field) => {
		setValue(`workingHours.${day}.${field}`, "");
		toast.success(`Cleared ${field} for ${day}`, {
			autoClose: 1000,
			hideProgressBar: true,
			transition: Zoom,
		});
	};

	const TimeField = ({ day, field, label }) => {
		const isClosed = watch(`workingHours.${day}.closed`);

		return (
			<div className="relative">
				<Controller
					name={`workingHours.${day}.${field}`}
					control={control}
					render={({ field: { value, onChange } }) => (
						<>
							<SearchableDropdown
								options={generateTimeOptions(timeDefaults[field])}
								margin="0"
								value={value ?? ""}
								onChange={onChange}
								isDisabled={isClosed}
							/>
							{value && (
								<button type="button" className="absolute right-8 top-0 p-2" onClick={() => handleClear(day, field)}>
									<MdClear size={18} className="text-gray-500 hover:text-red-500" />
								</button>
							)}
						</>
					)}
				/>
				{errors.workingHours?.[day]?.[field] && (
					<p className="text-red-500 text-sm mt-1">{errors.workingHours[day][field].message}</p>
				)}
			</div>
		);
	};

	return (
		<div className="">
			<h6 className="font-medium py-2 gap-4 text-lg border-b border-[#CCCCCC]">
				Generator Office Hours: {usTimeZones.find((item) => item.value == getValues("workingHours.timeZone"))?.label}
			</h6>
			{console.log("office hours",getValues("workingHours.timeZone"))}
			<div className="flex w-full py-2 font-medium">
				<div className="min-w-40"></div>

				<div className="w-[85%] grid grid-cols-4 gap-2 2xl:gap-6 items-center text-center">
					<h6>Open</h6>
					<h6>Lunch Start</h6>
					<h6>Lunch End</h6>
					<h6>Closes</h6>
				</div>

				<span className="min-w-[15%] flex items-center gap-1 justify-center">
					<h6 className="text-center min-w-fit w-1/2">Closed</h6>
					<h6 className="min-w-fit text-center w-1/4">Copy</h6>
					<h6 className="min-w-fit text-center w-1/4">Paste</h6>
				</span>
			</div>

			<ul className="grid gap-4">
				{daysOfWeek.map((day) => {
					const isClosed = watch(`workingHours.${day}.closed`);
					return (
						<li className="flex" key={day}>
							<h6 className="min-w-40">{day}</h6>
							<div className="w-[85%] grid grid-cols-4 gap-2 2xl:gap-6 items-start text-cardTextGray">
								<TimeField day={day} field="open" label="Open" />
								<TimeField day={day} field="lunchStart" label="Lunch Start" />
								<TimeField day={day} field="lunchEnd" label="Lunch End" />
								<TimeField day={day} field="close" label="Closes" />
							</div>
							<div className="flex items-center justify-center min-w-[15%]">
								<span className="w-1/2 flex items-center justify-center">
									<Controller
										name={`workingHours.${day}.closed`}
										control={control}
										render={({ field: { value, onChange } }) => (
											<Checkbox
												isChecked={value}
												setIsChecked={(isChecked) => {
													onChange(isChecked);
													handleClosedChange(day, isChecked);
												}}
												styles="justify-center"
											/>
										)}
									/>
								</span>
								<span className="w-1/4 flex items-center justify-center">
									<MdContentCopy
										size={24}
										className="text-[#DADADA] hover:fill-cardTextBlue hover:cursor-pointer"
										onClick={() => handleCopy(day)}
									/>
								</span>
								<span className="w-1/4 flex items-center justify-center">
									<MdContentPaste
										size={24}
										className="text-[#DADADA] hover:fill-cardTextBlue hover:cursor-pointer"
										onClick={() => handlePaste(day)}
									/>
								</span>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default GeneratorWorkingHours;
