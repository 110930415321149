import { createContext, useContext, useState } from "react";

// Create Context
const GeneratorDataContext = createContext();

// Provider Component
export const GeneratorDataProvider = ({ children }) => {
    const [generatorData, setGeneratorData] = useState(null); // Shared state

    return (
        <GeneratorDataContext.Provider value={{ generatorData, setGeneratorData }}>
            {children}
        </GeneratorDataContext.Provider>
    );
};

// Custom hook for consuming context
export const useGeneratorData = () => useContext(GeneratorDataContext);
