import React from "react";
import Handlebars from "handlebars";

// Define Handlebars template
const template = Handlebars.compile(`
  <!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </head>
    <body>
        <div>
            <style>
                body {
                    font-family: Arial;
                    margin: 4px 8px;
                }
                .title{
                    font-size: 15px;
                }
                .text-lg {
                    font-size: 12px;
                }
                .text-sm {
                    font-size: 10px;
                    line-height: normal;
                }
                .text-xs {
                    font-size: 8px;
                }
                .mx-auto {
                    margin-left: auto;
                    margin-right: auto;
                }
                .text-end{
                    text-align: end;
                    padding-right: 4px;
                }
                .text-center {
                    text-align: center;
                }
                
                .font-italic {
                    font-style: italic;
                }
                .flex {
                    display: flex;
                }
                .flex-col {
                    flex-direction: column;
                }
                .p-5 {
                    padding: 1rem;
                }
                .pb-2 {
                    padding-bottom: 0.5rem;
                }
                .relative {
                    position: relative;
                }
                .right-0 {
                    right: 0;
                }
                .bottom-0 {
                    bottom: 0;
                }
                .pt-6 {
                    padding-top: 12px;
                }
                .absolute {
                    position: absolute;
                }
                .border {
                    border: 1px solid #000;
                }
                .border-2 {
                    border-width: 2px;
                }
                .border-black {
                    border-color: #000;
                }
                .text-center {
                    text-align: center;
                }
                .font-bold {
                    font-weight: 700;
                }
                .bg-gray {
                    background-color: #efefef;
                }
                .border-b {
                    border-bottom-width: 1px;
                    border-top-width: 0px;
                    border-left-width: 0px;
                    border-right-width: 0px;
                }
                

                .justify-between {
                    justify-content: space-between;
                }
                .mb-1 {
                    margin-bottom: .25rem;
                }
                .font-semibold {
                    font-weight: 600;
                }
                .border-t {
                    border-top-width: 1px;
                    border-bottom-width: 0px;
                    border-left-width: 0px;
                    border-right-width: 0px;
                }
                .gap-1 {
                    gap: 0.25rem;
                }
                .gap-2 {
                    gap: 0.5rem;
                }
                .pt-2 {
                    padding-top: 0.25rem;
                }
                .hidden {
                    display: none;
                }
                .w-full {
                    width: 100%;
                }
                .w-half {
                    width: 50%;
                }
                .table-h{
                    border: 1px solid black;
                    height: 146.8px;
                }
                .w-60{
                    width: 60%;
                }
                .w-40{
                    width: 40%;
                }
                .p-2 {
                    padding: 2px;
                }
                .px-2 {
                    padding-left: 2px;
                    padding-right: 2px;
                }
                p {
                    margin: 0;
                }
                .hr{
                    margin: 0;                
    border: none;
    border-top: 1px dotted gray; /* Add a dotted top border */

                }
                h1{
                    margin: 0;
                }
                h2{
                    margin: 0;
                }
                .border-b {
                    border-bottom-width: 1px;
                }
                .custom-border {
                    border-collapse: collapse;
                }
                .border-r {
                    border-right-width: 0px;
                    border-top-width: 0px;
                    border-bottom-width: 0px;
                    border-left-width: 0px;
                }
                .text-right {
                    text-align: right;
                }
                .text-left {
                    text-align: left;
                }
                



                @page {
                    size: letter ;
                    margin: 0.75in 0.25in 0.75in 0.25in;
                }
            </style>
            <div class="flex flex-col">
                <div class="flex pb-2 relative">
                    <div class="h-20 w-20  absolute px-2 flex items-center">
                        <img style=" width: 80px; object-fit: contain;" src="{{transporter.logo}}" alt="Image">
                        
                    </div>
                    <div class="mx-auto">
                        <h1 class="title font-bold">
                            REGULATED MEDICAL WASTE SHIPPING & TRACKING DOCUMENT
                        </h1>
                        <p class="text-center text-sm font-italic">
                            UN3291, Regulated Medical Waste, n.o.s., 6.2, PGII
                        </p>
                    </div>
                    <div class="pt-6 px-2 absolute right-0  bottom-0">
                        <p class="text-sm font-semibold">Document ID #</p>
                        <p class="text-sm mb-1">{{documentId}}</p>
                    </div>
                </div>
                <div>
                    <div class="border border-2 border-black">
                        <div>
                            <div class="bg-gray border border-b">
                                <h2 class="font-bold text-center text-lg">
                                    GENERATOR INFORMATION
                                </h2>
                            </div>
                            <div>
                                <div class="flex flex-wrap w-full">
                                    <div class="w-60">
                                        <div class="flex text-xs border-black">
                                            <div
                                                class="flex flex-col gap-1 p-2 text-sm"
                                            >
                                                <p>
                                                    <span class="font-semibold"
                                                        >Name:</span
                                                    >
                                                    {{generator.generatorName}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Address:</span
                                                    >
                                                    {{generator.serviceAddress}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Phone:</span
                                                    >
                                                    {{generator.generatorPhone}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-40">
                                        <div
                                            class="flex flex-col gap-1 p-2 text-sm"
                                        >
                                            <p>
                                                <span class="font-semibold "
                                                    >Generator OCTO ID #:</span
                                                >
                                                {{generator.octoId}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Contractor:</span
                                                >
                                                {{generator.contractor}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Registration Number:</span
                                                >
                                                {{generator.registrationNumber}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr">
                            <div
                                class="border-t border-dotted border-black p-2 text-sm"
                            >
                                <p>
                                    <span class="font-semibold"
                                        >Generator's Certification:</span
                                    >
                                    <span class="font-italic">
                                        I certify that the information provided
                                        is accurate and that the materials have
                                        been properly classified, described,
                                        packaged, labeled, and are in suitable
                                        condition for transportation in
                                        compliance with the applicable U.S.
                                        Department of Transportation
                                        regulations.
                                    </span>
                                </p>
                            </div>
                            <div class="border-black text-sm pt-6">
                                <div class="flex p-2 border-black gap-2">
                                    <p class="font-semibold">
                                        Representative Signature:
                                    </p>
                                    <img style="width: 25px; object-fit: contain;" src="{{generator.representativeSignature}}"/>
                                </div>
                                <div
                                    class="flex justify-between p-2 border-black"
                                >
                                    <div class="flex mr-1">
                                        <p class="font-semibold">
                                            Generator Representative (Print):
                                        </p>
                                        <p>{{generator.representative}}</p>
                                    </div>
                                    <div class="flex">
                                        <p class="font-semibold">Date/Time:</p>
                                        <p>{{generator.dateTime}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-2">
                    <div class="border border-2 border-black">
                        <div>
                            <div class="bg-gray border border-b">
                                <h2 class="font-bold text-center text-lg">
                                    PRIMARY TRANSPORTER INFORMATION
                                </h2>
                            </div>
                            <div>
                                <div class="flex flex-wrap w-full">
                                    <div class="w-60">
                                        <div class="text-sm border-black">
                                            <div
                                                class="flex flex-col gap-1 p-2"
                                            >
                                                <p>
                                                    <span class="font-semibold"
                                                        >Name:</span
                                                    >
                                                    {{transporter.name}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Address:</span
                                                    >
                                                    {{transporter.street}},
                                                    {{transporter.state}},
                                                    {{transporter.zipCode}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Phone:</span
                                                    >
                                                    {{transporter.dispatchPhone}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-40">
                                        <div
                                            class="flex flex-col gap-1 p-2 text-sm"
                                        >
                                            <p>
                                                <span class="font-semibold"
                                                    >Transporter OCTO ID
                                                    #:</span
                                                >
                                                {{transporter.transporterOctoId}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Subcontractor:</span
                                                >
                                                {{transporter.subcontractor}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Registration Number:</span
                                                >
                                                {{transporter.registration1}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr">
                            <div
                                class="border-t border-dotted border-black p-2 text-sm"
                            >
                                <p class="">
                                    <span class="font-semibold"
                                        >Transporter's Certification:</span
                                    >
                                    <span class="font-italic">
                                        I certify that the information provided
                                        is true and correct, and that this load
                                        contains only untreated medical waste. I
                                        understand that any falsification of
                                        this manifest may result in the loss of
                                        my transporter's registration and/or the
                                        right to use State-authorized
                                        facilities.
                                    </span>
                                </p>
                            </div>
                            <div
                                class="border-t border-dotted border-black p-2 text-sm"
                            >
                                <p>
                                    <span 
                                        >Transferred to {{transporter.serviceVehicleId}} on {{transporter.date}}</span
                                    >
                                </p>
                            </div>
                            <div class="border-black text-sm pt-6">
                                <div class="flex p-2 border-black gap-2">
                                    <p class="font-semibold">Representative Signature:</p>
                                    <img style="width: 25px; object-fit: contain;" src="{{transporter.representativeSignature}}"/>
                                </div>
                                <div
                                    class="flex justify-between p-2 border-black"
                                >
                                    <div class="flex mr-1">
                                        <p class="font-semibold">
                                            Transporter Representative (Print):
                                        </p>
                                        <p>{{transporter.representative}}</p>
                                    </div>
                                    <div class="flex">
                                        <p class="font-semibold">Date/Time:</p>
                                        <p>{{transporter.dateTime}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="pt-2">
                    <div class="border border-2">
                        <div class="bg-gray border border-b p-1">
                            <h2 class="font-bold text-center text-lg">
                                DESCRIPTION OF WASTE
                            </h2>
                            <p class="italic text-center text-xs">
                                (UN3291, Regulated Medical Waste, N.O.S., 6.2,
                                PG II)
                            </p>
                        </div>
                        <div class="flex flex-wrap w-full">
                            
                            <div class="w-half table-h">
                                <table
                                    class="border border-r border-black w-full text-xs"
                                >
                                    <thead>
                                        <tr class="border border-b">
                                            <th class="p-1 text-left">
                                                Serial Number
                                            </th>
                                            <th class="p-1 text-left">
                                                Subwaste Type
                                            </th>
                                            <th class="p-1 text-left">
                                                Container
                                            </th>
                                            <th class="p-1 text-right">
                                                Quantity
                                            </th>
                                            <th class="p-1 text-right">
                                                Weight (Lbs)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>   
                                        
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                           
                            <div class="w-half table-h">
                                <table
                                    class="border border-r border-black w-full text-xs"
                                >
                                    <thead>
                                        <tr class="border border-b">
                                            <th class="p-1 text-left">
                                                Serial Number
                                            </th>
                                            <th class="p-1 text-left">
                                                Subwaste Type
                                            </th>
                                            <th class="p-1 text-left">
                                                Container
                                            </th>
                                            <th class="p-1 text-right">
                                                Quantity
                                            </th>
                                            <th class="p-1 text-right">
                                                Weight (Lbs)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>                                    
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left p-1">
                                                {{serialNumber}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{subwasteType}}
                                            </td>
                                            <td class="text-left p-1">
                                                {{container}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{quantity}}
                                            </td>
                                            <td class="p-1 text-right">
                                                {{weight}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="border border-t bg-gray">
                            <p class="text-center text-xs font-semibold">
                                TOTALS:
                            </p>
                        </div>
                        <div class="border border-t flex flex-wrap w-full text-xs"style="height: 16px;">
                            <div class="w-half text-right px-2">
                                <p>Quantity</p>
                            </div>
                            <div class="w-half text-left px-2">
                                <p>Weight (Lbs)</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
              
                <div class="pt-2">
                    <div class="border border-2 border-black">
                        <div>
                            <div class="bg-gray border border-b">
                                <h2 class="font-bold text-center text-lg">
                                    TRANSFER STATION / TRANSPORTER #2 INFORMATION
                                </h2>
                            </div>
                            <div>
                                <div class="flex flex-wrap w-full">
                                    <div class="w-60">
                                        <div class="text-sm border-black">
                                            <div
                                                class="flex flex-col gap-1 p-2"
                                            >
                                                <p>
                                                    <span class="font-semibold"
                                                        >Name:</span
                                                    >
                                                    {{transporter.name}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Address:</span
                                                    >
                                                    {{transporter.street}},
                                                    {{transporter.state}},
                                                    {{transporter.zipCode}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Phone:</span
                                                    >
                                                    {{transporter.dispatchPhone}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-40">
                                        <div
                                            class="flex flex-col gap-1 p-2 text-sm"
                                        >
                                            <p>
                                                <span class="font-semibold"
                                                    >OCTO ID #:</span
                                                >
                                                {{transporter.octoId}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Consolidated Tracking ID #:</span
                                                >
                                                {{consolidated.trackingId}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Registration Number:</span
                                                >
                                                {{transporter.registration1}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr">
                            <div
                                class="border-t border-dotted border-black p-2 text-sm"
                            >
                                <p class="">
                                    <span class="font-semibold"
                                        >Transporter's Certification:</span
                                    >
                                    <span class="font-italic">
                                        I certify that the information provided is true and correct, and that this load contains only untreated medical waste. I understand that any falsification of this manifest may result in the loss of my transporter's registration and/or the right to use State-authorized facilities.
                                    </span>
                                </p>
                            </div>
                            <div class="border-black text-sm pt-6">
                                <div class="flex p-2 border-black gap-2">
                                    <p class="font-semibold">
                                        Representative Signature:
                                    </p>
                                    <img style="width: 25px; object-fit: contain;" src="{{transfer.representativeSignature}}"/>
                                </div>
                                <div
                                    class="flex justify-between p-2 border-black"
                                >
                                    <div class="flex mr-1">
                                        <p class="font-semibold">
                                            Transporter Representative (Print):
                                        </p>
                                        <p>{{transporter.representative}}</p>
                                    </div>
                                    <div class="flex">
                                        <p class="font-semibold">Receved Date/Time:</p>
                                        <p>{{transporter.dateTime}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-2">
                    <div class="border border-2 border-black">
                        <div>
                            <div class="bg-gray border border-b">
                                <h2 class="font-bold text-center text-lg">
                                    TREATMENT FACILITY
                                </h2>
                            </div>
                            <div>
                                <div class="flex flex-wrap w-full">
                                    <div class="w-60">
                                        <div class="text-sm border-black">
                                            <div
                                                class="flex flex-col gap-1 p-2"
                                            >
                                                <p>
                                                    <span class="font-semibold"
                                                        >Name:</span
                                                    >
                                                    {{treatment.name}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Address:</span
                                                    >
                                                    {{treatment.street}},
                                                    {{treatment.state}},
                                                    {{treatment.zipCode}}
                                                </p>
                                                <p>
                                                    <span class="font-semibold"
                                                        >Phone:</span
                                                    >
                                                    {{treatment.dispatchPhone}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-40">
                                        <div
                                            class="flex flex-col gap-1 p-2 text-sm"
                                        >
                                            <p>
                                                <span class="font-semibold"
                                                    >OCTO ID #:</span
                                                >
                                                {{treatment.octoId}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Consolidated Tracking ID #:</span
                                                >
                                                {{consolidated.trackingId}}
                                            </p>
                                            <p>
                                                <span class="font-semibold"
                                                    >Registration Number:</span
                                                >
                                                {{treatment.registration1}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr">
                            <div
                            class="border-t border-dotted border-black p-2 text-sm"
                        >
                            <p class="">
                                <span class="font-semibold"
                                    >Discrepancy Indication Space:</span
                                >
                                <span class="font-italic">
                                    
                                </span>
                            </p>
                        </div>
                            <div
                                class="border-t border-dotted border-black p-2 text-sm"
                            >
                                <p class="">
                                    <span class="font-semibold"
                                        >Treatment Facility Certification:</span
                                    >
                                    <span class="font-italic">
                                        I certify that the information provided is true and accurate. I am authorized to accept untreated medical waste and have received the specified waste in accordance with the outlined requirements.
                                    </span>
                                </p>
                            </div>
                            <div class="border-black text-sm pt-6">
                                <div class="flex p-2 border-black gap-2">
                                    <p class="font-semibold">
                                        Representative Signature:
                                    </p>
                                    <img style="width: 25px; object-fit: contain;" src="{{treatment.representativeSignature}}"/>
                                </div>
                                <div
                                    class="flex justify-between p-2 border-black"
                                >
                                    <div class="flex mr-1">
                                        <p class="font-semibold">
                                            Treatment Facility Representative (Print):
                                        </p>
                                        <p>{{treatment.representative}}</p>
                                    </div>
                                    <div class="flex flex-col ">
                                        <div class="flex p-2">
                                            <p class="font-semibold">Received Date/Time:</p>
                                            <p>{{treatment.dateTime}}</p>
                                        </div>
                                        <div class="flex p-2">
                                            <p class="font-semibold">Total Weight:</p>
                                            <p>{{totalWeight}}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border border-t px-2 text-xs" style="height: 12px;">
                            <p>Emergency Response: {{transporter.companyDisplayName}} / {{transporter.website}} / {{transporterPhone}}</p>
                        </div>
                    </div>
                </div>
                


            </div>
            
            
            <div class="w-full">
                <p class="text-center text-xs">
                    <a  href="https://www.octopussaas.com/">Powered By Octopus SaaS: OCTO Smart Manifests™
                        
                    </a>
                    <p class="text-sm text-end">Page 1 of {{totalPages}}</p>
                </p>
            </div>                            
        </div>
        
    </body>
</html>
`);

// React component
const SmartManifest = () => {
	const data = {
		documentId: "7V4ZRKHkfJIrgt7bizka",
		generator: {
			generatorName: "Simpson",
			serviceAddress: "2001 Pan American Plaza, San Diego, CA, 92101",
			generatorPhone: "",
			octoId: "1000009",
			contractor: "",
			registrationNumber: "",
			representative: "Will J Smith (FBO) Simpson",
			representativeSignature:
				"https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/default%2Fgenerators%2FgyNydgvopak5UKaBwCkm%2FdfGNpb1Bu9AIKujtktrb%2FgeneratorSignImage.png?alt=media&token=577bf431-ebf2-47e0-9aa6-a7080f8f7f28",
			dateTime: "1/3/2025, 7:52:18 pm",
		},
		transporter: {
			name: "Billy Pvt Ltd",
			street: "8940 Activity Rd ",
			state: "CA",
			zipCode: "92126",
			dispatchPhone: "(123) 456-7890",
			logo: "https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/transporters%2FcompanyLogo%2Freinventors.svg?alt=media&token=fef44d55-a820-47c4-8e09-528db0b3c227",
			octoId: "T-1001",
			subcontractor: "",
			registration1: "1234",
			representative: "Will J Smith",
			representativeSignature:
				"https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/default%2Fgenerators%2FgyNydgvopak5UKaBwCkm%2FdfGNpb1Bu9AIKujtktrb%2FdriverSignImage.png?alt=media&token=33fc3beb-5f19-46b9-9e58-cc320c6ebb65",
			dateTime: "1/3/2025, 7:52:18 pm",
			serviceVehicleId: "SV0000002",
			date: "1/3/2025, 7:52:18 pm",
		},
		treatment: {
			name: "Treatment Facility",
			address: "1234 Treatment Facility Lane, City, State, 12345",
			phone: "123-456-7890",
			octoId: "T-1234567890",
			representative: "Treatment Facility Representative",
			representativeSignature:
				"https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/default%2Fgenerators%2FgyNydgvopak5UKaBwCkm%2FdfGNpb1Bu9AIKujtktrb%2FdriverSignImage.png?alt=media&token=33fc3beb-5f19-46b9-9e58-cc320c6ebb65",
			dateTime: "01/01/2021 12:00 PM",
			totalWeight: "1000 lbs",
		},
		waste: [
			{
				serialNumber: "",
				subWasteType: "Non-RCRA Pharmaceutical",
				containerType: "4kRsV79xwoSTGm71kdci",
				containerName: "12 Gallon Rx",
				quantity: "5",
				weight: "67",
			},
		],
	};

	// Split waste array into two chunks for the two-column layout
	const wasteChunks = [
		data.waste.slice(0, Math.ceil(data.waste.length / 2)),
		data.waste.slice(Math.ceil(data.waste.length / 2)),
	];

	const formData = {
		...data,
		wasteChunks,
	};

	// Generate HTML from template
	const html = template(formData);

	return (
		<div className="max-w-4xl mx-auto my-8">
			<div dangerouslySetInnerHTML={{ __html: html }} />
		</div>
	);
};

export default SmartManifest;
