import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { AuthenticationType, AzureMap, AzureMapsContext } from "react-azure-maps";
import { data } from "azure-maps-control";
import CustomHtmlGeneratorMarker from "../../../../../../../../../components/maps/CustomHtmlGeneratorMarker";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, COLLECTIONS, db } from "../../../../../../../../../config/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { limitScrollWheelZoom } from "../../../../../../../../../utils/helpers";
import { FaMinus, FaPlus } from "react-icons/fa";
const authOptions = {
	authType: AuthenticationType.subscriptionKey,
	subscriptionKey: import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
};
function RouteAssignment({
	generatorData = {},
	allRoutes = [],
	selectedRoutes = [],
	allGeneratorsData = [],
	allTreatmentData = [],
	allVendorData = [],
	serviceSchedules = [],
	routeOptions = [],
}) {
	const [currentCenter, setCurrentCenter] = useState(new data.Position(0, 0));
	const [user] = useAuthState(auth);
	const { mapRef, isMapReady, removeMapRef } = useContext(AzureMapsContext);
	useEffect(
		() => () => {
			removeMapRef();
		},
		[]
	);

	useEffect(() => {
		if (isMapReady && mapRef && generatorData.serviceAddCoordinates.lng && generatorData.serviceAddCoordinates.lat) {
			// // markerPositions.push(new data.Position(generatorData.randomCoordinates.lng, generatorData.randomCoordinates.lat));
			console.log("generatorData", generatorData);
			const box = data.BoundingBox.fromPositions([
				new data.Position(generatorData.serviceAddCoordinates.lng, generatorData.serviceAddCoordinates.lat),
			]);
			const center = data.BoundingBox.getCenter(box);
			mapRef.setCamera({ center: center, zoom: 12, duration: 200, type: "ease" });
			limitScrollWheelZoom(mapRef);
		}
	}, [isMapReady, mapRef, generatorData]);

	const zoomIn = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom + 1 });
		}
	};

	const zoomOut = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom - 1 });
		}
	};

	return (
		<div className="h-[500px]">
			<div className="w-full h-full rounded-xl p-2 z-50 bg-white">
				<AzureMap options={{ authOptions: authOptions, view: "Auto" }}>
					{allGeneratorsData.length > 0 &&
						allGeneratorsData.map((genData) => {
							if (
								genData?.serviceAddCoordinates &&
								genData?.serviceAddCoordinates.lat &&
								genData?.serviceAddCoordinates.lng
							) {
								return (
									<CustomHtmlGeneratorMarker
										key={genData.id}
										genData={genData}
										draggable={true}
										allRoutes={allRoutes}
										isCurrent={generatorData.id === genData.id ? true : false}
									/>
								);
							}
							return null;
						})}
					<div className="absolute bottom-5 right-5 flex flex-col gap-2 z-10">
						<button
							onClick={zoomIn}
							className="w-10 h-10 flex items-center justify-center 
																	 bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
																		font-bold rounded shadow-md"
						>
							<FaPlus />
						</button>
						<button
							onClick={zoomOut}
							className="w-10 h-10 flex items-center justify-center 
																	 bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
																	 font-bold rounded shadow-md"
						>
							<FaMinus />
						</button>
					</div>
				</AzureMap>
			</div>
		</div>
	);
}

RouteAssignment.propTypes = {
	allGeneratorsData: PropTypes.array,
	allRoutes: PropTypes.array,
	allTreatmentData: PropTypes.array,
	allVendorData: PropTypes.array,
	generatorData: PropTypes.object,
	getValues: PropTypes.func,
	selectedRoutes: PropTypes.array,
};

export default RouteAssignment;
