import PropTypes from "prop-types";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { COLLECTIONS, db, getManifestUrl } from "../../../../../../../../config/firebase";
import { showErrorToastMessage } from "../../../../../../../../utils/helpers";

const GeneratorManifests = ({ serviceId, generatorId }) => {
	const [manifests, setManifests] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (!serviceId) {
			return;
		}
		if (!generatorId) return;
		let unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.manifests),
				where("serviceId", "==", serviceId),
				where("generatorId", "==", generatorId)
			),
			(snap) => {
				const tempManifests = [];
				snap.forEach((doc) => {
					tempManifests.push({ id: doc.id, ...doc.data() });
				});
				setManifests(tempManifests);
			}
		);
		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, [serviceId, generatorId]);

	const manifestClickHandler = async (id) => {
		try {
			setLoading(true);
			if (!id) return;
			const manifest = manifests.find((manifest) => manifest.id === id);
			if (!manifest) return;
			if (manifest.url) {
				window.open(manifest.url, "_blank");
				setLoading(false);
				return;
			} else {
				const fetchUrl = getManifestUrl(id);
				console.log("fetchUrl", fetchUrl);
				window.open(fetchUrl, "_blank");
				setLoading(false);
				return;
			}
			// const response = await fetch(fetchUrl);
			// if (!response.ok) {
			// 	throw new Error(`Failed to fetch manifest: ${response.status}`);
			// }
			// const data = await response.json();
			// if (data.url) {
			// 	window.open(data.url, "_blank");
			// } else {
			// 	throw new Error("Invalid response format");
			// }
		} catch (error) {
			console.error("Error downloading manifest:", error);
			showErrorToastMessage("Failed to download manifest. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex flex-col gap-2">
			{manifests.map((manifest) => (
				<button
					className="text-primary-500 underline cursor-pointer text-left"
					key={manifest.id}
					onClick={() => {
						manifestClickHandler(manifest.id);
					}}
				>
					{manifest?.manifestOctoId?.length > 0 ? manifest?.manifestOctoId : manifest.id.slice(0, 5)}
				</button>
			))}
			{loading && <p className="text-gray-500">Loading...</p>}
		</div>
	);
};

GeneratorManifests.propTypes = {
	generatorId: PropTypes.string,
	serviceId: PropTypes.string,
};

export default GeneratorManifests;
