export const statusOptions = [
	{ label: "Active", value: "ACTIVE" },
	{ label: "Inactive", value: "INACTIVE" },
	{ label: "Out Of Network Services", value: "OUT_OF_NETWORK_SERVICES" },
];
export const DEFAULT_ROUTE_VALUES = {
	routeLabel: "",
	type: [],
	defaultStartLocation: {
		name: "",
	},
	defaultEndLocation: {
		name: "",
	},
	truckCostPerMile: "",
	isActive: true,
	totalDistanceInMeters: 0,
	totalTimeInSeconds: 0,
	status: "ACTIVE",
	color: "#1493D7",
};
