import CustomAzureMap from "../../../components/maps/CustomAzureMap";
import RoundedPin from "../../admin/assets/components/routes-optimization/components/RoundedPin";
const CustomAzureGeneratorDashboardMap = ({ generatorData, randomCoordinates }) => {
	console.log({ generatorData });
	return (
		<CustomAzureMap
			zoom={15}
			markers={[
				{
					coordinates: generatorData?.serviceAddCoordinates,
					pin: <RoundedPin className=" w-12 h-12" color="red" />,
					randomCoordinates: randomCoordinates,
					draggable: true,
				},
			]}
		/>
	);
};

export default CustomAzureGeneratorDashboardMap;
