import PropTypes from "prop-types";

import React from "react";

const TodaysReportsProgressBar = ({ inComplete = 0, complete = 0, scheduled = 0 }) => {
	const totalServices = inComplete + complete + complete;

	const inCompletePercentage = ((inComplete / totalServices) * 100).toFixed(2);
	const scheduledPercentage = ((scheduled / totalServices) * 100).toFixed(2);
	const completePercentage = ((complete / totalServices) * 100).toFixed(2);

	console.log({ inComplete, scheduled, complete });

	return (
		<div className="bg-gray-200 rounded-full h-5 w-full flex text-gray-700 text-md grid grid-cols-3">
			<div className="relative bg-blue-500 h-5 rounded-l-full" style={{ width: `${100}%` }}>
				<div className="absolute top-6 right-0">{inComplete}</div>
				<div className="absolute bottom-6 left-0">Incomplete</div>
			</div>
			<div className="relative bg-red-500 h-5" style={{ width: `${100}%` }}>
				<div className="absolute top-6 right-0">{scheduled}</div>
				<div className="absolute bottom-6 left-0">Scheduled</div>
			</div>
			<div className="relative bg-green-500 h-5 rounded-r-full" style={{ width: `${100}%` }}>
				<div className="absolute top-6 right-0">{complete}</div>
				<div className="absolute bottom-6 left-0">Completed</div>
			</div>
		</div>
	);
};

TodaysReportsProgressBar.propTypes = {
	inComplete: PropTypes.number,
	complete: PropTypes.number,
	scheduled: PropTypes.number,
};
export default TodaysReportsProgressBar;
