import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, replace } from "react-router-dom";
import SidebarItem from "./SIdebarItem";
import Icon from "../Icon";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";
import { db } from "../../../config/firebase";
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
import OctoIcon from "../OCTOPUS";

const GeneratorSidebar = ({ active }) => {
	const { logout, generatorData } = useGeneratorUser();
	const [expandedItemId, setExpandedItemId] = useState(null);
	const [expandedItemIds, setExpandedItemIds] = useState([]);
	const [isScrollable, setIsScrollable] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [displayImage, setDisplayImage] = useState("");
	useEffect(() => {
		const getLogo = () => {
			if (!generatorData?.id) return;
			const transporterRef = doc(db, "transporters", generatorData.transporterId);
			onSnapshot(transporterRef, (doc) => {
				if (doc.exists()) {
					setDisplayImage(doc.data().companyLogo?.link);
				} else {
					console.log("No such document!");
				}
			});
		};
		if (generatorData) getLogo();
	}, [generatorData]);

	const menuItems = [
		{
			id: 1,
			name: "generator_dashboard",
			label: "Dashboard",
			link: "/generator-dashboard",
			iconActive: "stroke",
		},

		{
			id: 4,
			name: "service_reports",
			label: "Service Reports",
			link: "/generator-dashboard/service-reports",
			iconActive: "stroke",
		},
		{
			id: 5,
			name: "invoices_and_payments",
			label: "Invoices and Payments",
			link: "/generator-dashboard/invoices-and-payments",
			iconActive: "stroke",
			children: [
				{
					id: 11,
					name: "submenu_users",
					label: "Saved Payment Methods",
					link: "saved-payment-methods",
				},
				{
					id: 21,
					name: "submenu_reports",
					label: "Make Payment",
					link: "make-payment",
				},
			],
		},
		{
			id: 8,
			name: "service_calendar",
			label: "Service Calendar",
			link: "/generator-dashboard/service-calendar",
			iconActive: "stroke",
		},
	];

	useEffect(() => {
		const shouldCollapseSubmenu = () => {
			if (!expandedItemId) return false;
			const expandedItem = menuItems.find(
				(item) => item.id === expandedItemId && location.pathname.startsWith(item.link) && item.children
			);
			if (!expandedItem) setExpandedItemId(null);
		};
		shouldCollapseSubmenu();
	}, [location, expandedItemId]);

	const logOutHandler = async () => {
		let toRedirectLink = `/generator-portal-auth?transporterId=${generatorData?.transporterId}`;
		logout();
		navigate(toRedirectLink);
	};

	const scrollHandler = (e) => {
		setIsScrollable(e.target.scrollTop > 0);
	};

	const clickHandler = (to = "/") => navigate(to);

	return (
		<nav className="w-72 bg-white fixed hidden md:flex flex-col h-screen">
			{/* Top section */}
			<div className="px-10 min-h-20 max-h-20 flex items-center gap-4 border border-borderCol">
				<img src={displayImage} alt="" className="object-cover  h-14" />
			</div>

			<div
				className="flex-grow border border-borderCol overflow-hidden "
				style={{
					zIndex: 9999,
				}}
			>
				<div className="h-full overflow-y-auto" onScroll={scrollHandler}>
					<ul className="flex flex-col pt-6">
						{menuItems.map((item, index) => (
							<SidebarItem
								key={item.label + index}
								item={item}
								active={active === item.name}
								expandedItemId={expandedItemId}
								setExpandedItemId={setExpandedItemId}
								expandedItemIds={expandedItemIds}
								setExpandedItemIds={setExpandedItemIds}
							/>
						))}
					</ul>
				</div>
			</div>

			{/* Fixed bottom section */}
			<ul
				className={`p-4 pb-4 flex flex-col w-full mt-2 ${
					isScrollable ? " border-t border-borderCol border-opacity-50" : ""
				}`}
			>
				<li
					onClick={logOutHandler}
					className="group rounded-full text-sm xl:text-base flex items-center gap-3 bg-cardBorderCol w-full pl-4 py-2 hover:text-cardTextBlue hover:bg-[#cccccc] hover:cursor-pointer mt-2"
				>
					<Icon name={"logout"} active={active == "logout"} />
					<h6>Logout</h6>
				</li>

				<li
					onClick={() => (window.location.href = "https://www.octopussaas.com/")}
					className="group  py-2 flex items-center  xl:text-base hover:cursor-pointer mt-2 scale-150 ml-20"
				>
					<OctoIcon />
				</li>
			</ul>
		</nav>
	);
};

export default GeneratorSidebar;
