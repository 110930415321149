import react, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { serviceTypesMap } from "../../../../../utils/constants";
import Loader from "../../../../../components/UI/loaders/Loader";
import { useNavigate } from "react-router-dom";

const getFormattedAddress = (address) =>
	[address?.street, address?.city, address?.state, address?.zipCode].filter(Boolean).join(", ") || "--";

const processData = (slots, routesMap) => {
	let newData = slots.map((item) => {
		let id = item.serviceId ?? item.id;
		let data =
			serviceTypesMap[item.scheduleData?.serviceType] +
			" pick up in " +
			getFormattedAddress(item.generatorData?.billingAddress);
		let time = routesMap[item?.scheduleData?.routeId] + ` (${item.scheduleData?.serviceDuration} Minutes)`;
		return { id, data, slot: time };
	});
	return newData;
};

const TimeSlotRepeater = ({ routeColumns, routesMap, loading, selectedDate }) => {
	const [allSlots, setAllSlots] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		let allSlots = routeColumns?.map((item) => {
			const totalCompleted = item.waypoints?.filter(
				(waypoint) => waypoint?.serviceData?.status === "COMPLETED"
			)?.length;
			const total = item.waypoints?.length;
			// if (total === 0) return null;
			return {
				id: item.routeId,
				routeName: item?.routeLabel ?? "",
				routeColor: item?.color ?? "",
				assignedDriver: item?.assignedDriverName ?? "",
				vehicleName: item?.assignedServiceVehicleName ?? "",
				stops: Array.isArray(item.waypoints) ? item.waypoints?.length : 0,
				stopsLabel: `${totalCompleted} of ${total}`,
				selectedDate: selectedDate ?? new Date(),
			};
		});
		console.log({ routeColumns });

		// let finalSlots = allSlots.flat();
		// let result = processData(finalSlots, routesMap);
		// console.log("====================================");
		// console.log({ allSlots, finalSlots, routesMap, result });
		// console.log("====================================");
		// allSlots = allSlots.filter(Boolean);
		setAllSlots(allSlots);
	}, [routeColumns]);
	return (
		<>
			{loading ? (
				<Loader height="h-28" />
			) : allSlots.length > 0 ? (
				allSlots.map((item) => (
					<li key={item.id} className="bg-creamWhite flex justify-between rounded-md p-4 mb-4">
						<div className="grid gap-2">
							<div className="flex gap-2 items-center">
								<div className={`rounded-md h-4 w-4`} style={{ backgroundColor: item.routeColor }}></div>
								<h6 className="opacity-40">{item.routeName}</h6>
							</div>
							<h6 className="">Assigned Driver: {item.assignedDriver}</h6>
							<h6 className="">Number of Stops: {item.stopsLabel}</h6>
						</div>
						<div className="flex justify-center items-center">
							<IoIosArrowForward
								className="hover:cursor-pointer hover:bg-cardTextGray hover:bg-opacity-40"
								onClick={() =>
									navigate(
										`/admin/operations/route-optimization?date=${item.selectedDate?.toLocaleDateString(
											"en-US"
										)}&routeId=${item.id}`
									)
								}
							/>
						</div>
					</li>
				))
			) : (
				<div className="flex h-full text-cardTextGray text-lg justify-center pt-[25%]">
					<p>No routes found</p>
				</div>
			)}
		</>
	);
};

export default TimeSlotRepeater;
