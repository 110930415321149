import RoundedPin from "./RoundedPin";
import {
	convertMetersToMiles,
	convertSecondsToHours,
	daysOfWeek,
	renderAddress,
	showInternalServerErrorToastMessage,
	showErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
	octoFormatter,
} from "../../../../../../utils/helpers";
import Lock from "./Lock";
import Priority from "./Priority";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useEffect, useRef, useState } from "react";
import {
	ITEM_TYPE_MAP,
	SERVICE_STATUS,
	SERVICE_TYPES,
	serviceDurationOptions,
} from "../../../../../../utils/constants";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SquarePin from "./SquarePin";
import Loader from "../../../../../../components/UI/loaders/Loader";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { Link, useSearchParams } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../../config/firebase";
import { LiaFileContractSolid } from "react-icons/lia";
import { MdOutlineAccessTime } from "react-icons/md";
import { log } from "handlebars";

const ServiceCard = ({
	id,
	columnId,
	text,
	index,
	generatorData,
	serviceData,
	scheduleData,
	dailyRouteId,
	allExpanded,
	isLocked,
	updateServiceIsLockedHandler,
	routeColor = "#FFA500",
	nextWaypoint,
	lat,
	lng,
	defaultEndLocation,
	removeServiceById = () => {},
	itemsOptions,
	itemsMap,
}) => {
	const [serviceOpen, setServiceOpen] = useState(true);
	const [priority, setPriority] = useState(serviceData?.isPriority ?? false);
	const [time, setTime] = useState("");
	const [distance, setDistance] = useState("");
	const serviceCardRef = useRef();
	let [searchParams, setSearchParams] = useSearchParams();
	const currentServiceId = searchParams.get("currentServiceId");
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id,
		disabled: isLocked,
		data: { type: ITEM_TYPE_MAP.SERVICE, dailyRouteId, columnId },
	});
	const routesLibrary = useMapsLibrary("routes");
	useEffect(() => {
		if (currentServiceId === id) {
			const { current } = serviceCardRef;
			if (current !== null) {
				current.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [currentServiceId, id]);

	const [directionsService, setDirectionsService] = useState();
	useEffect(() => {
		if (!routesLibrary) return;
		setDirectionsService(new routesLibrary.DirectionsService());
	}, [routesLibrary]);

	useEffect(() => {
		if (!directionsService || !lat || !lng) return;

		directionsService
			.route({
				origin: { lat, lng },
				destination:
					nextWaypoint !== null
						? { lat: nextWaypoint.lat, lng: nextWaypoint.lng }
						: { lat: defaultEndLocation.lat, lng: defaultEndLocation.lng },
				travelMode: "DRIVING",
				provideRouteAlternatives: false,
			})
			.then((response) => {
				if (response.routes[0]?.legs) {
					let calculatedDistance = 0;
					let calculatedTime = 0;
					response.routes[0]?.legs.map((leg) => {
						if (leg?.duration?.value) {
							calculatedTime += leg.duration.value;
						}
						if (leg?.distance?.value) {
							calculatedDistance += leg.distance.value;
						}
					});

					setDistance(calculatedDistance);
					setTime(calculatedTime);
				}
			});
	}, [directionsService, lat, lng, nextWaypoint, nextWaypoint]);
	useEffect(() => {
		setServiceOpen(allExpanded);
	}, [allExpanded]);
	useEffect(() => {
		setServiceOpen(false);
	}, [isDragging]);
	function formatUtcDateString(utcDateString) {
		const date = new Date(utcDateString);
		const formatter = new Intl.DateTimeFormat("en-US", {
			weekday: "short",
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			timeZone: "UTC",
		});

		return formatter.format(date);
	}
	const renderUpcomingOperatingHours = (date) => {
		if (typeof date === "string") {
			date = new Date(date);
		} else {
			console.log("String Not Found");
		}

		const currentDate = new Date(date);

		const dateUTC = new Date(date);

		const dayNo = dateUTC.getDay();

		const dayName = daysOfWeek[dayNo];

		if (!generatorData) return <p>N/A</p>;
		const operatingHours = generatorData.workingHours[dayName];

		if (operatingHours?.closed) {
			return <p className="text-red-500">Closed</p>;
		}
		if (operatingHours?.open && operatingHours?.close) {
			if (operatingHours?.lunchStart?.length && operatingHours?.lunchEnd?.length) {
				return (
					<>
						<p>
							{operatingHours.open} - {operatingHours?.lunchStart}
						</p>
						<p>
							{operatingHours?.lunchEnd} - {operatingHours.close}
						</p>
					</>
				);
			} else {
				return (
					<>
						<p>
							{operatingHours.open} - {operatingHours.close}
						</p>
					</>
					// <p>
					// 	{operatingHours.open} {operatingHours?.lunchStart?.length ? `: ${operatingHours?.lunchStart}` : ""} -
					// 	{operatingHours?.lunchEnd?.length ? `${operatingHours?.lunchStart} :` : ""} {operatingHours.close}
					// </p>
				);
			}
		} else {
			return <p>N/A</p>;
		}
	};

	const renderServiceFrequency = (type) => {
		if (type === "MTWM") return "MTW";
		return type;
	};

	return (
		<div
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Transform.toString(transform),
			}}
			className={`text-xs ${isDragging ? "rounded-xl max-h-20 h-20" : " "}`}
		>
			<div className={`${isDragging ? "opacity-0" : "opacity-100"} flex flex-col `} ref={serviceCardRef}>
				<div className={`rounded-xl ${currentServiceId === id ? "bg-dashBtnGradient p-0.5" : ""}`}>
					<div className={`text-center ${"bg-logoutBtn"} rounded-md`}>
						<div className="flex">
							<div className="flex flex-col items-center justify-center">
								{typeof scheduleData?.serviceType === "string" &&
								scheduleData.serviceType === SERVICE_TYPES.MEDICAL_WASTE ? (
									<RoundedPin
										className={`w-6 h-8 flex items-center justify-center relative`}
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
								{typeof scheduleData?.serviceType === "string" &&
								scheduleData.serviceType === SERVICE_TYPES.PAPER_SHREDDING ? (
									<SquarePin
										className="min-w-4 min-h-4  m-1"
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
								{typeof scheduleData?.serviceType === "string" &&
								scheduleData.serviceType === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING ? (
									<SquarePin
										className="min-w-4 min-h-4  m-1"
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
								{typeof scheduleData?.serviceType !== "string" &&
								scheduleData?.serviceType?.length &&
								scheduleData.serviceType[0] === SERVICE_TYPES.MEDICAL_WASTE ? (
									<RoundedPin
										className="w-6 h-8 flex items-center justify-center relative"
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
								{typeof scheduleData?.serviceType !== "string" &&
								scheduleData?.serviceType?.length &&
								scheduleData.serviceType[0] === SERVICE_TYPES.PAPER_SHREDDING ? (
									<SquarePin
										className="min-w-4 min-h-4  m-1"
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
								{typeof scheduleData?.serviceType !== "string" &&
								scheduleData?.serviceType?.length &&
								scheduleData.serviceType[0] === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING ? (
									<SquarePin
										className="min-w-4 min-h-4  m-1"
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
								{typeof scheduleData?.serviceType === "undefined" ? (
									<RoundedPin
										className="w-6 h-8 flex items-center justify-center relative"
										color={routeColor}
										text={typeof index !== "undefined" ? index + 1 : ""}
									/>
								) : null}
							</div>
							<div className="text-xxs text-left w-full font-semibold">
								<p>{generatorData?.generatorName ?? "N/A"}</p>
								<p>{octoFormatter(generatorData?.octoNumber) ?? "N/A"}</p>
							</div>
						</div>
						{serviceOpen && (
							<div className="flex p-2 flex-col gap-2 text-left">
								<div>{renderAddress(generatorData.serviceAddress)}</div>
								<div>
									<p>
										<span
											className={`font-semibold ${
												serviceData?.temporaryServiceInstruction?.length > 0 ? "text-red-500" : "text-black"
											}`}
										>
											Temporary Service Instruction:
										</span>
									</p>
									<p>{serviceData?.temporaryServiceInstruction ?? "N/A"}</p>
								</div>
								<div>
									<p className="font-semibold">Schedule Service Type</p>
									{typeof scheduleData?.serviceType === "string" &&
									scheduleData.serviceType === SERVICE_TYPES.MEDICAL_WASTE
										? "Medical Waste"
										: ""}
									{typeof scheduleData?.serviceType === "string" &&
									scheduleData.serviceType === SERVICE_TYPES.PAPER_SHREDDING
										? "Offsite Paper Shredding"
										: ""}
									{typeof scheduleData?.serviceType === "string" &&
									scheduleData.serviceType === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING
										? "Onsite Paper Shredding"
										: ""}
									{typeof scheduleData?.serviceType !== "string" &&
									scheduleData?.serviceType?.length &&
									scheduleData.serviceType[0] === SERVICE_TYPES.MEDICAL_WASTE
										? "Medical Waste"
										: ""}
									{typeof scheduleData?.serviceType !== "string" &&
									scheduleData?.serviceType?.length &&
									scheduleData.serviceType[0] === SERVICE_TYPES.PAPER_SHREDDING
										? "Offsite Paper Shredding"
										: ""}
									{typeof scheduleData?.serviceType !== "string" &&
									scheduleData?.serviceType?.length &&
									scheduleData.serviceType[0] === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING
										? "Onsite Paper Shredding"
										: ""}
								</div>
								<div>
									<p className="font-semibold">Today's Office Hours: </p>
									{renderUpcomingOperatingHours(formatUtcDateString(dailyRouteId))}
								</div>
								<div>
									<p className="font-semibold">Service Frequency: </p>
									<p>
										{scheduleData?.serviceFrequency?.type
											? renderServiceFrequency(scheduleData?.serviceFrequency?.type)
											: "N/A"}
									</p>
								</div>
								<div>
									<p className="font-semibold">Minimum Container Needed To Complete This Service</p>
									{scheduleData?.expectedItemOrService?.length
										? scheduleData?.expectedItemOrService.map((el, i) => (
												<p key={i} className="pb-0.5">
													<span>({el.quantity}) </span>
													<span>{itemsMap[el.item]}</span>
												</p>
										  ))
										: ""}
								</div>
								<div>
									<p>
										<span className="font-semibold">Permanent Service Instructions: </span>
									</p>
									<p>
										<span className="font-semibold">DEL: </span> {generatorData?.deliveryNote ?? "N/A"}
									</p>
									<p>
										<span className="font-semibold">PRK: </span> {generatorData?.parkingNote ?? "N/A"}
									</p>
									<p>
										<span className="font-semibold">LOC: </span> {generatorData?.locationOfWaste ?? "N/A"}
									</p>
									<p>
										<span className="font-semibold">Code: </span> {generatorData?.lockBoxCode ?? "N/A"}
									</p>
									<p>
										<span className="font-semibold">Service Notes: </span> {generatorData?.serviceInstructions ?? "N/A"}
									</p>
								</div>
								<div className="flex">
									<button
										className="border px-2 py-1 rounded-full text-gray-500 border-gray-500 hover:text-gray-700 hover:border-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
										disabled={serviceData?.status !== SERVICE_STATUS.PENDING}
										onClick={async () => {
											document.getElementById(`cancel_service_modal_${id}`).showModal();
										}}
									>
										Cancel Service
									</button>
								</div>
								<div className="flex">
									<Link
										to={`/admin/generators/${generatorData.id}/generator-profile`}
										className="text-primary-500 underline"
									>
										View Profile
									</Link>
								</div>

								<dialog id={`cancel_service_modal_${id}`} className="modal">
									<div className="modal-box">
										<form method="dialog" className="h-full">
											<button
												className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
												onClick={() => {
													document.getElementById(`cancel_service_modal_${id}`).close();
												}}
											>
												✕
											</button>
											<div className="flex flex-col h-full">
												<h3 className="font-bold text-lg">Are you sure to cancel this service?</h3>
												{/* <div className="flex py-5 gap-5 flex-col">
													<p className="">
														Proceeding with this operation will mark this service as{" "}
														{status === SERVICE_STATUS.CANCELLED ? "Pending" : "Cancelled"}.
													</p>
												</div> */}
												<div className="overflow-visible z-10 flex flex-col py-5">
													<label htmlFor={`cancellationNote_${id}`}>
														<p>Enter Note for Cancellation *</p>
														<textarea
															rows={3}
															name="cancellationNote"
															id={`cancellationNote_${id}`}
															defaultValue={serviceData?.temporaryServiceInstruction ?? ""}
															className={`w-full text-cardTextGray bg-inputBg border-none rounded-[20px] py-2 h-28 px-2 leading-tight focus:outline-none focus:ring-1 focus:ring-dashInActiveBtnText`}
															onFocus={(e) => {
																e.currentTarget.focus();
															}}
															onClick={(e) => {
																e.stopPropagation();
															}}
															onKeyDown={(e) => {
																e.stopPropagation();
															}}
														/>
													</label>
												</div>
												<div className="flex justify-between mt-auto">
													<button
														className="btn btn-error btn-sm"
														onClick={() => {
															console.log("Cancelling");
															document.getElementById(`cancel_service_modal_${id}`).close();
														}}
													>
														Keep This Service
													</button>
													<button
														className="btn btn-primary btn-sm"
														onClick={async () => {
															const cancellationNote = document.getElementById(`cancellationNote_${id}`).value.trim();
															if (cancellationNote.length === 0) {
																showErrorToastMessage("Cancellation note is required.");
																return;
															}
															console.log({ id });
															try {
																showLoadingToastMessage("Cancelling Service.");
																await updateDoc(doc(db, COLLECTIONS.scheduledServices, id), {
																	status: SERVICE_STATUS.CANCELLED,
																	temporaryServiceInstruction: cancellationNote,
																});
																removeServiceById(id);
																showSuccessToastMessage("Service Cancelled Successfully.");
															} catch (error) {
																console.error(error);
															}
														}}
													>
														Yes, Cancel This Service
													</button>
												</div>
											</div>
										</form>
									</div>
								</dialog>
							</div>
						)}
						<div className="w-full px-2 flex relative">
							<div className="flex items-center gap-1 text-xxs">
								<p>
									{scheduleData?.serviceDuration?.length
										? serviceDurationOptions
												.find((el) => el.value === scheduleData?.serviceDuration)
												?.label.replace("minutes", "min") ?? "15 min"
										: serviceDurationOptions.find((el) => el.value === "15")?.label ?? "15 min"}
								</p>
							</div>
							<div className="ml-auto flex gap-1 absolute right-0 bottom-0.5">
								<div className="max-w-fit ">
									{generatorData?.workingHours[daysOfWeek[new Date(formatUtcDateString(dailyRouteId)).getDay()]]
										?.closed ? (
										<MdOutlineAccessTime className="min-w-4 min-h-4  text-red-500" />
									) : null}
								</div>
								<div className="max-w-fit">
									{serviceData?.status === SERVICE_STATUS.PENDING ? (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-gray-500" />
									) : serviceData?.status === SERVICE_STATUS.INPROGRESS ? (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-yellow-500" />
									) : serviceData?.status === SERVICE_STATUS.COMPLETE ? (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-green-500" />
									) : serviceData?.status === SERVICE_STATUS.CANCELLED ? (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-red-500" />
									) : serviceData?.status === SERVICE_STATUS.DELETED ? (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-red-500" />
									) : serviceData?.status === SERVICE_STATUS.CLOSED ? (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-red-500" />
									) : (
										<LiaFileContractSolid className="min-w-4 min-h-4  text-gray-500" />
									)}
								</div>
								<div
									className="max-w-fit"
									onClick={() => {
										updateServiceIsLockedHandler(!isLocked, id);
									}}
								>
									<Lock className={`min-w-4 min-h-4  ${isLocked ? "stroke-primary-500" : "stroke-gray-400"}`} />
								</div>
								<div className="min-w-4 min-h-4  max-w-fit">
									<Priority
										className={`min-w-4 min-h-4   ${
											priority || serviceData?.temporaryServiceInstruction?.length ? "fill-red-500" : "fill-gray-400"
										}`}
									/>
								</div>
								<div
									className="max-w-fit flex items-center justify-center px-0.5"
									onClick={() => {
										setServiceOpen((prev) => !prev);
									}}
								>
									{!serviceOpen && <GoChevronDown className="min-w-4 min-h-4" />}
									{serviceOpen && <GoChevronUp className="min-w-4 min-h-4" />}
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>
				<div className="py-0.5 flex items-center gap-5 mx-auto">
					<div className="w-full grid grid-cols-2 text-left text-xxs">
						<span className="">{isNaN(parseInt(time)) ? "" : convertSecondsToHours(parseInt(time))}</span>
						<span className="">{isNaN(parseInt(distance)) ? "" : convertMetersToMiles(parseInt(distance))} miles</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ServiceCard;

{
	/* <LiaFileContractSolid />
; complete*/
}
{
	/* <MdOutlineAccessTime />; generator time conflict closed*/
}
{
	/* <MdOutlineLocalParking />; inprogress */
}
