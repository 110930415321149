import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../../../components/UI/Input";
import Button from "../../../../../../components/UI/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
	randomizeCoordinates,
	showErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../utils/helpers";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	runTransaction,
	serverTimestamp,
	setDoc,
	Timestamp,
	where,
	writeBatch,
} from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../../config/firebase";
import Loader from "../../../../../../components/UI/loaders/Loader";
import {
	getAllSatelliteLocationSnapshot,
	getAllTreatmentsLocationSnapshot,
	getAllVendorsLocationSnapshot,
} from "../../../../../../utils/firebaseOperations";
import MultiSelectRounded from "../../../../../../components/UI/dropdowns/MultiSelectRounded";
import {
	LOCATION_TYPE,
	LOCATION_TYPE_HOME_BASE,
	SERVICE_STATUS,
	serviceTypes,
} from "../../../../../../utils/constants";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import { DEFAULT_ROUTE_VALUES, statusOptions } from "../../../../../../utils/routeConstants";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { AzureMapsProvider } from "react-azure-maps";
import useTUserContext from "../../../../../../context/TransporterUserContext";
import CustomAzureRouteMap from "./CustomAzureRouteMap";

const AddRouteForm = () => {
	const { user, loading } = useTUserContext();
	const { id } = useParams();
	const [isLoading, setLoading] = useState(false);
	const [title, setTitle] = useState("Route Details");
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [vendorMarkers, setVendorMarkers] = useState([]);
	const [treatmentFacilities, setTreatmentFacilities] = useState([]);
	const [satelliteLocations, setSatelliteLocations] = useState([]);
	const [startLocationOptions, setStartLocationOptions] = useState([]);
	const [endLocationOptions, setEndLocationOptions] = useState([]);
	const [homeBaseCoordinates, setHomeBaseCoordinates] = useState(null);
	const [noOfGenerators, setNoOfGenerators] = useState(0);
	const [noOfPreviouslyCompletedServices, setNoOfPreviouslyCompletedServices] = useState(0);
	const [totalNoOfUpcomingServices, setTotalNoOfUpcomingServices] = useState(0);
	const navigate = useNavigate();
	const [htmlMarkers, setHtmlMarkers] = useState([]);
	const [currentRouteOptions, setCurrentRouteOptions] = useState([]);
	const [prevData, setPrevData] = useState([]);
	const [allRoutes, setAllRoutes] = useState([]);

	useEffect(() => {
		if (!user) return;
		let unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.routes), where("transporterId", "==", user?.uid)),
			(snapshot) => {
				const tempRoutes = [];
				snapshot.docs.forEach((doc) => {
					tempRoutes.push({ id: doc.id, ...doc.data() });
				});
				console.log(tempRoutes);
				setAllRoutes(tempRoutes);
			}
		);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);

	useEffect(() => {
		let unsubscribe = null;
		if (id) {
			unsubscribe = onSnapshot(
				query(collection(db, COLLECTIONS.serviceSchedules), where("routeId", "==", id)),
				async (snap) => {
					const genSet = new Set();
					snap.docs.forEach((doc) => {
						if (doc.exists()) {
							if (doc?.data()?.isDeleted) return;
							const { generatorId } = doc.data();
							genSet.add(generatorId);
						}
					});
					const temp = [];
					const genIds = Array.from(genSet);
					const jobs = genIds.map((id) => {
						return getDoc(doc(db, COLLECTIONS.generators, id)).then((snap) => {
							if (
								snap.exists() &&
								snap.data().serviceAddCoordinates &&
								snap.data().serviceAddCoordinates.lat &&
								snap.data().serviceAddCoordinates.lng
							) {
								const genData = { ...snap.data(), id: snap.id };
								genData.randomCoordinates = randomizeCoordinates(
									snap.data().serviceAddCoordinates.lat,
									snap.data().serviceAddCoordinates.lng
								);
								temp.push(genData);
							}
						});
					});
					await Promise.all(jobs);
					setHtmlMarkers(temp);
					setNoOfGenerators(genSet.size);
				}
			);
		} else {
			setHtmlMarkers([]);
			setNoOfGenerators(0);
		}
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [id]);
	useEffect(() => {
		let unsubscribe = null;
		if (id) {
			const startDate = new Date();
			const endDate = new Date();
			startDate.setDate(startDate.getDate() - 30);

			unsubscribe = onSnapshot(
				query(
					collection(db, COLLECTIONS.scheduledServices),
					where("routeId", "==", id),
					where("date", ">=", Timestamp.fromDate(startDate)),
					where("date", "<=", Timestamp.fromDate(endDate))
				),
				(snap) => {
					setTotalNoOfUpcomingServices(snap.docs.length);
					snap.docs.forEach((doc) => {
						const { status } = doc.data();
						if (status === SERVICE_STATUS.COMPLETE) {
							setNoOfPreviouslyCompletedServices((prev) => prev + 1);
						}
					});
				}
			);
		} else {
			setTotalNoOfUpcomingServices(0);
			setNoOfPreviouslyCompletedServices(0);
		}
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [id]);
	useEffect(() => {
		let unsubscribe = null;
		if (id) {
			const startDate = new Date();
			const endDate = new Date();
			endDate.setDate(endDate.getDate() + 30);
			unsubscribe = onSnapshot(
				query(
					collection(db, COLLECTIONS.scheduledServices),
					where("routeId", "==", id),
					where("date", ">=", Timestamp.fromDate(startDate)),
					where("date", "<=", Timestamp.fromDate(endDate))
				),
				(snap) => {
					setTotalNoOfUpcomingServices(snap.docs.length);
				}
			);
		} else {
			setTotalNoOfUpcomingServices(0);
		}
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [id]);

	useEffect(() => {
		if (!loading && !user) {
			navigate("/auth");
			return;
		}
		getHomeBaseCoordinates();
	}, [loading, user, navigate]);

	useEffect(() => {
		const unsubscribe = getAllVendorsLocationSnapshot(setVendorMarkers, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const unsubscribe = getAllTreatmentsLocationSnapshot(setTreatmentFacilities, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const unsubscribe = getAllSatelliteLocationSnapshot(setSatelliteLocations, user?.uid);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		const options = [];
		if (homeBaseCoordinates?.lat && homeBaseCoordinates?.lng) {
			options.push({ label: "Main Location", value: null, isDisabled: true });
			options.push({ label: homeBaseCoordinates.name, value: LOCATION_TYPE_HOME_BASE });
		}
		if (satelliteLocations.length > 0) {
			options.push({ label: "Satellite Locations", value: null, isDisabled: true });
			satelliteLocations.forEach((satellite) => options.push({ label: satellite.name, value: satellite.id }));
		}
		// if (vendorMarkers.length > 0) {
		// 	options.push({ label: "Vendors Locations", value: null, isDisabled: true });
		// 	vendorMarkers.forEach((vendor) => options.push({ label: vendor.name, value: vendor.id }));
		// }
		if (treatmentFacilities.length > 0) {
			options.push({ label: "Disposal Facilities", value: null, isDisabled: true });
			treatmentFacilities.forEach((treatment) => options.push({ label: treatment.name, value: treatment.id }));
		}

		setStartLocationOptions(options);
		setEndLocationOptions(options);
	}, [satelliteLocations, vendorMarkers, treatmentFacilities, homeBaseCoordinates]);

	useEffect(() => {
		if (id) {
			fetchData();
		} else {
			reset(DEFAULT_ROUTE_VALUES);
		}
	}, [id, setTitle]);
	const getHomeBaseCoordinates = async () => {
		const homeBaseRef = doc(db, COLLECTIONS.transporters, user.uid);
		const homeBaseDoc = await getDoc(homeBaseRef);
		if (homeBaseDoc.exists()) {
			const { transporterServiceCoordinates, companyDisplayName } = homeBaseDoc.data();
			if (transporterServiceCoordinates && transporterServiceCoordinates.lat && transporterServiceCoordinates.lng) {
				setHomeBaseCoordinates({
					lat: transporterServiceCoordinates.lat,
					lng: transporterServiceCoordinates.lng,
					name: companyDisplayName,
				});
			}
		}
	};

	const fetchData = async () => {
		try {
			setLoading(true);
			const dataRes = await getDoc(doc(db, COLLECTIONS.routes, id));

			if (!dataRes.exists()) {
				throw new Error("Data Not found");
			}

			const { defaultEndLocation, defaultStartLocation, status, routeLabel, type, color } = dataRes.data();
			setValue("routeLabel", routeLabel);
			setCurrentRouteOptions([{ label: routeLabel, value: id }]);
			setValue("status", status);
			setValue("color", color);
			setEnd(defaultEndLocation);
			setStart(defaultStartLocation);
			setPrevData({ color, defaultEndLocation, defaultStartLocation, status, routeLabel, type });

			if (Array.isArray(type)) {
				setValue("type", type);
			} else {
				setValue("type", []);
			}
			setLoading(false);
		} catch (error) {
			console.log({ error });
			showErrorToastMessage(error.message);
		} finally {
			setLoading(false);
		}
	};

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: DEFAULT_ROUTE_VALUES,
	});
	const formValues = watch();

	useEffect(() => {
		if (end) {
			setValue("defaultEndLocation", end);
		} else {
			setValue("defaultEndLocation", {
				name: "",
			});
		}
	}, [end, setValue]);
	useEffect(() => {
		if (start) {
			setValue("defaultStartLocation", start);
		} else {
			setValue("defaultStartLocation", {
				name: "",
			});
		}
	}, [start, setValue]);

	const onSubmit = async (data) => {
		if (!start) {
			showErrorToastMessage("Please set up start location.");
			return;
		}
		if (!end) {
			showErrorToastMessage("Please set up end location.");
			return;
		}

		showLoadingToastMessage("Saving...");
		try {
			setLoading(true);
			if (!id) {
				const routeDocREf = await addDoc(collection(db, "routes"), {
					...data,
					transporterId: user.uid,
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
				});
				const routeId = routeDocREf.id;
				data["totalDistanceInMeters"] = 0;
				data["totalTimeInSeconds"] = 0;
				await setDoc(
					doc(db, COLLECTIONS.routes, routeId, "default", "default"),
					{
						totalDistanceInMeters: data.totalDistanceInMeters,
						totalTimeInSeconds: data.totalTimeInSeconds,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
				await updateRouteId(routeId);
				showSuccessToastMessage("Route created successfully.");
			} else {
				console.log(data.defaultEndLocation.id, prevData.defaultEndLocation.id);
				console.log(data.defaultStartLocation.id, prevData.defaultStartLocation.id);
				let count = 0;
				let batch = writeBatch(db);
				batch.set(
					doc(db, COLLECTIONS.routes, id),
					{
						...data,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
				count++;
				batch.set(
					doc(db, COLLECTIONS.routes, id, "default", "default"),
					{
						totalDistanceInMeters: 0,
						totalTimeInSeconds: 0,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
				count++;
				if (
					data.defaultEndLocation.id !== prevData.defaultEndLocation.id ||
					data.defaultStartLocation.id !== prevData.defaultStartLocation.id
				) {
					const today = new Date();
					const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));
					console.log(todayUTC.toISOString());
					const allDailyRoutesRes = await getDocs(collection(db, COLLECTIONS.routes, id, COLLECTIONS.dailyRoutes));
					console.log(allDailyRoutesRes.docs.length);
					for (const doc of allDailyRoutesRes.docs) {
						const date = new Date(doc.id);
						console.log({ date, todayUTC });
						if (date >= todayUTC) {
							if (count >= 400) {
								await batch.commit();
								batch = writeBatch(db);
								count = 0;
							}
							batch.set(
								doc.ref,
								{
									defaultEndLocation: data.defaultEndLocation,
									defaultStartLocation: data.defaultStartLocation,
								},
								{ merge: true }
							);
							count++;
						}
					}
				}
				if (count > 0) {
					await batch.commit();
				}
				await updateRouteId(id);
				showSuccessToastMessage("Route updated successfully.");
			}
		} catch (error) {
			console.error("Error during submission:", error);
			showErrorToastMessage("An error occurred during submission. Internal Server Error.");
		} finally {
			setLoading(false);
			//handleBack();
		}
	};
	const updateRouteId = (id) => {
		return runTransaction(db, async (transaction) => {
			let count = 0;
			const countRes = await transaction.get(doc(db, COLLECTIONS.counters, "default"));
			if (countRes.exists() && countRes?.data()?.routeCount) {
				count = countRes?.data()?.routeCount ?? 0;
			}
			const routeRes = await transaction.get(doc(db, COLLECTIONS.routes, id));
			count++;

			if (routeRes.exists() && !routeRes?.data()?.routeOctoId) {
				transaction.set(
					doc(db, COLLECTIONS.routes, id),
					{
						routeOctoId: `RT${count.toString().padStart(7, "0")}`,
					},
					{ merge: true }
				);
				transaction.set(
					doc(db, COLLECTIONS.counters, "default"),
					{
						routeCount: count,
					},
					{ merge: true }
				);
			}
		});
	};

	const handleBack = () => navigate("/admin/assets/routes");

	if (isLoading) {
		return <Loader />;
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="bg-white p-8 m-6 py-2 flex flex-col rounded-cardRadii flex-grow">
			<div className="font-medium flex items-center gap-4 text-lg py-2 border-b border-cardTextGray border-opacity-40 text-black">
				{title}
			</div>

			<div className="grid grid-cols-4 gap-5">
				<div className="col-span-4">
					<div className="w-full h-96 my-4">
						<AzureMapsProvider>
							<CustomAzureRouteMap allRoutes={allRoutes} prevData={prevData} htmlMarkers={htmlMarkers} />
						</AzureMapsProvider>
						{/* <RouteSetupMap
							setStart={setStart}
							setEnd={setEnd}
							start={start}
							end={end}
							path={path}
							setPath={setPath}
							otherPaths={otherPaths}
							waypoints={waypoints}
							otherGeneratorMarkers={otherGeneratorMarkers}
							vendorMarkers={vendorMarkers}
							treatmentFacilities={treatmentFacilities}
							transporter={dummyTransporter}
							setFormValue={setValue}
						/> */}
					</div>
					<div className="grid gap-x-8 gap-y-4 grid-cols-2">
						<div className="">
							<Controller
								name="routeLabel"
								control={control}
								rules={{
									required: "Route Name is required",
									validate: (value) => {
										const exists = allRoutes.some(
											(route) => route.routeLabel.toLowerCase() === value.toLowerCase() && route.id !== id
										);
										return !exists || "Route name already exists";
									},
								}}
								render={({ field }) => {
									return <Input label={"Route Name"} isRequired {...field} margin={"mt-4 mb-0"} />;
								}}
							/>
							{errors.routeLabel && <p className="text-red-500 text-sm mt-1">{errors.routeLabel.message}</p>}
							<Controller
								name="defaultStartLocation.id"
								control={control}
								rules={{ required: "Default Start Location is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<Dropdown
											value={value}
											listHeight={"max-h-64"}
											onChange={(value) => {
												if (value === prevData?.defaultStartLocation?.id) return;
												onChange(value);
												if (prevData?.defaultStartLocation?.id) {
													document.getElementById(`change_start_location_modal`).showModal();
												}
												if (value === LOCATION_TYPE_HOME_BASE) {
													setStart({
														id: LOCATION_TYPE_HOME_BASE,
														lat: homeBaseCoordinates.lat,
														lng: homeBaseCoordinates.lng,
														name: homeBaseCoordinates.name,
														type: LOCATION_TYPE.TRANSPORTER,
													});
													return;
												}

												const foundSatellite = satelliteLocations.find((el) => el.id === value);
												if (foundSatellite) {
													setStart({
														id: foundSatellite.id,
														lat: foundSatellite.satelliteServiceCoordinates.lat,
														lng: foundSatellite.satelliteServiceCoordinates.lng,
														name: foundSatellite.name,
														type: LOCATION_TYPE.SATELLITE,
													});
													return;
												}
												const foundVendor = vendorMarkers.find((el) => el.id === value);

												if (foundVendor) {
													setStart({
														id: foundVendor.id,
														lat: foundVendor.vendorCoordinates.lat,
														lng: foundVendor.vendorCoordinates.lng,
														name: foundVendor.name,
														type: LOCATION_TYPE.VENDOR,
													});
													return;
												}
												const foundTreatment = treatmentFacilities.find((el) => el.id === value);
												if (foundTreatment) {
													setStart({
														id: foundTreatment.id,
														lat: foundTreatment.treatmentCoordinates.lat,
														lng: foundTreatment.treatmentCoordinates.lng,
														name: foundTreatment.name,
														type: LOCATION_TYPE.TSTF,
													});
													return;
												}
											}}
											options={startLocationOptions}
											label="Default Start Location *"
											className="text-sm"
										/>
										{errors.defaultStartLocation?.id?.message && (
											<p className="text-red-500 text-sm mt-1">{errors.defaultStartLocation?.id?.message}</p>
										)}
									</div>
								)}
							/>
							<dialog id={`change_start_location_modal`} className="modal ">
								<div className="modal-box">
									<button
										type="button"
										className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
										onClick={() => {
											document.getElementById(`change_start_location_modal`).close();
										}}
									>
										✕
									</button>
									<div className="flex flex-col h-full">
										<h3 className="font-bold text-lg">Are you sure to change this route start location?</h3>

										<div className="overflow-visible z-10 flex flex-col py-5"></div>

										<div className="flex justify-between mt-auto">
											<button
												type="button"
												className="btn btn-error btn-sm"
												onClick={() => {
													console.log("Cancelling");
													setValue("defaultStartLocation", prevData.defaultStartLocation);
													document.getElementById(`change_start_location_modal`).close();
												}}
											>
												Cancel
											</button>
											<button
												type="button"
												className="btn btn-primary btn-sm"
												onClick={async () => {
													console.log({ id });
													document.getElementById(`change_start_location_modal`).close();
												}}
											>
												Yes
											</button>
										</div>
									</div>
								</div>
							</dialog>
							<Controller
								name="color"
								control={control}
								rules={{ required: "Color for a route is required" }}
								render={({ field: { onChange, value } }) => {
									return (
										<div className="w-full py-2 grid grid-cols-2 items-start gap-2">
											<div className="grid grid-cols-3 items-center col-span-2 ">
												<p>Route Color *</p>
												<div className="col-span-2 bg-inputBg rounded-full">
													<HexColorInput
														className=" py-1.5 px-4 bg-transparent focus:outline-none"
														color={value}
														onChange={onChange}
														prefixed
													/>
												</div>
											</div>
											<div className="col-span-2 grid grid-cols-2">
												<HexColorPicker
													className="w-full ml-auto col-span-2"
													style={{ width: "100%" }}
													color={value}
													onChange={onChange}
												/>
											</div>
										</div>
									);
								}}
							/>
							{errors.truckCostPerMile && <p className="text-red-500 text-sm mt-1">{errors.color?.message}</p>}
						</div>
						<div className="">
							<Controller
								name={`type`}
								control={control}
								rules={{ required: "Route Type is required" }}
								render={({ field: { onChange, value } }) => (
									<MultiSelectRounded
										value={value}
										onChange={onChange}
										options={[
											serviceTypes[0],
											serviceTypes[1],
											serviceTypes[2],
											{ ...serviceTypes[3], isDisabled: true },
										]}
										isRequired={true}
										label="Route Type"
										id={`route-type`}
									/>
								)}
							/>
							{errors.type && <p className="text-red-500 text-sm mt-1">{errors.type?.message}</p>}
							<Controller
								name="defaultEndLocation.id"
								control={control}
								rules={{ required: "Default End Location is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<Dropdown
											value={value}
											listHeight={"max-h-64"}
											onChange={(value) => {
												console.log({ value });
												if (value === prevData?.defaultEndLocation?.id) return;
												onChange(value);
												if (prevData?.defaultEndLocation?.id) {
													document.getElementById(`change_end_location_modal`).showModal();
												}
												if (value === LOCATION_TYPE_HOME_BASE) {
													setEnd({
														id: LOCATION_TYPE_HOME_BASE,
														lat: homeBaseCoordinates.lat,
														lng: homeBaseCoordinates.lng,
														name: homeBaseCoordinates.name,
														type: LOCATION_TYPE.TRANSPORTER,
													});
													return;
												}

												const foundSatellite = satelliteLocations.find((el) => el.id === value);
												if (foundSatellite) {
													setEnd({
														id: foundSatellite.id,
														lat: foundSatellite.satelliteServiceCoordinates.lat,
														lng: foundSatellite.satelliteServiceCoordinates.lng,
														name: foundSatellite.name,
														type: LOCATION_TYPE.SATELLITE,
													});
													return;
												}
												const foundVendor = vendorMarkers.find((el) => el.id === value);
												if (foundVendor) {
													setEnd({
														id: foundVendor.id,
														lat: foundVendor.vendorCoordinates.lat,
														lng: foundVendor.vendorCoordinates.lng,
														name: foundVendor.name,
														type: LOCATION_TYPE.VENDOR,
													});
													return;
												}
												const foundTreatment = treatmentFacilities.find((el) => el.id === value);
												if (foundTreatment) {
													setEnd({
														id: foundTreatment.id,
														lat: foundTreatment.treatmentCoordinates.lat,
														lng: foundTreatment.treatmentCoordinates.lng,
														name: foundTreatment.name,
														type: LOCATION_TYPE.TSTF,
													});
													return;
												}
											}}
											options={endLocationOptions}
											label="Default End Location *"
											className="text-sm"
										/>
										{errors.defaultEndLocation?.id && (
											<p className="text-red-500 text-sm mt-1">{errors.defaultEndLocation?.id?.message}</p>
										)}
									</div>
								)}
							/>
							<dialog id={`change_end_location_modal`} className="modal ">
								<div className="modal-box">
									<button
										type="button"
										className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
										onClick={() => {
											document.getElementById(`change_end_location_modal`).close();
										}}
									>
										✕
									</button>
									<div className="flex flex-col h-full">
										<h3 className="font-bold text-lg">Are you sure to change this route end location?</h3>

										<div className="overflow-visible z-10 flex flex-col py-5"></div>

										<div className="flex justify-between mt-auto">
											<button
												type="button"
												className="btn btn-error btn-sm"
												onClick={() => {
													console.log("Cancelling");
													setValue("defaultEndLocation", prevData.defaultEndLocation);
													document.getElementById(`change_end_location_modal`).close();
												}}
											>
												Cancel
											</button>
											<button
												type="button"
												className="btn btn-primary btn-sm"
												onClick={async () => {
													console.log({ id });
													document.getElementById(`change_end_location_modal`).close();
												}}
											>
												Yes
											</button>
										</div>
									</div>
								</div>
							</dialog>
							<Controller
								name="status"
								control={control}
								render={({ field: { value, onChange } }) => (
									<div>
										<Dropdown
											value={value}
											onChange={(e) => {
												if (e === prevData.status) return;
												onChange(e);
												document.getElementById(`change_status_modal`).showModal();
											}}
											options={statusOptions}
											label="Status"
											className="text-sm"
											isDisabled={noOfGenerators > 0}
										/>
										{errors.status && <p className="text-red-500 text-sm mt-1">{errors.status?.message}</p>}
									</div>
								)}
							/>
							<dialog id={`change_status_modal`} className="modal ">
								<div className="modal-box">
									<button
										type="button"
										className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
										onClick={() => {
											document.getElementById(`change_status_modal`).close();
										}}
									>
										✕
									</button>
									<div className="flex flex-col h-full">
										<h3 className="font-bold text-lg">Are you sure to change this route status?</h3>

										<div className="overflow-visible z-10 flex flex-col py-5"></div>

										<div className="flex justify-between mt-auto">
											<button
												type="button"
												className="btn btn-error btn-sm"
												onClick={() => {
													console.log("Cancelling");
													setValue("status", prevData.status);
													document.getElementById(`change_status_modal`).close();
												}}
											>
												Cancel
											</button>
											<button
												type="button"
												className="btn btn-primary btn-sm"
												onClick={async () => {
													document.getElementById(`change_status_modal`).close();
												}}
											>
												Yes
											</button>
										</div>
									</div>
								</div>
							</dialog>
							{noOfGenerators > 0 && (
								<div className="text-xs text-red-500">
									This route has {noOfGenerators} generator(s) assigned to it. In order to change this route status
									please manually remove all generators from this route.
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="pt-4">
				<h6 className="font-medium text-lg pb-2 border-b border-cardTextGray border-opacity-40">Stats For Nerds</h6>
				<div className="py-4 flex flex-col gap-8">
					<div>
						<h6 className="text-lg">
							Number of services performed on this route over the last 30 days:{" "}
							<span>{noOfPreviouslyCompletedServices}</span>
						</h6>
					</div>
					<div>
						<h6 className="text-lg">
							Number of scheduled services in the next 30 days: <span>{totalNoOfUpcomingServices}</span>
						</h6>
					</div>
					<div>
						<h6 className="text-lg">
							Number of generators assigned to this route: <span>{noOfGenerators}</span>
						</h6>
					</div>
				</div>
			</div>
			<div className="flex justify-end gap-8 py-3">
				<Button btnStyle="form_nav_secondary" text={"View Route List"} onClick={handleBack} />
				<Button btnStyle="form_nav_primary" text={isLoading ? " Saving..." : "Save"} type="submit" />
			</div>
		</form>
	);
};

export default AddRouteForm;
