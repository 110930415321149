import React from "react";

import Handlebars from "handlebars";

const template = Handlebars.compile(`
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Delivery Receipt</title>
    <style>
        body {
        margin:
            font-family: Arial, sans-serif;
        }
        .container {
            width: 100%;
            height: 9in;
            /* margin: auto; */
            border: 2px solid black;
            display: flex; /* Enable flex layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Optional, for better control */
    margin: 0;
        }
        .header {
            display: flex;
            justify-content: space-between;
            padding: 0px 0px 0px 20px;
        }
        .header-left{
            text-align: left;
            /* font-size: 10px; */
        }
        .header-left h3{
            font-size: 11px;
            font-weight: 700;
            margin: 5px;
            margin-top: 15px;
        }
        .header-left p{
            
            font-size: 10px;
            margin: 5px;
        }
        .header-right{
        height: fit-content;
          border-left: 2px solid black;
          border-bottom: 2px solid black;
          top: 0


        }
        .header-right h2 {
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            background: green;
            color: white;
            padding: 5px 10px;
        }
        .header-right p{
            font-size: 10px;
            margin: 2px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid black;
            min-height: 30%;
            padding: 0;
        }
        .border {
                border: 1px solid #000;
            }

            .border-2 {
                border-width: 2px;
            }

            .border-black {
                border-color: #000;
            }
        
        td, th{
            border: 1px solid black;
        }
        .row-1{
            font-size: 10px;
            display: flex;
            background-color: #f5f5f5;
        }
        .table-header1{
            width: 100%;
        }
        .table-head{
            font-size: 10px;
            font-weight: 100;
            border: 1px solid black;
            padding: 2px;
            text-align: center;
        }
        .table-h {
            margin-top: 30px;
                border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: none;
    border-right: none;
                height: 146.8px;
            }

        .total {
            font-size: 10px;
            display: flex;
            justify-content: right;
            align-items: end;
            text-align: right;
            margin-top: 10px;
        }
        .signatures {
            font-size: 10px;
    margin-top: auto; /* Push this div to the bottom */
    padding: 10px; /* Optional, for spacing */
}
        .flex {
                    display: flex;
                }
                .w-full {
                    width: 100%;
                }
                .w-60{
                    width: 60%;
                }
                .w-40{
                    width: 40%;
                }
                .flex-col {
                flex-direction: column;
            }
            .m-2{
                margin: 2px ;
            }

            .text-center {
                text-align: center;
            }
            .text-xs {
                font-size: 8px;
            }

    </style>
</head>
<body>
    <div class="container">
        <div class="header">
          <div class="header-left">
            <h3>{{transporter.name}}</h3>
                <p>{{transporter.street}}</p>
                <p>{{generator.serviceAddress2}}</p>
                <p>{{generator.generatorPhone}}</p>
                <p>{{generator.email}}</p>
                <p>www.sdmedwaste.com</p>
          </div>
            <div class="header-right">
              <h2>DELIVERY RECEIPT</h2>
              <p><strong>Delivery Date:</strong> {{deliveryDate}}</p>
              <p><strong>Document ID:</strong> {{documentId}}</p>
            </div>
        </div>
        
        <div class="table-h">
            <div class="row-1">
                <div  style="width: 20%; text-align: center;">
                    <h6 style="margin: 2px 0; font-weight: bold; font-size: 10px;">OCTO Number</h6>
                            <p style="margin: 2px 0; font-weight: normal;">{{generator.octoId}}</p>
                </div>
                <div style="width: 60%;  border-left: 1px solid black; border-right: 1px solid black; text-align: center;">
                    <h6 style="margin: 2px 0; font-weight: bold; font-size: 10px;">
                        {{generator.generatorName}}
                    </h6>
                    <p style="margin: 2px 0; font-weight: normal;">{{generator.serviceAddress}}, {{generator.serviceAddress2}}</p>
                </div>
                <div style="width: 20%; text-align: center;">
                    <h6 style="margin: 2px 0; font-weight: bold;font-size: 10px;">Purchase Order Number</h6>
                            <p style="margin: 2px 0; font-weight: normal;">1234567899999999999</p>
                </div>
            </div>
            <table class="w-full border ">
                <thead>
                    <tr style="font-size: 10px;">
                        <th style="width: 10%; text-align: left; padding: 2px;"><b>Delivery Date</b></th>
                        <th style="width: 50%; text-align: left; padding: 2px;"><b>Description</b></th>
                        <th style="width: 15%; text-align: center; padding: 2px;">Usage</th>
                        <th style="width: 5%; text-align: center; padding: 2px;">Qty</th>
                        <th style="width: 10%; text-align: right; padding: 2px;">Rate</th>
                        <th style="width: 10%; text-align: right; padding: 2px;">Total</th>
                    </tr>
                </thead>
                <tbody style="font-size: 10px;">
                    <tr>
                        <td style="width: 10%; text-align: left; padding: 2px;">Delivery Date</td>
                        <td style="width: 50%; text-align: left; padding: 2px;">Description</td>
                        <td style="width: 15%; text-align: center; padding: 2px;">Usage</td>
                        <td style="width: 5%; text-align: center; padding: 2px;">Qty</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Rate</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Total</td>
                    </tr>
                    <tr>
                        <td style="width: 10%; text-align: left; padding: 2px;">Delivery Date</td>
                        <td style="width: 50%; text-align: left; padding: 2px;">Description</td>
                        <td style="width: 15%; text-align: center; padding: 2px;">Usage</td>
                        <td style="width: 5%; text-align: center; padding: 2px;">Qty</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Rate</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Total</td>
                    </tr>
                    <tr>
                        <td style="width: 10%; text-align: left; padding: 2px;">Delivery Date</td>
                        <td style="width: 50%; text-align: left; padding: 2px;">Description</td>
                        <td style="width: 15%; text-align: center; padding: 2px;">Usage</td>
                        <td style="width: 5%; text-align: center; padding: 2px;">Qty</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Rate</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Total</td>
                    </tr>
                    <tr>
                        <td style="width: 10%; text-align: left; padding: 2px;">Delivery Date</td>
                        <td style="width: 50%; text-align: left; padding: 2px;">Description</td>
                        <td style="width: 15%; text-align: center; padding: 2px;">Usage</td>
                        <td style="width: 5%; text-align: center; padding: 2px;">Qty</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Rate</td>
                        <td style="width: 10%; text-align: right; padding: 2px;">Total</td>
                    </tr>
                </tbody>

            </table>
        </div>
        <div class="total">
            <div style="width: 10%; flex-direction: column; text-align: left;">
                <p style="margin: 2px;"><strong>Subtotal:</strong></p>
                <p style="margin: 2px;"><strong>Sales Tax:</strong></p>
            <p style="margin: 2px;"><strong>Total:</strong></p>
            </div>
            <div style="width: 10%; flex-direction: column; text-align: right;">
                <p style="margin: 2px;"> $47.00</p>
            <p style="margin: 2px;"> $3.05</p>
            <p style="margin: 2px;"> $50.05</p>
            </div>
        </div>
        
    

        <div class="signatures">
            <div class="flex flex-wrap w-full">
                <div class="w-60">
                    <div class="flex text-xs border-black">
                        <div
                            class="flex flex-col gap-1 p-2 text-sm"
                        >
                            <p class="flex gap-1 m-2">
                                <span class="font-semibold"
                                    >Representative Signature:</span
                                >
                                <img style="width: 25px; object-fit: contain;" src="{{generator.representativeSignature}}"/>
                            </p>
                            <p class="m-2">
                                <span class="font-semibold"
                                    >Generator Representative (Print):</span
                                >
                                {{generator.representative}}
                            </p>
                            <p class="m-2">
                                <span class="font-semibold"
                                    >Date/Time:</span
                                >
                                Mon 10-09-2024 1:08 PM
                            </p>
                        </div>
                    </div>
                </div>
                <div class="w-40">
                    <div
                        class="flex flex-col gap-1 p-2 text-sm"
                    >
                        <p class="m-2">
                            <span class="font-semibold "
                                >Representative Signature:</span
                            >
                        </p>
                        <p class="m-2">
                            <span class="font-semibold"
                                >Transporter Representative (Print):</span
                            >
                            {{transporter.name}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full" style="border-top: 1px solid black;"><p class="text-center text-xs"> <a href="https://www.octopussaas.com/">Powered By Octopus SaaS: OCTO Smart Invoices™</a></p></div>
        
       
    </div>
</body>
</html>
`);


const DeliveryRecipt = () => {





  const data = {
		documentId: "7V4ZRKHkfJIrgt7bizka",
        deliveryDate:"1/3/2025",
        generator: {
			generatorName: "Healing Heart Medical Services",
			serviceAddress: "16835 W Bernardo Dr, D",
            serviceAddress2: "San Diego, CA 92101",
			generatorPhone: "(876) 742-6876",
            email: "service@healing.com",
			octoId: "1000009",
			representative: "Will J Smith (FBO) Simpson",
			representativeSignature:
				"https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/default%2Fgenerators%2FgyNydgvopak5UKaBwCkm%2FdfGNpb1Bu9AIKujtktrb%2FgeneratorSignImage.png?alt=media&token=577bf431-ebf2-47e0-9aa6-a7080f8f7f28",
		},
        transporter: {
			name: "Billy Pvt Ltd",
			street: "8940 Activity Rd ",
			state: "CA",
			zipCode: "92126",
			dispatchPhone: "(123) 456-7890",
			logo: "https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/transporters%2FcompanyLogo%2Freinventors.svg?alt=media&token=fef44d55-a820-47c4-8e09-528db0b3c227",
			octoId: "T-1001",
			subcontractor: "",
			registration1: "1234",
			representative: "Will J Smith",
			representativeSignature:
				"https://firebasestorage.googleapis.com/v0/b/routes-123.appspot.com/o/default%2Fgenerators%2FgyNydgvopak5UKaBwCkm%2FdfGNpb1Bu9AIKujtktrb%2FdriverSignImage.png?alt=media&token=33fc3beb-5f19-46b9-9e58-cc320c6ebb65",
			dateTime: "1/3/2025, 7:52:18 pm",
			serviceVehicleId: "SV0000002",
			date: "1/3/2025, 7:52:18 pm",
		},
  }

  const formData = {
		...data,
	};

	// Generate HTML from template
	const html = template(formData);

  return (
		<div className="max-w-4xl mx-auto my-8">
			<div dangerouslySetInnerHTML={{ __html: html }} />
		</div>
	);
}

export default DeliveryRecipt;