const GradientDefinition = () => (
	<svg width="0" height="0" style={{ position: "absolute" }}>
		<defs>
			<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stopColor="#007AFF" />
				<stop offset="100%" stopColor="#4CD964" />
			</linearGradient>
		</defs>
	</svg>
);

export default GradientDefinition;
