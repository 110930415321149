import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { emailRegex, urlRegex } from "../../../../utils/mimes";
import { RxCross2 } from "react-icons/rx";
import { FiPlus, FiUpload } from "react-icons/fi";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, db, storage } from "../../../../config/firebase";

export const StyledMultiSelect = ({ label, options, selectedOptions, setSelectedOptions, placeholder, error = "" }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	// Handle selection with a max limit of 3 options
	const handleSelect = (option) => {
		if (selectedOptions?.some((item) => item === option?.value)) {
			// Deselect the option
			setSelectedOptions(selectedOptions.filter((item) => item !== option.value));
		} else {
			// Allow selection only if less than 3 options are selected
			if (selectedOptions.length < 3) {
				setSelectedOptions([...selectedOptions, option.value]);
			}
		}
	};

	// Remove an option when clicking the 'X' button
	const removeOption = (optionToRemove, e) => {
		e.stopPropagation();
		setSelectedOptions(selectedOptions.filter((option) => option !== optionToRemove));
	};

	// Close dropdown when clicking outside
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Find the label for the selected options
	const getSelectedLabels = () => {
		return selectedOptions.map((selectedValue) => options.find((option) => option.value === selectedValue)?.label);
	};

	return (
		<div className="relative" ref={dropdownRef}>
			<div
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className={`
			w-full p-3 px-5 bg-[#F1F1F4] text-cardTextGray rounded-full
			flex items-center gap-2 justify-between cursor-pointer
			outline-none focus:outline focus:outline-2 focus:outline-blue-500
			transition-all min-h-[48px]
		  `}
			>
				<div className="flex overflow-scroll scrollbar-hide gap-1 items-center rounded-full focus:outline-blue-500">
					{selectedOptions?.length > 0 ? (
						getSelectedLabels().map((label, index) => (
							<span
								key={selectedOptions[index]}
								className="text-nowrap inline-flex items-center gap-1 px-2 bg-white rounded-full text-sm"
							>
								{label}
								<button
									type="button"
									onClick={(e) => removeOption(selectedOptions[index], e)}
									className="p-0.5 hover:bg-gray-300 hover:text-red-500 rounded-full"
								>
									<RxCross2 className="h-3 w-3" />
								</button>
							</span>
						))
					) : (
						<span>{placeholder || `Select ${label}`}</span>
					)}
				</div>
				<IoIosArrowDown
					size={20}
					className={`min-w-fit transform transition-transform ${isOpen ? "rotate-180" : ""}`}
				/>
			</div>
			{error && <p className="mt-1 text-sm text-red-500">{error}</p>}
			<div className="w-fit overflow-hidden rounded-xl">
				{isOpen && (
					<div
						className="absolute z-10 w-full bg-white border shadow-md rounded-xl mt-1 max-h-60 overflow-auto"
						style={{ animation: "scaleIn 200ms ease-out" }}
					>
						{options.map((option) => (
							<div
								key={option.value}
								onClick={() => handleSelect(option)}
								className={`p-3 group px-5 relative flex items-center gap-2 ${
									selectedOptions.length < 3 || selectedOptions.includes(option.value)
										? "hover:bg-gray-100 cursor-pointer"
										: "cursor-default"
								}`}
							>
								{selectedOptions.length >= 3 && !selectedOptions.includes(option.value) && (
									<div className="opacity-0 group-hover:opacity-100 transition-all duration-300 absolute left-1/3 y-1/2 bg-gray-900 text-white text-sm px-2 py-1 rounded pointer-events-none whitespace-nowrap">
										Max 3 has already been selected!
									</div>
								)}
								<input
									type="checkbox"
									checked={selectedOptions.includes(option.value)}
									readOnly
									className="h-4 w-4 rounded border-gray-300"
								/>
								<span
									className={
										selectedOptions.length >= 3 && !selectedOptions.includes(option.value) ? "text-gray-400" : ""
									}
								>
									{option.label}
								</span>
							</div>
						))}
					</div>
				)}
			</div>
			<style jsx>{`
				@keyframes scaleIn {
					from {
						opacity: 0;
						transform: scale(0.95);
					}
					to {
						opacity: 1;
						transform: scale(1);
					}
				}
			`}</style>
		</div>
	);
};

export const StyledSingleSelect = ({
	label,
	options,
	selectedOption,
	setSelectedOption,
	placeholder,
	style = "",
	error,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const containerRef = useRef(null);
	const [dropdownPosition, setDropdownPosition] = useState("bottom");

	const handleSelect = (option) => {
		setSelectedOption(option.value);
		setIsOpen(false);
	};

	const removeOption = (e) => {
		e.stopPropagation();
		setSelectedOption(null);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	// Calculate dropdown position
	useEffect(() => {
		if (isOpen && containerRef.current) {
			const rect = containerRef.current.getBoundingClientRect();
			const spaceBelow = window.innerHeight - rect.bottom;
			const spaceAbove = rect.top;
			const dropdownHeight = 256; // 64vh equivalent assuming 400px viewport height

			if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
				setDropdownPosition("top");
			} else {
				setDropdownPosition("bottom");
			}
		}
	}, [isOpen]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const getSelectedLabel = () => {
		return options.find((option) => option.value === selectedOption)?.label;
	};

	return (
		<div className="relative" ref={containerRef}>
			<div
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className={`
          ${style?.length > 0 ? "min-w-28 py-2 px-4 border rounded-lg" : "w-full p-3 px-5 rounded-full min-h-[48px]"}
          justify-between cursor-pointer flex items-center gap-2
          outline-none focus:outline bg-[#F1F1F4] text-cardTextGray ${
						error ? "outline-2 outline-red-500" : "focus:outline-blue-500"
					}
          transition-all relative z-20
        `}
			>
				<div className="flex gap-1 items-center rounded-full focus:outline-blue-500">
					{selectedOption ? (
						<span className="text-nowrap">{getSelectedLabel()}</span>
					) : (
						<span className="text-gray-500 text-opacity-70">{placeholder || `Select ${label}`}</span>
					)}
				</div>
				<IoIosArrowDown size={20} className={`transform transition-transform ${isOpen ? "rotate-180" : ""}`} />
			</div>

			{isOpen && (
				<div
					ref={dropdownRef}
					className={`
            absolute z-50 w-full bg-white border shadow-lg
            max-h-[64vh] overflow-auto
            ${style?.length > 0 ? "rounded-lg" : "rounded-xl"}
            ${dropdownPosition === "top" ? "bottom-[calc(100%+4px)]" : "top-[calc(100%+4px)]"}
          `}
					style={{ animation: "scaleIn 200ms ease-out" }}
				>
					{options.map((option) => (
						<div
							key={option.value}
							onClick={() => handleSelect(option)}
							className={`
                p-3 px-5 flex items-center gap-2 hover:bg-gray-100 cursor-pointer
                ${selectedOption === option.value ? "bg-gray-200" : ""}
              `}
						>
							<span>{option.label}</span>
						</div>
					))}
				</div>
			)}

			<style jsx="true">{`
				@keyframes scaleIn {
					from {
						opacity: 0;
						transform: scale(0.95);
					}
					to {
						opacity: 1;
						transform: scale(1);
					}
				}
			`}</style>
		</div>
	);
};

const defaultValues = {
	companyName: "",
	address: "",
	street: "",
	suite: "",
	city: "",
	state: "",
	zip: "",
	phone: "",
	email: "",
	websiteUrl: "",
	contacts: [],
	category: [],
	serviceArea: "",
	localAreas: [],
	shortDesc: "",
	bio: "",
	// profileImage: "",
};

const ProfileSettings = () => {
	const [contacts, setContacts] = useState([]);
	const defaultContact = {
		id: "",
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
	};
	const [newContact, setNewContact] = useState(defaultContact);
	const [isAddingContact, setIsAddingContact] = useState(false);
	const [newContactError, setNewContactError] = useState({});
	const [profileImage, setProfileImage] = useState("");
	const [profileLoaded, setIsProfileLoaded] = useState(false);
	const [isHoveringImage, setIsHoveringImage] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [openSections, setOpenSections] = useState({});
	const [charCount, setCharCount] = useState(0);
	const { data: octoUserData, loading: userLoading } = useSelector((state) => state.octoUserData);
	const { sendApprovalRequest } = useConnectionManager();
	const [user, loading] = useAuthState(auth);
	const dispatch = useDispatch();
	const imageRef = useRef();

	const methods = useForm({
		defaultValues,
		category: defaultValues.category || [],
		resolver: (values) => {
			const errors = {};

			// Company Name validation
			if (!values.companyName?.trim()) {
				errors.companyName = {
					type: "required",
					message: "Company name is required",
				};
			} else if (values.companyName.trim().length < 2) {
				errors.companyName = {
					type: "minLength",
					message: "Company name must be at least 2 characters",
				};
			}

			// Address validations
			if (!values.street?.trim()) {
				errors.street = {
					type: "required",
					message: "Street address is required",
				};
			}

			if (!values.city?.trim()) {
				errors.city = {
					type: "required",
					message: "City is required",
				};
			}

			if (!values.state) {
				errors.state = {
					type: "required",
					message: "State is required",
				};
			}

			if (!values.zip?.trim()) {
				errors.zip = {
					type: "required",
					message: "ZIP code is required",
				};
			} else if (!/^\d{5}(-\d{4})?$/.test(values.zip.trim())) {
				errors.zip = {
					type: "pattern",
					message: "Please enter a valid ZIP code",
				};
			}

			// Phone validation
			if (!values.phone?.toString()?.trim()) {
				errors.phone = {
					type: "required",
					message: "Phone number is required",
				};
			}

			// Website URL validation (optional field)
			if (values.websiteUrl?.trim() && !urlRegex.test(values.websiteUrl.trim())) {
				errors.websiteUrl = {
					type: "pattern",
					message: "Please enter a valid URL",
				};
			}

			// Category validation
			if (!values.category || values.category.length === 0) {
				errors.category = {
					type: "required",
					message: "At least one category is required",
				};
			} else if (values.category.length > 3) {
				errors.category = {
					type: "maxLength",
					message: "Maximum 3 categories allowed",
				};
			}

			// Service Area validation
			if (!values.serviceArea) {
				errors.serviceArea = {
					type: "required",
					message: "Please select a service area type",
				};
			}

			if (values.serviceArea === "local" && (!values.localAreas || values.localAreas.length === 0)) {
				errors.localAreas = {
					type: "validate",
					message: "At least one service area is required",
				};
			}

			// Short Description validation
			if (!values.shortDesc?.trim()) {
				errors.shortDesc = {
					type: "required",
					message: "Short description is required",
				};
			} else if (values.shortDesc.trim().length > 100) {
				errors.shortDesc = {
					type: "maxLength",
					message: "Short description must not exceed 100 characters",
				};
			}
			if (!values.bio?.trim()) {
				errors.bio = {
					type: "required",
					message: "Bio is required",
				};
			}

			return {
				values: {
					...values,
					companyName: values.companyName?.trim() ?? "",
					street: values.street?.trim() ?? "",
					city: values.city?.trim() ?? "",
					zip: values.zip?.trim() ?? "",
					websiteUrl: values.websiteUrl?.trim() || "",
					shortDesc: values.shortDesc?.trim() ?? "",
				},
				errors: Object.keys(errors).length > 0 ? errors : {},
			};
		},
	});

	const registerWithValidation = (name, options = {}) => {
		return methods.register(name, {
			...options,
			required: options.required ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required` : false,
		});
	};

	const watchAllFields = methods.watch();
	const toggleSection = (id) => {
		setOpenSections((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (imageRef.current && !imageRef.current.contains(event.target)) {
				setIsHoveringImage(false);
			}
			if (imageRef.current && !imageRef.current.contains(event.target)) {
				setIsHoveringImage(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	useEffect(() => {
		if (octoUserData) {
			let allValues = methods.getValues();
			let values = { ...octoUserData };
			if (!values.suite || !values.suite?.length) values.suite = "";

			const isChanged = Object.keys(allValues).find((key) =>
				Array.isArray(allValues[key])
					? JSON.stringify(allValues[key]) !== JSON.stringify(values[key])
					: allValues[key] !== values[key]
			);

			setIsFormChanged(isChanged);
		}
	}, [watchAllFields, profileImage, contacts, octoUserData]);

	useEffect(() => {
		if (octoUserData) {
			setProfileImage(
				octoUserData["profileImage"]?.length > 0
					? octoUserData["profileImage"]
					: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
			);

			setCharCount(octoUserData["shortDesc"].length);
			Object.keys(defaultValues).forEach((key) => {
				if (octoUserData[key]) methods.setValue(key, octoUserData[key]);
			});
			let allContacts = methods.getValues("contacts");
			setContacts(allContacts ?? []);
			setIsFormChanged(false);
		}
		setIsProfileLoaded(true);
	}, [octoUserData]);

	const handleImageChange = async (event) => {
		console.log({ event });
		const file = event.target.files[0];

		if (file) {
			toast.loading("Uploading image...");
			const reader = new FileReader();
			reader.onloadend = () => {
				setProfileImage(reader.result);
			};
			reader.readAsDataURL(file);
			let imgUrl = await uploadImageToFirebase(file);

			console.log({ imgUrl, file });

			let docRef = doc(db, "octoMarketUsers", octoUserData.id);
			try {
				await updateDoc(docRef, { profileImage: imgUrl });
			} catch (error) {
				toast.error("Something went wrong during updating image!");
			} finally {
				toast.dismiss();
				toast.success("Image updated successfully!");
				setIsHoveringImage(false);
			}
		}
	};

	console.log("====================================");
	console.log({ octoUserData });
	console.log("====================================");

	const handleAddContact = async () => {
		let err = {};
		if (
			!newContact.email.trim() ||
			contacts.find((contact) => contact.email == newContact.email?.trim()) ||
			!emailRegex.test(newContact.email?.trim())
		) {
			err.email = "Invalid Email";
		}
		if (newContact.firstName?.trim()?.length < 3) {
			err.firstName = "Invalid first name";
		}

		if (newContact.lastName?.trim()?.length < 3) {
			err.lastName = "Invalid last name";
		}
		if (!newContact.phone?.trim()?.length) {
			err.phone = "Invalid phone";
		}

		if (Object.keys(err).length > 0) {
			setNewContactError(err);
			return;
		}

		const newContacts = [...contacts, { ...newContact, id: Math.floor(Math.random() * 100000) }];
		setContacts(newContacts);
		setNewContact(defaultContact);
		setIsAddingContact(false);
		console.log("====================================");
		console.log({ values: methods.getValues() });
		console.log("====================================");
		// methods.setValue("contacts", newContacts);
		let docRef = doc(db, "octoMarketUsers", octoUserData.id);
		await updateDoc(docRef, { ...methods.getValues(), contacts: newContacts });
	};

	const onChangeHandler = (val, type = "") => {
		setNewContact((prev) => ({ ...prev, [type]: val }));
		let err = {};
		if (type == "email") {
			if (!val.trim() || contacts.find((contact) => contact.email == val?.trim()) || !emailRegex.test(val?.trim())) {
				err.email = "Invalid Email";
			}
		}
		if (type == "firstName")
			if (val?.trim()?.length < 3) {
				err.firstName = "Invalid first name";
			}
		if (type == "lastName")
			if (val?.trim()?.length < 3) {
				err.lastName = "Invalid last name";
			}
		if (type == "phone")
			if (!val?.trim()?.length) {
				err.phone = "Invalid phone";
			}

		setNewContactError((prev) => ({ ...prev, [type]: err[type] }));
	};

	const handleDeleteContact = async (index) => {
		const newContacts = contacts.filter((_, i) => index !== i);
		setContacts(newContacts);
		console.log({ values: methods.getValues() });
		// methods.setValue("contacts", newContacts);
		let docRef = doc(db, "octoMarketUsers", octoUserData.id);
		await updateDoc(docRef, { ...methods.getValues(), contacts: newContacts });
	};

	const uploadImageToFirebase = async (imageFile) => {
		try {
			const timestamp = Date.now();
			const storageRef = ref(storage, `profile-images/${timestamp}-${imageFile.name}`);

			const snapshot = await uploadBytes(storageRef, imageFile);

			const downloadURL = await getDownloadURL(snapshot.ref);
			return downloadURL;
		} catch (error) {
			console.error("Error uploading image:", error);
			throw error;
		}
	};

	const onSubmit = async (data) => {
		console.log("After onsubmit the data", data);
		localStorage.setItem("octoUserProfileData", JSON.stringify(data));

		const formData = {
			...data,
			contacts,
		};

		toast.loading("Saving..");

		let toUpdateDb = {
			...octoUserData,
			...formData,
			profileCompleted: true,
			userType: octoUserData.userType,
			popularityIndexCount: Number(octoUserData.popularityIndexCount) ?? 0,
		};

		toUpdateDb = Object.fromEntries(Object.entries(toUpdateDb).filter(([_, value]) => value !== undefined));
		try {
			let docRef = doc(db, "octoMarketUsers", octoUserData.id);

			await updateDoc(docRef, toUpdateDb);

			if (!octoUserData.profileCompleted && octoUserData.userType != "Transporter") {
				await sendApprovalRequest();
			}

			Object.keys(formData).forEach((key) => {
				defaultValues[key] = formData[key];
			});

			dispatch(updateUserData(toUpdateDb));

			setIsFormChanged(false);
			methods.reset(formData);
		} catch (error) {
			console.log({ error });
		} finally {
			toast.dismiss();
			toast.success("Saved successfully!");
		}
	};

	return (
		<div className="">
			<div className="container mx-auto py-16">
				<motion.div
					className="h-32 mx-4 bg-[#007AFF] p-4 rounded-t-xl"
					initial={{ opacity: 0, scale: 0.95 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5 }}
				/>

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)} className="mx-4 px-8 mb-20 bg-white pb-16 rounded-b-xl">
						<motion.div
							className="flex items-center justify-between py-10"
							initial={{ opacity: 0, x: -50 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.5 }}
						>
							<div className="flex items-center gap-4">
								<div
									className="relative bg-[#dfdfdf] rounded-lg h-28 overflow-hidden"
									onMouseEnter={() => setIsHoveringImage(true)}
								>
									{!profileLoaded ? (
										<Loader height="" />
									) : (
										<img
											src={
												profileImage?.length > 0
													? profileImage
													: "https://storage.googleapis.com/routes-123.appspot.com/assets/logo%201.png"
											}
											className="h-28 rounded-lg object-cover"
											alt="Profile"
										/>
									)}
									{isHoveringImage && (
										<div
											ref={imageRef}
											className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
										>
											<label className="cursor-pointer">
												<input
													type="file"
													className="hidden"
													accept="image/*"
													onClick={() => setIsHoveringImage(true)}
													onChange={handleImageChange}
												/>
												<FiUpload className="text-white text-2xl" />
											</label>
										</div>
									)}
								</div>
								<div>
									<h1 className="text-xl font-semibold capitalize">{octoUserData?.companyName}</h1>
									<p className="text-gray-500">{formatAddVendor(octoUserData)}</p>
								</div>
							</div>
							<motion.button
								type="submit"
								disabled={!isFormChanged}
								className={`px-10 py-3 rounded-full ${
									isFormChanged
										? "bg-[#007AFF] text-white hover:bg-blue-700"
										: "bg-gray-300 text-gray-500 cursor-not-allowed"
								}`}
								whileHover={isFormChanged ? { scale: 1.05 } : {}}
								whileTap={isFormChanged ? { scale: 0.95 } : {}}
							>
								Save
							</motion.button>
						</motion.div>

						<div className="grid grid-cols-2 gap-12 xl:gap-20">
							<div className="space-y-8">
								<div className="flex items-center gap-4 justify-between">
									<label className="flex w-1/4 gap-4 items-center text-black mb-2">
										<h6>Company Name</h6>
										{octoUserData?.userType == "Transporter" && (
											<div className="relative group">
												<BsFillInfoCircleFill size={14} className="text-cardTextGray group" />
												<div className="opacity-0 group-hover:opacity-100 transition-all duration-300 absolute left-1/2 y-1/2 bg-gray-900 text-white text-sm px-2 py-1 rounded pointer-events-none whitespace-nowrap">
													You can change the company name from transporter settings page!
												</div>
											</div>
										)}
									</label>
									<div className="w-3/4">
										<input
											{...registerWithValidation("companyName", { required: true })}
											className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full outline-none ${
												methods.formState.errors.companyName ? "outline outline-red-500" : "focus:outline-blue-500"
											}`}
											disabled={octoUserData?.userType == "Transporter"}
										/>
										{methods.formState.errors.companyName && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.companyName.message}</p>
										)}
									</div>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">
										Street <span className="text-red-500">*</span>
									</label>
									<div className="w-3/4">
										<input
											{...registerWithValidation("street", { required: true })}
											className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full outline-none ${
												methods.formState.errors.street ? "outline outline-red-500" : "focus:outline-blue-500"
											}`}
											placeholder="Enter Street"
										/>
										{methods.formState.errors.street && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.street.message}</p>
										)}
									</div>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">Suite</label>
									<input
										{...registerWithValidation("suite", { required: true })}
										className={`w-3/4 p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full outline-none focus:outline-blue-500`}
										placeholder="Enter Suite"
									/>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">
										City <span className="text-red-500">*</span>
									</label>
									<div className="w-3/4">
										<input
											{...registerWithValidation("city", { required: true })}
											className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full outline-none ${
												methods.formState.errors.city ? "outline outline-red-500" : "focus:outline-blue-500"
											}`}
											placeholder="Enter City"
										/>
										{methods.formState.errors.city && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.city.message}</p>
										)}
									</div>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">
										State <span className="text-red-500">*</span>
									</label>
									<div className="w-3/4">
										<Controller
											name="state"
											control={methods.control}
											rules={{ required: "State is required" }}
											render={({ field }) => (
												<StyledSingleSelect
													label="State"
													options={USStates.map((item) => ({ label: item.value, value: item.value }))}
													selectedOption={field.value}
													setSelectedOption={(value) => field.onChange(value)}
													error={methods.formState.errors.state?.message}
												/>
											)}
										/>
										{methods.formState.errors.state && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.state.message}</p>
										)}
									</div>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">
										Zip <span className="text-red-500">*</span>
									</label>
									<div className="w-3/4">
										<input
											{...registerWithValidation("zip", { required: true })}
											className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full outline-none ${
												methods.formState.errors.zip ? "outline outline-red-500" : "focus:outline-blue-500"
											}`}
											placeholder="Enter Zip Code"
										/>
										{methods.formState.errors.zip && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.zip.message}</p>
										)}
									</div>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">
										Phone <span className="text-red-500">*</span>
									</label>
									<div className="w-3/4">
										<Controller
											name="phone"
											control={methods.control}
											rules={{ required: "Phone number is required" }}
											render={({ field: { value, onChange } }) => (
												<PhoneInput
													addStyle={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
														methods.formState.errors.phone ? "outline outline-red-500" : "focus:outline-blue-500"
													}`}
													label={null}
													value={value}
													onChange={onChange}
													margin=""
												/>
											)}
										/>
										{methods.formState.errors.phone && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.phone.message}</p>
										)}
									</div>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="flex w-1/4 gap-4 items-center text-black mb-2">
										<h6>Email</h6>
										<div className="relative group">
											<BsFillInfoCircleFill size={14} className="text-cardTextGray group" />
											<div className="opacity-0 group-hover:opacity-100 transition-all duration-300 absolute left-1/2 y-1/2 bg-gray-900 text-white text-sm px-2 py-1 rounded pointer-events-none whitespace-nowrap">
												Primary email is not subjected to change!
											</div>
										</div>
									</label>
									<input
										{...methods.register("email")}
										className="w-3/4 p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full focus:outline-blue-500"
										disabled
									/>
								</div>

								<div className="flex items-center gap-4 justify-between">
									<label className="block w-1/4 text-black mb-2 text-nowrap">Website URL</label>
									<div className="w-3/4">
										<input
											{...methods.register("websiteUrl", {
												pattern: {
													value: urlRegex,
													message: "Please enter a valid URL",
												},
											})}
											className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full ${
												methods.formState.errors.websiteUrl
													? "outline outline-red-500"
													: "focus:outline focus:outline-2 focus:outline-blue-500"
											}`}
											placeholder="Enter website URL"
										/>
										{methods.formState.errors.websiteUrl && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.websiteUrl.message}</p>
										)}
									</div>
								</div>

								{/* Contacts Section */}
								<div>
									<label className="block text-xl font-medium text-black mb-2">Contacts</label>
									<div className="space-y-3">
										{contacts.map((contact, index) => (
											<div
												key={contact.firstName + contact.lastName + contact.email}
												className="rounded-lg overflow-hidden"
											>
												<button
													type="button"
													onClick={() => toggleSection(contact.id)}
													className="w-full text-[#007AFF] text-lg flex justify-between items-center bg-white transition-colors"
												>
													<span className="font-medium capitalize">{`Contact ${index + 1}`}</span>
													<IoIosArrowDown
														className={`w-5 h-5 transition-transform duration-200 ${
															openSections[contact.id] ? "transform rotate-180" : ""
														}`}
													/>
												</button>

												<div
													className={`overflow-hidden flex justify-between transition-all duration-200 ${
														openSections[contact.id] ? "max-h-48" : "max-h-0"
													}`}
												>
													<div className="p-4 space-y-2">
														<p className="text-sm text-gray-600 capitalize">
															<span className="font-medium">Name:</span> {`${contact.firstName} ${contact.lastName}`}
														</p>
														<p className="text-sm text-gray-600">
															<span className="font-medium">Phone:</span> {contact.phone}
														</p>
														<p className="text-sm text-gray-600">
															<span className="font-medium">Email:</span> {contact.email}
														</p>
													</div>
													<button
														type="button"
														onClick={() => handleDeleteContact(index)}
														className="h-fit relative group p-1.5 rounded-full hover:bg-cardTextGray hover:bg-opacity-10 transition-opacity duration-200 text-red-500 hover:text-red-700"
													>
														<RxCross2 size={20} />
													</button>
												</div>
											</div>
										))}
										{contacts.length < 2 ? (
											!isAddingContact ? (
												<button
													type="button"
													className="text-[#007AFF] flex gap-2 items-center bg-[#007AFF] hover:bg-primaryBlue hover:bg-opacity-20 bg-opacity-10 px-6 rounded-lg py-2 hover:scale-[102%] transition-transform duration-200 hover:text-blue-700"
													onClick={() => setIsAddingContact(true)}
												>
													<FiPlus />
													<p className="">Add Contact</p>
												</button>
											) : (
												<>
													<div className="grid grid-cols-1 gap-8">
														<div className="flex items-center gap-4 justify-between">
															<label className="block text-black w-1/4 text-nowrap mb-2">First Name</label>
															<input
																type="text"
																className={`w-3/4 p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.firstName ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																value={newContact.firstName}
																onChange={(e) => onChangeHandler(e.target.value, "firstName")}
																placeholder="Enter First Name"
																autoFocus={newContactError.firstName}
															/>
														</div>
														<div className="flex items-center gap-4 justify-between">
															<label className="block text-black mb-2 w-1/4 text-nowrap">Last Name</label>
															<input
																type="text"
																className={`w-3/4 p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.lastName ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																value={newContact.lastName}
																onChange={(e) => onChangeHandler(e.target.value, "lastName")}
																placeholder="Enter Last Name"
																autoFocus={newContactError.lastName}
															/>
														</div>
														<div className="flex items-center gap-4 justify-between">
															<label className="block text-black mb-2 w-1/4 text-nowrap">Phone</label>
															<div className="w-3/4">
																<PhoneInput
																	addStyle={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																		newContactError.phone ? "outline-red-500" : "focus:outline-blue-500"
																	}`}
																	label={null}
																	value={newContact.phone ?? ""}
																	onChange={(val) => onChangeHandler(val, "phone")}
																	margin=""
																/>
															</div>
														</div>
														<div className="flex items-center gap-4 justify-between">
															<label className="block text-black mb-2 w-1/4 text-nowrap">Email</label>
															<input
																type="email"
																className={`w-3/4 p-3 px-5 bg-[#F1F1F4] text-gray-700 outline-none rounded-full ${
																	newContactError.email ? "outline-red-500" : "focus:outline-blue-500"
																}`}
																value={newContact.email}
																onChange={(e) => onChangeHandler(e.target.value, "email")}
																placeholder="Enter Email"
																autoFocus={newContactError.email}
															/>
														</div>
													</div>
													<div className="flex gap-4 pt-4">
														<motion.button
															type="button"
															className="bg-[#007AFF] text-white px-6 h-11 w-28 rounded-full hover:bg-primary"
															whileHover={{ scale: 1.05 }}
															whileTap={{ scale: 0.95 }}
															onClick={handleAddContact}
														>
															Save
														</motion.button>
														<motion.button
															type="button"
															className="border border-cardTextGray text-cardTextGray px-6 h-11 w-28 rounded-full hover:bg-cardTextGray hover:bg-opacity-10"
															whileHover={{ scale: 1.05 }}
															whileTap={{ scale: 0.95 }}
															onClick={() => {
																setIsAddingContact(false);
																setNewContactError({});
															}}
														>
															Cancel
														</motion.button>
													</div>
												</>
											)
										) : (
											<p className="text-cardTextGray">Maximum of 2 contacts has already been added</p>
										)}
									</div>
								</div>
							</div>

							{/* Right Column */}
							<div className="space-y-8">
								<div className="w-full flex items-center gap-4 justify-between">
									{/* <div className="flex justify-between"> */}
									<div className="block w-1/4 text-black text-nowrap mb-2">
										<label className="flex flex-col items-start">
											<h6>
												Category <span className="text-red-500">*</span>
											</h6>
											<h6 className="text-cardTextGray">(Up to 3 categories)</h6>
										</label>
									</div>
									<div className="w-3/4">
										<Controller
											name="category"
											control={methods.control}
											rules={{ required: "At least one category is required" }}
											render={({ field }) => (
												<StyledMultiSelect
													label="Category"
													options={serviceOptions}
													selectedOptions={field.value}
													setSelectedOptions={(value) => field.onChange(value)}
													error={methods.formState.errors.category?.message}
												/>
											)}
										/>
									</div>
								</div>

								<ServiceAreaSelector />

								<div className="flex items-start gap-4 justify-between">
									{/* <div className="flex justify-between items-center"> */}
									<label className="block text-black mb-2 w-1/4">
										Short Description <span className="text-red-500">*</span>
										<div className="text-sm text-gray-500">({charCount}/100)</div>
									</label>
									{/* </div> */}
									<div className="grid grid-cols-1 w-3/4">
										<textarea
											{...registerWithValidation("shortDesc", { required: true })}
											className={`w-full p-3 px-5 h-fit bg-[#F1F1F4] text-gray-700 outline-none rounded-[24px] ${
												methods.formState.errors.shortDesc ? "outline outline-red-500" : "focus:outline-blue-500"
											}`}
											maxLength={100}
											onInput={(e) => {
												e.target.style.height = "48px";
												e.target.style.height = `${e.target.scrollHeight}px`;
												setCharCount(e.target.value.length);
											}}
										/>
										{methods.formState.errors.shortDesc && (
											<p className="mt-1 text-sm text-red-500">{methods.formState.errors.shortDesc.message}</p>
										)}
									</div>
								</div>

								<BioTextarea methods={methods} errors={methods.formState.errors} />
							</div>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export default ProfileSettings;

import { useFormContext } from "react-hook-form";
import PhoneInput from "../../../../components/UI/PhoneInput";
import { FaRegTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import useConnectionManager from "../../../../hooks/useConnectionManager";
import Loader from "../../../../components/UI/loaders/Loader";
import { toast } from "react-toastify";
import { stateOptions } from "../../../admin/assets/components/vendors/AddNewVendor";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { fetchUserData, formatAddVendor, updateUserData } from "../../../../store/sliceReducers/octoUserSlice";
import { serviceOptions, USStates } from "../../../../utils/constants";

const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: { staggerChildren: 0.1 },
	},
};

const itemVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: {
		opacity: 1,
		y: 0,
		transition: { type: "spring", stiffness: 300, damping: 24 },
	},
};

const radioVariants = {
	unchecked: { scale: 1 },
	checked: {
		scale: 1.1,
		transition: { type: "spring", stiffness: 300, damping: 10 },
	},
};

const localFieldsVariants = {
	hidden: {
		opacity: 0,
		height: 0,
		transition: { duration: 0.2 },
	},
	visible: {
		opacity: 1,
		height: "auto",
		transition: { duration: 0.3, ease: "easeOut" },
	},
};

const ServiceAreaSelector = () => {
	const {
		register,
		watch,
		setValue,
		control,
		formState: { errors },
	} = useFormContext();

	const shippingType = watch("serviceArea");
	const areas = watch("localAreas");
	const [localAreas, setLocalAreas] = useState([]);
	const [newArea, setNewArea] = useState({
		city: "",
		state: "",
		radius: "",
	});
	const [areaErrors, setAreaErrors] = useState({});

	useEffect(() => {
		setLocalAreas(areas ?? []);
	}, [areas]);

	// Validation function for the service area
	const validateServiceArea = () => {
		const errors = {};

		if (shippingType === "local" && localAreas.length === 0) {
			errors.localAreas = "At least one service area is required";
		}

		return errors;
	};

	const handleAddServiceArea = () => {
		const errors = {};

		if (!newArea.city.trim()) {
			errors.city = "City is required";
		}
		if (!newArea.state) {
			errors.state = "State is required";
		}
		if (!newArea.radius) {
			errors.radius = "Radius is required";
		}

		setAreaErrors(errors);

		if (Object.keys(errors).length === 0) {
			const areaString = `${newArea.city}, ${newArea.state}, ${newArea.radius} miles`;
			const updatedAreas = [...localAreas, areaString];
			setLocalAreas(updatedAreas);
			setValue("localAreas", updatedAreas);

			// Reset form
			setNewArea({
				city: "",
				state: "",
				radius: "",
			});
			setAreaErrors({});
		}
	};

	const handleRemoveServiceArea = (indexToRemove) => {
		const updatedAreas = localAreas.filter((_, index) => index !== indexToRemove);
		setLocalAreas(updatedAreas);
		setValue("localAreas", updatedAreas);
	};

	return (
		<motion.div className="" initial="hidden" animate="visible" variants={containerVariants}>
			<h2 className="mb-2 text-black">
				Service Area <span className="text-red-500">*</span>
			</h2>

			<motion.div className="flex items-center gap-8" variants={itemVariants}>
				<label className="flex items-center space-x-3 cursor-pointer">
					<motion.div
						variants={radioVariants}
						className="h-5"
						animate={shippingType === "nationwide" ? "checked" : "unchecked"}
					>
						<input
							type="radio"
							value="nationwide"
							{...register("serviceArea", { required: "Please select a service area type" })}
							className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
						/>
					</motion.div>
					<span>Nationwide Shipping</span>
				</label>

				<label className="flex items-center space-x-3 cursor-pointer">
					<motion.div
						variants={radioVariants}
						className="h-5"
						animate={shippingType === "local" ? "checked" : "unchecked"}
					>
						<input
							type="radio"
							value="local"
							{...register("serviceArea", { required: "Please select a service area type" })}
							className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
						/>
					</motion.div>
					<span>City, State, Radius (Miles)</span>
				</label>
			</motion.div>

			{errors.serviceArea && <p className="mt-1 text-sm text-red-500">{errors.serviceArea.message}</p>}

			<AnimatePresence>
				{shippingType === "local" && (
					<motion.div
						className="flex flex-col pt-4 gap-4"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={localFieldsVariants}
					>
						<div className="flex items-center w-full gap-4">
							<motion.div variants={itemVariants} className="w-1/3">
								<input
									type="text"
									placeholder="City *"
									value={newArea.city}
									onChange={(e) => {
										setNewArea((prev) => ({ ...prev, city: e.target.value }));
										setAreaErrors((prev) => ({ ...prev, city: "" }));
									}}
									className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full ${
										areaErrors.city ? "outline outline-red-500" : "focus:outline-blue-500"
									}`}
								/>
								{areaErrors.city && <p className="mt-1 text-sm text-red-500">{areaErrors.city}</p>}
							</motion.div>

							<motion.div variants={itemVariants} className="w-1/3 relative z-20">
								<Controller
									name="serviceAreaState"
									control={control}
									render={({ field }) => (
										<StyledSingleSelect
											options={USStates.map((item) => ({ label: item.value, value: item.value }))}
											selectedOption={newArea.state}
											setSelectedOption={(value) => {
												setNewArea((prev) => ({ ...prev, state: value }));
												setAreaErrors((prev) => ({ ...prev, state: "" }));
											}}
											placeholder="State *"
											error={areaErrors.state}
										/>
									)}
								/>
							</motion.div>

							<motion.div variants={itemVariants} className="w-1/6">
								<input
									type="number"
									placeholder="Miles *"
									min={0}
									value={newArea.radius}
									onChange={(e) => {
										setNewArea((prev) => ({ ...prev, radius: e.target.value }));
										setAreaErrors((prev) => ({ ...prev, radius: "" }));
									}}
									className={`w-full p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-full ${
										areaErrors.radius ? "outline outline-red-500" : "focus:outline-blue-500"
									}`}
								/>
								{areaErrors.radius && <p className="mt-1 text-sm text-red-500">{areaErrors.radius}</p>}
							</motion.div>

							<motion.div variants={itemVariants} className="w-1/6">
								<button
									type="button"
									onClick={handleAddServiceArea}
									disabled={!newArea.city || !newArea.state || !newArea.radius}
									className="bg-blue-500 text-white px-8 w-full py-3 rounded-full flex justify-center items-center hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
								>
									Add
								</button>
							</motion.div>
						</div>

						{shippingType === "local" && localAreas.length === 0 && (errors.localAreas || areaErrors.localAreas) && (
							<p className="mt-1 text-sm text-red-500">{errors.localAreas?.message || areaErrors.localAreas}</p>
						)}

						{localAreas.length > 0 && (
							<motion.div
								variants={itemVariants}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								className="relative z-10"
							>
								<h3 className="text-md mb-2">Added Service Areas</h3>
								<div className="space-y-2">
									{localAreas.map((area, index) => (
										<motion.div
											key={index}
											className="flex justify-between items-center bg-gray-100 p-3 rounded-full px-5"
											initial={{ opacity: 0, x: -20 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: 20 }}
										>
											<div>
												<span className="text-base font-normal">{area}</span>
											</div>
											<button
												type="button"
												onClick={() => handleRemoveServiceArea(index)}
												className="text-red-500 rounded-full hover:text-red-800 transition-colors duration-150"
											>
												<FaRegTrashAlt size={20} />
											</button>
										</motion.div>
									))}
								</div>
							</motion.div>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};

const BioTextarea = ({ methods, errors }) => {
	const bio = methods.watch("bio");

	useEffect(() => {
		const textarea = document.querySelector('textarea[name="bio"]');

		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	}, [bio]);

	return (
		<>
			<div className="flex items-start gap-4 justify-between">
				<label className="block text-nowrap w-1/4 text-black mb-2">
					Bio <span className="text-red-500">*</span>
				</label>
				<div className="w-3/4">
					<textarea
						{...methods.register("bio", { required: true })}
						className={`w-full p-3 px-5 h-fit bg-[#F1F1F4] text-gray-700 outline-none rounded-[24px] ${
							errors.bio ? "outline outline-red-500" : "focus:outline-blue-500"
						}`}
					/>
					{errors.bio && <p className="mt-1 text-sm text-red-500">{errors.bio?.message}</p>}
				</div>
			</div>
		</>
	);
};
