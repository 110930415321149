import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Operations = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname == "/admin/operations") navigate("/admin/operations/live-view");
		// else navigate(location.pathname);
	}, [location.pathname]);

	return (
		<div>
			<Outlet />
		</div>
	);
};

export default Operations;
