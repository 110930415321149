import { useContext, useEffect, useMemo, useState } from "react";
import {
	AzureMapDataSourceProvider,
	AzureMapHtmlMarker,
	AzureMapLayerProvider,
	AzureMapsContext,
} from "react-azure-maps";
import { source, Shape } from "azure-maps-control";
import { MILES_CONSTANT, restRoutingRequestUrl } from "../../utils/constants";
import { data } from "azure-maps-control";
import CustomHtmMarker from "./CustomHtmMarker";
import RoundedPin from "../../pages/admin/assets/components/routes-optimization/components/RoundedPin";
import { COLLECTIONS, db } from "../../config/firebase";
import { formatDuration, randomizeCoordinates } from "../../utils/helpers";
const CustomAzureRoute = ({ route, setRouteColumns }) => {
	const [currentCollection, setCurrentCollection] = useState(new data.LineString([]));
	const { mapRef } = useContext(AzureMapsContext);
	useEffect(() => {
		console.log("Rendering Route");
		console.log("Route", route);
		const query = [];
		const defaultOrder = [];
		const waypoints = route.waypoints ?? [];
		query.push(route.defaultStartLocation.lat + "," + route.defaultStartLocation.lng);
		for (var i = 0; i < waypoints.length; i++) {
			query.push(waypoints[i].lat + "," + waypoints[i].lng);
			defaultOrder.push(i);
		}
		query.push(route.defaultEndLocation.lat + "," + route.defaultEndLocation.lng);
		const waypointQuery = query.join(":");
		console.log("Waypoint Query", waypointQuery);
		calculateRoute(waypointQuery);

		return () => {};
	}, [route, route?.defaultStartLocation, route?.defaultEndLocation, route?.waypoints]);

	const calculateRoute = (waypointQuery) => {
		//Remove any previously calculated route information.

		//Create request to calculate a route in the order in which the waypoints are provided.
		var requestUrl = restRoutingRequestUrl.replace("{query}", waypointQuery);

		//Proces the request.
		fetch(requestUrl, {
			headers: {
				"Subscription-Key": import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
			},
		})
			.then((r) => {
				return r.json();
			})
			.then((r) => {
				console.log("Fetching Route", r);
				const response = r.routes[0];
				const lengthInMeters = response.summary.lengthInMeters;
				const travelTimeInSeconds = response.summary.travelTimeInSeconds;

				const distanceEl = document.getElementById(`totalDistanceInMeters_${route.id}`);
				const timeEl = document.getElementById(`totalTimeInSeconds_${route.id}`);
				if (distanceEl) distanceEl.innerHTML = parseFloat(lengthInMeters * MILES_CONSTANT).toFixed(2) + "Mile(s)";
				let totalServiceDuration = 0;
				if (route.waypoints) {
					route.waypoints.forEach((waypoint) => {
						if (waypoint?.scheduleData?.serviceDuration && !isNaN(waypoint.scheduleData.serviceDuration)) {
							totalServiceDuration += parseInt(waypoint.scheduleData.serviceDuration) * 60;
						} else {
							totalServiceDuration += 15 * 60;
						}
					});
				}
				if (timeEl) timeEl.innerHTML = formatDuration(travelTimeInSeconds, totalServiceDuration);
				let routeCoordinates = [];
				for (let legIndex = 0; legIndex < response.legs.length; legIndex++) {
					let leg = response.legs[legIndex];
					//Convert the route point data into a format that the map control understands.
					let legCoordinates = leg.points.map(function (point) {
						return [point.longitude, point.latitude];
					});
					//Combine the route point data for each route leg together to form a single path.
					routeCoordinates = routeCoordinates.concat(legCoordinates);
				}
				//Create a LineString from the route path points and add it to the data source.
				setCurrentCollection(new data.LineString([...routeCoordinates]));
			})
			.catch((e) => {
				console.error({ "Fetch Error": e });
			});
	};

	const startMarker = useMemo(() => {
		console.log("Rendering Start Marker");
		const randomCoordinates = randomizeCoordinates(route.defaultStartLocation.lat, route.defaultStartLocation.lng);
		return (
			<CustomHtmMarker
				id={`Route Start Marker ${route.id}`}
				coordinates={{ lat: route.defaultStartLocation.lat, lng: route.defaultStartLocation.lng }}
				randomCoordinates={randomCoordinates}
				draggable={true}
				pin={
					<RoundedPin
						className={"min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative object-cover"}
						color={route.color}
						text={"S"}
					/>
				}
			/>
		);
	}, [route.id, route.defaultStartLocation.lat, route.defaultStartLocation.lng, route.color]);

	// Similarly memoize the end marker
	const endMarker = useMemo(() => {
		console.log("Rendering End Marker");
		const randomCoordinates = randomizeCoordinates(route.defaultEndLocation.lat, route.defaultEndLocation.lng);
		return (
			<CustomHtmMarker
				id={`Route End Marker ${route.id}`}
				coordinates={{ lat: route.defaultEndLocation.lat, lng: route.defaultEndLocation.lng }}
				randomCoordinates={randomCoordinates}
				draggable={true}
				pin={
					<RoundedPin
						className={"min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative object-cover"}
						color={route.color}
						text={"E"}
					/>
				}
			/>
		);
	}, [route.id, route.defaultEndLocation.lat, route.defaultEndLocation.lng, route.color]);

	return (
		<>
			{startMarker}
			{endMarker}
			<AzureMapDataSourceProvider
				id={`LineLayer DataSourceProvider ${route.id} routeLine ${Math.random().toString()}`}
				collection={currentCollection}
			>
				<AzureMapLayerProvider
					type="LineLayer"
					options={{
						strokeColor: route.color ?? "black",
						strokeWidth: 3,
						strokeOpacity: 1,
						blur: 0,
						lineCap: "round",
						translate: [0, 0],
					}}
				/>
			</AzureMapDataSourceProvider>
		</>
	);
};

export default CustomAzureRoute;
