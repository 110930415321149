import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, signOutUser } from "../../config/firebase";
import Loader from "../../components/UI/loaders/Loader";
import Header from "./components/Header";
import AuthForm from "./components/auth/Auth";
import Modal from "./components/auth/RegistrationNotCompleted";
import { clearNotificationListener, fetchUserData, listenToUserData } from "../../store/sliceReducers/octoUserSlice";
import MarketIcon from "../../components/sidebars/MarketIcon";
import Octo365Icon from "../../components/sidebars/Octo365Icon";

const tabs = [
	{ label: "Home", link: "/market" },
	{ label: "Connections", link: "/market/connections" },
	{ label: "Company Profile", link: "/market/profile" },
];

const Index = () => {
	const [user, loading] = useAuthState(auth);
	const [isLoading, setIsLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(0);
	const { data: octoUserData, loading: userLoading } = useSelector((state) => state.octoUserData);
	const [modalOpen, setModalOpen] = useState(true);
	const scrollContainerRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (user) {
			dispatch(fetchUserData(user.uid));
			dispatch(listenToUserData(user.uid));
		}

		return () => {
			dispatch(clearNotificationListener());
		};
	}, [dispatch, user]);

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
		console.log({ octoUserData });

		if (octoUserData?.role == "octoMarketAdmin") {
			navigate("/market-admin");
		}

		if (location.pathname === "/market/profile") {
			setModalOpen(false);
		} else if (octoUserData?.profileCompleted === false || octoUserData?.rejected) {
			setModalOpen(true);
		}

		let indexOfActiveTab = tabs.findIndex((tab) => tab?.link === location.pathname);
		setActiveTab(indexOfActiveTab);
	}, [location.pathname, octoUserData, userLoading]);

	const logOutHandler = async () => {
		await signOutUser();
		navigate("/market");
	};

	useEffect(() => {
		setIsLoading(false);
		let indexOfActiveTab = tabs.findIndex((tab) => tab?.link === location.pathname);
		setActiveTab(indexOfActiveTab);
	}, []);

	if (loading || isLoading) return <Loader />;
	if (!user && !isLoading && !loading) return <Navigate to={"/auth"}></Navigate>;

	// Profile completion check
	if (!octoUserData?.profileCompleted && !loading && !userLoading && modalOpen) {
		return (
			<Modal isOpen={modalOpen} onClose={() => navigate("/market/profile")} title="Profile Incomplete">
				<p>You will need to complete the profile first in order to access the site!</p>
			</Modal>
		);
	}

	// Profile rejection check
	if (octoUserData?.rejected && modalOpen) {
		return (
			<Modal isOpen={modalOpen} onClose={() => navigate("/market/profile")} title="Profile Rejected">
				<p>Your account approval request has been rejected, please contact administrator!</p>
			</Modal>
		);
	}

	console.log({ octoUserData, loading, userLoading });

	if (userLoading) return <Loader />;

	return (
		<div className="h-screen relative">
			<Header user={user} logOut={logOutHandler} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
			<div className="h-screen absolute w-64 flex flex-col p-4 justify-end items-center bg-white border-r">
				<button
					onClick={() => navigate("/admin")}
					className={`group py-2 px-4 flex items-center text-sm xl:text-base hover:cursor-pointer`}
				>
					<Octo365Icon />
				</button>
			</div>
			<div
				style={{ height: `calc(100%)` }}
				className="bg-[#F9F9F9] w-auto pl-64 z-40 pt-16 overflow-scroll"
				ref={scrollContainerRef}
			>
				<Outlet />
			</div>
		</div>
	);
};

export default Index;
