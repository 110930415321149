import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GeneratorBreadcrumb from "./GeneratorBreadcrumb";
import GeneratorUserAccBtn from "../../pages/generator-portal/GenUserAccountButton/GeneratorUserAccountButton";

const GeneratorHeader = () => {
	const [breadCrumbOptions, setBreadCrumbOptions] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const currentPath = location.pathname;


	useEffect(() => {
		setBreadCrumbOptions(getBreadcrumbItems(location));
	}, [location]);

	const activeIndex = breadCrumbOptions.findIndex((item) => location.pathname.endsWith(item.path));

	return (
		<>
			{currentPath !== "/generator-dashboard" && (
				<div className="top-0 flex items-center px-8 text-sm xl:text-base justify-between bg-white border h-20">
					<GeneratorBreadcrumb items={breadCrumbOptions} activeIndex={activeIndex} navigate={navigate} />
					<GeneratorUserAccBtn/>
				</div>
			)}
		
		</>
	);
};

const getBreadcrumbItems = (location) => {
	const pathMap = {
		"/generator-dashboard/service-reports": "Service Reports",
		"/generator-dashboard/invoices-and-payments": "Invoices and Payments",
		"/generator-dashboard/saved-payment-methods": "Saved Payment Method",
		"/generator-dashboard/make-payment": "Make Payment",
	};
	const currentPath = location.pathname;
	 if (currentPath === "/generator-dashboard/service-calendar") {
	 	return [{ label: "Dashboard", path: "/generator-dashboard" },{label:"Service Calendar"}];
	 }
	if (currentPath === "/generator-dashboard/service-reports") {
		return [
			{ label: "Service Reports", path: "/generator-dashboard/service-reports" },
		];
	}
	if (currentPath === "/generator-dashboard/invoices-and-payments") {
		return [
			{ label: "Invoices and Payments", path: "/generator-dashboard/invoices-and-payments" },
		];
	}
	if (currentPath === "/generator-dashboard/saved-payment-methods") {
		return [
			{label:"Invoices and Payments",path:"/generator-dashboard/invoices-and-payments"},
			{ label: "Saved Payment Method", path: "/generator-dashboard/saved-payment-methods" },
		];
	}
	if (currentPath === "/generator-dashboard/make-payment") {
		return [
			{ label: "Invoices and Payments", path: "/generator-dashboard/invoices-and-payments" },
			{ label: "Make Payment", path: "/generator-dashboard/make-payment" },
		];
	}
	const label = pathMap[currentPath] || null;

	return label ? [{ label, path: location.pathname }] : [];
};

export default GeneratorHeader;
