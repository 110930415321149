const generateTimeSlots = (start = 7, end = 7, intervalMinutes = 15) => {
	const slots = [];
	let current = new Date();
	current.setHours(start, 0, 0, 0);

	const endTime = new Date(current);
	endTime.setDate(endTime.getDate() + (end <= start ? 1 : 0));
	endTime.setHours(end, 0, 0, 0);

	while (current < endTime) {
		const hours = current.getHours();
		const minutes = current.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
		slots.push(`${formattedHour}:${formattedMinutes} ${ampm}`);

		current.setMinutes(current.getMinutes() + intervalMinutes);
	}

	return slots;
};

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, onSnapshot, query, setDoc, Timestamp, where } from "firebase/firestore";
import { auth, COLLECTIONS, db, getManifestUrl } from "../../../../../config/firebase";
import {
	LOCATION_TYPE_HOME_BASE,
	scheduledServiceStatus,
	SERVICE_STATUS,
	SERVICE_TYPES,
	serviceTypes,
} from "../../../../../utils/constants";
import Loader from "../../../../../components/UI/loaders/Loader";
import { toast } from "react-toastify";
import {
	dateFormatter,
	limitScrollWheelZoom,
	randomizeCoordinates,
	showInfoToastMessage,
} from "../../../../../utils/helpers";
import TodaysReportsProgressBar from "./TodaysReportsProgressBar";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { IoIosArrowDown } from "react-icons/io";
import MapWithMarkers from "../../../../../components/maps/MapWithMarkers";
import { getAllRoutes, processGeneratorData } from "../../../../../utils/firebaseOperations";
import { useAuthState } from "react-firebase-hooks/auth";
// import TimeSlotScroller from "./TimeSlotScroller";
import Button from "../../../../../components/UI/Button";
import useTUserContext from "../../../../../context/TransporterUserContext";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { AuthenticationType, AzureMap, AzureMapsContext } from "react-azure-maps";
import CustomAzureRoute from "../../../../../components/maps/CustomAzureRoute";
import CustomAzureWayPointMarker from "../../../../../components/maps/CustomAzureWayPointMarker";
import SquarePin from "../../../assets/components/routes-optimization/components/SquarePin";
import RoundedPin from "../../../assets/components/routes-optimization/components/RoundedPin";
import { FiSearch } from "react-icons/fi";
import { data } from "azure-maps-control";
import { FaMinus, FaPlus } from "react-icons/fa";
const hourMarkers = generateTimeSlots();

const statusMap = {
	PENDING: "Pending",
	INPROGRESS: "In Progress",
	COMPLETE: "Completed",
};

const authOptions = {
	authType: AuthenticationType.subscriptionKey,
	subscriptionKey: import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
};

const LiveView = () => {
	const navigate = useNavigate();
	const [totalServices, setTotalServices] = useState(0);
	const [totalPendingServices, setTotalPendingServices] = useState(0);
	const [totalInprogressServices, setTotalInprogressServices] = useState(0);
	const [totalCompletedServices, setTotalCompletedServices] = useState(0);
	const [outOfNetworkServiceCount, setOutOfNetworkServiceCount] = useState(0);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [locations, setLocations] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [routeData, setRouteData] = useState([]);
	const [routes, setRoutes] = useState([]);
	const [selectedSubContractors, setSelectedSubContractors] = useState([]);
	const [transporterData, setTransporterData] = useState(null);
	const [treatmentFacilities, setTreatmentFacilities] = useState([]);
	const [satelliteLocations, setSatelliteLocations] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [routeColumns, setRouteColumns] = useState([]);
	const [filteredRouteColumns, setFilteredRouteColumns] = useState([]);
	const { user, loading } = useTUserContext();
	const [openRoutes, setOpenRoutes] = useState({});
	const [listeners, setListeners] = useState([]);
	const [selectedRouteDetails, setSelectedRouteDetails] = useState(null);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const headerScrollRef = useRef(null);
	const contentScrollRef = useRef(null);
	const [activeGenCard, setActiveGenCard] = useState(null);
	const scrollRef = useRef(null);
	const { isMapReady, mapRef, removeMapRef } = useContext(AzureMapsContext);
	useEffect(
		() => () => {
			removeMapRef();
		},
		[]
	);
	useEffect(() => {
		if (isMapReady && mapRef && routeColumns.length > 0) {
			const markerPositions = [];
			routeColumns.forEach((route) => {
				route.waypoints.forEach((wp) => {
					markerPositions.push(new data.Position(wp.lng, wp.lat));
					markerPositions.push(new data.Position(wp.randomCoordinates.lng, wp.randomCoordinates.lat));
				});
			});
			const box = data.BoundingBox.fromPositions(markerPositions);
			mapRef.setCamera({ bounds: box, padding: 100, duration: 200, type: "ease" });
			limitScrollWheelZoom(mapRef);
		}
	}, [isMapReady, mapRef, routeColumns]);

	const scroll = (direction) => {
		const scrollAmount = 500;
		[headerScrollRef, contentScrollRef].forEach((ref) => {
			if (ref.current) {
				const newScrollLeft =
					direction === "left" ? ref.current.scrollLeft - scrollAmount : ref.current.scrollLeft + scrollAmount;

				ref.current.scrollTo({ left: newScrollLeft, behavior: "instant" });
				setTimeout(() => {
					ref.current.scrollTo({ left: newScrollLeft, behavior: "smooth" });
				}, 10);
			}
		});
	};

	useEffect(() => {
		if (!headerScrollRef.current || !contentScrollRef.current) return;

		const handleScroll = (e) => {
			if (e.target === headerScrollRef.current) {
				contentScrollRef.current.scrollLeft = e.target.scrollLeft;
			} else if (e.target === contentScrollRef.current) {
				headerScrollRef.current.scrollLeft = e.target.scrollLeft;
			}
		};

		headerScrollRef.current?.addEventListener("scroll", handleScroll);
		contentScrollRef.current?.addEventListener("scroll", handleScroll);

		return () => {
			headerScrollRef.current?.removeEventListener("scroll", handleScroll);
			contentScrollRef.current?.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleViewDetails = (route) => {
		console.log("====================================");
		console.log({ route });
		console.log("====================================");
		setSelectedRouteDetails(route);
		setShowDetailsModal(true);
	};

	const timeSlots = Array.from({ length: 96 }, (_, i) => {
		const totalMinutes = i * 15;
		const hours = Math.floor(totalMinutes / 60) + 7;
		const minutes = totalMinutes % 60;

		const adjustedHours = hours % 24;
		const displayHour = adjustedHours === 0 ? 12 : adjustedHours > 12 ? adjustedHours - 12 : adjustedHours;
		const period = adjustedHours >= 12 && adjustedHours < 24 ? "PM" : "AM";

		return `${displayHour}:${minutes.toString().padStart(2, "0")} ${period}`;
	});

	const handleNextClick = () => {
		navigate("/admin/generators/reports/service-reports");
	};

	const fetchAllRoutesOptions = async () => {
		try {
			const resp = await getAllRoutes(user?.uid);
			const options = [];
			let map = {};
			if (resp) {
				resp
					?.filter((route) => route.status == "ACTIVE")
					.map((item) => {
						options.push({
							label: item.routeLabel,
							value: item.id,
						});
						map[item.id] = item.routeLabel;
					});
				setRoutes(resp);
				setRouteOptions([{ label: "All", value: "all" }, ...options]);
				setSelectedRoutes(["all", ...options.map((item) => item.value)]);
			}
		} catch (error) {
			console.log("Error fetching routes", error);
		}
	};

	useEffect(() => {
		if (!user) return;
		let unsubscribeTreatments;
		try {
			unsubscribeTreatments = onSnapshot(
				query(collection(db, COLLECTIONS.treatments), where("transporterId", "==", user?.uid)),
				(snap) => {
					const tempTreatmentData = snap.docs
						.filter((doc) => doc.exists() && doc.data().treatmentCoordinates)
						.map((doc) => ({ ...doc.data(), id: doc.id }));
					setTreatmentFacilities(tempTreatmentData);
				}
			);
		} catch (error) {
			console.error("Error isLoading treatments:", error);
		}
		return () => {
			if (unsubscribeTreatments) unsubscribeTreatments();
		};
	}, [user]);

	useEffect(() => {
		if (!user) return;
		let unsubscribeSatellite;
		try {
			unsubscribeSatellite = onSnapshot(
				query(collection(db, COLLECTIONS.satelliteLocation), where("transporterId", "==", user?.uid)),
				(snap) => {
					const tempSatelliteData = snap.docs
						.filter((doc) => doc.exists() && doc.data().satelliteServiceCoordinates)
						.map((doc) => ({ ...doc.data(), id: doc.id }));
					setSatelliteLocations(tempSatelliteData);
				}
			);
		} catch (error) {
			console.error("Error isLoading satellite locations:", error);
		}
		return () => {
			if (unsubscribeSatellite) unsubscribeSatellite();
		};
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(doc(db, COLLECTIONS.transporters, user?.uid), (snap) => {
			if (snap?.exists()) {
				setTransporterData({ ...snap.data(), id: snap.id });
			}
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user, loading]);

	useEffect(() => {
		const options = [];

		if (
			transporterData &&
			transporterData?.transporterServiceCoordinates?.lat &&
			transporterData?.transporterServiceCoordinates?.lng
		) {
			options.push({ label: "Home Base", value: LOCATION_TYPE_HOME_BASE });
		}
		if (satelliteLocations.length > 0) {
			satelliteLocations.forEach((satellite) => options.push({ label: satellite.name, value: satellite.id }));
		}
		if (treatmentFacilities.length > 0) {
			treatmentFacilities.forEach((treatment) => options.push({ label: treatment.name, value: treatment.id }));
		}
		setLocations(options);
		setSelectedLocations(["all", ...options.map((el) => el.value)]);
	}, [satelliteLocations, treatmentFacilities, transporterData]);

	useEffect(() => {
		if (user) fetchAllRoutesOptions();
	}, [user]);

	useEffect(() => {
		if (!user) return;

		const newListeners = [];

		const setupRealTimeListeners = async () => {
			try {
				setLoading(true);

				const dailyRouteIds = [];
				const currentDate = new Date();
				const utcFromDate = new Date(
					Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0)
				);
				dailyRouteIds.push(utcFromDate.toISOString());

				if (dailyRouteIds.length === 0) {
					setRouteColumns([]);
					return;
				}

				const processRouteUpdate = (routeColumn) => {
					setRouteColumns((prev) => {
						const existingIndex = prev.findIndex((item) => item.id === routeColumn.id);
						const updatedColumns =
							existingIndex !== -1
								? [...prev.slice(0, existingIndex), routeColumn, ...prev.slice(existingIndex + 1)]
								: [...prev, routeColumn];

						return updatedColumns
							.filter((e) => selectedRoutes.includes(e.routeId))
							.sort((a, b) => a.routeLabel.localeCompare(b.routeLabel));
					});
				};

				for (const routeId of selectedRoutes) {
					if (routeId === "all") continue;

					const dailyRouteRef = doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, dailyRouteIds[0]);

					const unsubscribeDailyRoute = onSnapshot(dailyRouteRef, async (dailyRouteDoc) => {
						if (!dailyRouteDoc.exists()) return;

						const routeData = routes.find((r) => r.id === routeId);
						const dailyData = dailyRouteDoc.data();

						console.log({ assignedDriverFor: dailyData?.assignedDriverName });

						const processedWaypoints = await Promise.all(
							dailyData.waypoints.map(async (wp) => {
								if (wp.serviceId) {
									const serviceRef = doc(db, COLLECTIONS.scheduledServices, wp.serviceId);

									const serviceUnsub = onSnapshot(serviceRef, async (serviceDoc) => {
										if (serviceDoc.exists()) {
											const serviceData = { ...serviceDoc.data(), id: serviceDoc.id };

											if (serviceData.serviceScheduleId) {
												const scheduleRef = doc(db, COLLECTIONS.serviceSchedules, serviceData.serviceScheduleId);
												const scheduleRes = await getDoc(scheduleRef);
												wp.scheduleData = scheduleRes.exists() ? { ...scheduleRes.data(), id: scheduleRes.id } : null;
											}

											if (serviceData.manifests?.length > 0) {
												const manifestsData = await Promise.all(
													serviceData.manifests.map(async (manifestId) => {
														const manifestRef = doc(db, COLLECTIONS.manifests, manifestId);
														const manifestSnap = await getDoc(manifestRef);
														return manifestSnap.exists() ? { ...manifestSnap.data(), id: manifestSnap.id } : null;
													})
												);
												serviceData.manifestsData = manifestsData.filter((manifest) => manifest !== null);
											} else {
												serviceData.manifestsData = [];
											}

											wp.serviceData = serviceData;
											setRouteColumns((prev) => [...prev]);
										}
									});

									newListeners.push(serviceUnsub);
								}

								if (wp.id) {
									const generatorDoc = await getDoc(doc(db, COLLECTIONS.generators, wp.id));
									wp.generatorData = generatorDoc.exists() ? await processGeneratorData(generatorDoc) : null;
								}

								wp.randomCoordinates = randomizeCoordinates(wp.lat, wp.lng);
								return wp;
							})
						);

						console.log("====================================");
						console.log({ processedWaypoints });
						console.log("====================================");

						const routeColumn = {
							routeId,
							dailyRouteId: dailyRouteIds[0],
							...routeData,
							...dailyData,
							assignedDriverName: dailyData?.assignedDriverName || "N/A",
							assignedServiceVehicleName: dailyData?.assignedServiceVehicleName || "N/A",
							waypoints: processedWaypoints?.filter((waypoint) => waypoint.serviceData || waypoint.scheduleData),
							id: routeId + dailyRouteIds[0],
							isRouteReversed: dailyData?.isRouteReversed ?? false,
						};

						processRouteUpdate(routeColumn);
					});

					newListeners.push(unsubscribeDailyRoute);
				}

				setListeners((prev) => {
					prev.forEach((unsubscribe) => unsubscribe());
					return newListeners;
				});
			} catch (error) {
				console.error("Error setting up real-time listeners:", error);
			} finally {
				setLoading(false);
			}
		};

		setupRealTimeListeners();

		return () => {
			newListeners.forEach((unsubscribe) => unsubscribe());
		};
	}, [selectedRoutes, user, routes]);

	const handleCounts = (routeColumns) => {
		let allWaypoints = routeColumns.map((route) => route.waypoints).flat();
		let outOfNetworkServiceCount = routeColumns
			.filter((el) => el?.status == "OUT_OF_NETWORK_SERVICE")
			.map((el) => el.waypoints.length)
			.reduce((a, b) => a + b, 0);
		let totalServices = allWaypoints.length;
		let totalPendingServices = allWaypoints.filter(
			(el) => el?.serviceData?.status === scheduledServiceStatus.PENDING
		).length;
		let totalInprogressServices = allWaypoints.filter(
			(el) => el?.serviceData?.status === scheduledServiceStatus.INPROGRESS
		).length;
		let totalCompletedServices = allWaypoints.filter(
			(el) => el?.serviceData?.status === scheduledServiceStatus.COMPLETE
		).length;
		setTotalServices(totalServices);
		setTotalPendingServices(totalPendingServices);
		setTotalInprogressServices(totalInprogressServices);
		setTotalCompletedServices(totalCompletedServices);
		setOutOfNetworkServiceCount(outOfNetworkServiceCount);
	};

	useEffect(() => {
		let latestRouteColumns = routeColumns
			.map((item) => {
				let filteredWaypoints = item?.waypoints?.filter(
					(wp) =>
						wp?.generatorData?.generatorName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
						wp?.generatorData?.generatorPhone?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
						wp?.generatorData?.generatorEmail?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
						wp?.generatorData?.octoNumber?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
						wp?.generatorData?.internalAccountNumber?.toLowerCase()?.includes(searchQuery?.toLowerCase())
				);

				return filteredWaypoints.length > 0 ? { ...item, waypoints: filteredWaypoints } : null;
			})
			.filter(Boolean);
		setFilteredRouteColumns(latestRouteColumns);
		handleCounts(latestRouteColumns);
	}, [selectedRoutes, routeColumns, searchQuery]);

	console.log("====================================");
	console.log({ filteredRouteColumns });
	console.log("====================================");

	const handleSelectedOptionsChange = (selected, type) => {
		if (type == "routes") setSelectedRoutes(selected);
		if (type == "subcontractors") setSelectedSubContractors(selected);
	};

	const renderPin = (route, serviceType, serviceId, color, index) => {
		if (serviceType === SERVICE_TYPES.MEDICAL_WASTE) {
			return (
				<RoundedPin
					className="min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative object-cover"
					color={color ?? "#FFA500"}
					text={typeof index !== "undefined" ? index + 1 : ""}
				/>
			);
		}
		if (serviceType === SERVICE_TYPES.PAPER_SHREDDING) {
			return (
				<SquarePin
					className="min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative"
					color={color ?? "#FFA500"}
					text={typeof index !== "undefined" ? index + 1 : ""}
				/>
			);
		}
		if (serviceType === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING) {
			return (
				<SquarePin
					className="min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative"
					color={color ?? "#FFA500"}
					text={typeof index !== "undefined" ? index + 1 : ""}
				/>
			);
		}
		return (
			<RoundedPin
				className="w-20 h-20 flex items-center justify-center relative"
				color={color ?? "#FFA500"}
				text={typeof index !== "undefined" ? index + 1 : ""}
			/>
		);
	};

	const handleClickView = async (id) => {
		console.log({ id });

		let manifest = await getDoc(doc(db, COLLECTIONS.manifests, id));
		if (manifest.exists() && manifest?.data()?.url) {
			console.log({ data: manifest?.data() });

			window.open(manifest.data()?.url);
		} else {
			const fetchUrl = getManifestUrl(id);
			console.log("fetchUrl", fetchUrl);
			window.open(fetchUrl, "_blank");
		}
	};

	const zoomIn = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom + 1 });
		}
	};

	const zoomOut = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom - 1 });
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<div className="main-container m-6 flex flex-col border overflow-scroll border-cardBorderCol bg-white rounded-cardRadii">
				<div className="px-8 pt-8 w-full flex flex-col 2xl:flex-row gap-4">
					<FilterSection
						handleSelectedOptionsChange={handleSelectedOptionsChange}
						locations={locations}
						routeOptions={routeOptions}
						searchQuery={searchQuery}
						setSearchQuery={setSearchQuery}
						selectedLocations={selectedLocations}
						selectedRoutes={selectedRoutes}
						setSelectedLocations={setSelectedLocations}
						setSelectedRoutes={setSelectedRoutes}
					/>
					<Counters
						totalServices={totalServices}
						totalCompletedServices={totalCompletedServices}
						outOfNetworkServiceCount={outOfNetworkServiceCount}
					/>
				</div>

				<div className="flex justify-between mx-8 pb-2 border-b-2 border-l-borderCol">
					<div className="text-lg font-medium text-black">Live View</div>
				</div>

				<div className="flex flex-col justify-center items-center mt-12 pb-2 px-8">
					<TodaysReportsProgressBar
						inComplete={totalServices - totalCompletedServices}
						complete={totalCompletedServices}
						scheduled={totalServices}
					/>
				</div>

				<div className="w-full h-[500px] p-8 pt-12">
					<AzureMap options={{ authOptions: authOptions, view: "Auto" }}>
						{filteredRouteColumns.length > 0 &&
							filteredRouteColumns.map((route) => {
								if (route.waypoints.length > 0) {
									return (
										<>
											<CustomAzureRoute
												key={selectedRoutes.join(",")}
												route={route}
												setRouteColumns={setFilteredRouteColumns}
											/>
											{route.waypoints.map((waypoint, index) => {
												if (!waypoint?.lng || !waypoint?.lat) return null;
												return (
													<CustomAzureWayPointMarker
														draggable={true}
														key={waypoint.serviceId}
														id={waypoint.serviceId}
														position={{ lat: waypoint.lat, lng: waypoint.lng }}
														serviceId={waypoint.serviceId}
														genData={waypoint.generatorData}
														waypoint={waypoint}
														route={route}
														routes={routes}
														pin={renderPin(
															route,
															waypoint?.scheduleData?.serviceType,
															waypoint?.serviceId,
															route.color,
															index
														)}
														activeGenCard={activeGenCard}
													></CustomAzureWayPointMarker>
												);
											})}
										</>
									);
								}
							})}
						<div className="absolute bottom-5 right-5 flex flex-col gap-2 z-10">
							<button
								onClick={zoomIn}
								className="w-10 h-10 flex items-center justify-center 
                       bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
                        font-bold rounded shadow-md"
							>
								<FaPlus />
							</button>
							<button
								onClick={zoomOut}
								className="w-10 h-10 flex items-center justify-center 
                       bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
                       font-bold rounded shadow-md"
							>
								<FaMinus />
							</button>
						</div>
					</AzureMap>
				</div>

				<div className="flex flex-1 px-8 overflow-hidden">
					{/* Left Section */}
					<div className="w-80 overflow-y-auto flex-shrink-0">
						<div className="font-semibold h-10 max-h-10 text-lg">Live Routes</div>
						{filteredRouteColumns.map((item, index) => (
							<div key={index} className="h-32 max-h-32 border-b">
								<p className="text-base font-medium">
									Route Name: <span className="font-normal">{item.routeLabel}</span>
								</p>
								<p className="text-base font-medium">
									Driver Name:{" "}
									<span className="font-normal">
										{item.assignedDriverName == "N/A"
											? item.waypoints?.[0]?.serviceData?.assignedDriverName
											: item.assignedDriverName}
									</span>
								</p>
								<p className="text-base font-medium">
									Service Vehicle:{" "}
									<span className="font-normal">
										{item.assignedServiceVehicleName == "N/A"
											? item.waypoints?.[0]?.serviceData?.assignedServiceVehicleName
											: item.assignedServiceVehicleName}
									</span>
								</p>
								<button className="text-primary mt-2 hover:underline" onClick={() => handleViewDetails(item)}>
									View Details
								</button>
							</div>
						))}
					</div>
					<div className="flex-1 flex flex-col overflow-hidden">
						<div className="border-b h-10 max-h-10 bg-gray-50 relative">
							<button
								className="absolute left-0 top-1/2 -translate-y-1/2 py-2 px-1 z-10"
								onClick={() => scroll("left")}
							>
								<ChevronLeft size={24} />
							</button>
							<div ref={headerScrollRef} className="scroll-container flex overflow-x-auto scrollbar-hide mx-8">
								<div style={{ width: `${timeSlots.length * 90 - 40}px` }} className="flex">
									{timeSlots.map((slot, index) => (
										<div
											key={index}
											className="flex-shrink-0 h-10 flex justify-center items-center px-4 py-2 bg-gray-100 border-x text-sm"
										>
											<p>{slot}</p>
										</div>
									))}
								</div>
							</div>
							<button
								className="absolute right-0 top-1/2 -translate-y-1/2 py-2 px-1 z-10"
								onClick={() => scroll("right")}
							>
								<ChevronRight size={24} />
							</button>
						</div>
						<div
							ref={contentScrollRef}
							className="scroll-container flex-1 overflow-x-auto overflow-y-hidden scrollbar-hide w-full"
						>
							{filteredRouteColumns.map((item, index) => (
								<div
									key={index}
									className="h-32 max-h-32 border-b-[0.5px] relative scrollbar-hide"
									style={{ width: `${timeSlots.length * 90 - 40}px`, maxWidth: `${timeSlots.length * 90 - 40}px` }}
								>
									<div className="w-full h-full px-8 relative">
										<div
											style={{
												background: `linear-gradient(to right, ${item.color}22 0%, ${item.color}FF 100%)`,
											}}
											className="absolute top-1/2 left-0 mx-8 right-0 h-1 bg-gray-200 transform -translate-y-1/2"
										/>
										{item.waypoints?.map((stop, stopIndex) => (
											<StopComp
												key={stopIndex}
												stop={stop}
												index={stopIndex}
												positionOnTimeLine={getPositionOnTimeLine(item.waypoints, stop, stopIndex)}
												routeCol={item.color}
												startTime={item.serviceData?.startedAt ?? item.startedAt}
												completedTime={item.serviceData?.completedAt}
												allStops={item.waypoints}
												setActiveGenCard={() => setActiveGenCard(stop.serviceId)}
											/>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				{filteredRouteColumns.length == 0 && (
					<div className="w-full h-40 flex items-center justify-center text-cardTextGray text-lg">
						<h2>No Live Route Found</h2>
					</div>
				)}

				{/* Details Modal */}
				{showDetailsModal && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
						<div className="bg-white rounded-lg p-6 px-8 max-w-fit w-full max-h-[80vh] overflow-y-auto">
							<div className="flex justify-between items-start mb-4">
								<h3 className="text-xl font-semibold">{selectedRouteDetails.routeLabel}</h3>
								<button onClick={() => setShowDetailsModal(false)} className="text-gray-500 h-fit hover:text-gray-700">
									✕
								</button>
							</div>

							<div className="mb-4">
								<p>
									<strong>Driver Name:</strong>{" "}
									{selectedRouteDetails.assignedDriverName == "N/A"
										? selectedRouteDetails.waypoints?.[0]?.serviceData?.assignedDriverName
										: selectedRouteDetails.assignedDriverName}
								</p>
								<p>
									<strong>Service Vehicle:</strong>{" "}
									{selectedRouteDetails.assignedServiceVehicleName == "N/A"
										? selectedRouteDetails.waypoints?.[0]?.serviceData?.assignedServiceVehicleName
										: selectedRouteDetails.assignedServiceVehicleName}
								</p>

								<div className="mt-2">
									{selectedRouteDetails.waypoints?.length > 0 ? (
										<>
											<div className="flex gap-4 border-y py-1">
												<span className="capitalize font-semibold w-4"></span>
												<p className="w-52">
													<span className="capitalize font-semibold">Status </span>
												</p>
												<p className="w-52 font-semibold">Generator Name</p>
												<p className="w-80 font-semibold">Document</p>
											</div>
											{selectedRouteDetails.waypoints.map((stop, index) => (
												<div className="flex gap-4" key={index}>
													<span className="capitalize font-semibold w-4">{index + 1}. </span>
													<p className="w-52">
														<span className="capitalize">{statusMap[stop?.serviceData?.status] ?? "Pending"} </span>
														<span>
															{stop?.serviceData?.completedAt
																? `(${formatTime(stop?.serviceData?.completedAt?.toDate() ?? null) ?? ""})`
																: ""}
														</span>
													</p>
													<p className="w-52">{stop?.generatorData?.generatorName}</p>
													<div className="w-80">
														{stop?.serviceData?.manifestsData?.map((manifest) => (
															<button
																disabled={!manifest?.manifestOctoId}
																onClick={() => handleClickView(manifest?.id)}
																className="hover:text-primary cursor-pointer text-wrap text-center disabled:text-cardTextGray"
															>
																{manifest?.manifestOctoId}
															</button>
														))}
													</div>
												</div>
											))}
										</>
									) : (
										<p>No service on this route</p>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				{/* </div> */}

				{/* Buttons Section */}
				<div className="flex justify-end p-4 mb-12 mt-12">
					<Button btnStyle="form_nav_primary" text="Next" onClick={handleNextClick} />
				</div>
			</div>
		</>
	);
};

export default LiveView;

const MapPin = ({ number, variant = "square", size = "8", routeCol = "red-600" }) => {
	const borderStyle = {
		border: `4px solid ${routeCol}`,
	};

	console.log("====================================");
	console.log({ routeCol });
	console.log("====================================");

	const containerClasses = `
	  relative inline-flex items-center justify-center
	  bg-white
	  ${size === "8" ? (variant === "square" ? "w-8 h-8" : "w-9 h-9") : ""}
	  ${variant === "square" ? "rounded" : "rounded-full"}
	  z-10
	`;

	const shapeClasses = `
	  flex items-center justify-center  
	  font-semibold
	  ${size === "8" ? "text-md" : size === "12" ? "text-xl" : "text-2xl"}
	`;

	const pointerStyles = {
		position: "absolute",
		left: "50%",
		bottom: "-14px",
		transform: "translateX(-50%)",
		width: "0",
		height: "0",
		borderLeft: "10px solid transparent",
		borderRight: "10px solid transparent",
		borderTop: `14px solid ${routeCol}`,
	};

	return (
		<div style={borderStyle} className={containerClasses}>
			<div className={shapeClasses}>{number}</div>
			<div style={pointerStyles} />
		</div>
	);
};

const timeToMinutes = (timeStr) => {
	console.log({ timeStr });
	if (timeStr) {
		const [time, period] = timeStr.split(" ");
		let [hours, minutes] = time.split(":").map(Number);

		if (period === "PM" && hours !== 12) hours += 12;
		if (period === "AM" && hours === 12) hours = 0;

		return hours * 60 + minutes;
	}
};

const getPositionOnTimeLine = (allStops = [], stop, i) => {
	let timeOnLine = 0;
	console.log({ timeOnLine_Before: timeOnLine });
	if (typeof stop?.serviceData?.completedAt?.toDate() == "object") {
		let date = stop?.serviceData?.completedAt?.toDate();
		console.log({ date });

		let hour = date.getHours();
		let minute = date.getMinutes();
		let checkTime = hour * 60 + minute;
		if (checkTime < 430) checkTime += 1445;
		timeOnLine = checkTime - 430;
		console.log({ timeOnLine_After_Service_Completed: timeOnLine });
	} else {
		allStops.map((item, index) => {
			if (index <= i) {
				if (allStops.length == 1) timeOnLine = 430;
				if (typeof item?.serviceData?.completedAt?.toDate() == "object") {
					let date = item?.serviceData?.completedAt?.toDate();
					console.log({ date });

					let hour = date.getHours();
					let minute = date.getMinutes();
					let checkTime = hour * 60 + minute;
					if (checkTime < 430) checkTime += 1445;
					timeOnLine = checkTime - 430;
				} else if (typeof item.serviceData?.startedAt?.toDate() == "object") {
					let date = item.serviceData?.startedAt?.toDate();
					let hour = date.getHours();
					let minute = date.getMinutes();
					timeOnLine = hour * 60 + minute;
				} else {
					timeOnLine += Number(item?.scheduleData?.serviceDuration ?? 15);
				}
			}
		});
	}

	console.log({ timeOnLine_After: timeOnLine });

	return timeOnLine;
};

const getTimePosition = (stopTime, timeInMin = null) => {
	console.log({ stopTime });

	const startMinutes = 430;
	const endMinutes = 1870;
	const currentMinutes = timeInMin ? timeInMin : timeToMinutes(stopTime);

	const totalDuration = endMinutes - startMinutes;
	const currentDuration = currentMinutes;

	console.log({ currentDuration, totalDuration });

	return `${(currentDuration / totalDuration) * 100}%`;
};

const StopComp = ({
	stop,
	index,
	positionOnTimeLine,
	routeCol,
	startTime,
	completedTime,
	allStops,
	setActiveGenCard,
}) => {
	const positionOnLine = getTimePosition("", positionOnTimeLine + 15);

	console.log({
		positionOnLine,
		timeToAdd: positionOnTimeLine + 10,
		completedTime: stop?.serviceData?.completedAt,
		startedTime: startTime,
		stop,
	});

	return (
		<div
			key={index}
			className="absolute top-1/2 p-3 cursor-pointer"
			style={{
				left: positionOnLine,
				transform: "translate(-50%, -50%)",
			}}
			onClick={() => setActiveGenCard()}
		>
			{stop.serviceData?.status != "COMPLETE" ? (
				stop?.scheduleData?.serviceType == "MEDICAL_WASTE" ? (
					<MapPin number={index + 1} variant="circle" routeCol={routeCol} />
				) : (
					<MapPin number={index + 1} variant="square" routeCol={routeCol} />
				)
			) : (
				<div
					className={`h-8 w-8 flex items-center justify-center font-bold ${
						stop.serviceData?.status == "COMPLETE"
							? `bg-primaryGreen text-white ${stop?.scheduleData?.serviceType == "MEDICAL_WASTE" ? "" : ""}`
							: stop?.scheduleData?.serviceType == "MEDICAL_WASTE"
							? `border-4 ${stop.color} bg-white rounded`
							: `border-4 ${stop.color} bg-white rounded-full`
					} mr-${4 + (index % 2 == 0 ? index : index + 1)} text-black`}
				>
					<p>{index + 1}</p>
				</div>
			)}
		</div>
	);
};

const formatTime = (date) => {
	if (date) {
		let hours = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const period = hours >= 12 ? "PM" : "AM";

		if (hours > 12) hours -= 12;
		if (hours === 0) hours = 12;

		return `${hours}:${minutes} ${period}`;
	} else {
		return "";
	}
};

const Counters = ({ totalServices, totalCompletedServices, outOfNetworkServiceCount }) => {
	return (
		<div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-1 2xl:w-[20%] gap-x-4 2xl:flex-col gap-1 text-nowrap">
			<div className="flex items-center gap-2">
				<p>{totalServices}</p>
				<p>{"Total Scheduled Services"}</p>
			</div>
			<div className="flex items-center gap-2">
				<p>{totalCompletedServices}</p>
				<p>{"Completed Services"}</p>
			</div>
			<div className="flex items-center gap-2">
				<p>{totalServices - totalCompletedServices}</p>
				<p>{"Incomplete Services"}</p>
			</div>
			<div className="flex items-center gap-2">
				<p>{outOfNetworkServiceCount}</p>
				<p>{"Out Of Network Services"}</p>
			</div>
		</div>
	);
};

const FilterSection = ({
	routeOptions,
	selectedRoutes,
	handleSelectedOptionsChange,
	searchQuery,
	setSearchQuery,
	locations,
	setSelectedLocations,
	setSelectedRoutes,
}) => {
	const clearFilters = () => {
		setSelectedRoutes([...routeOptions.map((el) => el.value)]);
		setSelectedLocations([...locations.map((el) => el.value)]);
	};

	useEffect(() => {
		clearFilters();
	}, []);

	return (
		<div className="flex justify-between items-start gap-4 w-full">
			<div className="flex gap-8 items-center w-[70%]">
				<div className="flex items-center w-2/3 bg-white rounded-full h-fit p-3 px-4 border border-gray-200 max-w-[50%]">
					<FiSearch className="text-cardTextGray opacity-50" size={20} />
					<input
						type="text"
						placeholder="Search by Name, OCTO Number, Phone, Email, Address, Documents "
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						className="pl-2 bg-transparent focus:outline-none text-cardTextGray text-sm flex-1 w-full"
					/>
				</div>
				<div className="w-1/3">
					<MultiselectDropdown
						width="w-full"
						buttonText="Routes"
						options={routeOptions}
						selectedOptions={selectedRoutes}
						onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "routes")}
					/>
				</div>
			</div>
			<div className="w-60 text-nowrap px-4">
				<button className="text-primary hover:text-blue-900 py-2" onClick={clearFilters}>
					Clear Filters
				</button>
			</div>
		</div>
	);
};
