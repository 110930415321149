import { StrictMode } from "react";
import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./App.jsx";
import { createRoot } from "react-dom/client";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIProvider } from "@vis.gl/react-google-maps";
import store from "../src/store/store.js";
import { Provider } from "react-redux";
import { MapServicesProvider } from "./context/mapServices.jsx";
import { GeneratorUserProvider } from "./context/GeneratorUserContext.jsx";
import "azure-maps-control/dist/atlas.min.css";
import { AzureMapsProvider } from "react-azure-maps";
import GradientDefinition from "./GradientDefinition.jsx";

createRoot(document.getElementById("root")).render(
	<>
		<Provider store={store}>
			<GeneratorUserProvider>
				<APIProvider apiKey={"AIzaSyBI-JdFKn09RN0JJujT_4x043m2ndrNATw"} libraries={[]}>
					<MapServicesProvider>
						<GradientDefinition />
						<RouterProvider router={AppRouter} />
						<ToastContainer stacked={true} autoClose={2000} draggable className={"text-sm"} pauseOnHover />
					</MapServicesProvider>
				</APIProvider>
			</GeneratorUserProvider>
		</Provider>
	</>
);
