import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllVendor, deleteVendor } from "../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { motion, AnimatePresence } from "framer-motion";
import MiniVendorRegModal from "../../../../../components/userAccBtn/MiniVendorRegModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../../config/firebase";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { createPortal } from "react-dom";
import useTUserContext from "../../../../../context/TransporterUserContext";

const VendorFacilities = () => {
	const [data, setData] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedStatuses, setSelectedStatuses] = useState(["all", "Active", "Inactive"]);
	const [selectedVendors, setSelectedVendors] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [anyChecked, setAnyChecked] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipTimer, setTooltipTimer] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTooltipHovered, setIsTooltipHovered] = useState(false);
	const [isTooltipActive, setIsTooltipActive] = useState(false);
	const { user, loading } = useTUserContext();
	const [editButton, setEditButton] = useState(false);
	const navigate = useNavigate();

	const formatPhoneNumber = (value) => {
		if (!value || value == "--") return "--";

		const phoneNumber = value.replace(/\D/g, "");
		const match = phoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
		if (match) {
			return `(${match[1]}) ${match[2]}-${match[3]}`;
		}
		return value;
	};

	useEffect(() => {
		const fetchVendors = async () => {
			try {
				const vendors = await getAllVendor(user?.uid);
				setData([...vendors]);
			} catch (err) {
				console.error("Error fetching vendor data:", err);
				setError("Failed to load vendor data.");
			} finally {
				setLoading(false);
			}
		};
		if (user) fetchVendors();
	}, [user]);

	const handleRowClick = (id) => {
		// navigate(`/admin/assets/vendors/add-vendors/${id}/edit`);
	};

	const handleDelete = async (id) => {
		const loadingToast = toast.loading("Deleting...");
		try {
			await deleteVendor(id);
			toast.update(loadingToast, {
				render: "Vendor deleted successfully!",
				type: "success",
				isLoading: false,
				autoClose: 3000,
			});
			const vendors = await getAllVendor(user?.uid);
			setData(vendors);
		} catch (error) {
			toast.update(loadingToast, {
				render: "Error deleting vendor!",
				type: "error",
				isLoading: false,
				autoClose: 3000,
			});
			console.error("Error deleting vendor: ", error);
		}
	};
	const statusFilterChangeHandler = (selected) => {
		if (selected.includes(["all", "ACTIVE", "INACTIVE"])) {
			setSelectedStatuses(["all", "ACTIVE", "INACTIVE"]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};

	useEffect(() => {
		console.log({ selectedStatuses });
	}, [selectedStatuses]);

	const filteredData = useMemo(() => {
		return data.filter((item) => {
			if (
				selectedStatuses.includes("all") ||
				selectedStatuses.includes("ACTIVE") ||
				selectedStatuses.includes("INACTIVE")
			) {
				return true;
			}
			return selectedStatuses.includes(item.vendorStatus);
		});
	}, [data, selectedStatuses]);

	const handleCheckboxChange = (index) => {
		const newSelected = selectedVendors.includes(index)
			? selectedVendors.filter((i) => i !== index)
			: [...selectedVendors, index];

		setSelectedVendors(newSelected);
		setSelectAll(newSelected.length === filteredData.length);
		setIndeterminate(newSelected.length > 0 && newSelected.length < filteredData.length);
		setAnyChecked(newSelected.length > 0);
		setEditButton(newSelected.length !== 1); // Disable edit button if more than 1 or none are selected
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedVendors([]);
			setAnyChecked(false);
			setEditButton(true);
		} else {
			setSelectedVendors(filteredData.map((_, index) => index));
			setAnyChecked(true);
			setEditButton(filteredData.length !== 1); // Disable if multiple are selected
		}
		setSelectAll(!selectAll);
		setIndeterminate(false);
	};

	const clickHandler = (action) => {
		if (action === "Delete") {
			selectedVendors.forEach((index) => {
				handleDelete(filteredData[index].id);
			});
			setSelectedVendors([]);
			setAnyChecked(false);
		} else if (action === "Edit") {
			selectedVendors.forEach((index) => {
				handleRowClick(filteredData[index].id);
			});
			if (selectedVendors.length > 1) {
				setEditButton(true);
			}
		}
	};

	const handleMouseEnter = (item, event) => {
		clearTimeout(tooltipTimer);
		const timer = setTimeout(() => {
			setActiveTooltip(item.id);
			setModalData(item);
			updateTooltipPosition(event);
			setIsTooltipActive(true);
		}, 3000);
		setTooltipTimer(timer);
	};

	const handleMouseLeave = () => {
		clearTimeout(tooltipTimer);
		if (!isTooltipHovered) {
			setActiveTooltip(null);
			setIsTooltipActive(false);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 500;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
		} else {
			left = cursorX + offset;
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const openAddVendorModal = () => {
		setIsModalOpen(true);
	};

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full ">
				<div className="flex justify-between items-center mx-8">
					<div className="text-lg font-medium text-black">Vendors List</div>
					<div className="my-4 px-2">
						<MultiselectDropdown
							buttonText="Vendor Status"
							options={[
								{ label: "All", value: "all" },
								{ label: "Active", value: "Active" },
								{ label: "Inactive", value: "Inactive" },
							]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>
				<div className="overflow-scroll ">
					<div className="sticky top-0 flex font-bold">
						<div className="flex font-medium py-4 min-w-fit bg-whiteBlue ">
							<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
								<input
									type="checkbox"
									className="w-4 h-4 bg-white"
									checked={selectAll}
									ref={(input) => input && (input.indeterminate = indeterminate)}
									onChange={handleSelectAllChange}
								/>
							</div>
							<div className="flex items-center text-sm font-semibold">
								<div className="truncate min-w-60">Name</div>
								<div className="truncate min-w-60">Address</div>
								<div className="truncate min-w-60">Email</div>
								<div className="truncate min-w-48">Phone</div>
								<div className="truncate min-w-48">Vendor Type</div>
								<div className="truncate min-w-48">Status</div>
							</div>
						</div>
					</div>

					<div className="max-h-[75vh] min-w-fit">
						{isLoading ? (
							Array.from({ length: 9 }).map((_, index) => (
								<div
									key={index}
									className="flex flex-col md:flex-row items-center border-b border-gray-300 animate-pulse"
									style={{ minHeight: "45px" }}
								>
									<div className="w-20"></div>
									<div className="min-w-60 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-60 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-60 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-48 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-48 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
									<div className="min-w-48 text-sm font-normal">
										<div className="h-4 bg-gray-300 rounded w-3/4"></div>
									</div>
								</div>
							))
						) : (
							<div className="min-w-fit pb-2 h-[68vh]">
								{filteredData.length > 0 ? (
									filteredData.map((item, index) => (
										<Link
											to={`../vendors/add-vendors/${item.id}/edit`}
											id={item.id}
											key={item.id}
											className="flex items-center transition-colors duration-300 border-b min-w-fit border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20 hover:cursor-pointer text-sm"
											// onMouseEnter={(e) => handleMouseEnter(item, e)}
											// onMouseLeave={handleMouseLeave}
											style={{ minHeight: "45px" }}
										>
											<div className="mx-8 flex justify-center items-center mt-1">
												<input
													type="checkbox"
													className="w-4 h-4 bg-white"
													checked={selectedVendors.includes(index)}
													onChange={(e) => {
														handleCheckboxChange(index);
													}}
													onClick={(e) => e.stopPropagation()}
												/>
											</div>
											<div className="truncate min-w-60 h-full cursor-pointer" onClick={() => handleRowClick(item.id)}>
												{item.name || "Name not provided"}
											</div>
											<div className="truncate min-w-60 h-full cursor-pointer" onClick={() => handleRowClick(item.id)}>
												{item.street || "Address not provided"}
											</div>
											<div className="truncate min-w-60 h-full cursor-pointer" onClick={() => handleRowClick(item.id)}>
												{item.email || " Email not provided"}
											</div>
											<div
												className="truncate min-w-48 h-full cursor-pointer hover:text-blueText hover:underline"
												onClick={() => handleRowClick(item.id)}
											>
												{formatPhoneNumber(item.phoneNumber) || "Phone not provided"}
											</div>
											<div className="truncate min-w-48 h-full cursor-pointer" onClick={() => handleRowClick(item.id)}>
												{
													Array.isArray(item.vendorType)
														? item.vendorType.map((type, index) => (
																<div key={index}>{type}</div> // or use <span> for inline
														  ))
														: "--" // fallback if vendorType isn't an array
												}
											</div>

											<div className="truncate min-w-48 h-full cursor-pointer" onClick={() => handleRowClick(item.id)}>
												{item.vendorStatus || "Active"}
											</div>
										</Link>
									))
								) : (
									<div className="min-w-fit pb-2 h-[68vh] flex justify-center items-center">
										<div className="w-full text-center py-4 text-cardTextGray">No Vendors Found</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<FooterActionItems vendorsSelected={selectedVendors} vendors={filteredData} />

				{/* <div
					className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-8 transition-all duration-300 ease-in-out ${selectedVendors.length > 0 ? "translate-y-0" : "translate-y-full"
						}`}
				>
					<div className="w-full py-4 flex justify-between items-center">
						<span className="truncate">{selectedVendors.length} item(s) selected</span>
						<div className="space-x-2">
						<button
							className={"px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out disabled:bg-gray-300"}
							onClick={() => handleExport()}
						>
							Export
						</button>
						<ExportModal
				isOpen={isModalOpen}
				isExporting={isExporting}
				progress={progress}
				onClose={() => setIsModalOpen(false)}
			/>
						</div>
					</div>
				</div> */}

				{!anyChecked && (
					<div className="fixed bottom-8 right-8">
						<button
							onClick={openAddVendorModal}
							className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
							</svg>
							<span>Add New Vendor</span>
						</button>
					</div>
				)}
				{isModalOpen && <MiniVendorRegModal onClose={() => setIsModalOpen(false)} activeSubmenu="vendor" />}
			</div>

			<AnimatePresence>
				{activeTooltip && modalData && isTooltipActive && (
					<motion.div
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
						}}
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setIsTooltipHovered(true)}
						onMouseLeave={() => setIsTooltipHovered(false)}
					>
						<ToolTipContent vendor={modalData} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const ExportModal = ({ isOpen, isExporting, progress, onClose }) => {
	if (!isOpen) return null;

	return createPortal(
		<div className="fixed inset-0 z-[9999] flex items-center justify-center">
			<div className="modal-overlay absolute inset-0 bg-black bg-opacity-50" />
			<div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
				<div className="p-8">
					{/* Header */}
					<div className="mb-6">
						<h3 className="text-2xl font-semibold text-gray-900">Exporting Data</h3>
						<p className="mt-2 text-sm text-gray-600">Please wait while we prepare your file...</p>
					</div>

					{/* Progress Section */}
					<div className="space-y-6">
						{/* Progress Bar */}
						<div className="w-full bg-gray-100 rounded-full h-3">
							<div
								className="bg-lightBlue h-3 rounded-full transition-all duration-300 ease-out"
								style={{ width: `${progress}%` }}
							/>
						</div>

						{/* Progress Details */}
						<div className="flex justify-between items-center">
							<p className="text-sm text-gray-600">{progress < 100 ? "Processing..." : "Download Complete!"}</p>
							<span className="text-sm font-medium text-gray-900">{Math.round(progress)}%</span>
						</div>

						{/* Status Message */}
						<div className="text-center">
							{progress === 100 ? (
								<p className="text-green-600 font-medium">
									Your download will begin automatically. This window will close shortly.
								</p>
							) : (
								<p className="text-gray-600">Please don't close this window while exporting...</p>
							)}
						</div>
					</div>

					{/* Close Button - Only shown when not exporting */}
					{!isExporting && (
						<button
							onClick={onClose}
							className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 
					focus:outline-none focus:text-gray-500 transition-colors duration-200"
							aria-label="Close modal"
						>
							<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>,
		document.body
	);
};

const FooterActionItems = ({ vendorsSelected, vendors }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleExport = async () => {
		setIsExporting(true);
		setIsModalOpen(true);
		setProgress(0);

		try {
			// Step 1: Data preparation (20% of progress)
			console.log("Log", vendors);

			const data = vendors.filter((_, index) => vendorsSelected.includes(index));

			const worksheetData = data.map((item, index) => {
				setProgress((index / data.length) * 20);

				return {
					Name: item.name,
					Address: item.street,
					Email: item.email,
					Phone: item.phoneNumber,
					"Vendor Type": item.vendorType.join(", "),
					Status: item.vendorStatus,
				};
			});
			setProgress(20);

			// Step 2: Create worksheet (40% of progress)
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.json_to_sheet(worksheetData);
			XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");
			setProgress(40);

			// Step 3: Convert to binary (70% of progress)
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			setProgress(70);

			// Step 4: Create blob and prepare for download (90% of progress)
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			setProgress(90);

			// Step 5: Trigger download (100% of progress)
			saveAs(blob, "exported_data.xlsx");
			setProgress(100);

			// Reset after showing 100% completion
			setTimeout(() => {
				setIsExporting(false);
				setProgress(0);
				setIsModalOpen(false);
			}, 1000);
		} catch (error) {
			console.error("Export failed:", error);
			setIsExporting(false);
			setProgress(0);
			setIsModalOpen(false);
		}
	};

	return (
		<div
			className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md bg-white px-8 transition-all duration-300 ease-in-out ${
				vendorsSelected.length > 0 ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{vendorsSelected.length} item(s) selected</span>
				<div className="space-x-2">
					<button
						className={
							"px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out disabled:bg-gray-300"
						}
						onClick={() => handleExport()}
					>
						Export
					</button>
					<ExportModal
						isOpen={isModalOpen}
						isExporting={isExporting}
						progress={progress}
						onClose={() => setIsModalOpen(false)}
					/>
				</div>
			</div>
		</div>
	);
};

const ToolTipContent = ({ vendor }) => {
	const details = [
		{ label: "Name", value: vendor.name || "--" },
		{ label: "Phone", value: vendor.phoneNumber || "--" },
		{ label: "Email", value: vendor.email || "--" },
		// { label: "Apartment", value: vendor.apartment || "--" },
		{ label: "State", value: vendor.state || "--" },
		{ label: "Address", value: vendor.street || "--" },
		// { label: "ZipCode", value: vendor.zipCode || "--" },
		{ label: "Type", value: vendor.vendorType?.join(", ") || "--" },
		{ label: "Vendor Status", value: vendor.vendorStatus || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className={`w-full flex`}>
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

export default VendorFacilities;
