import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import GeneratorSidebar from "../../components/sidebars/admin-sidebar/GeneratorSidebar";
import GeneratorHeader from "../../components/header/GeneratorHeader";
import GeneratorDashboard from "./dashboard/GeneratorDashboard";
import { GeneratorDataProvider } from "./dashboard/components/GeneratorDataContext";
import Documents from "./documents/Documents";
import GeneratorServiceReporting from "./generatorServiceReporting/GeneratorServiceReporting";
import Billing from "./billing/Billing";
import GroupAdministrator from "./groupAdministrator/GroupAdministrator";
import Communication from "./communication/Communication";
import { GeneratorBreadcrumbProvider } from "../../context/GeneratorBreadcrumbContext";
import { GeneratorUserProvider, useGeneratorUser } from "../../context/GeneratorUserContext";
import GeneratorInvoiceAndHistory from "./generatorInvoiceAndHistory/GeneratorInvoiceAndHistory";
import GeneratorSavedPaymentMethods from "./generatorSavedPaymentMethods/GeneratorSavedPaymentMethods";
import GeneratorMakePayment from "./generatorMakePayment/GeneratorMakePayment";
import ServiceCalender from "./dashboard/components/ServiceCalender/ServiceCalender";
import { Password } from "@mui/icons-material";
import { toast } from "react-toastify";
import { collection, doc, updateDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../config/firebase";
import UserAccBtn from "../../components/userAccBtn/UserAccBtn";
import GeneratorUserAccBtn from "./GenUserAccountButton/GeneratorUserAccountButton";
const GradientDefinition = () => (
	<svg width="0" height="0" style={{ position: "absolute" }}>
		<defs>
			<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stopColor="#007AFF" />
				<stop offset="100%" stopColor="#4CD964" />
			</linearGradient>
		</defs>
	</svg>
);
const GeneratorLayout = () => {
	const { user, authLoading, generatorData, allServices, setUser } = useGeneratorUser();

	const [active, setActive] = useState(null);
	const [hasNewPassword, setHasNewPassword] = useState(false);
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const scrollContainerRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		const pathMap = {
			"/generator-dashboard": "generator-dashboard",
			"generator-dashboard/documents": "documents",
			"/generator-dashboard/service-reports": "service-reports",
			"/generator-dashboard/invoices-and-payments": "invoices",
			"/generator-dashboard/saved-payment-methods": "saved-payment-methods",
			"/generator-dashboard/make-payment": "make-payment",
			"/generator-dashboard/service-calendar": "service-calendar",
		};
		console.log(location.pathname);
		setActive(pathMap[location.pathname] || "generator-dashboard");
	}, [location]);

	useEffect(() => {
		if (!authLoading && user) {
			console.log({ userFromLayout: user });
			if (!user?.hasSetNewPassword) setHasNewPassword(true);
		}
	}, [user, authLoading]);

	console.log({ generatorData, user });

	const setNewPasswordHandler = async () => {
		if (oldPassword !== user?.password) {
			toast.error("Old password is incorrect!");
			return;
		}
		if (!newPassword || !confirmNewPassword) {
			toast.error("Please fill all the fields!");
			return;
		}
		if (newPassword?.trim() !== confirmNewPassword?.trim()) {
			toast.error("Passwords don't match!");
			return;
		}

		let docRef = doc(db, COLLECTIONS.generatorContacts, user.contactId);
		try {
			await updateDoc(docRef, { password: newPassword, hasSetNewPassword: true });
			localStorage.setItem("GENERATOR_USER", JSON.stringify({ ...user, hasSetNewPassword: true }));
			setUser({ ...user, hasSetNewPassword: true, password: newPassword });
			setHasNewPassword(false);
		} catch (error) {
			console.log("Something went wrong", error);
			toast.error("Couldn't update password, please try after some times.");
		}
	};

	return (
		<GeneratorDataProvider>
			<GeneratorBreadcrumbProvider>
				<div className="flex h-screen">
					<GradientDefinition />
					<GeneratorSidebar active={active} />

					{hasNewPassword ? (
						<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
							<div className="bg-white z-20 grid gap-2 rounded-cardRadii max-w-md p-6 w-full min-h-fit">
								<h2 className="font-semibold text-xl">Set up new password</h2>
								<div className="mb-2">
									<label className="block pb-1 text-gray-700">Old Password</label>
									<input
										type="password"
										value={oldPassword}
										onChange={(e) => setOldPassword(e.target.value)}
										className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
										placeholder="Old Password"
									/>
								</div>
								<div className="mb-2">
									<label className="block pb-1 text-gray-700">New Password</label>
									<input
										type="password"
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
										placeholder="New Password"
									/>
								</div>
								<div className="mb-2">
									<label className="block pb-1 text-gray-700">Re-type New Password</label>
									<input
										type="password"
										value={confirmNewPassword}
										onChange={(e) => setConfirmNewPassword(e.target.value)}
										className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
										placeholder="Re-type New Password"
									/>
								</div>
								<button
									onClick={setNewPasswordHandler}
									className="w-full bg-dashBtnGradient text-white py-[9px] rounded transition duration-200"
								>
									Confirm
								</button>
							</div>
						</div>
					) : (
						<div className="flex-1 h-screen ml-0 md:ml-72 bg-primaryGray max-w-[calc(100vw-18rem)]">
							<GeneratorHeader />
							<div ref={scrollContainerRef} className="h-[calc(100%-80px)] overflow-auto">
								{active === "generator-dashboard" && <GeneratorDashboard />}
								{active === "documents" && <Documents />}
								{active === "service-reports" && <GeneratorServiceReporting />}
								{active === "invoices" && <GeneratorInvoiceAndHistory />}
								{active === "saved-payment-methods" && <GeneratorSavedPaymentMethods />}
								{active === "make-payment" && <GeneratorMakePayment />}
								{active === "service-calendar" && <ServiceCalender />}
							</div>
						</div>
					)}
				</div>
			</GeneratorBreadcrumbProvider>
		</GeneratorDataProvider>
	);
};

export default GeneratorLayout;
