import { useEffect, useRef, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import ReportCheckbox from "./ReportCheckBox";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../components/UI/loaders/Loader";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../../../../../config/firebase";
import {
	itemsMap,
	SERVICE_STATUS,
	serviceTypes,
	serviceTypesMap,
	transferStatusMap,
} from "../../../../../utils/constants";
import DropdownReport from "./ReportDropDown";
import {
	dateFormatter,
	formattedDate,
	formattedDateTime,
	octoFormatter,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../../../utils/helpers";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { FiSearch } from "react-icons/fi";
import { getAllDrivers, getAllRoutes, getAllTreatmentOptions } from "../../../../../utils/firebaseOperations";
import { useAuthState } from "react-firebase-hooks/auth";
import Button from "../../../../../components/UI/Button";
import useTUserContext from "../../../../../context/TransporterUserContext";
import { FooterActionItems } from "./ServiceReporting";
import { toast } from "react-toastify";

const ServiceCard = ({
	service,
	index,
	selectedServices,
	handleCheckboxChange,
	handleFinalTreatmentChange,
	transporterData,
	user,
	treatFaciOptions,
	handleUpdateTransferTo,
	treatmentMap,
}) => {
	const [isEditing, setIsEditing] = useState(null);
	const [isUpdating, setIsUpdating] = useState(null);
	const [selectedTreatmentId, setSelectedTreatmentId] = useState("");
	const [transferUpdateReason, setTransferUpdateReason] = useState("");

	useEffect(() => {
		const facilityEntry = Object.entries(treatmentMap).find(([id, name]) => name === service.transferredTo);
		setSelectedTreatmentId(facilityEntry?.[0] || "");
	}, [service.transferredTo, treatmentMap]);

	return (
		<>
			<div className={`py-2 font-normal pb-8 border-b ${selectedServices.includes(index) ? "bg-bgDefaultColor" : ""}`}>
				<div className="flex items-center pt-2 pb-6">
					<div className="min-w-8 mx-8 flex justify-center hover:cursor-pointer">
						<input
							type="checkbox"
							className="w-4 h-4 bg-white"
							checked={selectedServices.includes(service.id)}
							onChange={(e) => handleCheckboxChange(e, service.id)}
						/>
					</div>
					<div className="grid gap-2">
						<div className="flex items-center">
							<div className="text-wrap w-36">
								{service.transferDate ? formattedDateTime(service.transferDate) : "--"}
							</div>
							<div className="truncate w-52 text-thatblue">{service.transferId ?? "--"}</div>
							<div className="truncate w-48">{serviceTypesMap[service?.serviceType]}</div>
							<div className="truncate w-40">{service?.routeName}</div>
							<div className="truncate w-40">{service.pickUpTruck}</div>
							<div className="truncate w-52">{service.driverName}</div>
							<div className="truncate w-60">{transporterData?.transporterName ?? "--"}</div>
							<div className="truncate w-60">{transporterData?.transporterOctoId ?? "--"}</div>
							<div className="truncate w-80">
								{!isEditing ? (
									<div className="flex justify-between items-center pr-6 gap-2">
										<span className="truncate">{service?.transferredTo ?? "--"}</span>
										<button
											onClick={() => {
												// Find current treatment facility ID based on displayed name
												const treatment = treatFaciOptions.find((opt) => opt.label === service.transferredTo);
												setSelectedTreatmentId(treatment?.value || "");
												setIsEditing(service.id);
											}}
											className="text-primary"
										>
											Edit
										</button>
									</div>
								) : (
									<div className="flex gap-2 pr-6 items-center">
										<select
											value={selectedTreatmentId}
											onChange={(e) => setSelectedTreatmentId(e.target.value)}
											className="border rounded p-1 truncate flex-1"
										>
											{treatFaciOptions
												.filter((opt) => opt.value !== "all")
												.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
										</select>
										<button
											onClick={() => {
												// handleUpdateTransferTo(service.id, selectedTreatmentId);
												setIsUpdating(service.id);
											}}
											className="text-primary"
										>
											Save
										</button>
										<button
											onClick={() => {
												setIsEditing(null);
												setSelectedTreatmentId("");
											}}
											className="text-cardTextGray"
										>
											Cancel
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full overflow-hidden gap-6 border-dashInActiveBtnText px-6 pl-10">
					<div className="w-[45%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
						<div className="bg-[#DADADA] h-10 py-2 items-center justify-center">
							<h6 className="font-medium text-center">Transfer Summary</h6>
						</div>
						<div className="pb-2">
							<div className="grid grid-cols-4 text-center py-2 font-medium">
								<p>Manifest</p>
								<p>Sub Waste</p>
								<p>Container</p>
								<p>Weight</p>
							</div>
							{Array.isArray(service?.transferSummary) ? (
								service.transferSummary?.map((summary, index) => (
									<div key={index} className="grid grid-cols-4 text-center">
										<p>{summary?.manifestCount}</p>
										<p>{summary?.subWasteType}</p>
										<p>{summary?.containerCount}</p>
										<p>{summary?.totalWeight}</p>
									</div>
								))
							) : (
								<div className="w-full text-center py-2 text-secondary">
									<p>No data found</p>
								</div>
							)}
						</div>
					</div>
					<div className="w-[30%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
						<div className="bg-[#DADADA] h-10 py-2 grid items-center justify-center">
							<h6 className="font-medium text-center">Manifests</h6>
						</div>
						<div>
							<div className="grid grid-cols-1 text-center py-2 gap-1">
								<button
									className="hover:text-primary"
									onClick={() => {
										toast.info("Under development");
										console.log({
											allManifestIds: service?.allManifestIds,
											transferId: service.id,
											transporterId: transporterData.id,
										});
									}}
								>
									View All Associated Manifests
								</button>
								<button
									className="hover:text-primary"
									onClick={() => {
										toast.info("Under development");
										console.log({
											allManifestIds: service?.allManifestIds,
											transferId: service.id,
											transporterId: transporterData.id,
										});
									}}
								>
									View Consolidated Manifests
								</button>
							</div>
						</div>
					</div>
					<div className="flex flex-col overflow-hidden justify-between w-[25%] border border-dashInActiveBtnText rounded-lg">
						<div className="bg-[#DADADA] h-10 py-2 grid items-center justify-center">
							<h6 className="font-medium text-center">Options</h6>
						</div>
						<div className="flex flex-col justify-between">
							<div className="grid grid-cols-3 text-center py-2 font-medium">
								<p className="text-nowrap">Final Treatment</p>
								<div className="flex justify-center items-center">
									<input
										type="checkbox"
										className="w-4 h-4 bg-white"
										checked={service?.finalTreatment || false}
										onChange={() => handleFinalTreatmentChange(service.id)}
									/>
								</div>
								<p>
									{service?.finalTreatmentLogDate ? new Date(service.finalTreatmentLogDate)?.toLocaleDateString() : ""}
								</p>
							</div>
						</div>
						<div className="w-full flex py-1 h-10 items-center justify-center border-t border-[#DADADA] bg-primaryGreen">
							<p>{service?.finalTreatment ? "Logged Final Treatment" : "Log Final Treatment"}</p>
						</div>
					</div>
				</div>
			</div>
			{isUpdating && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
					<div className="modal-box">
						<div>
							<button
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
								type="button"
								onClick={() => {
									setSelectedTreatmentId("");
									setIsEditing(null);
									setIsUpdating(null);
								}}
							>
								✕
							</button>
						</div>
						<h3 className="font-bold text-lg">Are you sure?</h3>
						<div className="flex py-5 gap-5 flex-col">
							<p className="">
								Proceeding with this operation will change the treatment facility centre where the waste has been
								transferred.
							</p>
						</div>
						<div className={`py-5 block`}>
							<label htmlFor="cancellationNote">
								<p>Enter Note for Treatment facility Update *</p>
								<textarea
									rows={3}
									name="cancellationNote"
									value={transferUpdateReason}
									onChange={(e) => setTransferUpdateReason(e.target.value)}
									className={` w-full text-cardTextGray bg-inputBg border-none rounded-[20px] py-2 h-28 px-2 leading-tight focus:outline-none  focus:ring-1 focus:ring-dashInActiveBtnText`}
								/>
							</label>
						</div>
						<div className="flex w-full justify-between">
							<button
								className={`btn btn-error btn-sm`}
								type="button"
								onClick={() => {
									if (!transferUpdateReason?.trim()?.length) {
										toast.error("Please enter a valid reason for this update");
										return;
									}
									handleUpdateTransferTo(isEditing, selectedTreatmentId, transferUpdateReason);
									setIsUpdating(null);
									setIsEditing(null);
									setSelectedTreatmentId("");
								}}
							>
								{"Update The Status"}
							</button>
							<button
								type="button"
								className={`btn ${"btn-primary"} btn-sm`}
								onClick={() => {
									setSelectedTreatmentId("");
									setIsEditing(null);
									setIsUpdating(null);
								}}
							>
								{"Keep Current Status"}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const TransferReports = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [selectAll, setSelectAll] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [from, setFrom] = useState(new Date());
	const [to, setTo] = useState(new Date());
	const [selectedServices, setSelectedServices] = useState([]);
	const [pickupOptions, setPickupOptions] = useState([]);
	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [driverOptions, setDriverOptions] = useState([]);
	const [treatFaciOptions, setTreatFaciOptions] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedTreatFacis, setSelectedTreatFacis] = useState([]);
	const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
	const [completeServices, setCompleteServices] = useState([]);
	const [selectedDriverOptions, setSelectedDriverOptions] = useState([]);
	const [transStatusOptions, setTransStatusOptions] = useState([]);
	const [transferData, setTransferData] = useState([]);
	const [totalWeight, setTotalWeight] = useState([]);
	const [totalManifest, setTotalManifest] = useState([]);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [treatmentMap, setTreatmentMap] = useState({});
	const [isFiltering, startTransition] = useTransition();
	const [genIds, setGenIds] = useState([]);
	const [containersMap, setContainersMap] = useState({});
	const [genIdsLoading, setIsGenIdsLoading] = useState(true);
	const [transporterData, setTransporterData] = useState(null);
	const [indeterminate, setIndeterminate] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const selectAllRef = useRef();
	const { user, loading } = useTUserContext();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchTransporterData = async () => {
			if (!user?.uid) return;
			let docSnapshot = await getDoc(doc(db, COLLECTIONS.transporters, user.uid));
			if (docSnapshot.exists()) {
				let data = { ...docSnapshot.data(), id: docSnapshot.id };
				setTransporterData(data);
			}
		};

		fetchTransporterData();
	}, [user]);

	useEffect(() => {
		const currentSelected = selectedRows.length;
		const total = filteredData.length;
		const allSelected = currentSelected === total && total > 0;
		const isIndeterminate = currentSelected > 0 && currentSelected < total;

		setSelectAll(allSelected);
		setIndeterminate(isIndeterminate);

		if (selectAllRef.current) {
			selectAllRef.current.indeterminate = isIndeterminate;
		}
	}, [selectedRows, filteredData]);

	useEffect(() => {
		const fetchContainers = async () => {
			try {
				const containersRef = collection(db, "defaultPriceBook", "services", "containers");
				const snapshot = await getDocs(containersRef);

				const containersData = {};
				snapshot.forEach((doc) => {
					containersData[doc.id] = doc.data().labelOCTOFieldApp;
				});

				setContainersMap(containersData);
			} catch (error) {
				console.error("Error fetching containers:", error);
			}
		};

		fetchContainers();
	}, []);

	const fetchDocuments = async () => {
		if (!user) return;

		try {
			const q = query(collection(db, COLLECTIONS.generators), where("transporterId", "==", user?.uid));
			const querySnapshot = await getDocs(q);

			const ids = querySnapshot.docs.map((doc) => doc.id);

			setGenIds(ids);
		} catch (error) {
			console.error("Error fetching documents:", error);
		} finally {
			setIsGenIdsLoading(false);
		}
	};

	const fetchAllRoutesOptions = async () => {
		try {
			const resp = await getAllRoutes(user?.uid);
			const options = [];
			let map = {};
			resp
				?.filter((route) => route.status == "ACTIVE")
				?.map((item) => {
					options.push({
						label: item.routeLabel,
						value: item.id,
					});
					map[item.id] = item.routeLabel;
				});
			setRouteOptions([{ label: "All", value: "all" }, ...options]);
			setSelectedRoutes(["all", ...options.map((item) => item.value)]);
		} catch (error) {
			console.log("Error fetching routes", error);
		}
	};

	const fetchAllTreatmentOptions = async () => {
		try {
			const resp = await getAllTreatmentOptions(user?.uid);
			const options = [];
			let map = {};
			resp.forEach((item) => {
				options.push({
					label: item.name,
					value: item.id,
				});
				map[item.id] = item.name;
			});
			setTreatFaciOptions([{ label: "All", value: "all" }, ...options]);
			setSelectedTreatFacis(["all", ...options.map((item) => item.value)]);
			setTreatmentMap(map);
		} catch (error) {
			console.log("Error fetching treatment options", error);
		}
	};

	const fetchAllDriverOptions = async () => {
		try {
			const resp = await getAllDrivers(user?.uid);
			const options = [];
			let map = {};
			resp.map((item) => {
				let label = item.firstName + " " + item.lastName;
				options.push({
					label,
					value: label,
				});
				map[item.id] = item.driverName;
			});
			console.log({ resp, options });

			setDriverOptions([{ label: "All", value: "all" }, ...options]);
			setSelectedDriverOptions(["all", ...options.map((item) => item.value)]);
			setServiceTypeOptions([{ label: "All", value: "all" }, ...serviceTypes]);
			setSelectedServiceTypes(["all", ...serviceTypes.map((item) => item.value)]);
		} catch (error) {
			console.log("Error fetching drivers", error);
		}
	};

	const handleSelectAll = () => {
		if (selectedRows.length === filteredData.length) {
			setSelectedRows([]);
		} else {
			const allIds = filteredData.map((service) => service.id);
			setSelectedRows(allIds);
		}
	};

	const handleRowCheckbox = (e, serviceId) => {
		const isChecked = e.target.checked;
		setSelectedRows((prev) => (isChecked ? [...prev, serviceId] : prev.filter((id) => id !== serviceId)));
	};

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "N/A";
		}

		return Array.isArray(scheduleData.serviceType)
			? scheduleData.serviceType.reduce((accumulator, serviceType) => {
					const matchingService = serviceTypes.find((service) => service.value === serviceType);
					return accumulator.concat(matchingService ? matchingService.label : "N/A");
			  }, [])
			: scheduleData.serviceType;
	};

	const getSummaryData = (consolidated, itemized) => {
		console.log("====================================");
		console.log({ consolidated, itemized, containersMap });
		console.log("====================================");
		const summary = [];
		if (consolidated?.length > 0) {
			consolidated.forEach((el) => {
				summary.push({
					documentId: el.documentId ?? "--",
					container: containersMap[el.containerType] ?? "--",
					quantity: el.quantity ?? 1,
					weight: el.weight ?? "--",
				});
			});
		}
		if (itemized?.length > 0) {
			itemized.forEach((el) => {
				summary.push({
					documentId: el.documentId ?? "--",
					container: containersMap[el.containerType] ?? "--",
					quantity: el.quantity ?? 1,
					weight: el.weight ?? "--",
				});
			});
		}
		return summary;
	};

	const getFormattedAddress = (add) => {
		const addressParts = [add.serviceAddress?.street, add.serviceAddress?.city, add.serviceAddress?.state].filter(
			Boolean
		);

		const address = addressParts.length
			? addressParts.join(", ") + (add.serviceAddress?.zipCode ? ` ${add.serviceAddress.zipCode}` : "")
			: "--";

		return address;
	};

	const formatTreatmentFacilities = (transfers) => {
		const facilities = [];
		if (Array.isArray(transfers) && transfers.length > 0) {
			transfers.map((transfer) => {
				facilities.push(treatmentMap[transfer.transferTo]);
			});
		}
		return facilities?.filter(Boolean);
	};

	const viewButtonHandler = async () => {
		console.log("Getting all services");

		// Date validation
		if (!from || !to) {
			showErrorToastMessage("Please select both from and to dates.");
			return;
		}

		// Create UTC dates at start of day
		const utcFromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()));
		const utcToDate = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1));

		if (utcFromDate > utcToDate) {
			showErrorToastMessage("To date must be greater than from date.");
			return;
		}

		try {
			setIsLoading(true);

			let q = query(
				collection(db, COLLECTIONS.transfers),
				where("createdAt", ">=", utcFromDate),
				where("createdAt", "<", utcToDate),
				where("transferStatus", "==", SERVICE_STATUS.COMPLETE),
				where("transporterId", "==", user.uid)
			);

			const snap = await getDocs(q);
			if (snap.empty) {
				showErrorToastMessage("No completed services found in given range.");
				return;
			}

			// Process transfers
			const allCompletedServices = [];
			const wasteTypeMap = new Map();

			const transfers = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

			console.log({ transfers });

			await Promise.all(
				transfers.map(async (transfer) => {
					let transferredTo = transfer?.transferTo;
					let transferDate = transfer?.createdAt ? transfer?.createdAt?.toDate() : null;
					let transferId = transfer?.transferOctoId ?? transfer.transferId;
					let serviceType = null;
					let serviceData = null;

					if (transferredTo && transferredTo?.length) {
						const treatmentDoc = await getDoc(doc(db, COLLECTIONS.treatments, transferredTo));
						if (treatmentDoc.exists()) {
							let data = treatmentDoc.data();
							transferredTo = data?.name;
						} else {
							transferredTo = null;
						}
					}

					if (Array.isArray(transfer.services) && transfer.services?.length) {
						const serviceScheduleDoc = await getDoc(doc(db, COLLECTIONS.scheduledServices, transfer.services[0]));
						if (serviceScheduleDoc.exists()) {
							serviceData = serviceScheduleDoc.data();
							console.log({ serviceData });

							let serviceScheduleId = serviceData?.serviceScheduleId;
							const serviceDoc = await getDoc(doc(db, COLLECTIONS.serviceSchedules, serviceScheduleId));
							if (serviceDoc.exists()) {
								let data = serviceDoc.data();
								serviceType = getStringOfServiceTypes(data);
							} else {
								transferredTo = null;
							}
						} else {
							transferredTo = null;
						}
					}

					const containers = await Promise.all(
						transfer.containers?.map((id) =>
							getDoc(doc(db, COLLECTIONS.servicedContainers, id)).then((snap) => snap.data())
						) || []
					);

					const manifests = await Promise.all(
						transfer.manifests?.map((id) =>
							getDoc(doc(db, COLLECTIONS.manifests, id)).then((snap) => ({ id, ...snap.data() }))
						) || []
					);

					const transferManifestIds = manifests.map((manifest) => manifest.id);

					wasteTypeMap.clear();

					containers.forEach((container) => {
						const key = container.subWasteType;
						const existing = wasteTypeMap.get(key) || {
							containerCount: 0,
							manifestCount: 0,
							totalWeight: 0,
							manifestIds: [],
						};

						existing.containerCount++;
						existing.totalWeight += Number(container.weight) || 0;
						wasteTypeMap.set(key, existing);
					});

					manifests.forEach((manifest) => {
						const key = manifest.subWasteType;
						const existing = wasteTypeMap.get(key) || {
							containerCount: 0,
							manifestCount: 0,
							totalWeight: 0,
							manifestIds: [],
						};
						existing.manifestIds.push(manifest.id);
						existing.manifestCount++;
						wasteTypeMap.set(key, existing);
					});

					allCompletedServices.push({
						id: transfer.id,
						transferSummary: Array.from(wasteTypeMap, ([subWasteType, data]) => ({
							subWasteType,
							...data,
						})),
						serviceType,
						transferId,
						transferDate,
						transferredTo,
						driverName: serviceData?.assignedDriverName ?? "--",
						pickUpTruck: serviceData?.assignedServiceVehicleName ?? "--",
						routeName: routeOptions.find((item) => item.value == serviceData?.routeId)?.label ?? "--",
						finalTreatmentLogDate: transfer?.finalTreatmentLogDate ? transfer?.finalTreatmentLogDate?.toDate() : null,
						finalTreatment: Boolean(transfer?.finalTreatment),
						allManifestIds: transferManifestIds,
					});
				})
			);

			console.log({ allCompletedServices });

			setCompleteServices(allCompletedServices);
			setSelectedRows([]);
		} catch (error) {
			console.error("Error fetching services:", error);
			showErrorToastMessage("Failed to fetch services. Please try again.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (user) {
			fetchAllRoutesOptions();
			fetchAllDriverOptions();
			fetchAllTreatmentOptions();
		}
	}, [user]);

	useEffect(() => {
		if (user) {
			fetchDocuments();
		}
	}, [user]);

	useEffect(() => {
		if (user && !genIdsLoading) viewButtonHandler();
	}, [to, from, user, genIdsLoading]);

	console.log({ completeServices });

	const normalizedSearchQuery = searchQuery.replace(/\s+/g, "").toLowerCase();
	const hasIntersection = (arr1, arr2) => arr1.some((item) => arr2.includes(item));

	useEffect(() => {
		startTransition(() => {
			let matchedServices = completeServices.filter((item) => selectedServiceTypes.includes(item.serviceType));
			console.log({ matchedServices, selectedTreatFacis, selectedDriverOptions });
			matchedServices = matchedServices.filter((item) =>
				selectedDriverOptions.includes("all") ? true : selectedDriverOptions.includes(item.driverName)
			);
			console.log({ matchedServices, selectedTreatFacis });
			matchedServices = matchedServices.filter((item) =>
				selectedRoutes.includes(routeOptions.find((route) => route.label == item.routeName).value)
			);
			console.log("====================================");
			console.log({ matchedServices });
			console.log("====================================");
			matchedServices = matchedServices.filter((item) => {
				if (selectedTreatFacis.includes("all")) return true;
				const facilityId = Object.keys(treatmentMap).find((key) => treatmentMap[key] === item.transferredTo);
				return selectedTreatFacis.includes(facilityId);
			});
			console.log("====================================");
			console.log({ matchedServices });
			console.log("====================================");
			setFilteredData(matchedServices?.sort((a, b) => b.transferDate - a.transferDate));
			let combinedWeight = 0,
				combinedManifests = 0;
			matchedServices.map((item) => {
				item.transferSummary?.map((data) => (combinedWeight += Number(data?.totalWeight)));
			});
			matchedServices.map((item) => {
				item.transferSummary?.map((data) => (combinedManifests += Number(data?.manifestCount)));
			});
			console.log({ combinedWeight, combinedManifests });

			// Update state
			setTotalWeight(combinedWeight);
			setTotalManifest(combinedManifests);
		});
	}, [completeServices, selectedDriverOptions, selectedServiceTypes, selectedTreatFacis, selectedRoutes, searchQuery]);

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		if (newSelectAll) {
			setSelectedServices(transferData.map((_, i) => i));
		} else {
			setSelectedServices([]);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedServices((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
	};

	const handlePreviousButton = () => {
		// Handle previous button click
	};

	const handleFinalTreatmentChange = async (transferId) => {
		const serviceIndex = completeServices.findIndex((s) => s.id === transferId);
		if (serviceIndex === -1) return;

		const service = completeServices[serviceIndex];
		const currentFinalTreatment = service?.finalTreatment || false;
		const newFinalTreatment = !currentFinalTreatment;
		const newDate = newFinalTreatment ? new Date() : null;

		// Optimistic local update
		const updatedServices = [...completeServices];
		updatedServices[serviceIndex] = {
			...service,
			...service.options,
			finalTreatment: newFinalTreatment,
			finalTreatmentLogDate: newDate?.toDateString(),
		};
		setCompleteServices(updatedServices);

		try {
			const transferRef = doc(db, COLLECTIONS.transfers, transferId);
			await updateDoc(transferRef, {
				finalTreatment: newFinalTreatment,
				finalTreatmentLogDate: newDate,
			});
		} catch (error) {
			console.error("Error updating transfer document:", error);
			// Revert on error
			const revertedServices = [...completeServices];
			revertedServices[serviceIndex] = {
				...service,
				finalTreatment: currentFinalTreatment,
				finalTreatmentLogDate: service?.finalTreatmentLogDate,
			};
			setCompleteServices(revertedServices);
			showErrorToastMessage("Failed to update final treatment status.");
		}
	};

	const handleUpdateTransferTo = async (transferId, newFacilityId, transferUpdateReason) => {
		try {
			// Update Firestore
			const transferRef = doc(db, COLLECTIONS.transfers, transferId);
			await updateDoc(transferRef, {
				transferTo: newFacilityId,
				transferUpdateReason,
				isEdited: true,
				updatedAt: new Date(),
			});

			const currTransfer = completeServices.find((item) => item.id === transferId);

			let allManifests = currTransfer?.allManifestIds;
			if (allManifests?.length > 0) {
				allManifests.map(async (manifestId) => {
					const manifestRef = doc(db, COLLECTIONS.manifests, manifestId);
					await updateDoc(manifestRef, {
						url: "",
					});
				});
			}

			// Update local state optimistically
			setCompleteServices((prevServices) =>
				prevServices.map((service) =>
					service.id === transferId
						? {
								...service,
								transferredTo: treatmentMap[newFacilityId] || "--",
						  }
						: service
				)
			);
			showSuccessToastMessage("Facility updated successfully!");
		} catch (error) {
			console.error("Error updating facility:", error);
			showErrorToastMessage("Failed to update facility");
		}
	};

	const clearFilters = () => {
		setSelectedDriverOptions(["all", ...driverOptions.map((item) => item.value)]);
		setSelectedServiceTypes(["all", ...serviceTypes.map((item) => item.value)]);
		setSelectedRoutes(["all", ...routeOptions.map((item) => item.value)]);
		setSelectedTreatFacis(["all", ...treatFaciOptions.map((item) => item.value)]);
	};

	return (
		<div
			style={{ height: selectedRows.length > 0 ? `calc(100% - 60px)` : "100%" }}
			className="flex flex-col border-cardBorderCol bg-white rounded-cardRadii overflow-clip"
		>
			<div className="h-fit">
				<div className="text-lg font-medium px-8 py-4 text-black">Transfer History Report</div>
				<div className="w-full flex justify-between px-8 py-5">
					<div className="flex flex-wrap gap-5 gap-y-3 items-end">
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">From</p>
							<CustomDatePicker
								selectedDate={from}
								setSelectedDate={(value) => setFrom(new Date(value))}
								label="From Date"
							/>
						</div>
						<div className="flex flex-col min-w-52">
							<p className="text-sm text-gray-500 px-2">To</p>
							<CustomDatePicker selectedDate={to} setSelectedDate={(value) => setTo(new Date(value))} label="To Date" />
						</div>
						<MultiselectDropdown
							width="w-48"
							buttonText="Service Type"
							options={serviceTypeOptions}
							selectedOptions={selectedServiceTypes}
							onSelectedOptionsChange={(values) => setSelectedServiceTypes(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Route"
							options={routeOptions}
							selectedOptions={selectedRoutes}
							onSelectedOptionsChange={(values) => setSelectedRoutes(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Driver"
							options={driverOptions}
							selectedOptions={selectedDriverOptions}
							onSelectedOptionsChange={(values) => setSelectedDriverOptions(values)}
						/>
						<MultiselectDropdown
							width="w-48"
							buttonText="Treatment Facility"
							options={treatFaciOptions}
							selectedOptions={selectedTreatFacis}
							onSelectedOptionsChange={(values) => {
								const hasAll = values.includes("all");
								setSelectedTreatFacis(hasAll ? ["all", ...values] : values);
							}}
						/>
					</div>
					<button className="text-primary hover:text-blue-900" onClick={clearFilters}>
						Clear Filter
					</button>
				</div>
			</div>
			<div className="flex flex-col w-full font-medium overflow-y-scroll">
				<div className="flex bg-[#E5F2FF] w-fit py-4">
					<div className="w-8 mx-8 flex justify-center items-center hover:cursor-pointer">
						<input
							type="checkbox"
							ref={selectAllRef}
							className="w-4 h-4 bg-white"
							checked={selectAll}
							onChange={handleSelectAll}
						/>
					</div>
					<div className="flex items-center">
						<div className="truncate w-36">Transfer Date</div>
						<div className="truncate w-52">Transfer ID</div>
						<div className="truncate w-48">Service Type</div>
						<div className="truncate w-40">Route</div>
						<div className="truncate w-40">Service Vehicle</div>
						<div className="truncate w-52">Driver</div>
						<div className="truncate w-60">Transporter</div>
						<div className="truncate w-60">Transporter ID</div>
						<div className="truncate w-80">Transferred To</div>
					</div>
				</div>
				<div className="flex flex-col w-fit overflow-y-auto">
					{isLoading ? (
						<Loader height="h-20 mt-8 w-screen" />
					) : filteredData.length == 0 ? (
						<div className="flex h-40 w-screen text-cardTextGray text-xl justify-center items-center">
							<p>No result found</p>
						</div>
					) : (
						filteredData.map((item, index) => (
							<ServiceCard
								key={index}
								service={item}
								index={index}
								selectedServices={selectedRows}
								handleCheckboxChange={handleRowCheckbox}
								transporterData={transporterData}
								user={user}
								handleFinalTreatmentChange={handleFinalTreatmentChange}
								treatFaciOptions={treatFaciOptions}
								handleUpdateTransferTo={handleUpdateTransferTo}
								treatmentMap={treatmentMap}
							/>
						))
					)}
				</div>
			</div>
			<div className="bg-whiteBlue font-medium p-4 px-10 w-full flex">
				<div className="flex gap-16">
					<span>Totals: Manifests: {Number(totalManifest).toFixed(2)}</span>
					<span>Weight: {Number(totalWeight).toFixed(2)} lbs</span>
				</div>
			</div>
			<FooterActionItems servicesSelected={selectedRows} />
		</div>
	);
};

export default TransferReports;
