import React, { useEffect, useState } from "react";
import AuthForm from "./components/AuthForm";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, signOutUser } from "../../config/firebase";
import { toast } from "react-toastify";
import Loader from "../../components/UI/loaders/Loader.jsx";
import { doc, getDoc } from "firebase/firestore";

const Auth = () => {
	const [defaultForm, setDefaultForm] = useState("LogIn");
	const [isLoading, setIsLoading] = useState(true);
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();

	const checkForRedirection = async () => {
		if (!user?.uid) return;

		try {
			const transporterRef = doc(db, "users", user.uid);
			const marketAdminRef = doc(db, "admins", user.uid);
			const marketUserRef = doc(db, "octoMarketUsers", user.uid);

			const [transporterDoc, marketAdminDoc, marketUserDoc] = await Promise.all([
				getDoc(transporterRef),
				getDoc(marketAdminRef),
				getDoc(marketUserRef),
			]);

			setIsLoading(false);

			console.log({
				transporterExists: transporterDoc.exists(),
				marketAdminExists: marketAdminDoc.exists(),
				marketUserExists: marketUserDoc.exists(),
			});

			if (transporterDoc.exists()) {
				console.log("Redirecting to Admin (Transporter)");
				navigate("/admin");
				return;
			} else if (marketAdminDoc.exists()) {
				console.log("Redirecting to Market Admin");
				navigate("/market-admin");
				return;
			} else if (marketUserDoc.exists()) {
				console.log("Redirecting to Market User");
				navigate("/market");
				return;
			} else {
				console.log("Redirecting to Transporter Registration");
				navigate("/transporter");
				return;
			}
		} catch (error) {
			console.error("Error checking user role:", error);
			toast.error("An error occurred. Please try again.");
			await signOutUser();
		}
	};

	useEffect(() => {
		if (!loading && user) {
			checkForRedirection();
		} else if (!user && !loading) {
			setIsLoading(false);
		}
	}, [user, loading]);

	if (isLoading) return <Loader />;

	return (
		<div className="w-full min-h-screen h-fit flex items-center">
			<div className="w-1/2 relative">
				<img
					src="https://storage.googleapis.com/routes-123.appspot.com/logos/close-up-octopus-swimming-underwater.png"
					alt=""
					className="fixed h-screen top-0 w-1/2 object-cover"
				/>
			</div>
			<div
				className={`w-1/2 flex flex-col justify-center items-center px-10 lg:px-20 xl:px-24 bg-white ${
					defaultForm === "SignUp" ? "" : "h-screen"
				}`}
			>
				<div className="flex items-center py-6 justify-center">
					<img
						src="https://storage.googleapis.com/routes-123.appspot.com/logos/OCTOPUS.png"
						alt=""
						className="h-16 w-auto"
					/>
				</div>
				<AuthForm defaultForm={defaultForm} setDefaultForm={setDefaultForm} />
			</div>
		</div>
	);
};

export default Auth;
