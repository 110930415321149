import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth, COLLECTIONS, db, signOutUser } from "../../../config/firebase";
import { IoClose } from "react-icons/io5";
import { doc, getDoc } from "firebase/firestore";

const MarketUserBtn = ({ userData }) => {
	const [isAccDropOpen, setIsAccDropOpen] = useState(false);
	const [userName, setUserName] = useState(null);
	const accDropRef = useRef(null);
	const [user] = useAuthState(auth);
	// const userName = user?.displayName ?? user?.email?.split("@")[0];
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const fetchUserName = async () => {
			const docRef = doc(db, COLLECTIONS.octoMarketUsers, user.uid);
			const userDoc = await getDoc(docRef);
			setUserName(userDoc.data()?.name);
		};
		if (user) fetchUserName();
	}, [user]);

	console.log("====================================");
	console.log({ userData });
	console.log("====================================");

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (accDropRef.current && !accDropRef.current.contains(event.target)) {
				setIsAccDropOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside, true);
		document.addEventListener("touchstart", handleClickOutside, true);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside, true);
			document.removeEventListener("touchstart", handleClickOutside, true);
		};
	}, []);

	const toggleDropdown = (event) => {
		event.stopPropagation();
		setIsAccDropOpen((prev) => !prev);
	};

	const handleItemClick = async (action) => {
		setIsAccDropOpen(false);

		if (action === "logout") {
			await signOutUser();
		} else if (action === "audit_log") {
			navigate("/admin/audit");
		} else if (action === "my_profile") {
			navigate("/admin/my-settings");
		} else if (action === "transporter_profile") {
			navigate("/admin/profile");
		}
	};

	const notificationVariants = {
		hidden: { opacity: 0, y: -10 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -10 },
	};

	return (
		<div className="relative" ref={accDropRef}>
			<button
				onClick={toggleDropdown}
				className="flex items-center gap-4 hover:cursor-pointer focus:outline-none"
				type="button"
				aria-expanded={isAccDropOpen}
				aria-haspopup="true"
			>
				<div className="flex items-center justify-center max-h-10 max-w-10 bg-white border border-gray-200 rounded-full">
					<img
						src={`https://api.dicebear.com/9.x/initials/png?seed=${userName}`}
						alt="User Avatar"
						className="rounded-full w-full h-full object-cover"
					/>
				</div>
			</button>

			<AnimatePresence>
				{isAccDropOpen && (
					<motion.div
						variants={notificationVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
						className="absolute right-0 mt-2 w-60 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-50"
					>
						<div className="flex items-center justify-end p-4 py-3 border-b border-gray-200">
							<motion.button
								whileHover={{ scale: 1.1 }}
								whileTap={{ scale: 0.95 }}
								onClick={() => setIsAccDropOpen(false)}
								className="text-gray-500 hover:text-gray-700"
							>
								<IoClose size={20} />
							</motion.button>
						</div>
						<div className="max-h-96 px-4 py-1 overflow-y-auto">
							<h6 className="font-semibold text-gray-700 truncate">
								{userName ? user.displayName || userName.charAt(0).toUpperCase() + userName.slice(1) : "Default User"}
							</h6>
							<p className="text-sm text-cardTextGray truncate">{user?.email ?? "user@test.com"}</p>
						</div>
						<button
							onClick={() => handleItemClick("logout")}
							className="w-full text-left px-4 py-2 text-gray-700 hover:bg-lightBlue hover:text-white"
						>
							Logout
						</button>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default MarketUserBtn;
