import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { auth, db } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc } from "firebase/firestore";
import Button from "../../../components/UI/Button";
import useTUserContext from "../../../context/TransporterUserContext";

const fetchContractorData = async (transporterId) => {
	try {
		// Get the transporter's document
		const transporterDoc = await getDoc(doc(db, "transporters", transporterId));
		const transporterMarketDoc = await getDoc(doc(db, "octoMarketUsers", transporterId));

		if (!transporterDoc.exists() && !transporterMarketDoc.exists()) {
			console.log("No such transporter!");
			return [];
		}

		const data = transporterDoc.data();
		const transporterMarketData = transporterMarketDoc.data();
		console.log({ data, octoData: transporterMarketDoc.data() });

		if (!transporterMarketData) return [];

		const contractorRelationships = transporterMarketData.connections || {};

		// Get all contractor details
		const contractorPromises = Object.entries(contractorRelationships).map(async ([contractorId, relationship]) => {
			const contractorDoc = await getDoc(doc(db, "transporters", contractorId));

			if (!contractorDoc.exists()) return null;

			const contractorData = contractorDoc.data();

			console.log("====================================");
			console.log({ contractorData });
			console.log("====================================");

			return {
				id: contractorData,
				name: contractorData.name ?? "--",
				address: formatAdd(contractorData),
				generalEmail: contractorData.generalEmail?.length > 0 ? contractorData.generalEmail : "--",
				phoneNumber: contractorData.phoneNumber ?? "--",
				website: contractorData.website?.length ? contractorData?.website : "--",
				sharedGenerators: contractorData?.allGens?.length,
				startDate: relationship.startDate,
				status: relationship.status,
			};
		});

		const contractors = await Promise.all(contractorPromises);
		return contractors
			.concat([
				{
					id: transporterDoc.id,
					name: data.name ?? "--",
					address: formatAdd(data),
					generalEmail: data.generalEmail?.length > 0 ? data.generalEmail : "--",
					phoneNumber: data.phoneNumber ?? "--",
					website: data.website?.length ? data?.website : "--",
					sharedGenerators: data?.allGens?.length,
					startDate: new Date(),
					status: "",
				},
			])
			.filter(Boolean);
	} catch (error) {
		console.error("Error fetching contractor data:", error);
		return [];
	}
};

let formatAdd = (transporter) => {
	let formattedAdd = "";
	transporter.billingAddress?.street?.trim()?.length
		? (formattedAdd += transporter.billingAddress?.street)
		: (formattedAdd = "");
	transporter.billingAddress?.suite?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingAddress?.suite}`)
		: (formattedAdd += transporter.billingAddress?.suite ?? "");
	transporter.billingAddress?.city?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingAddress?.city}`)
		: (formattedAdd += transporter.billingAddress?.city ?? "");
	transporter.billingAddress?.state?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingAddress?.state}`)
		: (formattedAdd += transporter.billingAddress?.state ?? "");
	transporter.billingAddress?.zipCode?.trim()?.length && formattedAdd?.length
		? (formattedAdd += ` ${transporter.billingAddress?.zipCode}`)
		: (formattedAdd += transporter.billingAddress?.zipCode ?? "");

	return formattedAdd.length ? formattedAdd : "--";
};

const ContractorManagement = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();
	const { user, loading } = useTUserContext();

	useEffect(() => {
		const fetchTransporterDoc = async () => {
			const docRef = doc(db, "transporters", user?.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				console.log("Document data:", docSnap.data());
			} else {
				// docSnap.data() will be undefined in this case
				console.log("No such document!");
			}
		};

		if (user) fetchTransporterDoc();
	}, [user]);

	console.log({ user: user });

	// Dummy data for contractors
	const dummyContractorData = Array.from({ length: 5 }, (_, index) => ({
		id: index + 1,
		Customer: `${index + 1} Generator Name details`,
	}));

	// const [data, setData] = useState(dummyContractorData);
	const [contractorData, setContractorData] = useState([]);
	const dropdownOptions = ["Listed", "Unlisted"];

	useEffect(() => {
		const loadContractors = async () => {
			if (!user) return;

			setIsLoading(true);
			try {
				const contractors = await fetchContractorData(user?.uid);
				console.log({ contractors });

				setContractorData(contractors);
			} catch (error) {
				console.error("Error loading contractors:", error);
			} finally {
				setIsLoading(false);
			}
		};

		loadContractors();
	}, [user]);

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/subcontractor-schedules");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/subcontractor-management");
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	const handleSubmit = async (gens, subContractor) => {
		console.log({ gens });
		localStorage.setItem("gens", JSON.stringify(gens));
		localStorage.setItem("subContractor", subContractor);
		navigate("/admin/octo-connect/service-locations");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border text-sm xl:text-base border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 text-black">Contractor Management</div>
				<div className="text-base mx-8 mb-3 text-black">Current Partners Management</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center gap-2">
							<div className="truncate w-60">Contractor Name</div>
							{/* <div className="truncate w-60">OCTO Number</div> */}
							<div className="truncate w-80">Contractor Address</div>
							<div className="truncate w-60">Contractor Email</div>
							<div className="truncate w-60">Contractor Phone</div>
							<div className="truncate w-60">Contractor Website</div>
							<div className="truncate w-60 text-center">Number of Service Locations</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : contractorData.length > 0 ? (
							contractorData.map((contractor, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < contractorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex gap-2 items-center`}>
										<div className="truncate w-60 h-full">{contractor.name ?? "--"}</div>
										{/* <div className="w-60 h-full text-cardTextBlue truncate">{contractor.octoNumber ?? "--"}</div> */}
										<div className="truncate h-full w-80">{contractor.address ?? "--"}</div>
										<div className="truncate h-full w-60">{contractor.generalEmail ?? "--"}</div>
										<div className={`w-60 h-full`}>
											<span
												className={`truncate ${
													contractor.phoneNumber?.length > 0 ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) => (contractor.phoneNumber ? handlePhoneClick(e, contractor.phoneNumber) : "")}
											>
												{contractor.phoneNumber?.length > 0 ? contractor.phoneNumber : "--"}
											</span>
										</div>
										<div className="truncate h-full w-60">{contractor.website ?? "--"}</div>
										<div className="truncate h-full w-60 text-center">
											<button
												// onClick={() => handleSubmit(contractor.sharedGenerators, contractor.name)}
												className={`${
													contractor.sharedGenerators?.length ? "hover:text-blueText hover:underline" : "cursor-default"
												}`}
											>
												{contractor.sharedGenerators?.length?.toString() ?? "--"}
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* Buttons Section */}
				<div className="flex justify-end p-4 gap-8 mb-12 mt-12">
					<Button btnStyle="form_nav_secondary" text={"Previous"} onClick={handlePreviousBtn} />
					<Button btnStyle="form_nav_primary" text="Next" onClick={handleNextClick} />
				</div>
			</div>
		</div>
	);
};

export default ContractorManagement;
