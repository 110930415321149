import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import MiniRegistrationModal from "./MiniRegistrationModal";
import { AnimatePresence, motion } from "framer-motion";
import MiniDriverRegistrationForm from "./MiniDriverRegModal";
import MiniVendorRegModal from "./userAccBtn/MiniVendorRegModal";
import MiniTreatmentRegModal from "./userAccBtn/MiniTreatmentRegModal";
import MiniTransporterRegModal from "./userAccBtn/MiniServiceVehicleRegModal";
import MiniServiceVehicleRegModal from "./userAccBtn/MiniServiceVehicleRegModal";
import MiniSatelliteRegModal from "./userAccBtn/MiniSatelliteRegModal";

const AddNewBtnBar = () => {
	const navigate = useNavigate();
	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const menuRef = useRef(null);
	const location = useLocation();
	const [activeSubmenu, setActiveSubmenu] = useState("generator");
	const [WchModalOpen, setWchModalOpen] = useState("");

	const toggleSubmenu = () => setIsSubmenuOpen(!isSubmenuOpen);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsSubmenuOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	const clickHandler = (submenu) => {
		setActiveSubmenu(submenu);
		setIsSubmenuOpen(false);

		if (submenu === "Vendor") {
			setWchModalOpen("vendor");
		} else if (submenu === "driver") {
			setWchModalOpen("driver");
		} else if (submenu === "treatment") {
			setWchModalOpen("treatment");
		} else if (submenu === "satellite") {
			setWchModalOpen("satellite");
		} else if (submenu === "serviceVehicle") {
			setWchModalOpen("serviceVehicle");
		} else {
			setWchModalOpen("generator");
		}
	};

	return (
		<div className="relative flex flex-col gap-4 justify-end py-4" ref={menuRef}>
			<button
				className="bg-dashBtnGradient py-2.5 px-7 w-36 xl:w-40 h-12 flex justify-between hover:opacity-90 items-center rounded-full text-white"
				onClick={toggleSubmenu}
			>
				<h6>Add New</h6>
				<IoIosArrowDown
					size={20}
					className={`font-bold transform transition-transform duration-200 ${
						isSubmenuOpen ? "rotate-180" : "rotate-0"
					}`}
				/>
			</button>
			<AnimatePresence>
				{isSubmenuOpen && (
					<motion.ul
						className="absolute z-50 bg-white top-[76px] -right-0 min-w-full rounded-xl overflow-hidden shadow-lg"
						initial={{ opacity: 0, y: -10 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -10 }}
						transition={{ duration: 0.2 }}
					>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => clickHandler("generator")}
						>
							Generator
						</li>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => clickHandler("driver")}
						>
							Driver
						</li>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => {
								setIsSubmenuOpen(false);
								navigate("/admin/assets/routes/add");
							}}
						>
							Route
						</li>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => clickHandler("Vendor")}
						>
							Vendor
						</li>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => clickHandler("treatment")}
						>
							Treatment & Transfer
						</li>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => clickHandler("satellite")}
						>
							Satellite Location
						</li>
						<li
							className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue"
							onClick={() => clickHandler("serviceVehicle")}
						>
							Service Vehicle
						</li>
					</motion.ul>
				)}
			</AnimatePresence>
			{WchModalOpen === "generator" && (
				<MiniRegistrationModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
			{WchModalOpen === "driver" && (
				<MiniDriverRegistrationForm onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
			{WchModalOpen === "vendor" && (
				<MiniVendorRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
			{WchModalOpen === "treatment" && (
				<MiniTreatmentRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
			{WchModalOpen === "satellite" && (
				<MiniSatelliteRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
			{WchModalOpen === "transport" && (
				<MiniTransporterRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
			{WchModalOpen === "serviceVehicle" && (
				<MiniServiceVehicleRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />
			)}
		</div>
	);
};

export default AddNewBtnBar;
