import React, { useEffect, useState } from "react";
import PhoneInput from "../../../components/UI/PhoneInput";
import Input from "../../../components/UI/Input";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import { motion } from "framer-motion";
import Loader from "../../../components/UI/loaders/Loader";
import { sendOctoMarketMessage } from "../../../utils/apiOps";
import { toast } from "react-toastify";

const testEmailTemplates = [
	{
		subject: "Connection request",
		body: `Hi,
		Just wanted to connect with you, your profile looking good maybe we can make a good connection!`,
	},
];

const ContactForm = ({ data, to, id }) => {
	const [isPhoneDisabled, setIsPhoneDisabled] = useState(true);
	const [isEmailDisabled, setIsEmailDisabled] = useState(true);
	const [mailSent, setMailSent] = useState(false);
	const [sending, setSending] = useState(false);
	const [formData, setFormData] = useState({
		companyName: "",
		name: "",
		octoNumber: "",
		email: "Lorem ipsum dolor",
		phone: "+91 123 456 123",
		subject: "",
		message: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		console.log({ name, value, e });

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	console.log({ formData, to });

	useEffect(() => {
		if (data) {
			setFormData((prev) => ({
				companyName: data.companyName ?? "",
				name: data.name ?? "",
				octoNumber: data.octoNumber ?? "",
				email: data.email ?? "",
				phone: data.phone ?? "",
			}));
		}
	}, []);

	const handleSubmit = async (e) => {
		setSending(true);
		e.preventDefault();
		try {
			// let resp = await sendOctoMarketMessage(to, formData);
			setMailSent(true);
		} catch (error) {
			console.log(error.response);

			toast.error(error.response?.data?.error?.message ?? "Couldn't send right now, please try after some times");
		} finally {
			setSending(false);
			setFormData((prev) => ({
				...prev,
				subject: "",
				message: "",
			}));
		}
	};

	return (
		<div className="">
			<h2 className="text-2xl font-bold mb-4 text-thatblue">Send Us a Message</h2>

			<form onSubmit={handleSubmit} className="space-y-4">
				<div className="space-y-4">
					{/* Static text fields */}
					<div className="flex gap-4 items-start">
						<div className="w-[30%] text-inputLabel">Company Name</div>
						<div className="w-[70%] text-cardTextGray">{data?.companyName?.length ? data?.companyName : "--"}</div>
					</div>

					<div className="flex gap-4 pt-2 items-center">
						<div className="w-[30%] text-inputLabel">Your Name</div>
						<div className="w-[70%] text-cardTextGray">{data?.name?.length ? data?.name : "--"}</div>
					</div>

					{/* Editable fields */}
					<div className="w-full">
						<div className="flex items-center w-full">
							<div className="w-11/12">
								<Input
									label="Email"
									margin="mt-0"
									value={formData.email}
									onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target?.value }))}
									isDisabled={isEmailDisabled}
								/>
							</div>
							<button
								type="button"
								className="ml-2 w-1/12 text-blue-500 text-sm hover:text-blue-600"
								onClick={() => setIsEmailDisabled(!isEmailDisabled)}
							>
								{isEmailDisabled ? "(Edit)" : ""}
							</button>
						</div>
					</div>

					<div className="flex items-center w-full">
						<div className="w-11/12">
							<PhoneInput
								value={formData.phone}
								onChange={(val) => setFormData((prev) => ({ ...prev, phone: val }))}
								isDisabled={isPhoneDisabled}
								margin="0"
							/>
						</div>
						<button
							type="button"
							className="ml-2 w-1/12 text-blue-500 text-sm hover:text-blue-600"
							onClick={() => setIsPhoneDisabled(!isPhoneDisabled)}
						>
							{isPhoneDisabled ? "(Edit)" : ""}
						</button>
					</div>

					<div className="flex gap-2 items-center">
						<label className="w-[40%] text-cardTextGray" htmlFor="">
							Subject
						</label>
						<Input
							style={"w-full"}
							margin="mt-0"
							value={formData.subject}
							onChange={(e) => setFormData((prev) => ({ ...prev, subject: e.target?.value }))}
						/>
					</div>

					<div className="flex">
						<div className="w-[30%] text-inputLabel">Message</div>
						<textarea
							id="message"
							name="message"
							value={formData.message}
							onChange={handleChange}
							rows={4}
							className="w-[70%] p-3 px-5 bg-[#F1F1F4] text-gray-700 rounded-[24px] focus:outline-blue-500"
						/>
					</div>
				</div>
				<div className="flex w-full justify-end">
					{id == data?.id ? (
						<motion.button
							className={`disabled:cursor-not-allowed group disabled:bg-[#898989] bg-[#007AFF] hover:bg-blue-700 text-white px-10 py-3 rounded-full`}
							whileHover={{ scale: 1.05 }}
							disabled={true}
							whileTap={{ scale: 0.95 }}
							type="button"
						>
							{sending ? <Loader height="" /> : "Send Message"}
							<div className="opacity-0 group-hover:opacity-100 transition-all duration-300 absolute right-0 y-1/2 bg-gray-900 text-white text-sm px-2 py-1 rounded pointer-events-none whitespace-nowrap">
								Sending mail to self is prohibited!
							</div>
						</motion.button>
					) : (
						<div className="flex gap-8 items-center w-full justify-end">
							{mailSent && <p className="text-primaryGreen text-sm">Message Sent!</p>}
							<motion.button
								className={`disabled:cursor-not-allowed disabled:bg-[#898989] bg-[#007AFF] hover:bg-blue-700 text-white px-10 py-3 rounded-full`}
								whileHover={{ scale: 1.05 }}
								disabled={sending}
								whileTap={{ scale: 0.95 }}
								type="submit"
							>
								{sending ? <Loader height="" /> : "Send Message"}
							</motion.button>
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
