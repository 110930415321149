import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { BsFileEarmarkArrowUp, BsTrash, BsFileEarmarkCheck } from "react-icons/bs";
import { GrUpdate } from "react-icons/gr";
import { GoPencil } from "react-icons/go";

const FileUpload = ({ control, errors, label = "Attach File", name = "contractFiles", existingFile, onDeleteFile, isRequired = false }) => {
	const fileInputRef = useRef(null);
	const [isUpdating, setIsUpdating] = useState(false);
	const [localFile, setLocalFile] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [fileName, setFileName] = useState("");
	const [fileExtension, setFileExtension] = useState("");
	const [previewUrl, setPreviewUrl] = useState(null);
	const [showExisting, setShowExisting] = useState(true);


	const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const ALLOWED_FILE_TYPES = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml", "application/pdf"];

	const handleFilePickerClick = () => {
		fileInputRef.current?.click();
	};

	const getFileNameAndExtension = (fullName) => {
		const lastDotIndex = fullName.lastIndexOf(".");
		if (lastDotIndex === -1) return [fullName, ""];
		return [fullName.substring(0, lastDotIndex), fullName.substring(lastDotIndex)];
	};

	const isImageFile = (file) => {
		return file && file.type.startsWith("image/");
	};

	const isPdfFile = (file) => {
		return file && file.type === "application/pdf";
	};

	const createPreview = (file) => {
		if (isImageFile(file)) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreviewUrl(reader.result);
			};
			reader.readAsDataURL(file);
		} else if (isPdfFile(file)) {
			setPreviewUrl(URL.createObjectURL(file));
		} else {
			setPreviewUrl(null);
		}
	};

	const handleFileChange = (e, onChange) => {
		const file = e.target.files[0];
		if (!file) return;

		if (file.size > MAX_FILE_SIZE) {
      alert(`File size exceeds 5MB limit (${(file.size / 1024 / 1024).toFixed(2)}MB)`);
      fileInputRef.current.value = null;
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      alert("Invalid file type. Allowed: PNG, JPG, JPEG, SVG, PDF");
      fileInputRef.current.value = null;
      return;
    }

			const [baseName, extension] = getFileNameAndExtension(file.name);
			setFileName(baseName);
			setFileExtension(extension);
			setLocalFile(file);
			setIsUpdating(true);
			setShowExisting(false);
			createPreview(file);
			onChange(file);
		
	};

	const handleNameChange = (e) => {
    const newName = e.target.value;
    setFileName(newName);
    
    if (localFile) {
      const newFile = new File([localFile], `${newName}${fileExtension}`, {
        type: localFile.type,
        lastModified: Date.now(),
      });
      setLocalFile(newFile);
      control._formValues[name] = newFile;
    }
  };

	const resetFileSelection = (onChange) => {
    if (fileInputRef.current) fileInputRef.current.value = null;
    setLocalFile(null);
    setIsUpdating(false);
    setIsEditing(false);
    setFileName("");
    setFileExtension("");
    setPreviewUrl(null);
    setShowExisting(true);
    onChange(null); // This clears the form value
  };

	const handleEditComplete = () => {
		setIsEditing(false);
	};

	

	return (
    <div className="flex flex-col mt-4">
      <div className="flex justify-between items-center">
        <label htmlFor={name} className="text-inputLabel font-normal truncate">
          {`${label} ${isRequired ? "*" : ""}`}
        </label>
        
        <div className="w-2/3">
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => (
              <div className="bg-gray-100 rounded-full p-2 px-4 h-9 flex items-center shadow-sm">
                {!existingFile?.url && !isUpdating ? (
                  <div className="flex items-center w-full">
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      onChange={(e) => handleFileChange(e, onChange)}
                      accept={ALLOWED_FILE_TYPES.join(",")}
                    />
                    <span className="text-cardTextGray flex-grow truncate">Choose a file...</span>
                    <BsFileEarmarkArrowUp
                      className="text-cardTextGray hover:fill-cardTextBlue cursor-pointer"
                      onClick={handleFilePickerClick}
                      size={20}
                    />
                  </div>
                ) : isUpdating ? (
                  <div className="flex items-center justify-between w-full">
                    {isEditing ? (
  <div className="flex-grow flex items-center">
    <input
      type="text"
      value={fileName}
      onChange={handleNameChange}
      className="bg-transparent focus:outline-none flex-grow"
      autoFocus
      onBlur={handleEditComplete}
      onKeyDown={(e) => e.key === "Enter" && handleEditComplete()}
    />
    <span className="text-cardTextGray">{fileExtension}</span>
  </div>
) : isPdfFile(localFile) ? (
  <a
    href={previewUrl}
    target={previewUrl}
    rel="noopener noreferrer"
    className="text-cardTextBlue hover:underline flex-grow truncate"
  >
    {fileName + fileExtension}
  </a>
): isImageFile( localFile) ? (
  <a
    href={previewUrl || existingFile?.url}
    target="_blank"
    rel="noopener noreferrer"
    className="text-cardTextBlue hover:underline flex-grow truncate"
  >
    {fileName + fileExtension}
  </a>
) : (
  <span className="text-cardTextGray truncate flex-grow">
    {fileName + fileExtension}
  </span>
)}

                    <div className="flex gap-2 ml-2">
                      <button
                        type="button"
                        onClick={() => setIsEditing(!isEditing)}
                        className="hover:text-cardTextBlue"
                      >
                        <GoPencil size={18} />
                      </button>
                      <button
                        type="button"
                        onClick={() => resetFileSelection(onChange)}
                        className="hover:text-red-500"
                      >
                        <BsTrash size={18} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between w-full">
                    <a
                      href={existingFile.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-cardTextBlue hover:underline truncate flex items-center"
                    >
                      <BsFileEarmarkCheck className="mr-2 text-cardTextGreen" size={20} />
                      {existingFile.name}
                    </a>
                    <div className="flex gap-2 ml-2">
                      <button
                        type="button"
                        onClick={handleFilePickerClick}
                        className="hover:text-cardTextBlue"
                      >
                        <GrUpdate size={18} />
                      </button>
                      <button
                        type="button"
												onClick={() => resetFileSelection(onChange)}
                        className="hover:text-red-500"
                      >
                        <BsTrash size={18} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      {/* {(previewUrl || (existingFile?.url && isImageFile(localFile))) && (
        <div className="mt-4 flex justify-center">
          {isImageFile(localFile) ? (
            <img
              src={previewUrl || existingFile.url}
              alt="Preview"
              className="max-w-xs max-h-48 object-contain shadow-sm rounded"
            />
          ) : null}
        </div>
      )} */}

      {errors[name] && (
        <p className="text-red-500 text-sm mt-1">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default FileUpload;