import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTUserContext from "../../context/TransporterUserContext";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../config/firebase";
import { emailTemplateTriggerTypeOptions, emailTemplateTypeOptions } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmailTemplate = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { user } = useTUserContext();
	const [data, setData] = useState(null);
	const [transporterData, setTransporterData] = useState(null);
	const [customMessage, setCustomMessage] = useState(""); 
	const iframeRef = useRef(null);

	// Fetch template data
	useEffect(() => {
		if (!user || !user.uid || !id) return;

		if (!COLLECTIONS || !COLLECTIONS.emailTemplates) {
			console.error("emailTemplates collection is not defined");
			return;
		}

		const unsubscribe = onSnapshot(doc(db, COLLECTIONS.emailTemplates, user.uid, "templates", id), (snap) => {
			if (snap.exists()) {
				const templateData = { id: snap.id, ...snap.data() };
				setData(templateData);
				setCustomMessage(templateData.customMessage || "");  // Load existing custom message
			} else {
				console.log("No such document exists!");
				setData(null);
			}
		});

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user, id]);

	// Fetch transporter data
	useEffect(() => {
		if (!user || !user.transporterId) return;

		const unsubscribe = onSnapshot(doc(db, COLLECTIONS.transporters, user.transporterId), (snap) => {
			if (snap.exists()) {
				setTransporterData({ id: snap.id, ...snap.data() });
			} else {
				setTransporterData(null);
			}
		});

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);

	useEffect(() => {
		const adjustIframeHeight = () => {
			if (iframeRef.current) {
				const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
				if (iframeDocument && iframeRef.current.contentWindow) {
					iframeRef.current.style.height = iframeDocument.body.scrollHeight + "px";
				}
			}
		};

		if (iframeRef.current && data?.htmlContent && transporterData) {
			let htmlContent = data.htmlContent;

			if (transporterData.companyName) {
				htmlContent = htmlContent.replace(/{{transporter.name}}/g, transporterData.companyName);
			}

			// Address information from serviceAddress
			if (transporterData.serviceAddress) {
				if (transporterData.serviceAddress.street) {
					htmlContent = htmlContent.replace(/{{transporter.street}}/g, transporterData.serviceAddress.street);
				}

				if (transporterData.serviceAddress.suite) {
					htmlContent = htmlContent.replace(/{{transporter.suite}}/g, transporterData.serviceAddress.suite);
				}

				if (transporterData.serviceAddress.city) {
					htmlContent = htmlContent.replace(/{{transporter.city}}/g, transporterData.serviceAddress.city);
				}

				if (transporterData.serviceAddress.state) {
					htmlContent = htmlContent.replace(/{{transporter.state}}/g, transporterData.serviceAddress.state);
				}

				if (transporterData.serviceAddress.zipCode) {
					htmlContent = htmlContent.replace(/{{transporter.zip}}/g, transporterData.serviceAddress.zipCode);
				}
			}
			if (data?.customMessage) {
				htmlContent = htmlContent.replace(/{{customMessage}}/g, data.customMessage);
			}

			// Contact information
			if (transporterData.generalEmail) {
				htmlContent = htmlContent.replace(/{{transporter.email}}/g, transporterData.generalEmail);
			}

			if (transporterData.phoneNumber) {
				htmlContent = htmlContent.replace(/{{transporter.phone}}/g, transporterData.phoneNumber);
			}

			if (transporterData.companyLogo) {
				htmlContent = htmlContent.replace(/{{transporter.logo}}/g, transporterData.companyLogo.link);
			}

			const doc = iframeRef.current.contentWindow.document;
			doc.open();
			doc.write(htmlContent);
			doc.close();

			iframeRef.current.onload = adjustIframeHeight;

			setTimeout(adjustIframeHeight, 300);
		}
	}, [data, transporterData]);

	const handlePreviewClick = () => {
		navigate(`email/preview/${id}`);
	};
	const handleSaveClick = async () => {
		if (!user || !user.uid || !id) return;

		try {
			const templateRef = doc(db, COLLECTIONS.emailTemplates, user.uid, "templates", id);

			// Save the custom message along with other data
			await updateDoc(templateRef, {
				customMessage: customMessage
			});

			// Display success message
			toast.success("Custom Message saved successfully!", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

		} catch (error) {
			console.error("Error saving custom message:", error);
			toast.error("Failed to save custom message.");
		}
	};

	return (
		<div className="flex justify-center items-center min-h-screen bg-gray-100">
			<div className="justify-center items-center min-h-screen p-6 w-full max-w-full">
				<div className="mb-6">
					<h2 className="text-xl font-semibold text-gray-900">Email Details</h2>
				</div>
				<div className="w-full bg-white rounded-xl p-6">
					{/* Card */}
					<div className="bg-gray-50 p-6 w-full">
						<h3 className="text-lg font-medium text-gray-900 mb-4">{data?.name ?? "--"}</h3>

						{/* Details Section */}
						<div className="text-gray-700 text-sm">
							<div className="flex flex-wrap mb-3">
								<div className="w-full md:w-1/4 font-medium mb-1 md:mb-0">Type</div>
								<div className="w-full md:pl-12 md:w-3/4 text-gray-600">
									{emailTemplateTypeOptions.find((el) => el.value === data?.type)?.label ?? "--"}
								</div>
							</div>
							<div className="flex flex-wrap mb-3">
								<div className="w-full md:w-1/4 font-medium mb-1 md:mb-0">Trigger</div>
								<div className="w-full md:pl-12 md:w-3/4 text-gray-600">
									{emailTemplateTriggerTypeOptions.find((el) => el.value === data?.trigger)?.label ?? "--"}
								</div>
							</div>
							<div className="flex flex-wrap mb-3">
								<div className="w-full md:w-1/4 font-medium mb-1 md:mb-0">From Name</div>
								<div className="w-full md:pl-12 md:w-3/4 text-gray-600">noreply@octopussaas.com</div>
							</div>
							<div className="flex flex-wrap mb-3">
								<div className="w-full md:w-1/4 font-medium mb-1 md:mb-0">Reply-to Email</div>
								<div className="w-full md:pl-12 md:w-3/4 text-gray-600">--</div>
							</div>
							<div className="flex flex-wrap mb-2">
								<div className="w-full md:w-1/4 font-medium mb-1 md:mb-0">Subject Line</div>
								<div className="w-full md:pl-12 md:w-3/4 text-gray-600">{data?.subject ?? "--"}</div>
							</div>

							{/* Email Body */}
							<div className="flex flex-wrap mb-3">
								<div className="w-full md:w-1/4 font-medium mb-2 md:mb-0">Email Body</div>
								<div className="w-full md:pl-12 md:w-3/4">
									{data?.htmlContent ? (
										<iframe
											ref={iframeRef}
											title="Email Preview"
											className="w-full h-full"
											style={{ minHeight: "800px", border: "none", overflow: "hidden" }}
										/>
									) : (
										<p className="text-gray-600">--</p>
									)}
								</div>
							</div>

							{/* Custom Message */}
							<div className="flex flex-wrap mb-3">
								<div className="w-full md:w-1/4 font-medium mb-2 md:mb-0">Custom Message</div>
								<div className="w-full md:pl-12 md:w-3/4 text-gray-600">
									<textarea
										className="h-16 w-full md:w-3/4 lg:w-2/3 p-4 border border-gray-300 rounded-xl focus:ring-blue-500 focus:border-blue-500"
										value={customMessage}
										onChange={(e) => setCustomMessage(e.target.value)}
									/>
								</div>
							</div>
						</div>

						{/* Buttons */}
						<div className="flex justify-end gap-4 md:gap-8 py-10">
							<button className="text-gray-500" onClick={handlePreviewClick}>
								Preview
							</button>
							<button
								className="bg-blue-600 text-white px-6 md:px-12 py-2 rounded-full shadow-md hover:bg-blue-700"
								onClick={handleSaveClick}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
		</div>
	);
};

export default EmailTemplate;