const notificationOptions = [
	{ label: "Send email on pick-up", value: "EMAIL_ON_PICKUP" },
	{ label: "Send email on drop-off", value: "EMAIL_ON_DROPOFF" },
	{ label: "Send service email", value: "SERVICE_EMAIL" },
	{ label: "Send email for billing and payment receipts", value: "EMAIL_FOR_BILLING_PAYMENT" },
	{ label: "Send email on manifest edit", value: "EMAIL_ON_MANIFEST_CHANGE" },
];

const templateOptions = [
	{ label: "How to log in", value: "howToLogin" },
	{ label: "How to see your service calendar", value: "howToSeeServiceCalendar" },
	{ label: "How to see your online manifests", value: "howToSeeOnlineManifests" },
];

const accessOptions = [
	{ label: "Service", value: "SERVICE" },
	{ label: "Billing", value: "BILLING" },
];

const defaultContactValue = {
	name: "",
	phoneNumbers: [],
	emailAdd: "",
	username: "",
	password: "",
	contactStatus: "ACTIVE",
	urlLink: "",
	notification: [],
	contactNote: "",
	access: ["SERVICE", "BILLING"],
	otherAccess: "",
};

const statusMap = {
	ACTIVE: "Active",
	INACTIVE: "Inactive",
};

const accessOptionsMap = {
	SERVICE: "Service",
	BILLING: "Billing",
};

import React, { useState, useEffect } from "react";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import { BsPlusSquareFill } from "react-icons/bs";
import Input from "../../../../../../../../components/UI/Input";
import MultiSelectRounded from "../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import Button from "../../../../../../../../components/UI/Button";
import PhoneInput from "../../../../../../../../components/UI/PhoneInput";
import { toast } from "react-toastify";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import Dropdown from "../../../../../../../../components/UI/dropdowns/Dropdown";
import PasswordInput from "../../../../../../../../components/UI/PasswordInput";
import { useDispatch } from "react-redux";
import {
	collection,
	doc,
	updateDoc,
	arrayUnion,
	serverTimestamp,
	getDoc,
	setDoc,
	where,
	query,
	getDocs,
} from "firebase/firestore";
import { db } from "../../../../../../../../config/firebase";
import useTUserContext from "../../../../../../../../context/TransporterUserContext";
import { generateMixedPassword } from "../../../../../../../../utils/helpers";

export const checkDuplicateContact = async (email, generatorId, transporterId) => {
	if (!email) {
		return {
			isDuplicate: false,
			duplicateEmail: false,
		};
	}

	const normalizedEmail = email ? email.toLowerCase().trim() : "";

	try {
		const identifiersToCheck = [...(normalizedEmail ? [normalizedEmail] : [])];

		if (identifiersToCheck.length === 0) {
			return {
				isDuplicate: false,
				duplicateEmail: false,
			};
		}

		const duplicateQuery = query(
			collection(db, "generatorContacts"),
			where("transporterId", "==", transporterId),
			where("normalizedIdentifiers", "array-contains-any", identifiersToCheck)
		);

		const querySnapshot = await getDocs(duplicateQuery);

		if (querySnapshot.empty) {
			console.log("No snapshot found");

			return {
				isDuplicate: false,
				duplicateEmail: false,
			};
		} else {
			console.log("Snapshot found");
		}

		let duplicates = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));

		duplicates = duplicates.filter((item) => item.generatorId !== generatorId);
		const duplicateEmail = duplicates.find((contact) => normalizedEmail && contact.normalizedEmail === normalizedEmail);

		console.log({ duplicateEmail });

		return {
			isDuplicate: !!duplicateEmail,
			duplicateEmail: !!duplicateEmail,
			...(duplicateEmail && { duplicateEmailContact: duplicateEmail }),
		};
	} catch (error) {
		console.error("Error checking for duplicates:", error);
		throw error;
	}
};

export const addContacts = async (contactData, generatorId, transporterId) => {
	const { emailAdd, ...otherData } = contactData;
	const normalizedEmail = emailAdd.toLowerCase().trim();

	try {
		const contactDoc = {
			emailAdd,
			...otherData,
			normalizedEmail,
			normalizedIdentifiers: [normalizedEmail],
			generatorId,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
			contactStatus: "ACTIVE",
			transporterId,
		};

		const generatorContactsRef = doc(collection(db, "generatorContacts"));
		await setDoc(generatorContactsRef, contactDoc);

		const generatorRef = doc(db, "generators", generatorId);
		await updateDoc(generatorRef, {
			contacts: arrayUnion(generatorContactsRef),
		});
		return { id: generatorContactsRef.id, ...contactDoc };
	} catch (error) {
		console.error("Error adding contact:", error);
		throw error;
	}
};

export const updateContact = async (contactId, updates, generatorId, transporterId) => {
	toast.loading("Updating...");
	console.log({ updates, contactId });
	const { emailAdd, ...otherUpdates } = updates;
	const toUpdate = {
		...otherUpdates,
		updatedAt: serverTimestamp(),
		transporterId,
	};

	try {
		if (emailAdd) {
			const duplicateCheck = await checkDuplicateContact(emailAdd || "", generatorId, transporterId);

			if (duplicateCheck.duplicateEmail) {
				if (!duplicateCheck.duplicateEmailContact || duplicateCheck.duplicateEmailContact.id !== contactId) {
					toast.dismiss();
					toast.error(`Email ${emailAdd} is already registered with this transporter.`);
					return { success: false };
				}
			}
		}
		const normalizedEmail = (emailAdd ?? "").toLowerCase().trim();
		toUpdate.emailAdd = emailAdd;
		toUpdate.normalizedEmail = normalizedEmail;
		toUpdate.normalizedIdentifiers = [normalizedEmail];

		const contactRef = doc(db, "generatorContacts", contactId);
		await updateDoc(contactRef, toUpdate);
		toast.dismiss();
		toast.success("Updated successfully!");
		return { success: true };
	} catch (error) {
		toast.dismiss();
		toast.error("Couldn't update, please try after sometime!");
		console.error("Error updating contact:", error);
		throw error;
	}
};

const PhoneNumberChip = ({ number, onRemove }) => (
	<div className="inline-flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2">
		<span>{number}</span>
		<button onClick={() => onRemove(number)} type="button" className="ml-2 font-bold">
			&times;
		</button>
	</div>
);

const GeneratorContacts = ({ onNextClick, onClickBack, generatorData = {} }) => {
	const dispatch = useDispatch();
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		trigger,
		formState: { errors },
	} = useForm({
		defaultValues: {
			currentContact: defaultContactValue,
		},
	});

	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneError, setPhoneError] = useState("");
	const [selectedTemplates, setSelectedTemplates] = useState();
	const [isEditing, setIsEditing] = useState(false);
	const [allContacts, setAllContacts] = useState([]);
	const [editingContactId, setEditingContactId] = useState(null);
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "contacts",
	});
	const { user, loading } = useTUserContext();
	const watchPhoneNumbers = watch("currentContact.phoneNumbers");

	useEffect(() => {
		const fetchContactDetails = async () => {
			const generatorRef = doc(db, "generators", generatorData.id);
			const genDoc = await getDoc(generatorRef);
			let result = genDoc.data();
			if (result?.contacts && result.contacts.length > 0) {
				try {
					const contactPromises = result.contacts.map(async (contactRef) => {
						const contactDoc = await getDoc(contactRef);
						if (contactDoc.exists()) {
							return { contactId: contactDoc.id, ...contactDoc.data() };
						}
						return null;
					});

					const contactDetails = await Promise.all(contactPromises);
					const validContacts = contactDetails.filter((contact) => contact !== null);
					console.log({ validContacts });

					setAllContacts(validContacts);
					setValue("contacts", validContacts);
				} catch (error) {
					console.error("Error fetching contact details:", error);
				}
			}
		};

		fetchContactDetails();
	}, [generatorData, setValue, watchPhoneNumbers]);

	useEffect(() => {
		setValue("currentContact.password", generateMixedPassword());
	}, []);

	const handleContactEdit = (contactId) => {
		const contactToEdit = fields.find((contact) => contact.contactId === contactId);
		if (contactToEdit) {
			setIsEditing(true);
			setEditingContactId(contactId);
			setValue("currentContact", {
				...contactToEdit,
				contactId: contactId,
				notification: contactToEdit.notification || [],
				access: contactToEdit.access || [],
				phoneNumbers: contactToEdit.phoneNumbers || [],
			});

			setValue("currentContact.access", contactToEdit.access);
			setValue("currentContact.contactStatus", contactToEdit.contactStatus);
			setValue("currentContact.notification", contactToEdit.notification);

			let container = document.getElementById("scroll-container");
			console.log("Scrolling the container - ", container);
			container.scrollTo({ top: 0, behavior: "smooth" });
		}
	};

	const updateTheContact = async () => {
		const currentContact = getValues("currentContact");
		const contactIdToUpdate = editingContactId || currentContact.contactId;

		if (!contactIdToUpdate) {
			toast.error("No contact selected for update");
			return;
		}

		const isFormValid = await trigger("currentContact");
		if (!isFormValid) {
			toast.error("Please fill out all required fields correctly.");
			return;
		}
		const isNameValid = await trigger("currentContact.name");
		if (!isNameValid) {
			toast.error("Please fill out all required fields correctly.");
			return;
		}

		toast.loading("Updating contact...");

		const contactToUpdate = {
			...currentContact,
			contactId: contactIdToUpdate,
		};
		if (phoneNumber?.trim()?.length > 0) {
			let prevPhones = getValues("currentContact.phoneNumbers");
			const phoneNumberDigit = phoneNumber.replace(/\D/g, "");
			if (phoneNumberDigit.length != 10) {
				setPhoneError("Phone number length should be 10");
				toast.dismiss();
				toast.error("Phone number length should be 10");
				return;
			}
			trigger("currentContact.phoneNumbers");
			contactToUpdate.phoneNumbers = [...prevPhones, phoneNumber];
		}

		try {
			await updateContact(contactIdToUpdate, contactToUpdate, generatorData.id, user.uid);
			// const contactRef = doc(db, "generatorContacts", contactIdToUpdate);
			// await updateDoc(contactRef, {
			// 	...contactToUpdate,
			// 	updatedAt: serverTimestamp(),
			// });

			const contactIndex = fields.findIndex((c) => c.contactId === contactIdToUpdate);
			if (contactIndex !== -1) {
				update(contactIndex, contactToUpdate);
			}

			toast.dismiss();
			toast.success("Contact updated successfully!");
			setIsEditing(false);
			setEditingContactId(null);
			reset({ currentContact: defaultContactValue });
			setPhoneNumber("");
			setSelectedTemplates([]);
			setValue("currentContact.password", generateMixedPassword());
		} catch (error) {
			console.error("Error updating contact:", error);
			toast.dismiss();
			toast.error("Failed to update contact");
		}
	};

	const resetForm = () => {
		setIsEditing(false);
		setEditingContactId(null);
		reset({ currentContact: defaultContactValue });
		setPhoneNumber("");
		setValue("currentContact.password", generateMixedPassword());
	};

	const addPhoneNumber = () => {
		if (phoneNumber?.trim()?.length > 0) {
			const phoneNumberDigit = phoneNumber.replace(/\D/g, "");
			if (phoneNumberDigit.length != 10) {
				setPhoneError("Phone number length should be 10");
				toast.error("Phone number length should be 10");
				return;
			}
			let prevPhones = getValues("currentContact.phoneNumbers");
			setValue("currentContact.phoneNumbers", [...prevPhones, phoneNumber]);
			setPhoneNumber("");
		}
	};

	const removePhoneNumber = (numberToRemove) => {
		const updatedPhoneNumbers = getValues("currentContact.phoneNumbers").filter((number) => number !== numberToRemove);
		setValue("currentContact.phoneNumbers", updatedPhoneNumbers);
	};

	const addContact = async () => {
		const isFormValid = await trigger("currentContact");
		if (!isFormValid) {
			toast.error("Please fill out all required fields correctly.");
			return;
		}
		const isNameValid = await trigger("currentContact.name");
		if (!isNameValid) {
			toast.error("Please fill out all required fields correctly.");
			return;
		}
		const currentContact = getValues("currentContact");
		const hasData = Object.values(currentContact).some((value) => {
			if (Array.isArray(value)) {
				return value.length > 0;
			}
			return value !== "" && value !== null && value !== undefined;
		});
		toast.loading("Adding contact...");

		if (hasData) {
			const existingContacts = fields || [];
			if (currentContact.emailAdd) {
				const isEmailInCurrentList = existingContacts.some(
					(contact) => contact.emailAdd?.toLowerCase() === currentContact.emailAdd.toLowerCase()
				);
				if (isEmailInCurrentList) {
					toast.dismiss();
					toast.error(`Email ${currentContact.emailAdd} is already added to contacts`);
					return;
				}
			}
			if (currentContact.username) {
				const isUsernameInCurrentList = existingContacts.some(
					(contact) => contact.username?.toLowerCase() === currentContact.username.toLowerCase()
				);
				if (isUsernameInCurrentList) {
					toast.dismiss();
					toast.error(`Username ${currentContact.username} is already added to contacts`);
					return;
				}
			}

			try {
				const duplicateCheck = await checkDuplicateContact(currentContact.emailAdd, generatorData.id, user.uid);
				if (duplicateCheck.duplicateEmail) {
					toast.dismiss();
					return toast.error(`Email ${currentContact.emailAdd} is already registered with this transporter.`);
				}
				const phoneNumberDigit = phoneNumber.replace(/\D/g, "");
				console.log({ phoneNumberDigit, phoneNumber });
				if (phoneNumberDigit?.trim()?.length > 0) {
					const phoneNumberDigit = phoneNumber.replace(/\D/g, "");
					if (phoneNumberDigit.length != 10) {
						setPhoneError("Phone number length should be 10");
						toast.dismiss();
						toast.error("Phone number length should be 10");
						return;
					}
					let prevPhones = getValues("currentContact.phoneNumbers");
					currentContact.phoneNumbers = [...prevPhones, phoneNumber];
				}
				let resp = await addContacts(currentContact, generatorData.id, user.uid);
				console.log({ resp });
				toast.dismiss();
				toast.success("Added successfully");
				append(currentContact);
				setPhoneNumber("");
				setValue("currentContact", defaultContactValue);
				setValue("currentContact.notification", []);
				setValue("currentContact.password", generateMixedPassword());
				setSelectedTemplates([]);
			} catch (error) {
				toast.dismiss();
				toast.error("Couldn't add right now, please try again later");
			}
		} else {
			toast.dismiss();
			toast.warning("Please fill at least one field to add a contact");
		}
	};

	const onSubmitForm = async (e) => {
		e.preventDefault();
		toast.loading("Saving..");

		try {
			let allContacts = fields?.filter((contact) => {
				return Object.values(contact).some((value) => {
					if (Array.isArray(value)) {
						return value.length > 0;
					}
					return value !== "" && value !== null && value !== undefined;
				});
			});

			const currentContact = { ...getValues("currentContact") };

			currentContact.access = [];
			currentContact.contactStatus = "";
			let status = currentContact.contactStatus;
			let password = currentContact.password;
			currentContact.password = "";

			const hasData = Object.values(currentContact).some((value) => {
				if (Array.isArray(value)) {
					return value.length > 0;
				}
				return value !== "" && value !== null && value !== undefined;
			});

			console.log({ hasData, currentContact, fromContact: getValues("currentContact") });

			if (hasData && !isEditing) {
				const isNameValid = await trigger("currentContact.name");
				if (!isNameValid) {
					toast.dismiss();
					toast.error("Please fill out all required fields correctly.");
					return;
				}
				if (phoneNumber?.trim()?.length > 0) {
					const phoneNumberDigit = phoneNumber.replace(/\D/g, "");
					if (phoneNumberDigit.length != 10) {
						setPhoneError("Phone number length should be 10");
						toast.dismiss();
						toast.error("Phone number length should be 10");
						return;
					}
					let prevPhones = getValues("currentContact.phoneNumbers");
					currentContact.phoneNumbers = [...prevPhones, phoneNumber];
				}
				let access = getValues("currentContact.access");
				currentContact.access = access;
				currentContact.contactStatus = status;
				currentContact.password = password;
				console.log({ access });

				allContacts.push(currentContact);
			}

			const emailsMap = new Map();

			for (const contact of allContacts) {
				if (contact.emailAdd) {
					const emailAdd = contact.emailAdd?.toLowerCase();
					if (emailsMap.has(emailAdd)) {
						toast.dismiss();
						toast.error(`Duplicate email found within contacts: ${contact.emailAdd}`);
						return;
					}
					emailsMap.set(emailAdd, true);

					try {
						const duplicateCheck = await checkDuplicateContact(contact.emailAdd ?? "", generatorData.id, user.uid);

						if (duplicateCheck.duplicateEmail) {
							toast.dismiss();
							return toast.error(`Email ${contact.emailAdd} is already registered with this transporter.`);
						}
					} catch (error) {
						toast.dismiss();
						console.error("Error checking username:", error);
						toast.error("Error checking username uniqueness");
						return;
					}
				}
			}

			let dataToSent = {
				id: generatorData.id,
				contacts: allContacts,
			};

			if (!generatorData.onboardingStatus) {
				dataToSent.onboardingStatus = {};
			} else {
				dataToSent.onboardingStatus = generatorData.onboardingStatus;
			}

			dataToSent.onboardingStatus.registrationCompleted = true;
			dataToSent.onboardingStatus.profileCompleted = true;
			dataToSent.onboardingStatus.contactsCompleted = true;

			try {
				const contactUpdates = allContacts.map(async (contact) => {
					if (contact.contactId) {
						const contactRef = doc(db, "generatorContacts", contact.contactId);
						await updateDoc(contactRef, {
							...contact,
							updatedAt: serverTimestamp(),
							transporterId: user.uid,
						});
						return contactRef.id;
					} else {
						const newContactRef = doc(collection(db, "generatorContacts"));
						await setDoc(newContactRef, {
							...contact,
							contactId: newContactRef.id,
							generatorId: generatorData.id,
							normalizedEmail: contact.emailAdd?.toLowerCase().trim(),
							normalizedUsername: contact.username?.toLowerCase().trim(),
							createdAt: serverTimestamp(),
							updatedAt: serverTimestamp(),
							contactStatus: "ACTIVE",
							transporterId: user.uid,
						});
						return newContactRef.id;
					}
				});

				console.log({ allContacts, contactUpdates });

				let resp = await Promise.all(contactUpdates);

				const generatorRef = doc(db, "generators", generatorData.id);
				await updateDoc(generatorRef, {
					contacts: resp.map((contact) => doc(db, "generatorContacts", contact)),
				});

				toast.dismiss();
				toast.success("Saved successfully!");
				onNextClick();
			} catch (error) {
				console.error("Error saving data:", error);
				toast.dismiss();
				toast.error("Error saving data");
			}
		} catch (error) {
			toast.dismiss();
			console.error("Error saving data:", error);
		}
	};

	const sendEmailToGenerator = async () => {
		let email = getValues("currentContact.emailAdd");
		let username = getValues("currentContact.username");
		let password = getValues("currentContact.password");
		console.log({ email, selectedTemplates });

		try {
			// let resp = await sendEmailTemplates(email, selectedTemplates, { username, password });
			toast.success("Email sent successsfully!");
		} catch (error) {
			toast.error("Something went wrong!");
		}
	};

	return (
		<div className="w-full z-0 bg-white py-6 gap-4 mb-8 rounded-cardRadii flex flex-col">
			<form onSubmit={onSubmitForm} className="px-8">
				<GeneratorInfoHeader generatorData={generatorData} />
				<div className="py-2 text-lg flex justify-between items-center border-b mb-2 border-[#CCCCCC]">
					<h6 className="font-medium">{isEditing ? "Edit Contact" : "Generator Contacts (People)"}</h6>
				</div>
				<div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full">
					<div className="w-full md:w-1/2 space-y-4">
						<Controller
							name="currentContact.name"
							rules={{ required: "Contact Name is required" }}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input label="Contact Name" value={value} onChange={onChange} id="name-input" isRequired={true} />
							)}
						/>
						{errors?.currentContact?.name && (
							<p className="text-red-500 text-sm mt-1">{errors.currentContact.name.message}</p>
						)}
						<div>
							<PhoneInput
								label="Phone Number"
								type="multi"
								onClick={addPhoneNumber}
								value={phoneNumber}
								onChange={(value) => {
									setPhoneNumber(value);
									if (!value) {
										return setPhoneError("");
									}
									const phoneNumberDigit = value.replace(/\D/g, "");
									if (phoneNumberDigit.length != 10) {
										setPhoneError("Phone number length should be 10");
									} else setPhoneError("");
								}}
							/>
							{phoneError && <p className="text-red-500 text-sm mt-1">{phoneError}</p>}
							<div className="mt-2">
								{[...new Set(getValues("currentContact.phoneNumbers"))]?.map((number, index) => (
									<PhoneNumberChip key={index} number={number} onRemove={removePhoneNumber} />
								))}
							</div>
						</div>
						<Controller
							name="currentContact.emailAdd"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									label="Email / Username"
									value={value}
									onChange={(e) => {
										onChange(e);
										setValue("currentContact.username", e.target.value);
									}}
									id="emailAdd-input"
								/>
							)}
						/>
						<Controller
							name="currentContact.password"
							control={control}
							render={({ field: { onChange, value } }) => (
								<PasswordInput label="Password" value={value} onChange={onChange} id="password-input" />
							)}
						/>
						<MultiSelectRounded
							label="Emails"
							options={templateOptions}
							value={selectedTemplates}
							onChange={(value) => setSelectedTemplates(value)}
						/>
						<button className="rounded-full w-40 py-2 bg-[#e3f0ff]" onClick={sendEmailToGenerator} type="button">
							Send
						</button>
					</div>
					<div className="w-full md:w-1/2 space-y-4">
						<Controller
							name="currentContact.urlLink"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input label="URL Link" type="url" value={value} onChange={onChange} id="urlLink-input" />
							)}
						/>
						<Controller
							name="currentContact.contactStatus"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Dropdown
									label="Contact Status"
									options={[
										{ label: "Active", value: "ACTIVE" },
										{ label: "Inactive", value: "INACTIVE" },
									]}
									value={value}
									onChange={onChange}
									isRequired={true}
								/>
							)}
						/>
						<Controller
							name="currentContact.notification"
							control={control}
							render={({ field: { value, onChange } }) => (
								<MultiSelectRounded
									value={value}
									onChange={onChange}
									options={notificationOptions}
									label="Notification"
									id="notification-input"
								/>
							)}
						/>
						<Controller
							name="currentContact.contactNote"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input label="Contact Note" value={value} onChange={onChange} id="contactNote-input" />
							)}
						/>
						<Controller
							name="currentContact.access"
							control={control}
							render={({ field: { value, onChange } }) => (
								<MultiSelectRounded
									value={value}
									onChange={onChange}
									options={accessOptions}
									label="Access"
									id="access-input"
								/>
							)}
						/>
					</div>
				</div>
				<div className="flex justify-end mt-4">
					{isEditing ? (
						<div className="flex gap-4">
							<button
								onClick={resetForm}
								type="button"
								style={{ width: "124px", height: "30px" }}
								className="hover:bg-cardTextGray hover:bg-opacity-10 text-xs rounded-full border border-cardTextGray bg-white text-black transition-all duration-200"
							>
								Cancel
							</button>
							<button
								onClick={updateTheContact}
								type="button"
								style={{ width: "124px", height: "30px" }}
								className="bg-cardTextBlue text-white text-xs rounded-full border border-cardTextGray hover:bg-opacity-90 transition-all duration-200"
							>
								Update
							</button>
						</div>
					) : (
						<button
							type="button"
							className="flex gap-2 items-center hover:text-cardTextBlue text-dashInActiveBtnText rounded-full"
							onClick={addContact}
						>
							<BsPlusSquareFill className="text-cardTextBlue rounded-[4px]" size={22} />
							<p className="text-sm">Add</p>
						</button>
					)}
				</div>
				<div className="flex justify-end gap-8 py-6">
					<Button btnStyle="form_nav_secondary" text={"Back"} onClick={onClickBack} />
					<Button btnStyle="form_nav_primary" text="Save & Next" onClick={onSubmitForm} />
				</div>
			</form>
			<div className="py-2">
				<h6 className="font-medium text-lg px-8 mb-4">Generator Contact List</h6>
				{allContacts.length > 0 ? (
					<div className="flex flex-col">
						<div className="grid grid-cols-5 bg-whiteBlue p-4 px-8 font-medium">
							<div className="flex-1">Contact/Department Name</div>
							<div className="flex-1">Phone Number</div>
							<div className="flex-1">Email Address</div>
							<div className="flex-1">Contact Status</div>
							<div className="flex-1">Access</div>
						</div>
						{allContacts.map((contact) => (
							<div
								key={contact.contactId}
								className="flex p-4 text-cardTextGray border-b px-8 cursor-pointer hover:bg-cardTextGray hover:bg-opacity-20"
								onClick={() => handleContactEdit(contact.contactId)}
							>
								<div className="flex-1">{contact.name}</div>
								<div className="flex-1">
									{Array.isArray(contact.phoneNumbers) && [...new Set(contact.phoneNumbers)]?.join(", ")}
								</div>
								<div className="flex-1">{contact.emailAdd}</div>
								<div className="flex-1">{statusMap[contact.contactStatus]}</div>
								<div className="flex-1">{contact.access?.map((item) => accessOptionsMap[item])?.join(", ")}</div>
							</div>
						))}
					</div>
				) : (
					<p className="px-8 text-cardTextGray">No contacts added yet.</p>
				)}
			</div>
		</div>
	);
};

export default GeneratorContacts;
