import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../config/firebase";

const ServiceLocationList = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [subContractor, setSubContractor] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [loading, setLoading] = useState(true);
	const [generatorData, setGeneratorData] = useState([]);
	const navigate = useNavigate();

	const fetchGensData = async (generatorIds) => {
		if (Array.isArray(generatorIds)) {
			setIsLoading(true);
			try {
				// Check if the array is empty to prevent unnecessary query
				if (generatorIds.length === 0) {
					return [];
				}

				console.log("Going to query the generators from the firestore");

				// Create a query to fetch documents where the ID is in the array
				const q = query(collection(db, "generators"), where("__name__", "in", generatorIds));

				// Execute the query
				const querySnapshot = await getDocs(q);

				// Map the results to an array of generator documents
				const generators = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				console.log({ generators });

				setGeneratorData(generators);
			} catch (error) {
				console.error("Error fetching generators:", error);
				return [];
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		let gens = JSON.parse(localStorage.getItem("gens"));
		let subContractor = localStorage.getItem("subContractor");
		setSubContractor(subContractor);
		if (!gens || !gens.length) {
			return navigate("/admin/octo-connect/subcontractor-management");
		}

		fetchGensData(gens);
	}, []);

	console.log({ subContractor });
	const dropdownOptions = ["Listed", "Unlisted"];

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/subcontractor-management");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				{/* <div className="mt-6"> */}
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 mb-2 text-black">Service Locations</div>

				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Service Location</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-60">Status</div>
						</div>
					</div>
					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : generatorData.length > 0 ? (
							generatorData.map((generator, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < generatorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-80 h-full">{formatAdd(generator.serviceAddress) ?? "--"}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.generatorName ?? "--"}</div>
										<div className="truncate w-80 h-full">{subContractor ?? "--"}</div>
										<div className="truncate h-full w-60">{generator.status ?? "Pending"}</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* </div> */}
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 mb-12 mt-12">
				<button
					onClick={handlePreviousBtn}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Previous
				</button>
				{/* <button
					onClick={handleNextClick}
					style={{ width: "184px", height: "44px" }}
					className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
				>
					Next
				</button> */}
			</div>
		</div>
	);
};

export default ServiceLocationList;

let formatAdd = (data) => {
	let formattedAdd = "";
	data.street?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${data.street}`)
		: (formattedAdd += data.street);
		
	data.suite?.trim()?.length ? (formattedAdd += data.suite) : (formattedAdd = "");
	
	data.city?.trim()?.length && formattedAdd?.length ? (formattedAdd += `, ${data.city}`) : (formattedAdd += data.city);
	data.state?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${data.state}`)
		: (formattedAdd += data.state);
	data.zipCode?.trim()?.length && formattedAdd?.length
		? (formattedAdd += ` ${data.zipCode}`)
		: (formattedAdd += data.zipCode);

	return formattedAdd.length ? formattedAdd : "--";
};
