// flatpickr;
// const f = flatpickr(dobRef.current, {
// 	altInput: true,
// 	altFormat: "F j, Y",
// 	dateFormat: "Y-m-d",
// 	maxDate,
// 	defaultDate: BASE_DATE,
// });

// dobFiledFlatpickr.current = f;

import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	convertISODateStringToFormattedDate,
	generateUTCDate,
	randomizeCoordinates,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
	limitScrollWheelZoom,
	isUTC,
} from "../../../../../../utils/helpers";
import { Map, useMap } from "@vis.gl/react-google-maps";
import {
	collection,
	doc,
	getDoc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	Timestamp,
	updateDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../../../../../../config/firebase";
import RouteColumn from "./RouteColumn";
import MultiselectDropdown from "../../../../../../components/UI/dropdowns/MultiselectDropdown";
import CustomDatePicker from "../../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../../components/UI/loaders/Loader";
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	pointerWithin,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
	ITEM_TYPE_MAP,
	LOCATION_TYPE_HOME_BASE,
	scheduledServiceStatus,
	SERVICE_TYPES,
	serviceTypes,
} from "../../../../../../utils/constants";
import { createPortal } from "react-dom";
import ServiceCard from "./ServiceCard";
import {
	getAllSatelliteLocationSnapshot,
	getAllTreatmentsLocationSnapshot,
	getAllVendorsLocationSnapshot,
} from "../../../../../../utils/firebaseOperations";
import WaypointMarkers from "./WaypointMarkers";
import RoundedPin from "./RoundedPin";
import SquarePin from "./SquarePin";
import Input from "../../../../../../components/UI/Input";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import { DateObject } from "react-multi-date-picker";
import CustomMultiDateRangePicker from "../../../../../../components/UI/CustomMultiDateRangePicker";
import { useAuthState } from "react-firebase-hooks/auth";
import { set } from "date-fns";
import { AuthenticationType, AzureMap, AzureMapsContext } from "react-azure-maps";
import { data, HtmlMarker } from "azure-maps-control";
import CustomAzureWayPointMarker from "../../../../../../components/maps/CustomAzureWayPointMarker";
import CustomAzureRoute from "../../../../../../components/maps/CustomAzureRoute";
import useTUserContext from "../../../../../../context/TransporterUserContext";
import MultiSelectRounded from "../../../../../../components/UI/dropdowns/MultiSelectRounded";
import { FaMinus, FaPlus } from "react-icons/fa";
import { LegendToggleRounded } from "@mui/icons-material";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

const authOptions = {
	authType: AuthenticationType.subscriptionKey,
	subscriptionKey: import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
};
const RoutesOptimization = () => {
	const [routes, setRoutes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [searchParams] = useSearchParams();
	const [to, setTo] = useState(() => {
		const dateParam = searchParams.get("endDate") || searchParams.get("date");
		if (dateParam) {
			const currentDate = new Date(dateParam);
			console.log(currentDate.toDateString(), currentDate.toISOString());
			console.log(currentDate.getDate(), currentDate.getUTCDate());
			console.log(currentDate.getMonth(), currentDate.getUTCMonth());
			console.log(currentDate.getFullYear(), currentDate.getUTCFullYear());
			if (currentDate.getDate() !== currentDate.getUTCDate()) {
				return new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
			}
			return currentDate;
		}
		return new Date();
	});
	const [from, setFrom] = useState(() => {
		const dateParam = searchParams.get("startDate") || searchParams.get("date");
		if (dateParam) {
			const currentDate = new Date(dateParam);
			if (currentDate.getDate() !== currentDate.getUTCDate()) {
				return new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
			}
			return currentDate;
		}
		return new Date();
	});
	const [selected, setSelected] = useState([]);
	const [routeColumns, setRouteColumns] = useState([]);
	const [prevRouteColumns, setPrevRouteColumns] = useState([]);
	const [routeUpdatingIds, setRouteUpdatingIds] = useState([]);
	const [activeRouteColumn, setActiveRouteColumn] = useState(null);
	const [activeServiceCard, setActiveServiceCard] = useState(null);
	const [locations, setLocations] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [vendorMarkers, setVendorMarkers] = useState([]);
	const [treatmentFacilities, setTreatmentFacilities] = useState([]);
	const [satelliteLocations, setSatelliteLocations] = useState([]);
	const [transporterData, setTransporterData] = useState(null);
	const [drivers, setDrivers] = useState([]);
	const [currentRouteSplitId, setCurrentRouteSplitId] = useState(null);
	const [routeSplits, setRouteSplits] = useState([]);
	const [noOfSplit, setNoOfSplit] = useState(0);
	const { user } = useTUserContext();
	const { isMapReady, mapRef, removeMapRef } = useContext(AzureMapsContext);
	const [currentRouteChangeId, setCurrentRouteChangeId] = useState("");
	const [itemsOptions, setItemsOptions] = useState([]);
	const [itemsMap, setItemsMap] = useState({});

	useEffect(
		() => () => {
			removeMapRef();
		},
		[]
	);
	useEffect(() => {
		let unsubscribe = onSnapshot(collection(db, COLLECTIONS.defaultPriceBook, "services", "containers"), (snap) => {
			if (snap.docs.length) {
				let tempOptions = [];
				let tempMap = {};
				snap.docs.forEach((el) => {
					tempOptions.push({ label: el.data()?.masterItemName ?? "--", value: el.id });
					tempMap[el.id] = el.data()?.masterItemName ?? "--";
				});
				setItemsOptions(tempOptions);
				setItemsMap(tempMap);
			}
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);
	useEffect(() => {
		if (isMapReady && mapRef && routeColumns.length > 0) {
			const markerPositions = [];
			routeColumns.forEach((route) => {
				route.waypoints.forEach((wp) => {
					markerPositions.push(new data.Position(wp.lng, wp.lat));
					markerPositions.push(new data.Position(wp.randomCoordinates.lng, wp.randomCoordinates.lat));
				});
			});
			const box = data.BoundingBox.fromPositions(markerPositions);

			mapRef.setCamera({ bounds: box, padding: 100, duration: 200, type: "ease" });
			limitScrollWheelZoom(mapRef);
		}
	}, [isMapReady, mapRef, routeColumns]);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 3,
			},
		}),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
	);
	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.drivers),
				where("driverStatus", "==", "active"),
				where("transporterId", "==", user?.uid)
			),
			(snap) => {
				const tempDrivers = [];
				snap.docs.forEach((el) => {
					if (!el.exists()) return;
					const driverData = el.data();
					driverData.id = el.id;
					tempDrivers.push(driverData);
				});
				setDrivers(tempDrivers);
			}
		);

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, [user]);
	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.vendors), where("transporterId", "==", user?.uid)),
			(snap) => {
				const tempVendorData = [];
				snap.docs.forEach((vendor) => {
					if (vendor.exists() && vendor.data().vendorCoordinates) {
						tempVendorData.push({
							...vendor.data(),
							id: vendor.id,
						});
					}
				});
				setVendorMarkers(tempVendorData);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.treatments), where("transporterId", "==", user?.uid)),
			(snap) => {
				const tempTreatmentData = [];
				snap.docs.forEach((treatment) => {
					if (treatment.exists() && treatment.data().treatmentCoordinates) {
						tempTreatmentData.push({
							...treatment.data(),
							id: treatment.id,
						});
					}
				});

				setTreatmentFacilities(tempTreatmentData);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);
	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.satelliteLocation), where("transporterId", "==", user?.uid)),
			(snap) => {
				const tempSatelliteData = [];
				snap.docs.forEach((satellite) => {
					if (satellite.exists() && satellite.data().satelliteServiceCoordinates) {
						tempSatelliteData.push({
							...satellite.data(),
							id: satellite.id,
						});
					}
				});
				setSatelliteLocations(tempSatelliteData);
			}
		);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);
	useEffect(() => {
		if (!user) return;
		const unsubscribe = onSnapshot(doc(db, COLLECTIONS.transporters, user?.uid), (snap) => {
			if (snap?.exists()) {
				setTransporterData({ ...snap.data(), id: snap.id });
			}
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user]);
	useEffect(() => {
		const options = [];

		if (
			transporterData &&
			transporterData?.transporterServiceCoordinates?.lat &&
			transporterData?.transporterServiceCoordinates?.lng
		) {
			options.push({ label: "Main Location", value: null, isDisabled: true, isHeader: true });
			options.push({ label: transporterData?.companyDisplayName ?? "", value: LOCATION_TYPE_HOME_BASE });
		}
		if (satelliteLocations.length > 0) {
			options.push({ label: "Satellite Locations", value: null, isDisabled: true, isHeader: true });
			satelliteLocations.forEach((satellite) => options.push({ label: satellite.name, value: satellite.id }));
		}
		// if (vendorMarkers.length > 0) {
		// 	vendorMarkers.forEach((vendor) => options.push({ label: vendor.name, value: vendor.id }));
		// }
		if (treatmentFacilities.length > 0) {
			options.push({ label: "Disposal Facilities", value: null, isDisabled: true, isHeader: true });
			treatmentFacilities.forEach((treatment) => options.push({ label: treatment.name, value: treatment.id }));
		}
		setLocations(options);
		setSelectedLocations(["all", ...options.map((el) => el.value)]);
	}, [satelliteLocations, vendorMarkers, treatmentFacilities, transporterData]);

	useEffect(() => {
		if (!to || !from) return;
		const dailyRouteIds = [];
		const utcToDate = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 0, 0, 0, 0));
		const utcFromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0));
		while (utcFromDate <= utcToDate) {
			dailyRouteIds.push(utcFromDate.toISOString());
			utcFromDate.setDate(utcFromDate.getDate() + 1);
		}
		if (!selectedRoutes.length) {
			setRouteColumns([]);
			return;
		}
		if (!dailyRouteIds.length) {
			setRouteColumns([]);
			return;
		}
		updateRouteColumns(dailyRouteIds, selectedRoutes);
	}, [selectedRoutes, to, from, routes, selectedLocations]);

	const updateRouteColumns = async (dailyRouteIds, selectedRoutes) => {
		const temp = [];
		try {
			console.log("Fetching Route Columns");

			setLoading(true);
			const promises = selectedRoutes.map((selectedRouteId) => {
				return dailyRouteIds.map(async (dailyId) => {
					const alreadyExist = routeColumns.find((col) => col.id === selectedRouteId && col.dailyRouteId === dailyId);
					if (!alreadyExist) {
						const routeData = routes.find((route) => route.id === selectedRouteId);
						const dailyRouteRes = await getDoc(
							doc(db, COLLECTIONS.routes, selectedRouteId, COLLECTIONS.dailyRoutes, dailyId)
						);

						if (dailyRouteRes.exists() && routeData) {
							const dailyRouteData = dailyRouteRes.data();
							const defaultEndLocation = dailyRouteData?.defaultEndLocation ?? {};
							const defaultStartLocation = dailyRouteData?.defaultStartLocation ?? {};
							const filteredSelectedLocations = selectedLocations.filter((el) => el !== "all");
							dailyRouteData.id = dailyRouteRes.id;
							let waypoints = dailyRouteData.waypoints;
							if (
								filteredSelectedLocations.includes(defaultEndLocation.id) ||
								filteredSelectedLocations.includes(defaultStartLocation.id)
							) {
								if (waypoints?.length) {
									const waypointPromises = waypoints.map(async (wp) => {
										const generatorRes = await getDoc(doc(db, COLLECTIONS.generators, wp.id));
										if (generatorRes.exists()) {
											wp.generatorData = { ...generatorRes.data(), id: generatorRes.id };
										} else {
											wp.generatorData = null;
										}
										if (wp.serviceId) {
											const serviceRes = await getDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId));
											if (serviceRes.exists()) {
												wp.serviceData = { ...serviceRes.data(), id: serviceRes.id };
											} else {
												wp.serviceData = null;
											}
										}

										if (wp?.serviceData?.serviceScheduleId) {
											const scheduleRes = await getDoc(
												doc(db, COLLECTIONS.serviceSchedules, wp.serviceData.serviceScheduleId)
											);
											if (scheduleRes.exists()) {
												wp.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
											} else {
												wp.scheduleData = null;
											}
										}
										wp.randomCoordinates = randomizeCoordinates(wp.lat, wp.lng);
									});
									await Promise.all(waypointPromises);
								}
								waypoints = waypoints.filter(
									(el) => el.serviceData !== null && el.scheduleData !== null && el.generatorData !== null
								);
								waypoints = waypoints.filter((el) => el?.serviceData.status !== scheduledServiceStatus.DELETED);
								temp.push({
									routeId: selectedRouteId,
									dailyRouteId: dailyId,
									...routeData,
									...dailyRouteData,
									assignedDriverName: dailyRouteData?.assignedDriverName ?? "",
									assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "N/A",
									waypoints,
									id: selectedRouteId + dailyId,
									isRouteReversed: dailyRouteData?.isRouteReversed ?? false,
								});
							}
						}
					}
				});
			});
			await Promise.all(promises.flat());
			temp.sort((a, b) => {
				const dateA = new Date(a.dailyRouteId);
				const dateB = new Date(b.dailyRouteId);

				if (dateA < dateB) return -1;
				if (dateA > dateB) return 1;

				// If dates are equal, sort by routeLabel alphabetically
				if (a.routeLabel < b.routeLabel) return -1;
				if (a.routeLabel > b.routeLabel) return 1;

				return 0;
			});
			setRouteColumns(temp);
			setPrevRouteColumns(JSON.parse(JSON.stringify([...temp])));
			setRouteUpdatingIds([]);
		} catch (error) {
			console.log(error);
			setRouteColumns([]);
			setPrevRouteColumns([]);
			setRouteUpdatingIds([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let unsubscribe = null;
		if (!user) return;
		setLoading(true);
		try {
			unsubscribe = onSnapshot(
				query(
					collection(db, COLLECTIONS.routes),
					where("status", "==", "ACTIVE"),
					where("transporterId", "==", user?.uid),
					orderBy("routeLabel", "asc")
				),
				async (snap) => {
					if (!snap.docs.length) return;
					const tempRoute = [];
					snap.docs.forEach((el) => {
						if (!el.exists()) return;
						const routeData = el.data();
						routeData.id = el.id;
						tempRoute.push(routeData);
					});
					setRoutes(tempRoute);
					setSelectedRoutes(["all", ...tempRoute.map((el) => el.id)]);
				}
			);
		} catch (err) {
			console.log(err);
			showErrorToastMessage("Unable to get routes.");
		} finally {
			setLoading(false);
		}

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, [user]);

	const dragStartHandler = (event) => {
		const { active } = event;
		const { id, data } = active;
		const type = data.current.type;
		console.log(data.current);
		const currentColumn = routeColumns.find((el) => el?.id === data?.current?.columnId);
		if (type === ITEM_TYPE_MAP.ROUTES) {
			setActiveRouteColumn(findRouteColumnById(id));
		}
		if (type === ITEM_TYPE_MAP.SERVICE) {
			const service = findServiceCardByServiceId(id);
			console.log({ service });
			const tempServiceCard = { ...service, dailyRouteId: data.current.dailyRouteId };
			if (currentColumn) {
				tempServiceCard.routeColor = currentColumn.color;
			}
			setActiveServiceCard(tempServiceCard);
		}
	};
	const dragOverHandler = (event) => {
		console.log("Drag Over event");
		const { active, over } = event;

		if (!over) return;

		const activeType = active.data.current.type;
		const overType = over.data.current?.type;
		console.log({ activeType, overType });
		if (activeType === ITEM_TYPE_MAP.SERVICE && overType === ITEM_TYPE_MAP.SERVICE) {
			const activeServiceId = active.id;
			const overServiceId = over.id;
			const activeRouteColumnId = active.data?.current?.columnId;
			const overRouteColumnId = over.data?.current?.columnId;
			if (!activeRouteColumnId || !overRouteColumnId) return;
			const activeContainer = routeColumns.find((el) => el.id === activeRouteColumnId);
			const overContainer = routeColumns.find((el) => el.id === overRouteColumnId);
			const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeContainer.id);
			const overContainerIndex = routeColumns.findIndex((route) => route.id === overContainer.id);
			const activeServiceIndex = activeContainer.waypoints.findIndex((el) => el.serviceId === activeServiceId);
			const overServiceIndex = overContainer.waypoints.findIndex((el) => el.serviceId === overServiceId);

			setRouteColumns((newItems) => {
				console.log("state updated");

				const [removeditem] = newItems[activeContainerIndex].waypoints.splice(activeServiceIndex, 1);
				newItems[overContainerIndex].waypoints.splice(overServiceIndex, 0, removeditem);
				return newItems;
			});
		}
		if (activeType === ITEM_TYPE_MAP.SERVICE && overType === ITEM_TYPE_MAP.ROUTES) {
			const activeServiceId = active.id;
			const overRouteColumnId = over.id;
			const activeRouteColumnId = active.data?.current?.columnId;

			if (!activeRouteColumnId || !overRouteColumnId) return;
			if (activeRouteColumnId === overRouteColumnId) return;
			const activeContainer = routeColumns.find((el) => el.id === activeRouteColumnId);
			const overContainer = routeColumns.find((el) => el.id === overRouteColumnId);
			const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeContainer.id);
			const overContainerIndex = routeColumns.findIndex((route) => route.id === overContainer.id);
			const activeServiceIndex = activeContainer.waypoints.findIndex((el) => el.serviceId === activeServiceId);

			setRouteColumns((newItems) => {
				console.log("state updated");

				const [removeditem] = newItems[activeContainerIndex].waypoints.splice(activeServiceIndex, 1);
				newItems[overContainerIndex].waypoints.splice(
					newItems[overContainerIndex]?.waypoints?.length ?? 0,
					0,
					removeditem
				);
				return newItems;
			});
		}

		checkForChanges();
	};
	const checkForChanges = () => {
		setTimeout(() => {
			routeColumns.forEach((route) => {
				const prevRouteData = prevRouteColumns.find((el) => el.id === route.id);
				if (!prevRouteData) return;

				const routeString = JSON.stringify({ ...route });
				const prevRouteString = JSON.stringify({ ...prevRouteData });
				if (routeString !== prevRouteString) {
					setRouteUpdatingIds((prev) => Array.from(new Set([...prev, route.id])));
				} else {
					setRouteUpdatingIds((prev) => prev.filter((el) => el !== route.id));
				}
			});
		}, 0);
	};

	const dragEndHandler = (event) => {
		// Container Move handle
		const { active, over } = event;
		if (!over) return;
		const activeType = active.data.current.type;
		const overType = over.data.current.type;
		if (activeType === ITEM_TYPE_MAP.ROUTES && overType === ITEM_TYPE_MAP.ROUTES) {
			const activeRouteColumnId = active.id;
			const overRouteColumnId = over.id;
			if (activeRouteColumnId !== overRouteColumnId) {
				// Find the index of the active and over container
				const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeRouteColumnId);
				const overContainerIndex = routeColumns.findIndex((route) => route.id === overRouteColumnId);

				// Swap the active and over container
				let newItems = [...routeColumns];
				newItems = arrayMove(newItems, activeContainerIndex, overContainerIndex);
				setRouteColumns(newItems);
			}
		}
		if (activeType === ITEM_TYPE_MAP.SERVICE && overType === ITEM_TYPE_MAP.SERVICE) {
			const activeServiceId = active.id;
			const overServiceId = over.id;
			const activeRouteColumnId = active.data.current.columnId;
			const overRouteColumnId = over.data.current.columnId;
			if (!activeRouteColumnId || !overRouteColumnId) return;
			const activeContainer = routeColumns.find((el) => el.id === activeRouteColumnId);
			const overContainer = routeColumns.find((el) => el.id === overRouteColumnId);
			const activeContainerIndex = routeColumns.findIndex((route) => route.id === activeContainer.id);
			const overContainerIndex = routeColumns.findIndex((route) => route.id === overContainer.id);
			const activeServiceIndex = activeContainer.waypoints.findIndex((el) => el.serviceId === activeServiceId);
			const overServiceIndex = overContainer.waypoints.findIndex((el) => el.serviceId === overServiceId);
			// Same Container
			if (activeContainerIndex === overContainerIndex) {
				setRouteColumns((newItems) => {
					console.log("State updated");
					newItems[activeContainerIndex].waypoints = arrayMove(
						newItems[activeContainerIndex].waypoints,
						activeServiceIndex,
						overServiceIndex
					);
					return newItems;
				});
			}
		}

		setActiveRouteColumn(null);
		setActiveServiceCard(null);
		console.log("Drag End event");
	};
	const findRouteColumnById = (id) => {
		return routeColumns.find((el) => el.id === id);
	};
	const findServiceCardByServiceId = (serviceId) => {
		for (const route of routeColumns) {
			const found = route.waypoints.find((el) => el.serviceId === serviceId);
			if (found) {
				return found;
			}
		}
	};

	const removeServiceById = (serviceId) => {
		const newRouteColumns = [...routeColumns];
		for (const route of newRouteColumns) {
			const index = route.waypoints.findIndex((el) => el.serviceId === serviceId);
			if (index !== -1) {
				route.waypoints.splice(index, 1);
				setRouteColumns(newRouteColumns);
				return;
			}
		}
	};

	const routeUpdateHandler = async () => {
		console.log("Route Update Handler");
		try {
			showLoadingToastMessage("Updating Routes");
			const routeJobs = routeColumns.map(async (route) => {
				if (!routeUpdatingIds.includes(route.id)) return;
				if (!route?.dailyRouteId) return;
				if (!route?.routeId) return;
				const newWaypoints = [];
				const waypoints = route?.waypoints ?? [];
				const job = waypoints.map(async (wp) => {
					newWaypoints.push({
						id: wp.id,
						isLocked: wp.isLocked,
						isPriority: wp.isPriority,
						lat: wp.lat,
						lng: wp.lng,
						serviceId: wp.serviceId,
						type: wp.type,
					});
					const serviceDate = new Date(route.dailyRouteId);
					const dataToUpdate = {
						date: Timestamp.fromDate(serviceDate),
						routeId: route.routeId,
						updatedAt: serverTimestamp(),
					};
					console.log({ dataToUpdate, serviceId: wp.serviceId, isoString: serviceDate.toISOString() });
					try {
						await updateDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId), dataToUpdate);
					} catch (error) {
						console.log({ error });
					}
				});
				await Promise.all(job);
				try {
					await updateDoc(doc(db, COLLECTIONS.routes, route.routeId, COLLECTIONS.dailyRoutes, route.dailyRouteId), {
						waypoints: newWaypoints,
						updatedAt: serverTimestamp(),
					});
				} catch (error) {
					console.log(error);
				}
			});
			await Promise.all(routeJobs);
			setRouteUpdatingIds([]);
			setNoOfSplit(0);
			setRouteSplits([]);
			setPrevRouteColumns(JSON.parse(JSON.stringify([...routeColumns])));
			showSuccessToastMessage("Routes Updated Successfully");
		} catch (error) {
			console.log(error);
			showInternalServerErrorToastMessage();
		}
	};
	const routeResetHandler = () => {
		const dailyRouteIds = [];
		const utcToDate = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 0, 0, 0, 0));
		const utcFromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0));
		while (utcFromDate <= utcToDate) {
			dailyRouteIds.push(utcFromDate.toISOString());
			utcFromDate.setDate(utcFromDate.getDate() + 1);
		}
		if (!selectedRoutes.length) {
			setRouteColumns([]);
			return;
		}
		if (!dailyRouteIds.length) {
			setRouteColumns([]);
			return;
		}
		updateRouteColumns(dailyRouteIds, selectedRoutes);
	};
	const renderSwimLanes = () => {
		return (
			<>
				<div className="w-full bg-white z-10 flex justify-end gap-2">
					<button type="button">
						<HiOutlineChevronLeft
							className="min-h-5 min-w-5"
							onClick={() => {
								const swimlanes = document.getElementById("swimlanes");
								swimlanes.scrollLeft -= 200;
							}}
						/>
					</button>
					<button type="button">
						<HiOutlineChevronRight
							className="min-h-5 min-w-5"
							onClick={() => {
								const swimlanes = document.getElementById("swimlanes");
								swimlanes.scrollLeft += 200;
							}}
						/>
					</button>
				</div>
				<div
					className="h-full w-full p-2 overflow-x-scroll overflow-y-scroll"
					style={{
						display: "grid",
						gridTemplateColumns: `repeat(auto-fill, 224px)`,
						gap: "1rem",
						gridAutoFlow: "column",
					}}
					id="swimlanes"
				>
					{loading && (
						<div className="grid items-center justify-center h-full w-full">
							<Loader height="h-20 w-20" />
						</div>
					)}
					{!loading && (
						<>
							<DndContext
								collisionDetection={pointerWithin}
								sensors={sensors}
								onDragStart={dragStartHandler}
								onDragOver={dragOverHandler}
								onDragEnd={dragEndHandler}
							>
								<SortableContext items={routeColumns.map((col) => col.id)}>
									{routeColumns.map((column) => (
										<RouteColumn
											{...column}
											key={column.id}
											id={column.id}
											waypoints={column.waypoints}
											setSelected={setSelected}
											selected={selected}
											setColumns={setRouteColumns}
											locations={locations}
											defaultEndLocation={column.defaultEndLocation}
											defaultStartLocation={column.defaultStartLocation}
											satelliteLocations={satelliteLocations}
											vendorMarkers={vendorMarkers}
											treatmentFacilities={treatmentFacilities}
											transporterData={transporterData}
											setRouteColumns={setRouteColumns}
											isRouteReversed={column?.isRouteReversed ?? false}
											totalTimeInSeconds={column?.totalTimeInSeconds ?? 0}
											totalDistanceInMeters={column?.totalDistanceInMeters ?? 0}
											assignedDriverName={column?.assignedDriverName ?? ""}
											assignedDriverId={column?.assignedDriverId ?? ""}
											assignedServiceVehicleName={column?.assignedServiceVehicleName ?? ""}
											assignedServiceVehicleId={column?.assignedServiceVehicleId ?? ""}
											drivers={drivers}
											routes={routes}
											setCurrentRouteSplitId={setCurrentRouteSplitId}
											routeUpdatingIds={routeUpdatingIds}
											removeServiceById={removeServiceById}
											itemsOptions={itemsOptions}
											itemsMap={itemsMap}
											checkForChanges={checkForChanges}
											setRouteUpdatingIds={setRouteUpdatingIds}
										/>
									))}
								</SortableContext>
								{createPortal(
									<DragOverlay>
										{activeRouteColumn && (
											<RouteColumn
												{...activeRouteColumn}
												id={activeRouteColumn.id}
												waypoints={activeRouteColumn.waypoints}
												setSelected={setSelected}
												selected={selected}
												setColumns={setRouteColumns}
												locations={locations}
												defaultEndLocation={activeRouteColumn.defaultEndLocation}
												defaultStartLocation={activeRouteColumn.defaultStartLocation}
												satelliteLocations={satelliteLocations}
												vendorMarkers={vendorMarkers}
												treatmentFacilities={treatmentFacilities}
												transporterData={transporterData}
												setRouteColumns={setRouteColumns}
												isRouteReversed={activeRouteColumn?.isRouteReversed ?? false}
												totalTimeInSeconds={activeRouteColumn?.totalTimeInSeconds ?? 0}
												totalDistanceInMeters={activeRouteColumn?.totalDistanceInMeters ?? 0}
												assignedDriverName={activeRouteColumn?.assignedDriverName ?? null}
												assignedDriverId={activeRouteColumn?.assignedDriverId ?? null}
												assignedServiceVehicleName={activeRouteColumn?.assignedServiceVehicleName ?? "N/A"}
												drivers={drivers}
												routes={routes}
												setCurrentRouteSplitId={setCurrentRouteSplitId}
												itemsOptions={itemsOptions}
												itemsMap={itemsMap}
												checkForChanges={checkForChanges}
												setRouteUpdatingIds={setRouteUpdatingIds}
											/>
										)}
										{activeServiceCard && (
											<ServiceCard
												dailyRouteId={activeServiceCard.dailyRouteId}
												key={activeServiceCard.serviceId}
												id={activeServiceCard.serviceId}
												text={activeServiceCard.type + activeServiceCard.id}
												generatorData={activeServiceCard.generatorData}
												serviceData={activeServiceCard.serviceData}
												scheduleData={activeServiceCard.scheduleData}
												routeColor={activeServiceCard.routeColor ?? "#FFA500"}
												itemsOptions={itemsOptions}
												itemsMap={itemsMap}
											/>
										)}
									</DragOverlay>,
									document.body
								)}
							</DndContext>
						</>
					)}
				</div>
			</>
		);
	};

	const renderPin = (serviceType, serviceId, color, index) => {
		if (serviceType === SERVICE_TYPES.MEDICAL_WASTE) {
			return (
				<RoundedPin
					className="min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative object-cover"
					color={color ?? "#FFA500"}
					text={typeof index !== "undefined" ? index + 1 : ""}
				/>
			);
		}
		if (serviceType === SERVICE_TYPES.PAPER_SHREDDING) {
			return (
				<SquarePin
					className="min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative"
					color={color ?? "#FFA500"}
					text={typeof index !== "undefined" ? index + 1 : ""}
				/>
			);
		}
		if (serviceType === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING) {
			return (
				<SquarePin
					className="min-w-12 w-12 min-h-12 h-12 flex items-center justify-center relative"
					color={color ?? "#FFA500"}
					text={typeof index !== "undefined" ? index + 1 : ""}
				/>
			);
		}
		return (
			<RoundedPin
				className="w-20 h-20 flex items-center justify-center relative"
				color={color ?? "#FFA500"}
				text={typeof index !== "undefined" ? index + 1 : ""}
			/>
		);
	};

	const zoomIn = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom + 1 });
		}
	};

	const zoomOut = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom - 1 });
		}
	};

	return (
		<div className="p-5">
			<div className="main-container flex gap-2 flex-col rounded-cardRadii">
				<div className="w-full flex gap-5 items-end flex-wrap p-2">
					<div className="flex gap-5 items-end">
						<div className="min-w-32">
							<button
								className="p-2 px-10 border border-gray-200 bg-white rounded-full"
								onClick={() => {
									const today = new Date();
									setFrom(today);
									setTo(today);
								}}
							>
								Today
							</button>
						</div>
					</div>
					<div className="flex flex-col min-w-60">
						<p className="text-sm text-gray-500 px-2">From</p>
						<CustomDatePicker
							selectedDate={from}
							setSelectedDate={(value) => setFrom(new Date(value))}
							label={"From Date"}
							minDate={new Date()}
						/>
					</div>
					<div className="flex flex-col min-w-60">
						<p className="text-sm text-gray-500 px-2">To</p>
						<CustomDatePicker
							selectedDate={to}
							setSelectedDate={(value) => {
								setTo(new Date(value));
							}}
							minDate={new Date()}
							label={"To Date"}
						/>
					</div>
					<button
						className="p-2 px-5 bg-primary text-white rounded-full disabled:bg-gray-300 disabled:cursor-not-allowed"
						disabled={routeUpdatingIds.length === 0}
						onClick={() => {
							document.getElementById(`save_route_order`).showModal();
						}}
					>
						Save Route Order
					</button>
					<button
						className="p-2 px-5 bg-red-500 text-white rounded-full disabled:bg-gray-300 disabled:cursor-not-allowed"
						disabled={routeUpdatingIds.length === 0}
						onClick={() => {
							document.getElementById(`reset_route_order`).showModal();
						}}
					>
						Reset
					</button>
					<div className="flex gap-2 ml-auto">
						<div className="w-full">
							<p className="text-sm text-gray-500 px-2">Selected Locations</p>
							<MultiSelectRounded
								label=""
								styles="flex flex-col w-full gap-1"
								margin="0"
								options={[...locations.map((el) => el)]}
								value={selectedLocations}
								onChange={(e) => {
									setSelectedLocations(e);
								}}
							/>
						</div>

						<div className="w-full">
							<p className="text-sm text-gray-500 px-2">Selected Routes</p>
							<MultiSelectRounded
								styles="flex flex-col w-full gap-1"
								margin="0"
								options={[...routes.map((el) => ({ label: el.routeLabel, value: el.id }))]}
								value={selectedRoutes}
								onChange={(e) => {
									setSelectedRoutes(e);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="bg-white rounded-3xl h-[75vh] grid grid-cols-2 gap-5 p-2">
					<div className="max-h-[calc(75vh-20px)]">{renderSwimLanes()}</div>
					<div className="max-h-[calc(75vh-20px)] rounded-xl overflow-clip">
						{routeColumns.length > 0 &&
							routeColumns.map((route) => {
								return route.waypoints.map((waypoint, index) => {
									return (
										<div key={index}>
											<dialog
												key={`change_route_modal_${route.id + waypoint.serviceId}`}
												id={`change_route_modal_${route.id + waypoint.serviceId}`}
												className="modal"
											>
												<div className="modal-box h-80">
													<button
														type="button"
														className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
														onClick={() => {
															document.getElementById(`change_route_modal_${route.id + waypoint.serviceId}`).close();
														}}
													>
														✕
													</button>
													<div className="flex flex-col h-full gap-2">
														<h3 className="font-bold text-lg">Change Service Route</h3>

														<div className="grid grid-cols-2 gap-2">
															<p>Currently Assigned Route:</p>
															<p>{route.routeLabel}</p>
														</div>
														<Dropdown
															style={"max-h-24"}
															value={currentRouteChangeId}
															options={routes
																.filter((el) => el.id !== route.routeId)
																.map((el) => ({ label: el.routeLabel, value: el.id }))}
															onChange={(e) => {
																setCurrentRouteChangeId(e);
															}}
														/>

														<div className="flex justify-between mt-auto">
															<button
																disabled={currentRouteChangeId.length === 0 || currentRouteChangeId === route.routeId}
																type="button"
																className="btn btn-primary btn-sm"
																onClick={async () => {
																	document
																		.getElementById(`change_route_confirmation_modal_${route.id + waypoint.serviceId}`)
																		.showModal();
																}}
															>
																Change Route
															</button>
															<button
																type="button"
																className="btn btn-error btn-sm"
																onClick={() => {
																	console.log("Cancelling");
																	document
																		.getElementById(`change_route_modal_${route.id + waypoint.serviceId}`)
																		.close();
																}}
															>
																Cancel
															</button>
														</div>
													</div>
												</div>
											</dialog>
											<dialog
												key={`change_route_confirmation_modal_${route.id + waypoint.serviceId}`}
												id={`change_route_confirmation_modal_${route.id + waypoint.serviceId}`}
												className="modal"
											>
												<div className="modal-box h-80">
													<button
														type="button"
														className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
														onClick={() => {
															document
																.getElementById(`change_route_confirmation_modal_${route.id + waypoint.serviceId}`)
																.close();
														}}
													>
														✕
													</button>
													<div className="flex flex-col h-full">
														<h3 className="font-bold text-lg">Confirm Service Route Change</h3>

														<div className="overflow-visible z-10 flex flex-col py-5">
															<p>Are you sure you want to change route allocation of this service?</p>
														</div>

														<div className="flex justify-between mt-auto">
															<button
																type="button"
																className="btn btn-error btn-sm"
																onClick={() => {
																	console.log("Cancelling");
																	document
																		.getElementById(`change_route_confirmation_modal_${route.id + waypoint.serviceId}`)
																		.close();
																}}
															>
																Cancel
															</button>
															<button
																type="button"
																className="btn btn-primary btn-sm"
																onClick={() => {
																	console.log("Form Submitted");
																	const currentRoute = currentRouteChangeId;
																	console.log({ currentRoute });
																	try {
																		showLoadingToastMessage("Please wait we are updating the routes.");
																		const currentWaypoint = { ...waypoint };
																		const tempRouteColumns = JSON.parse(JSON.stringify([...routeColumns]));
																		const existingRouteColumn = tempRouteColumns.find(
																			(el) => el.id === currentRoute + route.dailyRouteId
																		);
																		const currentRouteColumn = tempRouteColumns.find((el) => el.id === route.id);
																		if (existingRouteColumn) {
																			existingRouteColumn.waypoints.push(currentWaypoint);
																			currentRouteColumn.waypoints = currentRouteColumn.waypoints.filter(
																				(el) => el.serviceId !== waypoint.serviceId
																			);
																			tempRouteColumns.forEach((route) => {
																				const prevRouteData = prevRouteColumns.find((el) => el.id === route.id);
																				if (!prevRouteData) return;

																				const routeString = JSON.stringify({ ...route });
																				const prevRouteString = JSON.stringify({ ...prevRouteData });
																				if (routeString !== prevRouteString) {
																					setRouteUpdatingIds((prev) => Array.from(new Set([...prev, route.id])));
																				} else {
																					setRouteUpdatingIds((prev) => prev.filter((el) => el !== route.id));
																				}
																			});
																			setRouteColumns(tempRouteColumns);
																		}
																		showSuccessToastMessage(
																			"Route updated in swimlanes successfully. Please click on Save Route Order to save the changes."
																		);
																	} catch (error) {
																		console.log(error);
																		showInternalServerErrorToastMessage();
																	} finally {
																		document
																			.getElementById(
																				`change_route_confirmation_modal_${route.id + waypoint.serviceId}`
																			)
																			.close();
																	}
																}}
															>
																OK
															</button>
														</div>
													</div>
												</div>
											</dialog>
										</div>
									);
								});
							})}
						<AzureMap options={{ authOptions: authOptions, view: "Auto" }}>
							{routeColumns.length > 0 &&
								routeColumns.map((route) => {
									if (route.waypoints.length > 0) {
										return (
											<>
												<CustomAzureRoute route={route} setRouteColumns={setRouteColumns} />
												{route.waypoints.map((waypoint, index) => {
													if (!waypoint?.lat || !waypoint?.lng) return null;
													return (
														<CustomAzureWayPointMarker
															draggable={true}
															key={waypoint.serviceId}
															id={waypoint.serviceId}
															position={{ lat: waypoint?.lat ?? 0, lng: waypoint?.lng ?? 0 }}
															serviceId={waypoint.serviceId}
															genData={waypoint.generatorData}
															waypoint={waypoint}
															route={route}
															routes={routes}
															pin={renderPin(
																waypoint?.scheduleData?.serviceType,
																waypoint?.serviceId,
																route?.color,
																index
															)}
														></CustomAzureWayPointMarker>
													);
												})}
											</>
										);
									}
								})}
							<div className="absolute bottom-5 right-5 flex flex-col gap-2 z-10">
								<button
									onClick={zoomIn}
									className="w-10 h-10 flex items-center justify-center 
                       bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
                        font-bold rounded shadow-md"
								>
									<FaPlus />
								</button>
								<button
									onClick={zoomOut}
									className="w-10 h-10 flex items-center justify-center 
                       bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
                       font-bold rounded shadow-md"
								>
									<FaMinus />
								</button>
							</div>
						</AzureMap>
					</div>
					<dialog id={`split_route`} className="modal">
						<div className="modal-box h-full min-w-[60vw] overflow-y-auto">
							<form method="dialog" className="h-full ">
								<button
									className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
									onClick={() => {
										document.getElementById(`split_route`).close();
									}}
								>
									✕
								</button>
								<div className="flex flex-col h-full">
									<div className="flex flex-col">
										<h3 className="font-bold text-lg">Split Route</h3>
										<div className="w-1/2">
											<Input
												label="No. of Splits"
												type="number"
												value={noOfSplit}
												onChange={(e) => {
													const newValue = e.target.valueAsNumber;
													setNoOfSplit(newValue);
													if (isNaN(newValue)) {
														showErrorToastMessage("Please enter a valid number.");
														return;
													}
													if (newValue <= 0) {
														showErrorToastMessage("Please enter a positive number.");
														return;
													}
													setRouteSplits((prev) => {
														const existingSplits = [...prev];
														// If new value is less than current, truncate the array
														if (newValue < existingSplits.length) {
															return existingSplits.slice(0, newValue);
														}
														if (newValue > existingSplits.length) {
															const additionalSplits = Array.from({ length: newValue - existingSplits.length }).map(
																() => ({
																	routeId: "",
																	date: new Date(),
																})
															);
															return [...existingSplits, ...additionalSplits];
														}
														return existingSplits;
													});
												}}
												style="flex-col"
											/>
										</div>
									</div>
									<div className="">
										{routeSplits.map((split, index) => {
											return (
												<div className="grid grid-cols-2 items-end gap-5" key={index}>
													<Dropdown
														styles="flex-col min-w-full text-sm"
														isRequired={true}
														onChange={(value) => {
															setRouteSplits((prev) => {
																return prev.map((el, i) => {
																	if (i === index) {
																		el.routeId = value;
																	}
																	return el;
																});
															});
														}}
														placeholder="Route"
														label={"Select Route"}
														value={split.routeId}
														options={routes.map((el) => ({ label: el.routeLabel, value: el.id }))}
													/>

													<div className="py-4">
														<CustomDatePicker
															labelClass="text-sm text-black"
															selectedDate={split.date}
															setSelectedDate={(value) => {
																setRouteSplits((prev) => {
																	return prev.map((el, i) => {
																		if (i === index) {
																			el.date = value;
																		}
																		return el;
																	});
																});
															}}
															label={"Date"}
															minDate={new Date()}
														/>
													</div>
												</div>
											);
										})}
									</div>
									<div className="flex justify-between mt-auto pb-5">
										<button
											type="button"
											className="btn btn-primary btn-sm"
											onClick={async () => {
												if (!currentRouteSplitId) return;

												const convertedRouteSplits = routeSplits.map((split) => {
													const date = new Date(split.date);
													const utcDate = new Date(
														Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
													);
													return { ...split, date: utcDate.toISOString() };
												});
												const uniqueCombinations = new Set();
												const filterRouteSplit = convertedRouteSplits.filter((split) => {
													if (!split.routeId || !split.date) return false;

													const uniqueKey = `${split.routeId}-${split.date}`;

													const alreadyExistInRouteColumns = routeColumns.find(
														(col) => col.routeId === split.routeId && col.dailyRouteId === split.date
													);

													const isDuplicate = uniqueCombinations.has(uniqueKey);

													if (!isDuplicate && !alreadyExistInRouteColumns) {
														uniqueCombinations.add(uniqueKey);
														return true;
													}

													return false;
												});

												console.log({ filterRouteSplit });
												const temp = [];
												const jobs = filterRouteSplit.map(async (split) => {
													const dailyRouteRes = await getDoc(
														doc(db, COLLECTIONS.routes, split.routeId, COLLECTIONS.dailyRoutes, split.date)
													);
													const routeData = routes.find((route) => route.id === split.routeId);
													if (dailyRouteRes.exists() && routeData) {
														const dailyRouteData = dailyRouteRes.data();
														dailyRouteData.id = dailyRouteRes.id;
														let waypoints = dailyRouteData.waypoints;

														if (waypoints?.length) {
															const waypointPromises = waypoints.map(async (wp) => {
																const generatorRes = await getDoc(doc(db, COLLECTIONS.generators, wp.id));
																if (generatorRes.exists()) {
																	wp.generatorData = { ...generatorRes.data(), id: generatorRes.id };
																} else {
																	wp.generatorData = null;
																}
																const serviceRes = await getDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId));
																if (serviceRes.exists()) {
																	wp.serviceData = { ...serviceRes.data(), id: serviceRes.id };
																} else {
																	wp.serviceData = null;
																}
																if (wp?.serviceData?.serviceScheduleId) {
																	const scheduleRes = await getDoc(
																		doc(db, COLLECTIONS.serviceSchedules, wp.serviceData.serviceScheduleId)
																	);
																	if (scheduleRes.exists()) {
																		wp.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
																	} else {
																		wp.scheduleData = null;
																	}
																}
																wp.randomCoordinates = randomizeCoordinates(wp.lat, wp.lng);
															});
															await Promise.all(waypointPromises);
															waypoints = waypoints.filter(
																(el) => el.serviceData !== null && el.scheduleData !== null && el.generatorData !== null
															);
															waypoints = waypoints.filter(
																(el) => el?.serviceData.status !== scheduledServiceStatus.DELETED
															);
														}

														temp.push({
															routeId: split.routeId,
															dailyRouteId: split.date,
															...routeData,
															...dailyRouteData,
															assignedDriverName: dailyRouteData?.assignedDriverName ?? "",
															assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "",
															waypoints,
															id: split.routeId + split.date,
														});
													} else {
														const dailyRouteData = {
															defaultStartLocation: routeData.defaultStartLocation,
															defaultEndLocation: routeData.defaultEndLocation,
															totalDistanceInMeters: 0,
															totalTimeInSeconds: 0,
															createdAt: serverTimestamp(),
															updatedAt: serverTimestamp(),
															waypoints: [],
														};
														setDoc(
															doc(db, COLLECTIONS.routes, split.routeId, COLLECTIONS.dailyRoutes, split.date),
															dailyRouteData
														);
														temp.push({
															routeId: split.routeId,
															dailyRouteId: split.date,
															...routeData,
															assignedDriverName: dailyRouteData?.assignedDriverName ?? "",
															assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "",
															waypoints: [],
															id: split.routeId + split.date,
														});
													}
												});
												await Promise.all(jobs);
												console.log({ temp });
												if (temp.length > 0) {
													const currentIndex = routeColumns.findIndex((el) => el.id === currentRouteSplitId);
													if (currentIndex !== -1) {
														const newItems = [...routeColumns];
														newItems.splice(currentIndex + 1, 0, ...temp);
														console.log({ newItems });
														setRouteColumns(newItems);
														setPrevRouteColumns(JSON.parse(JSON.stringify([...newItems])));
													}
												}
												document.getElementById(`split_route`).close();
											}}
										>
											OK
										</button>
										<button
											className="btn btn-error btn-sm"
											onClick={() => {
												setCurrentRouteSplitId(null);
												document.getElementById(`split_route`).close();
											}}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					</dialog>
					<dialog id={`save_route_order`} className="modal">
						<div className="modal-box">
							<form method="dialog">
								<button
									className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
									onClick={() => {
										document.getElementById(`save_route_order`).close();
									}}
								>
									✕
								</button>
								<h3 className="font-bold text-lg">Save Route Order</h3>

								<div className="overflow-visible z-10 flex flex-col py-5">
									<p>Are you Sure?</p>
								</div>
								<div className="h-20">
									<p>
										Proceeding with this operation will update {routeUpdatingIds.length} route(s). This action cannot be
										undone.
									</p>
								</div>
								<div className="flex justify-between">
									<button className="btn btn-primary btn-sm" onClick={routeUpdateHandler}>
										Save
									</button>
									<button
										className="btn btn-error btn-sm"
										onClick={() => {
											console.log("Cancelling");
											document.getElementById(`save_route_order`).close();
										}}
									>
										Cancel
									</button>
								</div>
							</form>
						</div>
					</dialog>
					<dialog id={`reset_route_order`} className="modal">
						<div className="modal-box">
							<form method="dialog">
								<button
									className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
									onClick={() => {
										document.getElementById(`reset_route_order`).close();
									}}
								>
									✕
								</button>
								<h3 className="font-bold text-lg">Reset Route Order To Original State</h3>

								<div className="overflow-visible z-10 flex flex-col py-5">
									<p>Are you Sure?</p>
								</div>
								<div className="h-20">
									<p>
										Proceeding with this operation will reset all the temporary changes made on{" "}
										{routeUpdatingIds.length} route(s). This action cannot be undone.
									</p>
								</div>
								<div className="flex justify-between">
									<button className="btn btn-primary btn-sm" onClick={routeResetHandler}>
										Restore to Original State
									</button>
									<button
										className="btn btn-error btn-sm"
										onClick={() => {
											console.log("Cancelling");
											document.getElementById(`reset_route_order`).close();
										}}
									>
										Keep Changes
									</button>
								</div>
							</form>
						</div>
					</dialog>
				</div>
			</div>
		</div>
	);
};

export default RoutesOptimization;
