import { useState } from "react";
import ChartjsRevenueAreaChart from "./Chart";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";

let dropOptions = [
	{
		label: "All Time",
		value: "All Time",
	},
	// {
	// 	label: "Last 30 Days",
	// 	value: "Last 30 Days",
	// },
	// {
	// 	label: "Last 90 Days",
	// 	value: "Last 90 Days",
	// },
	// {
	// 	label: "This Year",
	// 	value: "This Year",
	// },
];

const QuickAnalytics = () => {
	const [dropDownValue, setDropDownValue] = useState("All Time");

	return (
		<div className="bg-white rounded-cardRadii shadow-sm p-6 min-h-full">
			<div className="flex justify-between">
				<h2 className="text-lg font-medium mb-4">Revenue</h2>
				<Dropdown
					options={dropOptions}
					btnStyle="px-4 border border-cardBorderCol"
					// margin="0"
					value={dropDownValue}
					setValue={setDropDownValue}
				/>
			</div>
			<div className="h-[400px]">
				<ChartjsRevenueAreaChart />
			</div>
		</div>
	);
};

export default QuickAnalytics;
