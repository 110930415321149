import React, { useEffect, useMemo, useState } from "react";
import DashInfoCard from "../../../components/UI/cards/DashInfoCard";
import QuickAnalytics from "./components/quick-analytics/QuickAnalytics";
import OnGoingPickups from "./components/ongoing-pickups/OnGoingPickups";
import DriverLocations from "./components/driver-locations/DriverLocations";
import OpenCalendar from "./components/open-calendar/OpenCalendar";
import { auth, COLLECTIONS, db } from "../../../config/firebase";
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { fetchDocumentsInBatches } from "../octo-connect/SubcontractorServiceRequest";
import useTUserContext from "../../../context/TransporterUserContext";
import { processGeneratorData } from "../../../utils/firebaseOperations";
import { scheduledServiceStatus } from "../../../utils/constants";

const cardInfos = [
	{
		id: "serviceCount",
		title: "Today's Total Services",
		countData: {
			total: 0,
			label1: "Complete",
			count1: 0,
			label2: "Incomplete",
			count2: 0,
		},
		changePercantage: "12",
	},
	{
		id: "routeCount",
		title: "Today's Routes",
		countData: {
			total: 0,
			label1: "In Network",
			count1: 0,
			label2: "Out of Network",
			count2: 0,
		},
		changePercantage: "12",
	},
	{
		id: "ssrCount",
		title: "Subcontractor Service Requests",
		countData: {
			total: 0,
			label1: "Outgoing SSRs",
			count1: 0,
			label2: "Incoming SSRs",
			count2: 0,
		},
		changePercantage: "12",
	},
	{
		id: "generatorCount",
		title: "Contracted Generators",
		countData: {
			total: 0,
			label1: "Scheduled",
			count1: 0,
			label2: "Unscheduled",
			count2: 0,
		},
		changePercantage: "12",
	},
];

const Dashboard = () => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [cardsData, setCardsData] = useState(cardInfos);
	const [routes, setRoutes] = useState([]);
	const [selectedRoute, setSelectedRoute] = useState([]);
	const [routeColumns, setRouteColumns] = useState([]);
	const [allDatas, setAllDatas] = useState([]);
	const [genIds, setGenIds] = useState([]);
	const [pendingCons, setPendingCons] = useState([]);
	const [generators, setGenerators] = useState([]);
	const [currentTransporterData, setCurrentTransporterData] = useState(null);
	const [activeGens, setActiveGens] = useState([]);
	const { user, loading } = useTUserContext();
	const [isLoading, setIsLoading] = useState(false);

	const fetchAllPendingConnectionsData = async (transporterId) => {
		try {
			const transporterDoc = await getDoc(doc(collection(db, "transporters"), transporterId));
			if (transporterDoc.exists()) {
				setCurrentTransporterData({ id: transporterId, ...transporterDoc.data() });
				let sharedGenerators = transporterDoc.data()?.sharedGenerators;
				let pendingConns = Array.isArray(sharedGenerators?.toMe) ? sharedGenerators?.toMe : [];
				console.log({ pendingConns });

				let pending = [],
					ids = [];
				pendingConns?.map((item) => {
					if (item.status == "Pending") {
						pending.push(item);
						ids.push(item?.genId);
					}
				});
				setPendingCons(pending);
				setGenIds(ids);
			}
		} catch (error) {
			console.error(`Error fetching transporter ${transporterId}:`, error);
		}
	};

	console.log({ currentDate });

	useEffect(() => {
		if (user?.uid && !loading) fetchAllPendingConnectionsData(user.uid);
	}, [user?.uid, loading]);

	const fetchGens = async () => {
		try {
			let resp = await fetchDocumentsInBatches(db, "generators", genIds);
			setGenerators(resp);
		} catch (error) {
			console.log({ error });
		}
	};

	useEffect(() => {
		fetchGens();
	}, [genIds]);

	console.log({ allDatas });

	useEffect(() => {
		let latestPendingCons = pendingCons.map((connection) => {
			let gen = generators.find((item) => connection.genId == item.id);
			return {
				...connection,
				...gen,
			};
		});
		setAllDatas(latestPendingCons);
	}, [generators, pendingCons]);

	useEffect(() => {
		const dailyRouteIds = [];

		const utcToDate = new Date(
			Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0)
		);
		const utcFromDate = new Date(
			Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0)
		);

		while (utcFromDate <= utcToDate) {
			dailyRouteIds.push(utcFromDate.toISOString());
			utcFromDate.setDate(utcFromDate.getDate() + 1);
		}
		console.log({ dailyRouteIds });

		if (!dailyRouteIds.length) {
			setRouteColumns([]);
			return;
		}
		updateRouteColumns(dailyRouteIds, selectedRoute);
	}, [selectedRoute, currentDate]);

	async function fetchRoutes() {
		try {
			const q = query(
				collection(db, COLLECTIONS.routes),
				where("status", "in", ["ACTIVE", "OUT_OF_NETWORK_SERVICES"]),
				where("transporterId", "==", user?.uid),
				orderBy("routeLabel", "asc")
			);

			const snap = await getDocs(q);
			console.log("Routes:", snap.docs.length);

			if (snap.empty) return console.log("No routes found");

			const tempRoute = snap.docs.map((el) => ({
				...el.data(),
				id: el.id,
			}));

			setCardsData((prev) =>
				prev.map((card) => {
					if (card.id === "routeCount") {
						return {
							...card,
							count: routeColumns.length?.toString(),
							countData: {
								...card.countData,
								total: tempRoute.length,
								count1: tempRoute.filter((gen) => gen.status == "ACTIVE")?.length,
								count2: tempRoute.filter((gen) => gen.status == "OUT_OF_NETWORK_SERVICES")?.length,
							},
						};
					}
					return card;
				})
			);

			setRoutes(tempRoute?.filter((route) => route.status == "ACTIVE"));
			setSelectedRoute([...tempRoute?.filter((route) => route.status == "ACTIVE").map((el) => el.id)]);
		} catch (error) {
			console.error("Error fetching routes:", error);
		}
	}

	const fetchGenerators = async () => {
		try {
			const q = query(
				collection(db, COLLECTIONS.generators),
				where("transporterId", "==", user?.uid),
				where("generatorStatus", "in", ["CONTRACTED_SCHEDULED", "CONTRACTED_UNSCHEDULED", "PARKING", "NIGO"])
			);

			const snap = await getDocs(q);

			if (snap.empty) return console.log("No active generators found");

			console.log({ snap: snap.docs.length });

			const tempGens = snap.docs.map((el) => processGeneratorData(el));

			let resp = await Promise.all(tempGens);

			console.log("====================================");
			console.log({ resp });
			console.log("====================================");

			setActiveGens(resp);
		} catch (error) {
			console.error("Error fetching active generators:", error);
		}
	};

	useEffect(() => {
		if (user) {
			fetchRoutes();
			fetchGenerators();
		}
	}, [user]);

	console.log("====================================");
	console.log({ routes });
	console.log("====================================");

	const updateRouteColumns = async (dailyRouteIds, selectedRoutes) => {
		const temp = [];
		try {
			console.log("Fetching Route Columns", dailyRouteIds, selectedRoutes);

			setIsLoading(true);
			const promises = selectedRoutes.map((selectedRouteId) => {
				return dailyRouteIds.map(async (dailyId) => {
					const alreadyExist = routeColumns.find((col) => col.id === selectedRouteId && col.dailyRouteId === dailyId);
					if (!alreadyExist) {
						const routeData = routes.find((route) => route.id === selectedRouteId);
						const dailyRouteRes = await getDoc(
							doc(db, COLLECTIONS.routes, selectedRouteId, COLLECTIONS.dailyRoutes, dailyId)
						);

						if (dailyRouteRes.exists() && routeData) {
							const dailyRouteData = dailyRouteRes.data();
							dailyRouteData.id = dailyRouteRes.id;
							let waypoints = dailyRouteData.waypoints;
							if (waypoints?.length) {
								const waypointPromises = waypoints.map(async (wp) => {
									if (wp.id) {
										const generatorRes = await getDoc(doc(db, COLLECTIONS.generators, wp.id));
										if (generatorRes.exists()) {
											wp.generatorData = { ...generatorRes.data(), id: generatorRes.id };
										}
									}
									if (wp.serviceId) {
										const serviceRes = await getDoc(doc(db, COLLECTIONS.scheduledServices, wp.serviceId));
										if (serviceRes.exists()) {
											wp.serviceData = { ...serviceRes.data(), id: serviceRes.id };
										}
									}

									if (wp?.serviceData?.serviceScheduleId) {
										const scheduleRes = await getDoc(
											doc(db, COLLECTIONS.serviceSchedules, wp.serviceData.serviceScheduleId)
										);
										if (scheduleRes.exists()) {
											wp.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
										}
									}
								});
								await Promise.all(waypointPromises);
							}
							waypoints = waypoints.filter(
								(el) => typeof el?.serviceData !== "undefined" && typeof !el?.serviceData !== "undefined"
							);
							temp.push({
								routeId: selectedRouteId,
								dailyRouteId: dailyId,
								...routeData,
								...dailyRouteData,
								assignedDriverName: dailyRouteData?.assignedDriverName ?? "N/A",
								assignedServiceVehicleName: dailyRouteData?.assignedServiceVehicleName ?? "N/A",
								waypoints,
								id: selectedRouteId + dailyId,
								isRouteReversed: dailyRouteData?.isRouteReversed ?? false,
							});
						}
					}
				});
			});
			await Promise.all(promises.flat());
			temp.sort((a, b) => {
				const dateA = new Date(a.dailyRouteId);
				const dateB = new Date(b.dailyRouteId);

				if (dateA < dateB) return -1;
				if (dateA > dateB) return 1;

				// If dates are equal, sort by routeLabel alphabetically
				if (a.routeLabel < b.routeLabel) return -1;
				if (a.routeLabel > b.routeLabel) return 1;

				return 0;
			});
			setRouteColumns(temp);
		} catch (error) {
			console.log(error);
			setRouteColumns([]);
		} finally {
			setIsLoading(false);
		}
	};

	console.log({ routeColumns, cardsData, activeGens });

	useEffect(() => {
		if (routeColumns.length === 0 && !activeGens?.length) return;

		const flatWaypoints = routeColumns.flatMap((ss) => ss.waypoints);

		setCardsData((prev) =>
			prev.map((card) => {
				if (card.id === "serviceCount") {
					let count1 = 0,
						count2 = 0;
					flatWaypoints.map((waypoint) => {
						if (waypoint?.serviceData?.status == scheduledServiceStatus.COMPLETE) count1++;
						else count2++;
					});
					return {
						...card,
						count: flatWaypoints?.length,
						countData: {
							...card.countData,
							total: flatWaypoints.length,
							count1,
							count2,
						},
					};
				}
				if (card.id === "generatorCount") {
					return {
						...card,
						countData: {
							...card.countData,
							total: activeGens.length,
							count1: activeGens.filter((gen) => gen.generatorStatus == "CONTRACTED_SCHEDULED")?.length,
							count2: activeGens.filter((gen) => gen.generatorStatus == "CONTRACTED_UNSCHEDULED")?.length,
						},
					};
				}
				if (card.id === "ssrCount") {
					return { ...card, countData: { ...card.countData, total: allDatas.length } };
				}
				return card;
			})
		);
	}, [routeColumns, activeGens, allDatas]);

	const scrollHandler = (e) => {
		const target = e.target;
		target.scrollTop > 0 ? setIsScrollable(true) : setIsScrollable(false);
	};

	let routesMap = useMemo(() => {
		let obj = {};
		routes.forEach((route) => {
			obj[route.id] = route.routeLabel;
		});
		return obj;
	}, [routes]);

	let colorsMap = useMemo(() => {
		let obj = {};
		routes.forEach((route) => {
			obj[route.id] = route.color;
		});
		return obj;
	}, [routes]);

	return (
		<div
			className={`
          flex-1
          ${isScrollable ? "shadow-inner" : ""}
          py-4 px-6
        `}
			onScroll={scrollHandler}
		>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
				{cardsData.map(({ changePercantage, id, title, countData, count }, i) => (
					<DashInfoCard
						key={id}
						cardColor={i % 2 == 0 ? "green" : "blue"}
						changePercentage={changePercantage}
						title={title}
						count={count}
						countData={countData}
					/>
				))}
			</div>
			<div className="pt-4 flex flex-col gap-4 max-h-[840px] xl:flex-row">
				<div className="flex flex-col h-full max-h-full pb-10 gap-4 xl:w-[65%] 2xl:w-[70%]">
					<div className="w-full h-[520px] border border-cardBorderCol rounded-cardRadii">
						<QuickAnalytics />
					</div>
					<div className="h-[320px] w-full">
						<DriverLocations />
					</div>
				</div>
				<div className="w-full h-full border border-cardBorderCol xl:w-[35%] 2xl:w-[30%] rounded-cardRadii">
					<OpenCalendar
						routeColumns={routeColumns}
						routesMap={routesMap}
						colorsMap={colorsMap}
						currentDate={currentDate}
						setCurrentDate={setCurrentDate}
						loading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
