import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const data = [
	{ month: "2014", revenue: 40000 },
	{ month: "2015", revenue: 100000 },
	{ month: "2016", revenue: 340000 },
	{ month: "2017", revenue: 440000 },
	{ month: "2018", revenue: 480000 },
	{ month: "2019", revenue: 600000 },
	{ month: "2020", revenue: 700000 },
	{ month: "2021", revenue: 940000 },
	{ month: "2022", revenue: 900000 },
	{ month: "2023", revenue: 860000 },
	{ month: "2024", revenue: 1200000 },
];

const ChartjsRevenueAreaChart = () => {
	const chartRef = useRef(null);
	const chartInstance = useRef(null);

	useEffect(() => {
		if (chartInstance.current) {
			chartInstance.current.destroy();
		}

		const ctx = chartRef.current.getContext("2d");

		const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
		gradientFill.addColorStop(0, "rgba(0, 122, 255, 0.19)");
		gradientFill.addColorStop(1, "rgba(76, 217, 100, 0.0)");

		const maxRevenue = Math.max(...data.map((d) => d.revenue));
		const yMax = Math.ceil((maxRevenue * 1.1) / 100000) * 100000;
		const stepSize = yMax / 10;

		chartInstance.current = new Chart(ctx, {
			type: "line",
			data: {
				labels: data.map((item) => item.month),
				datasets: [
					{
						label: "Revenue",
						data: data.map((item) => item.revenue),
						fill: true,
						backgroundColor: gradientFill,
						borderColor: "#3B82F6",
						borderWidth: 2.5,
						pointBackgroundColor: "#FFFFFF",
						pointBorderColor: "#3B82F6",
						pointBorderWidth: 3,
						pointRadius: 6,
						pointHoverRadius: 8,
						tension: 0,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						backgroundColor: "black",
						titleColor: "white",
						bodyColor: "white",
						bodyFont: {
							size: 14,
						},
						padding: 12,
						cornerRadius: 15,
						displayColors: false,
						callbacks: {
							title: () => "",
							label: (context) => `$${context.parsed.y.toLocaleString("en-US")}`,
						},
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
						ticks: {
							color: "#9CA3AF",
						},
					},
					y: {
						beginAtZero: true,
						max: yMax,
						ticks: {
							color: "#9CA3AF",
							stepSize: stepSize,
							callback: (value) => {
								if (value >= 1000000) {
									return `${(value / 1000000).toFixed(1)}M`;
								} else if (value >= 1000) {
									return `${(value / 1000).toFixed(0)}K`;
								}
								return value;
							},
						},
						grid: {
							color: "#F3F4F6",
							drawBorder: false,
						},
					},
				},
				elements: {
					line: {
						tension: 0,
					},
					point: {
						radius: 6,
						hoverRadius: 8,
					},
				},
			},
		});

		return () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}
		};
	}, []);

	return <canvas ref={chartRef}></canvas>;
};

export default ChartjsRevenueAreaChart;
