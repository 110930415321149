import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../../../components/UI/Input";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import Checkbox from "../../../../../../components/UI/Checkbox";
import CustomDatePicker from "../../../../../../components/UI/CustomDatePicker";
import Button from "../../../../../../components/UI/Button";
import { useNavigate, useParams } from "react-router-dom";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, COLLECTIONS, db, storage } from "../../../../../../config/firebase.js";
import MultiselectRounded from "../../../../../../components/UI/dropdowns/MultiSelectRounded.jsx";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "../../../../../../components/UI/PhoneInput.jsx";
import USDInput from "../../../../../../components/UI/UsdInput.jsx";
import { collection, doc, getDoc, getDocs, query, addDoc, updateDoc, where, serverTimestamp } from "firebase/firestore";
import {
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../../../../../utils/helpers.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { EMAIL_TEMPLATES, LOCATION_TYPE_HOME_BASE } from "../../../../../../utils/constants.js";
import useTUserContext from "../../../../../../context/TransporterUserContext.jsx";

const AddDriverForm = () => {
	const { id } = useParams();
	const [driverData, setDriverData] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [driverId, setDriverId] = useState(null);
	const [existingFile, setExistingFile] = useState(null);
	const [locationOptions, setLocationOptions] = useState([]);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const { user, loading } = useTUserContext();
	const {
		handleSubmit,
		control,
		setValue,
		watch,
		reset,
		setError,
		setFocus,
		formState: { errors },
	} = useForm({
		defaultValues: DRIVER_DEFAULT_VALUES,
		shouldFocusError: true,
	});
	const formValues = watch();
	useEffect(() => {}, []);

	useEffect(() => {
		console.log(formValues);
	}, [formValues]);

	const fileInputRef = useRef(null);
	const navigate = useNavigate();
	useEffect(() => {
		if (!user) return;
		if (id) {
			setDriverId(id);
			fetchDriverData(id);
			getAllLocationOptions();
		}
	}, [id, user]);

	const getAllLocationOptions = async () => {
		let tempLocationOptions = [];
		console.log("user", user.uid, { user });
		const transporterRes = await getDoc(doc(db, COLLECTIONS.transporters, user?.uid));
		if (
			transporterRes.exists() &&
			transporterRes.data()?.transporterServiceCoordinates?.lat &&
			transporterRes.data()?.transporterServiceCoordinates?.lng
		) {
			tempLocationOptions.push({
				label: transporterRes.data()?.companyDisplayName ?? "",
				value: LOCATION_TYPE_HOME_BASE,
			});
		}
		const satelliteLocationsRes = await getDocs(
			query(
				collection(db, COLLECTIONS.satelliteLocation),
				where("transporterId", "==", user?.uid),
				where("satelliteStatus", "==", "Active")
			)
		);
		tempLocationOptions.push(
			...satelliteLocationsRes.docs.map((doc) => {
				const data = doc.data();
				return { label: data.name, value: doc.id };
			})
		);
		console.log({ tempLocationOptions });

		setLocationOptions(tempLocationOptions);
	};

	const fetchDriverData = async (id) => {
		const driverRes = await getDoc(doc(db, "drivers", id));
		if (driverRes.exists()) {
			reset(DRIVER_DEFAULT_VALUES);
			const driverData = driverRes.data();
			setDriverData(driverData);
			reset(driverData);
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const onSubmit = async (data) => {
		setIsloading(true);
		showLoadingToastMessage("Saving driver details...");
		console.log("data", data);
	  
		try {
		  if (!(await checkDriverEmailAvailabilityOnUpdate(data.email))) {
			setIsloading(false);
			showErrorToastMessage("Email already exists");
			return;
		  }
		  await updateDoc(doc(db, "drivers", driverId), data);
		  showSuccessToastMessage("Driver details saved successfully");
		  //navigate("/admin/assets/drivers");
		} catch (error) {
		  console.error("Error during submission:", error);
		  showInternalServerErrorToastMessage();
		} finally {
		  setIsloading(false);
		}
	  };
	const checkDriverEmailAvailabilityOnUpdate = async (email) => {
		const docRes = await getDocs(query(collection(db, "drivers"), where("email", "==", email)));
		if (docRes.docs.length === 1 && docRes.docs[0].id !== driverId) {
			setError("email", { type: "manual", message: "Email already exists" }, { shouldFocus: true });
			return false;
		} else if (docRes.docs.length > 1) {
			setError("email", { type: "manual", message: "Email already exists" }, { shouldFocus: true });
			return false;
		}
		return true;
	};

	const driverStatuses = [
		{ label: "Active", value: "active" },
		{ label: "Inactive", value: "inactive" },
	];

	const daysOfWeek = [
		{ label: "Monday", value: "Monday" },
		{ label: "Tuesday", value: "Tuesday" },
		{ label: "Wednesday", value: "Wednesday" },
		{ label: "Thursday", value: "Thursday" },
		{ label: "Friday", value: "Friday" },
		{ label: "Saturday", value: "Saturday" },
		{ label: "Sunday", value: "Sunday" },
	];

	const handleback = () => navigate("/admin/assets/drivers");

	const handleLoginEmail = async () => {
		try {
			const data = {
				template: {
					data: {
						email: driverData.email,
						password: driverData.password,
					},
					name: EMAIL_TEMPLATES.DRIVER_LOGIN_TEMPLATE,
				},
				to: [driverData.email],
				createdAt: serverTimestamp(),
			};
			console.log("driver data", driverData);
			showLoadingToastMessage("Sending email...");
			await addDoc(collection(db, "mails"), data);

			setIsButtonDisabled(true); // Disable button after success
			showSuccessToastMessage("Email sent Successfully");

			console.log("HandleLoginEmail");
		} catch (error) {
			console.error("Error sending email:", error);
			showErrorToastMessage("Failed to send email");
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="bg-white p-8 m-6 py-2 flex flex-col  rounded-cardRadii flex-grow"
		>
			<h6 className="font-medium text-lg py-2 text-black">Driver Profile</h6>

			<hr className="border-b-inputLabel pb-2" />

			<div className="grid gap-x-8 gap-y-2 grid-cols-2 ">
				<div className="flex flex-col space-y-4">
					<div className="flex items-center justify-between col-span-1">
						<label htmlFor={"firstName"} className="text-inputLabel font-normal mt-3 w-32">
							First Name*
						</label>

						<div className="w-2/3">
							<Controller
								name="firstName"
								control={control}
								rules={{ required: "First Name is required" }}
								render={({ field }) => {
									return <Input {...field} />;
								}}
							/>
							{errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between col-span-1">
						<label htmlFor="middleInitial" className="text-inputLabel font-normal mt-3 w-32">
							Middle Initial
						</label>
						<div className="w-2/3">
							<Controller
								name="middleInitial"
								control={control}
								rules={{
									pattern: {
										value: /^[A-Za-z]$/,
										message: "Middle Initial must be a single letter",
									},
								}}
								render={({ field, fieldState: { error } }) => (
									<>
										<Input
											{...field}
											maxLength={1}
											onChange={(e) => {
												const value = e.target.value
													.replace(/[^A-Za-z]/g, "")
													.slice(0, 1)
													.toUpperCase();
												field.onChange(value);
											}}
											value={field.value?.toUpperCase() || ""}
										/>
										{error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
									</>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between col-span-1">
						<label htmlFor={"lastName"} className="text-inputLabel font-normal mt-3 w-32">
							Last Name*
						</label>
						<div className="w-2/3">
							<Controller
								name="lastName"
								control={control}
								render={({ field }) => <Input {...field} />}
								rules={{ required: "Last Name is required" }}
							/>
							{errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"OctoDriverId"} className="text-inputLabel font-normal mt-3 w-32">
							OCTO ID #*
						</label>

						<div className="w-2/3">
							<Controller
								name="OctoDriverId"
								control={control}
								rules={{
									required: "OCTO ID is required",
								}}
								render={({ field }) => <Input {...field} onChange={() => {}} readOnly />}
							/>
							{errors.OctoDriverId && <p className="text-red-500 text-sm mt-1">{errors.OctoDriverId.message}</p>}
						</div>
					</div>

					<Controller
						name="phoneNumber"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<>
								<PhoneInput id="phoneNumber" value={field.value} onChange={field.onChange} isDisabled={false} />
								{error && <p className="text-red-600 text-sm mt-1">{error.message}</p>}
							</>
						)}
					/>

					<div className="flex items-center justify-between">
						<label htmlFor={"email"} className="text-inputLabel font-normal mt-3 w-32">
							Email / Username *
						</label>

						<div className="w-2/3">
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: "Invalid email address",
									},
								}}
								render={({ field }) => <Input {...field} />}
							/>

							{errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor="password" className="text-inputLabel font-normal mt-3 w-32">
							Password*
						</label>

						<div className="w-2/3 relative">
							<Controller
								name="password"
								control={control}
								rules={{
									required: "Password is required",
									minLength: {
										value: 8,
										message: "Password must be at least 8 characters long",
									},
								}}
								render={({ field }) => (
									<div className="relative">
										<Input {...field} type={showPassword ? "text" : "password"} className="pr-10" />
										<button
											type="button"
											className="absolute inset-y-0 right-0 pr-3 flex items-center"
											onClick={togglePasswordVisibility}
										>
											{!showPassword ? (
												<FaEyeSlash className="h-5 w-5 text-gray-400" />
											) : (
												<FaEye className="h-5 w-5 text-gray-400" />
											)}
										</button>
									</div>
								)}
							/>

							{errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-4">
						<label htmlFor="dateOfBirth" className="text-inputLabel font-normal mt-3 w-32">
							Date of Birth
						</label>

						<div className="w-2/3">
							<Controller
								name="dateOfBirth"
								control={control}
								render={({ field: { value, onChange } }) => (
									<CustomDatePicker
										startYear={1970}
										endYear={new Date().getFullYear()}
										selectedDate={value}
										setSelectedDate={onChange}
										label={"Date of Birth"}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between ">
						<label htmlFor="driverLicenseInfo" className="text-inputLabel font-normal mt-3 w-32">
							Driver License Number
						</label>

						<div className="w-2/3">
							<Controller name="driverLicenseInfo" control={control} render={({ field }) => <Input {...field} />} />

							{errors.driverLicenseInfo && (
								<p className="text-red-500 text-sm mt-1">{errors.driverLicenseInfo.message}</p>
							)}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor="licenseExpirationDate" className="text-inputLabel font-normal mt-3 w-32">
							License Expiration Date
						</label>
						<div className="w-2/3">
							<Controller
								name="licenseExpirationDate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<CustomDatePicker
										startYear={1970}
										endYear={2050}
										selectedDate={value}
										setSelectedDate={onChange}
										label={"Date of Birth"}
									/>
								)}
							/>

							{errors.licenseExpirationDate && (
								<p className="text-red-500 text-sm mt-1">{errors.licenseExpirationDate.message}</p>
							)}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"emergencyContactPerson"} className="text-inputLabel font-normal mt-3 w-32">
							Emergency Contact Person
						</label>
						<div className="w-2/3">
							<Controller
								name="emergencyContactPerson"
								control={control}
								render={({ field }) => <Input {...field} />}
							/>
							{errors.emergencyContactPerson && (
								<p className="text-red-500 text-sm mt-1">{errors.emergencyContactPerson.message}</p>
							)}
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"emergencyContactPhone"} className="text-inputLabel font-normal mt-3 w-32">
							Emergency Contact Phone
						</label>
						<div className="w-2/3">
							<Controller
								name="emergencyContactPhone"
								control={control}
								render={({ field }) => (
									<PhoneInput label="" id="emergencyContactPhone" value={field.value} onChange={field.onChange} />
								)}
							/>
							{errors.emergencyContactPhone && (
								<p className="text-red-600 text-sm mt-1">{errors.emergencyContactPhone.message}</p>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col space-y-4">
					<div className="flex items-center justify-between">
						<label htmlFor={"driverLocation"} className="text-inputLabel font-normal mt-3 w-32">
							Driver Location Assignment *
						</label>

						<div className="w-2/3 ">
							<Controller
								name={`driverLocation`}
								control={control}
								rules={{ required: "Driver Location Assignment is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<MultiselectRounded
											styles="flex flex-col w-full gap-1"
											value={value}
											onChange={onChange}
											options={locationOptions}
											id="driverLocation"
										/>
									</div>
								)}
							/>
							{errors?.driverLocation && <p className="text-red-500 text-sm mt-1">{errors.driverLocation.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"startDate"} className="text-inputLabel font-normal mt-3 w-32">
							Start Date
						</label>

						<div className="w-2/3">
							<Controller
								name="startDate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<CustomDatePicker
										startYear={1970}
										endYear={2050}
										selectedDate={value}
										setSelectedDate={onChange}
										label={"Date of Birth"}
									/>
								)}
							/>

							{errors.startDate && <p className="text-red-500 text-sm mt-1">{errors.startDate.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"hourlyWage"} className="text-inputLabel font-normal mt-3 w-32">
							Hourly Wage
						</label>

						<div className="w-2/3">
							<Controller
								name="hourlyWage"
								control={control}
								render={({ field }) => (
									<USDInput
										id="hourlyWage"
										value={field.value}
										onChange={(value) => field.onChange(value)}
										placeholder="$0.00"
										IsRequired={true}
									/>
								)}
							/>
							{errors.hourlyWage && <p className="text-red-500 text-sm mt-1">{errors.hourlyWage.message}</p>}
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor={"scheduledWorkDays"} className="text-inputLabel font-normal mt-3 w-32">
							Days Scheduled to Work
						</label>

						<div className="w-2/3">
							<Controller
								name="scheduledWorkDays"
								control={control}
								render={({ field: { onChange, value } }) => (
									<MultiselectRounded
										styles="flex flex-col w-full gap-1"
										value={value}
										onChange={onChange}
										options={daysOfWeek}
										id="scheduledWorkDays"
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"scheduledWorkHours"} className="text-inputLabel font-normal mt-3 w-32">
							Hours Scheduled to Work
						</label>

						<div className="w-2/3">
							<Controller name="scheduledWorkHours" control={control} render={({ field }) => <Input {...field} />} />
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"zebraPrintingSize"} className="text-inputLabel font-normal mt-3 w-32">
							ZebraPrinting Default Size
						</label>

						<div className="w-2/3">
							<Controller
								name="zebraPrintingSize"
								control={control}
								render={({ field }) => (
									<Dropdown
										options={[
											{ label: "Small", value: "small" },
											{ label: "Medium", value: "medium" },
											{ label: "Large", value: "large" },
										]}
										{...field}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"limitedDutyStatus"} className="text-inputLabel font-normal mt-3 w-32">
							Duty Status
						</label>

						<div className="w-2/3">
							<Controller
								name="limitedDutyStatus"
								control={control}
								render={({ field }) => (
									<Dropdown
										options={[
											{ label: "Full Duty", value: "Full Duty" },
											{ label: "Limited Duty", value: "Limited Duty" },
											{ label: "Off Duty", value: "Off Duty" },
										]}
										{...field}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<label htmlFor={"driverStatus"} className="text-inputLabel font-normal mt-3 w-32">
							Driver Status
						</label>

						{/* label="Driver Status" */}
						<div className="w-2/3">
							<Controller
								name="driverStatus"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Dropdown options={driverStatuses} value={value} onChange={onChange} styles="flex-col min-w-full" />
								)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-between mt-3">
						<label htmlFor="licensePhoto" className="truncate text-inputLabel font-normal mt-3 w-32">
							Copy of license
						</label>

						<div className="w-2/3">
							<Controller
								name="licensePhoto"
								control={control}
								// rules={{ required: "File attachment is required" }}
								render={({ field: { value, onChange } }) => (
									<div>
										<div
											className="flex items-center w-full p-2 px-4  bg-gray-100 rounded-full shadow-sm cursor-pointer hover:bg-gray-200"
											onClick={() => fileInputRef.current.click()}
										>
											<input
												ref={fileInputRef}
												type="file"
												className="hidden"
												accept=".png,.jpg,.jpeg"
												onChange={async (e) => {
													const file = e.target.files[0];
													setExistingFile(null); // Reset the existing file when a new file is selected
													if (!file) {
														showErrorToastMessage("No file selected.");
														return;
													}
													if (!file.type?.startsWith("image/")) {
														showErrorToastMessage("File type is not supported.");
														return;
													}
													const fileExtension = file.name.split(".").pop();
													const newFileName = `${Date.now()}.${fileExtension}`;
													const renamedFile = new File([file], newFileName, { type: file.type });
													// Update the file in the form state
													console.log("renamedFile", renamedFile);
													onChange(renamedFile);
													const storageRef = ref(storage, `/admin1/driverlicense/${renamedFile.name}`);
													const snapshot = await uploadBytes(storageRef, renamedFile);
													const downloadURL = await getDownloadURL(snapshot.ref);
													updateDoc(doc(db, "drivers", driverId), {
														licensePhoto: { name: renamedFile.name, url: downloadURL },
													});
													setValue("licensePhoto", { name: renamedFile.name, url: downloadURL });
												}}
											/>
											<span className="text-gray-400 flex-grow truncate">
												{existingFile ? existingFile?.name : value ? value.name : "No file selected"}
											</span>
											<BsFileEarmarkArrowUp className="text-gray-500" size={20} />
										</div>

										{/* Display the uploaded file or existing file preview */}
										{formValues?.licensePhoto ? (
											<div className="mt-2 bg-gray-50 p-2 pb-8 rounded-md shadow-sm w-full h-40">
												<p className="text-sm text-gray-600">Uploaded File:</p>
												{formValues?.licensePhoto?.url ? (
													<img
														src={formValues.licensePhoto.url}
														alt="Existing Uploaded Preview"
														className="w-full h-full object-contain"
													/>
												) : (
													formValues?.licensePhoto?.type &&
													(formValues?.licensePhoto?.type?.startsWith("image/") ? (
														<img
															src={URL.createObjectURL(value)}
															alt="Uploaded Preview"
															className="w-full h-full object-contain"
														/>
													) : (
														<p className="mt-2 text-gray-600">{"File type is not supported."}</p>
													))
												)}
											</div>
										) : null}
									</div>
								)}
							/>
							{errors.licensePhoto && <p className="text-red-500 text-sm mt-1">{errors.licensePhoto.message}</p>}
						</div>
					</div>
				</div>
			</div>
			<div className="flex items-center justify-between pt-2">
				<h6 className="font-medium text-lg py-5 text-black">OCTO Field App Access</h6>
				<button
					className={`px-5 py-2 rounded-cardRadii ${
						isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-[#c2e7ff]"
					}`}
					type="button"
					onClick={handleLoginEmail}
				>
					Send OCTO Field App Login Instructions{" "}
				</button>
			</div>

			<hr className="border-b-inputLabel pb-2" />

			<div className="grid gap-5 grid-cols-3 py-4">
				<Controller
					name="portalAccess.callCustomer"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Call customer" isChecked={value} setIsChecked={onChange} />
					)}
				/>

				<Controller
					name="portalAccess.emailCustomer"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Checkbox label="Email customer" isChecked={value} setIsChecked={onChange} />
					)}
				/>
			</div>

			<div className="flex justify-end gap-8 py-3">
				<Button btnStyle="form_nav_secondary" text={"View Driver List"} onClick={handleback} />

				<Button btnStyle="form_nav_primary" text={isLoading ? " Saving..." : "Save"} type="submit" />
			</div>
		</form>
	);
};

const DRIVER_DEFAULT_VALUES = {
	firstName: "",
	middleInitial: "",
	lastName: "",
	email: "",
	OctoDriverId: "",
	username: "",
	password: "",
	phoneNumber: "",
	zebraPrintingSize: "",
	driverLicenseInfo: "",
	driverLocation: "",
	licensePhoto: null,
	hourlyWage: "",
	startDate: null,
	dateOfBirth: null,
	scheduledWorkDays: [],
	scheduledWorkHours: "",
	limitedDutyStatus: "",
	driverStatus: "",
	licenseExpirationDate: "",
	emergencyContactPerson: "",
	emergencyContactPhone: "",
	portalAccess: {
		editCustomerInfo: false,
		adjustRoute: false,
		postponeStop: false,
		editPickups: false,
		weighPickups: false,
		adjustPricing: false,
		callCustomer: true,
		emailCustomer: true,
		billCustomersAndCollectPayments: false,
	},
};

export default AddDriverForm;
