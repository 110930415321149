import React from "react";
import { HiArrowNarrowUp } from "react-icons/hi";

const DashInfoCard = ({ cardColor = "green", title = "", count = "", countData = {}, changePercentage = "" }) => {
	const isGreen = cardColor === "green";
	const bgColor = isGreen ? "bg-primaryGreen" : "bg-lightBlue";
	const textColor = isGreen ? "text-white" : "text-white";
	const subtitleColor = isGreen ? "text-white" : "text-white";
	const arrowColor = isGreen ? "text-cardTextGreen" : "text-cardTextBlue";

	return (
		<div className={`${bgColor} text-white rounded-cardRadii p-4 md:p-6 flex justify-between flex-col gap-1`}>
			<p className={`text-sm md:text-lg ${subtitleColor}`}>{title}</p>
			<h1 className={`text-xl md:text-2xl font-bold ${textColor}`}>{countData.total}</h1>
			<div className="flex items-center gap-4">
				<div className="flex items-center gap-2">
					<p className={`text-sm md:text-base`}>{countData.label1}</p>
					<p className={`text-sm md:text-base`}>{countData.count1}</p>
				</div>
				<div className="flex items-center gap-2">
					<p className={`text-sm md:text-base`}>{countData.label2}</p>
					<p className={`text-sm md:text-base`}>{countData.count2}</p>
				</div>
			</div>
		</div>
	);
};

export default DashInfoCard;
