import PropTypes from "prop-types";
import React from "react";

const SquarePin = ({ className, text, color }) => {
	return (
		<svg className={`${className}`} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3708_7664)">
				<path
					d="M14 1.45232V12.6663C14 13.0514 13.8483 13.4207 13.5783 13.693C13.3083 13.9653 12.942 14.1183 12.5602 14.1183H10.3651L7.44177 18.2696C7.39171 18.3408 7.32549 18.3988 7.24865 18.4388C7.1718 18.4788 7.08657 18.4997 7.00007 18.4997C6.91358 18.4997 6.82835 18.4788 6.7515 18.4388C6.67466 18.3988 6.60844 18.3408 6.55838 18.2696L3.63493 14.1183H1.44012C1.25102 14.1183 1.06377 14.0808 0.889058 14.0078C0.714345 13.9348 0.555592 13.8279 0.421864 13.693C0.288136 13.5582 0.182052 13.3981 0.109669 13.222C0.0372851 13.0458 1.94278e-05 12.857 0 12.6663V1.45232C3.92309e-05 1.06715 0.151779 0.697773 0.421846 0.425418C0.691913 0.153064 1.05819 3.95632e-05 1.44012 0H12.5599C12.9418 0 13.3081 0.153012 13.5782 0.425375C13.8483 0.697738 14 1.06714 14 1.45232Z"
					fill={color}
				/>
				<path
					d="M11.6536 1.70312H2.31135C2.15666 1.70312 2.03125 1.82959 2.03125 1.9856V11.407C2.03125 11.563 2.15666 11.6894 2.31135 11.6894H11.6536C11.8083 11.6894 11.9337 11.563 11.9337 11.407V1.9856C11.9337 1.82959 11.8083 1.70312 11.6536 1.70312Z"
					fill="white"
				/>
				<text x="7" y="7" fontSize="8" textAnchor="middle" dominantBaseline="middle" fill="black">
					{text}
				</text>
			</g>
			<defs>
				<clipPath id="clip0_3708_7664">
					<rect width="14" height="18.5" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SquarePin;
