import { useEffect, useRef, useState, useTransition } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReportCheckbox from "./ReportCheckBox";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../components/UI/loaders/Loader";
import {
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	documentId,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	updateDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import { auth, COLLECTIONS, db, getDeliveryUrl, getManifestUrl } from "../../../../../config/firebase";
import {
	itemsMap,
	SERVICE_STATUS,
	serviceTypes,
	serviceTypesMap,
	subWasteTypesMap,
	transferStatusMap,
	transferStatusType,
} from "../../../../../utils/constants";
import DropdownReport from "./ReportDropDown";
import {
	dateFormatter,
	formattedDate,
	formattedDateTime,
	octoFormatter,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../../../utils/helpers";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { FiSearch } from "react-icons/fi";
import { getAllDrivers, getAllRoutes, getAllTreatmentOptions } from "../../../../../utils/firebaseOperations";
import { useAuthState } from "react-firebase-hooks/auth";
import Button from "../../../../../components/UI/Button";
import useTUserContext from "../../../../../context/TransporterUserContext";
import { toast } from "react-toastify";
import { BiTrash } from "react-icons/bi";

const transStatusOptions = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "In Transit",
		value: transferStatusType.ON_TRUCK,
	},
	{
		label: "Transferred",
		value: transferStatusType.COMPLETE,
	},
];

const ServiceReporting = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [completeServices, setCompleteServices] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [from, setFrom] = useState(() => new Date());
	const [to, setTo] = useState(new Date());
	const [pickupOptions, setPickupOptions] = useState([]);
	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [driverOptions, setDriverOptions] = useState([]);
	const [contractorOptions, setContractorOptions] = useState([]);
	const [subContractorOptions, setSubContractorOptions] = useState([]);
	const [selectedTransStatusOptions, setSelectedTransStatusOptions] = useState(
		transStatusOptions.map((item) => item.value)
	);
	const [selectedPickupOptions, setSelectedPickupOptions] = useState([]);
	const [selectedServices, setSelectedServices] = useState([]);
	const [selectedDrivers, setSelectedDrivers] = useState([]);
	const [genIds, setGenIds] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [totalWeight, setTotalWeight] = useState([]);
	const [isFiltering, startTransition] = useTransition();
	const [genIdsLoading, setIsGenIdsLoading] = useState(true);
	const [containersMap, setContainersMap] = useState({});
	const [delContainersMap, setDelContainersMap] = useState({});
	const [transporterData, setTransporterData] = useState(null);
	const [newContainers, setNewContainers] = useState([]);
	const [newDelContainers, setNewDelContainers] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [isDeleting, setIsDeleting] = useState(null);
	const [editing, setEditing] = useState({
		serviceId: null,
		section: null,
		consentAcceptance: false,
	});
	const [editedData, setEditedData] = useState({});
	const { user, loading } = useTUserContext();
	const selectAllRef = useRef();

	const fetchAllDriverOptions = async () => {
		try {
			const resp = await getAllDrivers(user?.uid);
			const options = [];
			resp.map((item) => {
				let label = item.firstName + " " + item.lastName;
				options.push({
					label,
					value: label,
				});
			});

			setDriverOptions([{ label: "All", value: "all" }, ...options]);
			setSelectedDrivers(["all", ...options.map((item) => item.value)]);
			fetchDocuments();
		} catch (error) {
			console.log("Error fetching drivers", error);
		}
	};

	const fetchDocuments = async () => {
		if (!user) return;

		try {
			const q = query(collection(db, COLLECTIONS.generators), where("transporterId", "==", user?.uid));
			const querySnapshot = await getDocs(q);

			const ids = querySnapshot.docs.map((doc) => doc.id);
			setGenIds(ids);
		} catch (error) {
			console.error("Error fetching documents:", error);
		} finally {
			setIsGenIdsLoading(false);
		}
	};

	const fetchAllTransferOptions = async () => {
		try {
			const resp = await getAllTreatmentOptions(user?.uid);
			const options = [];
			let map = {};
			resp.map((item) => {
				options.push({
					label: item.name,
					value: item.id,
				});
				map[item.id] = item.name;
			});
			setPickupOptions([{ label: "All", value: "all" }, ...options]);
			setSelectedPickupOptions(["all", ...options.map((item) => item.value)]);
		} catch (error) {
			console.log("Error fetching routes", error);
		}
	};

	useEffect(() => {
		const fetchTransporterData = async () => {
			if (!user?.uid) return;
			let docSnapshot = await getDoc(doc(db, COLLECTIONS.transporters, user.uid));
			if (docSnapshot.exists()) {
				let data = { ...docSnapshot.data(), id: docSnapshot.id };
				setTransporterData(data);
			}
		};

		fetchTransporterData();
	}, [user]);

	useEffect(() => {
		if (user && !genIdsLoading) viewButtonHandler();
	}, [to, from, user, genIdsLoading]);

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "N/A";
		}
		return Array.isArray(scheduleData.serviceType)
			? scheduleData.serviceType.reduce((accumulator, serviceType) => {
					const matchingService = serviceTypes.find((service) => service.value === serviceType);
					return accumulator.concat(matchingService ? matchingService.label : "N/A");
			  }, [])
			: scheduleData.serviceType;
	};

	const getFormattedAddress = (add) => {
		const addressParts = [add.serviceAddress?.street, add.serviceAddress?.city, add.serviceAddress?.state].filter(
			Boolean
		);

		const address = addressParts.length
			? addressParts.join(", ") + (add.serviceAddress?.zipCode ? ` ${add.serviceAddress.zipCode}` : "")
			: "--";

		return address;
	};

	useEffect(() => {
		const fetchContainers = async () => {
			try {
				const containersRef = collection(db, "defaultPriceBook", "services", "containers");
				const snapshot = await getDocs(containersRef);

				const containersData = {};
				snapshot.forEach((doc) => {
					containersData[doc.id] = doc.data().labelOCTOFieldApp;
				});

				setContainersMap(containersData);
			} catch (error) {
				console.error("Error fetching containers:", error);
			}
		};

		fetchContainers();
	}, []);

	useEffect(() => {
		const currentSelected = selectedRows.length;
		const total = filteredData.length;
		const allSelected = currentSelected === total && total > 0;
		const isIndeterminate = currentSelected > 0 && currentSelected < total;

		setSelectAll(allSelected);
		setIndeterminate(isIndeterminate);

		if (selectAllRef.current) {
			selectAllRef.current.indeterminate = isIndeterminate;
		}
	}, [selectedRows, filteredData]);

	useEffect(() => {
		const fetchDelContainers = async () => {
			try {
				const containersRef = collection(db, "defaultPriceBook", "products", "containers");
				const snapshot = await getDocs(containersRef);

				const containersData = {};
				snapshot.forEach((doc) => {
					containersData[doc.id] = doc.data().labelOCTOFieldApp;
				});

				setDelContainersMap(containersData);
			} catch (error) {
				console.error("Error fetching containers:", error);
			}
		};

		fetchDelContainers();
	}, []);

	const getSummaryData = (consolidated, itemized) => {
		console.log({ consolidated, itemized });

		const summary = [];
		consolidated?.forEach((el, index) =>
			summary.push({
				...el,
				_source: "consolidated",
				_index: index,
			})
		);
		itemized?.forEach((el, index) =>
			summary.push({
				...el,
				_source: "itemized",
				_index: index,
			})
		);
		console.log({ summary });

		return summary;
	};

	const viewButtonHandler = async () => {
		if (!from) {
			showErrorToastMessage("Please select from date.");
			return;
		}
		if (!to) {
			showErrorToastMessage("Please select to date.");
			return;
		}
		const utcFromDate = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0);
		const utcToDate = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59, 999);

		console.log({ utcFromDate, utcToDate });
		let q = query(
			collection(db, "scheduledServices"),
			where("completedAt", ">=", utcFromDate),
			where("completedAt", "<=", utcToDate),
			where("status", "==", SERVICE_STATUS.COMPLETE)
			// where("transporterId", "==", user.uid)
		);

		try {
			if (!isLoading) setIsLoading(true);
			const snap = await getDocs(q);

			let tempTotalWeight = 0;
			let tempTotalQuantity = 0;

			const allCompletedServices = [];
			let allDocs = snap.docs
				.map((doc) => ({ id: doc.id, ...doc.data() }))
				.filter((doc) => genIds.includes(doc.generatorId));

			const jobs = allDocs.map(async (serviceData) => {
				if (serviceData?.consolidated?.length > 0) {
					serviceData.consolidated.forEach((el) => {
						tempTotalWeight += parseFloat(`${el.weight}`);
						tempTotalQuantity += parseFloat(`${el.quantity}`);
					});
				}
				if (serviceData?.itemized?.length > 0) {
					serviceData.itemized.forEach((el) => {
						tempTotalWeight += parseFloat(`${el.weight}`);
						tempTotalQuantity++;
					});
				}
				// Fetch servicedContainers data
				let transferStatus = "COMPLETE";
				if (Array.isArray(serviceData.consolidated) && serviceData.consolidated.length > 0) {
					console.log({ consolidated: serviceData.consolidated });

					const containerRefs = serviceData.consolidated
						.map((item) => (item.id ? doc(db, COLLECTIONS.servicedContainers, item.id) : null))
						?.filter(Boolean);

					console.log({ containerRefs, serviceId: serviceData.id });

					const containerDocs = await Promise.all(containerRefs.map((ref) => getDoc(ref)));
					const allContainersExist = containerDocs.every((doc) => doc.exists());
					let allDocs = containerDocs.map((item) => item.data());
					const allComplete = containerDocs.every((doc) => doc.exists() && doc.data()?.transferStatus === "COMPLETE");

					serviceData.transferStatus = allContainersExist && allComplete ? "COMPLETE" : "INPROGRESS";
				} else {
					serviceData.transferStatus = "PENDING";
				}

				const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
				const scheduleRes = await getDoc(scheduleDataRef);
				if (!scheduleRes.exists()) return;
				const scheduleData = scheduleRes.data();

				const serviceTypeString = getStringOfServiceTypes(scheduleData);
				const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
				const generatorRes = await getDoc(generatorDataRef);
				if (!generatorRes.exists()) return;
				const generatorData = generatorRes.data();

				const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
				const routeRes = await getDoc(routeDataRef);
				if (!routeRes.exists()) return;
				const routeData = routeRes.data();

				const manifestDataRef = collection(db, COLLECTIONS.manifests);
				const manifestQuery = query(manifestDataRef, where("serviceId", "==", serviceData.id));
				const manifestRes = await getDocs(manifestQuery);
				let manifestData = manifestRes.docs.map((item) => ({ id: item.id, ...item.data() }));
				// Delivery Summary
				const transDataRef = collection(db, COLLECTIONS.deliveries);
				const transQuery = query(transDataRef, where("serviceId", "==", serviceData.id));
				const transRes = await getDocs(transQuery);
				let transData = transRes.docs.map((item) => ({ id: item.id, ...item.data() }));
				let deliverySummary = [],
					deliveryReceipt = null;
				if (transData.length > 0) {
					deliveryReceipt = {
						deliveryId: transData?.[0]?.id,
						deliveryOctoId: transData?.[0]?.deliveryOctoId,
						url: transData?.[0]?.url,
					};
					let allDeliveredContainers = transData?.[0]?.containers;
					const delContainerRefs = allDeliveredContainers.map((item) => doc(db, COLLECTIONS.deliveredContainers, item));
					const delContainerDocs = await Promise.all(delContainerRefs.map((ref) => getDoc(ref)));
					const allDelContainersData = delContainerDocs.map((doc) => ({ id: doc.id, ...doc.data() }));
					deliverySummary = allDelContainersData;
				}

				// console.log({ serviceData });
				if (serviceData.id == "zVLXrLBPlci5aILCJLeg") {
					console.log({ serviceData, generatorData, routeData });
				}

				allCompletedServices.push({
					id: serviceData.id,
					date: serviceData.date,
					completedAt: serviceData.completedAt,
					time: serviceData.date.toDate().toLocaleTimeString(),
					driverNote: serviceData?.driverNote?.length > 0 ? serviceData?.driverNote : "--",
					customerId: generatorDataRef.id,
					generatorId: generatorData.id,
					serviceId: serviceData.id,
					scheduleId: serviceData.serviceScheduleId,
					generatorName: `${generatorData?.generatorName ?? "--"} `,
					email: generatorData.serviceAddress?.email?.length ? generatorData.serviceAddress?.email : "--",
					phone: generatorData.serviceAddress?.phone?.length ? generatorData.serviceAddress?.phone : "",
					internalAccountNumber: generatorData.internalAccountNumber?.length
						? generatorData.internalAccountNumber
						: "--",
					genId: generatorData.id,
					driverId: serviceData.assignedDriverId,
					deliveryId: transData.length > 0 ? transData?.[0].id : null,
					octoNumber: octoFormatter(generatorData.octoNumber) ?? "--",
					address: getFormattedAddress(generatorData?.serviceAddress),
					billed: Boolean(serviceData?.billed),
					billingMethod: serviceData?.billingMethod,
					billingNote: serviceData?.billingNote,
					billedDate: serviceData?.billedDate?.toDate()?.toLocaleDateString() ?? null,
					paidDate: serviceData?.paidDate?.toDate()?.toLocaleDateString() ?? null,
					paid: serviceData?.isPaid ?? false,
					paidAmount: serviceData?.paidAmount ?? "--",
					picture: Array.isArray(serviceData?.serviceImages) ? serviceData?.serviceImages : [],
					optionChecked: false,
					driverName: `${serviceData?.assignedDriverName ?? "--"}`,
					pickUpTruck: `${serviceData?.assignedServiceVehicleName ?? "--"}`,
					serviceType: serviceTypeString ?? "--",
					routeName: routeData?.routeLabel ?? "--",
					routeId: routeRes.id ?? "--",
					quantity: tempTotalQuantity ?? "--",
					totalWeight: tempTotalWeight ?? "--",
					serviceVehicleId: serviceData.assignedServiceVehicleId,
					transporterId: user.uid,
					serviceStatus: serviceData?.status ?? "--",
					transferStatus: serviceData?.transferStatus,
					serviceSummary: getSummaryData(serviceData.consolidated, serviceData.itemized),
					deliverySummary,
					deliveryReceipt,
					noWaste: serviceData?.noWaste,
					manifestData: manifestData?.filter(
						(manifest, index, self) => index === self.findIndex((m) => m.manifestOctoId === manifest.manifestOctoId)
					),
				});
			});

			await Promise.all(jobs);
			const tempCompleteService = allCompletedServices
				?.filter((item) => item.serviceStatus === "COMPLETE")
				?.sort((a, b) => b.completedAt - a.completedAt)
				?.map((item) => ({
					...item,
					completedAt: item.completedAt,
				}));
			setTotalWeight(tempTotalWeight);
			setCompleteServices(tempCompleteService);
			setSelectedRows([]);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const normalizedSearchQuery = searchQuery.toLowerCase().replace(/\s+/g, "");

	useEffect(() => {
		startTransition(() => {
			let matchedServices = completeServices.filter((data) =>
				[data.generatorName, data.phone, data.email, data.address, data.octoNumber, data.internalAccountNumber].some(
					(field) => field?.toString()?.toLowerCase().replace(/\s+/g, "").includes(normalizedSearchQuery)
				)
			);
			matchedServices = matchedServices.filter((item) =>
				selectedServices.includes("all") ? true : selectedServices.includes(item.serviceType)
			);
			console.log({ matchedServices, selectedServices });
			matchedServices = matchedServices.filter((item) =>
				selectedDrivers.includes("all") ? true : selectedDrivers.includes(item.driverName)
			);
			console.log({ matchedServices, selectedDrivers });
			matchedServices = matchedServices.filter((item) =>
				selectedRoutes.includes("all") ? true : selectedRoutes.includes(item.routeId)
			);
			console.log({ matchedServices, selectedRoutes });
			matchedServices = selectedTransStatusOptions.includes("all")
				? matchedServices
				: matchedServices.filter((item) =>
						selectedTransStatusOptions.includes(
							item.transferStatus == transferStatusType.COMPLETE
								? transferStatusType.COMPLETE
								: transferStatusType.ON_TRUCK
						)
				  );
			console.log({ matchedServices, selectedTransStatusOptions });
			setFilteredData(matchedServices);
			setEditedData({});
			setEditing({ section: null, serviceId: null, consentAcceptance: false });
			setNewContainers([]);
			setNewDelContainers([]);
		});
	}, [
		completeServices,
		selectedDrivers,
		selectedPickupOptions,
		selectedServices,
		selectedRoutes,
		selectedTransStatusOptions,
		searchQuery,
	]);

	const fetchAllRoutesOptions = async () => {
		try {
			const resp = await getAllRoutes(user?.uid);
			const options = [];
			let map = {};
			resp
				?.filter((route) => route.status == "ACTIVE")
				.map((item) => {
					options.push({
						label: item.routeLabel,
						value: item.id,
					});
					map[item.id] = item.routeLabel;
				});
			setRouteOptions([{ label: "All", value: "all" }, ...options]);
			setServiceTypeOptions([{ label: "All", value: "all" }, ...serviceTypes]);
			setSelectedServices(["all", ...serviceTypes.map((item) => item.value)]);
			setSelectedRoutes(["all", ...options.map((item) => item.value)]);
		} catch (error) {
			console.log("Error fetching routes", error);
		}
	};

	useEffect(() => {
		if (user) {
			fetchAllDriverOptions();
			fetchAllRoutesOptions();
			fetchAllTransferOptions();
		}
	}, [user]);

	const handleDeliveryView = async (id, url) => {
		console.log("id,url", { id, url });

		if (url && url?.length > 0) {
			window.open(url, "_blank");
		} else {
			const fetchUrl = getDeliveryUrl(id);
			console.log("fetchUrl", fetchUrl);
			window.open(fetchUrl, "_blank");
		}
	};

	const handleBillingCheckbox = async (isChecked, serviceId, statusUpdateReason = "") => {
		let docRef = doc(db, COLLECTIONS.scheduledServices, serviceId);
		console.log({ isChecked, serviceId, statusUpdateReason });

		let toUpdate = {
			billed: isChecked,
			billedDate: isChecked ? Timestamp.now() : null,
			billingMethod: isChecked ? "internal" : "",
			billingNote: isChecked ? "Invoiced Through External Billing Platform" : statusUpdateReason,
		};

		try {
			await updateDoc(docRef, toUpdate);
			setCompleteServices((prevServices) =>
				prevServices.map((service) =>
					service.id === serviceId
						? {
								...service,
								...toUpdate,
								billedDate: isChecked ? Timestamp.now()?.toDate()?.toLocaleDateString() : null,
						  }
						: service
				)
			);

			toast.success(isChecked ? "Invoiced Through External Billing Platform!" : "Billing status updated.");
		} catch (error) {
			console.error("Error updating billing status:", error);
			toast.error("Failed to update billing status.");
		}
	};

	const handlePaidCheckbox = async (isChecked, serviceId, statusUpdateReason = "") => {
		let docRef = doc(db, COLLECTIONS.scheduledServices, serviceId);
		console.log({ isChecked, serviceId, statusUpdateReason });

		let toUpdate = {
			paid: isChecked,
			paidDate: isChecked ? Timestamp.now() : null,
			billingNote: isChecked ? "Payment recorded." : statusUpdateReason,
		};

		try {
			await updateDoc(docRef, toUpdate);
			setCompleteServices((prevServices) =>
				prevServices.map((service) =>
					service.id === serviceId
						? {
								...service,
								...toUpdate,
								paidDate: isChecked ? Timestamp.now()?.toDate()?.toLocaleDateString() : null,
						  }
						: service
				)
			);

			toast.success(isChecked ? "Payment marked as complete!" : "Payment status updated.");
		} catch (error) {
			console.error("Error updating payment status:", error);
			toast.error("Failed to update payment status.");
		}
	};

	const handleSelectAll = () => {
		if (selectedRows.length === filteredData.length) {
			setSelectedRows([]);
		} else {
			const allIds = filteredData.map((service) => service.id);
			setSelectedRows(allIds);
		}
	};

	const handleRowCheckbox = (e, serviceId) => {
		const isChecked = e.target.checked;
		setSelectedRows((prev) => (isChecked ? [...prev, serviceId] : prev.filter((id) => id !== serviceId)));
	};

	const handleEditClick = (serviceId, section) => {
		setEditing({ serviceId, section, consentAcceptance: false });
		const service = JSON.parse(JSON.stringify(filteredData.find((s) => s.id === serviceId)));
		setEditedData({
			serviceSummary: [...service.serviceSummary],
			deliverySummary: [...service.deliverySummary],
		});
		setNewContainers([]);
		setNewDelContainers([]);
	};

	const handleFieldChange = (serviceId, section, index, field, value) => {
		const newData = [...editedData[section]];
		newData[index][field] = value;
		setEditedData((prev) => ({ ...prev, [section]: newData }));
	};

	const handleSubWasteTypeChange = async (serviceId, section, index, field, value) => {
		const newData = [...editedData[section]];
		newData[index][field] = value;
		let resp = await fetchContainerData(value);
		console.log({ field, value, resp });
		newData[index]["subWasteType"] = resp.subWasteType;
		setEditedData((prev) => ({ ...prev, [section]: newData }));
	};

	const handleSave = async (serviceId) => {
		const serviceRef = doc(db, "scheduledServices", serviceId);
		const batch = writeBatch(db);
		const service = completeServices.find((s) => s.id === serviceId);
		console.log("service", service);
		try {
			let subWasteGroups = {};
			let oldSubWasteGroups = {};
			let newManifests = [];
			const newContainerIds = [];
			const deletedManifestIds = [];

			console.log({ newContainers, newDelContainers });

			if (editing.section === "service") {
				let newItemWithInvalidVal = newContainers.find(
					(item) => !item.containerType || item.containerType == "" || !Number(item.quantity)
				);
				if (newItemWithInvalidVal) {
					if (newItemWithInvalidVal.containerType == "") {
						toast.error("Container Type is required");
						return;
					}
					if (!Number(newItemWithInvalidVal.quantity)) {
						toast.error("Quantity must be non-zero value");
						return;
					}
				}
				console.log({ editedData });
				let itemWithInvalidVal = editedData.serviceSummary.find(
					(item) => !item.containerType || item.containerType == "" || !Number(item.quantity)
				);
				if (itemWithInvalidVal) {
					if (itemWithInvalidVal.containerType == "") {
						toast.error("Container Type is required");
						return;
					}
					if (!Number(itemWithInvalidVal.quantity)) {
						toast.error("Quantity must be non-zero value");
						return;
					}
				}
				const serviceUpdate = {
					consolidated: editedData.serviceSummary
						.filter((item) => item._source === "consolidated")
						.map(({ _source, _index, ...rest }) => rest),
					itemized: editedData.serviceSummary
						.filter((item) => item._source === "itemized")
						.map(({ _source, _index, ...rest }) => rest),
				};
				await Promise.all(
					editedData.serviceSummary.map(async (item) => {
						if (!item.id) return;
						const containerRef = doc(db, COLLECTIONS.servicedContainers, item.id);
						const containerDoc = await getDoc(containerRef);
						const containerData = containerDoc.data();
						console.log({ containerDoc, containerData });
						const oldSWT = containerData?.subWasteType;
						const newSWT = item.subWasteType;
						batch.update(containerRef, {
							quantity: Number(item.quantity) || 0,
							weight: Number(item.weight) || 0,
							subWasteType: newSWT,
							containerType: item.containerType,
						});
						subWasteGroups[newSWT] = [...(subWasteGroups[newSWT] || []), containerRef.id];
						if (oldSWT !== newSWT) {
							oldSubWasteGroups[oldSWT] = [...(oldSubWasteGroups[oldSWT] || []), containerRef.id];
						}
					})
				);
				// Processing NEW containers
				for (const newContainer of newContainers) {
					const containerRef = doc(collection(db, COLLECTIONS.servicedContainers));
					newContainerIds.push(containerRef.id);
					batch.set(containerRef, {
						...newContainer,
						serviceId,
						createdAt: Timestamp.now(),
						updatedAt: Timestamp.now(),
					});
					const swt = newContainer.subWasteType;
					subWasteGroups[swt] = [...(subWasteGroups[swt] || []), containerRef.id];
				}
				// Removing containers from old manifests
				Object.entries(oldSubWasteGroups).forEach(([oldSWT, containerIds]) => {
					const oldManifest = service.manifestData.find((m) => m.subWasteType === oldSWT);
					if (oldManifest) {
						const manifestRef = doc(db, COLLECTIONS.manifests, oldManifest.id);
						const originalContainers = oldManifest.containers || [];
						const remainingContainers = originalContainers.filter((id) => !containerIds.includes(id));
						if (remainingContainers.length === 0) {
							batch.delete(manifestRef);
							deletedManifestIds.push(oldManifest.id);
						} else {
							batch.update(manifestRef, {
								containers: arrayRemove(...containerIds),
								updatedAt: Timestamp.now(),
								url: null,
							});
						}
					}
				});
				// Add/update for new manifests
				const sampleManifest = service.manifestData[0];
				Object.entries(subWasteGroups).forEach(([newSWT, containerIds]) => {
					const existingManifest = service.manifestData.find((m) => m.subWasteType === newSWT);
					if (existingManifest) {
						const manifestRef = doc(db, COLLECTIONS.manifests, existingManifest.id);
						batch.update(manifestRef, {
							containers: arrayUnion(...containerIds),
							updatedAt: Timestamp.now(),
							url: null,
						});
					} else {
						const existingManifestOctoIds = service.manifestData.map((m) => m.manifestOctoId);
						const maxSuffix = existingManifestOctoIds.reduce((max, octoId) => {
							const parts = octoId.split("-");
							const suffix = parseInt(parts[parts.length - 1], 10);
							return suffix > max ? suffix : max;
						}, 0);
						const newSuffix = String(maxSuffix + 1).padStart(2, "0");
						const baseManifestId = sampleManifest.manifestOctoId.split("-").slice(0, -1).join("-");
						const newManifestOctoId = `${baseManifestId}-${newSuffix}`;
						const newManifestRef = doc(collection(db, COLLECTIONS.manifests));
						const newManifestData = {
							...(sampleManifest && {
								driverId: sampleManifest.driverId,
								generatorId: sampleManifest.generatorId,
								routeId: sampleManifest.routeId,
								scheduleId: sampleManifest.scheduleId,
								serviceVehicleId: sampleManifest.serviceVehicleId,
								transporterId: sampleManifest.transporterId,
							}),
							subWasteType: newSWT,
							containers: containerIds,
							serviceId: service.id,
							manifestOctoId: newManifestOctoId,
							createdAt: Timestamp.now(),
							updatedAt: Timestamp.now(),
							url: null,
						};
						batch.set(newManifestRef, newManifestData);
						newManifests.push({ ...newManifestData, id: newManifestRef.id });
					}
				});
				serviceUpdate.manifestData = [
					...service.manifestData.filter((m) => !subWasteGroups[m.subWasteType]),
					...newManifests.map(({ id, subWasteType }) => ({ id, subWasteType })),
				];
				serviceUpdate.consolidated = [
					...serviceUpdate.consolidated,
					...newContainers.map((c) => ({
						...c,
						id: newContainerIds.shift(),
						_source: "consolidated",
					})),
				];
				console.log({ serviceUpdate });
				if (service.noWaste) serviceUpdate.noWaste = service.noWaste;
				batch.update(serviceRef, serviceUpdate);
				await batch.commit();
				setCompleteServices((prev) =>
					prev.map((s) => {
						if (s.id !== serviceId) return s;
						const updatedManifests = [
							...s.manifestData.map((m) => {
								if (subWasteGroups[m.subWasteType]) {
									return {
										...m,
										containers: [...new Set([...m.containers, ...subWasteGroups[m.subWasteType]])],
										url: null,
									};
								}
								return m;
							}),
							...newManifests.filter((nm) => !s.manifestData.some((m) => m.subWasteType === nm.subWasteType)), // Prevents adding new manifests that already exist
						];
						return {
							...s,
							serviceSummary: [
								...editedData.serviceSummary,
								...newContainers.map((c) => ({
									...c,
									id: newContainerIds.shift(),
									_source: "consolidated",
								})),
							],
							manifestData: updatedManifests.filter((m) => m.containers?.length > 0),
						};
					})
				);
				// Updating filteredData
				setFilteredData((prev) =>
					prev.map((s) => (s.id === serviceId ? completeServices.find((cs) => cs.id === serviceId) : s))
				);
			}

			if (editing.section === "delivery") {
				console.log({ delId: service?.deliveryId });
				let invalidContainer = newDelContainers.find(
					(item) => !item.containerType || item.containerType == "" || !item.type || !Number(item.quantity)
				);
				if (invalidContainer) {
					if (invalidContainer.containerType == "") {
						toast.error("Container is required");
						return;
					}
					if (invalidContainer.type == "") {
						toast.error("Usage is required");
						return;
					}
					if (!Number(invalidContainer.quantity)) {
						toast.error("Quantity must be non-zero value");
						return;
					}
				}

				let itemWithInvalidVal = editedData.deliverySummary.find(
					(item) => !item.containerType || item.containerType == "" || !item.type || !Number(item.quantity)
				);
				if (itemWithInvalidVal) {
					if (!Number(itemWithInvalidVal.quantity)) {
						toast.error("Quantity must be non-zero value");
					}
					return;
				}

				const newDelContainerIds = [];
				let deliveryRef,
					deliveryDoc,
					deliveryCount = 0;

				if (!service?.deliveryId) {
					// Create a new delivery document
					deliveryRef = doc(collection(db, COLLECTIONS.deliveries));
					deliveryDoc = {
						id: deliveryRef.id,
						generatorId: service.generatorId,
						routeId: service.routeId,
						scheduleId: service.scheduleId,
						serviceId: service.serviceId,
						transporterId: service.transporterId,
						driverId: service.driverId,
						serviceVehicleId: service.serviceVehicleId,
						createdAt: Timestamp.now(),
						date: service?.completedAt ?? Timestamp.now(),
						updatedAt: Timestamp.now(),
						containers: [],
					};

					batch.set(deliveryRef, deliveryDoc);
				} else {
					// Fetch existing delivery document
					deliveryRef = doc(db, COLLECTIONS.deliveries, service.deliveryId);
					const deliverySnap = await getDoc(deliveryRef);

					if (deliverySnap.exists()) {
						deliveryDoc = { id: deliverySnap.id, ...deliverySnap.data() };
					} else {
						return toast.error("Couldn't find any delivery for this service");
					}
				}

				deliveryCount = deliveryDoc.containers.length;

				// Fetch existing delivery containers
				const existingContainerDocs = [];
				if (deliveryDoc.containers.length > 0) {
					const existingContainersQuery = query(
						collection(db, COLLECTIONS.deliveredContainers),
						where(documentId(), "in", deliveryDoc.containers)
					);
					const existingContainersSnap = await getDocs(existingContainersQuery);
					existingContainersSnap.forEach((doc) => {
						existingContainerDocs.push({ id: doc.id, ...doc.data() });
					});
				}

				let updatedContainers = newDelContainers.map((newContainer, index) => {
					let existingContainer = existingContainerDocs.find((c) => c.containerType === newContainer.containerType);

					if (existingContainer) {
						// UPDATE existing container
						batch.update(doc(db, COLLECTIONS.deliveredContainers, existingContainer.id), {
							...existingContainer,
							quantity: newContainer.quantity,
							weight: newContainer.weight,
							updatedAt: Timestamp.now(),
						});
						return { ...existingContainer, quantity: newContainer.quantity, weight: newContainer.weight };
					} else {
						const containerRef = doc(collection(db, COLLECTIONS.deliveredContainers));
						newDelContainerIds.push(containerRef.id);

						const existingDeliveryOctoIds = service.deliverySummary.map((d) => d.deliveryOctoId)?.filter(Boolean);

						const maxDeliverySuffix = existingDeliveryOctoIds.reduce((max, octoId) => {
							const parts = octoId.split("-");
							const suffix = parseInt(parts[parts.length - 1], 10);
							return suffix > max ? suffix : max;
						}, 0);

						const newDeliverySuffix = String(maxDeliverySuffix + 1).padStart(2, "0");

						const baseDeliveryId = existingDeliveryOctoIds.length
							? existingDeliveryOctoIds[0].split("-").slice(0, -1).join("-")
							: `DR-${transporterData?.transporterOctoId?.split("-")?.join("")}-${service.octoNumber
									?.split("-")
									?.join("")
									.slice(1, service.octoNumber?.split("-")?.join("")?.length)}-${formatDateToMMDDYYYY(
									service?.date?.toDate()
							  )}`;

						const newDeliveryOctoId = `${baseDeliveryId}-${newDeliverySuffix}`;

						const newContainerData = {
							...newContainer,
							updatedAt: Timestamp.now(),
							createdAt: Timestamp.now(),
							generatorId: service.generatorId,
							deliveryId: deliveryRef.id,
							routeId: service.routeId,
							scheduleId: service.scheduleId,
							serviceId: service.serviceId,
							serviceStatus: "COMPLETE",
							serviceVehicleId: service.serviceVehicleId,
							transporterId: service.transporterId,
						};

						batch.set(containerRef, newContainerData);
						return newContainerData;
					}
				});

				setNewDelContainers(updatedContainers);

				if (newDelContainerIds.length > 0) {
					batch.update(deliveryRef, {
						containers: arrayUnion(...newDelContainerIds),
						driverId: service.driverId,
						updatedAt: Timestamp.now(),
						url: "",
					});
				}
				await batch.commit();

				setCompleteServices((prev) =>
					prev.map((s) =>
						s.id === serviceId
							? {
									...s,
									deliverySummary: [
										...s.deliverySummary,
										...updatedContainers.map((c, index) => ({ ...c, id: newDelContainerIds[index] || c.id })),
									],
									deliveryId: deliveryDoc.id,
									deliveryReceipt: {
										deliveryId: deliveryDoc.id,
										deliveryOctoId: deliveryDoc.deliveryOctoId,
										url: "",
									},
							  }
							: s
					)
				);

				setFilteredData((prev) =>
					prev.map((s) =>
						s.id === serviceId
							? {
									...s,
									deliverySummary: [
										...s.deliverySummary,
										...updatedContainers.map((c, index) => ({ ...c, id: newDelContainerIds[index] || c.id })),
									],
									deliveryId: deliveryDoc.id,
									deliveryReceipt: {
										deliveryId: deliveryDoc.id,
										deliveryOctoId: deliveryDoc.deliveryOctoId,
										url: "",
									},
							  }
							: s
					)
				);
			}

			setNewContainers([]);
			setNewDelContainers([]);
			setEditing({ serviceId: null, section: null, consentAcceptance: false });
			toast.success("Changes saved successfully!");
		} catch (error) {
			setCompleteServices((prev) => prev.map((s) => (s.id === serviceId ? service : s)));
			setFilteredData((prev) => prev.map((s) => (s.id === serviceId ? service : s)));
			console.error("Error saving changes:", error);
			toast.error("Failed to save changes");
		}
	};

	const fetchContainerData = async (containerType = null) => {
		if (!containerType) return {};
		try {
			const docRef = doc(db, "defaultPriceBook", "services", "containers", containerType);
			const docSnap = await getDoc(docRef);

			let docData = { id: docSnap.id, ...docSnap.data() };

			return docData;
		} catch (error) {
			console.error("Error fetching containers:", error);
		}
	};

	const handleAddNewContainer = async (serviceId) => {
		setNewContainers([
			...newContainers,
			{
				tempId: Date.now(),
				subWasteType: "",
				containerType: "",
				quantity: 1,
				weight: 0,
			},
		]);
	};

	const handleAddNewDelContainer = async (serviceId) => {
		setNewDelContainers([
			...newDelContainers,
			{
				tempId: Date.now(),
				containerType: "",
				type: "",
				quantity: 1,
			},
		]);
	};

	const handleNewContainerChange = (tempId, field, value) => {
		setNewContainers(
			newContainers.map((container) => (container.tempId === tempId ? { ...container, [field]: value } : container))
		);
	};

	const handleNewDelContainerChange = (tempId, field, value) => {
		setNewDelContainers(
			newDelContainers.map((container) => (container.tempId === tempId ? { ...container, [field]: value } : container))
		);
	};

	const handleNewSubWasteTypeChange = async (tempId, field, value) => {
		let resp = await fetchContainerData(value);
		setNewContainers(
			newContainers.map((container) =>
				container.tempId === tempId ? { ...container, [field]: value, subWasteType: resp.subWasteType } : container
			)
		);
	};

	const handleRemoveNewContainer = (tempId) => {
		setNewContainers(newContainers.filter((c) => c.tempId !== tempId));
	};

	const handleRemoveNewDelContainer = (tempId) => {
		setNewDelContainers(newDelContainers.filter((c) => c.tempId !== tempId));
	};

	const handleDeleteContainer = async (serviceId, containerId) => {
		let toDeleteService = filteredData.find((item) => item.id == serviceId);
		if (toDeleteService?.serviceSummary?.length === 1) {
			toast.warn("All the containers cannot be removed!");
			return;
		}

		console.log("Deleting ", { serviceId, containerId, editedData, editing });

		try {
			toast.loading("Deleting container...");
			if (containerId && serviceId) {
				const batch = writeBatch(db);

				// Delete the container from `servicedContainers`
				batch.delete(doc(db, "servicedContainers", containerId));

				// Find the associated manifest
				const manifestQuery = query(collection(db, "manifests"), where("containers", "array-contains", containerId));
				const manifestSnap = await getDocs(manifestQuery);

				if (!manifestSnap.empty) {
					console.log("Manifest found");

					const manifestRef = doc(db, "manifests", manifestSnap.docs[0].id);
					const manifestData = manifestSnap.docs[0].data();

					batch.update(manifestRef, {
						containers: arrayRemove(containerId),
						url: null,
					});

					// If the manifest is left with no containers, delete it
					if (manifestData?.containers?.length === 1) {
						batch.delete(manifestRef);
						console.log(`Manifest with ID ${manifestSnap.docs[0].id} is now empty and will be deleted.`);
					}
				}

				// Update `scheduledServices` document
				const serviceRef = doc(db, "scheduledServices", serviceId);
				const serviceSnap = await getDoc(serviceRef);
				if (!serviceSnap.exists()) {
					throw new Error(`Service ${serviceId} not found`);
				}

				const serviceUpdate = serviceSnap.data();
				const updatedContainers = serviceUpdate?.consolidated
					? serviceUpdate.consolidated.filter((item) => item.id !== containerId)
					: [];

				console.log({ serviceUpdate, updatedContainers, containerId });

				batch.update(serviceRef, {
					consolidated: updatedContainers,
				});

				await batch.commit();
				console.log("Batch commit successful!");

				// Update local state after deletion
				setCompleteServices((prev) => {
					let updated = prev.map((service) =>
						service.id === serviceId
							? {
									...service,
									serviceSummary: service.serviceSummary.filter((c) => c.id !== containerId),
									manifestData: service.manifestData
										.map((manifest) => {
											let containerData = manifest.containers.filter((id) => id !== containerId);
											if (containerData.length === 0) return null;
											return {
												...manifest,
												containers: containerData,
											};
										})
										.filter(Boolean),
							  }
							: service
					);
					console.log("Updated completeServices:", updated);
					return updated;
				});

				setFilteredData((prev) => {
					let updated = prev.map((service) =>
						service.id === serviceId
							? {
									...service,
									serviceSummary: service.serviceSummary.filter((c) => c.id !== containerId),
									manifestData: service.manifestData
										.map((manifest) => {
											let containerData = manifest.containers.filter((id) => id !== containerId);
											if (containerData.length === 0) return null;
											return {
												...manifest,
												containers: containerData,
											};
										})
										.filter(Boolean),
							  }
							: service
					);
					console.log("Updated filteredData:", updated);
					return updated;
				});

				setEditedData((prev) => {
					if (!prev?.serviceSummary) return prev;
					let newServiceSummary = prev.serviceSummary.filter((item) => item.id !== containerId);
					console.log("Updated editedData:", { ...prev, serviceSummary: newServiceSummary });
					return { ...prev, serviceSummary: newServiceSummary };
				});

				toast.dismiss();
				setIsDeleting(null);
				toast.success("Container deleted successfully!");
			}
		} catch (error) {
			console.error("Error deleting container:", error);
			toast.dismiss();
			toast.error("Failed to delete container.");
		}
	};

	const handleDeleteDelContainer = async (serviceId, containerId) => {
		toast.loading("Deleting container...");
		let toDeleteService = filteredData.find((item) => item.id == serviceId);

		console.log({ toDeleteService });

		if (toDeleteService.deliverySummary?.length == 1) {
			toast.warn("All the containers cannot be removed!");
			return;
		}

		try {
			const batch = writeBatch(db);
			console.log("Deleting ", serviceId, containerId);

			batch.delete(doc(db, COLLECTIONS.deliveredContainers, containerId));
			const deliveryRef = doc(db, COLLECTIONS.deliveries, toDeleteService.deliveryId);
			const deliverySnap = await getDoc(deliveryRef);

			if (deliverySnap.exists()) {
				const deliveryData = deliverySnap.data();
				const updatedContainers = deliveryData.containers.filter((id) => id !== containerId);

				if (updatedContainers.length === 0) {
					batch.delete(deliveryRef);
					console.log(`Delivery with ID ${toDeleteService.deliveryId} is now empty and will be deleted.`);
				} else {
					batch.update(deliveryRef, {
						containers: arrayRemove(containerId),
						url: "",
					});
				}
			}

			await batch.commit();

			setCompleteServices((prev) =>
				prev.map((service) =>
					service.id === serviceId
						? {
								...service,
								deliverySummary: service.deliverySummary.filter((c) => c.id !== containerId),
								deliveryReceipt: { ...service.deliveryReceipt, url: "" },
						  }
						: service
				)
			);

			setFilteredData((prev) =>
				prev.map((service) =>
					service.id === serviceId
						? {
								...service,
								deliverySummary: service.deliverySummary.filter((c) => c.id !== containerId),
								deliveryReceipt: { ...service.deliveryReceipt, url: "" },
						  }
						: service
				)
			);

			console.log({ editedData });

			setEditedData((prev) => {
				let newDeliverySummary = prev.deliverySummary.filter((item) => item.id != containerId);
				return { ...prev, deliverySummary: newDeliverySummary };
			});
			toast.dismiss();
			setIsDeleting(null);
			toast.success("Container deleted successfully!");
		} catch (error) {
			console.error("Error deleting container:", error);
			toast.dismiss();
			toast.error("Failed to delete container.");
		}
	};

	console.log("====================================");
	console.log({ completeServices, containersMap, filteredData, editedData });
	console.log("====================================");

	return (
		<div
			style={{ height: selectedRows.length > 0 ? `calc(100% - 60px)` : "100%" }}
			className={`flex flex-col border-cardBorderCol overflow-clip bg-white rounded-cardRadii`}
		>
			<div className="h-fit">
				<div className="text-lg font-medium px-8 py-4 text-black">Service History Report</div>
				<FilterComp
					to={to}
					from={from}
					setTo={setTo}
					setFrom={setFrom}
					searchQuery={searchQuery}
					routeOptions={routeOptions}
					driverOptions={driverOptions}
					selectedRoutes={selectedRoutes}
					setSearchQuery={setSearchQuery}
					selectedDrivers={selectedDrivers}
					selectedServices={selectedServices}
					contractorOptions={contractorOptions}
					setSelectedRoutes={setSelectedRoutes}
					transStatusOptions={transStatusOptions}
					serviceTypeOptions={serviceTypeOptions}
					setSelectedDrivers={setSelectedDrivers}
					setSelectedServices={setSelectedServices}
					subContractorOptions={subContractorOptions}
					setContractorOptions={setContractorOptions}
					setSubContractorOptions={setSubContractorOptions}
					selectedTransStatusOptions={selectedTransStatusOptions}
					setSelectedTransStatusOptions={setSelectedTransStatusOptions}
				/>
			</div>
			<div className={`flex flex-col w-full font-medium overflow-y-scroll`}>
				<div className="flex bg-[#E5F2FF] w-fit py-4">
					<div className="w-8 mx-8 flex justify-center items-center hover:cursor-pointer">
						<input
							type="checkbox"
							ref={selectAllRef}
							className="w-4 h-4 bg-white"
							checked={selectAll}
							onChange={handleSelectAll}
						/>
					</div>
					<div className="flex items-center">
						<div className="truncate w-60">Service Date</div>
						<div className="truncate w-40">OCTO Number</div>
						<div className="truncate w-80">Generator</div>
						<div className="truncate w-48">Service Type</div>
						<div className="truncate w-40">Route</div>
						<div className="truncate w-40">Service Vehicle</div>
						<div className="truncate w-52">Driver</div>
						<div className="truncate w-48">Transporter ID</div>
						<div className="truncate w-40 text-center">Transfer Status</div>
					</div>
				</div>
				<div className="flex flex-col w-fit overflow-y-auto">
					{isLoading ? (
						<Loader height="h-20 pt-8 w-screen" />
					) : filteredData.length == 0 ? (
						<div className="flex h-40  w-screen text-cardTextGray text-xl justify-center items-center">
							<p>No result found</p>
						</div>
					) : (
						filteredData.map((service, index) => (
							<div key={service.id} className="py-2 w-fit font-normal border-b">
								<DataTable
									handleRowCheckbox={handleRowCheckbox}
									selectedRows={selectedRows}
									service={service}
									transporterData={transporterData}
								/>
								<div className="flex w-full overflow-hidden gap-6 border-dashInActiveBtnText px-6 pl-10">
									<div className="w-[45%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
										<div className="bg-[#DADADA] h-14 py-1 items-start justify-center">
											<div className="w-full flex items-center justify-center gap-2">
												<h6 className="font-medium text-center">Service Summary</h6>
												{editing.serviceId === service.id && editing.section === "service" ? (
													<>
														<button className="text-primary text-sm" onClick={() => handleSave(service.id)}>
															Save
														</button>
														<button
															className="text-cardTextGray text-sm"
															onClick={() => {
																setEditing({
																	serviceId: null,
																	section: null,
																	consentAcceptance: false,
																});
																setNewContainers([]);
																setEditedData({});
															}}
														>
															Cancel
														</button>
													</>
												) : (
													<button
														className="text-primary text-sm"
														onClick={() => handleEditClick(service.id, "service")}
													>
														(Edit)
													</button>
												)}
											</div>
											{service?.manifestData?.length > 0 && <ManifestMapper service={service} />}
										</div>
										<div className="pl-4 pb-2">
											<>
												{service.serviceSummary?.length == 0 &&
													service.noWaste &&
													(editing.section == "service" && editing.serviceId == service.id ? (
														<p className="text-center py-2"></p>
													) : (
														<p className="text-center py-2">Delivery Only</p>
													))}
											</>
											{service.serviceSummary?.length > 0 && (
												<div className="grid grid-cols-5 gap-2 text-left py-2 font-medium">
													<p>Subwaste Type</p>
													<p>Container</p>
													<p>Quantity</p>
													<p>Weight</p>
													{editing.serviceId === service.id && editing.section === "service" && <p>Delete</p>}
												</div>
											)}
											{Array.isArray(service?.serviceSummary) ? (
												service.serviceSummary?.map((summary, idx) => (
													<div key={idx} className="grid grid-cols-5 gap-2 my-1 text-left">
														{editing.serviceId === service.id && editing.section === "service" ? (
															<>
																{/* Subwaste Type Dropdown */}
																<select
																	value={editedData.serviceSummary[idx].subWasteType}
																	onChange={(e) =>
																		handleFieldChange(service.id, "serviceSummary", idx, "subWasteType", e.target.value)
																	}
																	disabled={true}
																	className="border rounded p-0.5 px-1 disabled:border-none"
																>
																	<option value="" disabled hidden></option>
																	{Object.entries(subWasteTypesMap).map(([value, label]) => (
																		<option key={label} value={label}>
																			{label}
																		</option>
																	))}
																</select>
																{/* Container Type Dropdown */}
																<select
																	value={editedData.serviceSummary[idx].containerType}
																	onChange={(e) =>
																		handleSubWasteTypeChange(
																			service.id,
																			"serviceSummary",
																			idx,
																			"containerType",
																			e.target.value
																		)
																	}
																	className="border rounded p-0.5 px-1"
																>
																	<option value="" disabled hidden></option>
																	{Object.entries(containersMap).map(([value, label]) => (
																		<option key={value} value={value}>
																			{label}
																		</option>
																	))}
																</select>
																{/* Quantity Input */}
																<input
																	className="border-b focus:outline-none px-1 w-16"
																	value={
																		editedData.serviceSummary[idx].quantity
																			? Number(editedData.serviceSummary[idx].quantity)
																			: 1
																	}
																	type="number"
																	min={1}
																	onChange={(e) =>
																		handleFieldChange(
																			service.id,
																			"serviceSummary",
																			idx,
																			"quantity",
																			e.target.value ? e.target.value?.toString() : "1"
																		)
																	}
																	onKeyDown={(e) => {
																		if (e.key === "." || e.key === "e") {
																			e.preventDefault();
																		}
																	}}
																/>
																{/* Weight Input */}
																<input
																	className="border-b focus:outline-none px-1 w-16"
																	value={
																		editedData.serviceSummary[idx].weight
																			? Number(editedData.serviceSummary[idx].weight)
																			: 0
																	}
																	type="number"
																	min={0}
																	onChange={(e) =>
																		handleFieldChange(
																			service.id,
																			"serviceSummary",
																			idx,
																			"weight",
																			e.target.value ? e.target.value?.toString() : "0"
																		)
																	}
																/>
																{editing.serviceId === service.id && editing.section === "service" && (
																	<button
																		onClick={() => {
																			console.log({ summary });
																			setIsDeleting({
																				serviceId: service.id,
																				containerId: summary.id,
																				section: "service",
																			});
																		}}
																		className="text-red-500 hover:text-red-700"
																	>
																		<BiTrash className="w-4 h-4" />
																	</button>
																)}
															</>
														) : (
															<>
																<p className="p-0.5 px-1">
																	{subWasteTypesMap[summary.subWasteType] ?? summary.subWasteType ?? "--"}
																</p>
																<p className="p-0.5 px-1">{containersMap[summary.containerType] ?? "--"}</p>
																<p className="p-0.5 px-1">{summary.quantity}</p>
																<p className="p-0.5 px-1">{summary.weight}</p>
															</>
														)}
													</div>
												))
											) : (
												<div className="w-full text-center py-2 text-secondary">
													<p>No data found</p>
												</div>
											)}
											{editing.serviceId === service.id &&
												editing.section === "service" &&
												newContainers.map((container, idx) => (
													<div
														key={container.tempId}
														className="grid grid-cols-5 gap-2 h-7 my-1 text-left items-center"
													>
														<select
															value={container.subWasteType}
															onChange={(e) =>
																handleNewContainerChange(container.tempId, "subWasteType", e.target.value)
															}
															disabled={true}
															className="border rounded p-0.5 px-1 disabled:border-none"
														>
															<option value="" disabled hidden></option>
															{Object.entries(subWasteTypesMap).map(([value, label]) => (
																<option key={label} value={label}>
																	{label}
																</option>
															))}
														</select>
														<select
															value={container.containerType}
															onChange={(e) =>
																handleNewSubWasteTypeChange(container.tempId, "containerType", e.target.value)
															}
															className="border rounded p-0.5 px-1"
														>
															<option value="" disabled hidden></option>
															{Object.entries(containersMap).map(([value, label]) => (
																<option key={value} value={value}>
																	{label}
																</option>
															))}
														</select>
														<input
															value={container.quantity ? Number(container.quantity) : 1}
															type="number"
															min={1}
															onChange={(e) =>
																handleNewContainerChange(
																	container.tempId,
																	"quantity",
																	e.target.value ? e.target.value?.toString() : "1"
																)
															}
															className="border-b focus:outline-none px-1 w-16"
															onKeyDown={(e) => {
																if (e.key === "." || e.key === "e") {
																	e.preventDefault();
																}
															}}
														/>
														<input
															value={container.weight ? Number(container.weight) : 0}
															type="number"
															min={0}
															onChange={(e) =>
																handleNewContainerChange(
																	container.tempId,
																	"weight",
																	e.target.value ? e.target.value?.toString() : "0"
																)
															}
															className="border-b focus:outline-none px-1 w-16"
														/>
														<button
															onClick={() => handleRemoveNewContainer(container.tempId)}
															className="text-red-500 hover:text-red-700"
														>
															<BiTrash className="w-4 h-4" />
														</button>
													</div>
												))}
											{editing.serviceId === service.id && editing.section === "service" && (
												<div className="flex gap-2 mt-2">
													<button className="text-primary text-sm" onClick={() => handleAddNewContainer(service.id)}>
														+ Add Container
													</button>
												</div>
											)}
										</div>
									</div>

									<div className="w-[35%] border border-dashInActiveBtnText rounded-lg overflow-hidden">
										<div className="bg-[#DADADA] h-14 py-1 grid items-start justify-center">
											<div className="w-full flex items-center justify-center gap-2">
												<h6 className="font-medium text-center">Delivery Summary</h6>
												{editing.serviceId === service.id && editing.section === "delivery" ? (
													<>
														<button className="text-primary text-sm" onClick={() => handleSave(service.id)}>
															Save
														</button>
														<button
															className="text-cardTextGray text-sm"
															onClick={() => {
																setEditing({
																	serviceId: null,
																	section: null,
																	consentAcceptance: false,
																});
																setNewDelContainers([]);
																setEditedData({});
															}}
														>
															Cancel
														</button>
													</>
												) : (
													<button
														className="text-primary text-sm"
														onClick={() => handleEditClick(service.id, "delivery")}
													>
														(Edit)
													</button>
												)}
											</div>
											{service?.deliverySummary?.length > 0 && service?.deliveryReceipt && (
												<div className="flex text-sm justify-center">
													<button
														className="text-center mr-1 hover:underline text-primary"
														onClick={() =>
															handleDeliveryView(service?.deliveryReceipt?.deliveryId, service?.deliveryReceipt?.url)
														}
													>
														{service?.deliveryReceipt?.deliveryOctoId ?? service?.deliveryReceipt?.deliveryId}
													</button>
												</div>
											)}
										</div>
										<div className="px-4 pb-2">
											<div className="flex gap-2 text-left py-2 font-medium">
												<p className="w-[50%]">Description</p>
												<p className="w-[30%]">Usage</p>
												<p className="w-[20%]">Quantity</p>
												{editing.serviceId === service.id && editing.section === "delivery" && <p>Delete</p>}
											</div>
											{Array.isArray(service?.deliverySummary) ? (
												service.deliverySummary?.map((summary, idx) => (
													<div key={idx} className="flex gap-2 my-1 text-left">
														{editing.serviceId === service.id && editing.section === "delivery" ? (
															<>
																{/* Container Type Dropdown */}
																<select
																	value={editedData.deliverySummary[idx].containerType}
																	onChange={(e) =>
																		handleFieldChange(
																			service.id,
																			"deliverySummary",
																			idx,
																			"containerType",
																			e.target.value
																		)
																	}
																	className="w-[50%] max-w-[50%] border rounded p-0.5 px-1"
																>
																	<option value="" disabled hidden></option>
																	{Object.entries(delContainersMap).map(([value, label]) => (
																		<option key={value} value={value}>
																			{label}
																		</option>
																	))}
																</select>
																{/* Usage Type Dropdown */}
																<select
																	value={editedData.deliverySummary[idx].type}
																	onChange={(e) =>
																		handleFieldChange(service.id, "deliverySummary", idx, "type", e.target.value)
																	}
																	className="w-[30%] max-w-[30%] border rounded p-0.5 px-1 capitalize"
																>
																	<option value="" disabled hidden></option>
																	<option value="USE">Use</option>
																	<option value="SUPPLY">Supply</option>
																</select>
																{/* Quantity Input */}
																<div className="w-[15%] p-0.5">
																	<input
																		className="w-16 border-b focus:outline-none px-1"
																		value={
																			editedData.deliverySummary[idx].quantity
																				? Number(editedData.deliverySummary[idx].quantity)
																				: 1
																		}
																		type="number"
																		min={1}
																		onChange={(e) =>
																			handleFieldChange(
																				service.id,
																				"deliverySummary",
																				idx,
																				"quantity",
																				e.target.value ? e.target.value?.toString() : "1"
																			)
																		}
																		onKeyDown={(e) => {
																			if (e.key === "." || e.key === "e") {
																				e.preventDefault();
																			}
																		}}
																	/>
																</div>
																{editing.serviceId === service.id && editing.section === "delivery" && (
																	<button
																		onClick={() =>
																			setIsDeleting({
																				serviceId: service.id,
																				containerId: editedData.deliverySummary[idx].id,
																				section: "delivery",
																			})
																		}
																		className="text-red-500 hover:text-red-700 w-[5%]"
																	>
																		<BiTrash className="w-4 h-4" />
																	</button>
																)}
															</>
														) : (
															<>
																<p className="w-[50%] p-0.5 px-1">{delContainersMap[summary?.containerType] ?? "--"}</p>
																<p className="w-[30%] p-0.5 px-1 capitalize">{summary?.type ?? "--"}</p>
																<p className="w-[15%] p-0.5 px-1.5">{summary.quantity}</p>
																<p className="w-[5%] p-0.5 px-1.5"></p>
															</>
														)}
													</div>
												))
											) : (
												<div className="w-full text-center py-2 text-secondary">
													<p>No data found</p>
												</div>
											)}
											{newDelContainers.map((delContainer, idx) => (
												<div key={delContainer.tempId} className="flex gap-2 h-7 my-1 text-left items-center">
													<select
														value={delContainer.containerType}
														onChange={(e) =>
															handleNewDelContainerChange(delContainer.tempId, "containerType", e.target.value)
														}
														className="w-[50%] max-w-[50%] border rounded p-0.5 px-1"
													>
														<option value="" disabled hidden></option>
														{Object.entries(delContainersMap).map(([value, label]) => (
															<option key={value} value={value}>
																{label}
															</option>
														))}
													</select>
													{/* Usage Type Dropdown */}
													<select
														value={delContainer.type}
														onChange={(e) => handleNewDelContainerChange(delContainer.tempId, "type", e.target.value)}
														className="w-[30%] max-w-[30%] border rounded p-0.5 px-1 capitalize"
													>
														<option value="" disabled hidden></option>
														<option value="USE">Use</option>
														<option value="SUPPLY">Supply</option>
													</select>
													{/* Quantity Input */}
													<div className="w-[15%] p-0.5">
														<input
															className="w-16 border-b focus:outline-none px-1"
															value={delContainer.quantity ? Number(delContainer.quantity) : 1}
															type="number"
															min={1}
															onChange={(e) =>
																handleNewDelContainerChange(
																	delContainer.tempId,
																	"quantity",
																	e.target.value ? e.target.value?.toString() : "1"
																)
															}
															onKeyDown={(e) => {
																if (e.key === "." || e.key === "e") {
																	e.preventDefault();
																}
															}}
														/>
													</div>
													<button
														onClick={() => handleRemoveNewDelContainer(delContainer.tempId)}
														className="text-red-500 hover:text-red-700 w-[5%]"
													>
														<BiTrash className="w-4 h-4" />
													</button>
												</div>
											))}
											{editing.serviceId === service.id && editing.section === "delivery" && (
												<div className="flex gap-2 mt-2">
													<button className="text-primary text-sm" onClick={() => handleAddNewDelContainer(service.id)}>
														+ Add Container
													</button>
												</div>
											)}
										</div>
									</div>
									<OptionsContainer
										handleBillingCheckbox={handleBillingCheckbox}
										handlePaidCheckbox={handlePaidCheckbox}
										service={service}
									/>
								</div>
								<PictureSection service={service} />
							</div>
						))
					)}
				</div>
			</div>
			{editing.section && editing.serviceId && !editing.consentAcceptance && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
					<div className="modal-box">
						<div>
							<button
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
								type="button"
								onClick={() => {
									setEditing({ section: null, serviceId: null, consentAcceptance: false });
								}}
							>
								✕
							</button>
						</div>
						<h3 className="font-bold text-lg">Are you sure?</h3>
						<div className="flex py-5 gap-5 flex-col">
							<p className="">You are about to edit this service!</p>
						</div>
						<div className="flex w-full justify-between">
							<button
								type="button"
								className={`btn btn-error btn-sm`}
								onClick={() => setEditing({ section: null, serviceId: null, consentAcceptance: false })}
							>
								{"No"}
							</button>
							<button
								className={`btn btn-primary btn-sm`}
								type="button"
								onClick={() => setEditing((prev) => ({ ...prev, consentAcceptance: true }))}
							>
								{"Yes"}
							</button>
						</div>
					</div>
				</div>
			)}
			{isDeleting && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
					<div className="modal-box">
						<div>
							<button
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
								type="button"
								onClick={() => setIsDeleting(null)}
							>
								✕
							</button>
						</div>
						<h3 className="font-bold text-lg">Are you sure?</h3>
						<div className="flex py-5 gap-5 flex-col">
							<p className="">You are about to delete container of this service!</p>
						</div>
						<div className="flex w-full justify-between">
							<button type="button" className={`btn btn-error btn-sm`} onClick={() => setIsDeleting(null)}>
								{"No"}
							</button>
							<button
								className={`btn btn-primary btn-sm`}
								type="button"
								onClick={() => {
									if (isDeleting.section === "service") {
										handleDeleteContainer(isDeleting.serviceId, isDeleting.containerId);
									} else if (isDeleting.section === "delivery") {
										handleDeleteDelContainer(isDeleting.serviceId, isDeleting.containerId);
									}
								}}
							>
								{"Yes"}
							</button>
						</div>
					</div>
				</div>
			)}
			<FooterActionItems servicesSelected={selectedRows} />
		</div>
	);
};

export default ServiceReporting;

export const FooterActionItems = ({ servicesSelected, services }) => {
	const deleteGenerators = () => {
		// const selectedGenIds = generators.filter((gen) => servicesSelected.includes(gen?.id)).map((gen) => gen.id);
		// try {
		// 	dispatch(deleteGenerator(selectedGenIds));
		// } catch (error) {
		// 	console.log(error);
		// }
	};

	const sendEmail = () => {
		// const selectedGens = generators
		// 	.filter((gen) => servicesSelected.includes(gen?.id))
		// 	.map((item) => item.generatorEmail)
		// 	.filter((item) => emailRegex.test(item))
		// 	.join(";");
		// window.open(`mailto:${selectedGens}`, "_blank");
	};

	const clickHandler = async (btnType) => {
		// if (btnType == "Delete") {
		// 	deleteGenerators();
		// } else if (btnType == "Email") {
		// 	sendEmail();
		// } else if (btnType == "Share") {
		// 	toast.info("Sharing feature is in progress!");
		// }
	};

	return (
		<div
			className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-6 transition-all duration-300 ease-in-out ${
				servicesSelected?.length > 0 ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{servicesSelected?.length} item(s) selected</span>
				<div className="flex gap-2">
					<ExportComponent servicesSelected={servicesSelected} generators={servicesSelected} />
					{["Email", "Send to Billing"].map((actionBtn) => (
						<button
							disabled={
								actionBtn !== "Email" && actionBtn == "Delete" && actionBtn !== "Merge" && actionBtn !== "Share"
							}
							key={actionBtn}
							className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 disabled:cursor-not-allowed bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
							onClick={() => clickHandler(actionBtn)}
						>
							{actionBtn}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

const ExportComponent = ({ genSelected, generators }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Close modal when clicking outside
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleExport = async () => {
		setIsExporting(true);
		setIsModalOpen(true);
		setProgress(0);

		try {
			// // Step 1: Data preparation (20% of progress)
			// let data = generators.filter((item) => genSelected.includes(item.id));
			// const worksheetData = data.map((item, index) => {
			// 	setProgress((index / data.length) * 20);
			// 	return {
			// 		"Generator Name": item.name,
			// 		"OCTO Number": item.octoNumber,
			// 		"Internal Account Number": item.internalAccountNumber,
			// 		"Service Address": item.address,
			// 		"Service Phone": item.phone,
			// 		"Service Email": item.email,
			// 		Status: item.generatorStatus,
			// 		"Onboarding Stage": JSON.stringify(item.onboardingStatus ?? {}),
			// 	};
			// });
			// setProgress(20);

			// // Step 2: Create worksheet (40% of progress)
			// const workbook = XLSX.utils.book_new();
			// const worksheet = XLSX.utils.json_to_sheet(worksheetData);
			// XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");
			// setProgress(40);

			// // Step 3: Convert to binary (70% of progress)
			// const excelBuffer = XLSX.write(workbook, {
			// 	bookType: "xlsx",
			// 	type: "array",
			// });
			// setProgress(70);

			// // Step 4: Create blob and prepare for download (90% of progress)
			// const blob = new Blob([excelBuffer], {
			// 	type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			// });
			// setProgress(90);

			// // Step 5: Trigger download (100% of progress)
			// saveAs(blob, "exported_data.xlsx");
			// setProgress(100);

			// Reset after showing 100% completion
			setTimeout(() => {
				setIsExporting(false);
				setProgress(0);
				setIsModalOpen(false);
			}, 1000);
		} catch (error) {
			console.error("Export failed:", error);
			setIsExporting(false);
			setProgress(0);
			setIsModalOpen(false);
		}
	};

	return (
		<>
			<button
				onClick={handleExport}
				disabled={isExporting}
				className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 
			disabled:cursor-not-allowed bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 
			border transition-colors duration-200 ease-in-out`}
			>
				{isExporting ? "Exporting..." : "Export"}
			</button>
		</>
	);
};

const FilterComp = ({
	to,
	from,
	setTo,
	setFrom,
	searchQuery,
	routeOptions,
	driverOptions,
	setSearchQuery,
	selectedRoutes,
	selectedDrivers,
	selectedServices,
	setSelectedRoutes,
	contractorOptions,
	serviceTypeOptions,
	transStatusOptions,
	setSelectedDrivers,
	setSelectedServices,
	subContractorOptions,
	setContractorOptions,
	setSubContractorOptions,
	selectedTransStatusOptions,
	setSelectedTransStatusOptions,
}) => {
	const clearFilters = () => {
		setSearchQuery("");
		setSelectedRoutes(["all", ...routeOptions.map((item) => item.value)]);
		setSelectedServices(["all", ...serviceTypeOptions.map((item) => item.value)]);
		setSelectedDrivers(["all", ...driverOptions.map((item) => item.value)]);
		setSelectedTransStatusOptions([...transStatusOptions.map((item) => item.value)]);
	};

	return (
		<>
			<div className="px-8 flex items-center justify-between">
				<div className="flex items-center bg-white rounded-full p-3 px-4 border border-gray-200 w-full max-w-[50%]">
					<FiSearch className="text-cardTextGray opacity-50" size={20} />
					<input
						type="text"
						placeholder="Search by Name, OCTO Number, Phone, Email, Address, Documents "
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						className="pl-2 bg-transparent focus:outline-none text-cardTextGray flex-1 w-full"
					/>
				</div>
				<button className="text-primary hover:text-blue-900" onClick={clearFilters}>
					Clear Filter
				</button>
			</div>
			<div className="w-11/12 flex px-8 py-5">
				<div className="flex flex-wrap gap-5 gap-y-3 items-end">
					<div className="flex flex-col min-w-52">
						<p className="text-sm text-gray-500 px-2">From</p>
						<CustomDatePicker
							selectedDate={from}
							setSelectedDate={(value) => setFrom(new Date(value))}
							label={"From Date"}
						/>
					</div>
					<div className="flex flex-col min-w-52">
						<p className="text-sm text-gray-500 px-2">To</p>
						<CustomDatePicker selectedDate={to} setSelectedDate={(value) => setTo(new Date(value))} label={"To Date"} />
					</div>
					<MultiselectDropdown
						width="w-48"
						buttonText="Service Type"
						options={serviceTypeOptions}
						selectedOptions={selectedServices}
						onSelectedOptionsChange={(values) => setSelectedServices(values)}
					/>
					<MultiselectDropdown
						width="w-48"
						buttonText="Route"
						options={routeOptions}
						selectedOptions={selectedRoutes}
						onSelectedOptionsChange={(values) => setSelectedRoutes(values)}
					/>
					<MultiselectDropdown
						width="w-48"
						buttonText="Driver"
						options={driverOptions}
						selectedOptions={selectedDrivers}
						onSelectedOptionsChange={(values) => setSelectedDrivers(values)}
					/>
					<MultiselectDropdown
						width="w-48"
						buttonText="Contractor"
						options={[]}
						selectedOptions={contractorOptions}
						onSelectedOptionsChange={(values) => setContractorOptions(values)}
					/>
					<MultiselectDropdown
						width="w-48"
						buttonText="Subcontractor"
						options={[]}
						selectedOptions={subContractorOptions}
						onSelectedOptionsChange={(values) => setSubContractorOptions(values)}
					/>
					<MultiselectDropdown
						width="w-48"
						buttonText="Transfer Status"
						options={transStatusOptions}
						selectedOptions={selectedTransStatusOptions}
						onSelectedOptionsChange={(values) => setSelectedTransStatusOptions(values)}
					/>
				</div>
			</div>
		</>
	);
};

const OptionsContainer = ({ service, handleBillingCheckbox, handlePaidCheckbox }) => {
	const [isChanging, setIsChanging] = useState(false);
	const [statusUpdateReason, setStatusUpdateReason] = useState("");
	const [pendingChange, setPendingChange] = useState(null);

	const handleCheckboxChange = (type, checked) => {
		if (!checked) {
			setIsChanging(true);
			setPendingChange(type);
		} else {
			type === "Billed" ? handleBillingCheckbox(checked, service.id) : handlePaidCheckbox(checked, service.id);
		}
	};

	const confirmChange = () => {
		if (!statusUpdateReason.trim()) {
			toast.error("Please enter a reason for the status update.");
			return;
		}
		if (pendingChange === "Billed") {
			handleBillingCheckbox(false, service.id, statusUpdateReason);
		} else if (pendingChange === "Paid") {
			handlePaidCheckbox(false, service.id, statusUpdateReason);
		}
		setIsChanging(false);
		setStatusUpdateReason("");
		setPendingChange(null);
	};

	return (
		<>
			<div className="flex flex-col h-fit overflow-hidden justify-between w-[20%] border border-dashInActiveBtnText rounded-lg">
				<div className="">
					<div className="bg-[#DADADA] h-14 py-1 grid items-start justify-center">
						<h6 className="font-medium text-center">Options</h6>
					</div>
					<div className="flex flex-col gap-2 py-2">
						<div className="flex justify-around items-center text-center font-medium">
							<p className="w-1/3">Billed</p>
							<span className="w-1/3">
								<input
									type="checkbox"
									className="w-4 h-4 bg-white"
									checked={service.billed}
									disabled={service?.paid}
									onChange={(e) => handleCheckboxChange("Billed", e.target.checked)}
								/>
							</span>
							<p className="w-1/3">{service?.billedDate}</p>
						</div>
						{service?.billingMethod === "internal" && (
							<div className="flex justify-around items-center text-center font-medium">
								<p className="w-1/3">Paid</p>
								<span className="w-1/3">
									<input
										type="checkbox"
										className="w-4 h-4 bg-white"
										checked={service?.paid}
										onChange={(e) => handleCheckboxChange("Paid", e.target.checked)}
									/>
								</span>
								<p className="w-1/3">{service?.paidDate}</p>
							</div>
						)}
					</div>
				</div>
				<div
					className={`w-full flex min-h-10 max-h-10 items-center justify-center border-t font-medium border-[#DADADA] ${
						service?.billingMethod === "internal" ? " bg-primaryGreen" : "text-red-600"
					}`}
				>
					<p>{service?.billingNote}</p>
				</div>
			</div>

			{isChanging && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center">
					<div className="modal-box">
						<button
							className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							type="button"
							onClick={() => setIsChanging(false)}
						>
							✕
						</button>
						<h3 className="font-bold text-lg">Are you sure?</h3>
						<p className="py-5">
							By unchecking this box, you are changing the status of <strong>{pendingChange}</strong>.
						</p>
						<div className="py-5">
							<label htmlFor="updateReason">
								<p>Enter reason for this change *</p>
								<textarea
									rows={3}
									name="updateReason"
									value={statusUpdateReason}
									onChange={(e) => setStatusUpdateReason(e.target.value)}
									className="w-full text-cardTextGray bg-inputBg border-none rounded-[20px] py-2 h-28 px-2 leading-tight focus:outline-none focus:ring-1 focus:ring-dashInActiveBtnText"
								/>
							</label>
						</div>
						<div className="flex w-full justify-between">
							<button className="btn btn-error btn-sm" type="button" onClick={confirmChange}>
								Yes
							</button>
							<button className="btn btn-primary btn-sm" type="button" onClick={() => setIsChanging(false)}>
								No
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const PictureSection = ({ service }) => {
	return (
		<div className="flex flex-col px-10 py-6">
			<div className="flex justify-between">
				<div className="flex gap-4">
					<p className="w-32 font-medium">Images:</p>
					{service?.picture?.length > 0 ? (
						service.picture.map((item, i) => (
							<Link target="_blank" key={item.url} className="text-primary underline" to={item.url}>
								View Image {i + 1}
							</Link>
						))
					) : (
						<p>N/A</p>
					)}
				</div>
				<div className="flex gap-4 w-[18%]">
					<p className="w-32 font-medium">Contractor:</p>
					<p>{service?.additional?.contractor}</p>
				</div>
			</div>
			<div className="flex justify-between">
				<div className="flex gap-4">
					<p className="text-nowrap w-32 font-medium">Driver Note:</p>
					<p>{service?.driverNote}</p>
				</div>
				<div className="flex gap-4 w-[18%] h-fit">
					<p className="text-nowrap min-w-32 font-medium">Subcontractor:</p>
					<p>{service?.additional?.subcontractor}</p>
				</div>
			</div>
		</div>
	);
};

const DataTable = ({ service, selectedRows, handleRowCheckbox, transporterData }) => {
	return (
		<div className="flex w-fit items-center pt-2 pb-6">
			<div className="min-w-8 mx-8 flex justify-center hover:cursor-pointer">
				<input
					type="checkbox"
					className="w-4 h-4 bg-white"
					checked={selectedRows.includes(service.id)}
					onChange={(e) => handleRowCheckbox(e, service.id)}
				/>
			</div>
			<div className="flex items-center">
				<div className="truncate w-60">{formattedDateTime(service.completedAt)}</div>
				<div className="truncate w-40">{service.octoNumber}</div>
				<div className="truncate w-80 text-thatblue">
					<Link to={`/admin/generators/${service.genId}/generator-dashboard`}>{service.generatorName}</Link>
				</div>
				<div className="truncate w-48">{serviceTypesMap?.[service.serviceType]}</div>
				<div className="truncate w-40">{service.routeName}</div>
				<div className="truncate w-40">{service.pickUpTruck}</div>
				<div className="truncate w-52">{service.driverName}</div>
				<div className="truncate w-48">{transporterData?.transporterOctoId}</div>
				<div className="truncate w-40 text-center">
					{service.transferStatus == "COMPLETE" ? "Transferred" : "In Transit"}
				</div>
			</div>
		</div>
	);
};

const ManifestMapper = ({ service }) => {
	const handleManifestView = async (id, url) => {
		console.log({ id, url });

		if (url && url?.length > 0) {
			window.open(url, "_blank");
		} else {
			const fetchUrl = getManifestUrl(id);
			console.log("fetchUrl", fetchUrl);
			window.open(fetchUrl, "_blank");
		}
	};

	return (
		<div className="flex text-sm justify-center">
			<span>
				{service?.manifestData?.[0]?.manifestOctoId?.slice(0, service?.manifestData?.[0]?.manifestOctoId?.length - 2)}
			</span>
			{service.manifestData?.map((manifest, index) => (
				<button
					key={manifest?.id}
					className="text-center mr-1 hover:underline text-primary"
					onClick={() => handleManifestView(manifest.id, manifest.url)}
				>
					{manifest?.manifestOctoId?.slice(manifest?.manifestOctoId?.length - 2, manifest?.manifestOctoId?.length)}
					{index < service.manifestData?.length - 1 ? ", " : ""}
				</button>
			))}
		</div>
	);
};

function formatDateToMMDDYYYY(date) {
	if (!(date instanceof Date)) {
		throw new Error("Invalid date object");
	}

	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const year = date.getFullYear();

	return `${month}${day}${year}`;
}
