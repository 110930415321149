import React, { useEffect, useState } from "react";
import MultiSelectRounded from "../../components/UI/dropdowns/MultiSelectRounded";
import Input from "../../components/UI/Input";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, COLLECTIONS, db, sendResetPasswordEmail } from "../../config/firebase";
import Button from "../../components/UI/Button";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import Loader from "../../components/UI/loaders/Loader";
import useTUserContext from "../../context/TransporterUserContext";

const MySettings = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [roles, setRoles] = useState([]);
	const [access, setAccess] = useState([]);
	const [selectedAccess, setSelectedAccess] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [prevData, setPrevData] = useState({});
	const [enableSave, setEnableSave] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { user, loading } = useTUserContext();

	useEffect(() => {
		if (user?.uid) {
			if (user?.uid == user?.userId) {
				setAccess([
					{
						label: "Full Access",
						value: "FULL_ACCESS",
					},
				]);
				setRoles([
					{
						label: "Admin",
						value: "ADMIN",
					},
				]);

				setSelectedAccess(["FULL_ACCESS"]);
				setSelectedRoles(["ADMIN"]);
			} else {
				setAccess([
					{
						label: "Full Access",
						value: "FULL_ACCESS",
					},
				]);
				setRoles([
					{
						label: "Staff",
						value: "STAFF",
					},
				]);
				setSelectedAccess(["FULL_ACCESS"]);
				setSelectedRoles(["STAFF"]);
			}
			const fetchUserData = async () => {
				if (!user?.uid) return;

				try {
					const userRef = doc(db, COLLECTIONS.users, user?.userId);
					const userSnap = await getDoc(userRef);

					const loggedInUserData = userSnap.exists() ? userSnap.data() : {};
					console.log({ loggedInUserData });

					const emailValue = loggedInUserData.email ?? loggedInUserData?.generalEmail ?? "";
					let firstNameValue = "",
						lastNameValue = "";

					if (loggedInUserData?.name) {
						let nameParts = loggedInUserData.name.split(" ") || [];
						firstNameValue = nameParts[0] || "";
						lastNameValue = nameParts[1] || "";
					} else {
						let nameParts = loggedInUserData?.name?.split(" ") || [];
						firstNameValue = loggedInUserData?.firstName ?? nameParts[0] ?? "";
						lastNameValue = loggedInUserData?.lastName ?? nameParts[1] ?? "";
					}

					setFirstName(firstNameValue);
					setLastName(lastNameValue);
					setEmail(emailValue);

					// Store original values
					setPrevData({
						firstName: firstNameValue,
						lastName: lastNameValue,
						email: emailValue,
						password: "",
						roles: [],
						access: [],
					});
				} catch (error) {
					console.error("Error fetching user data:", error);
				} finally {
					setIsLoading(false);
				}
			};
			fetchUserData();
		}
	}, [user, db]);

	useEffect(() => {
		const isDataChanged =
			firstName !== prevData.firstName ||
			lastName !== prevData.lastName ||
			email !== prevData.email ||
			password !== prevData.password;

		setEnableSave(isDataChanged);
	}, [firstName, lastName, email, password, prevData]);

	const handleSave = async () => {
		toast.loading("Saving...");
		setIsSaving(true);
		console.log("Save button clicked");
		const userRef = doc(db, COLLECTIONS.users, user?.userId);
		try {
			await updateDoc(userRef, {
				firstName,
				lastName,
				name: `${firstName} ${lastName}`,
			});
			if (user.uid == user.userId) {
				const marketUserRef = doc(db, COLLECTIONS.octoMarketUsers, user?.userId);
				await updateDoc(marketUserRef, {
					firstName,
					lastName,
					name: `${firstName} ${lastName}`,
				});
			}
		} catch (error) {
			console.log({ error });
		} finally {
			setPrevData({
				firstName,
				lastName,
				email,
				password: "",
			});
			setIsSaving(false);
			toast.dismiss();
			toast.success("Updated successfully");
		}
	};

	const passwordResetEmailHandler = async () => {
		toast.loading("Sending email...");
		try {
			await sendResetPasswordEmail(email);
			toast.dismiss();
			toast.success("Password reset email sent successfully");
		} catch (error) {
			toast.dismiss();
			toast.error("Error sending password reset email");
		}
	};

	return (
		<div className="p-6">
			<div className="flex flex-col justify-end px-8 py-6 border border-cardBorderCol bg-white rounded-cardRadii h-full">
				{isLoading ? (
					<Loader />
				) : (
					<>
						<div className="flex gap-8">
							<div className="w-1/2">
								<Input
									label="First Name"
									extra="bg-inputBg"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									isRequired={true}
								/>
								<Input
									label="Email"
									isDisabled={true}
									extra="bg-inputBg"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									isRequired={true}
								/>
								<MultiSelectRounded
									label="Roles"
									options={roles}
									value={selectedRoles}
									onChange={(value) => setSelectedRoles(value)}
									isDisabled={true}
								/>
							</div>
							<div className="w-1/2">
								<Input
									label="Last Name"
									extra="bg-inputBg"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									isRequired={true}
								/>
								<div className={`relative w-full flex items-center justify-between mt-4`}>
									<label className={`w-1/3 text-inputLabel flex items-center gap-1 font-normal pr-3`}>Password</label>
									<div className={`relative w-2/3`}>
										<button
											className={`
											w-full flex items-center text-sm text-primary hover:underline cursor-pointer bg-inputBg border-none rounded-full py-2 h-9 px-4
										  `}
											onClick={() => setOpenModal(true)}
										>
											Change Password
										</button>
									</div>
								</div>
								<MultiSelectRounded
									label="Access"
									options={access}
									value={selectedAccess}
									onChange={(value) => setSelectedAccess(value)}
									isDisabled={true}
								/>
							</div>
						</div>
						<div className="flex w-full justify-end">
							<div className="w-40">
								<Button
									type="primary"
									disabled={!enableSave || isSaving}
									onClick={handleSave}
									text={"Save"}
									className={"w-fit"}
								>
									Save
								</Button>
							</div>
						</div>
					</>
				)}
			</div>
			{openModal && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
					<div className="bg-white z-20 grid gap-2 rounded-cardRadii max-w-md p-4 w-full min-h-fit">
						<p>
							You will receive an email on this <span className="text-primary underline">{email}</span> with a link to
							change your password.
						</p>
						<div className="flex justify-between gap-4">
							<button
								className="bg-[#F3F3F3] text-nowrap border border-cardTextGray rounded-full w-24 p-1 px-4"
								type="button"
								onClick={() => setOpenModal(false)}
							>
								No
							</button>
							<button
								className="px-4 py-1 w-fit text-nowrap bg-primary transition-colors duration-200 text-white rounded-full"
								type="button"
								onClick={passwordResetEmailHandler}
							>
								Send Email
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MySettings;
