import React from "react";
import { formattedDate } from "../../../../../../../../../utils/helpers";

const GeneratorInfoHeader = ({ generatorData }) => {
	if (!generatorData) return null; 
     console.log("generatorDataa from header info",generatorData);
	 
	const addressParts = [
		generatorData?.serviceAddress?.street,
		generatorData?.serviceAddress?.city,
		generatorData?.serviceAddress?.state,
	].filter(Boolean);

	const address = addressParts.length
		? addressParts.join(", ") +
		  (generatorData.serviceAddress?.zipCode ? ` ${generatorData.serviceAddress.zipCode}` : "")
		: "--";

	const createdAtFormatted = generatorData.createdAt ? formattedDate(generatorData.createdAt) : "--";

	return (
		<div className="flex justify-between border-b h-24 pb-2 items-end border-[#CCCCCC]">
			<div>
				<h6 className="font-semibold text-2xl">{generatorData?.generatorName ?? "--"}</h6>
				<h6 className="font-medium text-lg">{generatorData?.octoNumber ?? "--"}</h6>
				<h6 className="font-medium text-lg">
					<span className="text-cardTextGray">{address}</span>
				</h6>
			</div>
			<div className="flex flex-col justify-between h-full">
				<h6 className="font-semibold text-end text-2xl">
					{generatorData.isSubContracted ? generatorData.subContractor?.name : ""}
				</h6>
				<h6 className="font-medium text-lg">
					Created Date: <span className="text-cardTextGray">{createdAtFormatted}</span>
				</h6>
			</div>
		</div>
	);
};

export default GeneratorInfoHeader;