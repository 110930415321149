import PropTypes from "prop-types";
import { AzureMapDataSourceProvider, AzureMapHtmlMarker, AzureMapLayerProvider, AzureMapPopup } from "react-azure-maps";
import { data } from "azure-maps-control";
import React, { useCallback, useEffect, useState } from "react";
import { serviceTypes } from "../../utils/constants";
import { convertISODateStringToFormattedDate, octoFormatter } from "../../utils/helpers";
import { useSearchParams } from "react-router-dom";

const CustomAzureWayPointMarker = ({
	draggable = false,
	pin = null,
	genData = {},
	children,
	serviceId = "",
	waypoint = {},
	route = { route },
	routes = [],
	activeGenCard = null,
}) => {
	const [currentPosition, setCurrentPosition] = useState(
		new data.Position(waypoint.randomCoordinates.lng, waypoint.randomCoordinates.lat)
	);
	const [currentCollection, setCurrentCollection] = useState(
		new data.LineString([
			[waypoint.lng, waypoint.lat],
			[currentPosition[0], currentPosition[1]],
		])
	);

	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const markerOptions = {
		position: currentPosition,
		draggable: draggable,
	};

	const onClick = useCallback(() => {
		setIsPopupVisible(true);
		setSearchParams((prev) => {
			prev.set("currentServiceId", serviceId);
			return prev;
		});
	}, [serviceId, setSearchParams]);

	const dragHandler = useCallback((e) => {
		setCurrentPosition(new data.Position(e.target.options.position[0], e.target.options.position[1]));
		setCurrentCollection(
			new data.LineString([
				[waypoint.lng, waypoint.lat],
				[e.target.options.position[0], e.target.options.position[1]],
			])
		);
	}, []);

	const eventToMarker = [
		{ eventName: "click", callback: onClick },
		{ eventName: "drag", callback: dragHandler },
	];

	useEffect(() => {
		if (activeGenCard !== null) {
			setIsPopupVisible(activeGenCard === waypoint.serviceId);
		}
	}, [activeGenCard, waypoint.serviceId]);

	useEffect(() => {
		const container = document.getElementById(`container_${route.id + waypoint.serviceId}`);
		if (container) {
			const button = document.createElement("button");
			button.type = "button";
			button.className = "bg-primary-500 text-white rounded-full py-2 px-10 hover:bg-primary-500/60";
			button.textContent = "Change Route";
			button.addEventListener("click", async () => {
				document.getElementById(`change_route_modal_${route.id + waypoint.serviceId}`).showModal();
				setIsPopupVisible(false);
			});
			container.appendChild(button);
		}
	}, [isPopupVisible]);

	return (
		<>
			<AzureMapHtmlMarker
				events={eventToMarker}
				options={{ ...markerOptions, pixelOffset: [0, 0] }}
				markerContent={pin}
			/>
			<AzureMapDataSourceProvider
				id={`LineLayer DataSourceProvider ${route.id + waypoint.serviceId}${Math.random().toString()}`}
				collection={currentCollection}
			>
				<AzureMapLayerProvider
					type="LineLayer"
					options={{
						strokeColor: "black",
						strokeWidth: 2,
						strokeOpacity: 0.5,
						blur: 0,
						lineCap: "round",
						translate: [0, 0],
					}}
				/>
			</AzureMapDataSourceProvider>

			{
				<AzureMapPopup
					isVisible={isPopupVisible}
					key={Math.random().toString()}
					options={{ position: currentPosition, showPointer: false }}
					events={[{ eventName: "close", callback: () => setIsPopupVisible(false) }]}
					popupContent={
						<div className="max-w-fit">
							<div className="flex flex-col bg-gray-300 p-5 pb-1 rounded-t-xl gap-2">
								<div className="flex flex-col pr-10">
									<p className="text-base font-semibold">{waypoint.generatorData.generatorName}</p>
									<p className="text-base font-semibold">{octoFormatter(waypoint.generatorData.octoNumber)}</p>
								</div>
								<div className="flex items-center gap-2">
									<a
										href={`/admin/generators/${waypoint.generatorData.id}/routes`}
										className="text-primary-500 underline"
									>
										View Routes
									</a>
								</div>
							</div>
							<div className="flex flex-col gap-5 border-t border-b h-fit p-4 text-sm bg-white rounded-b-xl">
								<div className="grid grid-cols-3 gap-x-10">
									<p className="font-semibold text-nowrap">Service Address:</p>
									<p className="col-span-2 text-wrap">
										{waypoint.generatorData?.serviceAddress?.street ?? ""}
										{waypoint?.generatorData?.serviceAddress?.suite
											? `, ${waypoint.generatorData.serviceAddress.suite}`
											: ""}
										, {waypoint?.generatorData?.serviceAddress?.city ?? ""},{" "}
										{waypoint?.generatorData?.serviceAddress?.state ?? ""}{" "}
										{waypoint?.generatorData?.serviceAddress?.zipCode ?? ""}
									</p>
									<p className="font-semibold text-nowrap">Date:</p>
									<p className="col-span-2">{convertISODateStringToFormattedDate(route.dailyRouteId)}</p>
									<div className="grid grid-cols-3 gap-x-10 col-span-3">
										<p className="font-semibold text-nowrap">Service</p>
										<p className="font-semibold text-nowrap">Service Freq.</p>
										<p className="font-semibold text-nowrap">Route</p>
										<p className="">
											{waypoint?.scheduleData?.serviceType?.length &&
											typeof waypoint?.scheduleData?.serviceType !== "string"
												? waypoint?.scheduleData?.serviceType.map(
														(type) => serviceTypes.find((el) => el.value === type)?.label
												  )
												: serviceTypes.find((el) => el.value === waypoint?.scheduleData?.serviceType)?.label}
										</p>
										<p className="">
											{waypoint?.scheduleData?.serviceFrequency?.type.length > 0
												? waypoint?.scheduleData?.serviceFrequency?.type === "MTWM"
													? "MTW"
													: waypoint?.scheduleData?.serviceFrequency?.type
												: "--"}
										</p>
										<p className="">{route.routeLabel}</p>
									</div>
								</div>
								<div
									id={`container_${route.id + waypoint.serviceId}`}
									className="flex justify-center items-center"
								></div>
							</div>
						</div>
					}
				/>
			}
		</>
	);
};

CustomAzureWayPointMarker.propTypes = {
	coordinates: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	draggable: PropTypes.bool,
	genData: PropTypes.object,
	activeGenCard: PropTypes.string,
};

export default CustomAzureWayPointMarker;
