import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SidebarItem from "./SIdebarItem";
import Icon from "../Icon";
import { signOutUser, auth, COLLECTIONS, db } from "../../../config/firebase";
import { menuItems } from "../../../utils/constants";
import { GetTransporterDataByUID } from "../../../utils/firebaseOperations";
import useTUserContext from "../../../context/TransporterUserContext";
import MarketIcon from "../MarketIcon";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const AdminSidebar = ({ active, showOnlyIcons }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [transporter, setTransporter] = useState(null);
	const [isScrollable, setIsScrollable] = useState(false);
	const [expandedItemIds, setExpandedItemIds] = useState([]);
	const { user, loading } = useTUserContext();

	useEffect(() => {
		const currentPath = location.pathname;
		const matchedIds = [];

		menuItems.forEach((item) => {
			if (item.children?.some((child) => currentPath.startsWith(child.link))) {
				matchedIds.push(item.id);
			}
		});

		if (matchedIds.length > 0) {
			setExpandedItemIds((prevIds) => {
				// Keep existing expanded items and add new matches
				const newIds = [...new Set([...prevIds, ...matchedIds])];
				return newIds;
			});
		}
	}, []);

	useEffect(() => {
		const fetchTransporter = () => {
			try {
				const unsubscribe = GetTransporterDataByUID(user.uid, (data) => {
					if (data) {
						setTransporter(data);
					} else {
						console.log("No transporter data found");
						navigate("/market");
					}
				});
				return () => unsubscribe();
			} catch (error) {
				console.error("Error fetching transporter data:", error);
			}
		};

		if (user) fetchTransporter();
	}, [loading, user]);

	const logOutHandler = async () => {
		await signOutUser();
		navigate("/");
	};

	const clickHandler = (to = "/") => navigate(to);

	const scrollHandler = (e) => {
		const target = e.target;
		target.scrollTop > 0 ? setIsScrollable(true) : setIsScrollable(false);
	};

	const handleMarketNavigation = async () => {
		try {
			const marketUserOldRef = doc(db, COLLECTIONS.octoMarketUsers, user?.userId);
			const getData = await getDoc(marketUserOldRef);

			if (!getData.data()?.firstName?.length) {
				const userRef = doc(db, COLLECTIONS.users, user?.userId);
				const userSnap = await getDoc(userRef);

				const loggedInUserData = userSnap.exists() ? userSnap.data() : {};
				console.log({ loggedInUserData });

				let firstNameValue = "",
					lastNameValue = "";

				if (loggedInUserData?.name) {
					let nameParts = loggedInUserData.name.split(" ") || [];
					firstNameValue = nameParts[0] || "";
					lastNameValue = nameParts[1] || "";
				} else {
					let nameParts = loggedInUserData?.name?.split(" ") || [];
					firstNameValue = loggedInUserData?.firstName ?? nameParts[0] ?? "";
					lastNameValue = loggedInUserData?.lastName ?? nameParts[1] ?? "";
				}

				let firstName = firstNameValue;
				let lastName = lastNameValue;

				const marketUserRef = doc(db, COLLECTIONS.octoMarketUsers, user?.userId);
				await updateDoc(marketUserRef, {
					firstName,
					lastName,
					name: `${firstName} ${lastName}`,
				});
			}
		} catch (error) {
			console.log({ error });
		} finally {
			navigate("/market");
		}
	};

	console.log({ active });

	return (
		<nav
			className={`${
				showOnlyIcons ? "w-24" : "w-72"
			} transition-all duration-300 bg-white fixed hidden md:flex flex-col h-screen`}
		>
			{/* Top section */}
			{!showOnlyIcons && (
				<div className="px-10 min-h-20 max-h-20 items-center grid gap-4 border border-borderCol">
					<div className="rounded-full">
						{typeof transporter?.companyLogo?.link == "string" && transporter?.companyLogo?.link?.length > 0 && (
							<div className="existing-file flex flex-col my-1 items-center justify-center">
								<img src={transporter?.companyLogo?.link} alt="Company Logo" className="object-cover  h-14" />
							</div>
						)}
						{!transporter?.companyLogo &&
							typeof transporter?.companyLogo?.link !== "string" &&
							!transporter?.companyLogo?.link?.length && (
								<div className="py-2 hover:bg-primaryGray cursor-pointer hover:underline  text-center mt-2 border border-borderCol text-sm px-2">
									<Link to={"/admin/profile"}>Add logo</Link>
								</div>
							)}
					</div>
				</div>
			)}
			{showOnlyIcons && (
				<div className=" p-2 border border-borderCol">
					<div className="">
						{transporter?.companyLogo?.link ? (
							<div className="existing-file">
								<img
									src={transporter.companyLogo.link}
									style={{ minWidth: "60px", minHeight: "60px" }}
									alt="Company Logo"
									className="object-cover max-h-16 max-w-16"
								/>
							</div>
						) : (
							<div className="h-10 w-10 md:h-16 md:w-16 rounded-full bg-dashBtnGradient" />
						)}
					</div>
				</div>
			)}
			{/* Scrollable middle section */}
			<div className="flex-grow border-r border-borderCol overflow-hidden" style={{ zIndex: 9999 }}>
				<div className="h-full overflow-y-auto" onScroll={scrollHandler}>
					<ul className="flex flex-col pt-6">
						{menuItems.map((item, index) => (
							<SidebarItem
								key={item.label + index}
								item={item}
								active={active === item.name}
								expandedItemIds={expandedItemIds}
								setExpandedItemIds={setExpandedItemIds}
								showOnlyIcons={showOnlyIcons}
							/>
						))}
					</ul>
				</div>
			</div>

			{/* Fixed bottom section */}
			<ul
				className={`p-4 flex border-r border-borderCol flex-col w-full ${
					isScrollable ? "border-t border-borderCol border-opacity-50" : ""
				}`}
			>
				{isScrollable && (
					<div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
				)}
				{user?.userId == user?.uid && (
					<li
						onClick={() => handleMarketNavigation()}
						className={`group px-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:cursor-pointer`}
					>
						<MarketIcon />
					</li>
				)}
			</ul>
		</nav>
	);
};

export default AdminSidebar;
