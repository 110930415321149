import React, { useCallback, useEffect, useState } from "react";
import Calender from "./Calenders/Calenders";
import { generateServiceMonths } from "../../../../../utils/helpers";
import CalendarWithTimeSlots from "../../../../admin/generator-management/components/generators/components/detail-forms/components/open-calendar/GeneratorCalendarComponent";
import { useGeneratorUser } from "../../../../../context/GeneratorUserContext";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { onSnapshot, query, collection, where, orderBy, getDoc, doc, Timestamp } from "firebase/firestore";
import { db, COLLECTIONS } from "../../../../../config/firebase";
import { renderAddress } from "../../../../../utils/helpers";
import { getGeneratorById } from "../../../../../utils/firebaseOperations";
import { useParams } from "react-router-dom";

const ServiceCalender = () => {
	const { user, authLoading, allServices: data } = useGeneratorUser();
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [months, setMonths] = useState([]);
	const [allServices, setAllServices] = useState(data || []);
	const [generatorData, setGeneratorData] = useState(null);
	const currentUrl = window.location.href;
	const url = new URL(currentUrl);
	const childParam = url.searchParams.get("child");
	console.log("Child parameter value:", childParam);
	const { id: genParam } = useParams();
	

	useEffect(() => {
		setAllServices(data);

		if (!childParam) return;
	    const startOfYear = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0, 0));
	const endOfYear = new Date(Date.UTC(currentYear, 11, 31, 23, 59, 59, 999)); 
		const unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.scheduledServices),
				where("generatorId", "==", childParam),
				where("status", "not-in", ["DELETED", "CANCELLED"]),
				where("date", ">=", Timestamp.fromDate(startOfYear)),
				where("date", "<=", Timestamp.fromDate(endOfYear)),
				orderBy("date", "asc")
			),
			async (snap) => {
				try {
					console.log("Snapshot received:", snap);
					const tempServices = [];
					const jobs = snap.docs.map(async (el) => {
						if (el.exists()) {
							console.log("Document data:", el.data());
							const serviceData = el.data();
							const scheduleRes = await getDoc(doc(db, COLLECTIONS.serviceSchedules, serviceData.serviceScheduleId));
							if (scheduleRes.exists()) {
								serviceData.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
							}
							tempServices.push({ ...serviceData, id: el.id });
						}
					});
					await Promise.all(jobs);
					console.log("tempServiceC", tempServices);
					setAllServices(tempServices);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);

		return () => {
			console.log("Unsubscribing from snapshot listener");
			unsubscribe();
		};
	}, [childParam,currentYear]);

	const setGenData = async (childParam) => {
		const genData = await getGeneratorById(childParam);
		setGeneratorData(genData);
	};
	useEffect(() => {
		if (childParam) {
			setGenData(childParam);
		}
	}, [childParam]);

	useEffect(() => {
		const dates = [];
		for (let i = 0; i < 12; i++) {
			const year = i >= 0 ? currentYear : currentYear - 1;
			const month = (i + 12) % 12; 
			dates.push(new Date(year, month, 1));
		}
		console.log("dates", dates);

		setMonths(dates);
		return () => {};
	}, [currentYear]);
	console.log("allservice", months);

	return (
		<div className={`grid p-5 ${genParam ? "" : "mt-5 ml-5"}`}>
			<div className="bg-white w-full rounded-xl flex flex-col items-start justify-evenly p-5  -mt-5 shadow-sm">
				<p className="font-semibold">{generatorData?.generatorName}</p>
				<p className="font-semibold">{generatorData?.octoNumber}</p>
				<p className="font-semibold">{renderAddress(generatorData?.serviceAddress)}</p>
			</div>
			<div className="rounded-xl grid gap-5 mt-5">
				<div className="flex gap-5">
					<div className="flex items-center gap-5">
						<button
							className="btn btn-ghost btn-sm btn-circle text-xl"
							onClick={() => {
								setCurrentYear((prev) => prev - 1);
							}}
						>
							<FaAngleLeft />
						</button>
						<p className="text-xl">{currentYear}</p>
						<button
							className="btn btn-ghost btn-sm btn-circle text-xl"
							onClick={() => {
								setCurrentYear((prev) => prev + 1);
							}}
						>
							<FaAngleRight />
						</button>
					</div>
					<div className="flex gap-5 ml-auto">
						<button
							className="bg-white min-w-40  text-black rounded-full py-3 px-4 transition-all duration-300 flex justify-center items-center space-x-2"
							onClick={() => {
								setCurrentYear(new Date().getFullYear());
							}}
						>
							Today
						</button>
					</div>
				</div>
				<div className="p-5 bg-white rounded-xl grid gap-5">
					<div className="grid grid-cols-3 gap-4 ">
						{months.map((month, index) => (
							<CalendarWithTimeSlots key={month} allServices={allServices} startDate={month} wholeCalender={false} showNavButtons = {false}/>
						))}
					</div>
					<div className="grid gap-5 w-full">
						<div className="grid grid-cols-3">
							<div className="flex flex-col gap-5">
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-none bg-white border-[3px] border-red-500 mr-2"></div>
									<span>{"Today's Date"}</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-red-500 mr-2"></div>
									<span>Medical Waste Service</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-green-500 mr-2"></div>
									<span>Paper Shredding Service</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="flex w-8 h-8 col-span-2">
										<div className="min-w-4 h-8 bg-green-500 rounded-r-full rotate-180"></div>
										<div className="min-w-4 h-8 bg-red-500 rounded-l-full rotate-180"></div>
									</div>
									<span>Medical Waste & Paper Shredding</span>
								</div>
							</div>
							<div className="flex flex-col gap-5">
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-red-500 mr-2"></div>
									<span>Today has either/or service</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-yellow-500 mr-2"></div>
									<span>Holiday (No services on this day)</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-gray-500 mr-2"></div>
									<span>Completed (Past) Service dates</span>
								</div>
								<div className="flex items-center gap-5">
									<div className="w-8 h-8 rounded-full bg-white border border-gray-500 mr-2"></div>
									<span>Unlogged (Past) Service dates</span>
								</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceCalender;
