import React, { useEffect, useState } from "react";
import FullCalender from "./FullCalender";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, COLLECTIONS } from "../../../config/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import useTUserContext from "../../../context/TransporterUserContext";
const Subcontrator_Schedules = () => {
	const [isTwoEventsSelected, setIsTwoEventsSelected] = useState(false);
	const [selectedEvents, setSelectedEvents] = useState([]);
	const navigate = useNavigate();
	const { user, loading } = useTUserContext();
	// const [user] = useAuthState(auth);
	const [routes, setRoutes] = useState([]);
	const [selectedRouteIds, setSelectedRouteIds] = useState([]);
	useEffect(() => {
		console.log({ selectedRouteIds });
	}, [selectedRouteIds]);

	useEffect(() => {
		if (!user) return;
		let unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.routes),
				where("transporterId", "==", user?.uid ?? ""),
				where("status", "==", "ACTIVE")
			),
			(snapshot) => {
				let tempRoutes = [];
				snapshot.forEach((doc) => {
					tempRoutes.push({ id: doc.id, ...doc.data() });
				});
				setRoutes(tempRoutes);
				setSelectedRouteIds(tempRoutes.map((route) => route.id));
			}
		);
		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, [user, loading]);

	const handleEventsSelected = (selectedEvents) => {
		setIsTwoEventsSelected(true);
		console.log("Selected events:", selectedEvents);
		setSelectedEvents(selectedEvents);
	};

	const constructUrl = (selectedEvents) => {
		if (!selectedEvents?.[0]?.id || !selectedEvents?.[1]?.id) {
			return null; // or handle the error case as needed
		}

		return `/admin/operations/route-optimization?scheduleId1=${encodeURIComponent(
			selectedEvents[0].id
		)}&scheduleId2=${encodeURIComponent(selectedEvents[1].id)}`;
	};
	const compareRoutes = () => {
		console.log("Redirecting to compare routes page");
		let url = constructUrl(selectedEvents);
		navigate("./assets/routes-optimization");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white px-8 rounded-cardRadii h-full">
				{/* <div className="p-6 px-0 pb-2 font-medium">Subcontractor Information Management</div> */}
				<div className="border-b"></div>
				{/* <div className="py-4 text-cardTextGray">
					Next Pick-Up Date <span className="ml-4 text-black">12/22/2024</span>
				</div> */}

				<div className="flex justify-between">
					<div className="p-6 px-0 pb-2 font-medium">Service Calendar</div>
					<div className="">
						{/* <button
							onClick={compareRoutes}
							style={{ width: "184px", height: "38px" }}
							disabled={selectedEvents.length != 2}
							className="bg-blue-300 px-4 py-1 rounded-full hover:bg-blue-500 hover:text-white transition-colors duration-300 disabled:bg-primaryGray disabled:text-black cursor-pointer"
						>
							Compare Routes
						</button> */}
					</div>
				</div>
				<div className="px-6 border-b"></div>
				<div className="flex gap-8 py-8">
					<div className="w-full xl:w-1/5">
						<div className="flex flex-col gap-2">
							{routes.map((route) => (
								<button
									key={route.id}
									className="flex items-center h-10 p-3 gap-3 rounded-[4px] cursor-pointer"
									onClick={() => {
										if (selectedRouteIds.includes(route.id)) {
											setSelectedRouteIds((prev) => prev.filter((id) => id !== route.id));
										} else {
											setSelectedRouteIds((prev) => [...prev, route.id]);
										}
									}}
								>
									{selectedRouteIds.includes(route.id) ? (
										<div
											className={`w-4 h-4 rounded-full`}
											style={{
												backgroundColor: route.color,
											}}
										></div>
									) : (
										<div className="w-4 h-4 rounded-full"></div>
									)}
									<h6 className="text-black">{route.routeLabel}</h6>
								</button>
							))}
						</div>
					</div>
					<div className="w-full xl:w-4/5">
						<FullCalender
							onTwoEventsSelected={handleEventsSelected}
							routes={routes}
							selectedRouteIds={selectedRouteIds}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Subcontrator_Schedules;
