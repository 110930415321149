import React, { useCallback, useEffect, useState } from "react";
import { AuthenticationType, AzureMap, AzureMapsContext } from "react-azure-maps";
import CustomHtmlGeneratorMarker from "./CustomHtmlGeneratorMarker";
import { limitScrollWheelZoom, randomizeCoordinates } from "../../utils/helpers";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useContext } from "react";
import { data } from "azure-maps-control";
import PropTypes from "prop-types";
import { FaMinus, FaPlus } from "react-icons/fa";

const authOptions = {
	authType: AuthenticationType.subscriptionKey,
	subscriptionKey: import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
};

const DEFAULT_CAMERA = {
	center: [-100.33, 39.5],
	zoom: 3,
};

const CustomAzureGeneratorsMapView = ({ routesMap, geocodedMarkers = [], filteredData = [] }) => {
	const [allRoutes, setAllRoutes] = useState([]);
	const [user] = useAuthState(auth);
	const { isMapReady, mapRef, removeMapRef } = useContext(AzureMapsContext);
	const [allGeneratorData, setAllGeneratorData] = useState([]);
	const [mapReady, setMapReady] = useState(false);

	useEffect(() => {
		const processMarkers = () => {
			const safeMarkers = Array.isArray(geocodedMarkers) ? geocodedMarkers : [];
			const tempGen = [];

			safeMarkers.forEach((marker) => {
				const coords = marker?.serviceAddCoordinates;
				if (coords?.lat && coords?.lng) {
					tempGen.push({
						...marker,
						randomCoordinates: randomizeCoordinates(coords.lat, coords.lng),
					});
				}
			});

			setAllGeneratorData(tempGen);
		};

		processMarkers();
	}, [geocodedMarkers]);

	useEffect(
		() => () => {
			removeMapRef?.();
		},
		[]
	);

	useEffect(() => {
		if (!user?.uid) return;

		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.routes), where("transporterId", "==", user.uid)),
			(snapshot) => {
				setAllRoutes(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			}
		);

		return () => unsubscribe();
	}, [user]);

	useEffect(() => {
		if (!isMapReady || !mapRef || !mapReady) return;

		try {
			const validGenerators = allGeneratorData.filter(
				(gen) =>
					gen?.serviceAddCoordinates?.lat &&
					gen?.serviceAddCoordinates?.lng &&
					gen?.randomCoordinates?.lat &&
					gen?.randomCoordinates?.lng
			);

			if (validGenerators.length > 0) {
				const markerPositions = validGenerators.flatMap((gen) => [
					new data.Position(gen.serviceAddCoordinates.lng, gen.serviceAddCoordinates.lat),
					new data.Position(gen.randomCoordinates.lng, gen.randomCoordinates.lat),
				]);

				const box = data.BoundingBox.fromPositions(markerPositions);
				mapRef.setCamera({ bounds: box, padding: 100, duration: 200, type: "ease" });
			} else {
				mapRef.setCamera(DEFAULT_CAMERA);
			}

			// Limit zoom only after the map is fully ready
			limitScrollWheelZoom(mapRef);
		} catch (error) {
			console.error("Error setting map camera:", error);
			mapRef.setCamera(DEFAULT_CAMERA);
		}
	}, [isMapReady, mapRef, allGeneratorData, mapReady]);

	const zoomIn = () => {
		if (mapRef) {
				const currentZoom = mapRef.getCamera().zoom;
				mapRef.setCamera({ zoom: currentZoom + 1 });
		}
};

const zoomOut = () => {
		if (mapRef) {
				const currentZoom = mapRef.getCamera().zoom;
				mapRef.setCamera({ zoom: currentZoom - 1 });
		}
};


	return (
		<div className="h-full">
			<div className="w-full h-full rounded-xl p-2 z-50 bg-white">
				<AzureMap
					options={{
						authOptions,
						...DEFAULT_CAMERA,
						view: "Auto",
					}}
					events={{
						ready: () => {
							console.log("Map is ready!");
							setMapReady(true);
						},
					}}
				>
					{mapReady &&
						allGeneratorData.map((genData) => {
							const coords = genData?.serviceAddCoordinates;
							if (!coords?.lat || !coords?.lng) return null;

							return (
								<CustomHtmlGeneratorMarker
									key={genData.id}
									genData={genData}
									draggable={true}
									allRoutes={allRoutes}
									isCurrent={false}
								/>
							);
						})}
						<div className="absolute bottom-5 right-5 flex flex-col gap-2 z-10">
																	<button 
																			onClick={zoomIn} 
																			className="w-10 h-10 flex items-center justify-center 
																								 bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
																									font-bold rounded shadow-md"
																	>
																			<FaPlus />
																	</button>
																	<button 
																			onClick={zoomOut} 
																			className="w-10 h-10 flex items-center justify-center 
																								 bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
																								 font-bold rounded shadow-md"
																	>
																			<FaMinus/>
																	</button>
															</div>
				</AzureMap>
			</div>
		</div>
	);
};

CustomAzureGeneratorsMapView.propTypes = {
	routesMap: PropTypes.object,
	geocodedMarkers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			serviceAddCoordinates: PropTypes.shape({
				lat: PropTypes.number.isRequired,
				lng: PropTypes.number.isRequired,
			}).isRequired,
			randomCoordinates: PropTypes.shape({
				lat: PropTypes.number,
				lng: PropTypes.number,
			}),
		})
	),
	filteredData: PropTypes.array,
};

CustomAzureGeneratorsMapView.defaultProps = {
	routesMap: {},
	geocodedMarkers: [],
	filteredData: [],
};

export default CustomAzureGeneratorsMapView;
