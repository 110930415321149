import React, { useEffect, useRef, useState, forwardRef, useLayoutEffect } from "react";
import { HiOutlineChevronDown, HiX } from "react-icons/hi";

const MultiSelectRounded = forwardRef(
	(
		{
			label,
			options,
			value,
			onChange,
			placeholder = "",
			styles = "",
			margin = "4",
			error,
			isDisabled,
			isRequired = false,
			maxWidth = "",
		},
		ref
	) => {
		const [isOpen, setIsOpen] = useState(false);
		const [selectedOptions, setSelectedOptions] = useState([]);
		const [highlightedIndex, setHighlightedIndex] = useState(-1);
		const dropdownRef = useRef(null);
		const buttonRef = useRef(null);
		const optionsRef = useRef([]);
		const [pillsContainerWidth, setPillsContainerWidth] = useState(null);

		const toggleDropdown = () => setIsOpen(!isOpen);

		const handleSelect = (option) => {
			if (isDisabled) return;
			let updatedSelection;
			if (selectedOptions.some((item) => item.value === option.value)) {
				updatedSelection = selectedOptions.filter((item) => item.value !== option.value);
			} else {
				updatedSelection = [...selectedOptions, option];
			}
			setSelectedOptions(updatedSelection);
			onChange(updatedSelection.map((item) => item.value));
		};

		const removeOption = (optionToRemove, e) => {
			e.stopPropagation();
			const updatedSelection = selectedOptions.filter((option) => option.value !== optionToRemove.value);
			setSelectedOptions(updatedSelection);
			onChange(updatedSelection.map((item) => item.value));
		};

		const handleKeyDown = (event) => {
			if (event.key === "Enter" || event.key === " ") {
				event.preventDefault();
				if (!isOpen) {
					setIsOpen(true);
				} else if (highlightedIndex !== -1) {
					handleSelect(options[highlightedIndex]);
				}
			} else if (event.key === "Escape") {
				setIsOpen(false);
				buttonRef.current.focus();
			} else if (isOpen) {
				switch (event.key) {
					case "ArrowDown":
						event.preventDefault();
						setHighlightedIndex((prev) => (prev < options.length - 1 ? prev + 1 : 0));
						break;
					case "ArrowUp":
						event.preventDefault();
						setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : options.length - 1));
						break;
					default:
						break;
				}
			}
		};

		useEffect(() => {
			const handleClickOutside = (event) => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => document.removeEventListener("mousedown", handleClickOutside);
		}, []);

		useEffect(() => {
			// Sync the selectedOptions with the value prop when value changes
			const selectedOptionObjects = options.filter((option) => value?.includes(option.value));
			setSelectedOptions(selectedOptionObjects);
		}, [value, options]);

		useEffect(() => {
			if (!isOpen) {
				setHighlightedIndex(-1);
			}
		}, [isOpen]);

		useEffect(() => {
			if (isOpen && highlightedIndex !== -1 && optionsRef.current[highlightedIndex]) {
				optionsRef.current[highlightedIndex].scrollIntoView({ block: "nearest" });
			}
		}, [isOpen, highlightedIndex]);

		// Calculate max width for pills container
		useEffect(() => {
			console.log({ buttonRef });
			const rect = buttonRef.current.getBoundingClientRect();
			console.log({ rect });
			setPillsContainerWidth(rect.width);
		}, [buttonRef]); // Recalculate when selection changes

		return (
			<div
				className={`relative flex ${styles ? styles : "items-center justify-between min-w-40"} my-${margin}`}
				ref={dropdownRef}
			>
				{label && (
					<label
						htmlFor={label?.toLowerCase().replace(" ", "-")}
						className={`${styles ? "w-full" : label ? "w-1/3" : "w-full"} text-inputLabel font-normal`}
					>
						{`${label} ${isRequired ? "*" : ""}`}
					</label>
				)}
				<div
					className={`${label ? "w-2/3" : "w-full"} ${
						isDisabled ? "bg-inputBg" : "cursor-pointer bg-inputBg focus:ring-1 focus:ring-dashInActiveBtnText"
					} border rounded-full max-h-9 h-9 pr-4 text-left text-cardTextGray flex items-center justify-between focus:outline-none overflow-hidden`}
					onClick={isDisabled ? () => {} : toggleDropdown}
					onKeyDown={handleKeyDown}
					tabIndex={isDisabled ? -1 : 0}
					role="combobox"
					ref={buttonRef}
				>
					<div
						className="flex-1 overflow-hidden whitespace-nowrap relative"
						style={{
							maxWidth: pillsContainerWidth ? `${pillsContainerWidth}px` : "calc(100% - 20px)",
							minWidth: "160px",
						}}
					>
						<div className="flex items-center gap-1 overflow-x-hidden py-1 pl-2 w-full">
							{selectedOptions.length > 0 ? (
								selectedOptions.map((option) => (
									<span
										key={option.value}
										className="bg-gray-200 text-gray-700 rounded-full whitespace-nowrap px-2 py-0.5 text-sm flex items-center gap-1 flex-shrink-0 max-w-[100px] overflow-hidden"
									>
										<span className="truncate">{option.label}</span>
										<button
											onClick={(e) => !isDisabled && removeOption(option, e)}
											tabIndex={isDisabled ? -1 : 0}
											onKeyDown={(e) => {
												if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
													removeOption(option, e);
												}
											}}
											type="button"
											className="flex-shrink-0"
										>
											<HiX className={`${isDisabled ? "cursor-default hidden focus:outline-none" : ""} h-4 w-4`} />
										</button>
									</span>
								))
							) : (
								<span className="opacity-50 pl-2 truncate">{placeholder}</span>
							)}
						</div>

						{selectedOptions.length > 0 && (
							<span className="absolute right-0 top-0 h-full w-8 bg-gradient-to-r from-transparent to-inputBg pointer-events-none"></span>
						)}
					</div>

					<HiOutlineChevronDown
						className={`h-5 w-5 text-cardTextGray transition-transform duration-250 flex-shrink-0 ${
							isOpen ? "transform rotate-180" : ""
						} ${isDisabled ? "hidden" : "block"}`}
					/>
				</div>

				{/* Dropdown menu */}
				{isOpen && (
					<ul
						className={`absolute z-10 overflow-y-auto mt-1 min-w-fit h-fit max-h-80 ${
							label && !styles ? "w-2/3" : "w-full"
						} text-nowrap top-full right-0 bg-white border border-dropdownDownArrow rounded-2xl shadow-md`}
						role="listbox"
					>
						{options.map((option, index) => (
							<li
								key={option.value}
								ref={(el) => (optionsRef.current[index] = el)}
								onClick={() =>
									(option?.isDisabled && option?.isDisabled === true) || (option?.isHeader && option?.isHeader === true)
										? () => {}
										: handleSelect(option)
								}
								onMouseEnter={() => setHighlightedIndex(index)}
								className={`px-4 h-9 py-2 hover:bg-[#BBBBBB3B] transition-colors duration-150 flex items-center ${
									highlightedIndex === index ? "bg-[#BBBBBB3B]" : ""
								} ${
									option?.isHeader && option?.isHeader === true && option?.isDisabled && option?.isDisabled === true
										? "bg-cardTextBlue hover:bg-cardTextBlue text-white cursor-not-allowed font-semibold"
										: option?.isDisabled && option?.isDisabled === true
										? "bg-white hover:bg-white text-gray-300 cursor-not-allowed"
										: "cursor-pointer"
								}`}
								role="option"
							>
								{option?.isHeader && option?.isHeader === true ? null : (
									<input
										type="checkbox"
										checked={selectedOptions.some((item) => item.value === option.value)}
										onChange={() => {}}
										className="mr-2"
										disabled={option?.isDisabled && option?.isDisabled === true}
									/>
								)}
								{option.label}
							</li>
						))}
					</ul>
				)}
				{error && <p className="absolute left-0 bottom-[-20px] text-red-500 text-xs">{error}</p>}
			</div>
		);
	}
);

MultiSelectRounded.displayName = "MultiSelectRounded";

export default MultiSelectRounded;
