import Loader from "../UI/loaders/Loader";
import { InfoWindow, useMap, AdvancedMarker, Map, Pin } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { IoMdClose } from "react-icons/io";
import { serviceTypesMap } from "../../utils/constants";

const MapWithMarkers = ({
	geocodedMarkers,
	initialCenter,
	isAddressesLoaded,
	zoomVal = 6,
	mapUniqueId = "Single",
	roundedVal = "rounded-secondary_radii",
	routesMap,
}) => {
	return isAddressesLoaded ? (
		<MapComp
			markers={geocodedMarkers}
			initialCenter={initialCenter}
			zoomVal={zoomVal}
			mapUniqueId={mapUniqueId}
			roundedVal={roundedVal}
			routesMap={routesMap}
		/>
	) : (
		<Loader />
	);
};

const MapComp = ({ markers, initialCenter, zoomVal, mapUniqueId, roundedVal, routesMap }) => {
	const [mapRef, setMapRef] = useState(null);

	useEffect(() => {
		if (mapRef && markers.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			markers.forEach((marker) => bounds.extend({ lat: marker.lat, lng: marker.lng }));
			mapRef.fitBounds(bounds);
		}
	}, [markers, mapRef]);

	return (
		<div className={`h-full w-full z-0 overflow-hidden ${roundedVal}`}>
			<Map
				className="h-full w-full z-0"
				mapId={"BoxLocation"}
				defaultCenter={initialCenter}
				gestureHandling="cooperative"
				// center={initialCenter}
				defaultZoom={12}
				zoomControl={true}
			>
				{markers.length > 0 && <ClusteredGenMarkers gens={markers} routesMap={routesMap} />}
			</Map>
		</div>
	);
};

export default React.memo(MapWithMarkers);

const ClusteredGenMarkers = ({ gens, routesMap }) => {
	const [markers, setMarkers] = useState({});
	const [openInfoWindows, setOpenInfoWindows] = useState(new Set());

	const map = useMap();
	const clusterer = useMemo(() => {
		if (!map) return null;
		return new MarkerClusterer({ map });
	}, [map]);

	useEffect(() => {
		if (!clusterer) return;
		clusterer.clearMarkers();
		clusterer.addMarkers(Object.values(markers));
	}, [clusterer, markers]);

	const setMarkerRef = useCallback((marker, key) => {
		setMarkers((markers) => {
			if ((marker && markers[key]) || (!marker && !markers[key])) return markers;
			if (marker) {
				return { ...markers, [key]: marker };
			} else {
				const { [key]: _, ...newMarkers } = markers;
				return newMarkers;
			}
		});
	}, []);

	const handleInfoWindowClose = useCallback((genKey) => {
		setOpenInfoWindows((prev) => {
			const newSet = new Set(prev);
			newSet.delete(genKey);
			return newSet;
		});
	}, []);

	const handleMarkerClick = useCallback((gen) => {
		setOpenInfoWindows((prev) => {
			const newSet = new Set(prev);
			newSet.add(gen.key);
			return newSet;
		});
	}, []);

	console.log("====================================");
	console.log({ gens, routesMap });
	console.log("====================================");

	return (
		<>
			{gens.map((gen) => (
				<GenMarker key={gen.key} gen={gen} onClick={handleMarkerClick} setMarkerRef={setMarkerRef} />
			))}

			{Array.from(openInfoWindows).map((genKey) => {
				const gen = gens.find((g) => g.key === genKey);
				if (!gen) return null;

				return (
					<InfoWindow
						key={genKey}
						anchor={markers[genKey]}
						className="min-h-fit w-[400px]"
						onCloseClick={() => handleInfoWindowClose(genKey)}
					>
						<div className="bg-white w-full shadow-lg rounded-lg overflow-hidden relative">
							<div className="flex justify-between items-center bg-gray-100 p-2 px-4 border-b border-gray-300">
								<h2 className="text-lg font-semibold text-gray-800">
									{gen.name}{" "}
									{location.pathname?.endsWith("map") && (
										<a
											className="text-xs font-thin text-blueText underline"
											target="_blank"
											href={`/admin/generators/${gen.id}/generator-profile`}
										>
											View Profile
										</a>
									)}
								</h2>
								<button
									onClick={() => handleInfoWindowClose(genKey)}
									className="text-gray-500 hover:text-red-500 transition-colors duration-200"
								>
									<IoMdClose size={24} />
								</button>
							</div>
							<div className="border-t w-full border-b h-fit p-4 text-sm">
								<div className="flex">
									<span className="font-medium w-1/3">Service Address:</span>
									<span className="w-2/3">{gen.address ?? "--"}</span>
								</div>
								<div className="flex">
									<span className="font-medium w-1/3">OCTO No:</span>
									<span className="w-2/3">{gen.octoNumber ?? "--"}</span>
								</div>
								<div className="flex items-start">
									<span className="font-medium w-1/3">Service Type:</span>
									<span className="w-2/3">
										{Array.isArray(gen?.serviceType)
											? gen.serviceType.map((serviceType) => serviceTypesMap[serviceType] || serviceType).join(", ")
											: "No services"}
									</span>
								</div>
								<div className="flex">
									<span className="font-medium w-1/3">Service Frequency:</span>
									<span className="w-2/3">
										{gen?.serviceFrequency?.map((freq) => freq.toString().replace("MTWM", "MTW")).join(", ") ?? "--"}
									</span>
								</div>
								<div className="flex">
									<span className="font-medium w-1/3">Route Assigned:</span>
									<span className="w-2/3">
										{Array.isArray(gen?.routes)
											? gen?.routes
													?.map((route) => routesMap?.[route])
													?.filter(Boolean)
													?.toString()
											: "None"}
									</span>
								</div>
							</div>
						</div>
					</InfoWindow>
				);
			})}

			<style>{`
        .gm-style-iw.gm-style-iw-c {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-style-iw-ch {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style-iw-d {
          padding: 0 !important;
          overflow: hidden !important;
        }
      `}</style>
		</>
	);
};

const GenMarker = ({ gen, onClick, setMarkerRef }) => {
	const handleClick = useCallback(() => onClick(gen), [onClick, gen]);
	const ref = useCallback((marker) => setMarkerRef(marker, gen.key), [setMarkerRef, gen.key]);

	return (
		<AdvancedMarker position={{ lat: gen.lat, lng: gen.lng }} ref={ref} onClick={handleClick}>
			<Pin />
		</AdvancedMarker>
	);
};
