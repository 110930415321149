import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaUserLarge } from "react-icons/fa6";
import { BiSolidLock } from "react-icons/bi";
import { toast } from "react-toastify";
import { useGeneratorUser } from "../../../context/GeneratorUserContext.jsx";
import { emailRegex } from "../../../utils/mimes";
import { doc, getDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../config/firebase.js";

const fetchTransporterImg = async (transporterId) => {
	let snapshot = await getDoc(doc(db, COLLECTIONS.transporters, transporterId));
	let data = {
		id: transporterId,
		...snapshot.data(),
	};
	return data?.companyLogo?.link ?? "";
};

const GeneratorPortalAuth = () => {
	const { user, authLoading, login } = useGeneratorUser();
	const [transporterImage, setTransporterImage] = useState("");
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const navigate = useNavigate();
	const query = useSearchParams();
	const transporterId = query[0]?.get("transporterId");

	useEffect(() => {
		if (!authLoading && user) {
			navigate("/generator-dashboard");
		}
	}, [authLoading, user, navigate]);

	const fetchTransporterData = async () => {
		let imgUrl = await fetchTransporterImg(transporterId);
		setTransporterImage(imgUrl);
	};

	useEffect(() => {
		if (transporterId) {
			fetchTransporterData();
		}
	}, [transporterId]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!emailRegex.test(formData.email)) {
			toast.error("Invalid email format");
			return;
		}

		if (!formData.password?.trim()) {
			toast.error("Password cannot be empty");
			return;
		}

		if (!transporterId) {
			toast.error("No linked transporter found");
			return;
		}

		login(formData.email, formData.password, transporterId);
	};

	return (
		<div className="flex flex-col gap-8 min-h-screen items-center justify-center bg-white">
			<div className="h-20">
				<img src={transporterImage} alt="" className="h-full" />
			</div>
			<div className="w-full flex flex-col items-center max-w-md bg-[#F6F8FA] p-12 rounded-sm shadow-md">
				<h3 className="text-center text-xl font-medium mb-6">Welcome</h3>

				<div className="space-y-6 w-full">
					{/* Email Field */}
					<div className="flex items-center border bg-white border-cardTextBlue rounded-full px-4 py-3">
						<span className="text-gray-400">
							<FaUserLarge />
						</span>
						<input
							type="email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							placeholder="Enter Email"
							className="custom-autofill ml-2 w-full border-none focus:ring-0 outline-none"
							required
						/>
					</div>

					{/* Password Field */}
					<div className="flex items-center border border-cardTextBlue bg-white rounded-full px-4 py-3">
						<span className="text-gray-400">
							<BiSolidLock size={20} />
						</span>
						<input
							type="password"
							name="password"
							value={formData.password}
							onChange={handleInputChange}
							placeholder="Enter Password"
							className="custom-autofill ml-2 w-full border-none focus:ring-0 outline-none"
							required
						/>
					</div>
				</div>

				{/* Submit Button */}
				<button
					onClick={handleSubmit}
					className="mt-8 w-3/4 bg-gradient-to-r from-blue-500 to-green-500 text-white py-3 rounded-md"
				>
					Login
				</button>

				<p className="text-center text-sm text-primary mt-4">Powered By Octopus SaaS</p>
			</div>
		</div>
	);
};

export default GeneratorPortalAuth;
