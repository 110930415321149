import { useEffect, useRef, useState } from "react";
import { CheckCircleIcon, ClockIcon, ArrowRightIcon } from "lucide-react";
import {
	onSnapshot,
	query,
	collection,
	where,
	orderBy,
	doc,
	getDoc,
	Timestamp,
	limit,
	getDocs,
} from "firebase/firestore";
import { db, COLLECTIONS } from "../../../config/firebase";
import GeneratorDetailsForm from "./components/GeneratorDetailsForm/GeneratorDetailsForm";
import GeneratorOfficeHours from "../../admin/generator-management/components/generators/components/detail-forms/components/GeneratorOfficeHours/GeneratorOfficeHours";
import ContactUsForm from "./components/ContactUsForm/ContactUsForm";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/UI/loaders/Loader";
import { dateFormatter, daysOfWeek, randomizeCoordinates, renderAddress } from "../../../utils/helpers";
import {
	frequencyPrimaryOptions,
	frequencySecondaryOptions,
	SERVICE_STATUS,
	serviceTypes,
} from "../../../utils/constants";
import CustomAzureGeneratorDashboardMap from "./CustomAzureGeneratorDashboardMap";
import { AzureMapsProvider } from "react-azure-maps";
import CalendarWithTimeSlots from "../../admin/generator-management/components/generators/components/detail-forms/components/open-calendar/GeneratorCalendarComponent";
import { getGeneratorById } from "../../../utils/firebaseOperations";
import { useGeneratorData } from "./components/GeneratorDataContext";
import ChildDropdown from "./components/dropDowns/childDropdown";
import ParentDropdown from "./components/dropDowns/parentDropdown";
import GeneratorUserAccBtn from "../GenUserAccountButton/GeneratorUserAccountButton";
import { flushSync } from 'react-dom';

const GeneratorDashboard = () => {
	const { user, authLoading, generatorData: data, allServices: genService } = useGeneratorUser();
	const [parentAccount, setParentAccount] = useState(null);
	const navigate = useNavigate();
	const [nextService, setNextService] = useState(null);
	const [lastCompleted, setLastCompleted] = useState(null);
	const contactFormRef = useRef();
	const [randomCoordinates, setRandomCoordinates] = useState({ lat: 0, lng: 0 });
	const [waypointsRemaining, setWaypointsRemaining] = useState("Stops Remaining");
	const [completed, setCompleted] = useState(false);
	const [generatorWaypointCompleted, setGeneratorWaypointCompleted] = useState(false);
	const [sameParentGenerators, setSameParentGenerators] = useState(null);
	const [routeStarted, setRouteStarted] = useState(null);
	const [progressBar, setProgressbar] = useState("0%");
	const [stopsRemaining, setStopsRemaining] = useState(0);
	const [value, setValue] = useState("");
	const [serviceFrequency, setServiceFrequency] = useState("");
	const [parentGenerator, setParentGenerator] = useState(null);
	const [childrenData, setChildrenData] = useState(null);
	const [selectedValue, setSelectedValue] = useState(null);
	const { generatorData, setGeneratorData } = useGeneratorData();
	const [todayService, setTodayService] = useState(null);
	const [allServices, setAllServices] = useState([]);
	const [allServiceFrequencies, setAllServiceFrequencies] = useState([
		...frequencyPrimaryOptions,
		...frequencySecondaryOptions,
	]);
	const [serviceCompletionText, setServiceCompletionText] = useState("");
	const [todayStartedRoute, setTodayStartedRoute] = useState();
	const [isLoadingTracking, setIsLoadingTracking] = useState(false);


	useEffect(() => {
		if (!data?.id) return;
		
		console.log("Initial data loading for generator:", data.id);
		setIsLoadingTracking(true);
		setGeneratorData(data);
		setAllServices(genService);
		if (data?.parentAccount) {
		  setParentAccount(false);
		} else {
		  setParentAccount(true);
		}
		performInitialServiceStatusCheck(data.id);
		
	  }, [data?.id]);

useEffect(() => {
	if (!generatorData?.id) return;
	if (generatorData.id !== data?.id) {
	  console.log(`Generator changed to ${generatorData.id} (different from context's ${data?.id})`);
	  setIsLoadingTracking(true);
	  performInitialServiceStatusCheck(generatorData.id);
	}
  }, [generatorData?.id]);

	  
	  const generatorCompletionStatusRef = useRef({});

useEffect(() => {
	window._processingGenerator = null;
	generatorCompletionStatusRef.current = {};
	
	return () => {
	  if (subscriptionsRef.current) {
		subscriptionsRef.current.forEach((unsub) => {
		  if (typeof unsub === 'function') {
			try {
			  unsub();
			} catch (e) {
			  console.error("Error unsubscribing:", e);
			}
		  }
		});
		subscriptionsRef.current = [];
	  }
	};
  }, []);
	useEffect(() => {
		if (!generatorData?.id) return;
		const q = query(collection(db, COLLECTIONS.serviceSchedules), where("generatorId", "==", generatorData.id));
		const unsubscribe = onSnapshot(q, async (snap) => {
			try {
				console.log("Snapshot received:", snap);
				const tempSchedules = await Promise.all(
					snap.docs.map(async (el) => {
						if (!el.exists()) return null;

						const data = { ...el.data(), id: el.id };
						if (Array.isArray(data.serviceType)) {
							data.serviceType = data.serviceType[0];
						}
						if (data.isDeleted) return null;
						let createdAt = null;
						if (data.createdAt?.seconds || data.createdAt?.nanoseconds) {
							createdAt = new Timestamp(data.createdAt.seconds, data.createdAt.nanoseconds).toDate();
						} else if (data.createdAt?.toDate) {
							createdAt = data.createdAt.toDate();
						} else {
							createdAt = new Date();
						}

						return { ...data, createdAt };
					})
				);
				const filteredSchedules = tempSchedules.filter(Boolean);
				filteredSchedules.sort((a, b) => a.createdAt - b.createdAt);

				const formattedServiceTypes = [...new Set(filteredSchedules.map((item) => item.serviceType))]
					.map((service) => {
						const matchedService = serviceTypes.find((type) => type.value === service);
						return matchedService ? matchedService.label : service;
					})
					.join(", ");

				console.log(formattedServiceTypes);
				const uniqueServiceTypes = [
					...new Set(
						filteredSchedules.map((item) => {
							const type = item.serviceFrequency?.type;
							return type;
						})
					),
				];
				uniqueServiceTypes.filter(Boolean);
				setValue(formattedServiceTypes);
				setServiceFrequency(uniqueServiceTypes);
				console.log("Final schedules:", filteredSchedules);
			} catch (error) {
				console.error("Error processing snapshot:", error);
			}
		});
		return () => {
			console.log("Unsubscribing from snapshot listener");
			unsubscribe();
		};
	}, [generatorData?.id]);

	useEffect(() => {
		if (!generatorData?.id) return;

		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.generators), where("parentAccount", "==", data?.id)),
			async (snap) => {
				try {
					const data = snap.docs.map((doc) => doc.data());
					setChildrenData(data);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);
		const unsubscribe2 = onSnapshot(
			query(collection(db, COLLECTIONS.generators), where("parentAccount", "==", generatorData.parentAccount)),
			async (snap) => {
				try {
					const data = snap.docs.map((doc) => doc.data());
					setSameParentGenerators(data);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);
		const unsubscribe3 = onSnapshot(
			query(collection(db, COLLECTIONS.generators), where("id", "==", generatorData?.parentAccount)),
			async (snap) => {
				try {
					const data = snap.docs.map((doc) => doc.data());
					setParentGenerator(data);
					console.log("psrentgen", data);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);
		return () => {
			console.log("Unsubscribing from snapshot listener");
			unsubscribe();
			unsubscribe2();
			unsubscribe3();
		};
	}, [generatorData?.id, generatorData?.parentAccount]);

	useEffect(() => {
		if (!authLoading && !user) {
			navigate("/generator-portal-auth");
		}
	}, [authLoading, user, navigate, generatorData]);
	
	useEffect(() => {
		console.log({ generatorData });
		if (!generatorData) return;
		if (
			generatorData?.serviceAddCoordinates &&
			generatorData.serviceAddCoordinates?.lat &&
			generatorData.serviceAddCoordinates?.lng
		) {
			setRandomCoordinates(
				randomizeCoordinates(generatorData.serviceAddCoordinates?.lat, generatorData.serviceAddCoordinates?.lng)
			);
		}

		return () => {};
	}, [generatorData]);

	useEffect(() => {
		if (!allServices?.length) return;
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const filteredDate = allServices?.filter((el) => {
			const serviceDate = el.date.toDate();
			return serviceDate.getTime() >= today.getTime();
		});

		const filteredCompleted = allServices?.filter((el) => el.status === "COMPLETE");
		if (filteredCompleted) {
			console.log("completed service", filteredCompleted, "yguyg", filteredCompleted[filteredCompleted.length - 1]);

			//setLastCompleted(filteredCompleted[filteredCompleted.length - 1]);
		} else {
			//setLastCompleted(null);
		}
		setCompleted(false);
		if (filteredDate.length) {
			//	setNextService(filteredDate[0]);
		}
	}, [allServices]);
	const fetchAvailableServices = async (snap) => {
		try {
			//setIsLoadingServices(true);
			await new Promise((resolve) => setTimeout(resolve));
			//setDelay(5000);
			let tempServices = [];
			const jobs = snap.docs.map(async (el) => {
				if (el.exists()) {
					const data = { ...el.data(), id: el.id };
					if (data?.routeId?.length > 0) {
						const routeRes = await getDoc(doc(db, COLLECTIONS.routes, data.routeId));
						if (routeRes.exists()) {
							data.routeData = { ...routeRes.data(), id: routeRes.id };
						}
					}
					if (data?.serviceScheduleId?.length > 0) {
						const serviceScheduleRes = await getDoc(doc(db, COLLECTIONS.serviceSchedules, data.serviceScheduleId));
						if (serviceScheduleRes.exists()) {
							data.serviceScheduleData = { ...serviceScheduleRes.data(), id: serviceScheduleRes.id };
						}
					}
					tempServices.push(data);
				}
			});
			await Promise.all(jobs);
			tempServices = tempServices.filter(
				(el) => el.status !== SERVICE_STATUS.DELETED && el.status !== SERVICE_STATUS.CANCELLED
			);
			const today = new Date();
			const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));
			console.log("todayUTC",todayUTC);
			
			setNextService(
				tempServices
					.filter((el) => {
						if (
							el.date.toDate() >= todayUTC&&el.status !== SERVICE_STATUS.COMPLETE
						) {
							return true;
						} else {
							return false;
						}
					})
					.sort((a, b) => a.date.toDate() - b.date.toDate())
			);
			setLastCompleted([
				...tempServices
					.filter((el) => el.status === SERVICE_STATUS.COMPLETE)
					.sort((a, b) => b.date.toDate() - a.date.toDate()),
			]);

			const todayServices = tempServices
			.filter((el) => {
			  const serviceDate = el.date.toDate();
			  return (
				serviceDate.getUTCFullYear() === todayUTC.getUTCFullYear() &&
				serviceDate.getUTCMonth() === todayUTC.getUTCMonth() &&
				serviceDate.getUTCDate() === todayUTC.getUTCDate()
			  );
			})
			.sort((a, b) => a.date.toDate() - b.date.toDate());
		  
		  console.log("Today services data:", todayServices); 
		  setTodayService(todayServices);
			console.log("tempServ",tempServices,nextService[0]?.date.toDate(),todayService[0]?.date.toDate(),todayService,todayUTC);
			
		} catch (error) {
			console.log(error);
		} finally {
			//setIsLoadingServices(false);
		}
	};


	const startDate = new Date();
	startDate.setDate(startDate.getDate()-30)
	const endDate = new Date();
	endDate.setDate(endDate.getDate() + 30);
    const subscriptionsRef = useRef([]);     

useEffect(() => {
	if (!generatorData?.id) return;
	const currentGenId = generatorData.id;
	const unsubscribers = [];
	
	const date = new Date();
	const todayUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
	const timestampId = todayUTC.toISOString();
	console.log("genId that is being tracked",currentGenId,generatorData?.id);
	
	setTimeout(() => {
	  const servicesUnsubscribe = onSnapshot(
		query(
		  collection(db, COLLECTIONS.scheduledServices),
		  where("generatorId", "==", currentGenId),
		  where("status", "not-in", [SERVICE_STATUS.CANCELLED, SERVICE_STATUS.DELETED]),
		  where("date", ">=", Timestamp.fromDate(startDate)),
		  where("date", "<=", Timestamp.fromDate(endDate)),
		  orderBy("date", "asc"),
		),
		async (snap) => {
		  if (generatorData.id !== currentGenId) {
			console.log(`Ignoring services snapshot - generator changed`);
			return;
		  }
		  
		  try {
			await fetchAvailableServices(snap);
			if (!todayService || todayService.length === 0) {
			  console.log(`No today services found for generator ${currentGenId}`);
			  return;
			}
			
			console.log(`Processing routes for generator ${currentGenId}`,timestampId);
			await processRoutesForTracking(todayService, timestampId, currentGenId);
		  } catch (error) {
			console.error(`Error processing services for generator ${currentGenId}:`, error);
		  }
		}
	  );
	  
	  unsubscribers.push(servicesUnsubscribe);
	  subscriptionsRef.current = unsubscribers;
	}, 300); 
	
	return () => {
	  if (subscriptionsRef.current) {
		subscriptionsRef.current.forEach((unsub) => {
		  if (typeof unsub === 'function') {
			try {
			  unsub();
			} catch (e) {
			  console.error("Error unsubscribing:", e);
			}
		  }
		});
		subscriptionsRef.current = [];
	  }
	};
  }, [generatorData?.id]);

useEffect(() => {
	return () => {
	  console.log("Component unmounting - cleaning up");
	   if (subscriptionsRef.current) {
		subscriptionsRef.current.forEach((unsub) => {
		  if (typeof unsub === 'function') {
			try {
			  unsub();
			} catch (e) {
			  console.error("Error unsubscribing:", e);
			}
		  }
		});
		subscriptionsRef.current = [];
	  }
	  
	  generatorCompletionStatusRef.current = {};
	  window._processedGenerators = {};
	};
  }, []);

async function performInitialServiceStatusCheck(genId) {
	if (!genId) return;
	
	console.log(`Performing initial service status check for generator ${genId}`);
	
	try {
	  const date = new Date();
	  const todayUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
	  const todayEndUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
	  
	  const todayStartTimestamp = Timestamp.fromDate(todayUTC);
	  const todayEndTimestamp = Timestamp.fromDate(todayEndUTC);
	  const servicesQuery = query(
		collection(db, COLLECTIONS.scheduledServices),
		where("generatorId", "==", genId),
		where("date", ">=", todayStartTimestamp),
		where("date", "<=", todayEndTimestamp),
		where("status", "not-in", [SERVICE_STATUS.CANCELLED, SERVICE_STATUS.DELETED])
	  );
	  
	  const servicesSnapshot = await getDocs(servicesQuery);
	  const services = servicesSnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
	  
	  console.log(`Found ${services.length} services for generator ${genId} on initial check`);
	  
	  if (services.length > 0) {
		setTodayService(services.sort((a, b) => a.date.toDate() - b.date.toDate()));
		const totalServices = services.length;
		const completedServices = services.filter(service => service.status === SERVICE_STATUS.COMPLETE).length;
		const allComplete = totalServices > 0 && completedServices === totalServices;
		const completionText = `${completedServices} of ${totalServices} services completed`;
		console.log(`Initial status for ${genId}: ${completionText}, allComplete: ${allComplete}`);
		let routeIsStarted = false;
		let selectedServiceObj = null;
		const routeIds = [...new Set(services.map(s => s.routeId))].filter(Boolean);
		for (const routeId of routeIds) {
		  if (!routeId) continue;
		  
		  try {
			const routeRef = doc(db, COLLECTIONS.routes, routeId);
			const dailyRouteRef = doc(routeRef, "dailyRoutes", todayUTC.toISOString());
			const dailyRouteData = await getDoc(dailyRouteRef);
			
			if (dailyRouteData.exists()) {
			  const routeData = dailyRouteData.data();
			  
			  if (routeData.assignedDriverId && routeData.assignedServiceVehicleId) {
				const routeServices = services.filter(s => s.routeId === routeId);
				
				for (const service of routeServices) {
				  if (service.status !== "CANCELLED" && service.status !== "DELETED") {
					selectedServiceObj = service;
					routeIsStarted = true;
				  if (service.status === "PENDING" || service.status === "INPROGRESS") {
					  break;
					}
				  }
				}
				
				if (routeIsStarted) break;
			  }
			}
		  } catch (error) {
			console.error(`Error checking route ${routeId} during initial status check:`, error);
		  }
		}
		
		flushSync(() => {
		  if (routeIsStarted && selectedServiceObj) {
			setTodayStartedRoute(selectedServiceObj);
			setRouteStarted(true);
		  } else {
			setRouteStarted(false);
		  }
		  
		  setServiceCompletionText(completionText);
		  setGeneratorWaypointCompleted(allComplete);
		  
		  generatorCompletionStatusRef.current[genId] = allComplete;
		});
		try {
		  localStorage.setItem(`completion_text_${genId}`, completionText);
		  localStorage.setItem(`waypoint_completed_${genId}`, JSON.stringify(allComplete));
		  localStorage.setItem(`route_started_${genId}`, JSON.stringify(routeIsStarted));
		} catch (e) {
		  console.error("Error saving to localStorage:", e);
		}
	  } else {
		console.log(`No services found for ${genId} during initial check`);
		
		flushSync(() => {
		  setServiceCompletionText("");
		  setGeneratorWaypointCompleted(false);
		  setRouteStarted(false);
		});
	  }
	} catch (error) {
	  console.error(`Error in initial service status check for ${genId}:`, error);
	} finally {
	  setIsLoadingTracking(false);
	}
  }


  async function processRoutesForTracking(todayServices, timestampId, currentGenId) {
	const isFirstRun = !window._processedGenerators;
	window._processedGenerators = window._processedGenerators || {};
	
	console.log(`Processing routes for generator ${currentGenId}, first run: ${isFirstRun}`);
	console.log(`Current generator in state: ${generatorData.id}`);
	
	if (generatorData.id === currentGenId) {
	  flushSync(() => {
		setGeneratorWaypointCompleted(false);
	  });
	  generatorCompletionStatusRef.current[currentGenId] = false;
	}
	const unsubscribers = [];
	
	if (!todayServices || todayServices.length === 0) {
	  console.log(`No today services for generator ${currentGenId}`);
	  setIsLoadingTracking(false);
	  return [];
	}
		if (generatorData.id !== currentGenId) {
	  setIsLoadingTracking(false);
	  return [];
	}
	
	try {
	  const generatorRoutesForDay = [...new Set(todayServices?.map((service) => service.routeId))];
	  
	  let anyRouteStarted = false;
	  let selectedService = null;
	  
	  console.log(`Processing ${generatorRoutesForDay.length} routes for generator ${currentGenId}`);
	  
	  for (const routeId of generatorRoutesForDay) {
		if (!routeId) continue;
		
		console.log(`Checking route ${routeId} for generator ${currentGenId}`);
		
		const routeRef = doc(db, COLLECTIONS.routes, routeId);
		const dailyRouteRef = doc(routeRef, "dailyRoutes", timestampId);
		
		try {
		  const dailyRouteSnapshot = await getDoc(dailyRouteRef);
		  
		  if (!dailyRouteSnapshot.exists()) {
			console.log(`No daily route data for route ${routeId}`);
			continue;
		  }
		  
		  const routeData = dailyRouteSnapshot.data();
		  const isRouteStarted = !!(routeData.assignedDriverId && routeData.assignedServiceVehicleId);
		  
		  
		  if (isRouteStarted) {
			const relatedServices = todayServices.filter((s) => s.routeId === routeId);
			
			console.log(`Found ${relatedServices.length} related services for route ${routeId}`);
			
			for (const service of relatedServices) {
			  if (generatorData.id !== currentGenId) {
				console.log(`Generator changed during service processing (current: ${generatorData.id}, expected: ${currentGenId})`);
				return unsubscribers;
			  }
			  
			  const serviceDoc = await getDoc(doc(db, COLLECTIONS.scheduledServices, service.id));
			  if (serviceDoc.exists()) {
				const serviceData = serviceDoc.data();
				console.log(`Service ${service.id} status: ${serviceData.status}`);
				if (serviceData.status !== "CANCELLED" && serviceData.status !== "DELETED") {
				  selectedService = service;
				  anyRouteStarted = true;
				  
				  if (serviceData.status === "PENDING" || serviceData.status === "INPROGRESS") {
					console.log(`Found active service ${service.id} with status ${serviceData.status}`);
					break;
				  }
				}
			  }
			}

			if (selectedService && generatorData.id === currentGenId) {
			  window._processedGenerators[currentGenId] = true;
			  
			  flushSync(() => {
				setTodayStartedRoute(selectedService);
				setRouteStarted(true);
				setGeneratorWaypointCompleted(false);
			  });
			  
			  generatorCompletionStatusRef.current[currentGenId] = false;
			  const selectedDailyRouteRef = doc(routeRef, "dailyRoutes", timestampId);
			  
			  const dailyRouteUnsubscribe = onSnapshot(selectedDailyRouteRef, async (snapshot) => {
				console.log(`Route update received for generator ${currentGenId}`);
				console.log(`Current generator in state: ${generatorData.id}`);
				if (generatorData.id !== currentGenId) {
				  console.log(`Ignoring route update for different generator (current: ${generatorData.id}, expected: ${currentGenId})`);
				  return;
				}
				
				if (!snapshot.exists()) return;
		
				try {
				  const currentServiceDoc = await getDoc(
					doc(db, COLLECTIONS.scheduledServices, selectedService.id)
				  );
				  
				  if (currentServiceDoc.exists()) {
					const status = currentServiceDoc.data().status;
					const isComplete = status === "COMPLETE";
					generatorCompletionStatusRef.current[currentGenId] = isComplete;
					
					console.log(`Service status pre-check for ${currentGenId}: ${status}, isComplete: ${isComplete}`);
					
					const routeData = snapshot.data();
					await processSelectedRouteData(selectedService, routeData, generatorRoutesForDay, currentGenId);
					if (generatorData.id === currentGenId) {
					  flushSync(() => {
						setGeneratorWaypointCompleted(generatorCompletionStatusRef.current[currentGenId] || false);
					  });
					}
				  } else {
					const routeData = snapshot.data();
					await processSelectedRouteData(selectedService, routeData, generatorRoutesForDay, currentGenId);
				  }
				} catch (error) {
				  console.error(`Error in onSnapshot completion pre-check:`, error);
				  const routeData = snapshot.data();
				  await processSelectedRouteData(selectedService, routeData, generatorRoutesForDay, currentGenId);
				}
			  });
			  unsubscribers.push(dailyRouteUnsubscribe);
			  break;
			}
		  }
		} catch (error) {
		  console.error(`Error processing route ${routeId} for generator ${currentGenId}:`, error);
		}
	  }
	  
	  if (!anyRouteStarted) {
		
		if (!window._processedGenerators[currentGenId]) {
		  flushSync(() => {
			setRouteStarted(false);
			setGeneratorWaypointCompleted(false);
		  });
		  generatorCompletionStatusRef.current[currentGenId] = false;
		} else {
		  console.log(`Skipping setRouteStarted(false) for generator ${currentGenId} as it was processed before`);
		}
	  }
	  
	  if (generatorData.id === currentGenId) {
		await calculateServiceCompletionStats(currentGenId, timestampId, generatorRoutesForDay);
	  }
	   return unsubscribers;
	  
	} catch (error) {
	  console.error(`Error in processRoutesForTracking for generator ${currentGenId}:`, error);
	  return unsubscribers;
	} finally {
	  setIsLoadingTracking(false);
	}
  }

  async function processSelectedRouteData(selectedService, routeData, generatorRoutesForDay, currentGenId) {
	if (!selectedService || !routeData || !routeData.waypoints) {
	  return;
	}
	
	if (generatorData.id !== currentGenId) {
	  console.log(`Ignoring route data for different generator (current: ${generatorData.id}, expected: ${currentGenId})`);
	  return;
	}
	
	try {
	  const date = new Date();
	  const todayUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
	  const timestampId = todayUTC.toISOString();
	  let generatorWaypointIndex = -1;
	  let inProgressIndex = -1;
	  let firstOccurrenceIndex = -1;
	  
	  console.log(`Current generator ID: ${generatorData.id}, waypoints count: ${routeData.waypoints.length}`);
	  const generatorWaypoints = routeData.waypoints.filter(wp => wp.id === generatorData.id);
	  console.log(`Found ${generatorWaypoints.length} waypoints for generator ${currentGenId}`);
	  
	  if (generatorWaypoints.length === 0) {
		console.log(`No waypoints found for generator ${currentGenId}, exiting`);
		return;
	  }
	  
	  for (let index = 0; index < routeData.waypoints.length; index++) {
		const waypoint = routeData.waypoints[index];
		
		if (waypoint.id === generatorData.id) {
		  console.log(`Found waypoint for current generator at index: ${index}, serviceId: ${waypoint.serviceId}`);
		  
		  if (firstOccurrenceIndex === -1) {
			firstOccurrenceIndex = index;
		  }
		  
		  try {
			if (generatorData.id !== currentGenId) {
			  console.log(`Generator changed during waypoint processing (current: ${generatorData.id}, expected: ${currentGenId})`);
			  return;
			}
			
			const serviceDoc = await getDoc(doc(db, COLLECTIONS.scheduledServices, waypoint.serviceId));
			
			if (!serviceDoc.exists()) continue;
			
			const serviceData = serviceDoc.data();
			console.log(`Service ${waypoint.serviceId} status: ${serviceData.status}`);
			
			if (serviceData.status === "INPROGRESS") {
			  inProgressIndex = index;
			  console.log(`Found INPROGRESS service at index ${index}`);
			} else if (serviceData.status === "PENDING" && generatorWaypointIndex === -1) {
			  generatorWaypointIndex = index;
			  console.log(`Found PENDING service at index ${index}`);
			} else if (serviceData.status === "COMPLETE") {
			  console.log(`Found COMPLETE service at index ${index}`);
			}
		  } catch (error) {
			console.error(`Error getting service data for waypoint at index ${index}:`, error);
		  }
		}
	  }
	  
	  if (inProgressIndex !== -1) {
		generatorWaypointIndex = inProgressIndex;
		console.log(`Using INPROGRESS waypoint at index ${inProgressIndex}`);
	  } else if (generatorWaypointIndex === -1) {
		generatorWaypointIndex = firstOccurrenceIndex;
		console.log(`Using first occurrence at index ${firstOccurrenceIndex}`);
	  }
	  
	  if (generatorWaypointIndex === -1) {
		console.log(`No valid waypoint index found for generator ${currentGenId}`);
		return;
	  }
	  
	  const serviceIds = routeData.waypoints
		.slice(0, generatorWaypointIndex + 1)
		.map((waypoint) => waypoint.serviceId)
		.filter((id) => id);
	  
	  console.log(`Calculating progress based on ${serviceIds.length} services up to waypoint ${generatorWaypointIndex}`);
	  let pendingCount = 0;
	  let currentGenStatus = null;
	  
	  for (const serviceId of serviceIds) {
		try {
		  if (generatorData.id !== currentGenId) {
			console.log(`Generator changed during service status check (current: ${generatorData.id}, expected: ${currentGenId})`);
			return;
		  }
		  
		  const serviceDoc = await getDoc(doc(db, COLLECTIONS.scheduledServices, serviceId));
		  
		  if (!serviceDoc.exists()) continue;
		  
		  const serviceData = serviceDoc.data();
		  
		  if (serviceData?.status === "PENDING") {
			pendingCount++;
		  }
		  
		  if (serviceId === routeData.waypoints[generatorWaypointIndex].serviceId) {
			currentGenStatus = serviceDoc;
			console.log(`Found current generator's service: ${serviceId}, status: ${serviceData.status}`);
		  }
		} catch (error) {
		  console.error(`Error checking service status for ${serviceId}:`, error);
		}
	  }
	  
	  if (!currentGenStatus) {
		try {
		  if (generatorData.id !== currentGenId) {
			console.log(`Generator changed during direct status check (current: ${generatorData.id}, expected: ${currentGenId})`);
			return;
		  }
		  
		  const serviceId = routeData.waypoints[generatorWaypointIndex].serviceId;
		  console.log(`Fetching current generator's service directly: ${serviceId}`);
		  
		  currentGenStatus = await getDoc(doc(db, COLLECTIONS.scheduledServices, serviceId));
		  
		  if (currentGenStatus && currentGenStatus.exists()) {
			console.log(`Direct fetch result: status=${currentGenStatus.data().status}`);
		  } else {
			console.log(`Direct fetch: service document does not exist`);
		  }
		} catch (error) {
		  console.error(`Error getting current generator status directly:`, error);
		}
	  }
	  
	  let progress = firstOccurrenceIndex > 0
		? ((generatorWaypointIndex + 1 - pendingCount) / (generatorWaypointIndex + 1)) * 100
		: 0;
	  
	  console.log(`Progress calculation: ${progress}%, pendingCount: ${pendingCount}`);
	  
	  let progressbarValue = progress;
	  let shouldSetCompleted = false;
	  
	  if (currentGenStatus && currentGenStatus.exists()) {
		const status = currentGenStatus.data().status;
		
		console.log(`Current generator service status: ${status}`);
		
		if (status === "COMPLETE") {
		  shouldSetCompleted = true;
		  progressbarValue = 100;
		  console.log(`Service is COMPLETE, setting progress to 100%`);
		} else if (status === "INPROGRESS" || pendingCount === 0) {
		  progressbarValue = 90;
		  console.log(`Service is INPROGRESS or no pending services, setting progress to 90%`);
		} else {
		  progressbarValue = progress === 100 ? 90 : progress;
		  console.log(`Setting progress to ${progressbarValue}%`);
		}
	  }
	  
	  let waypointsRemainingText = "Stops Remaining";
	  
	  if (currentGenStatus && currentGenStatus.exists()) {
		const status = currentGenStatus.data().status;
		
		if (status === "INPROGRESS") {
		  waypointsRemainingText = "Arriving Next";
		  console.log("Service in progress, setting text to 'Arriving Next'");
		} else if (status === "COMPLETE") {
		  waypointsRemainingText = "Complete";
		  console.log("Service complete, setting text to 'Complete'");
		} else if (pendingCount === 0) {
		  waypointsRemainingText = "Arriving Next";
		  console.log("No pending services, setting text to 'Arriving Next'");
		} else if (generatorWaypointIndex !== firstOccurrenceIndex) {
		  waypointsRemainingText = "Arriving Next";
		  console.log("Not first occurrence, setting text to 'Arriving Next'");
		} else {
		  waypointsRemainingText = "Stops Remaining";
		  console.log("Setting text to 'Stops Remaining', pending count:", pendingCount);
		}
	  }
	  
	  if (generatorData.id === currentGenId) {
		console.log(`Updating UI with: progress=${progressbarValue}, stopsRemaining=${pendingCount}, waypointsRemaining='${waypointsRemainingText}'`);
		
		flushSync(() => {
		  setProgressbar(progressbarValue);
		  setStopsRemaining(pendingCount);
		  setWaypointsRemaining(waypointsRemainingText);
		});
	  } else {
		console.log(`Skipping UI updates - generator changed (current: ${generatorData.id}, expected: ${currentGenId})`);
		return;
	  }
	  
	  let allComplete = true;
	  
	  if (generatorWaypoints.length > 0) {
		for (const waypoint of generatorWaypoints) {
		  if (!waypoint.serviceId) continue;
		  
		  try {
			if (generatorData.id !== currentGenId) {
			  console.log(`Generator changed during waypoint completion check (current: ${generatorData.id}, expected: ${currentGenId})`);
			  return;
			}
			
			const serviceDoc = await getDoc(doc(db, COLLECTIONS.scheduledServices, waypoint.serviceId));
			
			if (serviceDoc.exists()) {
			  const status = serviceDoc.data().status;
			  console.log(`Waypoint service ${waypoint.serviceId} status: ${status}`);
			  
			  if (status !== "COMPLETE") {
				allComplete = false;
				console.log(`Found non-complete service, setting allComplete to false`);
				break;
			  }
			} else {
			  allComplete = false;
			  console.log(`Service document does not exist, setting allComplete to false`);
			  break;
			}
		  } catch (error) {
			console.error(`Error checking waypoint service status:`, error);
			allComplete = false;
			break;
		  }
		}
	  } else {
		allComplete = false;
		console.log(`No waypoints found for this generator, setting allComplete to false`);
	  }
	  
	  generatorCompletionStatusRef.current[currentGenId] = allComplete;
	  console.log(`Setting completion status for generator ${currentGenId} to ${allComplete}`);
	  
	  if (generatorData.id === currentGenId) {
		console.log(`Updating waypoint completion status for generator ${currentGenId} to ${allComplete}`);
		flushSync(() => {
		  setGeneratorWaypointCompleted(allComplete);
		});
	  }
	  
	  if (generatorWaypoints.length === 0 && generatorData.id === currentGenId) {
		console.log(`No waypoints found in route data, calculating service stats separately`);
		await calculateServiceCompletionStats(currentGenId, timestampId, generatorRoutesForDay);
	  }
	  
	} catch (error) {
	  console.error(`Error processing route data for generator ${currentGenId}:`, error);
	}
  }

  async function calculateServiceCompletionStats(generatorId, timestampId, generatorRoutesForDay) {
	if (!generatorId) return { total: 0, completed: 0, allComplete: false };
	
	console.log(`Calculating service stats for generator ${generatorId}`);
	console.log(`Current generator in state: ${generatorData.id}`);
	
	if (generatorData.id !== generatorId) {
	  console.log(`Ignoring service stats for different generator (current: ${generatorData.id}, expected: ${generatorId})`);
	  return { total: 0, completed: 0, allComplete: false };
	}
	
	try {
	  let totalServicesCount = 0;
	  let completedServicesCount = 0;
	  const todayStart = new Date(timestampId);
	  const todayEnd = new Date(todayStart);
	  todayEnd.setHours(23, 59, 59, 999);
	  
	  const todayStartTimestamp = Timestamp.fromDate(todayStart);
	  const todayEndTimestamp = Timestamp.fromDate(todayEnd);
	  const servicesQuery = query(
		collection(db, COLLECTIONS.scheduledServices),
		where("generatorId", "==", generatorId),
		where("date", ">=", todayStartTimestamp),
		where("date", "<=", todayEndTimestamp),
		where("status", "not-in", [SERVICE_STATUS.CANCELLED, SERVICE_STATUS.DELETED])
	  );
	  
	  const servicesSnapshot = await getDocs(servicesQuery);
	  console.log(`Found ${servicesSnapshot.docs.length} services for generator ${generatorId}`);
	  servicesSnapshot.docs.forEach(doc => {
		console.log(`Service ${doc.id}: status=${doc.data().status}, generatorId=${doc.data().generatorId}`);
	  });
	  
	  totalServicesCount = servicesSnapshot.docs.length;
	  
	  completedServicesCount = servicesSnapshot.docs.filter(
		(doc) => doc.data().status === SERVICE_STATUS.COMPLETE
	  ).length;
	  
	  console.log(`Generator ${generatorId}: ${completedServicesCount} of ${totalServicesCount} completed`);
	  
	  if (totalServicesCount === 0 && generatorRoutesForDay && generatorRoutesForDay.length > 0) {
		console.log(`Checking route waypoints for generator ${generatorId}`);
		
		for (const routeId of generatorRoutesForDay) {
		  if (!routeId) continue;
		  
		  if (generatorData.id !== generatorId) {
			console.log(`Generator changed, cancelling stats calculation`);
			return { total: 0, completed: 0, allComplete: false };
		  }
		  
		  try {
			const routeDocRef = doc(db, COLLECTIONS.routes, routeId);
			const dailyRouteRef = doc(routeDocRef, "dailyRoutes", timestampId);
			const dailyRouteData = await getDoc(dailyRouteRef);
			
			if (!dailyRouteData.exists()) continue;
			
			const routeWaypoints = dailyRouteData.data().waypoints || [];
			
			const generatorWaypoints = routeWaypoints.filter((wp) => wp.id === generatorId);
			
			console.log(`Route ${routeId}: Found ${generatorWaypoints.length} waypoints for generator ${generatorId}`);
			
			if (generatorWaypoints.length === 0) continue;
			
			totalServicesCount += generatorWaypoints.length;
			
			for (const waypoint of generatorWaypoints) {
			  if (!waypoint.serviceId) continue;
			   if (generatorData.id !== generatorId) {
				console.log(`Generator changed, cancelling service status check`);
				return { total: 0, completed: 0, allComplete: false };
			  }
			  
			  try {
				const serviceDoc = await getDoc(doc(db, COLLECTIONS.scheduledServices, waypoint.serviceId));
				
				if (serviceDoc.exists()) {
				  console.log(`Waypoint service ${waypoint.serviceId} status: ${serviceDoc.data().status}`);
				  
				  if (serviceDoc.data().status === SERVICE_STATUS.COMPLETE) {
					completedServicesCount++;
				  }
				}
			  } catch (error) {
				console.error(`Error checking service status:`, error);
			  }
			}
		  } catch (error) {
			console.error(`Error getting daily route data:`, error);
		  }
		}
	  }
	  
	  console.log(`Final counts: ${completedServicesCount} of ${totalServicesCount} completed`);
	  
	  const allComplete = totalServicesCount > 0 && completedServicesCount === totalServicesCount;
	  
	  generatorCompletionStatusRef.current[generatorId] = allComplete;
	  
	  if (generatorData.id === generatorId) {
		console.log(`Setting completion status for generator ${generatorId} to ${allComplete}`);
		
		const completionText = `${completedServicesCount} of ${totalServicesCount} services completed`;
		  flushSync(() => {
		  setServiceCompletionText(completionText);
		  setGeneratorWaypointCompleted(allComplete);
		});
	  } else {
		console.log(`Skipping state updates - generator changed`);
	  }
	  
	  return {
		total: totalServicesCount,
		completed: completedServicesCount,
		allComplete,
	  };
	} catch (error) {
	  console.error(`Error calculating service stats:`, error);
	  return { total: 0, completed: 0, allComplete: false };
	}
  }

	useEffect(() => {
		if (childrenData && childrenData.length > 0 && !generatorData.parentAccount) {
			setSelectedValue("");
		} else {
			setSelectedValue(generatorData?.id || "");
		}
	}, [generatorData, childrenData]);

	useEffect(() => {
		if (!generatorData?.id) return;
        const currentDate = new Date();  
		const currentYear = currentDate.getFullYear();  
		const startOfYear = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0, 0)); 
		const endOfYear = new Date(Date.UTC(currentYear, 11, 31, 23, 59, 59, 999));
		const unsubscribe = onSnapshot(
			query(
				collection(db, COLLECTIONS.scheduledServices),
				where("generatorId", "==", generatorData.id),
				where("status", "not-in", ["DELETED", "CANCELLED"]),
				where("date", ">=", Timestamp.fromDate(startOfYear)),
				where("date", "<=", Timestamp.fromDate(endOfYear)),
				orderBy("date", "asc")
			),
			async (snap) => {
				try {
					console.log("Snapshot received:", snap);
					const tempServices = [];
					const jobs = snap.docs.map(async (el) => {
						if (el.exists()) {
							console.log("Document data:", el.data());
							const serviceData = el.data();
							const scheduleRes = await getDoc(doc(db, COLLECTIONS.serviceSchedules, serviceData.serviceScheduleId));
							if (scheduleRes.exists()) {
								serviceData.scheduleData = { ...scheduleRes.data(), id: scheduleRes.id };
							}
							tempServices.push({ ...serviceData, id: el.id });
						}
					});
					await Promise.all(jobs);
					console.log("tempService", tempServices);
					setAllServices(tempServices);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);

		return () => {
			console.log("Unsubscribing from snapshot listener");
			unsubscribe();
		};
	}, [generatorData?.id]);

	useEffect(() => {
		if (!allServices.length) return;
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		allServices.forEach((el) => console.log(el.date.toDate().toISOString()));
		const filteredDate = allServices.filter((el) => el.date.toDate() > today);
		const filteredCompleted = allServices.filter((el) => el.status === SERVICE_STATUS.COMPLETE);
		if (filteredCompleted.length) {
			//setLastCompleted(filteredCompleted[filteredCompleted.length - 1]);
		}
		console.log({ filteredDate });
		filteredDate.forEach((el) => console.log(el.date.toDate().toISOString()));
		if (filteredDate.length) {
			//setNextService(filteredDate[0]);
		}
	}, [allServices]);

	const renderWorkHours = () => {
		if (!generatorData?.workingHours) return "N/A";

		if (nextService?.length > 0 && nextService[0]?.date) {
			const serviceDate = nextService[0].date?.toDate?.();
			if (!serviceDate) return "N/A"; // Ensure serviceDate is valid
			const serviceDateUTC = formatUtcDateString(serviceDate.toISOString());
			const dayOfWeek = daysOfWeek[new Date(serviceDateUTC).getDay()];
			const todaysWorkHours = generatorData.workingHours?.[dayOfWeek];

			if (!todaysWorkHours) return "N/A";

			console.log("todayWH", todaysWorkHours, dayOfWeek);

			return todaysWorkHours.closed ? "Closed" : `${todaysWorkHours.open ?? "N/A"} - ${todaysWorkHours.close ?? "N/A"}`;
		}

		return "N/A";
	};

	function formatUtcDateString(utcDateString) {
		const date = new Date(utcDateString);
		const formatter = new Intl.DateTimeFormat("en-US", {
			weekday: "short",
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			timeZone: "UTC",
		});

		return formatter.format(date);
	}

	if (authLoading || !user || !generatorData) {
		return <Loader />;
	}
	const isParentWithChildren = !data?.parentAccount && childrenData && childrenData.length > 0;

	return (
		<div className="bg-bgDefaultColor px-32 py-5 flex flex-col gap-5 ">
			<div className="grid grid-cols-3 gap-5 items-center ">
				<ParentDropdown
					//  isParent={isparent}
					generatorData={generatorData}
					parentGenerator={parentGenerator}
					// handleChildChange={handleChildChange}
				/>

				<ChildDropdown
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
					isParentWithChildren={isParentWithChildren}
					isparent={!parentGenerator?.parentAccount}
					generatorData={generatorData}
					parentGenerator={parentGenerator}
					childrenData={childrenData}
					sameParentGenerators={sameParentGenerators}
					getGeneratorById={getGeneratorById}
					setGeneratorData={setGeneratorData}
					setGeneratorWaypointCompleted={setGeneratorWaypointCompleted}
					isLoadingTracking={isLoadingTracking}
				/>

				{/* <button className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full py-3 px-4 shadow-lg transition-all duration-300 flex justify-between items-center space-x-2">
					<span>Add Service Location</span>
					<span>
						<Plus />
					</span>
				</button> */}
				<div className="fixed top-4 right-8 z-50">
                 <GeneratorUserAccBtn />
                </div>
			</div>

			<div className="bg-white w-full rounded-xl flex flex-col items-start justify-evenly p-5 shadow-sm">
				<p className="font-semibold">{generatorData.generatorName}</p>
				<p className="font-semibold">{generatorData.octoNumber}</p>
				<p className="font-semibold">{renderAddress(generatorData.serviceAddress)}</p>
			</div>

			<div className="flex justify-between gap-5">
				<div className="bg-white p-10 flex items-center gap-5 flex-col w-full text-center rounded-xl shadow-md">
					<h1 className="font-bold text-3xl">Service</h1>

					<div className="">
						<h2 className="font-semibold text-lg">Next Service</h2>
						<h1 className="text-[#4CD964] text-xl">
						
{nextService?.length > 0 && nextService[0]?.date
  ? formatUtcDateString(nextService[0].date.toDate())
  : "N/A"}
						</h1>
					</div>

					<div className="grid gap-2 w-full max-w-80">
						<button
							className="w-full  bg-thatblue hover:bg-blue-500 rounded-full text-white p-3"
							onClick={() => {
								navigate("/generator-dashboard/service-reports");
							}}
						>
							Manage Service History
						</button>
						<button
							className="w-full  bg-white hover:bg-[#DEDEDE] rounded-full text-[#666666] border border-[#DEDEDE] p-3"
							onClick={() => {
								navigate(`/generator-dashboard/service-calendar?child=${generatorData?.id}`);
							}}
						>
							Manage Future Services
						</button>
					</div>

					<div className="w-full bg-thatblue text-white  rounded-xl p-5 grid  gap-2">
						<h1 className="font-semibold text-center">Service Summary</h1>
						<hr className="" />

						<div className="grid gap-1">
							<div className="grid grid-cols-2">
								<span className="text-left">Service Address:</span>
								<span className="text-left">{renderAddress(generatorData.serviceAddress)}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Next Service:</span>

								<span className="text-left">
								
{nextService?.length > 0 && nextService[0]?.date
  ? formatUtcDateString(nextService[0].date.toDate())
  : "N/A"}
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Office Hours:</span>
								<span className="text-left">{renderWorkHours() ? renderWorkHours() : "N/A"}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Service Type:</span>
								<span className="text-left">{value ? value : "N/A"}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Last Completed Service:</span>
								<span className="text-left">
									{lastCompleted?.[0]?.completedAt
										? (lastCompleted[0].completedAt.toDate?.().toLocaleDateString() ?? new Date())
										: "N/A"}
								</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Service Frequency:</span>
								<span className="text-left">
									{Array.isArray(serviceFrequency) && serviceFrequency.length > 0
										? serviceFrequency
												.map((service) => allServiceFrequencies?.find((el) => el.value === service)?.label ?? "N/A")
												.join(", ")
										: "N/A"}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white p-10 flex items-center gap-5 flex-col w-full text-center rounded-xl shadow-md">
					<h1 className="font-bold text-3xl">Billing</h1>

					<div className="">
						<h2 className="font-semibold text-lg">Open balance</h2>
						<h1 className="text-[#4CD964] text-xl">{dummyBillingData.openBalance}</h1>
					</div>

					<div className="grid gap-2 w-full max-w-80">
						<button
							className="w-full p-3 bg-thatblue hover:bg-blue-500 rounded-full text-white"
							onClick={() => {
								generatorData.id == data.id
									? navigate("/generator-dashboard/invoices-and-payments")
									: navigate(`/generator-dashboard/invoices-and-payments?child=${generatorData.id}`);
							}}
						>
							Manage Invoices
						</button>
						<button
							className="w-full p-3 bg-white hover:bg-[#DEDEDE] rounded-full text-[#666666] border border-[#DEDEDE]"
							onClick={() => {
								generatorData.id == data.id
									? navigate("/generator-dashboard/saved-payment-methods")
									: navigate(`/generator-dashboard/saved-payment-methods?child=${generatorData.id}`);
							}}
						>
							Manage Payment Options
						</button>
					</div>

					<div className="w-full bg-thatblue text-white  rounded-xl p-5 grid  gap-2">
						<h1 className="font-semibold text-center">Billing Summary</h1>

						<hr />

						<div className="grid gap-1">
							<div className="grid grid-cols-2">
								<span className="text-left">Billing Address:</span>
								<span className="text-left">{renderAddress(generatorData.billingAddress)}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Last Payment:</span>
								<span className="text-left">{dummyBillingData.lastPayment}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Overdue Balance:</span>
								<span className="text-left">{dummyBillingData.overdueBalance}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Unpaid Invoices:</span>
								<span className="text-left">{dummyBillingData.unpaidInvoices}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Billing Frequency:</span>
								<span className="text-left">{dummyBillingData.billingFrequency}</span>
							</div>
							<div className="grid grid-cols-2">
								<span className="text-left">Payment Type</span>
								<span className="text-left">{dummyBillingData.paymentType}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full grid grid-cols-2 gap-5 ">
				<div className="bg-white rounded-cardRadii shadow-md p-5 grid grid-cols-1 gap-2">
					<h2 className="text-lg font-bold text-left">Upcoming Service Schedules</h2>
					<div className="grid grid-cols-3 gap-2">
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 border-red-500 bg-white  rounded-none border-[3px] mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Today's Date</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 bg-red-500 rounded-full mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Medical Waste</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 bg-green-500 rounded-full mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Paper Shredding Schedule</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="flex w-6 h-6 col-span-2">
								<div className="w-3 h-6 bg-green-500 rounded-r-full rotate-180"></div>
								<div className="w-3 h-6 bg-red-500 rounded-l-full rotate-180"></div>
							</div>
							<span className="text-gray-700 col-span-6">Medical Waste & Paper Shredding</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 bg-yellow-500 rounded-full mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Holiday (No services on this day)</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 bg-gray-500 rounded-full mr-2 col-span-2" />
							<span className="text-gray-700 col-span-6">Completed (Past) Service dates</span>
						</div>
						<div className="grid grid-cols-8 items-center">
							<div className="w-6 h-6 col-span-2 rounded-full bg-white border border-gray-500 mr-2" />
							<span className="text-gray-700 col-span-6">Unlogged (Past) Service dates</span>
						</div>
					</div>
				</div>
				<div className="bg-white rounded-xl shadow-md flex flex-col gap-5 relative p-5 justify-center h-full">
				{console.log("all datats",serviceCompletionText,completed,generatorWaypointCompleted,waypointsRemaining,routeStarted,progressBar,stopsRemaining)}
				{console.log("date data",
  todayService?.[0]?.date ? formatUtcDateString(todayService[0].date.toDate()) : null,
  nextService?.[0]?.date ? formatUtcDateString(nextService[0].date.toDate()) : "not defined"
)}
				
					<div className="absolute top-0 right-0 text-sm">
						<p className="p-5">{serviceCompletionText}</p>
					</div>
					<div>
						<h2 className="text-lg font-bold text-left">Live Track</h2>
					</div>
					<div className="text-lg font-bold h-1/2 mt-auto flex items-end">
						{
nextService?.[0]?.date &&todayService?.[0]?.date&&						
formatUtcDateString(todayService[0]?.date.toDate()) === formatUtcDateString(nextService[0]?.date.toDate())
							? "Tracking "
							: "Check Back On: "}
						(
						{nextService?.[0]?.date && nextService[0].date.toDate() instanceof Date
							? formatUtcDateString(nextService[0].date.toDate())
							: "N/A"}
						)
					</div>

					<div className="h-1/2">
						<div className="relative w-full bg-gray-200 rounded-full h-3 ">
							<div
								className={`${!completed ? "bg-blue-500" : "bg-green-500"} h-3 rounded-full`}
								style={{
									width: generatorWaypointCompleted
										? "100%"
										: waypointsRemaining === "Arriving Next"
										? "90%"
										: routeStarted
										? `${progressBar}%`
										: "0%",
								}}
							/>
							<div className="flex justify-between items-center absolute w-full top-4 -translate-y-1/2">
								{/* Route Started Icon */}
								<div className="flex flex-col ">
									<CheckCircleIcon
										className={`w-7 h-7 ${
											routeStarted || generatorWaypointCompleted
												? "bg-blue-500 text-white"
												: "text-gray-300 bg-gray-200"
										} rounded-full `}
									/>
									<span
										className={`${routeStarted || generatorWaypointCompleted ? "text-blue-500" : "text-gray-700"}  `}
									>
										Route Started
									</span>
								</div>
								{/* Waypoints Remaining Circle */}
								<div className="flex flex-col items-center">
									<div
										className={`w-7 h-7 ${
											routeStarted || generatorWaypointCompleted ? "bg-blue-500" : "bg-gray-200"
										} rounded-full flex items-center justify-center text-white`}
									>
										{routeStarted && waypointsRemaining !== "Arriving Next" && !generatorWaypointCompleted && (
											<p>{stopsRemaining}</p>
										)}
									</div>
									<span
										className={`${
											routeStarted || waypointsRemaining == "Arriving Next" ? "text-blue-500" : "text-gray-700"
										}`}
									>
										{waypointsRemaining}
									</span>
								</div>
								{/* Service Completed Icon */}
								<div className="flex flex-col items-end ">
									<div
										className={`w-7 h-7 ${generatorWaypointCompleted ? "bg-blue-500" : "bg-gray-200"} rounded-full`}
									></div>
									<span className={`${generatorWaypointCompleted ? "text-blue-500" : "text-gray-700"}`}>
										Service Completed
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full grid grid-cols-2 gap-5">
				<div className="bg-white p-3 rounded-cardRadii shadow-md flex-1 flex flex-col">
					<h2 className="font-bold text-lg ml-1 pb-2">Service Calendar</h2>
					<CalendarWithTimeSlots allServices={allServices} wholeCalender={true} />
					<p
						className="text-thatblue text-md py-2 text-end hover:cursor-pointer"
						onClick={() => {
							navigate(`/generator-dashboard/service-calendar?child=${generatorData.id}`);
						}}
					>
						View Service Calendar
					</p>
				</div>

				<div className="shadow-sm w-full  bg-white rounded-xl">
					<AzureMapsProvider>
						{generatorData?.serviceAddCoordinates?.lat &&
						generatorData?.serviceAddCoordinates?.lng &&
						randomCoordinates?.lat &&
						randomCoordinates?.lng ? (
							<CustomAzureGeneratorDashboardMap generatorData={generatorData} randomCoordinates={randomCoordinates} />
						) : (
							<p>Generator Data not flow</p>
						)}
					</AzureMapsProvider>
				</div>
			</div>

			<GeneratorDetailsForm
				billingAddress={generatorData.billingAddress}
				serviceAddress={generatorData.serviceAddress}
				name={generatorData.generatorName}
				changeRequestHandler={() => {
					contactFormRef.current.scrollIntoView({ behavior: "smooth" });
				}}
			/>
			<GeneratorOfficeHours workingHours={generatorData.workingHours} />
			<div ref={contactFormRef}>
				<ContactUsForm />
			</div>
		</div>
	);
};

export default GeneratorDashboard;

const dummyBillingData = {
	openBalance: "N/A",
	billingAddress: "N/A",
	lastPayment: "N/A",
	overdueBalance: "N/A",
	unpaidInvoices: "N/A",
	billingFrequency: "N/A",
	paymentType: "N/A",
};