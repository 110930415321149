import React, { useState, useEffect } from "react";
import { BsInfoCircle } from "react-icons/bs";

const Input = ({
	id,
	label,
	placeholder,
	type = "text",
	value,
	onChange,
	onBlur,
	margin = "mt-4",
	style = null,
	isLoading = false,
	isDisabled = false,
	isInfo = "",
	isRequired = false,
	extra = "",
}) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [isValidUrl, setIsValidUrl] = useState(true);
	const [isCtrlPressed, setIsCtrlPressed] = useState(false);

	// Improved URL validation function with strict TLD requirement
	const validateURL = (input) => {
		if (type !== "url" || !input) return true;

		// Remove protocol if present for consistent validation
		let processedInput = input.toLowerCase().trim();
		processedInput = processedInput.replace(/^(https?:\/\/)/, "");

		// List of common TLDs (you can expand this list as needed)
		const commonTLDs = [
			"com",
			"org",
			"net",
			"edu",
			"gov",
			"mil",
			"int",
			"io",
			"co",
			"ai",
			"app",
			"dev",
			"tech",
			"info",
			"biz",
			"name",
			"pro",
			"museum",
			"travel",
			"aero",
			"cat",
			"coop",
			"jobs",
			"mobi",
			"tel",
			"xxx",
			"ac",
			"ad",
			"ae",
			"af",
			"ag",
			"ai",
			"al",
			"am",
			"ao",
			"aq",
			"ar",
			"as",
			"at",
			"au",
			"aw",
			"ax",
			"az",
			"ba",
			"uk",
			"us",
			"eu",
			"de",
			"fr",
			"jp",
			"cn",
			"in",
			"br",
			"ru",
		];

		// Check if the domain ends with a valid TLD
		const hasTLD = commonTLDs.some((tld) => processedInput.includes(`.${tld}`));
		if (!hasTLD) return false;

		// Regular expression for domain validation
		const domainSegment = "[a-zA-Z0-9-]+";
		const domainPattern = new RegExp(`^(www\\.)?${domainSegment}(\\.${domainSegment})+(/.*)?$`);

		return domainPattern.test(processedInput);
	};

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === "Control") {
				setIsCtrlPressed(true);
			}
		};

		const handleKeyUp = (e) => {
			if (e.key === "Control") {
				setIsCtrlPressed(false);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		window.addEventListener("keyup", handleKeyUp);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	const handleInputChange = (e) => {
		const input = e.target.value;
		if (type === "url") {
			setIsValidUrl(input === "" || validateURL(input));
		}
		onChange(e);
	};

	const handleClick = () => {
		if (type === "url" && isCtrlPressed && isValidUrl && value) {
			const formattedValue = value.startsWith("http://") || value.startsWith("https://") ? value : `https://${value}`;
			window.open(formattedValue, "_blank");
		}
	};

	const handleBlur = (e) => {
		if (onBlur) {
			onBlur(e);
		}
	};

	return (
		<div className={`relative flex ${style ?? "items-center justify-between"} ${margin}`}>
			{label && (
				<label
					htmlFor={id}
					className={`${
						style ? "w-full" : label ? "w-1/3" : "w-full"
					} text-inputLabel flex items-center gap-1 font-normal pr-3`}
				>
					{`${label}`}
					{isRequired ? "*" : ""}
					{isLoading ? (
						<svg className={`w-4 h-4 text-cardTextGray`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path
								className="fill-current"
								d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
								opacity=".25"
							/>
							<path
								className="fill-current animate-spin origin-center"
								d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
							/>
						</svg>
					) : (
						""
					)}
					{isInfo?.length > 0 && (
						<div
							className="relative"
							onMouseEnter={() => setShowTooltip(true)}
							onMouseLeave={() => setShowTooltip(false)}
						>
							<BsInfoCircle size={16} color="#666666" />
							{showTooltip && (
								<div className="absolute top-full left-0 min-w-72 mt-1 bg-gray-800 text-white text-sm rounded px-2 py-1 z-10">
									{isInfo}
								</div>
							)}
						</div>
					)}
				</label>
			)}
			<div className={`relative ${style ? "w-full" : label ? "w-2/3" : "w-full"}`}>
				<input
					id={id}
					type={type === "url" ? "text" : type}
					value={value}
					onChange={handleInputChange}
					onBlur={handleBlur} 
					onClick={handleClick}
					placeholder={placeholder}
					disabled={isDisabled}
					className={`
            ${extra?.length > 0 ? "" : "disabled:bg-white"}
            ${
							type === "url" && value && isValidUrl && isCtrlPressed
								? "hover:cursor-pointer hover:underline hover:text-blueText"
								: "hover:cursor-text"
						}
            w-full text-cardTextGray bg-inputBg border-none rounded-full py-2 h-9 px-4 
            focus:outline-none focus:ring-1 leading-tight focus:ring-dashInActiveBtnText
            [&:-webkit-autofill]:bg-inputBg!
            [&:-webkit-autofill:hover]:bg-inputBg!
            [&:-webkit-autofill:focus]:bg-inputBg!
            [&:-webkit-autofill:active]:bg-inputBg!
            [&.auto-filled]:bg-inputBg!
            [-webkit-text-fill-color:inherit]!
          `}
				/>
				{type === "url" && !isValidUrl && (
					<div className="absolute left-0 -bottom-6 text-red-500 text-sm">Please enter a valid URL.</div>
				)}
			</div>
		</div>
	);
};

export default Input;