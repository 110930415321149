import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, signOutUser } from "../../config/firebase";
import Loader from "../../components/UI/loaders/Loader";
import AdminAuthForm from "./AdminAuthForm";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import UserAccBtn from "../../components/userAccBtn/UserAccBtn";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Notifications from "../octo-market/components/Notifications";
import {
	markAdminNotificationAsRead,
	setupAdminNotificationListener,
	clearNotificationListener,
} from "../../store/sliceReducers/adminNotificationSlice";
import { toast } from "react-toastify";
import { TransporterUserProvider } from "../../context/TransporterUserContext";

const OctoAdminLanding = () => {
	const [user, loading] = useAuthState(auth);
	const [authState, setAuthState] = useState({
		isVerifying: true,
		isAuthorized: false,
		initialCheckDone: false,
	});
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		let isMounted = true;

		const verifyAdminRole = async () => {
			if (!user) {
				if (isMounted) {
					setAuthState({
						isVerifying: false,
						isAuthorized: false,
						initialCheckDone: true,
					});
				}
				return;
			}

			try {
				const docSnap = await getDoc(doc(db, "admins", user.uid));

				if (!docSnap.exists() || docSnap.data().role !== "octoMarketAdmin") {
					toast.error("Invalid admin credential");
					await signOutUser();
					if (isMounted) {
						setAuthState({
							isVerifying: false,
							isAuthorized: false,
							initialCheckDone: true,
						});
					}
					return;
				}

				if (isMounted) {
					setAuthState({
						isVerifying: false,
						isAuthorized: true,
						initialCheckDone: true,
					});
				}
			} catch (error) {
				console.error("Error verifying admin role:", error);
				toast.error("Invalid admin credential");
				await signOutUser();
				if (isMounted) {
					setAuthState({
						isVerifying: false,
						isAuthorized: false,
						initialCheckDone: true,
					});
				}
			}
		};

		// Reset state when user changes
		if (!loading) {
			setAuthState((prev) => ({ ...prev, isVerifying: true }));
			verifyAdminRole();
		}

		return () => {
			isMounted = false;
		};
	}, [user, loading]);

	// Show loader until both Firebase auth and our role verification are complete
	if (loading || !authState.initialCheckDone || authState.isVerifying) {
		return <Loader />;
	}

	// Show login form if not authenticated or not authorized
	if (!user || !authState.isAuthorized) {
		return <AdminAuthForm />;
	}

	// Determine active tab based on current location
	let locationActiveTab = "";
	if (location.pathname?.endsWith("users") || location.pathname?.includes("users")) {
		locationActiveTab = "users";
	}
	if (location.pathname?.endsWith("dashboard") || location.pathname?.includes("dashboard")) {
		locationActiveTab = "dashboard";
	}

	// Redirect to dashboard if on root admin path
	if (location.pathname.endsWith("market-admin") || location.pathname.endsWith("market-admin/")) {
		navigate("/market-admin/dashboard");
		return <Loader />;
	}

	return (
		<TransporterUserProvider>
			<div className="h-full w-full">
				<div className="w-full">
					<div className="w-80 fixed h-screen bg-[#F6F8FA]">
						<Sidebar activeTab={locationActiveTab} />
					</div>
					<div className="flex-1 ml-80 px-4 flex-col gap-2">
						<div className="border-b border-b-cardTextGray border-opacity-20">
							<Header activeTab={locationActiveTab} user={user} />
						</div>
						<div className="">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</TransporterUserProvider>
	);
};
const Header = ({ activeTab, user }) => {
	return (
		<div className="flex-1 p-4 py-6 border-cardTextGray border-opacity-20 flex items-center justify-between">
			<div className="flex flex-col items-start">
				{activeTab === "dashboard" ? (
					<>
						<h2 className="font-bold text-3xl text-[#2E2C34]">Dashboard</h2>
						<p className="text-cardTextGray">Get an overview of reports and analytics here</p>
					</>
				) : (
					<h2 className="font-bold text-3xl text-[#2E2C34]">Users</h2>
				)}
			</div>
			<nav className="flex items-center gap-8">
				<AdminNotifications />
				<UserAccBtn />
			</nav>
		</div>
	);
};

export default OctoAdminLanding;

const AdminNotifications = () => {
	const dispatch = useDispatch();
	const [user, loading] = useAuthState(auth);
	const {
		notifications,
		unreadCount,
		loading: notificationLoading,
		error,
	} = useSelector((state) => state.adminNotification);
	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
	const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
	const navigate = useNavigate();

	// Close notifications when clicking outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (isNotificationsOpen && !event.target.closest(".notifications-container")) {
				setIsNotificationsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, [isNotificationsOpen]);

	// Set up listener when component mounts
	useEffect(() => {
		if (user) {
			const listenerUnsubscribe = dispatch(setupAdminNotificationListener(user.uid));

			// Cleanup listener when component unmounts
			return () => {
				dispatch(clearNotificationListener());
			};
		}
	}, [user, dispatch]);

	const onClickHandler = async (notification) => {
		setIsNotificationsOpen(false);
		if (!notification.read) {
			try {
				const adminRef = doc(db, "admins", user.uid);
				let adminData = await getDoc(adminRef);
				const notificationToUpdate = adminData.data().notifications.find((n) => n.id === notification.id);
				let filteredNotifications = adminData.data().notifications.filter((n) => n.id !== notification.id);

				if (!notificationToUpdate) {
					throw new Error("Notification not found");
				}

				const updatedNotification = {
					...notificationToUpdate,
					read: true,
				};

				await updateDoc(adminRef, {
					notifications: [...filteredNotifications, updatedNotification],
				});
			} catch (error) {
				console.log({ error });
			}
		}
		navigate(`/market-admin/users/${notification.senderData.uid}`);
	};

	const toggleNotifications = (e) => {
		e.stopPropagation();
		setIsNotificationsOpen(!isNotificationsOpen);
	};

	useEffect(() => {
		let doesExist = notifications.find((notification) => notification.read == false);
		setHasUnreadNotification(doesExist);
	}, [notifications]);

	return <Notifications notifications={notifications} onNotificationClick={onClickHandler} onClose={() => {}} />;
};
