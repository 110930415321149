import CalendarWithTimeSlots from "./CalendarComponent";
import TimeSlotRepeater from "./TimeSlotRepeater";

const OpenCalendar = ({ routeColumns, routesMap, currentDate, setCurrentDate, loading }) => {
	return (
		<div className="bg-white rounded-cardRadii shadow-sm h-full p-6 pb-0 w-full">
			<h2 className="text-lg p-1 h-10 font-medium">Routes By Date</h2>
			<div className="w-full grid gap-4 h-[810px]">
				<div className="h-[360px]">
					<CalendarWithTimeSlots selectedDate={currentDate} setSelectedDate={setCurrentDate} />
				</div>
				<div className="h-[450px] overflow-scroll">
					<TimeSlotRepeater
						routeColumns={routeColumns}
						routesMap={routesMap}
						loading={loading}
						selectedDate={currentDate}
					/>
				</div>
			</div>
		</div>
	);
};

export default OpenCalendar;
