import PropTypes from "prop-types";
import { AuthenticationType, AzureMapsContext } from "react-azure-maps";
import { AzureMap } from "react-azure-maps";
import { FaPlus, FaMinus } from "react-icons/fa";
import { data } from "azure-maps-control";
import { useContext, useEffect } from "react";
import CustomHtmMarker from "./CustomHtmMarker";
import { limitScrollWheelZoom } from "../../utils/helpers";

const authOptions = {
	authType: AuthenticationType.subscriptionKey,
	subscriptionKey: import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
};
const CustomAzureMap = ({ markers = [] }) => {
	const { isMapReady, mapRef, removeMapRef } = useContext(AzureMapsContext);
	useEffect(() => {
		() => {
			if (mapRef) {
				mapRef.dispose();
				removeMapRef();
			}
		};
	}, []);

	useEffect(() => {
		if (isMapReady && mapRef) {
			const markerPositions = [];
			markers.forEach(({ coordinates, randomCoordinates }) => {
				if (coordinates?.lat && coordinates?.lng) {
					markerPositions.push(new data.Position(coordinates.lng, coordinates.lat));
				}
				if (randomCoordinates?.lat && randomCoordinates?.lng) {
					markerPositions.push(new data.Position(randomCoordinates.lng, randomCoordinates.lat));
				}
			});
			if (markerPositions.length === 0) return;
			console.log({ markerPositions });
			const box = data.BoundingBox.fromPositions(markerPositions);
			const center = data.BoundingBox.getCenter(box);
			mapRef.setCamera({ center: center, zoom: 12, duration: 200, type: "ease" });
			// mapRef.setCamera({ bounds: box, padding: 100, duration: 200, type: "ease" });
			limitScrollWheelZoom(mapRef);
		}
	}, [mapRef, markers, isMapReady]);

	const zoomIn = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom + 1 });
		}
	};

	const zoomOut = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom - 1 });
		}
	};
	return (
		<div className="w-full h-full rounded-xl p-2 z-50 bg-white">
			<AzureMap options={{ authOptions: authOptions, view: "Auto" }}>
				{markers.map(({ coordinates, randomCoordinates, draggable, pin }, index) => (
					<CustomHtmMarker
						key={index}
						coordinates={coordinates}
						randomCoordinates={randomCoordinates}
						draggable={draggable ?? false}
						pin={pin}
					/>
				))}
				<div className="absolute bottom-5 right-5 flex flex-col gap-2 z-10">
					<button
						onClick={zoomIn}
						className="w-10 h-10 flex items-center justify-center 
                       bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
                        font-bold rounded shadow-md"
					>
						<FaPlus />
					</button>
					<button
						onClick={zoomOut}
						className="w-10 h-10 flex items-center justify-center 
                       bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
                       font-bold rounded shadow-md"
					>
						<FaMinus />
					</button>
				</div>
			</AzureMap>
		</div>
	);
};

CustomAzureMap.propTypes = {
	center: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	markers: PropTypes.array,
	zoom: PropTypes.number,
};

export default CustomAzureMap;
