import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Reporting = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname == "/admin/reports") navigate("/admin/reports/service");
		// else navigate(location.pathname);
	}, [location.pathname]);

	return (
		<div className="h-full p-6">
			<Outlet />
		</div>
	);
};

export default Reporting;
