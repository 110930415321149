import React from "react";
import { Controller } from "react-hook-form";
import Input from "../../../../../../../../../components/UI/Input";
import Checkbox from "../../../../../../../../../components/UI/Checkbox";
import PhoneInput from "../../../../../../../../../components/UI/PhoneInput";
import { USStates } from "../../../../../../../../../utils/constants";
import SearchableDropdown from "../../../../../../../../../components/UI/dropdowns/SearchableDropdown";

const AddressForm = ({
	control,
	handleCopyAddress,
	errors,
	trigger,
	billingAddressLabel = "Generator Billing Address",
	serviceAddressLabel = "Service Address",
}) => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	const renderAddressFields = (addressType) => {
		return ["street", "suite", "city", "state", "zipCode", "email", "phone"].map((field) => (
			<Controller
				key={field}
				name={`${addressType}Address.${field}`}
				control={control}
				rules={{
					required: field !== "suite" ? "This field is required" : false,
					validate: (value) => {
						if (field === "email") {
							return emailRegex.test(value) || "Invalid email format";
						}
						if (field === "phone") {
							const phoneNumberDigit = value.replace(/\D/g, "");
							if (phoneNumberDigit && phoneNumberDigit.length !== 10) {
								return "Phone number must be 10 digits";
							}
						}
						return true;
					},
				}}
				render={({ field: { onChange, value } }) => (
					<>
						{field === "phone" ? (
							<>
								<PhoneInput
									id={`${addressType}Address-${field}`}
									label={field.charAt(0).toUpperCase() + field.slice(1)}
									onChange={onChange}
									value={value}
									IsRequired={true}
								/>
								{errors?.[`${addressType}Address-${field}`] && (
									<p className="text-red-500 text-sm mt-1">{errors?.[`${addressType}Address-${field}`]?.message}</p>
								)}
							</>
						) : field === "state" ? (
							<SearchableDropdown
								id={`${addressType}Address-${field}`}
								label="State"
								options={USStates.map((item) => ({ label: item.value, value: item.value }))}
								value={value}
								onChange={onChange}
								isRequired={true}
							/>
						) : (
							<Input
								id={`${addressType}Address-${field}`}
								label={field === "zipCode" ? "ZIP Code" : field.charAt(0).toUpperCase() + field.slice(1)}
								value={value || ""}
								onChange={onChange}
								error={errors[`${addressType}Address`]?.[field]}
								isRequired={field !== "suite" ? true : false}
							/>
						)}
						{errors[`${addressType}Address`]?.[field] && (
							<p className="text-red-500 text-sm mt-1">{errors[`${addressType}Address`][field].message}</p>
						)}
					</>
				)}
			/>
		));
	};

	return (
		<div className="w-full flex justify-between gap-8">
			<div className="w-full md:w-1/2">
				<div className="py-2 text-lg flex justify-between gap-2 items-center border-b mb-6 border-[#CCCCCC]">
					<h6 className="font-medium truncate">{serviceAddressLabel}</h6>
					<Controller
						name="isBillingCopyChecked"
						defaultValue={false}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Checkbox
								styles="gap-2"
								label="Copy to Billing Information"
								type="secondary"
								isChecked={value}
								setIsChecked={(isChecked) => {
									handleCopyAddress(isChecked, "billing");
									onChange(isChecked);
									if (isChecked) {
										trigger("billingAddress");
									}
								}}
							/>
						)}
					/>
				</div>
				{renderAddressFields("service")}
			</div>
			<div className="w-full md:w-1/2">
				<div className="py-2 text-lg flex justify-between gap-2 items-center border-b mb-6 border-[#CCCCCC]">
					<h6 className="font-medium truncate">{billingAddressLabel}</h6>
				</div>
				{renderAddressFields("billing")}
			</div>
		</div>
	);
};

export default AddressForm;
