import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLLECTIONS, db, getManifestUrl } from "../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../utils/constants";
import {
	dateFormatter,
	formattedDateTime,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../utils/helpers";
import Loader from "../../../components/UI/loaders/Loader";
import CustomDatePicker from "../../../components/UI/CustomDatePicker";
import ReportCheckbox from "../../admin/generator-management/components/reports/ReportCheckBox";
import DropdownReport from "../../admin/generator-management/components/reports/ReportDropDown";
import Button from "../../../components/UI/Button";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";
import MultiselectDropdown from "../../../components/UI/dropdowns/MultiselectDropdown";
import { FiSearch } from "react-icons/fi";
import { log } from "handlebars";
import { useGeneratorData } from "../dashboard/components/GeneratorDataContext";

const sortByOptions = [
	{ label: "Any", value: "any" },
	{ label: "By Date (asc)", value: "date_asc" },
	{ label: "By Date (desc)", value: "date_desc" },
	{ label: "By Generator(A-Z)", value: "generator_asc" },
	{ label: "By Generator(Z-A)", value: "generator_desc" },
	{ label: "By Route(A-Z)", value: "route" },
	{ label: "By Route(Z-A)", value: "route_desc" },
];

const handleCheckboxChange = (data, setData, index, field) => {
	const newData = [...data];
	newData[index][field] = !newData[index][field];
	setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
	const newData = [...data];
	newData[index].paidValue = value;
	setData(newData);
};

const GeneratorServiceReporting = () => {
	const { user, authLoading, generatorData: data, allServices: genService } = useGeneratorUser();
	const navigate = useNavigate();
	const { generatorData, setGeneratorData } = useGeneratorData();
	const [isLoading, setIsLoading] = useState(false);
	const [completeServices, setCompleteServices] = useState([]);
	const [parentAccount, setParentAccount] = useState(null);
	const [parentGenerator, setParentGenerator] = useState(null);
	const [sameParentGenerators, setSameParentGenerators] = useState(null);
	const [childrenData, setChildrenData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [from, setFrom] = useState(() => new Date());
	const [to, setTo] = useState(new Date());
	const dropdownOptions = ["Edit", "Delete", "Share"];
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [selectedSort, setSelectedSort] = useState("any");
	const [allTreatments, setAllTreatments] = useState([{ label: "Loading", value: null }]);
	const [totalWeight, setTotalWeight] = useState(0);
	const [selectedReportTypes, setSelectedReportTypes] = useState([
		"all",
		"Product_Delivery",
		"Disposal_Documents",
		"Delivery_Receipts",
	]);
	const [selectedServiceTypes, setSelectedServiceTypes] = useState(["all", "Medical_Waste", "Paper_Shredding"]);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [allServices, setAllServices] = useState([]);
	const [childGeneratorIds, setChildGeneratorIds] = useState([]);

	useEffect(() => {
		if (generatorData || user) {
			viewButtonHandler();
		}
	}, [generatorData, to, from, user]);

	useEffect(() => {
		if (!data?.id) return;
		setGeneratorData(data);
		viewButtonHandler();
	}, [data?.id]);

	useEffect(() => {
		viewButtonHandler();
	}, [selectedLocations]);

	useEffect(() => {
		if (childrenData.length > 0) {
			const ids = childrenData.map((child) => child.id);
			setChildGeneratorIds(ids);
			setSelectedLocations(["all", generatorData?.id, ...ids]); // Include logged-in generator ID
		} else {
			setSelectedLocations([generatorData?.id]);
		}
	}, [childrenData, generatorData]);

	useEffect(() => {
		if (!generatorData?.id) return;
		const q = query(collection(db, COLLECTIONS.serviceSchedules), where("generatorId", "==", generatorData.id));
		const unsubscribe = onSnapshot(q, async (snap) => {
			try {
				const tempSchedules = await Promise.all(
					snap.docs.map(async (el) => {
						if (!el.exists()) return null;
						const data = { ...el.data(), id: el.id };
						if (Array.isArray(data.serviceType)) {
							data.serviceType = data.serviceType[0];
						}
						if (data.isDeleted) return null;
						let createdAt = null;
						if (data.createdAt?.seconds || data.createdAt?.nanoseconds) {
							createdAt = new Timestamp(data.createdAt.seconds, data.createdAt.nanoseconds).toDate();
						} else if (data.createdAt?.toDate) {
							createdAt = data.createdAt.toDate();
						} else {
							createdAt = new Date();
						}
						return { ...data, createdAt };
					})
				);
				const filteredSchedules = tempSchedules.filter(Boolean);
				filteredSchedules.sort((a, b) => a.createdAt - b.createdAt);

				const formattedServiceTypes = [...new Set(filteredSchedules.map((item) => item.serviceType))]
					.map((service) =>
						service
							.toLowerCase()
							.replace(/_/g, " ")
							.replace(/\b\w/g, (char) => char.toUpperCase())
					)
					.join(", ");

				console.log(formattedServiceTypes);
				console.log("Final schedules:", filteredSchedules);
			} catch (error) {
				console.error("Error processing snapshot:", error);
			}
		});
		return () => {
			console.log("Unsubscribing from snapshot listener");
			unsubscribe();
		};
	}, [generatorData?.id]);

	useEffect(() => {
		if (!generatorData?.id) return;

		const unsubscribe = onSnapshot(
			query(collection(db, COLLECTIONS.generators), where("parentAccount", "==", data?.id)),
			async (snap) => {
				try {
					const data = snap.docs.map((doc) => doc.data());
					setChildrenData(data);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);
		const unsubscribe2 = onSnapshot(
			query(collection(db, COLLECTIONS.generators), where("parentAccount", "==", generatorData)),
			async (snap) => {
				try {
					const data = snap.docs.map((doc) => doc.data());
					setSameParentGenerators(data);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);

		const unsubscribe3 = onSnapshot(
			query(collection(db, COLLECTIONS.generators), where("id", "==", generatorData?.parentAccount)),
			async (snap) => {
				try {
					const data = snap.docs.map((doc) => doc.data());
					setParentGenerator(data);
				} catch (error) {
					console.error("Error processing snapshot:", error);
				}
			}
		);
		return () => {
			console.log("Unsubscribing from snapshot listener");
			unsubscribe();
			unsubscribe2();
			unsubscribe3();
		};
	}, [generatorData?.id, generatorData?.parentAccount]);

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "--"; // Handle missing data gracefully
		}

		return serviceTypes.find((service) => service.value === scheduleData.serviceType)?.label ?? "--";
	};

	const filteredServices = completeServices.filter(
		(service) =>
			service.octoNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
			service.customer.toLowerCase().includes(searchQuery.toLowerCase()) ||
			service.address.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const viewButtonHandler = async () => {
		console.log("getting all services");
		if (!from) {
			showErrorToastMessage("Please select from date.");
			return;
		}
		if (!to) {
			showErrorToastMessage("Please select to date.");
			return;
		}

		const utcFromDate = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 0, 0, 0, 0));
		const utcToDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0));

		if (utcFromDate < utcToDate) {
			showErrorToastMessage("To date must be greater than from date.");
			return;
		}
		while (utcFromDate <= utcToDate) {
			utcFromDate.setDate(utcFromDate.getDate() + 1);
		}

		let q = query(
			collection(db, "scheduledServices"),
			where("date", ">=", utcToDate),
			where("date", "<=", utcFromDate)
		);
		q = query(q, where("status", "in", [SERVICE_STATUS.COMPLETE, SERVICE_STATUS.CLOSED, SERVICE_STATUS.CANCELLED]));
		q = query(q, orderBy("date", "desc"));
		q = query(q, where("generatorId", "in", [generatorData?.id, ...childGeneratorIds]));

		try {
			setIsLoading(true);
			const snap = await getDocs(q);

			if (snap.docs.length < 1) {
				showErrorToastMessage("No completed services found in given range.");
			}
			let tempTotalWeight = 0;
			let tempTotalQuantity = 0;

			const allCompletedServices = [];
			const jobs = snap.docs.map(async (serviceSnap) => {
				let serviceWeight = 0;
				let serviceQuantity = 0;
				const serviceData = serviceSnap.data();

				if (serviceData?.consolidated?.length > 0) {
					serviceData.consolidated.forEach((el) => {
						tempTotalWeight += parseFloat(`${el.weight}`);
						serviceWeight += parseFloat(`${el.weight}`);
						tempTotalQuantity += parseFloat(`${el.quantity}`);
						serviceQuantity += parseFloat(`${el.quantity}`);
					});
				}
				if (serviceData?.itemized?.length > 0) {
					serviceData.itemized.forEach((el) => {
						tempTotalWeight += parseFloat(`${el.weight}`);
						serviceWeight += parseFloat(`${el.weight}`);
						tempTotalQuantity++;
						serviceQuantity++;
					});
				}
				const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
				const scheduleRes = await getDoc(scheduleDataRef);
				if (!scheduleRes.exists()) {
					return;
				}
				const scheduleData = scheduleRes.data();

				const serviceTypeString = getStringOfServiceTypes(scheduleData);
				const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
				const generatorRes = await getDoc(generatorDataRef);
				if (!generatorRes.exists()) {
					return;
				}
				const generatorData = generatorRes.data();

				const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
				const routeRes = await getDoc(routeDataRef);
				if (!routeRes.exists()) {
					return;
				}
				const routeData = routeRes.data();

				console.log("service data", serviceData);

				const manifestDataRef = collection(db, COLLECTIONS.manifests);
				const manifestQuery = query(manifestDataRef, where("serviceId", "==", serviceSnap.id));
				const manifestRes = await getDocs(manifestQuery);
				console.log("manifstRes", manifestRes);

				let manifestData = manifestRes.docs.map((item) => ({ id: item.id, ...item.data() }));
				console.log("manifest Data:", manifestData);

				allCompletedServices.push({
					id: serviceSnap.id,
					serviceDate: serviceData.date.toDate(),
					completedAt: serviceData?.completedAt ? serviceData.completedAt.toDate().toLocaleString() : "--", // Convert to string
					time: serviceData.date.toDate().toLocaleTimeString(),
					driverNote: serviceData?.driverNote?.length > 0 ? serviceData?.driverNote : "--",
					customer: `${generatorData?.generatorName ?? "--"} `,
					octoNumber: `${generatorData?.octoNumber ?? "--"}`,
					email: `${generatorData?.generatorEmail ?? "--"}`,
					address: `${generatorData.serviceAddress?.street ?? ""} ${generatorData.serviceAddress?.suite ?? ""} ${
						generatorData.serviceAddress?.city ?? ""
					} ${generatorData.serviceAddress?.state ?? ""} ${generatorData.serviceAddress?.zipCode ?? ""}`,
					billed: serviceData?.isBilled ?? false,
					billedDate: serviceData?.billedDate?.toDate()?.toLocaleDateString() ?? null,
					paidDate: serviceData?.paidDate?.toDate() ?? null,
					paid: serviceData?.isPaid ?? false,
					paidAmount: serviceData?.paidAmount ?? "--",
					optionChecked: false,
					driverName: `${serviceData?.assignedDriverName ?? "--"}`,
					pickUpTruck: `${serviceData?.assignedServiceVehicleName ?? "--"}`,
					serviceType: serviceTypeString ?? "--",
					routeName: routeData?.routeLabel ?? "--",
					quantity: serviceQuantity ?? "--",
					totalWeight: serviceWeight ?? "--",
					generatorId: serviceData.generatorId ?? "--",
					manifestData, // FIXED
				});
			});
			await Promise.all(jobs);

			const filteredCompletedServices = allCompletedServices.filter(
				(service) => selectedLocations.includes("all") || selectedLocations.includes(service.generatorId)
			);

			if (selectedSort.length > 0 && selectedSort === "generator_asc") {
				allCompletedServices.sort((a, b) => a.customer.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "generator_desc") {
				allCompletedServices.sort((a, b) => b.customer.localeCompare(a.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_asc") {
				allCompletedServices.sort((a, b) => a.routeName.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_desc") {
				allCompletedServices.sort((a, b) => b.routeName.localeCompare(a.customer));
			}

			setTotalWeight(tempTotalWeight);
			console.log("Final Total Weight:", tempTotalWeight);
			filteredCompletedServices.sort((a, b) => b.serviceDate - a.serviceDate);
			setCompleteServices(filteredCompletedServices);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleManifestView = async (id, url) => {
			console.log({ id, url });
	
			if (url && url?.length > 0) {
				window.open(url, "_blank");
			} else {
				const fetchUrl = getManifestUrl(id);
				console.log("fetchUrl", fetchUrl);
				window.open(fetchUrl, "_blank");
			}
		};



	const isParentWithChildren = !data?.parentAccount && childrenData && childrenData.length > 0;

	console.log("filterService", filteredServices);

	return (
		<div className="flex flex-col border-cardBorderCol bg-white h-full rounded-cardRadii ">
			<div className="text-lg font-normal mx-8 my-3 text-black flex">
				<p>Service History Report</p>
			</div>

			<div className="flex flex-col mx-8 my-5 gap-x-2">
				<div className="w-full">
					<div className="flex items-center py-2 z-50 gap-4 bg-white rounded-full px-5 border border-gray-200 h-12 relative max-w-1/2 w-1/2">
						<FiSearch className="text-cardTextGray" size={20} />
						<input
							type="text"
							placeholder="Search By Name, OCTO Number, Address, Document ID#"
							className="bg-transparent focus:outline-none w-full text-cardTextGray h-full"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
				</div>

				<div className="flex gap-2 items-end">
					<div className="flex flex-col min-w-52">
						<p className="text-sm text-gray-500 px-2">From</p>
						<div className="">
							<CustomDatePicker
								selectedDate={from}
								setSelectedDate={(value) => setFrom(new Date(value))}
								label={"From Date"}
								borderClass="rounded-lg"
							/>
						</div>
					</div>
					<div className="flex flex-col min-w-52">
						<p className="text-sm text-gray-500 px-2">To</p>
						<CustomDatePicker
							selectedDate={to}
							setSelectedDate={(value) => setTo(new Date(value))}
							label={"To Date"}
							borderClass="rounded-lg"
						/>
					</div>
					<div className="flex items-center gap-2 border-borderCol p-2 h-12 relative overflow-x-auto overflow-y-hidden">
						<div className="flex items-center gap-1">
							<div className="flex min-w-35">
								<MultiselectDropdown
									width="w-35"
									buttonText="Report Type"
									options={[
										{ label: "All", value: "all" },
										{ label: "Disposal Documents", value: "Disposal_Documents" },
										{ label: "Delivery Receipts", value: "Delivery_Receipts" },
										{ label: "Product Delivery", value: "Product_Delivery" },
									]}
									selectedOptions={selectedReportTypes}
									onSelectedOptionsChange={(values) => setSelectedReportTypes(values)}
								/>
							</div>
							<div className="flex min-w-35 ">
								<MultiselectDropdown
									width="w-35"
									buttonText="Service Type"
									options={[
										{ label: "All", value: "all" },
										{ label: "Medical Waste", value: "Medical_Waste" },
										{ label: "Paper Shredding", value: "Paper_Shredding" },
									]}
									selectedOptions={selectedServiceTypes}
									onSelectedOptionsChange={(values) => setSelectedServiceTypes(values)}
								/>
							</div>

							<div className="flex min-w-35 ">
								<MultiselectDropdown
									width="w-35"
									buttonText="Service Locations"
									options={[
										{ label: "All", value: "all" }, // "All" option at the top
										...(generatorData
											? [
													{
														label: `(You) ${generatorData.generatorName}, ${
															generatorData.serviceAddress?.street || ""
														}`,
														value: generatorData.id,
													},
											  ]
											: []),
										...(isParentWithChildren
											? childrenData.map((child) => ({
													label: `${child.generatorName}, ${child.serviceAddress?.street || ""}`,
													value: child.id,
											  }))
											: []),
									]}
									selectedOptions={selectedLocations}
									onSelectedOptionsChange={(values) => {
										// Ensure both the logged-in generator ID and "all" are included
										if (values.includes("all")) {
											setSelectedLocations(["all", data.id, ...childGeneratorIds]); // Include logged-in generator ID and all child IDs
										} else {
											setSelectedLocations(values);
										}
									}}
									disabled={!isParentWithChildren}
								/>
							</div>
						</div>
					</div>
					<div className="ml-auto max-w-fit cursor-pointer">
						<p
							onClick={() => {
								setSelectedReportTypes(["all", "Product_Delivery", "Disposal_Documents", "Delivery_Receipts"]);
								setSelectedServiceTypes(["all", "Medical_Waste", "Paper_Shredding"]);
								setSelectedLocations(["all", data.id, ...childGeneratorIds]);
								setTo(new Date());
								setFrom(new Date());
							}}
							className="text-sm whitespace-nowrap text-primary-500 ml-auto self-end py-2"
						>
							Clear Filter
						</p>
					</div>
				</div>
			</div>
			<div className=" flex flex-col h-full overflow-x-auto">
				<div className="flex font-medium py-4 min-w-fit bg-whiteBlue">
					<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
						<input type="checkbox" className="w-4 h-4 bg-white" />
					</div>
					<div className="flex items-center text-sm font-semibold">
						<div className="min-w-60 pr-2">Date and Time</div>
						<div className="min-w-60 pr-2">Octo Number</div>
						<div className="min-w-60 pr-2">Generator Name</div>
						<div className="min-w-60 pr-2">Service Address</div>
						<div className="min-w-60 pr-2">Driver Note</div>
						<div className="min-w-60 pr-2">Qty (Items/Containers)</div>
						<div className="min-w-60 pr-2">Total Weight</div>
						<div className="min-w-60 pr-2">Document ID#</div>
						<div className="min-w-60 pr-2">Option</div>
					</div>
				</div>

				<div className="flex-grow overflow-y-auto min-w-fit">
					{isLoading ? (
						<Loader height="h-20 pt-8" />
					):filteredServices.length > 0 ? (
						filteredServices.map((item, index) => (
							<div
								key={index}
								className="flex items-center transition-colors duration-300 border-b min-w-fit border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20 hover:cursor-pointer text-sm"
								style={{ minHeight: "70px" }}
							>
								<div className="mx-8 flex justify-center items-center mt-1">
									<input
										type="checkbox"
										className="w-4 h-4 bg-white"
										// checked={completeServices.includes(index)}
									/>
								</div>
								<div className="min-w-60 pr-2">
									<div>{item.completedAt}</div>
								</div>

								<div className="min-w-60 pr-2">
									<div>{item.octoNumber}</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">{item.customer}</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">{item.address}</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">{item.driverNote}</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">{item.quantity}</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">{item.totalWeight}</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">
									{item.manifestData?.map((manifest) => (
														<button
															key={manifest?.id}
															className="text-center text-sm text-primary"
															onClick={() => handleManifestView(manifest.id, manifest.url)}
														>
															{manifest?.manifestOctoId}
														</button>
													))}
									</div>
								</div>
								<div className="min-w-60 pr-2">
									<div className="text-sm font-medium">{item.options}</div>
								</div>
							</div>
						))
					) : (
						<div className="flex justify-center items-center min-h-[50vh]">No Data Found</div>
					)}
				</div>

				<div className="flex font-medium py-4 min-w-fit bg-whiteBlue">
					<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer"></div>
					<div className="min-w-60 flex gap-2 items-center">
						<div className="flex gap-2 items-center pr-2">Total Record(s) Found {filteredServices.length}</div>
					</div>
					<div className="min-w-60 pr-2"></div>
					<div className="min-w-60 pr-2"></div>
					<div className="min-w-60 pr-2"></div>
					<div className="min-w-60 pr-2"></div>
					<div className="min-w-60 pr-2"></div>
					<div className="min-w-60 pr-2">
						<div className="mx-auto">{totalWeight.toFixed(2)} lbs</div>
					</div>
					<div className="min-w-60 pr-2"></div>
					<div className="min-w-60 pr-2"></div>
				</div>
			</div>

			{/* Buttons Section */}
			<div className="bg-white shadow-md flex justify-end p-4 gap-5 mt-auto">
				<Button
					btnStyle="form_nav_primary"
					text={"Go To Dashboard"}
					type="button"
					onClick={() => {
						navigate("/generator-dashboard");
					}}
				/>
				<Button
					btnStyle="form_nav_secondary"
					text="Go To Invoice History"
					onClick={() => {
						navigate("/generator-dashboard/invoices-and-payments");
					}}
				/>
			</div>
		</div>
	);
};

export default GeneratorServiceReporting;
