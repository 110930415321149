import React, { useEffect, useState, useRef } from "react";
import { onSnapshot, doc, collection } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import useTUserContext from "../../context/TransporterUserContext";
import { COLLECTIONS, db } from "../../config/firebase";

const Preview = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { user } = useTUserContext();
	const [transporterData, setTransporterData] = useState(null);
	const [data, setData] = useState(null);
	const [customMessage, setCustomMessage] = useState(""); 
	const iframeRef = useRef(null);

	useEffect(() => {
		if (!user || !user.transporterId || !id) return;

		const transporterDocRef = doc(db, COLLECTIONS.emailTemplates, user.transporterId);
		const templatesCollectionRef = collection(transporterDocRef, "templates");
		const templateDocRef = doc(templatesCollectionRef, id);

		let unsubscribe = onSnapshot(templateDocRef, (snap) => {
			if (snap.exists()) {
				const templateData = { id: snap.id, ...snap.data() };
				setData(templateData);
				setCustomMessage(templateData.customMessage || ""); 
			}
		});

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user, id]);

	useEffect(() => {
		if (!user) return;

		let unsubscribe = onSnapshot(doc(db, COLLECTIONS.transporters, user.transporterId), (snap) => {
			if (snap.exists()) {
				setTransporterData({ id: snap.id, ...snap.data() });
			}
		});

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [user, id]);

	// Inject HTML content into iframe and adjust height
	useEffect(() => {
		const adjustIframeHeight = () => {
			if (iframeRef.current) {
				const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
				if (iframeDocument && iframeRef.current.contentWindow) {
					iframeRef.current.style.height = iframeDocument.body.scrollHeight + "px";
				}
			}
		};

		if (iframeRef.current && data?.htmlContent && transporterData) {
			let htmlContent = data.htmlContent;

			if (transporterData.companyName) {
				htmlContent = htmlContent.replace(/{{transporter.name}}/g, transporterData.companyName);
			}
			// Address information from serviceAddress
			if (transporterData.serviceAddress) {
				if (transporterData.serviceAddress.street) {
					htmlContent = htmlContent.replace(/{{transporter.street}}/g, transporterData.serviceAddress.street);
				}

				if (transporterData.serviceAddress.suite) {
					htmlContent = htmlContent.replace(/{{transporter.suite}}/g, transporterData.serviceAddress.suite);
				}

				if (transporterData.serviceAddress.city) {
					htmlContent = htmlContent.replace(/{{transporter.city}}/g, transporterData.serviceAddress.city);
				}

				if (transporterData.serviceAddress.state) {
					htmlContent = htmlContent.replace(/{{transporter.state}}/g, transporterData.serviceAddress.state);
				}

				if (transporterData.serviceAddress.zipCode) {
					htmlContent = htmlContent.replace(/{{transporter.zip}}/g, transporterData.serviceAddress.zipCode);
				}
			}

			// Contact information
			if (transporterData.generalEmail) {
				htmlContent = htmlContent.replace(/{{transporter.email}}/g, transporterData.generalEmail);
			}

			if (transporterData.phoneNumber) {
				htmlContent = htmlContent.replace(/{{transporter.phone}}/g, transporterData.phoneNumber);
			}

			if (transporterData.companyLogo) {
				htmlContent = htmlContent.replace(/{{transporter.logo}}/g, transporterData.companyLogo.link);
			}
			if (data?.customMessage) {
				htmlContent = htmlContent.replace(/{{customMessage}}/g, data.customMessage);
			}
			const doc = iframeRef.current.contentWindow.document;
			doc.open();
			doc.write(htmlContent);
			doc.close();

			iframeRef.current.onload = adjustIframeHeight;

			setTimeout(adjustIframeHeight, 300);
		}
	}, [data, transporterData]);

	const handlePreviewClick = () => {
		navigate(`email/preview/${id}`);
	};

	return (
		<div className="min-h-screen bg-gray-100 p-4 md:p-6 w-full">
			<div className="w-full mx-auto">
				{/* Header */}
				<h1 className="text-xl md:text-2xl font-bold mb-4">Email Template Preview</h1>

				<div className="bg-white rounded-lg shadow-md p-4 md:p-6">
					{/* Email Header */}
					<div className="flex flex-col md:flex-row justify-between items-start mb-6">
						<div className="flex items-center mb-2 md:mb-0">
							<div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center mr-3">
								<span className="text-gray-600 text-xl">M</span>
							</div>
							<div>
								<p className="font-medium">
									Sample Generator{" "}
									<span className="font-normal text-gray-500 text-sm">&lt;generator@example.com&gt;</span>
								</p>
								<p className="text-gray-500 text-sm flex items-center">
									to me
									<svg
										className="w-4 h-4 ml-1"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
									</svg>
								</p>
							</div>
						</div>
						<div className="text-left md:text-right text-gray-500 text-sm">
							<p>9:14 AM</p>
							<p>(5 hours ago)</p>
						</div>
					</div>

					{/* Email Body */}
					<div className="mb-6">
						<div className="w-full">
							{data?.htmlContent ? (
								<iframe
									ref={iframeRef}
									title="Email Preview"
									className="w-full h-full"
									style={{ minHeight: "700px", border: "none" }}
								/>
							) : (
								<p className="text-gray-600 p-4">No content available</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Preview;