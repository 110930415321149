import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../config/firebase";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import MultiselectDropdown from "../../../components/UI/dropdowns/MultiselectDropdown";
import { processGeneratorData } from "../../../utils/firebaseOperations";
import { formattedDateTime } from "../../../utils/helpers";
import { RxCross2 } from "react-icons/rx";
import Button from "../../../components/UI/Button";
import useTUserContext from "../../../context/TransporterUserContext";

export const fetchDocumentsInBatches = async (db, collectionName, docIds) => {
	if (!docIds.length) return [];

	const chunkArray = (array, size) => {
		const result = [];
		for (let i = 0; i < array.length; i += size) {
			result.push(array.slice(i, i + size));
		}
		return result;
	};

	const chunks = chunkArray(docIds, 10);
	const collectionRef = collection(db, collectionName);

	try {
		const queryPromises = chunks.map((chunk) => {
			const q = query(collectionRef, where("id", "in", chunk));
			return getDocs(q);
		});

		const snapshots = await Promise.all(queryPromises);

		const processedDocuments = await Promise.all(
			snapshots.flatMap((snapshot) =>
				snapshot.docs.map(async (doc) => {
					const processed = await processGeneratorData(doc);
					return processed || null;
				})
			)
		);

		return processedDocuments.filter(Boolean);
	} catch (error) {
		console.error("Error fetching documents:", error);
		throw error;
	}
};

const SubcontractorServiceRequest = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [generators, setGenerators] = useState([]);
	const [allDatas, setAllDatas] = useState([]);
	const [genIds, setGenIds] = useState([]);
	const [pendingCons, setPendingCons] = useState([]);
	const [transporterNames, setTransporterNames] = useState({});
	const [currentTransporterData, setCurrentTransporterData] = useState(null);
	const { user, loading } = useTUserContext();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [toUpdate, setToUpdate] = useState(null);
	const navigate = useNavigate();

	const fetchAllPendingConnectionsData = async (transporterId) => {
		try {
			const transporterDoc = await getDoc(doc(db, "transporters", transporterId));
			if (transporterDoc.exists()) {
				setCurrentTransporterData({ id: transporterId, ...transporterDoc.data() });
	
				let sharedGenerators = transporterDoc.data()?.sharedGenerators;
				let pendingConns = Array.isArray(sharedGenerators?.toMe) ? sharedGenerators?.toMe : [];
	
				console.log("Pending Connections:", pendingConns);
				let pending = [];
				let ids = [];
	
				pendingConns.forEach((item) => {
					if (item.status === "Pending") {
						pending.push(item);
						ids.push(item.genId);
					}
				});
	
				setPendingCons(pending);
				setGenIds(ids); 
			}
		} catch (error) {
			console.error(`Error fetching transporter ${transporterId}:`, error);
		}
	};
	

	console.log({ currentTransporterData, pendingCons, genIds });

	useEffect(() => {
		if (user?.uid) fetchAllPendingConnectionsData(user?.uid);
	}, [user]);

	const fetchGens = async () => {
		try {
			let resp = await fetchDocumentsInBatches(db, "generators", genIds);
			setGenerators(resp);
		} catch (error) {
			console.log({ error });
		}
	};

	useEffect(() => {
		fetchGens();
	}, [genIds]);

	console.log({ allDatas });

	useEffect(() => {
		let latestPendingCons = pendingCons.map((connection) => {
			let gen = generators.find((item) => connection.genId === item.id) || {}; 
	
			return {
				...gen, 
				...connection, 
			};
		});
	
		console.log("allData", latestPendingCons);
		setAllDatas(latestPendingCons);
	}, [generators, pendingCons]);
	

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/subcontractor-management");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/pending-connections");
	};

	const handleDecline = async (generatorId, subContractorId) => {
		console.log({ generatorId, subContractorId });

		let currTransporterRef = doc(db, "transporters", user?.uid);
		let currTransporterData = await getDoc(currTransporterRef);
		let currTransporterSharedGens = currTransporterData.data()?.sharedGenerators ?? {};
		let newTransporterSharedGen = currTransporterSharedGens?.toMe?.map((item) => {
			if (item.genId == generatorId) {
				return {
					...item,
					status: "Declined",
				};
			}
			return item; 
		});
		console.log({ newTransporterSharedGen });
		await updateDoc(currTransporterRef, {
			sharedGenerators: {
				...currTransporterSharedGens,
				toMe: newTransporterSharedGen,
			},
		});

		let subContractorRef = doc(db, "transporters", subContractorId);
		let subContractorData = await getDoc(subContractorRef);
		let subContractorSharedGens = subContractorData.data()?.sharedGenerators ?? {};
		console.log({ subContractorSharedGens });

		let newSubContractorSharedGen = subContractorSharedGens?.fromMe?.map((item) => {
			if (item.genId == generatorId) {
				return {
					...item,
					status: "Declined",
				};
			}
			return item; 
		});
		await updateDoc(subContractorRef, {
			sharedGenerators: {
				...subContractorSharedGens,
				fromMe: newSubContractorSharedGen,
			},
		});
	};

	const handleAccept = async (generatorId, subContractorId) => {
		// setIsLoading(true);
		console.log("genIds",generatorId,subContractorId);
		
		try {
			let currTransporterRef = doc(db, "transporters", user?.uid);
			let currTransporterData = await getDoc(currTransporterRef);
			let currTransporterSharedGens = currTransporterData.data()?.sharedGenerators ?? {};
	
			let newTransporterSharedGen = currTransporterSharedGens?.toMe?.map((item) => {
				if (item?.genId === generatorId) {
					return {
						...item,
						status: "Accepted",
					};
				}
				return item; 
			});
	
			await updateDoc(currTransporterRef, {
				sharedGenerators: {
					...currTransporterSharedGens,
					toMe: newTransporterSharedGen,
				},
			});
	
			let subContractorRef = doc(db, "transporters", subContractorId);
			let subContractorData = await getDoc(subContractorRef);
			let subContractorSharedGens = subContractorData.data()?.sharedGenerators ?? {};
	
			let newSubContractorSharedGen = subContractorSharedGens?.fromMe?.map((item) => {
				if (item?.genId === generatorId) {
					return {
						...item,
						status: "Accepted",
					};
				}
				return item; 
			});
	
			await updateDoc(subContractorRef, {
				sharedGenerators: {
					...subContractorSharedGens,
					fromMe: newSubContractorSharedGen,
				},
			});
	
			let generatorRef = doc(db, "generators", generatorId);
			let generatorDoc = await getDoc(generatorRef);
			let generatorData = generatorDoc.data();
	
			let newGeneratorData = {
				...generatorData,
				isSubContracted: true,
				subContractor: {
					id: subContractorId,
					name: subContractorData.data()?.companyName, 
				},
			};
	
			await updateDoc(generatorRef, newGeneratorData);
		} catch (error) {
			console.error("Error accepting generator:", error);
		}
		// setIsLoading(false);
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div className="text-xl xl:text-2xl font-semibold text-black">Subcontractor Service Request</div>
					<div className=" px-2">
						<MultiselectDropdown
							buttonText="Subcontractor"
							options={[{ label: "All", value: "all" }]}
							selectedOptions={[]}
							onSelectedOptionsChange={() => {}}
						/>
					</div>
				</div>
				<div className="text-base mx-8 mb-3 text-black">Pending Acceptance</div>

				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Service Address</div>
							<div className="truncate w-80">Service Type</div>
							<div className="truncate w-60">Service Frequency</div>
							<div className="truncate w-60">Expected Containers</div>
							<div className="truncate w-32">Status</div>
							<div className="truncate w-60">Date/Time</div>
							<div className="truncate w-60">Pricebook</div>
							<div className="truncate w-60 text-center">Action</div>
						</div>
					</div>
                     {console.log("allDatas",allDatas)}
					<div className={`min-w-fit overflow-y-scroll min-h-40 h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : allDatas.length > 0 ? (
							allDatas.map((generator, index) => (
								<div
									key={generator.id}
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < generators.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-80 h-full">{generator?.subContractorName ?? "--"}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.name ?? "--"}</div>
										<div className="truncate w-80 h-full">
											{generator?.["serviceAddress"] && Object.keys(generator?.["serviceAddress"]).length > 0
												? [
														[
															generator?.["serviceAddress"]?.["street"],
															generator?.["serviceAddress"]?.["suite"] || "",
															generator?.["serviceAddress"]?.["city"],
															generator?.["serviceAddress"]?.["state"],
														].filter(Boolean).join(", "),
														generator?.["serviceAddress"]?.["zipCode"],
												  ]
														.filter(Boolean)
														.join(" ") || "--"
												: "--"}
										</div>
										<div className="truncate h-full w-80">
											{generator.serviceType?.length > 0 ? generator.serviceType?.toString() : "--"}
										</div>
										<div className="truncate h-full w-60">{generator.serviceFrequency}</div>
										<div className="truncate h-full w-60">
	                                      {generator.expectedItemsOrServices?.length > 0 ? (
	                                    	generator.expectedItemsOrServices.map((itemObj, index) => (
		                            	<span key={index}>
				                          {itemObj?.item} (Qty: {itemObj?.quantity}){index < generator?.expectedItemsOrServices.length - 1 ? ", " : ""}
			                            </span>	))) : ("--")}</div>

										<div className="truncate h-full w-32">{generator.status ?? "Pending"}</div>
										<div className="truncate h-full w-60">{formattedDateTime(generator.timeStamp) ?? "--"}</div>
										<div className="truncate h-full w-60">{generator.pricebook ?? "--"}</div>
										<div className="flex gap-2 w-60 items-center">
											<button
												className="bg-[#F3F3F3] rounded-full w-24 p-1 px-4"
												onClick={() => {
													setIsModalOpen(true);
													{console.log("generatorLog",generator)
													}
													setToUpdate({ generatorId: generator?.id, subContractorId: generator?.from?.id });
												}}
											>
												Decline
											</button>
											<button
												className={`px-4 py-1 text-nowrap bg-primary transition-colors duration-200 text-white rounded-full`}
												onClick={() => handleAccept(generator.genId, generator.transporterId)}
											>
												Accept & Import
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 gap-8 mb-12 mt-12">
				<Button btnStyle="form_nav_secondary" text={"Previous"} onClick={handlePreviousBtn} />
				<Button btnStyle="form_nav_primary" text="Next" onClick={handleNextClick} />
			</div>
			{isModalOpen && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center">
					<div className="bg-white z-20 rounded-cardRadii max-w-md w-full min-h-fit">
						<div className="flex flex-col justify-between min-h-40 p-6">
							<h6 className="text-2xl font-semibold">Are you sure?</h6>
							<div className="flex justify-end mt-4">
								<button onClick={() => setIsModalOpen(false)} className="bg-[#F3F3F3] rounded-full w-24 p-1 px-4">
									No
								</button>
								<button
									onClick={() => handleDecline(toUpdate?.generatorId, toUpdate?.subContractorId)}
									className={`px-4 py-1 w-24 bg-primary transition-colors duration-200 text-white rounded-full`}
								>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default SubcontractorServiceRequest;