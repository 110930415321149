import { useState, useEffect, useRef } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { octoFormatter } from "../../../../../utils/helpers";

const ChildDropdown = ({
  selectedValue,
  setSelectedValue,
  isParentWithChildren,
  isparent,
  generatorData,
  parentGenerator,
  childrenData,
  sameParentGenerators,
  getGeneratorById,
  setGeneratorData,
  setGeneratorWaypointCompleted,
  isLoadingTracking
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDisplayText, setSelectedDisplayText] = useState("Select Location");
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectChange = async (selectedId, displayText) => {
    if (selectedId) {
      setIsLoading(true);
      console.log(`Changing generator to ${selectedId}`);
      
      try {
        if (typeof setGeneratorWaypointCompleted === 'function') {
          setGeneratorWaypointCompleted(false);
        }
        const gendata = await getGeneratorById(selectedId);
        setGeneratorData(gendata);
        setSelectedValue(selectedId);
        setSelectedDisplayText(displayText);
        setIsOpen(false);
      } catch (error) {
        console.error("Error changing generator:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formatOctoNumber = (octoNumber) => {
    if (!octoNumber) return "";
    if (/^G-\d{3}-\d{4}$/.test(octoNumber)) {
      return octoNumber;
    }

    if (/^\d+$/.test(octoNumber)) {
      let numStr = octoNumber.padStart(7, "0");
      return `G-${numStr.slice(0, 3)}-${numStr.slice(3)}`;
    }
    return octoNumber;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  const shouldBeEnabled = () => {
    if (isLoading) return false;
    if (!isparent || isParentWithChildren) {
      return true;
    }
    
    return false;
  };

  const isEnabled = shouldBeEnabled();

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        className={`w-full flex justify-between items-center px-4 py-2 bg-white border rounded-full focus:outline-none ${
          !isEnabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={() => isEnabled && setIsOpen(!isOpen)}
        disabled={!isEnabled}
        style={{
          backgroundColor: "white",
          WebkitTextFillColor: "black",
          opacity: 1,
        }}
      >
        <span className="truncate max-w-[80%] overflow-hidden text-ellipsis">
          {isLoading ? "Loading..." : selectedDisplayText}
        </span>
        {isLoading ? (
          <div className="h-5 w-5 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
        ) : (
          <HiOutlineChevronDown
            className={`h-5 w-5 text-cardTextGray transition-transform duration-250 flex-shrink-0 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        )}
      </button>

      {isOpen && (
        <ul className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto">
          {generatorData?.parentAccount && (
            <li className="bg-cardTextBlue text-white px-4 py-2 cursor-not-allowed">Parent Account</li>
          )}
          
          {generatorData?.parentAccount && Array.isArray(parentGenerator) && parentGenerator.length > 0 &&
            parentGenerator.map((child, index) => {
              if (!child) return null;
              
              const displayText = ` ${child.octoNumber ? formatOctoNumber(child.octoNumber) : ""} ${
                " , " + child.generatorName || ""
              }`;
              
              return (
                <li
                  key={index}
                  className={`px-4 py-2 hover:bg-gray-200 cursor-pointer ${
                    child.id === generatorData?.id ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleSelectChange(child.id, displayText)}
                >
                  {displayText}
                </li>
              );
            })}

          {generatorData && <li className="bg-cardTextBlue text-white px-4 py-2 cursor-not-allowed">
            {generatorData?.parentAccount ? "Sibling Accounts" : "Child Accounts"}
          </li>}
          
          {(isParentWithChildren && isparent && !generatorData?.parentAccount
            ? childrenData
            : sameParentGenerators)?.map((child, index) => {
              if (!child) return null;
              
              const displayText = ` ${child.octoNumber ? formatOctoNumber(child.octoNumber) : ""} ${
                " , " + child.generatorName || ""
              }`;
              
              return (
                <li
                  key={index}
                  className={`px-4 py-2 hover:bg-gray-200 cursor-pointer ${
                    child.id === generatorData?.id ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleSelectChange(child.id, displayText)}
                >
                  {displayText}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default ChildDropdown;