import PropTypes from "prop-types";
import {
	AzureMapDataSourceProvider,
	AzureMapHtmlMarker,
	AzureMapLayerProvider,
	AzureMapPopup,
	AzureMapsContext,
	useCreatePopup,
} from "react-azure-maps";
import { data } from "azure-maps-control";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FcBiohazard } from "react-icons/fc";
import RoundedPin from "../../pages/admin/assets/components/routes-optimization/components/RoundedPin";
import SquarePin from "../../pages/admin/assets/components/routes-optimization/components/SquarePin";
import { use } from "react";
import { SERVICE_TYPES, serviceTypesMap } from "../../utils/constants";
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { COLLECTIONS, db } from "../../config/firebase";
import { FaStar } from "react-icons/fa";
import { log } from "handlebars";
import { octoFormatter } from "../../utils/helpers";
const CustomHtmlGeneratorMarker = ({ draggable = false, genData = {}, allRoutes = [], isCurrent = false, color }) => {
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [schedules, setSchedules] = useState([]);
	const [iconType, setIconType] = useState("");
	const [currentPosition, setCurrentPosition] = useState(
		new data.Position(genData.randomCoordinates.lng, genData.randomCoordinates.lat)
	);
	const [currentCollection, setCurrentCollection] = useState(
		new data.LineString([
			[genData.serviceAddCoordinates.lng, genData.serviceAddCoordinates.lat],
			[genData.randomCoordinates.lng, genData.randomCoordinates.lat],
		])
	);

	const markerOptions = {
		position: currentPosition,
		draggable: draggable,
	};
	const onClick = (e) => {
		console.log("You click on: ", e);
		setIsPopupVisible((prev) => !prev);
	};
	const dragHandler = useCallback(
		(e) => {
			setCurrentPosition(new data.Position(e.target.options.position[0], e.target.options.position[1]));
			setCurrentCollection(
				new data.LineString([
					[genData.serviceAddCoordinates.lng, genData.serviceAddCoordinates.lat],
					[e.target.options.position[0], e.target.options.position[1]],
				])
			);
		},
		[genData]
	);

	const eventToMarker = [
		{ eventName: "click", callback: onClick },
		{ eventName: "drag", callback: dragHandler },
	];

	useEffect(() => {
		if (!genData) return;
		let unsubscribed = onSnapshot(
			query(
				collection(db, COLLECTIONS.serviceSchedules),
				where("generatorId", "==", genData?.id ?? ""),
				orderBy("createdAt", "asc")
			),
			(snap) => {
				if (snap.docs.length > 0) {
					const data = snap.docs[0].data();
					if (data?.serviceType?.length > 0) {
						setIconType(data.serviceType);
					} else {
						setIconType("");
					}
					const tempSchedules = [];
					snap.docs.forEach((el) => {
						if (el.exists()) {
							const data = { ...el.data(), id: el.id };
							if (typeof data.serviceType !== "string") {
								data.serviceType = data.serviceType[0];
							}
							if (!data.hasOwnProperty("isDeleted") || data.isDeleted === false) {
								tempSchedules.push(data);
							}
						}
					});
					setSchedules(tempSchedules);
				} else {
					setIconType("");
				}
			}
		);
		return () => {
			if (unsubscribed) {
				unsubscribed();
			}
		};
	}, [genData]);

	const renderIcon = useCallback(() => {
		if (isCurrent) {
			return <FaStar className="w-10 h-10 text-yellow-500" />;
		}
		if (iconType === SERVICE_TYPES.MEDICAL_WASTE) {
			return <RoundedPin className={"w-12 h-12"} color={color ?? "red"} />;
		}
		if (iconType === SERVICE_TYPES.PAPER_SHREDDING) {
			return <SquarePin className={"w-10 h-10"} color={color ?? "red"} />;
		}
		if (iconType === SERVICE_TYPES.ON_SITE_PAPER_SHREDDING) {
			return <SquarePin className={"w-10 h-10"} color={color ?? "red"} />;
		}

		return <RoundedPin className={"w-12 h-12"} color={color ?? "red"} />;
	}, [isCurrent, iconType]);

	return (
		<>
			<AzureMapHtmlMarker events={eventToMarker} options={markerOptions} markerContent={renderIcon()} />
			<AzureMapDataSourceProvider id={`LineLayer DataSourceProvider ${genData.id}`} collection={currentCollection}>
				<AzureMapLayerProvider
					type="LineLayer"
					options={{
						strokeColor: "black",
						strokeWidth: 2,
						strokeOpacity: 0.5,
						blur: 0,
						lineCap: "round",
						translate: [0, 0],
					}}
				/>
			</AzureMapDataSourceProvider>
			<AzureMapPopup
				isVisible={isPopupVisible}
				key={Math.random().toString()}
				options={{ position: currentPosition, showPointer: false }}
				events={[{ eventName: "close", callback: () => setIsPopupVisible(false) }]}
				popupContent={
					<div className="max-w-fit">
						<div className="flex flex-col bg-gray-300 p-5 pb-1 rounded-t-xl gap-2 ">
							<div className="flex flex-col pr-10">
								<p className="text-base font-semibold">{genData.generatorName}</p>
								<p className="text-base font-semibold">{octoFormatter(genData.octoNumber)}</p>
							</div>
							<div className="flex items-center gap-2">
								<a href={`/admin/generators/${genData.id}/routes`} className="text-primary-500 underline">
									View Routes
								</a>
							</div>
						</div>
						<div className="flex flex-col gap-2 border-t border-b h-fit p-4 text-sm  bg-white rounded-b-xl">
							<div className="grid grid-cols-3 gap-x-10">
								<p className="font-semibold text-nowrap">Service Address:</p>
								<p className="col-span-2 text-wrap">
									{genData?.serviceAddress?.street ?? ""}
									{genData?.serviceAddress?.suite ? `, ${genData.serviceAddress.suite}` : ""},{" "}
									{genData?.serviceAddress?.city ?? ""}, {genData?.serviceAddress?.state ?? ""}{" "}
									{genData?.serviceAddress?.zipCode ?? ""}
								</p>
							</div>
							<div>
								<div className="grid grid-cols-3 gap-x-10">
									<p className="font-semibold text-nowrap">Service</p>
									<p className="font-semibold text-nowrap">Service Freq.</p>
									<p className="font-semibold text-nowrap">Route </p>
								</div>
								{schedules.map((el) => (
									<div key={el.id} className="grid grid-cols-3 gap-x-10">
										<p className=" text-nowrap">{serviceTypesMap[el.serviceType]}</p>
										<p className=" text-nowrap">
											{el?.serviceFrequency?.type?.length
												? el?.serviceFrequency?.type === "MTWM"
													? "MTW"
													: el?.serviceFrequency?.type
												: ""}
										</p>
										<p className=" text-nowrap">
											{allRoutes.find((route) => {
												if (route.id === el.routeId) {
													return route;
												}
											})?.routeLabel ?? ""}{" "}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				}
			/>
		</>
	);
};

CustomHtmlGeneratorMarker.propTypes = {
	coordinates: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	draggable: PropTypes.bool,
	genData: PropTypes.object,
};

export default CustomHtmlGeneratorMarker;
