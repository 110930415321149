import React, { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { emailRegex } from "../../../utils/mimes";
import { sendResetPasswordEmail } from "../../../config/firebase";
import { FaEnvelope } from "react-icons/fa";

const ForgotPasswordEmail = ({ setDefaultForm }) => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Email validation
		if (!emailRegex.test(email)) {
			return toast.error("Invalid email!");
		}

		setLoading(true);
		try {
			await sendResetPasswordEmail(email);
			toast.success("Password reset link sent to your email!");
			// Redirecting to login after successful password reset email
			setDefaultForm("LogIn");
		} catch (error) {
			console.log(error);
			if (error.code === "auth/user-not-found") {
				toast.error("No user found with this email!");
			} else {
				toast.error("Failed to send reset email. Please try again.");
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="space-y-4 bg-[#F6F8FA] shadow-lg p-8 w-full mb-12">
			<div className="space-y-6 px-8 lg:px-12">
				<div className="relative flex justify-center text-center border-b text-sm border-gray-300">
					<button
						className={`relative px-6 py-3 w-full font-medium focus:outline-none bg-gradient-to-r from-[#007AFF] to-[#4CD964] bg-clip-text text-transparent`}
						type="button"
						disabled={true}
					>
						Reset Password
					</button>
					{/* Sliding Indicator */}
					<motion.div
						className="absolute bottom-0 w-full h-[2px] bg-gradient-to-r from-blue-500 to-green-400 rounded-t-full"
						transition={{ type: "keyframes", stiffness: 200, damping: 20 }}
					/>
				</div>
				<div>
					<label className="block text-xs font-medium text-gray-700">Email Address</label>
					<div className="relative mt-1">
						<FaEnvelope className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Enter your email address"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
					</div>
				</div>
				<button
					type="submit"
					onClick={handleSubmit}
					disabled={loading}
					className={`w-full p-3 font-semibold rounded-md ${
						loading
							? "bg-primaryGray border border-cardTextGray text-cardTextGray"
							: " text-white bg-blue-500 hover:bg-blue-600"
					}`}
				>
					{loading ? "Sending..." : "Send Reset Link"}
				</button>
			</div>

			<p className="text-center">
				Remember your password?{" "}
				<button onClick={() => setDefaultForm("LogIn")} className="text-[#007AFF] underline hover:text-indigo-500">
					Log in
				</button>
			</p>
		</div>
	);
};

export default ForgotPasswordEmail;
