import React from "react";

const RoundedPin = ({ className, text, color }) => {
	return (
		<svg className={`${className}`} viewBox="2 4 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_f_501_657)">
				<ellipse cx="12.4961" cy="21.677" rx="2.5" ry="0.892857" fill="black" fillOpacity="0.2" />
			</g>
			<g filter="url(#filter1_d_501_657)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.5 4C16.6421 4 20 7.35786 20 11.5C20 14.6759 18.026 17.3908 15.238 18.4845L12.9272 21.6431C12.879 21.7092 12.815 21.7632 12.7407 21.8004C12.6663 21.8377 12.5838 21.8571 12.5 21.8571C12.4162 21.8571 12.3337 21.8377 12.2593 21.8004C12.185 21.7632 12.121 21.7092 12.0728 21.6431L9.76202 18.4845C6.97402 17.3908 5 14.6759 5 11.5C5 7.35786 8.35786 4 12.5 4Z"
					fill={color}
				/>
			</g>
			{/* Square */}
			<path
				d="M17.8627 11.5027C17.8627 8.54398 15.4642 6.14551 12.5056 6.14551C9.54691 6.14551 7.14844 8.54398 7.14844 11.5027C7.14844 14.4613 9.54691 16.8598 12.5056 16.8598C15.4642 16.8598 17.8627 14.4613 17.8627 11.5027Z"
				fill="white"
			/>

			{/* Text */}
			<text x="12.5" y="11.5" fontSize="8" textAnchor="middle" dominantBaseline="middle" fill="black">
				{text}
			</text>

			<defs>
				<filter
					id="filter0_f_501_657"
					x="7.99609"
					y="18.7842"
					width="9"
					height="5.78613"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_501_657" />
				</filter>
				<filter
					id="filter1_d_501_657"
					x="0"
					y="0"
					width="25"
					height="27.8574"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="" />
					<feGaussianBlur stdDeviation="2.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_501_657" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_501_657" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};

export default RoundedPin;
