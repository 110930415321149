import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../config/firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import Button from "../../../components/UI/Button";
import useTUserContext from "../../../context/TransporterUserContext";

const fetchSubcontractorData = async (transporterId) => {
	try {
		// Get the transporter's document
		const transporterDoc = await getDoc(doc(db, "transporters", transporterId));

		if (!transporterDoc.exists()) {
			console.log("No such transporter!");
			return [];
		}

		const data = transporterDoc.data();
		const contractorRelationships = data.contractorTo || {};

		// Get all subcontractor details
		const subcontractorPromises = Object.entries(contractorRelationships).map(
			async ([subcontractorId, relationship]) => {
				const subcontractorDoc = await getDoc(doc(db, "transporters", subcontractorId));

				if (!subcontractorDoc.exists()) return null;

				const subcontractorData = subcontractorDoc.data();

				console.log({ status: relationship.status });

				return {
					id: subcontractorId,
					name: subcontractorData.name ?? "",
					// octoNumber: subcontractorData.octoNumber,
					address: formatAdd(subcontractorData),
					generalEmail: subcontractorData.billingEmail ?? "",
					phoneNumber: subcontractorData.billingPhone ?? "",
					website: subcontractorData.website ?? "",
					sharedGenerators: relationship.sharedGenerators,
					startDate: relationship.startDate,
					status: relationship.status,
				};
			}
		);

		const subcontractors = await Promise.all(subcontractorPromises);
		return subcontractors.filter(Boolean).filter((item) => item.status == "accepted"); // Remove any null entries
	} catch (error) {
		console.error("Error fetching subcontractor data:", error);
		return [];
	}
};

const fetchContractorData = async (transporterId) => {
	try {
		// Get the transporter's document
		const transporterDoc = await getDoc(doc(db, "transporters", transporterId));
		const transporterMarketDoc = await getDoc(doc(db, "octoMarketUsers", transporterId));

		if (!transporterDoc.exists() && !transporterMarketDoc.exists()) {
			console.log("No such transporter!");
			return [];
		}

		const data = transporterDoc.data();
		const transporterMarketData = transporterMarketDoc.data();
		console.log({ data, octoData: transporterMarketDoc.data() });

		if (!transporterMarketData) return [];

		const contractorRelationships = transporterMarketData.connections || {};

		// Get all contractor details
		const contractorPromises = Object.entries(contractorRelationships).map(async ([contractorId, relationship]) => {
			const contractorDoc = await getDoc(doc(db, "transporters", contractorId));

			if (!contractorDoc.exists()) return null;

			const contractorData = contractorDoc.data();
			return {
				id: contractorData,
				name: contractorData.name ?? "--",
				address: formatAdd(contractorData),
				generalEmail: contractorData.generalEmail?.length > 0 ? contractorData.generalEmail : "--",
				phoneNumber: contractorData.phoneNumber ?? "--",
				website: contractorData.website?.length ? contractorData?.website : "--",
				sharedGenerators: contractorData?.allGens?.length,
				startDate: relationship.startDate,
				status: relationship.status,
			};
		});

		const contractors = await Promise.all(contractorPromises);
		return contractors
			.concat([
				{
					id: transporterDoc.id,
					name: data.name ?? "--",
					address: formatAdd(data),
					generalEmail: data.generalEmail?.length > 0 ? data.generalEmail : "--",
					phoneNumber: data.phoneNumber ?? "--",
					website: data.website?.length ? data?.website : "--",
					sharedGenerators: data?.allGens?.length,
					startDate: new Date(),
					status: "",
				},
			])
			.filter(Boolean);
	} catch (error) {
		console.error("Error fetching contractor data:", error);
		return [];
	}
};

let formatAdd = (transporter) => {
	let formattedAdd = "";
	transporter.billingAddress?.street?.trim()?.length
		? (formattedAdd += transporter.billingAddress?.street)
		: (formattedAdd = "");
	transporter.billingAddress?.suite?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingAddress?.suite}`)
		: (formattedAdd += transporter.billingAddress?.suite ?? "");
	transporter.billingAddress?.city?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingAddress?.city}`)
		: (formattedAdd += transporter.billingAddress?.city ?? "");
	transporter.billingAddress?.state?.trim()?.length && formattedAdd?.length
		? (formattedAdd += `, ${transporter.billingAddress?.state}`)
		: (formattedAdd += transporter.billingAddress?.state ?? "");
	transporter.billingAddress?.zipCode?.trim()?.length && formattedAdd?.length
		? (formattedAdd += ` ${transporter.billingAddress?.zipCode}`)
		: (formattedAdd += transporter.billingAddress?.zipCode ?? "");

	return formattedAdd.length ? formattedAdd : "--";
};

/* 

Everyone who has opted is subcontractor
and if they have paid they will become contractor also
and this payment status to be added after billing is implemented.

*/

const Subcontrator_Management = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentTransporterData, setCurrentTransporterData] = useState({});
	const { user, loading } = useTUserContext();
	const navigate = useNavigate();
	const [subContractors, setSubContractors] = useState([]);
	const [subContractorData, setSubContractorData] = useState([]);
	const dropdownOptions = ["Listed", "Unlisted"];

	const fetchAllPendingConnectionsData = async (transporterId) => {
		try {
			const transporterDoc = await getDoc(doc(collection(db, "transporters"), transporterId));
			if (transporterDoc.exists()) {
				setCurrentTransporterData({ id: transporterId, ...transporterDoc.data() });
				let sharedGenerators = transporterDoc.data()?.sharedGenerators;
				let pendingConns = Array.isArray(sharedGenerators?.fromMe) ? sharedGenerators?.fromMe : [];
				let subContractors = [];

				pendingConns.forEach((conn) => {
					subContractors.push(conn.to?.id);
				});

				return subContractors;
			}
		} catch (error) {
			console.error(`Error fetching transporter ${transporterId}:`, error);
		}
	};

	useEffect(() => {
		const loadSubcontractors = async () => {
			if (!user) return;

			setIsLoading(true);
			try {
				const subcontractors = await fetchContractorData(user?.uid);
				console.log({ subcontractors });
				setSubContractors(subcontractors);
				setSubContractorData(subcontractors);
			} catch (error) {
				console.error("Error loading subcontractors:", error);
			} finally {
				setIsLoading(false);
			}
		};

		loadSubcontractors();
	}, [user]);

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/contractor-management");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/service-request");
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	const handleSubmit = async (gens, subContractor) => {
		console.log({ gens });
		localStorage.setItem("gens", JSON.stringify(gens));
		localStorage.setItem("subContractor", subContractor);
		navigate("/admin/octo-connect/service-locations");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border text-sm xl:text-base border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 text-black">Sub-Contractor Management</div>
				<div className="text-base mx-8 mb-3 text-black">Current Partners Management</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-60">Sub-Contractor Name</div>
							{/* <div className="truncate w-60">OCTO Number</div> */}
							<div className="truncate w-80">Sub-Contractor Address</div>
							<div className="truncate w-60">Sub-Contractor Email</div>
							<div className="truncate w-60">Sub-Contractor Phone</div>
							<div className="truncate w-60">Sub-Contractor Website</div>
							<div className="truncate w-60 text-center">Number of Service Locations</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-40 pt-4" />
						) : subContractorData?.length > 0 ? (
							subContractorData?.map((subContractor, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < subContractorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-60 h-full">{subContractor.name ?? "--"}</div>
										{/* <div className="w-60 h-full text-cardTextBlue truncate">{subContractor.octoNumber ?? "--"}</div> */}
										<div className="truncate h-full w-80">{subContractor.address ?? "--"}</div>
										<div className="truncate h-full w-60">{subContractor.generalEmail ?? "--"}</div>
										<div className={`w-60 h-full`}>
											<span
												className={`truncate ${
													subContractor.phoneNumber?.length > 0 ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) =>
													subContractor.phoneNumber ? handlePhoneClick(e, subContractor.phoneNumber) : ""
												}
											>
												{subContractor.phoneNumber?.length > 0 ? subContractor.phoneNumber : "--"}
											</span>
										</div>
										<div className="truncate h-full w-60">{subContractor.website ?? "--"}</div>
										<div className="truncate h-full w-60 text-center">
											<button
												onClick={() => handleSubmit(subContractor.sharedGenerators, subContractor.name)}
												className={`${
													subContractor.sharedGenerators?.length
														? "hover:text-blueText hover:underline"
														: "cursor-default"
												}`}
											>
												{subContractor.sharedGenerators?.length?.toString() ?? "--"}
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* Buttons Section */}
				<div className="flex justify-end p-4 gap-8 mb-12 mt-12">
					<Button btnStyle="form_nav_secondary" text={"Previous"} onClick={handlePreviousBtn} />
					<Button btnStyle="form_nav_primary" text="Next" onClick={handleNextClick} />
				</div>
			</div>
		</div>
	);
};

export default Subcontrator_Management;
