import { InfoWindow, Map, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Loader from "../UI/loaders/Loader";
import { serviceTypesMap } from "../../utils/constants";

const MapWithSingleMarker = ({
	geocodedMarker,
	initialCenter,
	isAddressesLoaded,
	zoomVal = 6,
	mapUniqueId = "Single",
	routesMap,
}) => {
	console.log({ geocodedMarker, initialCenter });

	return isAddressesLoaded ? (
		<MapComp
			marker={geocodedMarker}
			initialCenter={initialCenter}
			zoomVal={zoomVal}
			mapUniqueId={mapUniqueId}
			routesMap={routesMap}
		/>
	) : (
		<Loader />
	);
};

const MapComp = ({ marker, initialCenter, zoomVal, mapUniqueId, routesMap }) => {
	const [mapRef, setMapRef] = useState(null);
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);

	useEffect(() => {
		if (mapRef && marker.lat && marker.lng) {
			const bounds = new window.google.maps.LatLngBounds();
			bounds.extend({ lat: marker.lat, lng: marker.lng });
			mapRef.fitBounds(bounds);
		}
	}, [marker, mapRef]);

	const handleMarkerClick = useCallback(() => {
		setInfoWindowOpen(true);
	}, []);

	const handleInfoWindowClose = useCallback(() => {
		setInfoWindowOpen(false);
	}, []);

	console.log("====================================");
	console.log({ marker });
	console.log("====================================");

	return (
		<div className="h-full w-full z-0 overflow-hidden rounded-secondary_radii">
			<Map
				className="h-full w-full z-0"
				mapId={mapUniqueId}
				gestureHandling="cooperative"
				defaultCenter={initialCenter}
				// center={initialCenter}
				defaultZoom={zoomVal}
				zoomControl={true}
				onLoad={(map) => setMapRef(map)}
			>
				{marker && (
					<>
						{infoWindowOpen && (
							<InfoWindow onCloseClick={handleInfoWindowClose} position={{ lat: marker.lat, lng: marker.lng }}>
								<div className="bg-white shadow-lg w-[400px] rounded-lg overflow-hidden relative">
									<div className="flex justify-between items-center bg-gray-100 p-2 border-b border-gray-300">
										<h2 className="text-lg font-semibold text-gray-800">{marker.name}</h2>
										<button
											onClick={handleInfoWindowClose}
											className="text-gray-500 hover:text-red-500 transition-colors duration-200"
										>
											<IoMdClose size={24} />
										</button>
									</div>
									<div className="border-t w-full border-b h-fit p-4 text-sm">
										<div className="flex">
											<span className="font-medium w-1/3">Service Address:</span>
											<span className="w-2/3">{marker.address ?? "--"}</span>
										</div>
										<div className="flex">
											<span className="font-medium w-1/3">OCTO No:</span>
											<span className="w-2/3">{marker.octoNumber ?? "--"}</span>
										</div>
										<div className="flex items-start">
											<span className="font-medium w-1/3">Service Type:</span>
											<span className="w-2/3">
												{Array.isArray(marker?.serviceType)
													? marker.serviceType
															.map((serviceType) => serviceTypesMap[serviceType] || serviceType)
															.join(", ")
													: "No services"}
											</span>
										</div>
										<div className="flex">
											<span className="font-medium w-1/3">Service Frequency:</span>
											<span className="w-2/3">
												{marker?.serviceFrequency?.map((freq) => freq.toString().replace("MTWM", "MTW")).join(", ") ??
													"--"}
											</span>
										</div>
										<div className="flex">
											<span className="font-medium w-1/3">Route Assigned:</span>
											<span className="w-2/3">
												{Array.isArray(marker?.routes)
													? marker?.routes
															?.map((route) => routesMap?.[route])
															?.filter(Boolean)
															?.toString()
													: "None"}
											</span>
										</div>
									</div>
								</div>
								<style>{`
        .gm-style-iw.gm-style-iw-c {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-style-iw-ch {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style-iw-d {
          padding: 0 !important;
          overflow: hidden !important;
        }
      `}</style>
							</InfoWindow>
						)}
						<AdvancedMarker position={{ lat: marker.lat, lng: marker.lng }} onClick={handleMarkerClick}>
							<Pin />
						</AdvancedMarker>
					</>
				)}
			</Map>
		</div>
	);
};

export default React.memo(MapWithSingleMarker);
