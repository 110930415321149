import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { COLLECTIONS, db } from "../../../../../../../../config/firebase";
import { SERVICE_STATUS } from "../../../../../../../../utils/constants";

const NoOfStops = ({ serviceDate, routeId }) => {
	const [stops, setStops] = useState(0);
	useEffect(() => {
		let unsubscribe = null;
		try {
			const date = new Date(serviceDate);
			const serviceDateString = typeof serviceDate === "string" ? serviceDate : date.toISOString();
			console.log({ serviceDateString });
			unsubscribe = onSnapshot(
				doc(db, COLLECTIONS.routes, routeId, COLLECTIONS.dailyRoutes, serviceDateString),
				async (snap) => {
					if (snap.exists()) {
						const currentWaypoints = snap.data()?.waypoints ?? [];
						const tempWaypoints = [];
						const jobs = await currentWaypoints.map(async (waypoint) => {
							if (waypoint?.serviceId) {
								const serviceRes = await getDoc(doc(db, COLLECTIONS.scheduledServices, waypoint.serviceId));
								if (
									serviceRes.exists() &&
									serviceRes.data()?.status !== SERVICE_STATUS.CANCELLED &&
									serviceRes.data()?.status !== SERVICE_STATUS.DELETED
								) {
									tempWaypoints.push(waypoint);
								}
							}
						});
						await Promise.all(jobs);
						setStops(tempWaypoints.length);
					}
				}
			);
		} catch (error) {
			console.log(error);
		}

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	return stops;
};

export default NoOfStops;
