import React, { useEffect, useState, useMemo, useCallback, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import Loader from "../../../../../components/UI/loaders/Loader";
import { useBreadcrumb } from "../../../../../context/BreadcrumbContext";
import { PiListBold } from "react-icons/pi";
import { FiMapPin, FiSearch } from "react-icons/fi";
import MapWithMarkers from "../../../../../components/maps/MapWithMarkers";
import {
	frequencyPrimaryOptions,
	frequencySecondaryOptions,
	generatorStatus,
	serviceTypes,
} from "../../../../../utils/constants";
import { useGeocoding } from "../../../../../hooks/useGeocoding";
import GenreratorsList from "./components/GenreratorsList";
import { useDispatch, useSelector } from "react-redux";
import {
	startGeneratorListener,
	stopGeneratorListener,
	allGenerators,
	isLoading,
	hasError,
} from "../../../../../store/sliceReducers/generatorSlice";
import { auth } from "../../../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAllRoutes } from "../../../../../utils/firebaseOperations";
import { groupGeneratorsByParent } from "../../../../../utils/helpers";
import { log } from "handlebars";
import { use } from "react";
import CustomAzureGeneratorsMapView from "../../../../../components/maps/CustomAzureGeneratorsMapView";
import { AzureMapsProvider } from "react-azure-maps";
import useTUserContext from "../../../../../context/TransporterUserContext";

const usePersistedState = (key, defaultValue) => {
	const [state, setState] = useState(() => {
		const storedValue = localStorage.getItem(key);
		return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
	});

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);

	return [state, setState];
};

const serviceFreqValues = [
	"all",
	...frequencyPrimaryOptions?.map((item) => (item.value == "MTWM" ? "MTW" : item.value)),
	...frequencySecondaryOptions?.map((item) => item.value),
	"Unscheduled",
];
const serviceFreqOpts = [
	{
		label: "All",
		value: "all",
	},
	...frequencyPrimaryOptions?.map((item) =>
		item.value == "MTWM" ? { label: "MTW", value: "MTW" } : { label: item.value, value: item.value }
	),
	...frequencySecondaryOptions?.map((item) => ({ label: item.value, value: item.value })),
	{
		label: "Unscheduled",
		value: "Unscheduled",
	},
];

const Generators = ({ viewOption }) => {
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = usePersistedState("generators/searchQuery", "");
	const [selectedServiceTypes, setSelectedServiceTypes] = usePersistedState("generators/serviceTypes", [
		"all",
		...serviceTypes.map((item) => item.value),
	]);
	const [selectedContractorTypes, setSelectedContractorTypes] = usePersistedState("generators/contractorTypes", [
		"all",
		"contractors",
		"subContractors",
	]);
	const [selectedGeneratorStatus, setSelectedGeneratorStatus] = usePersistedState("generators/generatorStatus", [
		"all",
		...generatorStatus.map((item) => item.value),
	]);
	const [selectedServiceFrequencies, setSelectedServiceFrequencies] = usePersistedState(
		"generators/serviceFrequencies",
		serviceFreqValues
	);
	const [selectedRoutes, setSelectedRoutes] = usePersistedState("generators/routes", []);
	const [routesMap, setRoutesMap] = useState([]);
	const [isAddressesLoaded, setIsAddressesLoaded] = useState(false);
	const { handleSetBreadcrumb } = useBreadcrumb();
	const [routeOptions, setRouteOptions] = useState([]);
	const [selectedGenerators, setSelectedGenerators] = useState([]);
	const { batchGeocodeAddresses, batchGeocodeGeneratorsAddresses, calculateCenterPosition } = useGeocoding();
	const [geocodedMarkers, setGeocodedMarkers] = useState([]);
	const generators = useSelector(allGenerators);
	const genLoading = useSelector(isLoading);
	const error = useSelector(hasError);
	const [isPending, startTransition] = useTransition();
	const [filteredData, setFilteredData] = useState([]);
	const [mapGenData, setMapGenData] = useState([]);
	const [groupedGenerators, setGroupedGenerators] = useState([]);
	const { user, loading } = useTUserContext();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!viewOption) {
			return navigate("/admin/generators/list");
		}
	}, [navigate]);

	useEffect(() => {
		if (!loading && user?.uid) dispatch(startGeneratorListener(user?.uid));
		return () => dispatch(stopGeneratorListener());
	}, [dispatch, loading, user]);

	const fetchAllRoutesOptions = async () => {
		try {
			const resp = await getAllRoutes(user?.uid);
			const options = [];
			let map = {};
			resp.map((item) => {
				options.push({
					label: item.routeLabel,
					value: item.id,
				});
				map[item.id] = item.routeLabel;
			});
			setRoutesMap(map);
			setRouteOptions([{ label: "All", value: "all" }, ...options]);

			setSelectedRoutes(["all", ...options.map((item) => item.value)]);
		} catch (error) {
			console.log("Error fetching routes", error);
		}
	};

	useEffect(() => {
		if (!loading && user) fetchAllRoutesOptions();
	}, [loading]);

	const normalizedSearchQuery = searchQuery.replace(/\s+/g, "").toLowerCase();
	const hasIntersection = (arr1, arr2) => {
		if (arr1.includes("all")) return true;
		console.log("====================================");
		console.log({ arr1, arr2 });
		console.log("====================================");
		return arr1.some((item) => arr2.includes(item));
	};

	console.log("====================================");
	console.log({ generators, geocodedMarkers });
	console.log("====================================");

	useEffect(() => {
		startTransition(() => {
			let matchedGenerators = generators.filter((data) =>
				[data.name, data.phone, data.email, data.address, data.octoNumber, data.internalAccountNumber].some((field) =>
					field?.toString()?.toLowerCase().replace(/\s+/g, "").includes(normalizedSearchQuery)
				)
			);

			console.log({ newLength: matchedGenerators.length });

			matchedGenerators = matchedGenerators.filter(
				(item) =>
					hasIntersection(selectedServiceTypes, item.serviceType) ||
					selectedServiceTypes.includes(item.serviceType) ||
					(Array.isArray(item.serviceType) && item.serviceType.length === 0)
			);

			console.log({ newLength: matchedGenerators.length });

			matchedGenerators = matchedGenerators.filter(
				(item) =>
					selectedGeneratorStatus.includes(item.generatorStatus) || item.generatorStatus === "" || !item.generatorStatus
			);

			console.log({ newLength: matchedGenerators.length });

			matchedGenerators = matchedGenerators.filter((item) =>
				hasIntersection(selectedServiceFrequencies, item.serviceFrequency)
			);

			if (!selectedRoutes.includes("all")) {
				matchedGenerators = matchedGenerators.filter((item) => hasIntersection(selectedRoutes, item.routes));
			}

			console.log({ newLength: matchedGenerators.length });

			const matchedParentIds = new Set(matchedGenerators.map((gen) => gen.parentAccount).filter(Boolean));

			const filteredParents = generators.filter(
				(gen) => matchedParentIds.has(gen.id) && matchedGenerators.includes(gen)
			);

			const finalDataFiltered = [...matchedGenerators, ...filteredParents];
			setFilteredData([...new Map(finalDataFiltered.map((item) => [item.id, item])).values()]);
		});
	}, [
		generators,
		normalizedSearchQuery,
		selectedServiceTypes,
		selectedGeneratorStatus,
		selectedServiceFrequencies,
		selectedRoutes,
	]);

	useEffect(() => {
		const orderedGenerators = groupGeneratorsByParent(generators);
		setGroupedGenerators(orderedGenerators);
	}, [generators]);

	const handleCheckboxChange = (id) => {
		if (id === "select_all") {
			setSelectedGenerators(filteredData.map((item) => item.id));
			return;
		}

		if (id === "deselect_all") {
			setSelectedGenerators([]);
			return;
		}

		const generator = generators.find((g) => g.id === id);

		if (generator) {
			if (!generator.parentAccount) {
				const childIds = generators.filter((g) => g.parentAccount === id).map((g) => g.id);

				if (selectedGenerators.includes(id)) {
					setSelectedGenerators((prev) =>
						prev.filter((selectedId) => selectedId !== id && !childIds.includes(selectedId))
					);
				} else {
					setSelectedGenerators((prev) => [...new Set([...prev, id, ...childIds])]);
				}
			} else {
				setSelectedGenerators((prev) =>
					prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
				);
			}
		}
	};

	useEffect(() => {
		if (selectedGenerators.length > 0) {
			setSelectedGenerators([]);
		}
	}, [filteredData]);

	const handleRowClick = (data) => {
		window.open(`/admin/generators/${data.id}`, "_blank");
	};

	const geocodeAddresses = useCallback(
		async (generatorsData) => {
			console.log({ generatorsData, filteredData });
			if (generatorsData.length > 0) {
				const markers = await batchGeocodeGeneratorsAddresses(generatorsData);
				setGeocodedMarkers(markers);
				setIsAddressesLoaded(true);
			} else {
				setGeocodedMarkers([]);
				setIsAddressesLoaded(true);
			}
		},
		[filteredData]
	);

	useEffect(() => {
		log("filteredData", filteredData);
		if (viewOption === "map" && generators.length > 0) {
			geocodeAddresses(filteredData);
		} else {
			// setGeocodedMarkers([]);
			// setIsAddressesLoaded(true);
		}
	}, [navigate, filteredData]);

	const handleSelectedOptionsChange = (selected, type) => {
		if (type == "serviceType") setSelectedServiceTypes(selected);
		if (type == "generatorStatus") setSelectedGeneratorStatus(selected);
		if (type == "contractorType") setSelectedContractorTypes(selected);
		if (type == "serviceFrequency") setSelectedServiceFrequencies(selected);
		if (type == "route") setSelectedRoutes(selected);
	};

	if (!viewOption) return <Loader />;

	return (
		<div
			style={{ height: selectedGenerators.length > 0 ? `calc(100% - 40px)` : `100%` }}
			className={`main-container flex flex-col border text-xs xl:text-sm border-cardBorderCol bg-white rounded-cardRadii`}
		>
			<FilterComponent
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				filteredData={filteredData}
				selectedServiceFrequencies={selectedServiceFrequencies}
				selectedServiceTypes={selectedServiceTypes}
				selectedContractorTypes={selectedContractorTypes}
				selectedRoutes={selectedRoutes}
				handleSelectedOptionsChange={handleSelectedOptionsChange}
				selectedGeneratorStatus={selectedGeneratorStatus}
				viewOption={viewOption}
				routeOptions={routeOptions}
				generators={generators}
				generatorStatus={generatorStatus}
				serviceFreqValues={serviceFreqValues}
				serviceTypes={serviceTypes}
				setSelectedContractorTypes={setSelectedContractorTypes}
				setSelectedGeneratorStatus={setSelectedGeneratorStatus}
				setSelectedRoutes={setSelectedRoutes}
				setSelectedServiceFrequencies={setSelectedServiceFrequencies}
				setSelectedServiceTypes={setSelectedServiceTypes}
			/>

			<div style={{ height: `calc(100% - 80px)` }} className="w-full text-[#11142D] px-4 pb-4">
				{viewOption == "list" ? (
					<>
						{isPending ? (
							<Loader />
						) : (
							<GenreratorsList
								isLoading={genLoading}
								generators={filteredData}
								handleRowClick={handleRowClick}
								selectedGenerators={selectedGenerators}
								handleCheckboxChange={handleCheckboxChange}
								groupedGenerators={groupedGenerators}
								searchQuery={normalizedSearchQuery}
								routesMap={routesMap}
								isFilterApplied={generators?.length == filteredData?.length}
							/>
						)}
					</>
				) : (
					<>
						{isAddressesLoaded ? (
							<AzureMapsProvider>
								<CustomAzureGeneratorsMapView
									routesMap={routesMap}
									geocodedMarkers={geocodedMarkers}
									filteredData={filteredData}
								/>
							</AzureMapsProvider>
						) : (
							<Loader />
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Generators;

const FilterComponent = ({
	searchQuery,
	setSearchQuery,
	filteredData,
	selectedServiceFrequencies,
	selectedServiceTypes,
	selectedContractorTypes,
	selectedRoutes,
	handleSelectedOptionsChange,
	selectedGeneratorStatus,
	viewOption,
	routeOptions,
	generators,
	setSelectedGeneratorStatus,
	setSelectedContractorTypes,
	setSelectedServiceTypes,
	setSelectedServiceFrequencies,
	setSelectedRoutes,
	serviceFreqValues,
	serviceTypes,
	generatorStatus,
}) => {
	const navigate = useNavigate();

	const clearAllFilters = () => {
		setSearchQuery("");
		setSelectedGeneratorStatus(["all", ...generatorStatus.map((item) => item.value)]);
		setSelectedContractorTypes(["all", "contractors", "subContractors"]);
		setSelectedServiceTypes(["all", ...serviceTypes.map((item) => item.value)]);
		setSelectedServiceFrequencies(serviceFreqValues);
		setSelectedRoutes(["all", ...routeOptions.map((item) => item.value)]);
	};

	return (
		<div className="flex px-4 py-4 gap-2 items-center justify-between">
			<div className="flex items-center gap-4 w-full max-w-sm">
				<div className="flex items-center bg-white rounded-full p-3 px-4 border border-gray-200 w-full max-w-2xl">
					<FiSearch className="text-cardTextGray opacity-50" size={20} />
					<input
						type="text"
						placeholder="Search by Name, Phone Number, Email, or Address"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						className="ml-2 bg-transparent focus:outline-none text-cardTextGray flex-1 w-full"
					/>
				</div>
				<h6 className="text-nowrap text-cardTextGray">
					{filteredData?.length} of {generators.length} Generators
				</h6>
			</div>
			<div className="flex items-center gap-2 overflow-x-auto">
				<div className="flex items-center gap-2 border-x border-borderCol px-2 h-14 relative overflow-x-auto">
					<div className="flex items-center gap-2">
						<MultiselectDropdown
							width="w-44"
							buttonText="Service Frequency"
							options={serviceFreqOpts}
							selectedOptions={selectedServiceFrequencies}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "serviceFrequency")}
						/>
						<MultiselectDropdown
							width="w-40 ml-2"
							buttonText="Route"
							options={routeOptions}
							selectedOptions={selectedRoutes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "route")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Contractor Type"
							options={[
								{ label: "All", value: "all" },
								{ label: "Contractors", value: "contractors" },
								{ label: "Sub-contractors", value: "subContractors" },
							]}
							selectedOptions={selectedContractorTypes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "contractorType")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Generator Status"
							options={[{ label: "All", value: "all" }, ...generatorStatus]}
							selectedOptions={selectedGeneratorStatus}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "generatorStatus")}
						/>
						<MultiselectDropdown
							width="w-44"
							buttonText="Service Type"
							options={[{ label: "All", value: "all" }, ...serviceTypes]}
							selectedOptions={selectedServiceTypes}
							onSelectedOptionsChange={(values) => handleSelectedOptionsChange(values, "serviceType")}
						/>
					</div>
				</div>

				<button className="px-2 text-primary text-nowrap hover:text-blue-900 py-2" onClick={clearAllFilters}>
					Clear Filters
				</button>
				<div className="flex items-center gap-2">
					<button
						className={`${
							viewOption == "list" ? "border-cardTextBlue text-cardTextBlue" : "text-cardTextGray border-borderCol"
						} flex items-center justify-between gap-3 px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out whitespace-nowrap`}
						onClick={() => navigate("/admin/generators/list")}
					>
						<span>List</span>
						<PiListBold size={16} />
					</button>
					<button
						className={`${
							viewOption == "map" ? "border-cardTextBlue text-cardTextBlue" : "text-cardTextGray border-borderCol"
						} flex items-center justify-between gap-3 px-4 py-2 rounded-lg bg-creamWhite border transition-colors hover:bg-cardTextGray hover:bg-opacity-10 duration-200 ease-in-out whitespace-nowrap`}
						onClick={() => navigate("/admin/generators/map")}
					>
						<span>Map</span>
						<FiMapPin size={16} />
					</button>
				</div>
			</div>
		</div>
	);
};
