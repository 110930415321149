import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth,signOutUser } from "../../../config/firebase";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGeneratorUser } from "../../../context/GeneratorUserContext";

const GeneratorUserAccBtn = () => {
    const [isAccDropOpen, setIsAccDropOpen] = useState(false);
    const accDropRef = useRef(null);
    const { user, loading,logout,generatorData } = useGeneratorUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (accDropRef.current && !accDropRef.current.contains(event.target)) {
                setIsAccDropOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside, true);
        document.addEventListener("touchstart", handleClickOutside, true);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
            document.removeEventListener("touchstart", handleClickOutside, true);
        };
    }, []);

    const toggleDropdown = (event) => {
        event.stopPropagation();
        setIsAccDropOpen((prev) => !prev);
    };

    const handleItemClick = async (action) => {
        setIsAccDropOpen(false);

        if (action === "logout") {
            await signOutUser();
        } else if (action === "audit_log") {
            navigate("/admin/audit");
        } else if (action === "my_profile") {
            navigate("/admin/my-settings");
        } else if (action === "transporter_profile") {
            navigate("/admin/profile");
        }
    };
    console.log("user",user);
    
    const userName = useMemo(() => {
        let name = user?.name
        return name;
    }, [user]);
    const logOutHandler = async () => {
		let toRedirectLink = `/generator-portal-auth?transporterId=${generatorData?.transporterId}`;
		logout();
		navigate(toRedirectLink);
	};

    return (
        <div className="relative" ref={accDropRef}>
            <button
                onClick={toggleDropdown}
                className="flex items-center gap-4 hover:cursor-pointer focus:outline-none"
                type="button"
                aria-expanded={isAccDropOpen}
                aria-haspopup="true"
            >
                <div className="flex items-center justify-center max-h-12 max-w-12 bg-white border border-gray-200 rounded-full">
                    <img
                        src={user?.photoURL ?? `https://api.dicebear.com/9.x/initials/png?seed=${userName}`}
                        alt="User Avatar"
                        className="rounded-full w-full h-full object-cover"
                    />
                </div>
            </button>

            <AnimatePresence>
                {isAccDropOpen && (
                    <motion.ul
                        className="absolute z-50 bg-white top-[60px] text-nowrap -right-0 min-w-full rounded-xl overflow-hidden shadow-lg"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                    >
                        {!location.pathname?.includes("market-admin") && (
                            <>
                                <div className="flex flex-col px-4 py-2">
                                    <h6 className="font-semibold text-gray-700 truncate">{userName ? userName : "Default User"}</h6>
                                    <p className="text-sm text-cardTextGray truncate">{user?.emailAdd ?? "user@test.com"}</p>
                                </div>
                            
                            </>
                        )}
                        <li>
                            <button
                                onClick={() => logOutHandler()}
                                className="w-full text-left px-4 py-1.5 text-gray-700 hover:bg-lightBlue hover:text-white"
                            >
                                Logout
                            </button>
                        </li>
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
};

export default GeneratorUserAccBtn;
