const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { COLLECTIONS, db, storage } from "../../config/firebase";
import { editTransportorDetails } from "../../utils/firebaseOperations";
import Input from "../../components/UI/Input";
import Dropdown from "../../components/UI/dropdowns/Dropdown";
import Button from "../../components/UI/Button";
import Textarea from "../transporters/TextBox";
import PhoneInput from "../../components/UI/PhoneInput";
import FileUpload from "../transporters/UploadFiles";
import { auth } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Checkbox from "../../components/UI/Checkbox";
import Radio from "../../components/UI/Radiobtn";
import CustomDatePicker from "../transporters/TransporterDatePicker";
import AddressForm from "../transporters/Form";
import MiniTreatmentRegModal from "../transporters/TreatmentModal";
import { getAllTreatment } from "../../utils/firebaseOperations";
import UrlInput from "../../components/UI/UrlInput";
import { GetTransporterDataByUID } from "../../utils/firebaseOperations";
import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { useGeocoding } from "../../hooks/useGeocoding"; // Assuming the geocoding hook is imported
import BusinessHours from "../transporters/BusinessHours";
import useTUserContext from "../../context/TransporterUserContext";

const Profile = () => {
	const navigate = useNavigate();
	const { user, loading } = useTUserContext();
	const [transporterData, setTransporterData] = useState();
	const [isFormModified, setIsFormModified] = useState(false);

	// Default form values
	const defaultValues = {
		companyName: "",
		phoneNumber: "",
		registration1: "",
		website: "",
		dot: "",
		workingHours: {
			timeZone: "",
			Monday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
			Tuesday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
			Wednesday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
			Thursday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
			Friday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
			Saturday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
			Sunday: { open: "", lunchStart: "", lunchEnd: "", close: "", closed: false, required: false },
		},
		transporterStatus: "Active",
		reviewLink: "",
		entityId: "",
		sic: "",
		cageCode: "",
		companyDisplayName: "",
		generalEmail: "",
		registration2: "",
		companyLogo: null,
		startOfFiscalYear: "",
		ein: "",
		epaId: "",
		sos: "",
		naicsCode: "",
		shortDescription: "",
		companyMissionStatement: "",
		bio: "",
		aboutEmail: "",
		existingUser: "",
		serviceOffered: {
			medicalWasteTransporter: false,
			medicalWasteTransferStation: false,
			medicalWasteTreatmentFacilities: false,
			rollOffContainer: false,
			offsitePaperShredding: false,
			onsitePaperShredding: false,
			solidWaste: false,
			recycling: false,
		},
		optedForOctoConnect: true,
		dispatchEmail: "",
		dispatchPhone: "",
		billingAddress: { name: "", street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
		serviceAddress: { name: "", street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
	};

	// Use react-hook-form's `useForm`
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		getValues,
		trigger,
		setValue,
	} = useForm({ defaultValues });

	const [billingCopyChecked, setBillingCopyChecked] = useState(false);
	const [serviceCopyChecked, setServiceCopyChecked] = useState(false);

	// Watch form values for billing and service address
	const watchBillingAddress = watch("billingAddress") || {};
	const watchServiceAddress = watch("serviceAddress") || {};
	const watchCompanyLogo = watch("companyLogo") || {};
	const watchWorkingHours = watch("workingHours") || {};
	const watchExistingUser = watch("existingUser") || {};
	const watchTransporterStatus = watch("transporterStatus") || {};
	const watchOptedForOctoConnect = watch("optedForOctoConnect") || {};

	// Geocoding
	const { batchGeocodeGeneratorsAddresses } = useGeocoding(); // Geocoding hook
	const [serviceAddCoordinates, setServiceAddCoordinates] = useState(null);
	const [billingAddCoordinates, setBillingAddCoordinates] = useState(null);

	const handleCopyAddress = (isChecked, copyFrom) => {
		if (copyFrom === "service" && isChecked) {
			Object.keys(watchBillingAddress).forEach((key) => {
				setValue(`serviceAddress.${key}`, watchBillingAddress[key]);
			});
		} else if (copyFrom === "billing" && isChecked) {
			Object.keys(watchServiceAddress).forEach((key) => {
				setValue(`billingAddress.${key}`, watchServiceAddress[key]);
			});
		}
	};

	const geocodeAddresses = async () => {
		try {
			// Geocode service address
			if (watchServiceAddress.street && watchServiceAddress.city && watchServiceAddress.state) {
				const serviceMarkers = await batchGeocodeGeneratorsAddresses([
					{ name: watchServiceAddress.name || "Service Address", serviceAddress: watchServiceAddress },
				]);

				if (serviceMarkers && serviceMarkers[0]) {
					setServiceAddCoordinates({
						lat: serviceMarkers[0].lat,
						lng: serviceMarkers[0].lng,
					});
				}
			}

			// Geocode billing address
			if (watchBillingAddress.street && watchBillingAddress.city && watchBillingAddress.state) {
				const billingMarkers = await batchGeocodeGeneratorsAddresses([
					{ name: watchBillingAddress.name || "Billing Address", serviceAddress: watchBillingAddress },
				]);

				if (billingMarkers && billingMarkers[0]) {
					setBillingAddCoordinates({
						lat: billingMarkers[0].lat,
						lng: billingMarkers[0].lng,
					});
				}
			}
		} catch (error) {
			console.error("Geocoding error:", error);
			toast.error("Error getting location coordinates");
		}
	};

	useEffect(() => {
		geocodeAddresses();
	}, [
		watchServiceAddress.street,
		watchServiceAddress.city,
		watchServiceAddress.state,
		watchServiceAddress.zipCode,
		watchBillingAddress.street,
		watchBillingAddress.city,
		watchBillingAddress.state,
		watchBillingAddress.zipCode,
	]);

	const sanitizeData = (data) => {
		// Sanitize data to replace undefined with null
		Object.keys(data).forEach((key) => {
			if (data[key] === undefined) {
				data[key] = null;
			} else if (typeof data[key] === "object" && data[key] !== null) {
				data[key] = sanitizeData(data[key]); // Recursively sanitize nested objects
			}
		});
		return data;
	};

	// Watch to detect changes
	const companyName = watch("companyName");
	const phoneNumber = watch("phoneNumber");
	const registration1 = watch("registration1");
	const website = watch("website");
	const dot = watch("dot");
	const reviewLink = watch("reviewLink");
	const entityId = watch("entityId");
	const sic = watch("sic");
	const cageCode = watch("cageCode");
	const companyDisplayName = watch("companyDisplayName");
	const generalEmail = watch("generalEmail");
	const registration2 = watch("registration2");
	const startOfFiscalYear = watch("startOfFiscalYear");
	const ein = watch("ein");
	const epaId = watch("epaId");
	const sos = watch("sos");
	const naicsCode = watch("naicsCode");
	const shortDescription = watch("shortDescription");
	const companyMissionStatement = watch("companyMissionStatement");
	const bio = watch("bio");
	const aboutEmail = watch("aboutEmail");
	const dispatchEmail = watch("dispatchEmail");
	const dispatchPhone = watch("dispatchPhone");
	const watchServiceOffered = watch("serviceOffered") || {};
	
	useEffect(() => {
		// Log the company logo objects to debug
		console.log("transporterData?.companyLogo:", transporterData?.companyLogo);
		console.log("watchCompanyLogo:", watchCompanyLogo);
		
		// Processing values for second useEffect comparison
		const existingUserValue = String(transporterData?.existingUser ?? "").trim();
		const watchExistingUserValue = typeof watchExistingUser === "object" ? "" : String(watchExistingUser ?? "").trim();
	
		const transporterStatusValue = String(transporterData?.transporterStatus ?? "Active").trim();
		const watchTransporterStatusValue =
			typeof watchTransporterStatus === "object" ? "" : String(watchTransporterStatus ?? "").trim();
	
		// Fix for Company Logo Comparison - using name comparison
		let companyLogoValue = transporterData?.companyLogo?.name || "";
		let companyLogoLink = transporterData?.companyLogo?.link || "";
		let watchCompanyLogoValue = "";
		
		// Check if a file has been uploaded
		if (watchCompanyLogo instanceof File) {
			watchCompanyLogoValue = watchCompanyLogo.name || "";
		} else if (watchCompanyLogo && watchCompanyLogo.name) {
			watchCompanyLogoValue = watchCompanyLogo.name || "";
		}
		
		// Log the values for debugging
		console.log("companyLogoValue (name):", companyLogoValue);
		console.log("watchCompanyLogoValue (name):", watchCompanyLogoValue);
		
		// Check if the company logo has been modified by comparing names
		const isCompanyLogoModified = (companyLogoValue !== watchCompanyLogoValue && watchCompanyLogoValue !== "");
	
		const optedForOctoConnectValue = transporterData?.optedForOctoConnect ?? false;
		const watchOptedForOctoConnectValue =
			typeof watchOptedForOctoConnect === "object" ? false : watchOptedForOctoConnect;
	
		// Second useEffect comparison logic
		const isModifiedFromSecondEffect =
			(watchExistingUserValue !== existingUserValue && watchExistingUserValue !== "") ||
			(watchTransporterStatusValue !== transporterStatusValue && watchTransporterStatusValue !== "") ||
			isCompanyLogoModified ||
			watchOptedForOctoConnectValue !== optedForOctoConnectValue;
	
		// First useEffect comparison logic
		const isModifiedFromFirstEffect =
			startOfFiscalYear !== transporterData?.startOfFiscalYear ||
			companyName !== transporterData?.companyName ||
			phoneNumber !== transporterData?.phoneNumber ||
			registration1 !== transporterData?.registration1 ||
			website !== transporterData?.website ||
			dot !== transporterData?.dot ||
			reviewLink !== transporterData?.reviewLink ||
			entityId !== transporterData?.entityId ||
			sic !== transporterData?.sic ||
			cageCode !== transporterData?.cageCode ||
			companyDisplayName !== transporterData?.companyDisplayName ||
			generalEmail !== transporterData?.generalEmail ||
			registration2 !== transporterData?.registration2 ||
			ein !== transporterData?.ein ||
			epaId !== transporterData?.epaId ||
			sos !== transporterData?.sos ||
			naicsCode !== transporterData?.naicsCode ||
			shortDescription !== transporterData?.shortDescription ||
			companyMissionStatement !== transporterData?.companyMissionStatement ||
			bio !== transporterData?.bio ||
			aboutEmail !== transporterData?.aboutEmail ||
			dispatchEmail !== transporterData?.dispatchEmail ||
			dispatchPhone !== transporterData?.dispatchPhone ||
			watchBillingAddress.name !== transporterData?.billingAddress?.name ||
			watchBillingAddress.street !== transporterData?.billingAddress?.street ||
			watchBillingAddress.suite !== transporterData?.billingAddress?.suite ||
			watchBillingAddress.city !== transporterData?.billingAddress?.city ||
			watchBillingAddress.state !== transporterData?.billingAddress?.state ||
			watchBillingAddress.zipCode !== transporterData?.billingAddress?.zipCode ||
			watchBillingAddress.email !== transporterData?.billingAddress?.email ||
			watchBillingAddress.phone !== transporterData?.billingAddress?.phone ||
			watchServiceAddress.name !== transporterData?.serviceAddress?.name ||
			watchServiceAddress.street !== transporterData?.serviceAddress?.street ||
			watchServiceAddress.suite !== transporterData?.serviceAddress?.suite ||
			watchServiceAddress.city !== transporterData?.serviceAddress?.city ||
			watchServiceAddress.state !== transporterData?.serviceAddress?.state ||
			watchServiceAddress.zipCode !== transporterData?.serviceAddress?.zipCode ||
			watchServiceAddress.email !== transporterData?.serviceAddress?.email ||
			watchServiceAddress.phone !== transporterData?.serviceAddress?.phone ||
			watchServiceOffered.medicalWasteTransporter !== transporterData?.serviceOffered?.medicalWasteTransporter ||
			watchServiceOffered.medicalWasteTransferStation !==
			transporterData?.serviceOffered?.medicalWasteTransferStation ||
			watchServiceOffered.medicalWasteTreatmentFacilities !==
			transporterData?.serviceOffered?.medicalWasteTreatmentFacilities ||
			watchServiceOffered.rollOffContainer !== transporterData?.serviceOffered?.rollOffContainer ||
			watchServiceOffered.offsitePaperShredding !== transporterData?.serviceOffered?.offsitePaperShredding ||
			watchServiceOffered.onsitePaperShredding !== transporterData?.serviceOffered?.onsitePaperShredding ||
			watchServiceOffered.solidWaste !== transporterData?.serviceOffered?.solidWaste ||
			watchServiceOffered.recycling !== transporterData?.serviceOffered?.recycling ||
			watchWorkingHours.Monday?.open !== transporterData?.workingHours?.Monday?.open ||
			watchWorkingHours.Monday?.lunchStart !== transporterData?.workingHours?.Monday?.lunchStart ||
			watchWorkingHours.Monday?.lunchEnd !== transporterData?.workingHours?.Monday?.lunchEnd ||
			watchWorkingHours.Monday?.close !== transporterData?.workingHours?.Monday?.close ||
			watchWorkingHours.Monday?.closed !== transporterData?.workingHours?.Monday?.closed ||
			watchWorkingHours.Monday?.required !== transporterData?.workingHours?.Monday?.required ||
			watchWorkingHours.Tuesday?.open !== transporterData?.workingHours?.Tuesday?.open ||
			watchWorkingHours.Tuesday?.lunchStart !== transporterData?.workingHours?.Tuesday?.lunchStart ||
			watchWorkingHours.Tuesday?.lunchEnd !== transporterData?.workingHours?.Tuesday?.lunchEnd ||
			watchWorkingHours.Tuesday?.close !== transporterData?.workingHours?.Tuesday?.close ||
			watchWorkingHours.Tuesday?.closed !== transporterData?.workingHours?.Tuesday?.closed ||
			watchWorkingHours.Tuesday?.required !== transporterData?.workingHours?.Tuesday?.required ||
			watchWorkingHours.Wednesday?.open !== transporterData?.workingHours?.Wednesday?.open ||
			watchWorkingHours.Wednesday?.lunchStart !== transporterData?.workingHours?.Wednesday?.lunchStart ||
			watchWorkingHours.Wednesday?.lunchEnd !== transporterData?.workingHours?.Wednesday?.lunchEnd ||
			watchWorkingHours.Wednesday?.close !== transporterData?.workingHours?.Wednesday?.close ||
			watchWorkingHours.Wednesday?.closed !== transporterData?.workingHours?.Wednesday?.closed ||
			watchWorkingHours.Wednesday?.required !== transporterData?.workingHours?.Wednesday?.required ||
			watchWorkingHours.Thursday?.open !== transporterData?.workingHours?.Thursday?.open ||
			watchWorkingHours.Thursday?.lunchStart !== transporterData?.workingHours?.Thursday?.lunchStart ||
			watchWorkingHours.Thursday?.lunchEnd !== transporterData?.workingHours?.Thursday?.lunchEnd ||
			watchWorkingHours.Thursday?.close !== transporterData?.workingHours?.Thursday?.close ||
			watchWorkingHours.Thursday?.closed !== transporterData?.workingHours?.Thursday?.closed ||
			watchWorkingHours.Thursday?.required !== transporterData?.workingHours?.Thursday?.required ||
			watchWorkingHours.Friday?.open !== transporterData?.workingHours?.Friday?.open ||
			watchWorkingHours.Friday?.lunchStart !== transporterData?.workingHours?.Friday?.lunchStart ||
			watchWorkingHours.Friday?.lunchEnd !== transporterData?.workingHours?.Friday?.lunchEnd ||
			watchWorkingHours.Friday?.close !== transporterData?.workingHours?.Friday?.close ||
			watchWorkingHours.Friday?.closed !== transporterData?.workingHours?.Friday?.closed ||
			watchWorkingHours.Friday?.required !== transporterData?.workingHours?.Friday?.required ||
			watchWorkingHours.Saturday?.open !== transporterData?.workingHours?.Saturday?.open ||
			watchWorkingHours.Saturday?.lunchStart !== transporterData?.workingHours?.Saturday?.lunchStart ||
			watchWorkingHours.Saturday?.lunchEnd !== transporterData?.workingHours?.Saturday?.lunchEnd ||
			watchWorkingHours.Saturday?.close !== transporterData?.workingHours?.Saturday?.close ||
			watchWorkingHours.Saturday?.closed !== transporterData?.workingHours?.Saturday?.closed ||
			watchWorkingHours.Saturday?.required !== transporterData?.workingHours?.Saturday?.required ||
			watchWorkingHours.Sunday?.open !== transporterData?.workingHours?.Sunday?.open ||
			watchWorkingHours.Sunday?.lunchStart !== transporterData?.workingHours?.Sunday?.lunchStart ||
			watchWorkingHours.Sunday?.lunchEnd !== transporterData?.workingHours?.Sunday?.lunchEnd ||
			watchWorkingHours.Sunday?.close !== transporterData?.workingHours?.Sunday?.close ||
			watchWorkingHours.Sunday?.closed !== transporterData?.workingHours?.Sunday?.closed ||
			watchWorkingHours.Sunday?.required !== transporterData?.workingHours?.Sunday?.required;
	
		// Combined result
		const isFormModifiedResult = isModifiedFromFirstEffect || isModifiedFromSecondEffect;
		console.log("Is Company Logo Modified:", isCompanyLogoModified);
		console.log("Is Form Modified:", isFormModifiedResult);
	
		setIsFormModified(isFormModifiedResult);
	}, [
		// Dependencies from first useEffect
		startOfFiscalYear,
		companyName,
		phoneNumber,
		registration1,
		website,
		dot,
		reviewLink,
		watchWorkingHours,
		entityId,
		sic,
		cageCode,
		companyDisplayName,
		generalEmail,
		registration2,
		ein,
		epaId,
		sos,
		naicsCode,
		shortDescription,
		companyMissionStatement,
		bio,
		aboutEmail,
		dispatchEmail,
		dispatchPhone,
		watchBillingAddress,
		transporterData?.companyName,
		transporterData?.phoneNumber,
		transporterData?.registration1,
		transporterData?.website,
		transporterData?.dot,
		transporterData?.reviewLink,
		transporterData?.entityId,
		transporterData?.sic,
		transporterData?.cageCode,
		transporterData?.companyDisplayName,
		transporterData?.generalEmail,
		transporterData?.registration2,
		transporterData?.ein,
		transporterData?.epaId,
		transporterData?.sos,
		transporterData?.naicsCode,
		transporterData?.shortDescription,
		transporterData?.companyMissionStatement,
		transporterData?.bio,
		transporterData?.aboutEmail,
		transporterData?.dispatchEmail,
		transporterData?.dispatchPhone,
		transporterData?.watchBillingAddress,
		watchBillingAddress.name,
		watchBillingAddress.street,
		watchBillingAddress.suite,
		watchBillingAddress.city,
		watchBillingAddress.state,
		watchBillingAddress.zipCode,
		watchBillingAddress.email,
		watchBillingAddress.phone,
		watchServiceAddress.name,
		watchServiceAddress.street,
		watchServiceAddress.suite,
		watchServiceAddress.city,
		watchServiceAddress.state,
		watchServiceAddress.zipCode,
		watchServiceAddress.email,
		watchServiceAddress.phone,
		watchServiceOffered.medicalWasteTransporter,
		watchServiceOffered.medicalWasteTransferStation,
		watchServiceOffered.medicalWasteTreatmentFacilities,
		watchServiceOffered.rollOffContainer,
		watchServiceOffered.offsitePaperShredding,
		watchServiceOffered.onsitePaperShredding,
		watchServiceOffered.solidWaste,
		watchServiceOffered.recycling,
		watchWorkingHours.Monday?.open,
		watchWorkingHours.Monday?.lunchStart,
		watchWorkingHours.Monday?.lunchEnd,
		watchWorkingHours.Monday?.close,
		watchWorkingHours.Monday?.closed,
		watchWorkingHours.Monday?.required,
		watchWorkingHours.Tuesday?.open,
		watchWorkingHours.Tuesday?.lunchStart,
		watchWorkingHours.Tuesday?.lunchEnd,
		watchWorkingHours.Tuesday?.close,
		watchWorkingHours.Tuesday?.closed,
		watchWorkingHours.Tuesday?.required,
		watchWorkingHours.Wednesday?.open,
		watchWorkingHours.Wednesday?.lunchStart,
		watchWorkingHours.Wednesday?.lunchEnd,
		watchWorkingHours.Wednesday?.close,
		watchWorkingHours.Wednesday?.closed,
		watchWorkingHours.Wednesday?.required,
		watchWorkingHours.Thursday?.open,
		watchWorkingHours.Thursday?.lunchStart,
		watchWorkingHours.Thursday?.lunchEnd,
		watchWorkingHours.Thursday?.close,
		watchWorkingHours.Thursday?.closed,
		watchWorkingHours.Thursday?.required,
		watchWorkingHours.Friday?.open,
		watchWorkingHours.Friday?.lunchStart,
		watchWorkingHours.Friday?.lunchEnd,
		watchWorkingHours.Friday?.close,
		watchWorkingHours.Friday?.closed,
		watchWorkingHours.Friday?.required,
		watchWorkingHours.Saturday?.open,
		watchWorkingHours.Saturday?.lunchStart,
		watchWorkingHours.Saturday?.lunchEnd,
		watchWorkingHours.Saturday?.close,
		watchWorkingHours.Saturday?.closed,
		watchWorkingHours.Saturday?.required,
		watchWorkingHours.Sunday?.open,
		watchWorkingHours.Sunday?.lunchStart,
		watchWorkingHours.Sunday?.lunchEnd,
		watchWorkingHours.Sunday?.close,
		watchWorkingHours.Sunday?.closed,
		watchWorkingHours.Sunday?.required,
		watchExistingUser,
		watchTransporterStatus,
		watchCompanyLogo,
		watchOptedForOctoConnect,
		transporterData,
	]);

	const onSubmit = async (data) => {
		console.log("Submitting data:", data);
		try {
			const submittingToast = toast.loading("Submitting...");

			// Check if user ID exists
			if (!user?.uid && !loading) {
				toast.error("User ID not found. Please refresh the page.");
				toast.dismiss(submittingToast);
				return;
			}

			// Validate addresses if filled (billing and service addresses)
			const isBillingAddressFilled = Object.values(data.billingAddress || {}).some(
				(value) => value && value.trim && value.trim() !== ""
			);
			const isServiceAddressFilled = Object.values(data.serviceAddress || {}).some(
				(value) => value && value.trim && value.trim() !== ""
			);

			if (isBillingAddressFilled) {
				const isBillingValid = await trigger("billingAddress");
				if (!isBillingValid) {
					toast.error("Please fill all required fields in the billing address.");
					toast.dismiss(submittingToast);
					return;
				}
			}

			if (isServiceAddressFilled) {
				const isServiceValid = await trigger("serviceAddress");
				if (!isServiceValid) {
					toast.error("Please fill all required fields in the service address.");
					toast.dismiss(submittingToast);
					return;
				}
			}

			// Extract serviceOffered and companyLogo, while retaining other fields from data
			const { serviceOffered = {}, companyLogo, ...restData } = data;

			// Remove timestamp fields from the existing data
			const { createdAt, lastUpdated, ...cleanRestData } = restData;

			const transporterDataToSave = {
				...cleanRestData,
				contractedGens: transporterData?.contractedGens || [],
				subContractedGens: transporterData?.subContractedGens || [],
				generators: transporterData?.generators || [],
				connections: transporterData?.connections || {},
				notifications: transporterData?.notifications || [],
				contacts: transporterData?.contacts || [],
				serviceOffered: {
					medicalWasteTransporter: false,
					medicalWasteTransferStation: false,
					medicalWasteTreatmentFacilities: false,
					rollOffContainer: false,
					offsitePaperShredding: false,
					onsitePaperShredding: false,
					solidWaste: false,
					recycling: false,
					...serviceOffered,
				},
				transporterStatus: cleanRestData.transporterStatus || "Active",
				optedForOctoConnect: cleanRestData.optedForOctoConnect ?? true,
				existingUser: cleanRestData.existingUser || "",
			};

			// Handle address copy logic if checkboxes are checked
			const copyDetails = (source, target) => {
				const copyFields = ["name", "street", "suite", "city", "state", "zip", "email", "phone"];
				copyFields.forEach((field) => {
					transporterDataToSave[`${target}${field.charAt(0).toUpperCase() + field.slice(1)}`] =
						cleanRestData[`${source}${field.charAt(0).toUpperCase() + field.slice(1)}`];
				});
			};

			if (billingCopyChecked) copyDetails("service", "billing");
			if (serviceCopyChecked) copyDetails("billing", "service");

			// Handle company logo upload if a new logo is provided
			if (companyLogo && companyLogo instanceof File) {
				const token = Math.random().toString(36).substring(2);
				const storageRef = ref(storage, `/transporters/companyLogo/${companyLogo.name}`);
				const snapshot = await uploadBytes(storageRef, companyLogo);
				const downloadURL = await getDownloadURL(snapshot.ref);

				transporterDataToSave.companyLogo = {
					name: companyLogo.name,
					link: downloadURL,
					mime: companyLogo?.type,
					path: storageRef.fullPath,
					ref: "",
					token,
				};
			}

			// Add user identification and current timestamp
			transporterDataToSave.userId = user.uid;
			transporterDataToSave.lastUpdated = serverTimestamp();

			// Keep the original createdAt timestamp if it exists
			if (transporterData?.createdAt) {
				transporterDataToSave.createdAt = transporterData.createdAt;
			}

			// Add geocoded coordinates to the final data
			transporterDataToSave.transporterServiceCoordinates = {
				lat: serviceAddCoordinates?.lat || 32.7122671,
				lng: serviceAddCoordinates?.lng || -117.0542354,
			};
			transporterDataToSave.transporterBillingCoordinates = {
				lat: billingAddCoordinates?.lat || 32.7122671,
				lng: billingAddCoordinates?.lng || -117.0542354,
			};

			// Sanitize data to remove any undefined values
			const sanitizedData = sanitizeData(transporterDataToSave);

			// Update the transporter document in the database
			const result = await editTransportorDetails(sanitizedData, user.uid);

			if (result.success) {
				toast.dismiss(submittingToast);
				toast.success("Transporter updated successfully!");
				//navigate("/admin");
			} else {
				throw new Error("Failed to update Transporter");
			}
		} catch (error) {
			console.error(error);
			toast.error(error.message || "Failed to update Transporter");
			toast.dismiss(submittingToast);
		}
	};

	const fieldsToCheck = [
		{ name: "companyName", id: "company-name-field" },
		{ name: "billingAddress", id: "billing-address-field" },
		{ name: "serviceAddress", id: "service-address-field" },
		{ name: "phoneNumber", id: "phoneNumber" },
		{ name: "companyDisplayName", id: "company-display-name-field" },
		{ name: "generalEmail", id: "generalEmail" },
		{ name: "registration1", id: "registration1" },
	];

	for (const field of fieldsToCheck) {
		if (errors[field.name]) {
			const element = document.getElementById(field.id);
			if (element) {
				// Scroll to the element with a delay
				setTimeout(() => {
					element.scrollIntoView({ behavior: "smooth", block: "center" });
				}, 200);
				break;
			}
		}
	}

	useEffect(() => {
		const fetchTransporter = () => {
			try {
				// const user = auth.currentUser;
				if (!user && loading) {
					console.log("No user logged in");
					return;
				}

				const unsubscribe = GetTransporterDataByUID(user.uid, (data) => {
					if (data) {
						console.log("Transporter data:", data);
						setTransporterData(data);

						// Update form with fetched data
						Object.keys(data).forEach((key) => {
							if (key in defaultValues) {
								setValue(key, data[key]);
							}
						});

						// Handle nested objects separately
						["serviceOffered", "billingAddress", "serviceAddress"].forEach((field) => {
							if (data[field]) {
								Object.keys(data[field]).forEach((subField) => {
									setValue(`${field}.${subField}`, data[field][subField]);
								});
							}
						});
						if (data.workingHours) {
							setValue("workingHours.timeZone", data.workingHours.timeZone || "");
							["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].forEach((day) => {
								if (data.workingHours[day]) {
									setValue(`workingHours.${day}.open`, data.workingHours[day].open || "");
									setValue(`workingHours.${day}.lunchEnd`, data.workingHours[day].lunchEnd || "");
									setValue(`workingHours.${day}.lunchStart`, data.workingHours[day].lunchStart || "");
									setValue(`workingHours.${day}.close`, data.workingHours[day].close || "");
									setValue(`workingHours.${day}.closed`, data.workingHours[day].closed || false);
									setValue(`workingHours.${day}.required`, data.workingHours[day].required || false);
								}
							});
						}
						if (!data.transporterOctoId.length) {
							updateTransporterId(user.uid);
						}
					}
				});

				// Cleanup the real-time listener when the component unmounts
				return () => unsubscribe();
			} catch (error) {
				console.error("Error fetching transporter data:", error);
				toast.error("Failed to load transporter data");
			}
		};

		if (user && !loading) fetchTransporter();
	}, [setValue, user]);

	const updateTransporterId = (id) => {
		console.log("Updating transporter ID");
		return runTransaction(db, async (transaction) => {
			let count = 1000;
			const countRes = await transaction.get(doc(db, COLLECTIONS.counters, "default"));
			if (countRes.exists() && countRes?.data()?.transporterCount) {
				count = countRes?.data()?.transporterCount ?? 1000;
			}
			const transporterRes = await transaction.get(doc(db, COLLECTIONS.transporters, id));
			count++;

			if (transporterRes.exists() && !transporterRes?.data()?.transporterOctoId) {
				transaction.set(
					doc(db, COLLECTIONS.transporters, id),
					{
						transporterOctoId: `T-${count.toString()}`,
					},
					{ merge: true }
				);
				transaction.set(
					doc(db, COLLECTIONS.counters, "default"),
					{
						transporterCount: count,
					},
					{ merge: true }
				);
			}
		});
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openAddTreatmentModal = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsModalOpen(true);
	};

	const [treatmentOptions, setTreatmentOptions] = useState([]);
	useEffect(() => {
		// Subscribe to real-time treatment updates
		if (user && !loading) {
			const unsubscribe = getAllTreatment((treatmentsData) => {
				const optionsForDropdown = treatmentsData.map(({ name }) => ({
					label: `${name}`,
					value: `${name}`,
				}));

				// Update the state with new options
				setTreatmentOptions(optionsForDropdown);
			}, user?.uid);
			return () => unsubscribe();
		}
	}, [loading, user]);

	return (
		<>
			<div className="p-6">
				<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<ToastContainer />
					<div className="my-4 ml-6 text-xl font-semibold">
						Transporter Profile ({transporterData?.transporterOctoId ?? "--"})
					</div>
					<div className="ml-6">General Information</div>
					<div className=" mt-4 ml-6 text-gray-500">(Information appears in public profile)</div>
					<div className="border-b mb-2 border-[#CCCCCC]"></div>
					<div className="mx-6">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex gap-8">
								<div className="w-1/2">
									<div id="company-name-field">
										<Controller
											name="companyName"
											control={control}
											rules={{ required: "Company Name is required" }}
											render={({ field: { value, onChange } }) => (
												<div>
													<Input
														value={value}
														onChange={onChange}
														label="Company Name*"
														className="w-full px-2 py-1 text-sm"
													/>
													{errors.companyName && (
														<p className="text-red-500 text-sm mt-1">{errors.companyName?.message}</p>
													)}
												</div>
											)}
										/>
									</div>
									<div id="company-display-name-field">
										<Controller
											name="companyDisplayName"
											control={control}
											rules={{ required: "Company Display Name is required" }}
											render={({ field: { value, onChange } }) => (
												<div>
													<Input
														value={value}
														onChange={onChange}
														label="Company Display Name*"
														className="w-full px-2 py-1 text-sm"
													/>
													{errors.companyDisplayName && (
														<p className="text-red-500 text-sm mt-1">{errors.companyDisplayName?.message}</p>
													)}
												</div>
											)}
										/>
									</div>
									<div id="phoneNumber">
										<Controller
											name="phoneNumber"
											control={control}
											rules={{ required: "Phone is required" }}
											render={({ field }) => (
												<div>
													<PhoneInput
														id="phoneNumber"
														label="General Phone*"
														value={field.value}
														onChange={field.onChange}
													/>
													{errors.phoneNumber && (
														<p className="text-red-500 text-sm mt-1">{errors.phoneNumber?.message}</p>
													)}
												</div>
											)}
										/>
									</div>
									<div id="generalEmail">
										<Controller
											name="generalEmail"
											control={control}
											rules={{
												required: "Email is required",
												pattern: {
													value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
													message: "Please enter a valid email address",
												},
											}}
											render={({ field: { value, onChange } }) => (
												<div>
													<Input
														value={value}
														onChange={onChange}
														label="General Email*"
														className="w-full px-2 py-1 text-sm"
													/>
													{errors.generalEmail && (
														<p className="text-red-500 text-sm mt-1">{errors.generalEmail?.message}</p>
													)}
												</div>
											)}
										/>
									</div>
									<Controller
										name="website"
										control={control}
										rules={{
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Please enter a valid URL",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<UrlInput
													value={value}
													onChange={onChange}
													type="url"
													label="Website URL"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
												{errors.website && <p className="text-red-500 text-sm mt-4">{errors.website?.message}</p>}
											</div>
										)}
									/>
									<div className="flex items-center justify-between mt-8">
										<label className="truncate text-inputLabel font-normal">Start of Fiscal Year</label>
										<div className="w-2/3">
											<Controller
												name="startOfFiscalYear"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CustomDatePicker
														selectedDate={value}
														setSelectedDate={onChange}
														label="Start of Fiscal Year"
													/>
												)}
											/>
										</div>
									</div>

									<Controller
										name="reviewLink"
										control={control}
										rules={{
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Please enter a valid URL",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div className="mb-8">
												<UrlInput
													value={value}
													onChange={onChange}
													type="url"
													label="Google Review Link"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
												{errors.reviewLink && <p className="text-red-500 text-sm mt-1">{errors.reviewLink?.message}</p>}
											</div>
										)}
									/>
									<div>
										<FileUpload
											control={control}
											label="Company Logo"
											errors={errors}
											existingFile={typeof transporterData?.companyLogo == "object" ? transporterData?.companyLogo : {}}
											name={"companyLogo"}
											onDeleteFile={() => console.log("Clicked to delete file")}
										/>
									</div>
									<div className="flex justify-center mt-2">
										{watchCompanyLogo.link == transporterData?.companyLogo?.link &&
											typeof transporterData?.companyLogo?.link == "string" &&
											transporterData?.companyLogo?.link?.length && (
												<div className="existing-file">
													<img
														src={transporterData.companyLogo.link ?? ""}
														alt="Company Logo"
														style={{ maxWidth: "100px", maxHeight: "100px", marginBottom: "10px" }}
													/>
												</div>
											)}
									</div>

									<div className="text-xs mt-1 text-gray-600">
										<span className="">Note:</span>{" "}
										<span className="font-light">
											(File should be a maximum of 5 MB and one of the following formats: PNG, JPG, JPEG, or SVG file.)
										</span>
									</div>

									<Controller
										name="transporterStatus"
										control={control}
										rules={{ required: "Transporter Status is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={statusOptions}
													label="Transporter Status*"
													className="text-sm"
												/>
												{errors.transporterStatus && (
													<p className="text-red-500 text-sm mt-1">{errors.transporterStatus?.message}</p>
												)}
											</div>
										)}
									/>

									<div>
										<Link
											to={`/generator-portal-auth?transporterId=${user?.uid}`}
											className="py-2 text-primary hover:underline"
											target="_blank"
										>
											Log In to Generator Portal
										</Link>
									</div>
								</div>

								<div className="w-1/2">
									<div id="registration1">
										<Controller
											name="registration1"
											control={control}
											rules={{ required: "Registration Number 1 is required" }}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<div>
													<Input
														label="Registration Number 1*"
														value={value}
														onChange={onChange}
														error={error ? error.message : null}
													/>
													{errors.registration1 && (
														<p className="text-red-500 text-sm mt-1">{errors.registration1?.message}</p>
													)}
												</div>
											)}
										/>
									</div>
									<Controller
										name="registration2"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 2" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="epaId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EPA ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="dot"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="DOT Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="ein"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EIN" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="entityId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Entity ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sos"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SOS Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sic"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SIC Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="naicsCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="NAICS Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="cageCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="CAGE Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
								</div>
							</div>
							<div id="billing-address-field">
								<div id="service-address-field">
									<AddressForm
										control={control}
										handleCopyAddress={handleCopyAddress}
										errors={errors}
										trigger={trigger}
									/>
								</div>
							</div>
							<div className="mt-4">
								<BusinessHours
									control={control}
									errors={errors}
									getValues={getValues}
									setValue={setValue}
									watch={watch}
								/>
							</div>

							<div className="mt-8 mb-2 font-medium">About</div>
							<div className="border-t"></div>
							<div className="">
								<div className="flex gap-8 w-full mt-2">
									<div className="w-1/2">
										<Controller
											name="shortDescription"
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Short Description" type="Text" value={value} onChange={onChange} />
												</div>
											)}
										/>
									</div>
								</div>

								<div className="flex gap-8 w-full ">
									<div className="w-1/2">
										<Controller
											name="bio"
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Bio" type="Text" value={value} onChange={onChange} />
												</div>
											)}
										/>
									</div>
								</div>
							</div>
							<div className="">
								<div className="mt-12 mb-4 font-medium">Services Offered</div>
								<div className="border-t"></div>
								<div className="grid gap-5 grid-cols-3 py-4 mt-2">
									<Controller
										name="serviceOffered.medicalWasteTransporter"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Medical Waste Transporter</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.medicalWasteTransferStation"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Medical Waste Transfer Station</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.medicalWasteTreatmentFacilities"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Medical Waste Treatment Facilities</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.rollOffContainer"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Roll Off Container</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.offsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Offsite Paper Shredding</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.onsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Onsite Paper Shredding</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.solidWaste"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Solid Waste</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.recycling"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Recycling</span>
											</div>
										)}
									/>

								</div>
								<div className="border-t"></div>
								<div className="justify-between gap-5 py-4 mt-2">
									<div className="flex justify-between">
										<div className="w-1/2">
											<Controller
												name="existingUser"
												control={control}
												render={({ field }) => (
													<div>
														<Dropdown
															options={treatmentOptions}
															value={field.value}
															onChange={field.onChange}
															label="Link Existing TF/TS "
															className="text-sm"
														/>
													</div>
												)}
											/>
										</div>
									</div>

									<div className="bottom-8 ">
										<button onClick={openAddTreatmentModal} className="text-blue-600  flex items-center space-x-2">
											<svg
												className="w-6 h-6"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
											</svg>
											<span className="text-gray-600">Create New TF/TS</span>
										</button>
										{isModalOpen && <MiniTreatmentRegModal onClose={() => setIsModalOpen(false)} />}
									</div>
								</div>
								<div className="py-4 font-medium">Dispatch Department</div>
								<div className="border-t"></div>
								<div className="flex gap-6 w-full">
									<div className="w-1/2">
										<Controller
											name="dispatchEmail"
											control={control}
											rules={{
												required: "Email is required",
												pattern: {
													value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
													message: "Please enter a valid email address",
												},
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<div>
													<Input label="Email*" type="text" value={value} onChange={onChange} />
													{error?.message && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
												</div>
											)}
										/>
									</div>

									<div className="w-1/2">
										<Controller
											name="dispatchPhone"
											control={control}
											rules={{ required: "Phone is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<PhoneInput label="Phone" value={value} onChange={onChange} IsRequired={true} />
													{errors.dispatchPhone && (
														<p className="text-red-500 text-sm mt-1">{errors.dispatchPhone.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>
								<div className="mt-10 mb-4 font-medium">OCTO Connect: Subcontractor Partnership Program</div>
								<div className="border-t"></div>
								<div className=" mt-4 w-10/12  text-gray-600">
									By opting into the OCTO Connect Subcontractor Partnership Program, you agree to allow contractors to
									potentially connect with you based on the proximity of their generator s address to your service
									radius. When a contractor expresses interest in partnering with you, a notification will be sent to
									inform you of the potential partnership.
								</div>

								<div className="flex py-4 gap-6">
									<Controller
										name="optedForOctoConnect"
										control={control}
										render={({ field: { value, onChange } }) => (
											<>
												<Radio
													label="Opt In"
													isChecked={value === true}
													setIsChecked={(checked) => {
														if (checked) {
															onChange(true);
														} else {
															onChange(false);
														}
													}}
												/>
												<Radio
													label="Opt Out"
													isChecked={value === false}
													setIsChecked={(checked) => {
														if (checked) {
															onChange(false);
														} else {
															onChange(true);
														}
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>
							<div>
								<div className="flex justify-end gap-8 mb-8">
									<Button
										type="button"
										btnStyle="form_nav_primary"
										text="Update"
										disabled={!isFormModified}
										className={`${!isFormModified ? "disabled:bg-cardTextGray cursor-not-allowed" : ""}`}
										onClick={() => {
											trigger().then((isValid) => {
												if (isValid) {
													handleSubmit(onSubmit)();
												} else {
													scrollToErrors();
												}
											});
										}}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
