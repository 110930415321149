import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
	where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLLECTIONS, db } from "../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../utils/constants";
import {
	dateFormatter,
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showSuccessToastMessage,
} from "../../../utils/helpers";
import Loader from "../../../components/UI/loaders/Loader";
import CustomDatePicker from "../../../components/UI/CustomDatePicker";
import ReportCheckbox from "../../admin/generator-management/components/reports/ReportCheckBox";
import DropdownReport from "../../admin/generator-management/components/reports/ReportDropDown";
import Button from "../../../components/UI/Button";
import { RiBankFill } from "react-icons/ri";
import { IoCardOutline } from "react-icons/io5";
const sortByOptions = [
	{ label: "Any", value: "any" },
	{ label: "By Date (asc)", value: "date_asc" },
	{ label: "By Date (desc)", value: "date_desc" },
	{ label: "By Generator(A-Z)", value: "generator_asc" },
	{ label: "By Generator(Z-A)", value: "generator_desc" },
	{ label: "By Route(A-Z)", value: "route" },
	{ label: "By Route(Z-A)", value: "route_desc" },
];

const handleCheckboxChange = (data, setData, index, field) => {
	const newData = [...data];
	newData[index][field] = !newData[index][field];
	setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
	const newData = [...data];
	newData[index].paidValue = value;
	setData(newData);
};

const GeneratorSavedPaymentMethods = () => {
	const [hoveredRow, setHoveredRow] = useState(null);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [completeServices, setCompleteServices] = useState([]);

	const [from, setFrom] = useState(() => new Date("12-01-2024"));
	const [to, setTo] = useState(new Date("01-01-2025"));
	const dropdownOptions = ["Edit", "Delete", "Share"];
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [selectedSort, setSelectedSort] = useState("any");
	const [allTreatments, setAllTreatments] = useState([{ label: "Loading", value: null }]);
	const [activeTab, setActiveTab] = useState("saved");
	const handleNextClick = () => {
		navigate("drop-off-report");
	};

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const handlePerviousButton = () => {
		navigate(-1);
	};

	// useEffect(() => {
	// 	const treatmentRef = collection(db, COLLECTIONS.treatments);
	// 	const unsubscribe = onSnapshot(treatmentRef, async (snap) => {
	// 		const list = [{ label: "All", value: "all" }];
	// 		snap.docs.forEach((treatmentSnap) => {
	// 			if (!treatmentSnap.exists()) {
	// 				return;
	// 			}
	// 			const data = treatmentSnap.data();
	// 			list.push({ label: data.name, value: treatmentSnap.id });
	// 		});
	// 		setAllTreatments(list);
	// 		viewButtonHandler();
	// 	});

	// 	return () => {
	// 		if (unsubscribe) unsubscribe();
	// 	};
	// }, []);

	const getStringOfServiceTypes = (scheduleData) => {
		if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
			return "N/A"; // Handle missing data gracefully
		}

		return scheduleData.serviceType.reduce((accumulator, serviceType) => {
			const matchingService = serviceTypes.find((service) => service.value === serviceType);
			return accumulator.concat(matchingService ? matchingService.label : "N/A");
		}, []); // Use an empty array as the initial accumulator
	};

	const viewButtonHandler = async () => {
		console.log("getting all services");
		if (!from) {
			showErrorToastMessage("Please select from date.");
		}
		if (!to) {
			showErrorToastMessage("Please select to date.");
		}
		const fromDate = Timestamp.fromDate(new Date(from));
		const toDate = Timestamp.fromDate(new Date(to));
		let q = query(collection(db, "scheduledServices"), where("date", ">=", fromDate), where("date", "<=", toDate));
		q = query(q, where("status", "==", SERVICE_STATUS.COMPLETE));
		if (selectedSort.length > 0 && selectedSort === "date_desc") {
			q = query(q, orderBy("date", "desc"));
		}
		if (selectedSort.length > 0 && selectedSort === "date_asc") {
			q = query(q, orderBy("date", "asc"));
		}

		try {
			setIsLoading(true);
			const snap = await getDocs(q);
			if (snap.docs.length < 1) {
				showErrorToastMessage("No completed services found in given range.");
			}

			console.log({ items: snap.docs.length });

			const allCompletedServices = [];
			const jobs = snap.docs.map(async (serviceSnap) => {
				const serviceData = serviceSnap.data();
				// console.log({ serviceData });

				// let isExist = false;
				// if (selectedTreatments.length > 0) {
				// 	selectedTreatments.forEach((value) => {
				// 		if (value === routeData.treatmentId) {
				// 			isExist = true;
				// 		}
				// 	});
				// }
				// if (selectedTreatments.length > 0 && !isExist) {
				// 	return;
				// }

				const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
				const scheduleRes = await getDoc(scheduleDataRef);
				if (!scheduleRes.exists()) {
					return;
				}
				const scheduleData = scheduleRes.data();

				const serviceTypeString = getStringOfServiceTypes(scheduleData);
				const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
				const generatorRes = await getDoc(generatorDataRef);
				if (!generatorRes.exists()) {
					return;
				}
				const generatorData = generatorRes.data();

				const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
				const routeRes = await getDoc(routeDataRef);
				if (!routeRes.exists()) {
					return;
				}
				const routeData = routeRes.data();

				allCompletedServices.push({
					id: serviceSnap.id,
					date: dateFormatter(serviceData.date.toDate().toLocaleDateString()),
					time: serviceData.date.toDate().toLocaleTimeString(),
					customer: `${generatorData?.generatorName ?? "N/A"} `,
					octoNumber: `${generatorData?.octoNumber ?? "N/A"}`,
					email: `${generatorData?.generatorEmail ?? "N/A"}`,
					address: `${generatorData?.street ?? ""} ${generatorData?.suite ?? ""} ${generatorData?.city ?? ""} ${
						generatorData?.state ?? ""
					} ${generatorData?.zipCode ?? ""}`,
					billed: serviceData?.isBilled ?? false,
					billedDate: serviceData?.billedDate?.toDate() ?? null,
					paidDate: serviceData?.paidDate?.toDate() ?? null,
					paid: serviceData?.isPaid ?? false,
					paidAmount: serviceData?.paidAmount ?? "dummy amount",
					optionChecked: false,
					driverName: `${serviceData?.assignedDriverName ?? "N/A"}`,
					pickUpTruck: `${serviceData?.assignedServiceVehicleName ?? "N/A"}`,
					serviceType: serviceTypeString ?? "dummy service",
					routeName: routeData?.routeLabel ?? "dummy route name",
				});
			});
			await Promise.all(jobs);
			if (selectedSort.length > 0 && selectedSort === "generator_asc") {
				allCompletedServices.sort((a, b) => a.customer.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "generator_desc") {
				allCompletedServices.sort((a, b) => b.customer.localeCompare(a.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_asc") {
				allCompletedServices.sort((a, b) => a.routeName.localeCompare(b.customer));
			}
			if (selectedSort.length > 0 && selectedSort === "route_desc") {
				allCompletedServices.sort((a, b) => b.routeName.localeCompare(a.customer));
			}
			console.log({
				allCompletedServices,
			});
			setCompleteServices(allCompletedServices);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="flex flex-col border-cardBorderCol bg-white rounded-cardRadii h-full m-5">
			<div className="container mx-auto p-5 grid gap-5">
				<h2 className="text-xl font-semibold mb-4">Saved Payment Methods</h2>

				<div className="grid grid-cols-2 gap-5">
					<div className="grid grid-cols-4 gap-5">
						<div className="border border-primary-500 p-5 rounded-md col-span-3 text-gray-500">
							<div className="flex flex-col gap-2 ">
								<RiBankFill className="text-primary" />
								<p className="text-lg font-semibold text-primary">Bank Account</p>
							</div>
							<p>Account Ending in XXXX</p>
							<p>Account Nickname</p>
							<div className="flex gap-2">
								<input type="radio" name="" id="" />
								<button className="">Make Default Payment</button>
							</div>
						</div>
						<p className="text-3xl p-2 rounded-md border border-gray-200 shadow-md max-w-fit max-h-fit">...</p>
					</div>
					<div className=" grid grid-cols-4 gap-5">
						<div className="border border-primary-500 p-5 rounded-md col-span-3 text-gray-500">
							<div className="flex flex-col gap-2 ">
								<IoCardOutline className="text-primary-500" />
								<p className="text-lg font-semibold text-primary-500">Credit Card</p>
							</div>
							<h3 className="text-lg font-semibold "></h3>
							<p>Account Ending in XXXX</p>
							<p>Account Nickname</p>
							<div className="flex gap-2">
								<input type="radio" name="" id="" />
								<button className="">Make Default Payment</button>
							</div>
						</div>
						<p className="text-3xl p-2 rounded-md border border-gray-200 shadow-md max-w-fit max-h-fit">...</p>
					</div>
				</div>
				<div className="grid gap-5">
					<div>
						<p className="text-lg font-semibold">Add Payment Methods</p>
						<hr />
					</div>
					<div className="flex gap-5">
						<button
							className={` min-w-32 border px-4 py-2 rounded-md ${
								activeTab === "saved" ? "bg-blue-500 text-white" : "bg-gray-100"
							}`}
						>
							ACH
						</button>
						<button
							className={`min-w-32 border px-4 py-2 rounded-md ${
								activeTab === "add" ? "bg-blue-500 text-white" : "bg-gray-100"
							}`}
						>
							Credit Card
						</button>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-4">
					<div className=" rounded-md">
						<div className="grid gap-2">
							<label htmlFor="" className="text-sm">
								Account Nickname
								<input type="text" placeholder="" className="border p-2 rounded-full w-full" />
							</label>

							<label htmlFor="" className="text-sm">
								Account Type
								<input type="text" placeholder="" className="border p-2 rounded-full w-full" />
							</label>
							<label htmlFor="" className="text-sm">
								Routing Number
								<input type="text" placeholder="" className="border p-2 rounded-full w-full" />
							</label>
						</div>
					</div>
					<div className="grid gap-2">
						<div className="grid gap-2">
							<label htmlFor="" className="text-sm">
								Account Holder
								<input type="text" placeholder="" className="border p-2 rounded-full w-full" />
							</label>
							<label htmlFor="" className="text-sm">
								Account Number
								<input type="text" placeholder="" className="border p-2 rounded-full w-full" />
							</label>
						</div>
						<div className="ml-auto">
							<button className="min-w-32 p-2 rounded-full  bg-primary/10 mr-5">Cancel</button>
							<button className="min-w-32 p-2 bg-blue-500 text-white rounded-full">Save</button>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-5">
					<div className="border border-gray-300 rounded-xl p-5 grid gap-2">
						<p className="text-xl font-semibold">AutoPay</p>
						<p className="text-sm">Enroll in AutoPay for worry-free, automatic payments.</p>
						<div className="grid grid-cols-3 text-xs">
							<p className="text-center p-2 bg-[#4CD964] text-white rounded-tl-2xl border-r border-white">Customer #</p>
							<p className="border p-2 bg-[#4CD964] text-white text-center">AutoPay Status</p>
							<p className="border p-2 bg-[#4CD964] text-white text-center rounded-tr-2xl border-l border-white"></p>
							<p className="border p-2 text-center">1000383433</p>
							<p className="border p-2 text-green-500 text-center">On</p>
							<p className="border p-2 text-center">
								<button className="text-blue-500 mx-auto">Edit</button>
							</p>
						</div>
					</div>
					<div className="border border-gray-300 rounded-xl p-5 grid gap-2">
						<p className="text-xl font-semibold">Paperless Billing</p>
						<p className="text-sm">Go green and save time-switch to paperless billing.</p>
						<div className="grid grid-cols-3 text-xs">
							<p className="text-center p-2 bg-[#4CD964] text-white rounded-tl-2xl border-r border-white">Customer #</p>
							<p className="border p-2 bg-[#4CD964] text-white text-center">AutoPay Status</p>
							<p className="border p-2 bg-[#4CD964] text-white text-center rounded-tr-2xl border-l border-white"></p>
							<p className="border p-2 text-center">1000383433</p>
							<p className="border p-2 text-red-500 text-center">OFF</p>
							<p className="border p-2 text-center">
								<button className="text-blue-500 mx-auto">Enroll</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GeneratorSavedPaymentMethods;
