const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import { createNewTransport } from "../../utils/firebaseOperations";
import Input from "../../components/UI/Input";
import Dropdown from "../../components/UI/dropdowns/Dropdown";
import Button from "../../components/UI/Button";
import Textarea from "./TextBox";
import PhoneInput from "../../components/UI/PhoneInput";
import FileUpload from "./UploadFiles";
import Layout from "./Layout/Layout";
import { auth } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Checkbox from "../../components/UI/Checkbox";
import Radio from "../../components/UI/Radiobtn";
import CustomDatePicker from "./TransporterDatePicker";
import AddressForm from "./Form";
import MiniTreatmentRegModal from "./TreatmentModal";
import MultiSelectRounded from "../../components/UI/dropdowns/MultiSelectRounded";
import { getAllTreatment } from "../../utils/firebaseOperations";
import UrlInput from "../../components/UI/UrlInput";
import {
	showErrorToastMessage,
	showInternalServerErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
} from "../../utils/helpers";
import { useGeocoding } from "../../hooks/useGeocoding";
import BusinessHours from "./BusinessHours";

const AddTransporter = () => {
	const navigate = useNavigate();
	const [user] = useAuthState(auth);
	const [transporterData, setTransporterData] = useState();

	// Default form values
	const defaultValues = {
		name: "",
		phoneNumber: "",
		registration1: "",
		website: "",
		dot: "",
		businessHours: "",
		transporterStatus: "Active",
		reviewLink: "",
		entityId: "",
		sic: "",
		cageCode: "",
		companyDisplayName: "",
		generalEmail: "",
		registration2: "",
		companyLogo: null,
		startOfFiscalYear: "",
		ein: "",
		epaId: "",
		sos: "",
		naicsCode: "",
		shortDescription: "",
		companyMissionStatement: "",
		bio: "",
		aboutEmail: "",
		existingUser: "",
		serviceOffered: {
			medicalWasteTransporter: false,
			medicalWasteTransferStation: false,
			medicalWasteTreatmentFacilities: false,
			rollOffContainer: false,
			offsitePaperShredding: false,
			onsitePaperShredding: false,
			solidWaste: false,
			recycling: false,
		},
		optedForOctoConnect: true,
		dispatchEmail: "",
		dispatchPhone: "",
		billingAddress: { name: "", street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
		serviceAddress: { name: "", street: "", suite: "", city: "", state: "", zipCode: "", email: "", phone: "" },
		generators: [],
	};

	// Use react-hook-form's `useForm`
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		trigger,
		getValues,
		setValue,
	} = useForm({ defaultValues });

	const [billingCopyChecked, setBillingCopyChecked] = useState(false);
	const [serviceCopyChecked, setServiceCopyChecked] = useState(false);

	// Watch form values for billing and service address
	const watchBillingAddress = watch("billingAddress") || {};
	const watchServiceAddress = watch("serviceAddress") || {};

	const handleCopyAddress = (isChecked, copyFrom) => {
		if (copyFrom === "service" && isChecked) {
			Object.keys(watchBillingAddress).forEach((key) => {
				setValue(`serviceAddress.${key}`, watchBillingAddress[key]);
			});
		} else if (copyFrom === "billing" && isChecked) {
			Object.keys(watchServiceAddress).forEach((key) => {
				setValue(`billingAddress.${key}`, watchServiceAddress[key]);
			});
		}
	};

	const sanitizeData = (data) => {
		// Sanitize data to replace undefined with null
		Object.keys(data).forEach((key) => {
			if (data[key] === undefined) {
				data[key] = null;
			} else if (typeof data[key] === "object" && data[key] !== null) {
				data[key] = sanitizeData(data[key]); // Recursively sanitize nested objects
			}
		});
		return data;
	};

	const { batchGeocodeGeneratorsAddresses } = useGeocoding(); // Geocoding hook for geocoding addresses
	const [serviceAddCoordinates, setServiceAddCoordinates] = useState(null);
	const [billingAddCoordinates, setBillingAddCoordinates] = useState(null);

	const geocodeAddresses = async () => {
		try {
			// Geocode service address
			if (watchServiceAddress.street && watchServiceAddress.city && watchServiceAddress.state) {
				const serviceMarkers = await batchGeocodeGeneratorsAddresses([
					{ name: watchServiceAddress.name || "Service Address", serviceAddress: watchServiceAddress },
				]);

				if (serviceMarkers && serviceMarkers[0]) {
					setServiceAddCoordinates({
						lat: serviceMarkers[0].lat,
						lng: serviceMarkers[0].lng,
					});
				}
			}

			// Geocode billing address
			if (watchBillingAddress.street && watchBillingAddress.city && watchBillingAddress.state) {
				const billingMarkers = await batchGeocodeGeneratorsAddresses([
					{ name: watchBillingAddress.name || "Billing Address", serviceAddress: watchBillingAddress },
				]);

				if (billingMarkers && billingMarkers[0]) {
					setBillingAddCoordinates({
						lat: billingMarkers[0].lat,
						lng: billingMarkers[0].lng,
					});
				}
			}
		} catch (error) {
			console.error("Geocoding error:", error);
			showErrorToastMessage("Error getting location coordinates");
		}
	};

	useEffect(() => {
		geocodeAddresses();
	}, [
		watchServiceAddress.street,
		watchServiceAddress.city,
		watchServiceAddress.state,
		watchServiceAddress.zipCode,
		watchBillingAddress.street,
		watchBillingAddress.city,
		watchBillingAddress.state,
		watchBillingAddress.zipCode,
	]);

	const companyName = localStorage.getItem("companyName");
	if (companyName) {
		console.log("Company Name from localStorage:", companyName);
	} else {
		console.log("No company name found in localStorage.");
	}

	const onSubmit = async (data) => {
		console.log({ data });

		try {
			showLoadingToastMessage("Submitting...");

			const isBillingAddressFilled = Object.values(data.billingAddress).some((value) => value.trim() !== "");
			const isServiceAddressFilled = Object.values(data.serviceAddress).some((value) => value.trim() !== "");

			if (isBillingAddressFilled) {
				const isBillingValid = await trigger("billingAddress");
				if (!isBillingValid) {
					showErrorToastMessage("Please fill all required fields in the billing address.");
					return;
				}
			}

			if (isServiceAddressFilled) {
				const isServiceValid = await trigger("serviceAddress");
				if (!isServiceValid) {
					showErrorToastMessage("Please fill all required fields in the service address.");
					return;
				}
			}

			const { serviceOffered = {}, companyLogo, ...restData } = data;

			const transporterDataToSave = {
				...restData,
				contractedGens: [],
				subContractedGens: [],
				generators: [],
				connections: {},
				notifications: [],
				contacts: [],
				serviceOffered: {
					medicalWasteTransporter: false,
					medicalWasteTransferStation: false,
					medicalWasteTreatmentFacilities: false,
					rollOffContainer: false,
					offsitePaperShredding: false,
					onsitePaperShredding: false,
					solidWaste: false,
					recycling: false,
					...serviceOffered,
				},
				transporterStatus: restData.transporterStatus || "Active",
				optedForOctoConnect: restData.optedForOctoConnect ?? true,
				existingUser: restData.existingUser || "",
				transporterServiceCoordinates: {
					lat: serviceAddCoordinates?.lat || 32.7122671,
					lng: serviceAddCoordinates?.lng || -117.0542354,
				},
				transporterBillingCoordinates: {
					lat: billingAddCoordinates?.lat || 32.7122671,
					lng: billingAddCoordinates?.lng || -117.0542354,
				},
			};

			// Handle copy logic with a more concise approach
			const copyDetails = (source, target) => {
				const copyFields = ["name", "street", "suite", "city", "state", "zip", "email", "phone"];
				copyFields.forEach((field) => {
					transporterDataToSave[`${target}${field.charAt(0).toUpperCase() + field.slice(1)}`] =
						restData[`${source}${field.charAt(0).toUpperCase() + field.slice(1)}`];
				});
			};

			// Apply copy logic based on checkboxes
			if (billingCopyChecked) copyDetails("service", "billing");
			if (serviceCopyChecked) copyDetails("billing", "service");

			// Handle file upload
			if (companyLogo) {
				try {
					const token = Math.random().toString(36).substring(2);
					const storageRef = ref(storage, `/transporters/companyLogo/${companyLogo.name}`);
					const snapshot = await uploadBytes(storageRef, companyLogo);
					const downloadURL = await getDownloadURL(snapshot.ref);

					transporterDataToSave.companyLogo = {
						name: companyLogo.name,
						link: downloadURL,
						mime: companyLogo?.type,
						path: storageRef.fullPath,
						ref: "",
						token,
					};
				} catch (error) {
					console.log("====================================");
					console.log({ error });
					console.log("====================================");
				}
			}

			try {
				// Add user identification
				transporterDataToSave.userId = user.uid;
				let transporterName = localStorage.getItem("transporterName");
				transporterDataToSave.transporterName = transporterName ?? "";

				// Create new transport
				await createNewTransport(transporterDataToSave, user.email);

				showSuccessToastMessage("Transporter submitted successfully!");

				await new Promise((resolve) => setTimeout(resolve, 3000));
				navigate("/admin");
			} catch (error) {
				console.log("====================================");
				console.log({ error });
				console.log("====================================");
			}
		} catch (error) {
			console.error(error);
			showInternalServerErrorToastMessage(error.message || "Failed to save Transporter");
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openAddTreatmentModal = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsModalOpen(true);
	};

	const [treatmentOptions, setTreatmentOptions] = useState([]);
	useEffect(() => {
		// Subscribe to real-time treatment updates
		if (!user) return;
		const unsubscribe = getAllTreatment((treatmentsData) => {
			const optionsForDropdown = treatmentsData.map(({ name }) => ({
				label: `${name}`,
				value: `${name}`,
			}));

			// Update the state with new options
			setTreatmentOptions(optionsForDropdown);
		}, user?.uid);

		return () => unsubscribe();
	}, [user]);

	return (
		<>
			<div className="sticky top-0 z-10">
				<Layout />
			</div>
			<div className="p-6 ml-72">
				<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<div className="my-4 ml-6 text-xl font-semibold">Transporter Profile</div>
					<div className="ml-6">General Information</div>
					<div className=" mt-4 ml-6 text-gray-500">(Information appears in public profile)</div>
					<div className="border-b mb-2 border-[#CCCCCC]"></div>
					<div className="mx-6">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex gap-8">
								<div className="w-1/2">
									<Controller
										name="companyName"
										control={control}
										rules={{ required: "Company Name is required" }}
										defaultValue={companyName}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Company Name*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.companyName && (
													<p className="text-red-500 text-sm mt-1">{errors.companyName?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="companyDisplayName"
										control={control}
										rules={{ required: "Company Display Name is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Company Display Name*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.companyDisplayName && (
													<p className="text-red-500 text-sm mt-1">{errors.companyDisplayName?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="phoneNumber"
										control={control}
										rules={{ required: "Phone is required" }}
										render={({ field }) => (
											<div>
												<PhoneInput
													id="phoneNumber"
													label="General Phone*"
													value={field.value}
													onChange={field.onChange}
												/>
												{errors.phoneNumber && (
													<p className="text-red-500 text-sm mt-1">{errors.phoneNumber?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="generalEmail"
										control={control}
										rules={{
											required: "Email is required",
											pattern: {
												value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
												message: "Please enter a valid email address",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="General Email*"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.generalEmail && (
													<p className="text-red-500 text-sm mt-1">{errors.generalEmail?.message}</p>
												)}
											</div>
										)}
									/>

									<Controller
										name="website"
										control={control}
										rules={{
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Please enter a valid URL",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div>
												<UrlInput
													value={value}
													onChange={onChange}
													type="url"
													label="Website URL"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
												{errors.website && <p className="text-red-500 text-sm mt-4">{errors.website?.message}</p>}
											</div>
										)}
									/>
									<div className="flex items-center justify-between mt-8">
										<label className="truncate text-inputLabel font-normal">Start of Fiscal Year</label>
										<div className="w-2/3">
											<Controller
												name="startOfFiscalYear"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CustomDatePicker
														selectedDate={value}
														setSelectedDate={onChange}
														label="Start of Fiscal Year"
													/>
												)}
											/>
										</div>
									</div>
									{/* <Controller
										name="businessHours"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Business Hours" value={value} onChange={onChange} />
											</div>
										)}
									/> */}

									<Controller
										name="reviewLink"
										control={control}
										rules={{
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Please enter a valid URL",
											},
										}}
										render={({ field: { value, onChange } }) => (
											<div className="mb-8">
												<UrlInput
													value={value}
													onChange={onChange}
													type="url"
													label="Google Review Link"
													className="w-full px-2 py-1 text-sm"
													placeholder="https://example.com"
												/>
												{errors.reviewLink && <p className="text-red-500 text-sm mt-1">{errors.reviewLink?.message}</p>}
											</div>
										)}
									/>
									<div>
										<FileUpload
											control={control}
											label=" Company Logo"
											errors={errors}
											name={"companyLogo"}
											onDeleteFile={() => console.log("Clicked to delete file")}
										/>
									</div>
									<div className="flex justify-center mt-2">
										{transporterData?.companyLogo?.link && (
											<div className="existing-file">
												<img
													src={transporterData.companyLogo.link}
													alt="Company Logo"
													style={{ maxWidth: "100px", maxHeight: "100px", marginBottom: "10px" }}
												/>
											</div>
										)}
									</div>

									<div className="text-xs mt-1 text-gray-600">
										<span className="">Note:</span>{" "}
										<span className="font-light">
											(File should be a maximum of 5 MB and one of the following formats: PNG, JPG, JPEG.)
										</span>
									</div>

									<Controller
										name="transporterStatus"
										control={control}
										rules={{ required: "Transporter Status is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={statusOptions}
													label="Transporter Status*"
													className="text-sm"
												/>
												{errors.transporterStatus && (
													<p className="text-red-500 text-sm mt-1">{errors.transporterStatus?.message}</p>
												)}
											</div>
										)}
									/>
								</div>

								<div className="w-1/2">
									<Controller
										name="registration1"
										control={control}
										rules={{ required: "Registration Number 1 is required" }}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<div>
												<Input
													label="Registration Number 1*"
													value={value}
													onChange={onChange}
													error={error ? error.message : null}
												/>
												{errors.registration1 && (
													<p className="text-red-500 text-sm mt-1">{errors.registration1?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="registration2"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 2" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="epaId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EPA ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="dot"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="DOT Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="ein"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EIN" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="entityId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Entity ID Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sos"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SOS Number" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="sic"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SIC Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="naicsCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="NAICS Code" value={value} onChange={onChange} />
											</div>
										)}
									/>

									<Controller
										name="cageCode"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="CAGE Code" value={value} onChange={onChange} />
											</div>
										)}
									/>
								</div>
							</div>
							<AddressForm control={control} handleCopyAddress={handleCopyAddress} errors={errors} trigger={trigger} />
							<div className="mt-4">
								<BusinessHours
									control={control}
									errors={errors}
									getValues={getValues}
									setValue={setValue}
									watch={watch}
								/>
							</div>
							<div className="mt-8 mb-2 font-medium">About</div>
							<div className="border-t"></div>
							<div className="">
								<div className="flex gap-8 w-full mt-2">
									<div className="w-1/2">
										<Controller
											name="shortDescription"
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Short Description" type="Text" value={value} onChange={onChange} />
												</div>
											)}
										/>
									</div>
									{/* <div className="w-1/2">
										<Controller
											name="companyMissionStatement"
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Company Mission Statement" type="Text" value={value} onChange={onChange} />
												</div>
											)}
										/>
									</div> */}
								</div>

								<div className="flex gap-8 w-full ">
									<div className="w-1/2">
										<Controller
											name="bio"
											control={control}
											render={({ field: { onChange, value } }) => (
												<div>
													<Textarea label="Bio" type="Text" value={value} onChange={onChange} />
												</div>
											)}
										/>
									</div>
								</div>
							</div>
							<div className="">
								<div className="mt-12 mb-4 font-medium">Services Offered</div>
								<div className="border-t"></div>
								<div className="grid gap-5 grid-cols-3 py-4 mt-2">
									<Controller
										name="serviceOffered.medicalWasteTransporter"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Medical Waste Transporter</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.medicalWasteTransferStation"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Medical Waste Transfer Station</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.medicalWasteTreatmentFacilities"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Medical Waste Treatment Facilities</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.rollOffContainer"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Roll Off Container</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.offsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Offsite Paper Shredding</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.onsitePaperShredding"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Onsite Paper Shredding</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.solidWaste"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Solid Waste</span>
											</div>
										)}
									/>
									<Controller
										name="serviceOffered.recycling"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="flex items-center">
												<Checkbox isChecked={value} setIsChecked={onChange} />
												<span className="ml-2 text-cardTextGray">Recycling</span>
											</div>
										)}
									/>

								</div>
								<div className="border-t"></div>
								<div className="justify-between gap-5 py-4 mt-2">
									<div className="flex justify-between">
										<div className="w-1/2">
											<Controller
												name="existingUser"
												control={control}
												render={({ field }) => (
													<div>
														<Dropdown
															options={treatmentOptions}
															value={field.value}
															onChange={field.onChange}
															label="Link Existing TF/TS "
															className="text-sm"
														/>
													</div>
												)}
											/>
										</div>
									</div>

									<div className="bottom-8 ">
										<button onClick={openAddTreatmentModal} className="text-blue-600  flex items-center space-x-2">
											<svg
												className="w-6 h-6"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
											</svg>
											<span className="text-gray-600">Create New TF/TS</span>
										</button>
										{isModalOpen && <MiniTreatmentRegModal onClose={() => setIsModalOpen(false)} />}
									</div>
								</div>
								<div className="py-4 font-medium">Dispatch Department</div>
								<div className="border-t"></div>
								<div className="flex gap-6 w-full">
									<div className="w-1/2">
										<Controller
											name="dispatchEmail"
											control={control}
											rules={{
												required: "Email is required",
												pattern: {
													value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
													message: "Please enter a valid email address",
												},
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<div>
													<Input label="Email*" type="text" value={value} onChange={onChange} />
													{error?.message && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
												</div>
											)}
										/>
									</div>

									<div className="w-1/2">
										<Controller
											name="dispatchPhone"
											control={control}
											rules={{ required: "Phone is required" }}
											render={({ field: { onChange, value } }) => (
												<div>
													<PhoneInput label="Phone" value={value} onChange={onChange} IsRequired={true} />
													{errors.dispatchPhone && (
														<p className="text-red-500 text-sm mt-1">{errors.dispatchPhone.message}</p>
													)}
												</div>
											)}
										/>
									</div>
								</div>
								<div className="mt-10 mb-4 font-medium">OCTO Connect: Subcontractor Partnership Program</div>
								<div className="border-t"></div>
								<div className=" mt-4 w-10/12  text-gray-600">
									By opting into the OCTO Connect Subcontractor Partnership Program, you agree to allow contractors to
									potentially connect with you based on the proximity of their generator s address to your service
									radius. When a contractor expresses interest in partnering with you, a notification will be sent to
									inform you of the potential partnership.
								</div>

								<div className="flex py-4 gap-6">
									<Controller
										name="optedForOctoConnect"
										control={control}
										render={({ field: { value, onChange } }) => (
											<>
												<Radio
													label="Opt In"
													isChecked={value === true}
													setIsChecked={(checked) => {
														if (checked) {
															onChange(true);
														} else {
															onChange(false);
														}
													}}
												/>
												<Radio
													label="Opt Out"
													isChecked={value === false}
													setIsChecked={(checked) => {
														if (checked) {
															onChange(false);
														} else {
															onChange(true);
														}
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div className="flex justify-end gap-8 py-10">
								<Button type="submit" btnStyle="form_nav_primary" text={"Submit"} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddTransporter;
