import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../../config/firebase';

const COLLECTIONS = {
    defaultPriceBook: 'defaultPriceBook'
};

const Billingtemp = () => {
    const [containersData, setContainersData] = useState([]);
    const [groupedContainers, setGroupedContainers] = useState({});

    const groupContainersBySubWasteType = (containers) => {
        const grouped = containers.reduce((acc, container) => {
            const subWasteType = container.subWasteType || 'Uncategorized';
            if (!acc[subWasteType]) {
                acc[subWasteType] = [];
            }
            acc[subWasteType].push(container);
            return acc;
        }, {});

        return grouped;
    };

    useEffect(() => {
        let unsubscribe = onSnapshot(
            collection(db, COLLECTIONS.defaultPriceBook, "services", "containers"),
            (snap) => {
                if (snap.docs.length) {
                    const tempContainers = snap.docs.map((el) => ({
                        id: el.id,
                        ...el.data(),
                    }));

                    setContainersData(tempContainers);
                    setGroupedContainers(groupContainersBySubWasteType(tempContainers));
                }
            }
        );

        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, []);

    return (
        <div className="p-6">
            <div className="mb-2">
                <h1 className="text-xl font-bold">Billing - Containers</h1>
            </div>
            {Object.entries(groupedContainers).map(([subWasteType, containers]) => (
                <div key={subWasteType} className="mb-6 relative">
                    <div className="bg-white shadow-md rounded-lg p-6 mb-20 relative">
                        
                        {/* SubWasteType Heading */}
                        <div className="mb-2">
                            <h3 className="text-m font-bold">{subWasteType}</h3>
                        </div>

                        <div className="overflow-x-auto">
                            
                            {/* Header */}
                            <div className="sticky top-0 flex font-bold bg-whiteBlue py-4 min-w-fit">
                                <div className="flex items-center text-sm font-semibold w-full">
                                    <div className="truncate min-w-[23rem] ml-6">Master Item Name</div>
                                    <div className="truncate min-w-[24rem]">Category</div>
                                    <div className="truncate min-w-32">Service Price</div>
                                    <div className="truncate min-w-28">Active</div>
                                    <div className="truncate min-w-40">Additional Container</div>
                                    <div className="truncate min-w-40">Charge Per Pound</div>
                                    <div className="truncate min-w-32">Flat Fee</div>
                                    <div className="truncate min-w-60">Include in Flat Monthly Charge</div>
                                    <div className="truncate min-w-48">Label OCTO Connect</div>
                                    <div className="truncate min-w-60">Label OCTO Delivery Receipt</div>
                                    <div className="truncate min-w-48">Label OCTO FieldApp</div>
                                    <div className="truncate min-w-56">Label OCTO Invoices</div>
                                    <div className="truncate min-w-60">Label OCTO Smart Manifests</div>
                                    <div className="truncate min-w-24">Lifecycle</div>
                                    <div className="truncate min-w-24">Overage</div>
                                    <div className="truncate min-w-40">Price Per Pound</div>
                                    <div className="truncate min-w-40">Replacement Cost</div>
                                    <div className="truncate min-w-[24rem]">Nomenclature</div>
                                    <div className="truncate min-w-24">SKU</div>
                                    <div className="truncate min-w-24">Sort Order</div>
                                    <div className="truncate min-w-24">Sub Flat Fee</div>
                                    <div className="truncate min-w-48">SubWasteType</div>
                                    <div className="truncate min-w-48">SubWasteType Label</div>
                                    <div className="truncate min-w-40">SubWasteType Sort</div>
                                    <div className="truncate min-w-32">Tare Weight</div>
                                    <div className="truncate min-w-24">Taxable</div>
                                    <div className="truncate min-w-20">Up To</div>
                                    <div className="truncate min-w-24">Usage Cycle</div>
                                    <div className="truncate min-w-24">Volume</div>
                                    <div className="truncate min-w-48">Waste Type</div>
                                    <div className="truncate min-w-40">Weight Limit</div>

                                </div>
                            </div>

                            {/* Content */}
                            <div className="max-h-[75vh] min-w-fit">
                                {containers.map((container) => (
                                    <div
                                        key={container.id}
                                        className="flex items-center transition-colors duration-300 border-b min-w-fit border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20 text-sm"
                                        style={{ minHeight: '45px' }}
                                    >
                                        <div className="flex items-center w-full">
                                            <div className="truncate min-w-[23rem] ml-6">{container.masterItemName}</div>
                                            <div className="truncate min-w-[24rem]">{container.category}</div>
                                            <div className="truncate min-w-32">${container.servivePrice}</div>
                                            <div className="truncate min-w-28">{String(container.active)}</div>
                                            <div className="truncate min-w-40">{container.additionalContainer}</div>
                                            <div className="truncate min-w-40">{String(container.chargePerPound)}</div>
                                            <div className="truncate min-w-32">{container.flatFee}</div>
                                            <div className="truncate min-w-60">{String(container.includeInFlatMonthlyCharge)}</div>
                                            <div className="truncate min-w-48">{container.labelOCTOConnect}</div>
                                            <div className="truncate min-w-60">{container.labelOCTODeliveryReceipt}</div>
                                            <div className="truncate min-w-48">{container.labelOCTOFieldApp}</div>
                                            <div className="truncate min-w-56">{container.labelOCTOInvoices}</div>
                                            <div className="truncate min-w-60">{container.labelOCTOSmartManifests}</div>
                                            <div className="truncate min-w-24">{container.lifeCycle}</div>
                                            <div className="truncate min-w-24">{container.overage}</div>
                                            <div className="truncate min-w-40">{container.pricePerPound}</div>
                                            <div className="truncate min-w-40">{container.replacementCost}</div>
                                            <div className="truncate min-w-[24rem]">{container.nominclature}</div>
                                            <div className="truncate min-w-24">{container.sku}</div>
                                            <div className="truncate min-w-24">{container.sortOrder}</div>
                                            <div className="truncate min-w-24">{container.subFlatFee}</div>
                                            <div className="truncate min-w-48">{container.subWasteType}</div>
                                            <div className="truncate min-w-48">{container.subWasteTypeLabel}</div>
                                            <div className="truncate min-w-40">{container.subWasteTypeSort}</div>
                                            <div className="truncate min-w-32">{container.tareWeight}</div>
                                            <div className="truncate min-w-24">{String(container.taxable)}</div>
                                            <div className="truncate min-w-20">{container.upTo}</div>
                                            <div className="truncate min-w-24">{container.useageCycle}</div>
                                            <div className="truncate min-w-24">{container.volume}</div>
                                            <div className="truncate min-w-48">{container.wasteType}</div>
                                            <div className="truncate min-w-40">{container.weightLimit}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Button */}
                        <button
                            className="absolute right-4 bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2 mt-8"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                            <span>{subWasteType}</span>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Billingtemp;
