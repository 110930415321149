import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import AdminSidebar from "../../components/sidebars/admin-sidebar/AdminSidebar";
import DriverManagement from "./assets/components/drivers/DriverManagement";
import GeneratorManagement from "./generator-management/GeneratorManagement";
import Vehicles from "./vehicles/Vehicles";
import Routes from "./assets/components/routes/index";
import Audit from "./audit/Audit";
import Settings from "./settings/Settings";
import Profile from "./Profile";
import EmailList from "../../template/E-mail-Template/EmailList";
import EmailTemplate from "../../template/E-mail-Template/EmailTemplate";
import Preview from "../../template/E-mail-Template/Preview";
import Assets from "./assets/Assets";
import { BreadcrumbProvider } from "../../context/BreadcrumbContext";
import Header from "../../components/header/Header";
import ManageWastes from "./wastes/ManageWastes";
import Subcontractor from "./octo-connect/Subcontractor";
import MySettings from "./MySettings";
import useTUserContext, { TransporterUserProvider } from "../../context/TransporterUserContext";
import ServiceReporting from "./generator-management/components/reports/ServiceReporting";
import TransferReports from "./generator-management/components/reports/TransferReports";
import LiveView from "./generator-management/components/reports/Reports";
import RoutesOptimization from "./assets/components/routes-optimization/components/RoutesOptimization";
import { AzureMapsProvider } from "react-azure-maps";
import ServiceCalender from "../generator-portal/dashboard/components/ServiceCalender/ServiceCalender";
import Operations from "./operations/Operations";
import Reporting from "./reporting/Reporting";
import OctoConnect from "./octo-connect/OctoConnect";
import Billing from "./billings/Billing";
import Subcontrator_Schedules from "./octo-connect/Subcontractor_Schedules";
import Billingtemp from "./billings/Billingtemp";

const AdminLayout = () => {
	const [active, setActive] = useState(null);
	const scrollContainerRef = useRef(null);
	const location = useLocation();
	const [showOnlyIcons, setShowOnlyIcons] = useState(false);

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname.endsWith("/admin")) setActive("dashboard");
		if (location.pathname.includes("/admin/generators")) setActive("generator");
		if (location.pathname.includes("/admin/assets")) setActive("asset");
		if (location.pathname.includes("/admin/wastes")) setActive("waste");
		if (location.pathname.includes("/admin/users")) setActive("user");
		if (location.pathname.includes("/admin/reports")) setActive("report");
		if (location.pathname.includes("/admin/octo-gps")) setActive("octo-gps");
		if (location.pathname.includes("/admin/billings")) setActive("billing");
		if (location.pathname.includes("/admin/routes")) setActive("route");
		if (location.pathname.includes("/admin/settings")) setActive("setting");
		if (location.pathname.includes("/admin/profile")) setActive("profile");
		if (location.pathname.includes("/admin/email")) setActive("email");
		if (location.pathname.includes("/admin/email/edit")) setActive("template");
		if (location.pathname.includes("/admin/email/preview")) setActive("preview");
		if (location.pathname.includes("/admin/my-settings")) setActive("my_profile");
		if (location.pathname.includes("/admin/audit")) setActive("audit");
		if (location.pathname.includes("/admin/vendor")) setActive("vendor");
		if (location.pathname.includes("/admin/octo-connect")) setActive("octo-connect");
		if (location.pathname.includes("/admin/operations")) setActive("operations");
		if (location.pathname.includes("/admin/billing-temp")) setActive("billing-temp");

		console.log({ location });
	}, [location]);

	console.log("Active item set to, ", active);

	return (
		<TransporterUserProvider>
			<BreadcrumbProvider>
				<div className="">
					<div>
						<AdminSidebar active={active} showOnlyIcons={showOnlyIcons} />
					</div>
					<div
						className={`${location.pathname == "/admin" ? "overflow-scroll" : ""} flex-1 h-screen ml-0  bg-primaryGray`}
						style={{ marginLeft: showOnlyIcons ? "96px" : "288px" }}
					>
						<Header />
						<div
							className={`${location.pathname == "/admin" ? "" : "h-[calc(100%-80px)] overflow-scroll"}`}
							ref={scrollContainerRef}
							id="scroll-container"
						>
							{active == "dashboard" && <Dashboard />}
							{active == "generator" && <GeneratorManagement />}
							{active == "asset" && <Assets />}
							{active == "operations" && <Operations />}
							{active == "report" && <Reporting />}
							{active == "route" && <Routes />}
							{active == "setting" && <Settings />}
							{active == "waste" && <ManageWastes />}
							{active == "octo-connect" && <OctoConnect />}
							{active == "profile" && <Profile />}
							{active == "email" && <EmailList />}
							{active == "preview" && <Preview />}
							{active == "template" && <EmailTemplate />}
							{active == "billing-temp" && <Billingtemp />}
							{active == "live_view" && (
								<AzureMapsProvider>
									<LiveView />
								</AzureMapsProvider>
							)}
							{/* {active == "service_history" && <ServiceReporting />}
							{active == "transfer_history" && <TransferReports />} */}
							{active == "route_optimization" && (
								<AzureMapsProvider>
									<RoutesOptimization />
								</AzureMapsProvider>
							)}
							{active == "user" && <UnderDevelopmentComponent />}
							{active == "audit" && <UnderDevelopmentComponent />}
							{/* {active == "report" && <UnderDevelopmentComponent />} */}
							{active == "billing" && <Billing />}
							{active == "octo-gps" && <UnderDevelopmentComponent />}
							{active == "my_profile" && <MySettings />}
						</div>
					</div>
				</div>
			</BreadcrumbProvider>
		</TransporterUserProvider>
	);
};

export default AdminLayout;

export const UnderDevelopmentComponent = () => {
	return <div className="flex items-center justify-center w-full h-full font-semibold text-3xl">Under development</div>;
};
