import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { getDrivers, deleteDriver } from "../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import MiniDriverRegistrationForm from "../../../../../components/MiniDriverRegModal";
import Tooltip from "../../../../../components/UI/Tooltip";
import ShimmerLoader from "../../../../../components/UI/loaders/ShimmerLoader";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { auth, COLLECTIONS, db } from "../../../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { log } from "handlebars";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { LOCATION_TYPE_HOME_BASE } from "../../../../../utils/constants";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { createPortal } from "react-dom";
import useTUserContext from "../../../../../context/TransporterUserContext";

const statusOptions = [
	{ label: "Active", value: "active" },
	{ label: "Inactive", value: "inactive" },
];
const ManageDrivers = () => {
	const [selectedDrivers, setSelectedDrivers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [data, setData] = useState([]);

	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const navigate = useNavigate();

	const [selectedStatuses, setSelectedStatuses] = useState(["all", ...statusOptions.map((option) => option.value)]);
	const { user, loading } = useTUserContext();
	const [locationOptions, setLocationOptions] = useState([]);

	useEffect(() => {
		if (!user) return;
		getDriversData();
	}, [user]);

	const filteredData = useMemo(() => {
		console.log({ selectedStatuses });
		return data.filter((driver) => {
			if (selectedStatuses.includes(driver.driverStatus)) {
				return true;
			}
			return false;
		});
	}, [data, selectedStatuses]);

	useEffect(() => {
		if (selectedDrivers.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedDrivers.length === filteredData.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedDrivers, filteredData]);

	const getDriversData = async () => {
		setLoading(true);
		try {
			let tempLocationOptions = [];
			console.log("user", user.uid);
			const transporterRes = await getDoc(doc(db, COLLECTIONS.transporters, user?.uid));
			if (
				transporterRes.exists() &&
				transporterRes.data()?.transporterServiceCoordinates?.lat &&
				transporterRes.data()?.transporterServiceCoordinates?.lng
			) {
				tempLocationOptions.push({
					label: transporterRes.data()?.companyDisplayName ?? "",
					value: LOCATION_TYPE_HOME_BASE,
				});
			}
			const satelliteLocationsRes = await getDocs(
				query(collection(db, COLLECTIONS.satelliteLocation), where("transporterId", "==", user.uid))
			);
			tempLocationOptions.push(
				...satelliteLocationsRes.docs.map((doc) => {
					const data = doc.data();
					return { label: data.name, value: doc.id };
				})
			);

			setLocationOptions(tempLocationOptions);

			const drivers = await getDrivers(user?.uid);
			drivers.forEach((driver) => {
				console.log("driver", driver.driverLocation);
				if (driver.driverLocation.length > 0) {
					driver.driverLocation = driver.driverLocation.map((curr) => {
						const location = tempLocationOptions.find((loc) => loc.value === curr);
						if (location) {
							return location.label;
						}
						return "";
					});
				}
				console.log("driver", driver.driverLocation);
			});
			setData([...drivers]);
		} catch (err) {
			console.log(err);
			setError("Error loading drivers");
		} finally {
			setLoading(false);
		}
	};

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		setIndeterminate(false);
		if (newSelectAll) {
			setSelectedDrivers([...Array(filteredData.length).keys()]);
		} else {
			setSelectedDrivers([]);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedDrivers((prev) => {
			if (prev.includes(index)) {
				return prev.filter((i) => i !== index);
			} else {
				return [...prev, index];
			}
		});
	};

	const handleRowClick = (driver) => {
		navigate(`${driver.id}/edit`);
	};

	const openAddDriverModal = () => {
		setIsModalOpen(true);
	};
	const statusFilterChangeHandler = (selected) => {
		console.log({ selected });

		if (selected.includes("All")) {
			setSelectedStatuses(["all", ...statusOptions.map((el) => el.value)]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div>Drivers List</div>
					<div className=" px-2">
						<MultiselectDropdown
							buttonText="Driver Status"
							options={[{ label: "All", value: "all" }, ...statusOptions]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>
				<div
					className="overflow-x-scroll overflow-y-hidden"
					style={{
						height: `calc(100% - 10px)`,
					}}
				>
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								ref={(input) => input && (input.indeterminate = indeterminate)}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center">
							<div className="truncate w-60">Name</div>
							<div className="truncate w-32">OCTO ID #</div>
							<div className="truncate w-40">Phone</div>
							<div className="truncate w-60">Email</div>
							<div className="truncate w-60">Location Assignment</div>
							<div className="truncate w-32">Driver Status</div>
						</div>
					</div>

					{isLoading ? (
						<ShimmerLoader cols={6} rows={20} height={"68vh"} />
					) : (
						<div className="min-w-fit overflow-y-scroll pb-2 h-[68vh]">
							{filteredData.length > 0 ? (
								filteredData.map((driver, index) => (
									<DriverItem
										key={index}
										driver={driver}
										index={index}
										selectedDrivers={selectedDrivers}
										handleCheckboxChange={handleCheckboxChange}
										handleRowClick={handleRowClick}
									/>
								))
							) : (
								<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
									<p>No drivers found</p>
								</div>
							)}
						</div>
					)}

					<FooterActionItems driversSelected={selectedDrivers} drivers={filteredData} onRefresh={getDriversData} />
				</div>
			</div>

			{/* Add New Driver Button - Hidden when checkboxes are selected */}
			{selectedDrivers.length === 0 && (
				<div className="fixed bottom-8 right-8">
					<button
						onClick={openAddDriverModal}
						className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
						</svg>
						<span>Add New Driver</span>
					</button>
				</div>
			)}

			{isModalOpen && <MiniDriverRegistrationForm onClose={() => setIsModalOpen(false)} activeSubmenu="driver" />}
		</div>
	);
};

const DriverItem = ({ driver, index, selectedDrivers, handleCheckboxChange, handleRowClick }) => {
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [transformOrigin, setTransformOrigin] = useState("top left");
	const timeoutRef = useRef(null);
	const tooltipRef = useRef(null);

	const handleMouseEnter = (driverId, event) => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setActiveTooltip(driverId);
			updateTooltipPosition(event);
		}, 1000);
	};

	const handleMouseLeave = (event) => {
		clearTimeout(timeoutRef.current);
		if (
			!tooltipRef.current ||
			!event.relatedTarget ||
			!(event.relatedTarget instanceof Node) ||
			!tooltipRef.current.contains(event.relatedTarget)
		) {
			setActiveTooltip(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 300;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
			setTransformOrigin("top right");
		} else {
			left = cursorX + offset;
			setTransformOrigin("top left");
		}

		top = cursorY - offset;
		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	return (
		<Link
			to={`../drivers/${driver.id}/edit`}
			className="flex w-full items-center transition-colors duration-300 border-b border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20"
			style={{ minHeight: "45px" }}
		>
			<div className="min-w-8 mx-6 flex justify-center hover:cursor-pointer">
				<input
					type="checkbox"
					className="w-4 h-4 bg-white"
					checked={selectedDrivers.includes(index)}
					onChange={(e) => {
						handleCheckboxChange(index);
					}}
					onClick={(e) => e.stopPropagation()}
				/>
			</div>
			<div
				className="w-full flex items-center cursor-pointer"
				// onClick={() => handleRowClick(driver)}
				// onMouseEnter={(e) => handleMouseEnter(driver.OctoDriverId || driver.id, e)}
				// onMouseLeave={handleMouseLeave}
			>
				<div className="truncate w-60">{`${driver.firstName} ${driver.middleInitial || ""} ${driver.lastName}`}</div>
				<div className="truncate w-32">{(driver.OctoDriverId || driver.id)?.slice(0, 6) || "--"}</div>
				<div className="w-40">
					<span
						className={`truncate ${driver.phoneNumber ? "hover:text-blueText hover:underline" : ""}`}
						onClick={(e) => driver.phoneNumber && handlePhoneClick(e, driver.phoneNumber)}
					>
						{driver.phoneNumber || "--"}
					</span>
				</div>
				<div className="truncate w-60">{driver.email || "--"}</div>
				<div className="truncate w-60">
					{driver?.driverLocation?.length > 0 ? driver.driverLocation.map((el) => <p key={el}>{el}</p>) : "--"}
				</div>
				<div className="truncate w-32 capitalize">{driver.driverStatus}</div>
			</div>
			<AnimatePresence>
				{activeTooltip === (driver.OctoDriverId || driver.id) && (
					<motion.div
						ref={tooltipRef}
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
							width: "300px",
						}}
						initial={{ opacity: 0, scale: 0.8, transformOrigin }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setActiveTooltip(driver.OctoDriverId || driver.id)}
						onMouseLeave={handleMouseLeave}
					>
						<ToolTipContent driver={driver} />
					</motion.div>
				)}
			</AnimatePresence>
		</Link>
	);
};

const ToolTipContent = ({ driver }) => {
	const details = [
		{ label: "Name", value: `${driver.firstName} ${driver.middleInitial || ""} ${driver.lastName}` },
		{ label: "Phone", value: driver.phoneNumber || "--" },
		{ label: "Email", value: driver.email || "--" },
		{ label: "Location Assignment", value: driver.driverLocation || "--" },
		{ label: "OCTO ID", value: driver.OctoDriverId || driver.id || "--" },
		{ label: "Status", value: driver.driverStatus || "Active" },
		{ label: "License", value: driver.driverLicenseInfo || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className="w-full flex">
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">
						{typeof item.value === "string" ? item.value : item.value.map((el) => <p key={el}>{el}</p>)}
					</div>
				</div>
			))}
		</div>
	);
};
const StepsComp = ({ steps }) => {
	const stepsList = [
		{ key: "personalInfo", label: "Personal Info" },
		{ key: "license", label: "License" },
		{ key: "training", label: "Training" },
		{ key: "documents", label: "Documents" },
		{ key: "vehicle", label: "Vehicle" },
	];

	return (
		<div className="flex h-11 items-center justify-center gap-1">
			{stepsList.map((step) => (
				<Tooltip key={step.key} text={step.label}>
					<span className={`h-3.5 w-1.5 rounded-full ${steps[step.key] ? "bg-lightBlue" : "bg-cardTextGray"}`}></span>
				</Tooltip>
			))}
		</div>
	);
};

const ExportModal = ({ isOpen, isExporting, progress, onClose }) => {
	if (!isOpen) return null;

	return createPortal(
		<div className="fixed inset-0 z-[9999] flex items-center justify-center">
			<div className="modal-overlay absolute inset-0 bg-black bg-opacity-50" />
			<div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
				<div className="p-8">
					{/* Header */}
					<div className="mb-6">
						<h3 className="text-2xl font-semibold text-gray-900">Exporting Data</h3>
						<p className="mt-2 text-sm text-gray-600">Please wait while we prepare your file...</p>
					</div>

					{/* Progress Section */}
					<div className="space-y-6">
						{/* Progress Bar */}
						<div className="w-full bg-gray-100 rounded-full h-3">
							<div
								className="bg-lightBlue h-3 rounded-full transition-all duration-300 ease-out"
								style={{ width: `${progress}%` }}
							/>
						</div>

						{/* Progress Details */}
						<div className="flex justify-between items-center">
							<p className="text-sm text-gray-600">{progress < 100 ? "Processing..." : "Download Complete!"}</p>
							<span className="text-sm font-medium text-gray-900">{Math.round(progress)}%</span>
						</div>

						{/* Status Message */}
						<div className="text-center">
							{progress === 100 ? (
								<p className="text-green-600 font-medium">
									Your download will begin automatically. This window will close shortly.
								</p>
							) : (
								<p className="text-gray-600">Please don't close this window while exporting...</p>
							)}
						</div>
					</div>

					{/* Close Button - Only shown when not exporting */}
					{!isExporting && (
						<button
							onClick={onClose}
							className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 
					focus:outline-none focus:text-gray-500 transition-colors duration-200"
							aria-label="Close modal"
						>
							<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>,
		document.body
	);
};

const FooterActionItems = ({ driversSelected, drivers, onRefresh }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleAction = async (actionType) => {
		const selectedDriverIds = drivers.filter((_, i) => driversSelected.includes(i)).map((driver) => driver.id);

		switch (actionType) {
			case "Delete":
				console.log("Delete", selectedDriverIds);
				break;
			case "Email":
				const selectedEmails = drivers
					.filter((_, i) => driversSelected.includes(i))
					.map((driver) => driver.email)
					.filter(Boolean)
					.join(";");
				window.open(`mailto:${selectedEmails}`, "_blank");
				break;

			// Add more actions as needed
		}
	};

	const handleExport = async () => {
		setIsExporting(true);
		setIsModalOpen(true);
		setProgress(0);

		try {
			// Step 1: Data preparation (20% of progress)
			const data = drivers.filter((_, index) => driversSelected.includes(index));

			const worksheetData = data.map((item, index) => {
				setProgress((index / data.length) * 20);

				return {
					Name: item.firstName + item.middleInitial + item.lastName,
					Phone: item.phoneNumber,
					Email: item.email,
					"Location Assignment": item.driverLocation?.join(", ") || "",
					"OCTO ID": item.id,
					Status: item.driverStatus,
					"Duty Status": item.limitedDutyStatus,
				};
			});
			setProgress(20);

			// Step 2: Create worksheet (40% of progress)
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.json_to_sheet(worksheetData);
			XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");
			setProgress(40);

			// Step 3: Convert to binary (70% of progress)
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			setProgress(70);

			// Step 4: Create blob and prepare for download (90% of progress)
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			setProgress(90);

			// Step 5: Trigger download (100% of progress)
			saveAs(blob, "exported_data.xlsx");
			setProgress(100);

			// Reset after showing 100% completion
			setTimeout(() => {
				setIsExporting(false);
				setProgress(0);
				setIsModalOpen(false);
			}, 1000);
		} catch (error) {
			console.error("Export failed:", error);
			setIsExporting(false);
			setProgress(0);
			setIsModalOpen(false);
		}
	};

	return (
		<div
			className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md bg-white px-8 transition-all duration-300 ease-in-out ${
				driversSelected?.length > 0 ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{driversSelected?.length} item(s) selected</span>
				<div className="space-x-2">
					<button
						className={
							"px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out disabled:bg-gray-300"
						}
						onClick={() => handleExport()}
					>
						Export
					</button>
					<ExportModal
						isOpen={isModalOpen}
						isExporting={isExporting}
						progress={progress}
						onClose={() => setIsModalOpen(false)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ManageDrivers;
