import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
 import SidebarItem from "../../../components/sidebars/admin-sidebar/SIdebarItem";
 import Icon from "../../../components/sidebars/Icon";
 import { signOutUser } from "../../../config/firebase";
 import { transporter } from "../../../utils/constants";

const TransportSideBar = ({ active }) => {
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [expandedItemIds, setExpandedItemIds] = useState([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  console.log({ active });

  useEffect(() => {
    const shouldCollapseSubmenu = () => {
      if (!expandedItemId) return false;

      const expandedItem = transporter.find((item) => item.id == expandedItemId && location.pathname.startsWith(item.link) && item.children);
      if (!expandedItem) {
        setExpandedItemId(null);
      }
    };
    shouldCollapseSubmenu();
  }, [location, expandedItemId]);

  const logOutHandler = async () => {
    await signOutUser();
    navigate("/");
  };

  const clickHandler = (to = "/") => navigate(to);

  const scrollHandler = (e) => {
    const target = e.target;
    target.scrollTop > 0 ? setIsScrollable(true) : setIsScrollable(false);
  };

  return (
    <nav className="w-72 bg-white fixed hidden md:flex flex-col h-screen">
      {/* Top section */}
      <div className="px-10 min-h-20 max-h-20  items-center gap-4 border border-borderCol">
        <img src="" alt="" className="h-10 w-10 md:h-16 md:w-16 rounded-full bg-dashBtnGradient ml-16" />
        <h6 className="text-xs text-black text-center"></h6>
      </div>

      {/* Middle section */}
      <div className="flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto" onScroll={scrollHandler}>
          <ul className="flex flex-col pt-6">
            {/* {transporter.length > 0 ? (
              transporter.map((item, index) => (
                <SidebarItem
                  key={item.label + index}
                  item={item}
                  active={active == item.name}
                  expandedItemId={expandedItemId}
                  setExpandedItemId={setExpandedItemId}
                  expandedItemIds={expandedItemIds}
                  setExpandedItemIds={setExpandedItemIds}
                />
              ))
            ) : (
              <div className="text-center text-gray-400">No Items Available</div> // Optional message when no items are available
            )} */}
          </ul>
        </div>
      </div>

      {/* Bottom section */}
      <ul className={`p-4 flex flex-col w-full ${isScrollable ? " border-t border-borderCol border-opacity-50" : ""}`}>
        {isScrollable && <div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>}
        {/* <li
          //onClick={() => clickHandler("/admin/market")}
          className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${active == "market" ? "text-cardTextBlue" : ""}`}
        >
          <Icon name={"market"} active={active == "market"} />
          <h6>OCTO Market</h6>
        </li>
        <li
          //onClick={() => clickHandler("/")}
          className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${active == "connect" ? "text-cardTextBlue" : ""}`}
        >
          <Icon name={"connect"} active={active == "connect"} />
          <h6>OCTO GPS</h6>
        </li>
        <li
         // onClick={() => clickHandler("")}
          className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${active == "audit" ? "text-cardTextBlue" : ""}`}
        >
          <Icon name={"audit"} active={active == "audit"} />
          <h6>Audit Log</h6>
        </li> */}
        <li
          //onClick={() => clickHandler("/")}
          className={`group pl-4 py-2 flex items-center gap-3 text-sm xl:text-base hover:text-cardTextBlue hover:cursor-pointer ${active == "setting" ? "text-cardTextBlue" : ""}`}
        >
          <Icon name={"setting"} active={active == "setting"} />
          <h6>Settings</h6>
        </li>
        <li
          onClick={logOutHandler}
          className="group rounded-full text-sm xl:text-base flex items-center gap-3 bg-cardBorderCol w-full pl-4 py-2 hover:text-cardTextBlue hover:bg-[#cccccc] hover:cursor-pointer"
        >
          <Icon name={"logout"} active={active == "logout"} />
          <h6>Logout</h6>
        </li>
      </ul>
    </nav>
  );
};

export default TransportSideBar;
