import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth, COLLECTIONS, db } from "../../../../../config/firebase";
import {
	getAllVehicleSatelliteLocationSnapshot,
	getServiceVehicleStorageRef,
} from "../../../../../utils/firebaseOperations";
import Input from "../../../../../components/UI/Input";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import Textarea from "../../../../../components/UI/Textarea";
import {
	showErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
	uploadFileToFirebaseStorage,
	validateImage,
} from "../../../../../utils/helpers";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	runTransaction,
	serverTimestamp,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { fuelOptions, statusOptions, vehicleOptions } from "../../../../../utils/vehicleConstants";
import UploadFiles from "../../../../transporters/UploadFiles";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../components/UI/loaders/Loader";
import {
	DEFAULT_SERVICE_VEHICLE_VALUES,
	LOCATION_TYPE_HOME_BASE,
	VEHICLE_WEIGHT_CLASS_TYPES,
} from "../../../../../utils/constants";
import FooterActionBtns from "../../../generator-management/components/generators/components/detail-forms/forms/components/FooterActionBtns";
import Button from "../../../../../components/UI/Button";
import { log } from "handlebars";
import InputTreatment from "../treatment-facilities/InputTreatment";
import { useAuthState } from "react-firebase-hooks/auth";
import useTUserContext from "../../../../../context/TransporterUserContext";

const AddNewServiceVehicle = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isLoading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [vehicleLocationOptions, setVehicleLocationOptions] = useState([{ label: "Loading", value: "loading" }]);
	const { user, loading } = useTUserContext();
	useEffect(() => {
		let unsubscribe = getAllVehicleSatelliteLocationSnapshot(setVehicleLocationOptions);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm({ defaultValues: DEFAULT_SERVICE_VEHICLE_VALUES });
	const formValues = watch();
	useEffect(() => {
		console.log({ formValues });
	}, [formValues]);

	useEffect(() => {
		if (!user) return;
		getAllLocationOptions();
	}, [user]);
	const getAllLocationOptions = async () => {
		let tempLocationOptions = [];
		console.log("user", user?.uid);
		const transporterRes = await getDoc(doc(db, COLLECTIONS.transporters, user?.uid));
		if (
			transporterRes.exists() &&
			transporterRes.data()?.transporterServiceCoordinates?.lat &&
			transporterRes.data()?.transporterServiceCoordinates?.lng
		) {
			tempLocationOptions.push({
				label: transporterRes.data()?.companyDisplayName ?? "",
				value: LOCATION_TYPE_HOME_BASE,
			});
		}
		const satelliteLocationsRes = await getDocs(
			query(
				collection(db, COLLECTIONS.satelliteLocation),
				where("transporterId", "==", user?.uid),
				where("satelliteStatus", "==", "Active")
			)
		);
		tempLocationOptions.push(
			...satelliteLocationsRes.docs.map((doc) => {
				const data = doc.data();
				return { label: data.name, value: doc.id };
			})
		);
		console.log({ tempLocationOptions });

		setVehicleLocationOptions(tempLocationOptions);
	};
	const updateServiceVehicleId = (id) => {
		return runTransaction(db, async (transaction) => {
			let count = 0;
			const countRes = await transaction.get(doc(db, COLLECTIONS.counters, "default"));
			if (countRes.exists() && countRes?.data()?.serviceVehicleCount) {
				count = countRes?.data()?.serviceVehicleCount;
			}
			const serviceVehicleRes = await transaction.get(doc(db, COLLECTIONS.serviceVehicles, id));
			console.log({ serviceVehicle: serviceVehicleRes.data() });

			count++;
			log({ count });
			if (serviceVehicleRes.exists() && !serviceVehicleRes?.data()?.serviceVehicleId) {
				transaction.set(
					doc(db, COLLECTIONS.serviceVehicles, id),
					{
						serviceVehicleId: `SV${count.toString().padStart(7, "0")}`,
					},
					{ merge: true }
				);
				transaction.set(
					doc(db, COLLECTIONS.counters, "default"),
					{
						serviceVehicleCount: count,
					},
					{ merge: true }
				);
			}
		});
	};
	const onSubmit = async (data) => {
		let isUpdating = true;
		if (id) {
			showLoadingToastMessage("Updating Service Vehicle Data");
			isUpdating = false;
		} else {
			showLoadingToastMessage("Creating New Service Vehicle");
		}
		let serviceVehicleId =
			id ?? (await addDoc(collection(db, COLLECTIONS.serviceVehicles), { createdAt: serverTimestamp() })).id;
		console.log({ serviceVehicleId });
		if (!serviceVehicleId) {
			showErrorToastMessage("Internal Server Error.");
			return;
		}
		if (data?.insuranceCopy && !data?.insuranceCopy?.url) {
			const url = await uploadFileToFirebaseStorage(
				data.insuranceCopy,
				getServiceVehicleStorageRef(`/default/serviceVehicles/${serviceVehicleId}/insuranceCopy`)
			);
			const file = {
				name: data.insuranceCopy.name,
				type: data.insuranceCopy?.type ?? "",
				url,
			};
			delete data.insuranceCopy;
			data.insuranceCopy = file;
		}
		if (data?.registrationCopy && !data?.registrationCopy?.url) {
			const url = await uploadFileToFirebaseStorage(
				data.registrationCopy,
				getServiceVehicleStorageRef(`/default/serviceVehicles/${serviceVehicleId}/registrationCopy`)
			);
			const file = {
				name: data.registrationCopy.name,
				type: data.registrationCopy?.type ?? "",
				url,
			};
			delete data.insuranceCopy;
			data.registrationCopy = file;
		}
		if (data?.transporterPermit && !data?.transporterPermit?.url) {
			const url = await uploadFileToFirebaseStorage(
				data.transporterPermit,
				getServiceVehicleStorageRef(`/default/serviceVehicles/${serviceVehicleId}/transporterPermit`)
			);
			const file = {
				name: data.transporterPermit.name,
				type: data.transporterPermit?.type ?? "",
				url,
			};
			delete data.transporterPermit;
			data.transporterPermit = file;
		}
		data.transporterId = user?.uid;
		console.log({ data });
		const docRef = doc(db, COLLECTIONS.serviceVehicles, serviceVehicleId);
		await updateDoc(docRef, data);
		updateServiceVehicleId(serviceVehicleId);
		showSuccessToastMessage("Service Vehicle Data Updated Successfully!");
		// reset(DEFAULT_SERVICE_VEHICLE_VALUES);
		//navigate("/admin/assets/service-vehicles");
	};

	useEffect(() => {
		const fetchCurrentServiceVehicleData = async () => {
			if (id) {
				try {
					setLoading(true);
					const snap = await getDoc(doc(db, COLLECTIONS.serviceVehicles, id));
					if (!snap.exists()) {
						throw new Error("Data not found.");
					}
					setData({ ...snap.data(), truckCostPerMile: snap.data()?.truckCostPerMile ?? "" });
					reset({ ...snap.data(), truckCostPerMile: snap.data()?.truckCostPerMile ?? "" });
				} catch (error) {
					console.log(error);
					toast.error("Failed to fetch data.");
				} finally {
					setLoading(false);
				}
			}
		};

		fetchCurrentServiceVehicleData();
	}, [id, reset]);

	if (isLoading) return <Loader />;

	return (
		<div className="p-6">
			<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<ToastContainer />
				<div className="flex justify-between my-4 mx-6">
					<h6 className="font-medium text-lg">{"Service Vehicle Profile"}</h6>
				</div>
				<div className="border-b mb-2 border-[#CCCCCC]" />
				<div className="mx-6">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex">
							<div className="flex w-full gap-20">
								<div className="w-1/2">
									{/* {id && (
										<div className="flex items-center mt-3">
											<p className="basis-1/3">Vehicle ID#</p>
											<p className="basis-2/3 py-2 px-3 bg-inputBg rounded-full">{id}</p>
										</div>
									)} */}
									<Controller
										name="name"
										control={control}
										rules={{ required: "Nick name is required." }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Nickname"
													isRequired
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.name && <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>}
											</div>
										)}
									/>
									{id && (
										<Input
											value={data?.serviceVehicleId ?? "--"}
											onChange={() => {}}
											label="Vehicle ID #"
											isDisabled={true}
											className="w-full px-2 py-1 text-sm"
										/>
									)}
									<Controller
										name="year"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input value={value} onChange={onChange} label="Year" className="w-full px-2 py-1 text-sm" />
												{errors.year && <p className="text-red-500 text-sm mt-1">{errors.year?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="make"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input value={value} onChange={onChange} label="Make" className="w-full px-2 py-1 text-sm" />
												{errors.make && <p className="text-red-500 text-sm mt-1">{errors.make?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="model"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input value={value} onChange={onChange} label="Model" className="w-full px-2 py-1 text-sm" />
												{errors.model && <p className="text-red-500 text-sm mt-1">{errors.model?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="licensePlate"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="License Plate"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.licensePlate && (
													<p className="text-red-500 text-sm mt-1">{errors.licensePlate?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="vin"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input value={value} onChange={onChange} label="VIN#" className="w-full px-2 py-1 text-sm" />
												{errors.vin && <p className="text-red-500 text-sm mt-1">{errors.vin?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="vehicleType"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={vehicleOptions}
													label="Vehicle Type"
													className="text-sm"
												/>
												{errors.vehicleType && (
													<p className="text-red-500 text-sm mt-1">{errors.vehicleType?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="vehicleClass"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="relative">
												{/* <p className="border rounded-full absolute w-5 h-5 top-0 left-[21%] text-center">i</p> */}
												<Dropdown
													value={value}
													onChange={onChange}
													options={VEHICLE_WEIGHT_CLASS_TYPES}
													label="Vehicle Class"
													className="text-sm"
												/>
												{errors.vehicleClass && (
													<p className="text-red-500 text-sm mt-1">{errors.vehicleClass?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="fuelType"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={fuelOptions}
													label="Fuel Type"
													className="text-sm"
												/>
												{errors.fuelType && <p className="text-red-500 text-sm mt-1">{errors.fuelType?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="payloadCapacity"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Payload Capacity (Lbs)"
													type="number"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.payloadCapacity && (
													<p className="text-red-500 text-sm mt-1">{errors.payloadCapacity?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="status"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={onChange}
													options={statusOptions}
													label="Status"
													className="text-sm"
												/>
												{errors.status && <p className="text-red-500 text-sm mt-1">{errors.status?.message}</p>}
											</div>
										)}
									/>
								</div>
								<div className="w-1/2">
									<Controller
										name="locationAssignment"
										control={control}
										rules={{ required: "Vehicle Location Assignment is required." }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown
													value={value}
													onChange={(e) => {
														console.log({ e });
														onChange(e);
														setValue(
															"locationAssignmentName",
															vehicleLocationOptions.find((el) => el.value === e)?.label ?? "N/A"
														);
													}}
													options={vehicleLocationOptions}
													label="Vehicle Location Assignment *"
													className="text-sm"
												/>
												{errors.locationAssignment && (
													<p className="text-red-500 text-sm mt-1">{errors.locationAssignment?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="dot"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input value={value} onChange={onChange} label="DOT#" className="w-full px-2 py-1 text-sm" />
												{errors.dot && <p className="text-red-500 text-sm mt-1">{errors.dot?.message}</p>}
											</div>
										)}
									/>
									<Controller
										name="intrastate"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Input
													value={value}
													onChange={onChange}
													label="Intrastate#"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.dot && <p className="text-red-500 text-sm mt-1">{errors.intrastate?.message}</p>}
											</div>
										)}
									/>

									<Controller
										name="registrationExpirationDate"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="pt-4">
												<div className="grid grid-cols-3 items-center">
													<p className="text-gray-500">Registration Expiration Date</p>
													<div className="col-span-2">
														<CustomDatePicker
															selectedDate={value}
															setSelectedDate={onChange}
															label="Registration Expiration Date"
														/>
													</div>
												</div>

												{errors.registrationExpirationDate && (
													<p className="text-red-500 text-sm mt-1">{errors.registrationExpirationDate?.message}</p>
												)}
											</div>
										)}
									/>
									<Controller
										name="insuranceExpirationDate"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div className="pt-4">
												<div className="grid grid-cols-3 items-center">
													<p className="text-gray-500">Insurance Expiration Date</p>
													<div className="col-span-2">
														<CustomDatePicker
															selectedDate={value}
															setSelectedDate={onChange}
															label="Insurance Expiration Date"
														/>
													</div>
												</div>

												{errors.insuranceExpirationDate && (
													<p className="text-red-500 text-sm mt-1">{errors.insuranceExpirationDate?.message}</p>
												)}
											</div>
										)}
									/>

									<div>
										<UploadFiles
											control={control}
											validator={(file) =>
												file
													? validateImage(file) || "Please select a valid image file. File should be less than 5 mb."
													: true
											}
											disableError={true}
											errors={errors}
											label="Copy of Registration"
											name="registrationCopy"
											labelStyles="whitespace-pre-wrap"
											existingFile={typeof watch("registrationCopy") === "object" ? watch("registrationCopy") : null}
											onDeleteFile={() => setValue("registrationCopy", null)}
										/>
										{errors.registrationCopy && (
											<p className="text-red-500 text-sm mt-1">{errors.registrationCopy?.message}</p>
										)}
									</div>
									<div>
										<UploadFiles
											control={control}
											validator={(file) =>
												file
													? validateImage(file) || "Please select a valid image file. File should be less than 5 mb."
													: true
											}
											disableError={true}
											errors={errors}
											label="Copy of Insurance"
											name="insuranceCopy"
											labelStyles="whitespace-pre-wrap"
											existingFile={typeof watch("insuranceCopy") === "object" ? watch("insuranceCopy") : null}
											onDeleteFile={() => setValue("insuranceCopy", null)}
										/>
										{errors.insuranceCopy && (
											<p className="text-red-500 text-sm mt-1">{errors.insuranceCopy?.message}</p>
										)}
									</div>
									<div>
										<UploadFiles
											control={control}
											validator={(file) =>
												file
													? validateImage(file) || "Please select a valid image file. File should be less than 5 mb."
													: true
											}
											disableError={true}
											errors={errors}
											label="Copy of Transporter Permit"
											labelStyles="whitespace-pre-wrap"
											name="transporterPermit"
											existingFile={typeof watch("transporterPermit") === "object" ? watch("transporterPermit") : null}
											onDeleteFile={() => setValue("transporterPermit", null)}
										/>
										{errors.transporterPermit && (
											<p className="text-red-500 text-sm mt-1">{errors.transporterPermit?.message}</p>
										)}
									</div>

									<Controller
										name="specialEquipment"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Textarea
													value={value}
													onChange={onChange}
													label="Special Equipment"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.specialEquipment && (
													<p className="text-red-500 text-sm mt-1">{errors.specialEquipment?.message}</p>
												)}
											</div>
										)}
									/>
									<div className="pt-4">
										<Controller
											name="truckCostPerMile"
											control={control}
											render={({ field: { onChange, value } }) => {
												return (
													<InputTreatment
														label="Vehicle Cost Per Mile"
														value={value}
														inputClassName="w-full px-2 py-[18px] text-sm"
														onChange={(e) => {
															const value = e.target.value;

															onChange(value);
														}}
													/>
												);
											}}
										/>
									</div>
									{errors.truckCostPerMile && (
										<p className="text-red-500 text-sm mt-1">{errors.truckCostPerMile?.message}</p>
									)}
									<Controller
										name="notes"
										control={control}
										render={({ field: { value, onChange } }) => (
											<div>
												<Textarea
													value={value}
													onChange={onChange}
													label="Notes"
													className="w-full px-2 py-1 text-sm"
												/>
												{errors.notes && <p className="text-red-500 text-sm mt-1">{errors.notes?.message}</p>}
											</div>
										)}
									/>
								</div>
							</div>
						</div>

						<div className="flex justify-end gap-8 py-10 ">
							<Button
								className={"whitespace-nowrap min-w-fit px-4"}
								btnStyle="form_nav_secondary"
								text="View Service Vehicle List"
								onClick={() => navigate("/admin/assets/service-vehicles")}
							/>
							<Button btnStyle="form_nav_primary" text={"Save"} type="submit" />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewServiceVehicle;
