import React, { useContext, useEffect, useMemo, useState } from "react";
import {
	AuthenticationType,
	AzureMap,
	AzureMapDataSourceProvider,
	AzureMapLayerProvider,
	AzureMapsContext,
} from "react-azure-maps";
import CustomHtmlGeneratorMarker from "../../../../../../components/maps/CustomHtmlGeneratorMarker";
import { limitScrollWheelZoom } from "../../../../../../utils/helpers";
import { data } from "azure-maps-control";
import { FaMinus, FaPlus } from "react-icons/fa";
const authOptions = {
	authType: AuthenticationType.subscriptionKey,
	subscriptionKey: import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY,
};
const CustomAzureRouteMap = ({ allRoutes, prevData, htmlMarkers = [] }) => {
	const { isMapReady, mapRef, removeMapRef } = useContext(AzureMapsContext);

	const renderMarkers = useMemo(() => htmlMarkers.map((marker) => renderHTMLPoint(marker)), [htmlMarkers, allRoutes]);
	useEffect(
		() => () => {
			removeMapRef();
		},
		[]
	);
	function renderHTMLPoint(generatorData) {
		return (
			<CustomHtmlGeneratorMarker
				key={generatorData.id}
				coordinates={generatorData?.serviceAddCoordinates}
				genData={generatorData}
				draggable={true}
				allRoutes={allRoutes}
				color={prevData?.color}
			/>
		);
	}
	useEffect(() => {
		console.log(isMapReady);
		if (isMapReady && mapRef && htmlMarkers.length > 0) {
			const markerPositions = [];
			htmlMarkers.forEach((marker) => {
				markerPositions.push(new data.Position(marker?.serviceAddCoordinates?.lng, marker?.serviceAddCoordinates?.lat));
				markerPositions.push(new data.Position(marker?.randomCoordinates?.lng, marker?.randomCoordinates?.lat));
			});
			const box = data.BoundingBox.fromPositions(markerPositions);
			const center = data.BoundingBox.getCenter(box);
			mapRef.setCamera({ bounds: box, padding: 20, duration: 200, type: "ease" });
			limitScrollWheelZoom(mapRef);
		}
	}, [isMapReady, mapRef]);
	const zoomIn = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom + 1 });
		}
	};

	const zoomOut = () => {
		if (mapRef) {
			const currentZoom = mapRef.getCamera().zoom;
			mapRef.setCamera({ zoom: currentZoom - 1 });
		}
	};
	function clusterClicked(e) {
		console.log("clusterClicked", e);
	}

	return (
		<AzureMap
			options={{
				authOptions: authOptions,
				view: "Auto",
			}}
		>
			<AzureMapDataSourceProvider
				events={{
					dataadded: (e) => {
						console.log("Data on source added", e);
					},
				}}
				id={"markersExample AzureMapDataSourceProvider"}
				options={{ cluster: true, clusterRadius: 2 }}
			>
				<AzureMapLayerProvider
					id={"markersExample AzureMapLayerProvider"}
					options={{
						textOptions: {
							textField: ["get", "generatorName"], //Specify the property name that contains the text you want to appear with the symbol.
							offset: [0, 1.2],
						},
					}}
					events={{
						click: clusterClicked,
						dbclick: clusterClicked,
					}}
					lifecycleEvents={{
						layeradded: () => {
							console.log("LAYER ADDED TO MAP");
						},
					}}
					type={"'SymbolLayer'"}
				/>
				{renderMarkers}
			</AzureMapDataSourceProvider>
			<div className="absolute bottom-5 right-5 flex flex-col gap-2 z-10">
				<button
					type="button"
					onClick={zoomIn}
					className="w-10 h-10 flex items-center justify-center 
																		 bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
																			font-bold rounded shadow-md"
				>
					<FaPlus />
				</button>
				<button
					type="button"
					onClick={zoomOut}
					className="w-10 h-10 flex items-center justify-center 
																		 bg-gray-400 bg-opacity-30 hover:bg-opacity-50 active:bg-opacity-100 
																		 font-bold rounded shadow-md"
				>
					<FaMinus />
				</button>
			</div>
		</AzureMap>
	);
};

export default CustomAzureRouteMap;
