import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaEnvelope, FaLock, FaUser } from "react-icons/fa";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useAuthState } from "react-firebase-hooks/auth";
import {
	auth,
	createNewUserWithEmailAndPassword,
	db,
	signInUserWithEmailAndPassword,
	signOutUser,
} from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addUserToDb } from "../../../utils/firebaseOperations";
import { setUserData } from "../../../store/sliceReducers/octoUserSlice";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { useDispatch } from "react-redux";
import ForgotPasswordEmail from "./ForgotPasswordEmail";

const AuthForm = ({ defaultForm, setDefaultForm }) => {
	return (
		<div className="w-full lg:max-w-xl">
			{defaultForm == "SignUp" && <SignUpForm defaultForm={defaultForm} setDefaultForm={setDefaultForm} />}
			{defaultForm == "LogIn" && <LogInForm defaultForm={defaultForm} setDefaultForm={setDefaultForm} />}
			{defaultForm == "ForgotPassword" && (
				<ForgotPasswordEmail defaultForm={defaultForm} setDefaultForm={setDefaultForm} />
			)}
		</div>
	);
};

export default AuthForm;

const SignUpForm = ({ defaultForm, setDefaultForm }) => {
	const [activeTab, setActiveTab] = useState("octo365");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	// Form State
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		firstName: "",
		lastName: "",
		companyName: "",
	});
	const navigate = useNavigate();
	const [user, loading, error] = useAuthState(auth);
	const [errors, setErrors] = useState({});
	const [isSigningUp, setIsSigningUp] = useState(false);
	const dispatch = useDispatch();

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		setErrors({ ...errors, [e.target.name]: "" });
	};

	// Validation function
	const validateForm = () => {
		let newErrors = {};

		if (!formData.email.includes("@")) {
			newErrors.email = "Invalid email address";
		}
		if (formData.password.length < 6) {
			newErrors.password = "Password must be at least 6 characters";
		}
		if (formData.password !== formData.confirmPassword) {
			newErrors.confirmPassword = "Passwords do not match";
		}
		if (!formData.firstName.trim()) {
			newErrors.firstName = "First name is required";
		}
		if (!formData.lastName.trim()) {
			newErrors.lastName = "Last name is required";
		}
		if (!formData.companyName.trim()) {
			newErrors.companyName = "Company name is required";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			setIsSigningUp(true);
			if (activeTab === "octo365") {
				console.log("OCTO 365 Registration:", formData);
				try {
					toast.loading("Signing Up...");
					await createNewUserWithEmailAndPassword(formData.email, formData.password);
					localStorage.setItem("companyName", formData.companyName);
					localStorage.setItem("transporterName", `${formData.firstName + " " + formData.lastName}`);
					toast.dismiss();
				} catch (error) {
					toast.dismiss();
					console.log(error);
					if (error.code === "auth/email-already-in-use") {
						toast.error("Email already in use");
					} else {
						toast.error("Error during signing up");
					}
				}
			} else {
				console.log("Marketplace Registration:", formData);
				try {
					let resp = await createNewUserWithEmailAndPassword(formData.email, formData.password);
					let timestamp = Timestamp.now();
					let dataToBeSent = {
						email: formData.email,
						name: formData.firstName + " " + formData.lastName,
						firstName: formData.firstName,
						lastName: formData.lastName,
						companyName: formData.companyName,
						profileCompleted: false,
						approved: false,
						popularityIndexCount: 0,
						contacts: [],
						category: [],
						blocked: false,
						deleted: false,
						createdAt: timestamp,
						userType: "Vendor",
					};
					dispatch(setUserData(dataToBeSent));
					await addUserToDb("octoMarketUsers", resp.user.uid, dataToBeSent);
				} catch (error) {
					console.log(error);
					if (error.code == "auth/email-already-in-use") {
						return toast.error("User already exists!");
					}
				}
			}
			setIsSigningUp(false);
		}
	};

	return (
		<form onSubmit={handleSubmit} className="space-y-4 bg-[#F6F8FA] shadow-lg p-8 w-full mb-12">
			{/* Tab Switcher */}
			<div className="relative flex justify-center text-center border-b mx-6 text-sm border-gray-300">
				{["octo365", "marketplace"].map((tab) => (
					<button
						key={tab}
						className={`relative px-6 py-2 w-full font-medium text-gray-500 focus:outline-none ${
							activeTab === tab ? "bg-gradient-to-r from-[#007AFF] to-[#4CD964] bg-clip-text text-transparent" : ""
						}`}
						onClick={() => setActiveTab(tab)}
						type="button"
					>
						{tab === "octo365" ? "OCTO 365" : "OCTO Marketplace"}
					</button>
				))}
				{/* Sliding Indicator */}
				<motion.div
					className="absolute bottom-0 w-1/2 h-[2px] bg-gradient-to-r from-blue-500 to-green-400 rounded-t-full"
					initial={false}
					animate={{
						left: activeTab === "octo365" ? "0%" : "50%",
					}}
					transition={{ type: "keyframes", stiffness: 200, damping: 20 }}
				/>
			</div>
			<div className="flex flex-col px-8 lg:px-12 gap-4">
				{/* Email Address */}
				<div>
					<label className="block text-xs font-medium text-gray-700">Email Address</label>
					<div className="relative mt-1">
						<FaEnvelope className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							placeholder="Enter your email address"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
					</div>
					{errors.email && <p className="text-red-500 mt-1 text-xs">{errors.email}</p>}
				</div>

				{/* Password */}
				<div>
					<label className="block text-xs font-medium text-gray-700">Password</label>
					<div className="relative mt-1">
						<FaLock className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type={showPassword ? "text" : "password"}
							name="password"
							value={formData.password}
							onChange={handleChange}
							placeholder="Create password"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
						<button
							type="button"
							onClick={() => setShowPassword(!showPassword)}
							className="absolute right-4 top-3.5 text-gray-500"
						>
							{showPassword ? <MdVisibility size={20} /> : <MdVisibilityOff size={20} />}
						</button>
					</div>
					{errors.password && <p className="text-red-500 mt-1 text-xs">{errors.password}</p>}
				</div>

				{/* Confirm Password */}
				<div>
					<label className="block text-xs font-medium text-gray-700">Confirm Password</label>
					<div className="relative mt-1">
						<FaLock className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type={showConfirmPassword ? "text" : "password"}
							name="confirmPassword"
							value={formData.confirmPassword}
							onChange={handleChange}
							placeholder="Confirm password"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
						<button
							type="button"
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
							className="absolute right-4 top-3.5 text-gray-500"
						>
							{showConfirmPassword ? <MdVisibility size={20} /> : <MdVisibilityOff size={20} />}
						</button>
					</div>
					{errors.confirmPassword && <p className="text-red-500 mt-1 text-xs">{errors.confirmPassword}</p>}
				</div>

				{/* First Name */}
				<div>
					<label className="block text-xs font-medium text-gray-700">First Name</label>
					<div className="relative mt-1">
						<FaUser className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type="text"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							placeholder="Enter your first name"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
					</div>
					{errors.firstName && <p className="text-red-500 mt-1 text-xs">{errors.firstName}</p>}
				</div>

				{/* Last Name */}
				<div>
					<label className="block text-xs font-medium text-gray-700">Last Name</label>
					<div className="relative mt-1">
						<FaUser className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type="text"
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							placeholder="Enter your last name"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
					</div>
					{errors.lastName && <p className="text-red-500 mt-1 text-xs">{errors.lastName}</p>}
				</div>

				<div>
					<label className="block text-xs font-medium text-gray-700">Company Name</label>
					<div className="relative mt-1">
						<FaUser className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type="text"
							name="companyName"
							value={formData.companyName}
							onChange={handleChange}
							placeholder="Company name"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
					</div>
					{errors.companyName && <p className="text-red-500 mt-1 text-xs">{errors.companyName}</p>}
				</div>

				{/* Submit Button */}
				<button
					type="submit"
					className={`w-full mt-2 p-3 font-semibold rounded-md ${
						isSigningUp
							? "bg-primaryGray border border-cardTextGray text-cardTextGray"
							: " text-white bg-blue-500 hover:bg-blue-600"
					}`}
				>
					{isSigningUp ? "Signing Up..." : "Sign Up"}
				</button>
				<p className="text-center">
					Already a member?{" "}
					<button onClick={() => setDefaultForm("LogIn")} className="text-[#007AFF] underline hover:text-indigo-500">
						Log in
					</button>
				</p>
			</div>
		</form>
	);
};

const LogInForm = ({ defaultForm, setDefaultForm }) => {
	const [activeTab, setActiveTab] = useState("octo365");
	const [showPassword, setShowPassword] = useState(false);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const navigate = useNavigate();
	const [user, loading, error] = useAuthState(auth);
	const [errors, setErrors] = useState({});
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [checked, setChecked] = useState(false);
	const dispatch = useDispatch();

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		setErrors({ ...errors, [e.target.name]: "" });
	};

	const validateForm = () => {
		let newErrors = {};

		if (!formData.email.includes("@")) {
			newErrors.email = "Invalid email address";
		}
		if (formData.password.length < 6) {
			newErrors.password = "Password must be at least 6 characters";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			setIsLoggingIn(true);
			try {
				await signInUserWithEmailAndPassword(formData.email, formData.password);
			} catch (error) {
				console.log(error, formData);
				switch (error.code) {
					case "auth/user-not-found":
						toast.error("Invalid credentials.");
						break;
					case "auth/wrong-password":
						toast.error("Invalid credentials.");
						break;
					case "auth/invalid-credential":
						toast.error("Invalid credentials.");
						break;
					case "auth/too-many-requests":
						toast.error("Too many failed attempts. Please try again later.");
						break;
					default:
						toast.error("Something went wrong! Please try again.");
						break;
				}
			} finally {
				setIsLoggingIn(false);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit} className="space-y-4 bg-[#F6F8FA] shadow-lg p-8 w-full mb-12">
			<div className="flex flex-col px-8 lg:px-12 gap-4">
				<div className="relative flex justify-center text-center border-b text-sm border-gray-300">
					<button
						className={`relative px-6 py-3 w-full font-medium focus:outline-none bg-gradient-to-r from-[#007AFF] to-[#4CD964] bg-clip-text text-transparent`}
						type="button"
						disabled={true}
					>
						Sign In
					</button>
					{/* Sliding Indicator */}
					<motion.div
						className="absolute bottom-0 w-full h-[3px] bg-gradient-to-r from-blue-500 to-green-400 rounded-t-full"
						transition={{ type: "keyframes", stiffness: 200, damping: 20 }}
					/>
				</div>
				{/* Email Address */}
				<div>
					<label className="block text-xs font-medium text-gray-700">Email Address</label>
					<div className="relative mt-1">
						<FaEnvelope className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							placeholder="Enter your email address"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
					</div>
					{errors.email && <p className="text-red-500 mt-1 text-xs">{errors.email}</p>}
				</div>

				{/* Password */}
				<div>
					<label className="block text-xs font-medium text-gray-700">Password</label>
					<div className="relative mt-1">
						<FaLock className="absolute left-4 top-3.5 text-gray-400" size={20} />
						<input
							type={showPassword ? "text" : "password"}
							name="password"
							value={formData.password}
							onChange={handleChange}
							placeholder="Enter password"
							className="custom-autofill w-full pl-12 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
						/>
						<button
							type="button"
							onClick={() => setShowPassword(!showPassword)}
							className="absolute right-4 top-3.5 text-gray-500"
						>
							{showPassword ? <MdVisibility size={20} /> : <MdVisibilityOff size={20} />}
						</button>
					</div>
					{errors.password && <p className="text-red-500 mt-1 text-xs">{errors.password}</p>}
				</div>
				<div className="flex justify-between items-center">
					<Checkbox label="Remember Me" checked={checked} onChange={setChecked} />
					<button
						onClick={() => setDefaultForm("ForgotPassword")}
						type="button"
						className="text-red-600 text-sm hover:text-red-700 transition-colors duration-300 cursor-pointer"
					>
						Forgot password?
					</button>
				</div>
				{/* Submit Button */}
				<button
					type="submit"
					className={`w-full p-3 font-semibold rounded-md ${
						isLoggingIn
							? "bg-primaryGray border border-cardTextGray text-cardTextGray"
							: " text-white bg-blue-500 hover:bg-blue-600"
					}`}
				>
					{isLoggingIn ? "Logging In..." : "Log In"}
				</button>
				<p className="text-center">
					Not a member?{" "}
					<button onClick={() => setDefaultForm("SignUp")} className="text-[#007AFF] underline hover:text-indigo-500">
						Sign up
					</button>
				</p>
			</div>
		</form>
	);
};

const Checkbox = ({ label, checked, onChange, className = "" }) => {
	return (
		<div className={`flex items-center ${className}`} onClick={() => onChange(!checked)}>
			<div
				className={`h-5 w-5 rounded-sm flex items-center border border-cardTextGray border-opacity-20 justify-center transition-colors duration-200 cursor-pointer`}
			>
				{checked && (
					<svg className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="black">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
					</svg>
				)}
			</div>
			<label className="ml-2 block text-sm text-cardTextGray cursor-default">{label}</label>
		</div>
	);
};
