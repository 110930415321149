import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllTreatment, deletetreatment } from "../../../../../utils/firebaseOperations";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
import { motion, AnimatePresence } from "framer-motion";
import MiniTreatmentRegModal from "../../../../../components/userAccBtn/MiniTreatmentRegModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../../config/firebase";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { createPortal } from "react-dom";
import useTUserContext from "../../../../../context/TransporterUserContext";

export const statusOptions = [
	{ label: "Active", value: "ACTIVE" },
	{ label: "Inactive", value: "INACTIVE" },
];

const TreatmentFacilities = () => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [data, setData] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedStatuses, setSelectedStatuses] = useState(["all", "Active", "Inactive"]);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [tooltipTimer, setTooltipTimer] = useState(null);
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [modalData, setModalData] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();
	const [isTooltipActive, setIsTooltipActive] = useState(false);
	const [isHoveringTooltip, setIsHoveringTooltip] = useState(false);
	const { user, loading } = useTUserContext();

	useEffect(() => {
		const fetchTreatments = async () => {
			try {
				const unsubscribe = getAllTreatment((treatments) => {
					setData(treatments);
				}, user?.uid);

				// You can also handle isLoading state when the data stream starts
				setLoading(false);
				return () => {
					unsubscribe(); // Cleanup subscription on unmount
				};
			} catch (err) {
				console.error("Error fetching treatment data:", err);
				setError("Failed to load treatment data.");
				setLoading(false);
			}
		};

		// Fetch treatments and subscribe to real-time data
		if (user) fetchTreatments();
	}, [user]);

	const handleRowClick = (id) => {
		// navigate(`/admin/assets/treatment/add-treatment/${id}/edit`);
	};

	const handleDelete = async (id) => {
		try {
			const result = await deletetreatment(id);
			if (result.success) {
				setData((prevData) => prevData.filter((item) => item.id !== id));
				setSelectedTreatments((prev) => prev.filter((index) => filteredData[index].id !== id));
			} else {
				console.error("Deletion failed");
			}
		} catch (error) {
			console.error("Error deleting treatment:", error);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedTreatments((prev) => {
			const newSelection = [...prev];
			if (newSelection.includes(index)) {
				newSelection.splice(newSelection.indexOf(index), 1);
			} else {
				newSelection.push(index);
			}
			return newSelection;
		});
	};

	const handleSelectAllChange = () => {
		setSelectAll(!selectAll);
		setSelectedTreatments(selectAll ? [] : filteredData.map((_, index) => index));
	};

	const statusFilterChangeHandler = (selected) => {
		if (selected.includes(["all", "ACTIVE", "INACTIVE"])) {
			setSelectedStatuses(["all", "ACTIVE", "INACTIVE"]);
		} else {
			setSelectedStatuses([...selected]);
		}
	};

	useEffect(() => {
		console.log({ selectedStatuses });
	}, [selectedStatuses]);

	const filteredData = useMemo(() => {
		return data.filter((item) => {
			if (
				selectedStatuses.includes("all") ||
				selectedStatuses.includes("ACTIVE") ||
				selectedStatuses.includes("INACTIVE")
			) {
				return true;
			}
			return selectedStatuses.includes(item.treatmentStatus);
		});
	}, [data, selectedStatuses]);

	const clickHandler = (action) => {
		if (action === "Delete") {
			selectedTreatments.forEach((index) => {
				handleDelete(filteredData[index].id);
			});
		} else if (action === "Edit") {
			selectedTreatments.forEach((index) => {
				handleRowClick(filteredData[index].id);
			});
		} else {
			console.log(`${action} clicked`);
		}
	};

	const anyChecked = selectedTreatments.length > 0;

	const handleMouseEnter = (item, event) => {
		clearTimeout(tooltipTimer);
		const timer = setTimeout(() => {
			setActiveTooltip(item.id);
			setModalData(item);
			updateTooltipPosition(event);
			setIsTooltipActive(true);
		}, 3000);
		setTooltipTimer(timer);
		setHoveredRow(item.id);
	};

	const handleMouseLeave = () => {
		if (!isHoveringTooltip) {
			clearTimeout(tooltipTimer);
			setActiveTooltip(null);
			setIsTooltipActive(false);
			setHoveredRow(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 500;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
		} else {
			left = cursorX + offset;
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const openAddTreatmentModal = () => {
		setIsModalOpen(true);
	};

	if (isLoading) {
		return (
			<div className="p-6">
				<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<div className="flex justify-between mx-8">
						<div className="text-lg font-normal text-black pt-6">Treatment Facility (TF) / Transfer Station (TS)</div>
						<div className="my-4 px-2">
							<MultiselectDropdown
								buttonText="TF/TS Status"
								options={[
									{ label: "All", value: "all" },
									{ label: "Active", value: "Active" },
									{ label: "Inactive", value: "Inactive" },
								]}
								selectedOptions={selectedStatuses}
								onSelectedOptionsChange={statusFilterChangeHandler}
							/>
						</div>
					</div>
					<div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-[#E5F2FF]">
						<div className="flex items-center text-sm">
							<div className="truncate w-80">Name</div>
							{/* <div className="truncate w-40">OCTO Number</div> */}
							<div className="truncate w-[22rem]">Address</div>
							<div className="truncate w-44">Email</div>
							<div className="truncate w-36">Phone</div>
							<div className="truncate w-60">Facility Type</div>
							<div className="truncate w-32">Status</div>
						</div>
					</div>
					<div className="overflow-y-scroll">
						{Array.from({ length: 9 }).map((_, index) => (
							<div
								key={index}
								className="flex flex-col md:flex-row items-start pt-2 border-b pl-8 border-gray-300 animate-pulse"
								style={{ minHeight: "80px" }}
							>
								<div className="w-full md:w-1/5 text-sm font-normal">
									<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
								</div>
								{/* <div className="w-full md:w-1/5 text-sm font-normal">
                  <div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
                </div> */}
								<div className="w-full md:w-1/5 text-sm font-normal pt-2 pl-2">
									<div className="h-4 bg-gray-300 rounded mt-1 w-3/4"></div>
								</div>
								<div className="w-full md:w-1/5 pt-4 text-sm font-normal">
									<div className="h-4 bg-gray-300 rounded w-3/4"></div>
								</div>
								<div className="w-full md:w-1/5 pt-4 text-sm font-normal">
									<div className="h-4 bg-gray-300 rounded w-3/4"></div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}

	if (error) {
		return <div className="text-red-600">{error}</div>;
	}

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="flex justify-between">
					<div className="text-lg font-normal ml-8 text-black pt-6">
						Treatment Facility (TF) / Transfer Station (TS)
					</div>
					<div className="my-4 px-4 mr-6">
						<MultiselectDropdown
							buttonText="TF/TS Status"
							options={[
								{ label: "All", value: "all" },
								{ label: "Active", value: "Active" },
								{ label: "Inactive", value: "Inactive" },
							]}
							selectedOptions={selectedStatuses}
							onSelectedOptionsChange={statusFilterChangeHandler}
						/>
					</div>
				</div>

				<div className="overflow-x-auto overflow-y-hidden" style={{ height: "calc(100% - 10px)" }}>
					<div className="bg-[#E5F2FF] flex font-medium py-4 min-w-fit">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input
								type="checkbox"
								className="w-4 h-4 bg-white"
								checked={selectAll}
								onChange={handleSelectAllChange}
							/>
						</div>
						<div className="flex items-center text-sm font-semibold">
							<div className="truncate w-80">Name</div>
							{/* <div className="truncate w-40">OCTO Number</div> */}
							<div className="truncate w-[22rem]">Address</div>
							<div className="truncate w-44">Email</div>
							<div className="truncate w-36">Phone</div>
							<div className="truncate w-60">Facility Type</div>
							<div className="truncate w-32">Status</div>
						</div>
					</div>

					<div className="flex flex-col">
						{filteredData.length === 0 ? (
							<div className="min-w-fit overflow-y-scroll pb-2 h-[68vh] flex justify-center items-center">
								<div className="w-full text-center py-4 text-cardTextGray">
									No Treatment Facility (TF) / Transfer Station (TS) Found
								</div>
							</div>
						) : (
							<div className="min-w-fit overflow-y-scroll pb-2 h-[68vh]">
								{filteredData.map((item, index) => (
									<Link
										to={`../treatment/add-treatment/${item.id}/edit`}
										key={item.id}
										className="flex items-center transition-colors duration-300 border-b min-w-fit border-gray-300 hover:bg-cardTextGray hover:bg-opacity-20 hover:cursor-pointer text-sm"
										// onMouseEnter={(e) => handleMouseEnter(item, e)}
										// onMouseLeave={handleMouseLeave}
										style={{ minHeight: "45px" }}
									>
										<div className="mx-8 flex items-center mt-1">
											<input
												key={item.id}
												type="checkbox"
												className="w-4 h-4 bg-white"
												checked={selectedTreatments.includes(index)}
												onChange={(e) => {
													handleCheckboxChange(index);
												}}
												onClick={(e) => e.stopPropagation()}
											/>
										</div>
										<div className="truncate min-w-80" onClick={() => handleRowClick(item.id)}>
											{item.name || "Name not provided"}
										</div>
										{/* <div className="truncate min-w-40 text-cardTextBlue" onClick={() => handleRowClick(item.id)}>{item.octoNumber || "octo Number not provided"}</div> */}
										<div className="truncate min-w-[22rem]" onClick={() => handleRowClick(item.id)}>
											{item.street || "Address not provided"} {item.city && `, ${item.city}`}{" "}
											{item.state && `, ${item.state}`} {item.zipCode && ` ${item.zipCode}`}
										</div>
										<div className="truncate min-w-44" onClick={() => handleRowClick(item.id)}>
											{item.email || "Email not provided"}
										</div>
										<div
											className="truncate min-w-36 hover:text-blueText hover:underline"
											onClick={() => handleRowClick(item.id)}
										>
											{item.phone || "Phone not provided"}
										</div>
										<div className="truncate min-w-60" onClick={() => handleRowClick(item.id)}>
											{item.medicalwasteServices?.length ? (
												item.medicalwasteServices.map((service, index) => (
													<div key={index} className="mb-2">
														{service || "Service not specified"}
													</div>
												))
											) : (
												<div>Not provided</div>
											)}
										</div>

										<div className="truncate min-w-32" onClick={() => handleRowClick(item.id)}>
											{item.treatmentStatus || ""}
										</div>
									</Link>
								))}
							</div>
						)}
					</div>
				</div>
			</div>

			<FooterActionItems treatmentsSelected={selectedTreatments} treatments={filteredData} />

			{/* <div
				className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md bg-white px-8 transition-all duration-300 ease-in-out ${selectedTreatments.length > 0 ? "translate-y-0" : "translate-y-full"
					}`}
			>
				<div className="w-full py-4 flex justify-between items-center">
					<span className="truncate">{selectedTreatments.length} item(s) selected</span>
					<div className="space-x-2">
					{["Export", "Merge", "Share"].map(
							(actionBtn) => (
								<button
									key={actionBtn}
									className={`px-4 py-2 rounded-lg ${actionBtn === "Delete"
										? "bg-lightBlue text-white hover:bg-cardTextBlue"
										: "bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"
										} border transition-colors duration-200 ease-in-out`}
									onClick={() => clickHandler(actionBtn)}
								>
									{actionBtn}
								</button>
							)
						)}
					</div>
				</div>
			</div> */}

			{!anyChecked && (
				<div className="fixed bottom-8 right-8">
					<button
						onClick={openAddTreatmentModal}
						className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
						</svg>
						<span>Add New TF/TS</span>
					</button>
					{isModalOpen && <MiniTreatmentRegModal onClose={() => setIsModalOpen(false)} activeSubmenu="treatment" />}
				</div>
			)}
			<AnimatePresence>
				{activeTooltip && modalData && isTooltipActive && (
					<motion.div
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
						}}
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setIsHoveringTooltip(true)}
						onMouseLeave={() => setIsHoveringTooltip(false)}
					>
						<ToolTipContent treatments={modalData} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const ToolTipContent = ({ treatments }) => {
	const details = [
		{ label: "Name", value: treatments.name || "--" },
		{ label: "Phone", value: treatments.phone || "--" },
		{ label: "Email", value: treatments.email || "--" },
		{ label: "Suite", value: treatments.suite || "--" },
		{ label: "City", value: treatments.city || "--" },
		{ label: "State", value: treatments.state || "--" },
		{ label: "ZipCode", value: treatments.zipCode || "--" },
		{ label: "Treatment Status", value: treatments.treatmentStatus || "--" },
		{ label: "Notification Type", value: treatments.notificationTypeOption || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className="w-full flex">
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

const ExportModal = ({ isOpen, isExporting, progress, onClose }) => {
	if (!isOpen) return null;

	return createPortal(
		<div className="fixed inset-0 z-[9999] flex items-center justify-center">
			<div className="modal-overlay absolute inset-0 bg-black bg-opacity-50" />
			<div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
				<div className="p-8">
					{/* Header */}
					<div className="mb-6">
						<h3 className="text-2xl font-semibold text-gray-900">Exporting Data</h3>
						<p className="mt-2 text-sm text-gray-600">Please wait while we prepare your file...</p>
					</div>

					{/* Progress Section */}
					<div className="space-y-6">
						{/* Progress Bar */}
						<div className="w-full bg-gray-100 rounded-full h-3">
							<div
								className="bg-lightBlue h-3 rounded-full transition-all duration-300 ease-out"
								style={{ width: `${progress}%` }}
							/>
						</div>

						{/* Progress Details */}
						<div className="flex justify-between items-center">
							<p className="text-sm text-gray-600">{progress < 100 ? "Processing..." : "Download Complete!"}</p>
							<span className="text-sm font-medium text-gray-900">{Math.round(progress)}%</span>
						</div>

						{/* Status Message */}
						<div className="text-center">
							{progress === 100 ? (
								<p className="text-green-600 font-medium">
									Your download will begin automatically. This window will close shortly.
								</p>
							) : (
								<p className="text-gray-600">Please don't close this window while exporting...</p>
							)}
						</div>
					</div>

					{/* Close Button - Only shown when not exporting */}
					{!isExporting && (
						<button
							onClick={onClose}
							className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 
					focus:outline-none focus:text-gray-500 transition-colors duration-200"
							aria-label="Close modal"
						>
							<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>,
		document.body
	);
};

const FooterActionItems = ({ treatmentsSelected, treatments }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleExport = async () => {
		setIsExporting(true);
		setIsModalOpen(true);
		setProgress(0);

		try {
			// Step 1: Data preparation (20% of progress)
			console.log("log", treatments);

			const data = treatments.filter((_, index) => treatmentsSelected.includes(index));

			const worksheetData = data.map((item, index) => {
				setProgress((index / data.length) * 20);

				return {
					Name: item.name,
					Address: item.street + item.city + item.state + item.zipCode,
					Email: item.email,
					Phone: item.phone,
					"Facility Type": item.medicalwasteServices?.join(", ") || "",
					Status: item.treatmentStatus,
				};
			});
			setProgress(20);

			// Step 2: Create worksheet (40% of progress)
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.json_to_sheet(worksheetData);
			XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");
			setProgress(40);

			// Step 3: Convert to binary (70% of progress)
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			setProgress(70);

			// Step 4: Create blob and prepare for download (90% of progress)
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			setProgress(90);

			// Step 5: Trigger download (100% of progress)
			saveAs(blob, "exported_data.xlsx");
			setProgress(100);

			// Reset after showing 100% completion
			setTimeout(() => {
				setIsExporting(false);
				setProgress(0);
				setIsModalOpen(false);
			}, 1000);
		} catch (error) {
			console.error("Export failed:", error);
			setIsExporting(false);
			setProgress(0);
			setIsModalOpen(false);
		}
	};

	return (
		<div
			className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md bg-white px-8 transition-all duration-300 ease-in-out ${
				treatmentsSelected?.length > 0 ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{treatmentsSelected?.length} item(s) selected</span>
				<div className="space-x-2">
					<button
						className={
							"px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out disabled:bg-gray-300"
						}
						onClick={() => handleExport()}
					>
						Export
					</button>
					<ExportModal
						isOpen={isModalOpen}
						isExporting={isExporting}
						progress={progress}
						onClose={() => setIsModalOpen(false)}
					/>
				</div>
			</div>
		</div>
	);
};

export default TreatmentFacilities;
