import PropTypes from "prop-types";
import { useEffect, useRef, useState, forwardRef } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

const Dropdown = forwardRef(
	(
		{
			id,
			label,
			options,
			value,
			onChange,
			placeholder = "",
			styles = "",
			margin = "4",
			error,
			isDisabled,
			isRequired = false,
			noCursor = false,
			buttonStyle = "",
			listHeight = "",
			disabledBgColor = "",
			disabledTextColor = "",
			onBlur,
		},
		ref
	) => {
		const [isOpen, setIsOpen] = useState(false);
		const [highlightedIndex, setHighlightedIndex] = useState(-1);
		const dropdownRef = useRef(null);
		const buttonRef = useRef(null);
		const optionsRef = useRef([]);
		const selectActionRef = useRef(false);

		const toggleDropdown = () => {
			if (noCursor) return;
			setIsOpen(!isOpen);
		};

		const handleSelect = (option) => {
			selectActionRef.current = true;
			onChange(option.value);
			setIsOpen(false);
			buttonRef.current.focus();
			
			if (onBlur) {
				setTimeout(() => {
					onBlur();
					selectActionRef.current = false;
				}, 10);
			}
		};

		const handleKeyDown = (event) => {
			if (noCursor) return;
			if (event.key === "Enter" || event.key === " ") {
				event.preventDefault();
				if (!isOpen) {
					setIsOpen(true);
				} else if (highlightedIndex !== -1) {
					handleSelect(options[highlightedIndex]);
				}
			} else if (event.key === "Escape") {
				setIsOpen(false);
				buttonRef.current.focus();
			} else if (isOpen) {
				switch (event.key) {
					case "ArrowDown":
						event.preventDefault();
						setHighlightedIndex((prev) => (prev < options.length - 1 ? prev + 1 : 0));
						break;
					case "ArrowUp":
						event.preventDefault();
						setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : options.length - 1));
						break;
					default:
						break;
				}
			}
		};

		const handleBlur = (event) => {
			if (selectActionRef.current) return;
			
			if (dropdownRef.current && !dropdownRef.current.contains(event.relatedTarget) && onBlur) {
				onBlur();
			}
		};

		useEffect(() => {
			const handleClickOutside = (event) => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
					
					if (onBlur && !selectActionRef.current) {
						onBlur();
					}
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => document.removeEventListener("mousedown", handleClickOutside);
		}, [onBlur]);

		useEffect(() => {
			if (!isOpen) {
				setHighlightedIndex(-1);
			}
		}, [isOpen]);

		useEffect(() => {
			if (isOpen && highlightedIndex !== -1 && optionsRef.current[highlightedIndex]) {
				optionsRef.current[highlightedIndex].scrollIntoView({ block: "nearest" });
			}
		}, [isOpen, highlightedIndex]);

		const selectedOption = options.find((option) => option.value === value);

		return (
			<div
				className={`relative flex ${styles ?? "justify-between items-center "} my-${margin} ${""}`}
				ref={dropdownRef}
			>
				{label && (
					<label
						htmlFor={id ? id : label?.toLowerCase().replace(" ", "-")}
						className={`${styles ? "w-full" : label ? "w-1/3" : "w-full"} text-inputLabel font-normal`}
					>
						{`${label} ${isRequired ? "*" : ""}`}
					</label>
				)}
				<button
					id={id ? id : label?.toLowerCase().replace(" ", "-")}
					onClick={toggleDropdown}
					onKeyDown={handleKeyDown}
					onBlur={handleBlur}
					type="button"
					ref={buttonRef}
					disabled={isDisabled}
					aria-haspopup="listbox"
					aria-expanded={isOpen}
					aria-required={isRequired}
					className={`${styles ? "w-full" : label ? "w-2/3" : "w-full"}  ${
						label ? "bg-inputBg" : "bg-inputBg"
					} border-none rounded-full max-h-9 px-4 text-left text-cardTextGray flex justify-between items-center focus:outline-none focus:ring-1 focus:ring-dashInActiveBtnText ${buttonStyle}`}
					style={{ height: "40px" }}
				>
					<span className={`${!selectedOption ? "opacity-50" : "truncate"}`}>
						{selectedOption ? selectedOption.label : placeholder}
					</span>
					<HiOutlineChevronDown
						className={`h-5 w-5 text-cardTextGray transition-transform duration-250 ${
							isOpen ? "transform rotate-180" : ""
						} ${label ? (isDisabled ? "hidden" : "block") : "block"}`}
					/>
				</button>
				{isOpen && (
					<ul
						role="listbox"
						className={`absolute z-20 overflow-y-scroll
            min-w-fit ${listHeight?.length > 0 ? listHeight : "max-h-64"} ${
							styles ? "w-full" : label ? "w-2/3 right-0" : "w-full left-0"
						} text-nowrap top-full mt-1 bg-white border border-dropdownDownArrow rounded-2xl shadow-md`}
					>
						{options.map((option, index) => (
							<li
								key={option.value === null ? index : option.value}
								ref={(el) => (optionsRef.current[index] = el)}
								onClick={() => option.value !== null && handleSelect(option)}
								onMouseEnter={() => setHighlightedIndex(index)}
								role="option"
								aria-selected={option.value === value}
								className={`px-4 h-9 py-2 flex flex-col justify-center hover:bg-[#BBBBBB3B] cursor-pointer transition-colors duration-150 ${
									value === option.value ? "bg-[#BBBBBB3B]" : ""
								} ${
									option?.isDisabled === true &&
									`${
										disabledBgColor && disabledTextColor
											? `bg-${disabledBgColor} text-${disabledTextColor} hover:bg-${disabledBgColor} hover:text-${disabledTextColor}`
											: "bg-cardTextBlue text-white hover:bg-cardTextBlue font-semibold"
									}`
								}`}
							>
								{option.label}
							</li>
						))}
					</ul>
				)}
				{error && <p className="absolute left-0 bottom-[-20px] text-red-500 text-xs">{error}</p>}
			</div>
		);
	}
);

Dropdown.propTypes = {
	buttonStyle: PropTypes.string,
	error: PropTypes.any,
	id: PropTypes.any,
	isDisabled: PropTypes.any,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	listHeight: PropTypes.string,
	margin: PropTypes.string,
	noCursor: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	styles: PropTypes.string,
	value: PropTypes.any,
};

Dropdown.displayName = "Dropdown";

export default Dropdown;