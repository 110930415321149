import React from "react";
import {
	TbDrone,
	TbReportMoney,
	TbChartArcs,
	TbLogout,
	TbMist,
	TbNews,
	TbTruckReturn,
	TbShredder,
} from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { PiNotepad, PiTrashBold } from "react-icons/pi";
import { BiChart } from "react-icons/bi";
import { LuUsers } from "react-icons/lu";
import { RiArtboard2Line } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { FcBiohazard } from "react-icons/fc";
import { LiaBiohazardSolid } from "react-icons/lia";
import { TbFileDollar } from "react-icons/tb";
import { TfiBarChart } from "react-icons/tfi";
import { BsSend } from "react-icons/bs";
import { TbSwitch3 } from "react-icons/tb";
import { FaDribbble } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { TbRouteX2 } from "react-icons/tb";
import { TbUserStar } from "react-icons/tb";
import { TbReplace } from "react-icons/tb";
import { TbReport } from "react-icons/tb";
import { TbFidgetSpinner } from "react-icons/tb";
import { TbTrolley } from "react-icons/tb";
import { TbLiveView } from "react-icons/tb";
import { TbZoomInArea } from "react-icons/tb";
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { PiBuildingApartment } from "react-icons/pi";
import { LiaSitemapSolid } from "react-icons/lia";
import { TbGps } from "react-icons/tb";
import { TbRoute } from "react-icons/tb";
import { VscCalendar } from "react-icons/vsc";
import { TbPigMoney } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { TbReportSearch } from "react-icons/tb";
import { PiHashStraight } from "react-icons/pi";
import { LiaChessPawnSolid } from "react-icons/lia";
import { HiOutlineArrowsPointingIn } from "react-icons/hi2";
import { TbBuildingStore } from "react-icons/tb";
import { LiaSatelliteDishSolid } from "react-icons/lia";
import { TbMapRoute } from "react-icons/tb";
import { PiPlugs } from "react-icons/pi";
import { HiArrowPath } from "react-icons/hi2";
import { HiArrowRightEndOnRectangle } from "react-icons/hi2";
import { HiArrowRightStartOnRectangle } from "react-icons/hi2";
import MarketIcon from "./MarketIcon";

const RouteOptimizationIcon = ({ size = 24, className = "", active, ...props }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 18C18.4183 18 22 14.4183 22 10C22 8.89187 21.7747 7.83635 21.3674 6.87677L16.1221 12.1221L18.2426 14.2426C17.1569 15.3284 15.6569 16 14 16C10.6863 16 8 13.3137 8 10C8 6.68629 10.6863 4 14 4C15.2958 4 16.4957 4.41079 17.4765 5.10925L13.2929 9.29289L14 10L14.7071 10.7071L14.7079 10.7079L20.3205 5.09522C20.3203 5.09493 20.3201 5.09464 20.3199 5.09436L21.7071 3.70711L20.2929 2.29289L18.9056 3.68014C17.551 2.62708 15.8487 2 14 2C9.58172 2 6 5.58172 6 10V18H14ZM18 20H6V22H18V20Z"
			className={active ? "fill-[url(#gradient)]" : "fill-current hover:fill-[url(#gradient)]"}
		/>
	</svg>
);

const SatelliteLocationIcon = ({ size = 24, className = "", active, ...props }) => (
	<svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.73 12.02L21.71 8.04C22.1 7.65 22.1 7.02 21.71 6.63L17.37 2.29C16.98 1.9 16.35 1.9 15.96 2.29L11.98 6.27L8.00002 2.29C7.80002 2.1 7.55002 2 7.29002 2C7.04002 2 6.78002 2.1 6.59002 2.29L2.25002 6.63C1.86002 7.02 1.86002 7.65 2.25002 8.04L6.23002 12.02L2.25002 16C1.86002 16.39 1.86002 17.02 2.25002 17.41L6.59002 21.75C6.98002 22.14 7.61002 22.14 8.00002 21.75L11.98 17.77L15.96 21.75C16.16 21.95 16.41 22.04 16.67 22.04C16.93 22.04 17.18 21.94 17.38 21.75L21.72 17.41C22.11 17.02 22.11 16.39 21.72 16L17.73 12.02ZM12 9C12.55 9 13 9.45 13 10C13 10.55 12.55 11 12 11C11.45 11 11 10.55 11 10C11 9.45 11.45 9 12 9ZM3.66002 7.34L7.29002 10.96L10.91 7.33L7.29002 3.71L3.66002 7.34ZM10 13C9.45002 13 9.00002 12.55 9.00002 12C9.00002 11.45 9.45002 11 10 11C10.55 11 11 11.45 11 12C11 12.55 10.55 13 10 13ZM11 14C11 14.55 11.45 15 12 15C12.55 15 13 14.55 13 14C13 13.45 12.55 13 12 13C11.45 13 11 13.45 11 14ZM14 11C14.55 11 15 11.45 15 12C15 12.55 14.55 13 14 13C13.45 13 13 12.55 13 12C13 11.45 13.45 11 14 11ZM13.03 16.72L16.66 20.34L20.28 16.71L16.66 13.09L13.03 16.72Z"
			className={active ? "stroke-[url(#gradient)]" : "stroke-current hover:stroke-[url(#gradient)]"}
		/>
	</svg>
);

const RoutesIcon = ({ size = 24, className = "", active, ...props }) => (
	<svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 9C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 8.50061 18.1257 6.45028 14H12C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 10.1256 7.37194 11.1643 7.99963 12H5.59229C5.22418 10.9591 5 9.93952 5 9ZM12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
			className={active ? "fill-[url(#gradient)]" : "fill-current hover:fill-[url(#gradient)]"}
		/>
	</svg>
);

const AssetsIcon = ({ size = 24, className = "", active, ...props }) => (
	<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3 7L10 3L21 7M3 7V12L14 16L21 12V7M3 7L14 11L21 7"
			className={active ? "stroke-[url(#gradient)]" : "stroke-current group-hover:stroke-[url(#gradient)]"}
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M3 12V17L14 21L21 17V12"
			className={active ? "stroke-[url(#gradient)]" : "stroke-current group-hover:stroke-[url(#gradient)]"}
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</svg>
);

const ServiceTicketIcon = ({ size = 24, className = "", active, ...props }) => (
	<svg width="20" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5 5H11M5 9H11M9 13H11M1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19L12 17L10 19L8 17L6 19L4 17L1 19Z"
			stroke="#242424"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className={active ? "stroke-[url(#gradient)]" : "hover:stroke-current"}
		/>
	</svg>
);

const ReportIcon = ({ size = 24, className = "", active, ...props }) => (
	<TbMist size={size} style={{ fill: active ? "[url(#gradient)]" : "" }} />
);

const iconMap = {
	dashboard: RiDashboardHorizontalLine,
	operations: LiaSitemapSolid,
	submenu_live_view: TbGps,
	submenu_route_optimization: TbRoute,
	submenu_service_calendar: VscCalendar,
	submenu_drivers: LiaChessPawnSolid,
	submenu_routes: TbMapRoute,
	submenu_routes_optimization: RouteOptimizationIcon,
	waste: LiaBiohazardSolid,
	["octo-connect"]: PiPlugs,
	connect: TbSwitch3,
	market: MarketIcon,
	billing: TbPigMoney,
	submenu_invoice_history_report: TbReportMoney,
	submenu_satellite_location: LiaSatelliteDishSolid,
	submenu_vendors: TbBuildingStore,
	user: RiArtboard2Line,
	submenu_users: LuUsers,
	submenu_treatments: HiOutlineArrowsPointingIn,
	report: ReportIcon,
	submenu_service_history: TbReportAnalytics,
	submenu_transfer_history: TbReportSearch,
	asset: PiHashStraight,
	audit: IoEyeOutline,
	setting: IoSettingsOutline,
	logout: TbLogout,
	submenu_Subcontrator_Management: TbTruckDelivery,
	submenu_Subcontrator_Finder: HiArrowPath,
	submenu_ContractorManagement: TbTruckDelivery,
	submenu_Match_Request: TbTruckDelivery,
	submenu_Conflict_Handling: TbTruckDelivery,
	submenu_Subcontrator_Schedules: HiArrowPath,
	submenu_octo_mngmt: HiArrowRightEndOnRectangle,
	submenu_octo_finder: HiArrowPath,
	submenu_match_req: TbReplace,
	submenu_schedules: TbReport,
	submenu_handling: TbFidgetSpinner,
	submenu_subcont_mngmt: HiArrowRightStartOnRectangle,
	submenu_manage: TbTruckReturn,
	submenu_finder: TbZoomInArea,
	submenu_boxlocations: TbLiveView,
	submenu_weights: TbShredder,
	submenu_service_tickets: ServiceTicketIcon,
	transporter: PiNotepad,
	submenu_service_vehicles: TbTruckDelivery,
	generator: PiBuildingApartment,
	invoices_and_payments: TbFileDollar,
	service_reports: IoSettingsOutline,
};

const iconFillMap = {
	RiDashboardHorizontalLine: false,
	LiaSatelliteDishSolid: false,
	PiBuildingApartment: false,
	LiaSitemapSolid: true,
	LiaChessPawnSolid: true,
	PiPlugs: true,
	TbReportMoney: false,
	HiOutlineArrowsPointingIn: true,
	PiTrashBold: true,
	HiArrowPath: true,
	TbBuildingStore: true,
	LuUsers: false,
	IoSettingsOutline: false,
	submenu_service_history: true,
	submenu_transfer_history: true,
	TbLogout: false,
	TbMist: true,
	TbRoute: false,
	TbMapRoute: true,
	TbGps: false,
	TbSwitch3: false,
	FaDribbble: true,
	TbNews: false,
	BsSend: true,
	BiChart: true,
	TfiBarChart: true,
	TbTruckDelivery: false,
	LiaBiohazardSolid: true,
	TbFileDollar: false,
	TbRouteX2: false,
	TbUserStar: false,
	TbReplace: false,
	TbReport: false,
	TbFidgetSpinner: true,
	TbTrolley: false,
	PiHashStraight: true,
	SatelliteLocationIcon: true,
	RoutesIcon: false,
	RouteOptimizationIcon: true,
	ServiceTicketIcon: true,
};

const Icon = ({ name, active, type = "", level = 0 }) => {
	const IconComponent = iconMap[name];

	if (!IconComponent) {
		console.warn(`Icon not found for name: ${name}`);
		return null;
	}

	const size = type || level > 0 ? 20 : 24;
	const useFill = iconFillMap[IconComponent.name] ?? false;

	let className = "";
	if (!type) {
		if (level === 0) {
			className = useFill
				? `group-hover:fill-[url(#gradient)] ${active ? "fill-[url(#gradient)]" : ""}`
				: `group-hover:stroke-[url(#gradient)] ${active ? "stroke-[url(#gradient)]" : ""}`;
		} else {
			className = `${active ? "text-gray-500" : ""}`;
		}
	}

	const iconProps = { size, className };

	return <IconComponent {...iconProps} />;
};

export default Icon;
