import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../config/firebase";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import MultiselectDropdown from "../../../components/UI/dropdowns/MultiselectDropdown";
import { getSubContractors, processGeneratorData } from "../../../utils/firebaseOperations";
import { formattedDateTime } from "../../../utils/helpers";
import { RxCross2 } from "react-icons/rx";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import Button from "../../../components/UI/Button";
import useTUserContext from "../../../context/TransporterUserContext";

const fetchDocumentsInBatches = async (db, collectionName, docIds) => {
	if (!docIds.length) return [];

	// Split IDs into chunks of 10 (Firestore limit for "in" queries)
	const chunkArray = (array, size) => {
		const result = [];
		for (let i = 0; i < array.length; i += size) {
			result.push(array.slice(i, i + size));
		}
		return result;
	};

	const chunks = chunkArray(docIds, 10);
	const collectionRef = collection(db, collectionName);

	try {
		const queryPromises = chunks.map((chunk) => {
			const q = query(collectionRef, where("id", "in", chunk));
			return getDocs(q);
		});

		const snapshots = await Promise.all(queryPromises);

		const processedDocuments = await Promise.all(
			snapshots.flatMap((snapshot) =>
				snapshot.docs.map(async (doc) => {
					const processed = await processGeneratorData(doc);
					return processed || null;
				})
			)
		);

		return processedDocuments.filter(Boolean);
	} catch (error) {
		console.error("Error fetching documents:", error);
		throw error;
	}
};

const PendingConnections = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [generators, setGenerators] = useState([]);
	const [allDatas, setAllDatas] = useState([]);
	const [genIds, setGenIds] = useState([]);
	const [pendingCons, setPendingCons] = useState([]);
	const [transporterNames, setTransporterNames] = useState({});
	const [currentTransporterData, setCurrentTransporterData] = useState(null);
	const { user, loading } = useTUserContext();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showAvailableSubCont, setShowAvailableSubCont] = useState(false);
	const [subContractors, setSubContractors] = useState([]);
	const [selectedSubAssignee, setSelectedSubAssignee] = useState("");
	const [toDelete, setToDelete] = useState(null);
	const navigate = useNavigate();

	const fetchAllPendingConnectionsData = async (transporterId) => {
		try {
			const transporterDoc = await getDoc(doc(collection(db, "transporters"), transporterId));
			if (transporterDoc.exists()) {
				setCurrentTransporterData({ id: transporterId, ...transporterDoc.data() });
				let sharedGenerators = transporterDoc.data()?.sharedGenerators;
				let pendingConns = Array.isArray(sharedGenerators?.fromMe) ? sharedGenerators?.fromMe : [];
				let pending = [],
					ids = [];
				pendingConns?.map((item) => {
					pending.push(item);
					ids.push(item?.genId);
				});
				setPendingCons(pending);
				setGenIds(ids);
			}
		} catch (error) {
			console.error(`Error fetching transporter ${transporterId}:`, error);
		}
	};

	console.log({ currentTransporterData, pendingCons, genIds });

	useEffect(() => {
		if (user?.uid) fetchAllPendingConnectionsData(user?.uid);
	}, [user?.uid]);

	const fetchGens = async () => {
		try {
			let resp = await fetchDocumentsInBatches(db, "generators", genIds);
			setGenerators(resp);
		} catch (error) {
			console.log({ error });
		}
	};

	useEffect(() => {
		fetchGens();
	}, [genIds]);

	console.log({ allDatas });

	useEffect(() => {
		let latestPendingCons = pendingCons.map((connection) => {
			let gen = generators.find((item) => connection.genId == item.id);
			return {
				...connection,
				...gen,
			};
		});
		setAllDatas(latestPendingCons);
	}, [generators, pendingCons]);

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/service-request");
	};

	const handleReject = async (generatorId, subContractorId) => {
		console.log({ generatorId, subContractorId });

		// Delete from the current transporter who has sent
		let currTransporterRef = doc(db, "transporters", user?.uid);
		let currTransporterData = await getDoc(currTransporterRef);
		let currTransporterSharedGens = currTransporterData.data()?.sharedGenerators ?? {};
		let newTransporterSharedGen = currTransporterSharedGens?.fromMe?.filter((item) => item.genId != generatorId);
		console.log({ newTransporterSharedGen });
		await updateDoc(currTransporterRef, {
			sharedGenerators: {
				...currTransporterSharedGens,
				fromMe: newTransporterSharedGen,
			},
		});

		// Delete from the transporter who has received as a subcontractor
		let subContractorRef = doc(db, "transporters", subContractorId);
		let subContractorData = await getDoc(subContractorRef);
		let subContractorSharedGens = subContractorData.data()?.sharedGenerators ?? {};
		console.log({ subContractorSharedGens });

		let newSubContractorSharedGen = subContractorSharedGens?.toMe?.filter((item) => item.genId != generatorId);
		await updateDoc(subContractorRef, {
			sharedGenerators: {
				...currTransporterSharedGens,
				toMe: newSubContractorSharedGen,
			},
		});
	};

	const handleReAssign = async (generatorId, subContractorId) => {
		try {
			const currentTransporterRef = doc(db, "transporters", user?.uid);
			const assigneeSubContractorRef = doc(db, "transporters", selectedSubAssignee);
			const existingSubContractorRef = doc(db, "transporters", subContractorId);

			const currentTransporterDoc = await getDoc(currentTransporterRef);
			const assigneeSubContractorDoc = await getDoc(assigneeSubContractorRef);
			const existingSubContractorDoc = await getDoc(existingSubContractorRef);

			let currTransporterSharedGens = currentTransporterDoc.data()?.sharedGenerators ?? {};
			let subContractorSharedGens = assigneeSubContractorDoc.data()?.sharedGenerators ?? {};
			let existingSubContractorSharedGens = existingSubContractorDoc.data()?.sharedGenerators ?? {};

			let toUpdateCurr = Array.isArray(currTransporterSharedGens.fromMe) ? currTransporterSharedGens.fromMe : [];
			let toUpdateAssignee = Array.isArray(subContractorSharedGens.toMe) ? subContractorSharedGens.toMe : [];
			let toUpdateExisting = Array.isArray(existingSubContractorSharedGens.toMe)
				? existingSubContractorSharedGens.toMe
				: [];

			let timeStamp = new Date();

			toUpdateExisting.filter((item) => item.genId != generatorId);
			let toUpdateNewCurr = toUpdateCurr.filter((item) => item.genId != generatorId);

			toUpdateNewCurr.push({
				status: "Pending",
				genId: generatorId,
				timeStamp,
				to: {
					id: selectedSubAssignee,
					name: assigneeSubContractorDoc.data().name,
				},
			});

			toUpdateAssignee.push({
				status: "Pending",
				genId: generatorId,
				timeStamp,
				from: {
					id: user?.uid,
					name: currentTransporterDoc.data().name,
				},
			});

			await setDoc(
				currentTransporterRef,
				{
					sharedGenerators: { ...currTransporterSharedGens, fromMe: toUpdateCurr },
				},
				{ merge: true }
			);
			await setDoc(
				assigneeSubContractorRef,
				{
					sharedGenerators: { ...subContractorSharedGens, toMe: toUpdateAssignee },
				},
				{ merge: true }
			);
		} catch (error) {
			console.error("Error accepting generator:", error);
		}
		setIsLoading(false);
		setShowAvailableSubCont(false);
	};

	const handleSubContractorAvailability = async () => {
		setShowAvailableSubCont(true);
		try {
			let subs = await getSubContractors(user?.uid);
			await Promise.all(
				subs.map(async (subId) => {
					try {
						let subRef = doc(db, "transporters", subId);
						let subData = await getDoc(subRef);
						console.log({ subData });

						if (subData.exists()) {
							let name = subData.data()?.name;
							finalSubs.push({ name, id: subId });
						}
					} catch (error) {
						console.log({ error });
					}
				})
			);

			setSubContractors(finalSubs);
		} catch (error) {
			console.error("Error fetching subcontractors:", error);
		}
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg px-8 py-4 flex justify-between items-center">
					<div className="text-xl xl:text-2xl font-semibold text-black">Pending Connections</div>
					<div className="px-2">
						<MultiselectDropdown
							buttonText="Subcontractor"
							options={[{ label: "All", value: "all" }]}
							selectedOptions={[]}
							onSelectedOptionsChange={() => {}}
						/>
					</div>
				</div>

				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Service Address</div>
							<div className="truncate w-80">Service Type</div>
							<div className="truncate w-60">Service Frequency</div>
							<div className="truncate w-60">Expected Containers</div>
							<div className="truncate w-60">Date/Time</div>
							<div className="truncate w-32">Status</div>
							<div className="truncate w-48 text-center">Action</div>
						</div>
					</div>
					<div className={`min-w-fit overflow-y-scroll min-h-40 h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : allDatas.length > 0 ? (
							allDatas.map((generator, index) => (
								<div
									key={generator.id}
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < generators.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-80 h-full">{generator?.to?.name ?? "--"}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.name ?? "--"}</div>
										<div className="truncate w-80 h-full">
											{generator?.["serviceAddress"] && Object.keys(generator?.["serviceAddress"]).length > 0
												? [
														[
															generator?.["serviceAddress"]?.["street"],
															generator?.["serviceAddress"]?.["suite"] || "",
															generator?.["serviceAddress"]?.["city"],
															generator?.["serviceAddress"]?.["state"],
														]
															.filter(Boolean)
															.join(", "),
														generator?.["serviceAddress"]?.["zipCode"],
												  ]
														.filter(Boolean)
														.join(" ") || "--"
												: "--"}
										</div>
										<div className="truncate h-full w-80">
											{generator.serviceType?.length > 0 ? generator.serviceType?.toString() : "--"}
										</div>
										<div className="truncate h-full w-60">{generator.serviceFrequency}</div>
										<div className="truncate h-full w-60">
											{generator.expectedItemsOrServices?.length > 0 ? generator.expectedItemsOrServices : "--"}
										</div>
										<div className="truncate h-full w-60">{formattedDateTime(generator.timeStamp) ?? "--"}</div>
										<div className="truncate h-full w-32">{generator.status ?? "Pending"}</div>
										<div className="flex gap-2 w-48 items-center">
											<button
												onClick={() => handleReject(generator?.id, generator?.to?.id)}
												className="bg-[#F3F3F3] rounded-full w-24 p-1 px-4"
											>
												Cancel
											</button>
											<button
												className={`px-4 py-1 w-24 bg-primary transition-colors duration-200 text-white rounded-full`}
												onClick={() => {
													setIsModalOpen(true);
													setToDelete({ generatorId: generator?.id, subContractorId: generator?.to?.id });
												}}
											>
												Reassign
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Buttons Section */}
			<div className="flex justify-end p-4 mb-12 mt-12">
				<Button btnStyle="form_nav_primary" text="Next" onClick={handleNextClick} />
			</div>
			{/* I have to change here to state variable instead of direct boolean */}
			{isModalOpen && (
				<div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center">
					<div className="bg-white z-20 rounded-cardRadii max-w-md w-full min-h-fit">
						{!showAvailableSubCont ? (
							<div className="flex flex-col justify-between min-h-52 p-6">
								<h6 className="text-2xl font-semibold">Are you sure?</h6>
								<p className="text-lg">You are about to assign this generator to new subcontractor.</p>
								<div className="flex justify-end gap-2">
									<button onClick={() => setIsModalOpen(false)} className="bg-[#F3F3F3] rounded-full w-24 p-1 px-4">
										No
									</button>
									<button
										onClick={handleSubContractorAvailability}
										className={`px-4 py-1 w-24 bg-primary transition-colors duration-200 text-white rounded-full`}
									>
										Yes
									</button>
								</div>
							</div>
						) : (
							<div className="flex flex-col justify-between min-h-40 p-6">
								<h6 className="text-2xl font-semibold">Select Subcontractor</h6>
								<div>
									<Dropdown
										options={subContractors
											.map((sub) => ({ label: sub.name, value: sub.id }))
											.filter((item) => item.value !== user?.uid)}
										value={selectedSubAssignee}
										onChange={(val) => setSelectedSubAssignee(val)}
										styles="flex-col min-w-full"
									/>
								</div>
								<div className="flex justify-end gap-2">
									<button
										onClick={() => {
											setShowAvailableSubCont(false);
											setIsModalOpen(true);
										}}
										className="bg-[#F3F3F3] rounded-full w-24 p-1 px-4"
									>
										Cancel
									</button>
									<button
										onClick={() => handleReAssign(toDelete?.generatorId, toDelete?.subContractorId)}
										className={`px-4 py-1 w-36 bg-primary transition-colors duration-200 text-white rounded-full`}
									>
										Re-assign
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default PendingConnections;
