import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { Polyline } from "../../../../../../components/maps/PolyLine";
import { useSearchParams } from "react-router-dom";
function randomizeCoordinates(lat, lng, offset = 0.005) {
	const randomOffsetLat = (Math.random() - 0.5) * offset;
	const randomOffsetLng = (Math.random() - 0.5) * offset;
	const newLat = lat + randomOffsetLat;
	const newLng = lng + randomOffsetLng;
	return { lat: newLat, lng: newLng };
}

const WaypointMarkers = ({
	children,
	isEditable = true,
	headerContent,
	position,
	pin = null,
	mapRef,
	serviceId = "",
}) => {
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);
	const [markerRef, marker] = useAdvancedMarkerRef();
	const [currentMarkerPositions, setCurrentMarkerPositions] = useState(
		randomizeCoordinates(position.lat, position.lng)
	);
	let [searchParams, setSearchParams] = useSearchParams();
	const onMarkerDrag = (event) => {
		const newPosition = {
			lat: event.latLng.lat(),
			lng: event.latLng.lng(),
		};
		setCurrentMarkerPositions(newPosition);
	};

	useEffect(() => {
		setCurrentMarkerPositions(randomizeCoordinates(position.lat, position.lng));
	}, [position]);

	return (
		<>
			<AdvancedMarker
				ref={markerRef}
				onClick={() => {
					setInfoWindowOpen(true);
					setSearchParams({ ...searchParams, currentServiceId: serviceId });
				}}
				position={currentMarkerPositions}
				draggable
				onDrag={onMarkerDrag}
			>
				{pin}
			</AdvancedMarker>
			{infoWindowOpen && (
				<InfoWindow
					position={currentMarkerPositions}
					className="w-80"
					onCloseClick={() => setInfoWindowOpen(false)}
					headerContent={headerContent}
					ariaLabel={"This is Aria Label"}
					shouldFocus={false}
				>
					{children}
				</InfoWindow>
			)}
			<Polyline
				strokeWeight={2}
				strokeColor={"#000"}
				path={[{ ...currentMarkerPositions }, { lat: position.lat, lng: position.lng }]}
			/>
		</>
	);
};

WaypointMarkers.propTypes = {
	children: PropTypes.node,
	isEditable: PropTypes.bool,
	pin: PropTypes.any,
	position: PropTypes.any.isRequired,
};

export default WaypointMarkers;
