import { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, COLLECTIONS, db } from "../config/firebase";

const UserContext = createContext();

export const TransporterUserProvider = ({ children }) => {
	const [user, loading, error] = useAuthState(auth);
	const [userData, setUserData] = useState(null);
	const [userLoading, setUserLoading] = useState(true);

	useEffect(() => {
		let unsubscribe;

		if (user) {
			const userRef = doc(db, COLLECTIONS.users, user.uid);
			unsubscribe = onSnapshot(
				userRef,
				(userSnap) => {
					setUserData(
						userSnap.exists()
							? { ...userSnap.data(), ...user, uid: userSnap.data()?.transporterId, userId: user.uid }
							: { ...user }
					);
					setUserLoading(false);
				},
				(error) => {
					console.error("Error listening to user updates:", error);
					setUserLoading(false);
				}
			);
		} else {
			setUserLoading(false);
			setUserData(null);
		}

		return () => unsubscribe && unsubscribe();
	}, [user]);

	return (
		<UserContext.Provider value={{ user: userData, loading: userLoading, error }}>{children}</UserContext.Provider>
	);
};

// Export with the same name to avoid changes in existing code
const useTUserContext = () => useContext(UserContext);
export default useTUserContext;
