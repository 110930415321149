import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	BarChart,
	Bar,
	PieChart,
	Pie,
	Cell,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
} from "recharts";
import { db } from "../../config/firebase";
import { formattedDate } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { StyledSingleSelect } from "../octo-market/components/profile/Profile";

const DashboardPanel = () => {
	const [users, setUsers] = useState([]);
	const [totalNum, setTotalNum] = useState(0);
	const [selectedPeriod, setSelectedPeriod] = useState("1 Day");
	const navigate = useNavigate();
	// Sparkline data for the stats cards
	const sparklineData = [
		{ value: 400 },
		{ value: 380 },
		{ value: 350 },
		{ value: 370 },
		{ value: 360 },
		{ value: 380 },
		{ value: 400 },
	];

	const periods = [
		{ label: "Day", value: "1 Day" },
		{ label: "Week", value: "7 days" },
		{ label: "Month", value: "1 months" },
		{ label: "Quarter", value: "4 months" },
		{ label: "Year", value: "12 months" },
	];

	const barData = [
		{ name: "Jan", value1: 260, value2: 180 },
		{ name: "Feb", value1: 120, value2: 100 },
		{ name: "Mar", value1: 210, value2: 200 },
		{ name: "Apr", value1: 200, value2: 250 },
		{ name: "May", value1: 230, value2: 220 },
		{ name: "Jun", value1: 150, value2: 140 },
		{ name: "Jul", value1: 200, value2: 190 },
	];

	const pieData = [
		{ name: "Contractor", value: 43 },
		{ name: "Vendor", value: 28 },
		{ name: "Transporter", value: 29 },
	];

	const COLORS = ["#007AFF", "#C0C0C0", "#4CD964"];

	// Fetch all users
	const fetchAllUsers = async () => {
		try {
			const userQuery = query(collection(db, "octoMarketUsers"));
			const querySnapshot = await getDocs(userQuery);
			const allUsers = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
				createdAt: doc.data()?.createdAt ? doc.data()?.createdAt?.toDate() : "",
			}));
			console.log(allUsers);
			setTotalNum(allUsers.length);
			setUsers(
				allUsers?.filter((item) => new Date(item.createdAt).getDate() == new Date().getDate() && item.profileCompleted)
			);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	useEffect(() => {
		fetchAllUsers();
	}, []);

	console.log(users);

	return (
		<div className="p-4 my-4 w-full">
			{/* Stats Cards */}
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-8">
				<div className="p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex justify-between items-start">
						<div>
							<h2 className="text-lg font-medium mb-2">Total Users</h2>
							<p className="text-2xl font-bold">{totalNum}</p>
							<p className="text-green-500 text-sm mt-1">+3.25% compared to last week</p>
						</div>
						<div className="w-24 h-12">
							<LineChart width={96} height={48} data={sparklineData}>
								<Line
									type="monotone"
									dataKey="value"
									stroke="#00C853"
									strokeWidth={2}
									dot={false}
									isAnimationActive={false}
								/>
							</LineChart>
						</div>
					</div>
				</div>
				<div className="p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex justify-between items-start">
						<div>
							<h2 className="text-lg font-medium mb-2">Contact Forms Submitted</h2>
							<p className="text-2xl font-bold">12</p>
							<p className="text-green-500 text-sm mt-1">+3.25% compared to last week</p>
						</div>
						<div className="w-24 h-12">
							<LineChart width={96} height={48} data={sparklineData}>
								<Line
									type="monotone"
									dataKey="value"
									stroke="#00C853"
									strokeWidth={2}
									dot={false}
									isAnimationActive={false}
								/>
							</LineChart>
						</div>
					</div>
				</div>
			</div>

			{/* Charts Section */}
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6 mb-4 md:mb-8">
				<div className="lg:col-span-2 p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
						<h2 className="text-lg font-medium">New Users</h2>
						<StyledSingleSelect
							label=""
							options={periods}
							selectedOption={selectedPeriod}
							setSelectedOption={(value) => setSelectedPeriod(value)}
							style="df"
						/>
						{/* <button className="px-3 py-1 border rounded-md text-gray-600 whitespace-nowrap">6 months ▾</button> */}
					</div>
					<div className="h-[300px] md:h-[400px] w-full">
						<ResponsiveContainer width="100%" height="100%">
							<BarChart data={barData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar dataKey="value1" name="Subcontractors" fill="#3366FF" />
								<Bar dataKey="value2" name="Vendors" fill="#00C853" />
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>

				<div className="p-4 md:p-6 rounded-lg border border-[#E2E2E2]">
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-lg font-medium">User Categories</h2>
						<span className="text-sm text-gray-500">{selectedPeriod}</span>
					</div>
					<div className="h-[300px] md:h-[400px] w-full">
						<ResponsiveContainer width="100%" height="100%">
							<PieChart>
								<Pie
									data={pieData}
									cx="50%"
									cy="50%"
									innerRadius={60}
									outerRadius={90}
									dataKey="value"
									label={({ name, percent }) => `${name} ${(percent * 100).toFixed(1)}%`}
									labelStyle={{ fontSize: "0.8rem", fill: "black" }}
								>
									{pieData.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
									))}
								</Pie>
								<Tooltip
									formatter={(value, name) => [
										`${value} (${((value / pieData.reduce((sum, item) => sum + item.value, 0)) * 100).toFixed(1)}%)`,
										name,
									]}
								/>
								<Legend
									layout="vertical"
									align="end"
									verticalAlign="bottom"
									wrapperStyle={{
										paddingLeft: "20px",
									}}
								/>
							</PieChart>
						</ResponsiveContainer>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardPanel;
